import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { getReadAgendaItemsQueryKey, useCloseAgendaItemQuestion, useCreateAgendaItem, useDeleteAgendaItem, useDeleteAgendaItemQuestion, useOpenAgendaItemQuestion, useUpdateAgendaItem, } from '../../../../services/online/calendar/calendar';
import { createForm } from '../../../../utils/formik';
import { AgendaItemType } from '../../../../services/types';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = (agendaItemEntity, onClose, refetchAgendaItems) => {
    // Attributes
    const queryClient = useQueryClient();
    const [showVotting, setShowVotting] = useState(false);
    const [isQuestionClosed, setQuestionClosed] = useState(false);
    const [isAnonymousDisabled, setAnonymouDisabled] = useState(false);
    const [showTerminateBtn, setShowTerminateBtn] = useState(false);
    const [showDeleteVotingBtn, setShowDeleteVotingBtn] = useState(false);
    const { isOpen: isAgendaItemTypeOpen, onToggle: handleAgendaItemTypeToggle } = useDisclosure();
    // Effects
    useEffect(() => {
        if (agendaItemEntity?.question) {
            setShowVotting(true);
            setAnonymouDisabled(true);
            setShowDeleteVotingBtn(true);
            setShowTerminateBtn(true);
        }
        if (agendaItemEntity?.question?.closedOn) {
            setQuestionClosed(true);
        }
    }, [agendaItemEntity]);
    // Queries
    const { mutateAsync: createAgendaItem, isLoading: isCreateLoading } = useCreateAgendaItem();
    const { mutateAsync: updateAgendaItem, isLoading: isUpdateLoading } = useUpdateAgendaItem();
    const { mutateAsync: deleteAgendaItem, isLoading: isDeleteLoading } = useDeleteAgendaItem();
    const { mutateAsync: closeAgendaItemQuestion, isLoading: isCloseQuestionLoading, } = useCloseAgendaItemQuestion();
    const { mutateAsync: openAgendaItemQuestion, isLoading: isOpenQuestionLoading, } = useOpenAgendaItemQuestion();
    const { mutateAsync: deleteAgendaItemQuestion, isLoading: isDeleteQuestionLoading, } = useDeleteAgendaItemQuestion();
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            questionId: agendaItemEntity?.question?.id ?? '',
            duration: agendaItemEntity?.duration ?? 0,
            type: agendaItemEntity?.type ?? AgendaItemType[0],
            title: agendaItemEntity?.title ?? '',
            question: agendaItemEntity?.question?.text ?? '',
            isAnonymous: agendaItemEntity?.question?.isAnonymous ?? false,
        },
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });
    const { resetForm } = rest;
    const form = createForm(values, rest);
    const disableAgendaItemCreation = !values.title;
    // Functions
    function onDeleteAgendaItem() {
        void deleteAgendaItem({ agendaItemId: agendaItemEntity?.id }, {
            onSuccess: () => {
                onClose();
                resetForm();
                refetchAgendaItems();
            },
        });
    }
    function handleSubmit() {
        if (isCreateLoading ||
            isUpdateLoading ||
            !dirty ||
            disableAgendaItemCreation)
            return onClose();
        if (agendaItemEntity?.id) {
            // Update
            void updateAgendaItem({
                data: {
                    id: agendaItemEntity.id,
                    title: values?.title,
                    eventId: agendaItemEntity?.eventId,
                    isCompleted: agendaItemEntity?.isCompleted,
                    sortOrder: agendaItemEntity?.sortOrder,
                    question: {
                        id: values?.questionId || undefined,
                        isAnonymous: values?.isAnonymous,
                        text: values?.question,
                    },
                    duration: Number(values.duration),
                    type: values.type,
                    agendaItemParentId: agendaItemEntity?.agendaItemParentId,
                },
            }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries([
                        getReadAgendaItemsQueryKey({
                            'event-id': agendaItemEntity.eventId,
                        }),
                    ]);
                    onClose();
                    setShowVotting(false);
                    resetForm();
                    refetchAgendaItems();
                },
            });
            return;
        }
        // Else Create
        void createAgendaItem({
            data: {
                eventId: agendaItemEntity?.eventId,
                isCompleted: false,
                question: {
                    isAnonymous: values?.isAnonymous,
                    text: values?.question,
                },
                title: values?.title,
                duration: Number(values.duration),
                type: values.type,
                agendaItemParentId: agendaItemEntity?.agendaItemParentId,
            },
        }, {
            onSuccess: () => {
                void queryClient.invalidateQueries([
                    getReadAgendaItemsQueryKey({
                        'event-id': agendaItemEntity.eventId,
                    }),
                ]);
                onClose();
                setShowVotting(false);
                resetForm();
                refetchAgendaItems();
            },
        });
    }
    function onTerminateVote() {
        void closeAgendaItemQuestion({
            questionId: agendaItemEntity?.question?.id ?? '',
        }, {
            onSuccess: () => setQuestionClosed(true),
        });
    }
    function onOpenVote() {
        void openAgendaItemQuestion({
            questionId: agendaItemEntity?.question?.id ?? '',
        }, {
            onSuccess: () => setQuestionClosed(false),
        });
    }
    function onDeleteVoting() {
        if (!agendaItemEntity?.id) {
            return setShowVotting(false);
        }
        void deleteAgendaItemQuestion({
            questionId: agendaItemEntity?.question?.id ?? '',
        }, {
            onSuccess: () => {
                setShowVotting(false);
                setShowTerminateBtn(false);
                setShowDeleteVotingBtn(false);
                setAnonymouDisabled(false);
                form.question.onChange('');
                form.questionId.onChange(undefined);
            },
        });
    }
    function handleAgendaItemTypeChange(type) {
        // Remove the vote if it's not a decision again
        if (type !== AgendaItemType[1]) {
            form.question.onChange('');
            form.questionId.onChange('');
            setShowVotting(false);
        }
        form.type.onChange(type);
        handleAgendaItemTypeToggle();
    }
    return {
        isEditMode: !!agendaItemEntity?.id,
        isDecisionType: values?.type === AgendaItemType[1],
        handleSubmit,
        form,
        showVotting,
        setShowVotting,
        onDeleteAgendaItem,
        onDeleteVoting,
        onTerminateVote,
        isDeleteLoading,
        isCreateLoading,
        isUpdateLoading,
        isCloseQuestionLoading,
        isDeleteQuestionLoading,
        isOpenQuestionLoading,
        isQuestionClosed,
        onOpenVote,
        isAnonymousDisabled,
        showTerminateBtn,
        showDeleteVotingBtn,
        resetForm,
        isAgendaItemTypeOpen,
        handleAgendaItemTypeToggle,
        handleAgendaItemTypeChange,
    };
};
export default useLogic;
