import React from 'react';
import { ImageBackground } from 'react-native';
import EventCardContent from './event-card-content/index.web';
import styles from './style.web';
import { useEventCardLogic } from './logic.web';
const EventCard = ({ event, eventType, isPublished, onLayout, onClick, }) => {
    // Logic
    const { isEventPast, getPresenceStatusFromInvitee, isTasksLoading, tasksToDoLength, isCurrentUserInvited, } = useEventCardLogic(event);
    // Render
    if (isEventPast) {
        return (<ImageBackground source={require('../../../assets/images/past-event-bg.png')} resizeMode="cover" style={styles.imageBackground} onLayout={e => onLayout(e?.nativeEvent?.layout?.height)}>
        <EventCardContent isEventPast={isEventPast} eventStartDate={event?.startDate} eventEndDate={event?.endDate} eventType={eventType} eventTitle={event?.name} isPublished={isPublished} onClick={onClick} presence={getPresenceStatusFromInvitee()} isTasksLoading={isTasksLoading} tasksToDoLength={tasksToDoLength} isCurrentUserInvited={isCurrentUserInvited}/>
      </ImageBackground>);
    }
    return (<EventCardContent isEventPast={isEventPast} eventStartDate={event?.startDate} eventEndDate={event?.endDate} eventType={eventType} eventTitle={event?.name} isPublished={isPublished} onClick={onClick} presence={getPresenceStatusFromInvitee()} isTasksLoading={isTasksLoading} tasksToDoLength={tasksToDoLength} isCurrentUserInvited={isCurrentUserInvited}/>);
};
export default EventCard;
