import _ from 'lodash';
export default function getInviteesFromEvent(event) {
    const presentAttendeeIds = [];
    const absentAttendeeIds = [];
    const remoteAttendeeIds = [];
    event?.invitees?.forEach(invitee => {
        if (invitee?.acceptedOn) {
            presentAttendeeIds?.push(invitee?.personId);
        }
        if (invitee?.refusedOn && !invitee?.proxyId) {
            absentAttendeeIds?.push(invitee?.personId);
        }
        if (invitee?.isRemote) {
            remoteAttendeeIds?.push(invitee?.personId);
        }
    });
    return {
        presentAttendeeIds: _.uniq(presentAttendeeIds),
        absentAttendeeIds: _.uniq(absentAttendeeIds),
        remoteAttendeeIds: _.uniq(remoteAttendeeIds),
    };
}
