import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import COLORS from '../../../../../../constants/colors';
import { tKeys } from '../../../../../../translations/keys';
import Text from '../../../../../../components/content/text';
import { t } from 'i18next';
import styles from './style.web';
import FONTS from '../../../../../../constants/fonts';
import { HStack } from '@chakra-ui/react';
const ButtonAddTask = ({ onClickButtonAddDocument, }) => {
    // Render
    return (<Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={onClickButtonAddDocument}>
      <HStack width={'100%'} spacing={0}>
        <Icon name="add-task" width={25} height={25} fill={COLORS.White.T500.value}/>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} style={styles.text}>
          {t(tKeys.home.more.repository.addbutton.title)}
        </Text>
      </HStack>
    </Button>);
};
export default ButtonAddTask;
