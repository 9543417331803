import COLORS from '../constants/colors';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomShadow = {
    FloatingButton: 'FloatingButton',
    FormControlContainer: 'FormControlContainer',
    PopupMenu: 'PopupMenu',
    MenuShadow: 'MenuShadow',
};
const CUSTOM_SHADOWS = {
    [CustomShadow.FloatingButton]: {
        shadowColor: COLORS.Black.T500.value,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        elevation: 12,
    },
    [CustomShadow.FormControlContainer]: {
        shadowColor: '#ceced7',
        shadowOffset: {
            width: 0,
            height: 9,
        },
        shadowOpacity: 0.22,
        shadowRadius: 9.22,
        elevation: 12,
    },
    [CustomShadow.PopupMenu]: {
        shadowColor: COLORS.Black.T500.value,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.12,
        shadowRadius: 8,
        elevation: 12,
    },
    [CustomShadow.MenuShadow]: {
        shadowColor: COLORS.Black.T100.value,
        shadowOpacity: 0.1,
        shadowOffset: {
            height: 4,
            width: 4,
        },
        elevation: 6,
    },
};
export default CUSTOM_SHADOWS;
