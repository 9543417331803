import { useEffect, useMemo, useState } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { PeriodValues } from './types';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useLogic = (form, values) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const [isAllMembersSelected, setAllMembersSelected] = useState(false);
    // Query
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    // Data
    const allCompanyMembers = useMemo(() => enrolments?.items?.map(enrolment => enrolment.person), [enrolments]);
    // Handlers
    const onPressPeriodTimeButton = (period) => {
        form?.fromDate?.onChange(PeriodValues[period]?.fromDate);
        form?.toDate?.onChange(PeriodValues[period]?.toDate);
        form?.period?.onChange(period);
    };
    const onPressFromDateButton = (fromDate) => {
        // Reset the period
        form?.period?.onChange(null);
        form?.fromDate?.onChange(fromDate?.toISOString());
    };
    const onPressToDateButton = (toDate) => {
        // Reset the period
        form?.period?.onChange(null);
        form?.toDate?.onChange(toDate?.toISOString());
    };
    const isMemberSelected = (member) => {
        return !!values?.publishedBy?.find(id => id === member?.id);
    };
    const onChooseMember = (member) => {
        let updatedIds;
        if (isMemberSelected(member)) {
            updatedIds = values?.publishedBy?.filter(id => id !== member.id);
        }
        else {
            updatedIds = values?.publishedBy?.concat(member?.id);
        }
        form?.publishedBy?.onChange(updatedIds);
    };
    const onSelectAllMembers = () => {
        if (isAllMembersSelected) {
            // Unselect all members
            form?.publishedBy?.onChange([]);
        }
        else {
            // Select all members
            form?.publishedBy?.onChange(allCompanyMembers?.map(person => person.id));
        }
        setAllMembersSelected(prev => !prev);
    };
    // Effect
    useEffect(() => {
        const isAllSelected = allCompanyMembers?.every(person => values?.publishedBy?.includes(person.id));
        setAllMembersSelected(isAllSelected);
    }, [values?.publishedBy]);
    // Return
    return {
        allCompanyMembers,
        isMemberSelected,
        onChooseMember,
        onPressPeriodTimeButton,
        onPressFromDateButton,
        onPressToDateButton,
        form,
        onSelectAllMembers,
        isAllMembersSelected,
    };
};
export default useLogic;
