import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import FONTS from '../../../../../../../constants/fonts';
import COLORS from '../../../../../../../constants/colors';
export const AddButtonSubAgendaItem = ({ onClick, isOver, title, }) => {
    // Attributes
    const { fontFamily, fontSize, fontWeight } = FONTS.T1.T12Px.Medium500.value;
    // Render
    return (<Stack width={'100%'} px={'.75rem'} py={'.75rem'} borderWidth={1} borderColor={isOver ? COLORS.Govrn.Blue.T500.value : COLORS.Stroke.value} borderStyle={'dashed'} borderRadius={'.5rem'} cursor={!!onClick && 'pointer'} onClick={onClick} backgroundColor={isOver && COLORS.Govrn.Blue.T100.value} textColor={isOver && COLORS.Govrn.Blue.T500.value}>
      <Text fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight}>
        {title}
      </Text>
    </Stack>);
};
