import React from 'react';
import { Pressable } from 'react-native';
import AvatarDetails from '../../../../../../components/content/avatar-details';
import Icon from '../../../../../../components/content/icon';
import Flex from '../../../../../../components/surfaces/flex';
import FONTS from '../../../../../../constants/fonts';
const MultiSelectItem = ({ item, isSelected, onPressSelected, avatarSize = 36, avatarFont = FONTS.T1.T12Px.Bold700.value, showStatus, showBoardRole = true, endEnhancer, }) => {
    // Render
    return (<Pressable onPress={onPressSelected}>
      <Flex justify="space-between" align="center">
        <AvatarDetails avatarSize={avatarSize} avatarFont={avatarFont} person={item} showStatus={showStatus} showBoardRole={showBoardRole}/>
        {endEnhancer ||
            (isSelected ? (<Icon name={'box-selected'} width={24} height={24}/>) : (<Icon name={'box'} width={24} height={24}/>))}
      </Flex>
    </Pressable>);
};
export default MultiSelectItem;
