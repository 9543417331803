import * as React from 'react';
import Svg, { G, Rect, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const ArrowDown = (props) => (<Svg {...props}>
    <G filter="url(#a)">
      <Rect x={12} y={8} width={44} height={44} rx={22} fill="#fff"/>
      <Path d="M34 24.166v11.667M39.833 30 34 35.833 28.167 30" stroke="#393360" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
    </G>
    <Defs></Defs>
  </Svg>);
export default ArrowDown;
