import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContent: {
        width: '100%',
        height: '100%',
        paddingVertical: 20,
    },
    buttonFilterContainer: {
        padding: 10,
        width: '100%',
        height: '100%',
    },
    filterAllFileTypesContainer: {
        marginTop: 12,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
    },
    flatlistContent: {
        flex: 1,
        paddingBottom: 32,
    },
    bottomButton: {
        bottom: 0,
        left: 0,
        right: 0,
        paddingHorizontal: 12,
        paddingBottom: 16,
        marginVertical: 12,
        width: '100%',
    },
});
export default styles;
