import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '../../../../components/content/avatar';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import useLogic from './logic.web';
import Modal from '../../../../components/surfaces/modal/index.web';
import { Box, Checkbox, HStack, Stack, VStack } from '@chakra-ui/react';
import Input from '../../../../components/inputs/input';
import AvatarDetails from '../../../../components/content/avatar-details';
const ChatSettings = ({ channelId, isOpen, onClose }) => {
    // Attributes
    const { t } = useTranslation();
    const { channel, form, onMemberPress, onDeleteGroupPress, isDeleteLoading, isEditable, isSubmitLoading, handleOnSubmit, handleOpenFilePicker, values, image, peopleInTheCompany, onSelectMember, isMemberSelected, } = useLogic(channelId, onClose);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.channel.chatSettings.title)} actionButton={isEditable && (<HStack spacing={'1rem'}>
            <Button kind={Kind.TRANSPARENT} onPress={onDeleteGroupPress} isLoading={isDeleteLoading} backgroundColorPerso="white" borderColor="red" borderWidth={1}>
              <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Error.T500.value}>
                {t(tKeys.chat_settings.delete_group)}
              </Text>
            </Button>
            <Button size={Size.MINI} kind={Kind.SECONDARY} isLoading={isSubmitLoading} onPress={handleOnSubmit}>
              <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
                {t(tKeys.common.save)}
              </Text>
            </Button>
          </HStack>)} hasCloseButton hasFooter={isEditable}>
      <VStack width={'full'} py={'.875rem'} px={'.75rem'} bgColor={COLORS.BG.value} spacing={'.75rem'} overflow={'scroll'}>
        {/* Profile picture */}
        <VStack width={'100%'} p={'1.25rem'} spacing={'1rem'} borderRadius={'.75rem'} bgColor={COLORS.White.T500.value} boxShadow={'md'}>
          <Stack position={'relative'}>
            {channel?.isGroup && (channel.picture || image) ? (<Avatar imageSource={image ?? values?.picture?.uri} name={values?.name} width={'5rem'} height={'5rem'} borderRadius={100} backgroundColor={COLORS.Line.value}/>) : (<Stack width={'5rem'} height={'5rem'} borderRadius={100} backgroundColor={COLORS.Line.value} justifyContent={'center'} alignItems={'center'}>
                <Icon name="users" width={24} height={24}/>
              </Stack>)}
            {isEditable && (<Stack position={'absolute'} bottom={-2} right={-2}>
                <Box borderRadius={'5rem'} borderWidth={3} borderColor={COLORS.White.T500.value} backgroundColor={COLORS.Line.value} onClick={handleOpenFilePicker} p={'.5rem'} cursor={'pointer'}>
                  <Icon name="camera-filled" width={12} height={12}/>
                </Box>
              </Stack>)}
          </Stack>
          {!isEditable && (<Text font={FONTS.T1.T14Px.Bold.value}>{channel?.name}</Text>)}
        </VStack>
        {isEditable && (<VStack width={'full'} alignItems={'flex-start'} p={'1rem'} bgColor={COLORS.White.T500.value} borderRadius={'.75rem'} boxShadow={'md'}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.messages.groupName.label)}
            </Text>
            <Input name={'groupName'} value={form?.name.value} onChange={e => {
                form?.name.onChange?.(e?.currentTarget?.value);
            }} placeholder={t(tKeys.messages.groupName.placeholder)}/>
          </VStack>)}
        <VStack alignItems={'flex-start'} width={'100%'} p={'1rem'} bgColor={COLORS.White.T500.value} borderRadius={'.75rem'} boxShadow={'md'} overflow={'scroll'} maxHeight={'28rem'}>
          {peopleInTheCompany?.map(person => (<Box width="100%" key={person?.id} cursor={'pointer'}>
              <HStack justify="space-between" width={'full'}>
                <Stack width={'full'} onClick={() => onMemberPress(person?.id)}>
                  <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
                </Stack>
                {isEditable ? (<Checkbox isChecked={isMemberSelected(person)} onChange={() => {
                    onSelectMember(person);
                }}/>) : (<Icon name="chevron-right" width={24} height={24}/>)}
              </HStack>
            </Box>))}
        </VStack>
      </VStack>
    </Modal>);
};
export default ChatSettings;
