import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import Icon from '../../../../../../../components/content/icon';
import Text from '../../../../../../../components/content/text';
import Container from '../../../../../../../components/surfaces/container';
import Flex from '../../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import styles from './styles';
import LineSeparator from '../../../../../../../components/utility/line-separator';
import useFormatDate from '../../../../../../../hooks/format-date/useFormatDate';
const CardFooter = ({ date, isInsightfulSelected, onPressMessage, iconSize = 32, dateTextFont = FONTS.T1.T10Px.Regular400.value, dateTextColor = COLORS.InputText.value, currentUserId, postId, handleLikePost, footerSeparator, }) => {
    // Attributes
    const { formatDateTime } = useFormatDate();
    const [isInsightfulSelectedState, setIsInsightfulSelectedState] = useState(isInsightfulSelected);
    // Functions
    const onPressInsightful = () => {
        setIsInsightfulSelectedState(!isInsightfulSelectedState);
        handleLikePost();
    };
    // Effects
    useEffect(() => {
        setIsInsightfulSelectedState(isInsightfulSelected);
    }, [isInsightfulSelected]);
    // Render
    return (<>
      {footerSeparator && (<Container style={styles.separator}>
          <LineSeparator color={COLORS.Line.value} width={1} type="bottom"/>
        </Container>)}

      <Flex>
        {/* Insightful icon */}
        {!!currentUserId && !!postId && (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <Pressable onPress={onPressInsightful}>
            {isInsightfulSelectedState ? (<Icon name={'insightful-selected'} width={iconSize} height={iconSize}/>) : (<Icon name={'insightful'} width={iconSize} height={iconSize}/>)}
          </Pressable>)}

        {/* Message icon */}
        {onPressMessage && (<Pressable onPress={onPressMessage} style={styles.commentsPressable}>
            <Icon name={'comments'} width={iconSize} height={iconSize}/>
          </Pressable>)}

        {/*  Date */}
        {date && (<Container style={styles.dateContainer}>
            <Text font={dateTextFont} color={dateTextColor}>
              {formatDateTime({ date, formatWithTimezone: true })}
            </Text>
          </Container>)}
      </Flex>
    </>);
};
export default CardFooter;
