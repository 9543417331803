import Page from '../../components/surfaces/page';
import React from 'react';
import COLORS from '../../constants/colors';
import NavigationHeader from '../../components/navigation/navigation-header/index.web';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/content/icon';
import { Button as ChakraButton, HStack, VStack, useDisclosure, } from '@chakra-ui/react';
import { tKeys } from '../../translations/keys';
import LoadingWrapper from '../../components/utility/loading-wrapper/index.web';
import { DocumentPreview } from '../document-viewer/document-preview/index.web';
import useBoardpackLogic from './logic.web';
import BoardpackItems from './boardpack-items/index.web';
import DocumentViewer from '../document-viewer/index.web';
import { BusyIndicator } from '../../components/utility/busy-indicator';
import { BoardPackDocumentPreviewPlaceholder } from './placeholder/index.web';
import Button from '../../components/inputs/button';
import { Kind } from '../../components/inputs/button/constants/kind';
const Boardpack = () => {
    // Attributes
    const { t } = useTranslation();
    const DocumentViewerDisclosure = useDisclosure();
    const { event, linkedDocumentToAgendaItems, goBack, isPageLoading, handleSelectFile, handleSetWebviewerInstance, selectedDocument, selectedFile, isAdmin, agendaItemId, agendaItemName, downloadAllFiles, isDownloadLoading, } = useBoardpackLogic();
    // Render
    return (<Page backgroundColor={COLORS.BG.value}>
      <NavigationHeader startEnhancer={<ChakraButton p={'.25rem'} bgColor={COLORS.Tag.value} borderRadius={'.5rem'} onClick={() => goBack()}>
            <Icon name="chevron-left-default" height={15} width={15}/>
          </ChakraButton>} endEnhancer={<Button kind={Kind.SECONDARY} onPress={downloadAllFiles} isLoading={isDownloadLoading}>
            {t(tKeys.boardpack.page.cta.download_all)}
          </Button>}>
        {t(tKeys.boardpack.page.title, {
            eventName: event?.name,
            interpolation: { escapeValue: false },
        })}
      </NavigationHeader>
      <LoadingWrapper isLoading={isPageLoading}>
        <HStack width={'full'} height={'full'} spacing={0} overflow={'auto'}>
          <VStack backgroundColor={COLORS.White.T500.value} width={'40%'} alignItems={'flex-start'} p={'1.25rem'} height={'full'} overflow={'auto'} borderRightWidth={1} spacing={'.625rem'}>
            {linkedDocumentToAgendaItems?.map((agendaItem, index) => {
            return (<BoardpackItems agendaItem={agendaItem} key={agendaItem.id} onSelectedFile={handleSelectFile} selectedFile={selectedFile} parentIndexes={[index + 1]}/>);
        })}
          </VStack>
          {/* Document Previewer */}
          <BusyIndicator isEmpty={!selectedDocument} placeholder={<BoardPackDocumentPreviewPlaceholder isAdmin={isAdmin} event={event} agendaItemId={agendaItemId} agendaItemName={agendaItemName}/>}>
            <DocumentPreview selectedDocument={selectedDocument} isSmallPreview width={'100%'} setPDFtronInstance={handleSetWebviewerInstance} onFullScreenPress={DocumentViewerDisclosure.onOpen} fullScreenRight={'7%'} fullScreenTop={'6rem'}/>
          </BusyIndicator>
        </HStack>
      </LoadingWrapper>
      {/* Modal */}
      <DocumentViewer isOpen={DocumentViewerDisclosure.isOpen} onClose={DocumentViewerDisclosure.onClose} setPDFtronInstance={handleSetWebviewerInstance} selectedDocument={selectedDocument}/>
    </Page>);
};
export default Boardpack;
