import React from 'react';
import Text from '../../components/content/text';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import useLogic from './logic.web';
import { Stack, VStack } from '@chakra-ui/react';
const SelectMemberRole = ({ memberRole, onChoose, }) => {
    // Attributes
    const { userRoles } = useLogic(memberRole);
    // Render
    return (<VStack minWidth={'17.5rem'} alignItems={'flex-start'} py={'.25rem'} spacing={0}>
      {userRoles.map(role => (<Stack _hover={{ backgroundColor: COLORS.BG.value }} backgroundColor={role.value === memberRole ? COLORS.BG.value : undefined} key={role.value} width={'full'} onClick={() => onChoose(role.value)} py={'.5rem'} px={'1rem'} cursor={'pointer'}>
          <Text font={FONTS.T1.T14Px.Regular400.value}>{role.label}</Text>
        </Stack>))}
    </VStack>);
};
export default SelectMemberRole;
