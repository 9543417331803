import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        paddingVertical: 20,
        height: '100%',
        width: '100%',
    },
    filterByMembersContainer: {
        marginTop: 12,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
    },
    searchInput: {
        height: 45,
        borderRadius: 8,
    },
    searchContainer: {
        paddingVertical: 10,
    },
    bottomButton: {
        bottom: 0,
        left: 0,
        right: 0,
        paddingHorizontal: 12,
        paddingBottom: 16,
        marginVertical: 12,
        width: '100%',
    },
    flatlistContent: {
        paddingBottom: 32,
    },
    separator: {
        height: 20,
    },
});
export default styles;
