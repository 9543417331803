import React, { useEffect, useMemo, useState } from 'react';
import Text from '../../../../../components/content/text';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import useRouter from '../../../../../hooks/router/useRouter';
import { useReadKnowledgeBaseCultures } from '../../../../../services/online/knowledge-base/knowledge-base';
const useLogic = () => {
    // Attributes
    const { goBack, push, params } = useRouter();
    const [selectedLanguage, setSelectedLanguage] = useState();
    const culture = params?.culture;
    // Query
    const { data: cultures } = useReadKnowledgeBaseCultures();
    // Effect
    useEffect(() => {
        setSelectedLanguage(culture);
    }, [culture]);
    const languages = useMemo(() => {
        return cultures?.map(culture => ({ label: culture, value: culture })) ?? [];
    }, [cultures]);
    const options = useMemo(() => languages?.map(language => {
        return {
            id: language?.value,
            children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
              {language?.label}
            </Text>),
        };
    }), [languages]);
    // Handler
    function onSubmitFilters() {
        push('/home/more/knowledge-base', { culture: selectedLanguage });
    }
    function onSelectLanguage(value) {
        if (selectedLanguage === value) {
            setSelectedLanguage(undefined);
        }
        else {
            setSelectedLanguage(value);
        }
    }
    // return
    return {
        onGoBack: goBack,
        options,
        selectedLanguage,
        onSubmitFilters,
        onSelectLanguage,
        isSubmitDisabled: culture === selectedLanguage,
    };
};
export default useLogic;
