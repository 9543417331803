import { t } from 'i18next';
import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import PeriodTimeButtonGroup from '../../../screens/home/feed/components/inputs/period-time-button-group';
import { PeriodTime } from '../../../screens/home/feed/filter/types';
import { tKeys } from '../../../translations/keys';
import Text from '../../content/text';
import Button from '../../inputs/button';
import { Kind } from '../../inputs/button/constants/kind';
import FilterCalendar from '../../pickers/filter-calendar';
import Container from '../container';
import Flex from '../flex';
import styles from './style';
import { Box } from '@chakra-ui/react';
const FilterHeader = ({ title, 
// From date props
fromDateValue, onPressFromDateButton, isFromDateDirty, closeFromDateCalendar, 
// To date props
toDateValue, onPressToDateButton, isToDateDirty, closeToDateCalendar, 
// Period group buttons
selectedPeriodValue, handleOnPressPeriodTimeButton, 
// Select all options
handleOnSelect, isAllOptionsSelected, }) => {
    // Attributes
    const periodTimes = [
        PeriodTime.TODAY,
        PeriodTime.CURRENT_WEEK,
        PeriodTime.CURRENT_MONTH,
        PeriodTime.CURRENT_YEAR,
    ];
    // Render
    return (<Flex direction="column">
      {/* Title */}
      <Box mx={'.75rem'} marginBottom={'1rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS?.Text.T400.value}>
          {t(tKeys.home.feed.filter['custom-oeriods'].title)}
        </Text>

        {/* From (date) + calendar */}
        <FilterCalendar name={'fromDateCalendar'} value={fromDateValue} onChange={onPressFromDateButton} title={t(tKeys.common.from)} isValueTouched={isFromDateDirty} closeCalendar={closeFromDateCalendar}/>

        {/* To (date) + calendar */}
        <FilterCalendar name={'toDateCalendar'} value={toDateValue} onChange={onPressToDateButton} title={t(tKeys.common.to)} isValueTouched={isToDateDirty} closeCalendar={closeToDateCalendar} initialValue={fromDateValue} minDate={fromDateValue}/>
      </Box>

      {/* Period */}
      <PeriodTimeButtonGroup selectedValue={selectedPeriodValue} periodTimes={periodTimes} handleOnPressPeriodTimeButton={period => handleOnPressPeriodTimeButton(period)}/>

      {/* Published by - members of the company */}
      {/* Title */}
      <Flex justify="space-between" style={styles.filterByMembersContainer}>
        <Container marginHorizontal={12}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>{title}</Text>
        </Container>

        <Container marginHorizontal={12}>
          <Button kind={Kind.TRANSPARENT} onPress={handleOnSelect}>
            <Text color={COLORS.InputText.value}>
              {isAllOptionsSelected
            ? t(tKeys.common['unselect-all'])
            : t(tKeys.common['select-all'])}
            </Text>
          </Button>
        </Container>
      </Flex>
    </Flex>);
};
export default FilterHeader;
