import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        padding: 12,
        alignSelf: 'flex-end',
        width: '100%',
    },
    dayContainer: {
        width: 30,
        height: 30,
        borderRadius: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        textAlign: 'center',
    },
});
export default styles;
