import React from 'react';
import Flex from '../../surfaces/flex';
import COLORS from '../../../constants/colors';
import { SideBarRouting } from './side-bar-routing';
import { useCompaniesContext } from '../../../contexts/companies';
import { refresh } from '../../../services/online/people/people';
import { saveTokens } from '../../../utils/handleTokens';
export const Sidebar = () => {
    const { allUserCompanies, selectedCompanyId, updateCurrentCompany } = useCompaniesContext();
    const currentCompany = allUserCompanies?.find(company => company.id === selectedCompanyId);
    async function handleCompanyUpdate(workspaceId) {
        try {
            const res = await refresh({ useRefreshToken: true });
            if (res) {
                await saveTokens({
                    accessToken: res.accessToken,
                    refreshToken: res.refreshToken,
                });
            }
        }
        catch (err) {
            console.error('Error when fetching new tokens : ', err);
        }
        updateCurrentCompany(workspaceId);
    }
    return (<Flex direction="row" style={{
            borderRightColor: COLORS.Line.value,
            borderRightWidth: 1,
            height: '100vh',
        }}>
      <SideBarRouting title={currentCompany?.name} updateCurrentCompany={handleCompanyUpdate} workspaces={allUserCompanies}/>
    </Flex>);
};
