import React, { useMemo } from 'react';
import Card from '../../../../../../../components/surfaces/card/index.web';
import Container from '../../../../../../../components/surfaces/container';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import CardHeader from '../card-header';
import useFormatDate from '../../../../../../../hooks/format-date/useFormatDate';
import Text from '../../../../../../../components/content/text';
const CardPostInvitation = ({ companyName, companyImageURI, item, index, styles, creator, footerSeparator, handleOnPostPress, isPostDetails, }) => {
    // Attributes
    // Need to format this  because we receive messages from the BE like so :
    // _{0} just joined {1} ! Welcome {3}
    const formatContent = item?.content
        ?.replace('_', '')
        ?.replace('{0}', creator?.firstname)
        ?.replace('{1}', creator?.lastname)
        ?.replace('{2}!  ', `@${companyName} !\n`)
        ?.replace('{3}', creator?.firstname)
        ?.trim() + ' 🎉';
    const createdDate = useMemo(() => new Date(item?.createdOn), [item?.createdOn]);
    const { formatDateTime } = useFormatDate();
    // Render if no creator
    if (!creator) {
        return <></>;
    }
    // Render
    return (<Card isPostDetails={isPostDetails} backgroundColor={[
            COLORS.Govrn.DarkBlue.T300.value,
            COLORS.Tags.BluePurple.value,
        ]}>
      <Container style={{ padding: 16 }}>
        <CardHeader imageUri={companyImageURI} title={formatContent} titleFont={FONTS.T1.T14Px.SemiBold600.value} titleColor={COLORS.White.T500.value} imageHeight={33} imageWidth={33} iconHeight={24} iconWidth={24} subTitle={<Text color={COLORS.White.T500.value} font={FONTS.T1.T10Px.Regular400.value}>
              {formatDateTime({
                date: createdDate,
                formatWithTimezone: true,
            })}
            </Text>}/>
      </Container>
    </Card>);
};
export default CardPostInvitation;
