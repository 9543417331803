import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../../hooks/router/useRouter';
import { getReadMeQueryKey, useUpdatePerson, } from '../../../../../services/online/people/people';
import { tKeys } from '../../../../../translations/keys';
import { createForm } from '../../../../../utils/formik';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../../../../components/content/use-toast/index.web';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { goBack, params } = useRouter();
    const mobilePhone = params?.mobilePhone;
    const currentUserData = params?.currentUserData;
    const queryClient = useQueryClient();
    const toast = useToast();
    // Queries
    const { mutateAsync: updatePerson, isLoading: isUpdatePersonLoading } = useUpdatePerson();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPressButton = async () => {
        if (dirty) {
            try {
                await updatePerson({
                    data: {
                        ...currentUserData,
                        phone: mobilePhone,
                    },
                    params: {
                        'phone-code': form?.code?.value,
                    },
                }, {
                    onSuccess: () => {
                        void queryClient.invalidateQueries(getReadMeQueryKey());
                        goBack(2);
                        toast({
                            status: 'success',
                            title: t(tKeys.profile.user_profile.update_phone_number.success),
                        });
                    },
                });
            }
            catch (err) {
                console.error('Error with updating the phone number : ', err);
                toast({
                    status: 'error',
                    title: t(tKeys.profile.user_profile.mobile.error),
                });
            }
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: handleOnPressButton,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        handleOnPressButton,
        isUpdatePersonLoading,
        dirty,
        form,
    };
};
export default useLogic;
