import { useTranslation } from 'react-i18next';
import BaseModal from '../../../../../../../components/surfaces/modal/base-modal';
import { TouchableHighlight, View } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
import Flex from '../../../../../../../components/surfaces/flex';
import styles from './style';
import Icon from '../../../../../../../components/content/icon';
import Container from '../../../../../../../components/surfaces/container';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
const MoreModal = ({ isOpen, onDismiss, onDisableOffline, }) => {
    const { t } = useTranslation();
    return (<BaseModal visible={isOpen} onDismiss={onDismiss} swipeDirection={'down'} animationIn={'slideInUp'} animationOut={'slideOutDown'}>
      <View style={{
            borderTopStartRadius: 16,
            borderTopEndRadius: 16,
            paddingVertical: 40,
            paddingHorizontal: 12,
            backgroundColor: COLORS.White.T500.value,
        }}>
        <Flex direction="column" style={styles.mainContainer}>
          {/** Disable offline mode */}
          <TouchableHighlight onPress={onDisableOffline} underlayColor={COLORS.Tag.value} style={styles.pressableContainer}>
            <Flex>
              <Flex style={{
            height: 32,
            width: 32,
            backgroundColor: COLORS.IconsBg.value,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 8,
        }}>
                <Icon name="download-file" width={14} height={14} color={COLORS.Govrn.Blue.T500.value} fill={'transparent'}/>
              </Flex>
              <Container style={styles.textContainer}>
                <Text color={COLORS.Text.T400.value} font={FONTS.T1.T14Px.Medium500.value}>
                  {t(tKeys.document.moreModal.button.disable)}
                </Text>
              </Container>
            </Flex>
          </TouchableHighlight>
        </Flex>
      </View>
    </BaseModal>);
};
export default MoreModal;
