/* eslint-disable react/prop-types */
import React from 'react';
import { Linking } from 'react-native';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { tKeys } from '../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import truncateString from './utils';
const EventDetailsInformations = ({ eventTimezone, eventLocalization, eventUrl, eventShouldBeRecorded, eventWillBeRecorded, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render functions
    function EventUrlText() {
        return (<Box 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => await Linking.openURL(eventUrl)} cursor={'pointer'}>
        <Text color={COLORS.Blue.value} font={FONTS.T1.T14Px.Medium500.value} numberOfLines={3}>
          {truncateString(eventUrl, 40)}
        </Text>
      </Box>);
    }
    // Render
    return (<VStack width={'full'} borderRadius={'.5rem'} alignItems={'flex-start'} bgColor={COLORS.White.T500.value} borderWidth={1} borderColor={COLORS.Line.value} p={'1.25rem'} spacing={'1rem'}>
      <Text font={FONTS.T1.T14Px.SemiBold600.value}>
        {t(tKeys.calendar.event_details.tabs.informations)}
      </Text>
      <HStack width={'full'} alignItems={'flex-start'}>
        <VStack alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
          {/* Event localisation */}
          {eventLocalization ? (<HStack width={'full'}>
              <Icon name="map-pin-black" width={18} height={21} fill={'transparent'}/>
              <Text font={FONTS.T1.T14Px.Regular400.value}>
                {eventLocalization}
              </Text>
            </HStack>) : null}

          {/* Event timezone */}
          {eventTimezone ? (<HStack width={'full'}>
              <Icon name="clock-black" width={18} height={21}/>
              <Text font={FONTS.T1.T14Px.Regular400.value}>
                {eventTimezone}
              </Text>
            </HStack>) : null}
        </VStack>
        {eventUrl && (<VStack alignItems={'flex-start'} width={'full'} justify={'flex-start'} spacing={'1rem'}>
            {/* Event link */}
            <HStack width={'full'} alignItems={'flex-start'}>
              <Box minH={'1.125rem'} minW={'1.125rem'}>
                <Icon name="clip" width={18} height={18}/>
              </Box>
              <EventUrlText />
              {eventShouldBeRecorded ? (<Icon name="ai-assist" width={18} height={18} fill={!eventWillBeRecorded ? 'red' : null}/>) : null}
            </HStack>
          </VStack>)}
      </HStack>
    </VStack>);
};
export default EventDetailsInformations;
