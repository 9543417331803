import { HStack } from '@chakra-ui/react';
import Icon from '../../../../../components/content/icon';
import Text from '../../../../../components/content/text';
import React from 'react';
import COLORS from '../../../../../constants/colors';
export const EventDetailsInviteesPresenceTag = ({ iconName, onClick, iconColor, title, }) => {
    // Render
    return (<HStack onClick={onClick} px={'1rem'} py={'.5rem'} _hover={{ backgroundColor: COLORS.Tag.value }} cursor={'pointer'} width={'full'}>
      <Icon name={iconName} width={16} height={16} color={iconColor}/>
      <Text>{title}</Text>
    </HStack>);
};
