import React from 'react';
import Icon from '../../../../../../components/content/icon';
import COLORS from '../../../../../../constants/colors';
import { Box, Center, Image } from '@chakra-ui/react';
const DeletableImage = ({ imageURI, borderWidth, borderColor, borderRadius, hasDeleteButton = false, onPressDeleteButton, }) => {
    // Functions
    const renderDeleteButton = () => {
        if (!hasDeleteButton) {
            return <></>;
        }
        // Render the delete button on the image / document
        return (<Box onClick={onPressDeleteButton} bgColor={COLORS.Text.T500.value} borderRadius={'1rem'} position={'absolute'} top={1} right={1} p={'.25rem'}>
        <Center>
          <Icon name="close-cross" height={16} width={16} stroke={COLORS.White.T500.value}/>
        </Center>
      </Box>);
    };
    // Render
    return (<Box width={'fit-content'} height={'fit-content'} borderRadius={borderRadius} borderColor={borderColor} borderWidth={borderWidth} overflow={'hidden'} position={'relative'}>
      <Image src={imageURI} boxSize="6.4375rem" objectFit={'cover'}/>
      {renderDeleteButton()}
    </Box>);
};
export default DeletableImage;
