import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../components/content/list';
import Text from '../../components/content/text';
import SearchInput from '../../components/inputs/search-input';
import Container from '../../components/surfaces/container';
import LoadingAnimation from '../../components/utility/loading';
import { Size } from '../../components/utility/loading/size';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import useLogic from './logic.web';
import styles from './style.web';
import { StackDivider, VStack } from '@chakra-ui/react';
const SelectTimezone = ({ timezoneId, onChangeTimezoneId, }) => {
    const { isTimezoneDataLoading, searchValue, selectedTimezone, setSearchValue, timezones, handleOnChooseTimeZone, } = useLogic(timezoneId, onChangeTimezoneId);
    const { t } = useTranslation();
    const options = useMemo(() => {
        return timezones
            ?.filter(timezone => timezone?.displayName
            ?.toLocaleLowerCase()
            ?.includes(searchValue.toLocaleLowerCase()))
            .map(timezone => {
            return {
                id: timezone?.id,
                children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
              {timezone?.displayName}
            </Text>),
            };
        });
    }, [timezones, searchValue]);
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'} spacing={0} divider={<StackDivider />}>
      <SearchInput name={'search'} value={searchValue} onChange={setSearchValue} placeholder={t(tKeys.profile['user-profile']['select-timezone'].placeholder)} style={{
            width: '100%',
            backgroundColor: 'white',
        }}/>

      {isTimezoneDataLoading && (<Container alignSelf="center" margin={16}>
          <LoadingAnimation size={Size.COMPACT}/>
        </Container>)}
      <VStack alignItems={'flex-start'} width={'full'} maxH={'13.75rem'} maxWidth={'21.25rem'}>
        <List name={'id'} onChange={handleOnChooseTimeZone} value={selectedTimezone} options={options} style={styles.listContainer} testID={'timeZoneValues'} showSelectedItemFirst/>
      </VStack>
    </VStack>);
};
export default SelectTimezone;
