import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const FilterGroupBy = (props) => (<Svg viewBox="0 0 20 20" fill={props.fill ?? 'none'} {...props}>
    <Path d="M6.66669 15H17.5" stroke={props.stroke ?? '#8F95B2'} strokeWidth={1.66667} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M2.5 15H2.50833" stroke={props.stroke ?? '#8F95B2'} strokeWidth={1.66667} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M6.66669 10H17.5" stroke={props.stroke ?? '#8F95B2'} strokeWidth={1.66667} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M2.5 10H2.50833" stroke={props.stroke ?? '#8F95B2'} strokeWidth={1.66667} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M6.66669 5H17.5" stroke={props.stroke ?? '#8F95B2'} strokeWidth={1.66667} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M2.5 5H2.50833" stroke={props.stroke ?? '#8F95B2'} strokeWidth={1.66667} strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>);
export default FilterGroupBy;
