import { HStack, Stack, VStack, Collapse } from '@chakra-ui/react';
import React from 'react';
import COLORS from '../../../constants/colors';
import Icon from '../../../components/content/icon';
import Text from '../../../components/content/text/index.web';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { useBoardPackItemsLogic } from './logic.web';
const BoardpackItems = ({ agendaItem, onSelectedFile, selectedFile, parentIndexes, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isAccordionOpen, setAccordionOpen, displayIndexes } = useBoardPackItemsLogic(agendaItem, parentIndexes);
    // Render
    return (<VStack width={'100%'}>
      {/* Agenda item */}
      <VStack w="full" p="0.75rem" bgColor={COLORS.BG.value} rounded="0.5rem" border="0.0625rem" borderStyle="solid" borderColor={COLORS.Line.value} onClick={() => setAccordionOpen(prev => !prev)}>
        <HStack w="full" justifyContent="space-between" cursor="pointer">
          <VStack w="full" spacing="0" alignItems="flex-start">
            <HStack spacing={'.25rem'}>
              <Text font={FONTS.T1.T12Px.Medium500.value}>
                {displayIndexes}
              </Text>
              <Text font={FONTS.T1.T12Px.Medium500.value}>
                {agendaItem?.title}
              </Text>
            </HStack>
            <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText.value}>
              {t(tKeys.board_pack.informations, {
            duration: agendaItem.duration,
            count: agendaItem.documents?.length,
        })}
            </Text>
          </VStack>
          <Stack transform={isAccordionOpen ? 'rotate(180deg)' : ''}>
            <Icon name="chevron-down" width="1rem"/>
          </Stack>
        </HStack>
        <Collapse in={isAccordionOpen} style={{ width: '100%' }}>
          <VStack width={'100%'} spacing={'.5rem'}>
            {agendaItem?.documents?.map(document => {
            return (<HStack onClick={e => {
                    e.stopPropagation();
                    onSelectedFile(document);
                }} cursor="pointer" width="full" justifyContent="flex-start" key={document?.id} bgColor={selectedFile?.id === document.id
                    ? COLORS.Govrn.Blue.T100.value
                    : COLORS.White.T500.value} p={'.5rem'} borderWidth={1} borderRadius={'.25rem'} borderColor={selectedFile?.id === document.id
                    ? COLORS.Govrn.Blue.T500.value
                    : COLORS.Line.value}>
                  <Stack>
                    <Icon name="files" width="0.75rem"/>
                  </Stack>
                  <Text font={FONTS.T1.T12Px.Medium500.value}>
                    {document?.title}
                  </Text>
                </HStack>);
        })}
          </VStack>
        </Collapse>
      </VStack>
      {/* Sub agenda items */}
      {agendaItem?.subAgendaItems?.length > 0 && (<VStack width={'100%'} pl={'.75rem'}>
          {agendaItem.subAgendaItems.map((subAgendaItem, subIndex) => (<BoardpackItems agendaItem={subAgendaItem} onSelectedFile={onSelectedFile} key={subAgendaItem.id} selectedFile={selectedFile} parentIndexes={[...parentIndexes, subIndex + 1]}/>))}
        </VStack>)}
    </VStack>);
};
export default BoardpackItems;
