/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Tabs from '../../../../components/navigation/tabs';
import Tab from '../../../../components/navigation/tabs/tab';
import Container from '../../../../components/surfaces/container';
import Page from '../../../../components/surfaces/page';
import LoadingWrapper from '../../../../components/utility/loading-wrapper';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import MembersInvitationsList from '../components/list/member-invitation-list';
import MembersList from '../components/list/member-list/index.web';
import GroupsList from '../components/list/group-list/index.web';
import useLogic from './logic.web';
import styles from './style';
import { MembersTabs } from './types';
import { HStack, Stack } from '@chakra-ui/react';
import Search from '../../../search/index.web';
const Members = () => {
    // Attributes
    const { isEnrolmentsLoading, handleOnPressTab, selectedTab, setSelectedTab, handleOnPressMember, isAdmin, invitations, isInvitationsLoading, handleOnDeleteMemberInvitation, handleOnResendInvitation, currentUserId, refetchAfterInvite, handleEnrolmentUpdate, enrolments, groups, isGroupsLoading, refetchGroups, handleGroupDelete, handleShowMembersFilterChange, showMembersFilter, } = useLogic();
    const { t } = useTranslation();
    function TabsComponent() {
        switch (selectedTab) {
            case 0: {
                return (<MembersList enrolments={enrolments} isEnrolmentsLoading={isEnrolmentsLoading} handleOnPressMember={handleOnPressMember} isAdmin={isAdmin} onEnrolmentUpdate={handleEnrolmentUpdate} refetchAfterInvite={refetchAfterInvite} onTabPress={handleOnPressTab} onShowMembersFilterChange={handleShowMembersFilterChange} showMembersFilter={showMembersFilter}/>);
            }
            case 1: {
                return (<GroupsList groups={groups} isGroupsLoading={isGroupsLoading} onGroupDelete={handleGroupDelete} isAdmin={isAdmin} refetchGroups={refetchGroups}/>);
            }
            case 2: {
                return (<MembersInvitationsList invitations={invitations} isInvitationsLoading={isInvitationsLoading} handleOnDelete={handleOnDeleteMemberInvitation} handleOnResendInvitation={handleOnResendInvitation} currentUserId={currentUserId} isAdmin={isAdmin}/>);
            }
            default:
                return <></>;
        }
    }
    // Render
    return (<Page backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader style={{ zIndex: 900 }} justifyTitle={'flex-start'} endEnhancer={<HStack spacing={'1.25rem'} align="center">
            <Search />
          </HStack>}>
        {t(tKeys.tasks.create_task.assign_to.members)}
      </NavigationHeader>

      <Container style={styles.tabsContainer}>
        <Tabs activeKey={selectedTab} onChange={activeKey => handleOnPressTab(activeKey)}>
          <Tab title={t(tKeys.more.page.menu.members.menu_title)} onSelect={() => setSelectedTab(MembersTabs.Members)}/>
          <Tab title={t(tKeys.common.groups)} onSelect={() => setSelectedTab(MembersTabs.Groups)}/>
          {isAdmin && (<Tab title={t(tKeys.common.invitations)} onSelect={() => setSelectedTab(MembersTabs.Members)}/>)}
        </Tabs>
      </Container>

      <Stack height={'full'} width={'100%'} backgroundColor={COLORS.BG.value} overflow={'auto'}>
        <LoadingWrapper isLoading={isEnrolmentsLoading}>
          <TabsComponent />
        </LoadingWrapper>
      </Stack>
    </Page>);
};
export default Members;
