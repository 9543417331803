import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import Text from '../../../content/text';
import styles from './style.web';
import Image from '../../../content/image';
import Tag from '../../../content/tag';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import { boxShadow } from '../../../../styles/box-shadow-style';
const DocumentCardCompact = ({ title, eventDate, eventName, updateDate, onPress, titleNbOfLines, imageURI, }) => {
    const DateTag = () => {
        return (<Tag font={FONTS.T1.T10Px.Medium500.value} color={COLORS.Govrn.Blue.T500.value} height={21} backgroundColor={COLORS.IconsBg.value}>
        {`${t(tKeys.document.card.update.date)} ${moment(updateDate ?? eventDate).format('DD/MM/YYYY')}`}
      </Tag>);
    };
    // Render
    return (<HStack justifyContent="space-between" style={styles.mainContent} onClick={onPress} boxShadow={boxShadow} width={'100%'}>
      <HStack alignItems="flex-start" width={'100%'} spacing={'.5rem'}>
        <Stack width={'2.75rem'} height={'2.75rem'}>
          <Image borderRadius={4} imageURI={imageURI ?? require('../../../../assets/images/pdf_preview.png')} width={'100%'} height={'100%'} isLocalImage={!imageURI}/>
        </Stack>
        <VStack width="100%" spacing={'.25rem'} alignItems={'flex-start'}>
          {/* Document title */}
          <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Text.T400.value}>
            {title}
          </Text>
          <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value}>
            {eventName}
          </Text>
        </VStack>
      </HStack>

      {eventDate || updateDate ? (<Stack alignItems="flex-end" minWidth={'fit-content'}>
          {DateTag()}
        </Stack>) : null}
    </HStack>);
};
export default DocumentCardCompact;
