import React from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import useFormatDate from '../../../hooks/format-date/useFormatDate';
import { tKeys } from '../../../translations/keys';
import getUserName from '../../../utils/getUserName';
import Tag from '../../content/tag';
import Text from '../../content/text';
import useLogic from './logic';
import styles from './style';
import { Stack, HStack, VStack } from '@chakra-ui/react';
const TaskCard = ({ task, dueDate, eventId, tag, creatorId, assigneeId, agendatItemId, title, handleTaskPress, }) => {
    // Attributes
    const { t } = useTranslation();
    const { formatDate } = useFormatDate();
    const { creatorData, assigneeData, eventData, currentTitle, question } = useLogic({
        creatorId,
        assigneeId,
        eventId,
        agendatItemId,
        title,
    });
    // Function
    const getDataByTagType = () => {
        switch (tag) {
            case 1:
                return {
                    textColor: COLORS.Govrn.PastelGreen.T500.value,
                    backgroundColor: COLORS.Govrn.PastelGreen.T100.value,
                    textValue: t(tKeys.tasks.type.READ),
                    iconName: 'task-read',
                };
            case 2:
                return {
                    textColor: COLORS.Govrn.Pink.T500.value,
                    backgroundColor: COLORS.Govrn.Pink.T100.value,
                    textValue: t(tKeys.tasks.type.RSVP),
                    iconName: 'task-rsvp',
                };
            case 3:
                return {
                    textColor: COLORS.Govrn.Yellow.T500.value,
                    backgroundColor: COLORS.Govrn.Yellow.T100.value,
                    textValue: t(tKeys.tasks.type.PROXY),
                    iconName: 'task-proxy',
                };
            case 4:
                return {
                    textColor: '#AA55E3',
                    backgroundColor: '#E9D6F6',
                    textValue: t(tKeys.tasks.type.VOTES),
                    iconName: 'task-voting',
                };
            case 5:
                return {
                    textColor: COLORS.Warning.T500.value,
                    backgroundColor: COLORS.Warning.T100.value,
                    textValue: t(tKeys.tasks.type.SIGN),
                    iconName: 'task-sign',
                };
            case 6:
                return {
                    textColor: COLORS.Govrn.DarkBlue.T500.value,
                    backgroundColor: COLORS.Govrn.DarkBlue.T100.value,
                    textValue: t(tKeys.tasks.type.ACTION),
                    iconName: 'task-action',
                };
        }
    };
    // Render
    const renderTagByType = () => {
        const { backgroundColor, textColor, textValue } = getDataByTagType();
        return (<Tag backgroundColor={backgroundColor} color={textColor} height={24} font={FONTS.T1.T12Px.Medium500.value} style={styles.tag}>
        {textValue.toLocaleUpperCase()}
      </Tag>);
    };
    // Render
    return (<Stack onClick={() => handleTaskPress(task, question)} style={styles.mainContainer} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'} cursor={'pointer'}>
      <HStack width={'100%'} justifyContent="space-between">
        <VStack width={'100%'} alignItems={'flex-start'} spacing={'.25rem'}>
          {/* Title */}
          {currentTitle ? (<Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Text.T400.value}>
              {currentTitle}
            </Text>) : null}

          {/* SUBTITLE SECTION */}
          {(dueDate || eventData?.name) && (<Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value} numberOfLines={1}>
              {dueDate &&
                t(tKeys.tasks.card.dueDate, {
                    interpolation: { escapeValue: false },
                    date: formatDate({
                        date: dueDate,
                        formatWithTimezone: false,
                    }),
                })}
              {dueDate && eventData?.name && ` • ${eventData?.name}`}
            </Text>)}
        </VStack>

        {/* ASSIGNEES SECTION */}
        <HStack width={'full'} spacing={'.75rem'} align="center" justifyContent={'flex-end'}>
          {/* Creator name */}
          {creatorData && (<HStack align="center" spacing={'.25rem'} py={'.25rem'} px={'.5rem'} bgColor={COLORS.Tag.value} borderRadius={'1rem'}>
              <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText.value}>
                {`${t(tKeys.taskDetail.taskCard.createdBy)} `}
              </Text>
              <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Text.T400.value}>
                {getUserName(creatorData)}
              </Text>
            </HStack>)}

          {/* Assigne name */}
          <HStack align="center" spacing={'.25rem'} py={'.25rem'} px={'.5rem'} bgColor={COLORS.Tag.value} borderRadius={'1rem'}>
            <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText.value}>
              {`${t(tKeys.tasks.card.assignedTo)}`}
            </Text>
            <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Text.T400.value}>
              {getUserName(assigneeData)}
            </Text>
          </HStack>

          {/* Task type */}
          {renderTagByType()}
        </HStack>
      </HStack>
    </Stack>);
};
export default TaskCard;
