import moment from 'moment';
export var DateFormat;
(function (DateFormat) {
    DateFormat["FullFormat"] = "DD MMMM YYYY";
    DateFormat["ShortFormat"] = "DD MMM YYYY";
})(DateFormat || (DateFormat = {}));
// use moment format to format date with a format
export function formatDate(date, format, placeholder) {
    return date ? moment(date).format(format) : placeholder;
}
