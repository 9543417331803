import React from 'react';
import { Pressable, View } from 'react-native';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import Text from '../../../content/text';
import styles from './style';
const Tab = ({ children, onSelect, active, font = FONTS.T1.T14Px.SemiBold600.value, }) => {
    // Render
    return (<Pressable onPress={onSelect} style={styles.tabMainContainer}>
      <Text font={active
            ? FONTS.T1.T12Px.SemiBold600.value
            : FONTS.T1.T12Px.Medium500.value} color={active ? COLORS.Govrn.Blue.T500.value : COLORS.InputText.value}>
        {children}
      </Text>

      <View style={{ ...styles.activeBar, opacity: active ? 1 : 0 }}/>
    </Pressable>);
};
export default Tab;
