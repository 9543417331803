import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        marginHorizontal: 12,
    },
    textContainer: {
        margin: 12,
    },
    flatlist: {
        height: '100%',
    },
});
export default styles;
