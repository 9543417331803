import { useMemo } from 'react';
import { useCompaniesContext } from '../../../contexts/companies';
import { useReadAgendaItems, useReadEvent, } from '../../../services/online/calendar/calendar';
import { useReadMe, useReadPerson, } from '../../../services/online/people/people';
import getStaleTime from '../../../utils/getStaleTime';
const useLogic = ({ creatorId, assigneeId, eventId, agendatItemId, title }) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: currentUserData } = useReadMe();
    const { data: creatorData } = useReadPerson(creatorId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!creatorId && assigneeId !== currentUserData?.person?.id,
            staleTime: getStaleTime(),
        },
    });
    const { data: assigneeData } = useReadPerson(assigneeId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!assigneeId,
            staleTime: getStaleTime(),
        },
    });
    // eventId
    const { data: eventData } = useReadEvent(eventId, {
        query: {
            enabled: !!eventId,
            staleTime: getStaleTime(),
        },
    });
    // AgendaItem
    const { data: agendaItems } = useReadAgendaItems({ 'event-id': eventId }, { query: { enabled: !!eventId } });
    // Doing this because there is no getAgendaItemById in the back-end
    const agendaItem = useMemo(() => agendaItems?.find(i => i?.id === agendatItemId), [agendaItems]);
    const currentTitle = agendaItem?.title ?? title;
    // return
    return {
        creatorData,
        assigneeData,
        eventData,
        agendaItem,
        currentTitle,
        question: agendaItem?.question,
    };
};
export default useLogic;
