import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    flatList: {
        paddingHorizontal: 8,
        paddingTop: 8,
        backgroundColor: COLORS.BG.value,
        marginBottom: -16,
    },
    contentContainerStyle: {
        paddingBottom: 32,
        paddingTop: 8,
    },
    placeholder: {
        backgroundColor: COLORS.BG.value,
    },
    bottomContainer: {
        paddingHorizontal: 0,
    },
});
export default styles;
