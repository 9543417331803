import React, { useState } from 'react';
import AvatarDetails from '../../../../../../components/content/avatar-details';
import Text from '../../../../../../components/content/text';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import FieldItem from './field';
import styles from './style';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import Collapsible from 'react-native-collapsible';
import CalendarPicker from '../../../../../../components/pickers/calendar';
import FormControl from '../../../../../../components/inputs/form-control';
import useFormatDate from '../../../../../../hooks/format-date/useFormatDate';
import { HStack, StackDivider, VStack } from '@chakra-ui/react';
const TaskDetailGroupField = ({ form, isEditable, peopleInTheCompany, }) => {
    // Attributes
    const { formatDate } = useFormatDate();
    const [isCollapsed, setCollapsed] = useState(true);
    const [isAssignPeopleCollapsed, setIsAssignPeopleCollapsed] = useState(true);
    const { t } = useTranslation();
    // Return
    return (<VStack width={'full'} alignItems={'flex-start'} style={styles.mainContainer} divider={<StackDivider margin={'0!important'}/>}>
      {/* Created by field */}
      <FormControl error={form?.creatorId?.meta?.error}>
        <FieldItem label={t(tKeys.taskDetail.taskCard.createdBy)} isDisabled renderValue={<AvatarDetails avatarSize={'1.5rem'} personId={form?.creatorId?.value} titleColor={COLORS.InputText.value}/>}/>
      </FormControl>

      {/* Assigned to field */}
      <FormControl error={form?.assignedId?.meta?.error}>
        <FieldItem label={t(tKeys.common.assignedTo)} onPress={() => setIsAssignPeopleCollapsed(prev => !prev)} renderValue={<AvatarDetails avatarSize={'1.5rem'} personId={form?.assignedId?.value}/>}>
          <Collapsible collapsed={isAssignPeopleCollapsed} renderChildrenCollapsed={false}>
            <VStack alignItems={'flex-start'} width={'full'} px={'1.75rem'} py={'1.25rem'} spacing={'1rem'} maxHeight={'12rem'} overflow={'auto'} zIndex={1}>
              {!!peopleInTheCompany &&
            peopleInTheCompany.map(person => (<HStack justify="space-between" key={person.id} width={'full'} onClick={() => {
                    form?.assignedId?.onChange(person?.id);
                    setIsAssignPeopleCollapsed(prev => !prev);
                }} cursor={'pointer'}>
                    <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
                  </HStack>))}
            </VStack>
          </Collapsible>
        </FieldItem>
      </FormControl>

      {/* Created on field */}
      <FormControl error={form?.creationDate?.meta?.error}>
        <FieldItem label={t(tKeys.common.created_on)} isDisabled renderValue={<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText.value}>
              {formatDate({
                date: form.creationDate?.value,
                formatWithTimezone: false,
                format: 'DD MMMM yyyy',
            })}
            </Text>}/>
      </FormControl>

      {/* Due date field */}
      <FormControl error={form?.dueDate?.meta?.error}>
        <FieldItem label={t(tKeys.common.due_date)} renderValue={<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
              {formatDate({
                date: form.dueDate?.value,
                formatWithTimezone: false,
                format: 'DD MMMM yyyy',
            })}
            </Text>} onPress={() => setCollapsed(prev => !prev)} isDisabled={!isEditable}>
          {/* This error is normal since the component is not up to date to the last React Native children props. But it works like this soooo.... 👀 */}
          <Collapsible collapsed={isCollapsed} renderChildrenCollapsed={false}>
            <CalendarPicker {...form.dueDate} handlePress={() => setCollapsed(prev => !prev)} backgroundColor={COLORS.Tag.value}/>
          </Collapsible>
        </FieldItem>
      </FormControl>
    </VStack>);
};
export default TaskDetailGroupField;
