/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Text from '../../../../../../../components/content/text';
import { PressableType } from '../../../../../../../components/inputs/button/type';
import PressableWrapper from '../../../../../../../components/inputs/button/wrapper';
import Card from '../../../../../../../components/surfaces/card/index.web';
import Container from '../../../../../../../components/surfaces/container';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import NewsFeedPostModal from '../../modal/news-feed-modal/index.web';
import CardFooter from '../card-footer';
import CardHeader from '../card-header/index.web';
import { t } from 'i18next';
import Icon from '../../../../../../../components/content/icon';
import { Box, Stack, useDisclosure, Image, VStack } from '@chakra-ui/react';
import DocumentViewer from '../../../../../../document-viewer/index.web';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
const CardPostDocument = ({ currentUserId, itemPostOfDocument, index, styles, handleOnPressMessage, creator, isCreatorLoading, isPostLikedByUser, handleDeletePost, handleDismissModal, handleEditPost, handleIconPress, showPostModal, handleLike, isDeletePostLoading, onPressMemberName, footerSeparator, handleOnPostPress, isPostDetails, }) => {
    // Attributes
    const isPicture = itemPostOfDocument?.document?.file?.mimeType === 'jpeg' ||
        itemPostOfDocument?.document?.file?.mimeType === 'jpg' ||
        itemPostOfDocument?.document?.file?.mimeType === 'png' ||
        itemPostOfDocument?.document?.file?.mimeType !== 'application/pdf';
    const [imageExists, setImageExists] = useState(true);
    const { isOpen: isOpenDocumentViewer, onOpen: onOpenDocumentViewer, onClose: onCloseDocumentViewer, } = useDisclosure();
    const [document, setDocument] = useState();
    function handleOnClickDocument() {
        setDocument({
            documentUri: itemPostOfDocument?.document?.file?.uri,
            documentTitle: itemPostOfDocument?.document?.file?.filename,
            document: itemPostOfDocument?.document,
        });
        onOpenDocumentViewer();
    }
    useEffect(() => {
        const checkImageExists = async () => {
            try {
                const response = await fetch(itemPostOfDocument?.document?.file?.uri ??
                    itemPostOfDocument?.document?.file?.thumbnailUri);
                if (!response.ok) {
                    setImageExists(false);
                }
                // eslint-disable-next-line detox-bcp/no-unhandled-catch
            }
            catch (e) {
                setImageExists(false);
            }
        };
        void checkImageExists();
    }, [itemPostOfDocument?.document?.file]);
    // Functions
    const ImageComponent = ({ imageSrc }) => {
        if (!imageExists)
            return <></>;
        if (isPicture) {
            return (<Box alignSelf={'center'}>
          <Image borderRadius={'0.75rem'} src={itemPostOfDocument?.document?.file?.uri} width={'full'} maxHeight={'25rem'} objectFit="contain"/>
        </Box>);
        }
        return (<VStack width={'full'} alignItems={'flex-start'} justifyContent={'space-between'}>
        <Stack width={'full'} maxHeight={'25rem'} alignItems={'center'}>
          <Image src={imageSrc ??
                require('../../../../../../../assets/images/pdf_preview.png')} height={imageSrc ? '100%' : '20rem'} objectFit="contain"/>
        </Stack>

        <Stack bgColor={COLORS.Disabled.value} width={'full'} borderRadius={'.75rem'} height={'3.125rem'} padding={'1rem'} alignSelf={'flex-end'}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value}>
            {itemPostOfDocument?.document?.file.filename}
          </Text>
        </Stack>
      </VStack>);
    };
    if (isCreatorLoading) {
        return <></>;
    }
    // Render
    return (<Card isPostDetails={isPostDetails} onPress={() => handleOnPostPress(itemPostOfDocument?.id)} header={<CardHeader imageUri={creator?.picture?.uri} title={`${creator?.firstname ?? ''} ${creator?.lastname ?? ''}`} titleFont={FONTS.T1.T14Px.SemiBold600.value} subTitle={t(creator?.enrolments[0]?.boardRoleName)} imageHeight={33} imageWidth={33} onPressIcon={currentUserId === creator?.id ? handleIconPress : undefined} iconHeight={24} iconWidth={24} onPressMemberName={onPressMemberName} memberId={creator?.id} actionButton={currentUserId === creator?.id && (<PopOver from={<Icon name={'navigation-bar-more-default'} width={24} height={24}/>} isPortal={!isPostDetails}>
                <NewsFeedPostModal handleEditPost={handleEditPost} handleDeletePost={handleDeletePost} handleOnDismiss={handleDismissModal} isDeletePostLoading={isDeletePostLoading}/>
              </PopOver>)}/>} footer={<CardFooter date={new Date(itemPostOfDocument?.createdOn)} currentUserId={currentUserId} postId={itemPostOfDocument?.id} onPressMessage={handleOnPressMessage} isInsightfulSelected={isPostLikedByUser} handleLikePost={handleLike} footerSeparator={footerSeparator}/>}>
      <Container paddingHorizontal={12}>
        <Text numberOfLines={100}>{itemPostOfDocument?.content}</Text>
      </Container>

      <Container paddingTop={24} marginHorizontal={16}>
        {isPicture ? (<ImageComponent />) : (<PressableWrapper pressableType={PressableType.SCALE} onPress={handleOnClickDocument}>
            <Card backgroundColor={[
                COLORS.Govrn.Grey.T100.value,
                COLORS.White.T600.value,
            ]}>
              <ImageComponent imageSrc={itemPostOfDocument?.document?.file?.thumbnailUri}/>
            </Card>
          </PressableWrapper>)}
      </Container>
      {/* Modals */}
      <DocumentViewer selectedDocument={document} isOpen={isOpenDocumentViewer} onClose={onCloseDocumentViewer}/>
    </Card>);
};
export default CardPostDocument;
