import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    listContainer: {
        marginTop: 0,
        maxWidth: '21.25rem',
        minWidth: '21.25rem',
        width: '100%',
    },
});
export default styles;
