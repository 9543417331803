import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import styles from './style';
import SingleSelectItem from '../../../home/feed/components/pickers/single-select-item';
import TaskDetailsHeaderList from '../../components/content/task-details-header-list';
import useLogic from './logic';
import SearchInput from '../../../../components/inputs/search-input';
const CustomTaskDetailAssignTo = () => {
    // Attributes
    const { allPeopleInCompany, form, goBack, selectAssignPeople, selectedPerson, } = useLogic();
    const { t } = useTranslation();
    // Functions
    const renderItems = ({ item }) => {
        return (<SingleSelectItem item={item} onPressSelected={() => selectAssignPeople(item)}/>);
    };
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader onGoBack={goBack}>
        {t(tKeys.taskDetail.assignTo)}
      </NavigationHeader>
      <SearchInput {...form?.searchValue} placeholder={t(tKeys.common.search)} isClearable={form?.searchValue?.value?.length > 0} onPressClear={() => form?.searchValue?.onChange('')} testID={'manageEventSearchInput'}/>

      <FlatList data={allPeopleInCompany ?? []} renderItem={renderItems} showsVerticalScrollIndicator={false} contentContainerStyle={styles.flatlist} ListHeaderComponent={() => (<TaskDetailsHeaderList selectedPerson={selectedPerson}/>)}/>
    </Page>);
};
export default CustomTaskDetailAssignTo;
