// mocks/react-native-toast-message.js
import React from 'react';
// export const Toast = {
//   show: () => {},
//   hide: () => {},
// };
export const Toast = () => <div />;
Toast.show = () => {
    console.warn('Toast.show() ne fait rien sur le Web.');
};
Toast.hide = () => {
    console.warn('Toast.hide() ne fait rien sur le Web.');
};
export const ToastUI = props => <div {...props}/>;
export const BaseToast = props => <div {...props}/>;
export const ErrorToast = props => <BaseToast {...props}/>;
export const InfoToast = props => <BaseToast {...props}/>;
export const SuccessToast = props => <BaseToast {...props}/>;
// Add other exports from react-native-toast-message that you may need
