import React from 'react';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import InvitationlistItem from '../member-invitation-list-item';
import { Stack, VStack } from '@chakra-ui/react';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
const MembersInvitationsList = ({ invitations, isInvitationsLoading, handleOnDelete, handleOnResendInvitation, currentUserId, isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<LoadingWrapper isLoading={isInvitationsLoading}>
      <VStack height={'full'} width={'full'} alignItems={'flex-start'} px={'1.25rem'} pb={'1.25rem'} pt={'2rem'} spacing={'1rem'} overflow={'auto'}>
        <Stack pb={'.5rem'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.common.invitations)}
          </Text>
        </Stack>
        <VStack width={'full'} alignItems={'flex-start'} spacing={'.5rem'}>
          {invitations?.map(invitation => (<InvitationlistItem key={invitation?.id} item={invitation} currentUserId={currentUserId} handleOnDelete={() => handleOnDelete(invitation?.id)} handleOnResendInvitation={() => handleOnResendInvitation(invitation?.id)} isAdmin={isAdmin}/>))}
        </VStack>
      </VStack>
    </LoadingWrapper>);
};
export default MembersInvitationsList;
