import React from 'react';
import Modal from '../index.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../translations/keys';
import { Button, VStack } from '@chakra-ui/react';
import Text from '../../../content/text';
import FONTS from '../../../../constants/fonts';
import COLORS from '../../../../constants/colors';
export const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, title, isDeleteLoading, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Modal isOpen={isOpen} onClose={onClose} size={'3xl'} title={t(tKeys.delete_confirmation_modal.title)} hasFooter={false} hasCloseButton>
      <VStack height={'20rem'} width={'100%'} p={'2rem'} spacing={'2rem'} justifyContent={'center'}>
        <VStack width={'100%'} spacing={'1rem'} textAlign={'center'}>
          <Text font={FONTS.T1.T16Px.Medium500.value} numberOfLines={2}>
            {title}
          </Text>
          <Text font={FONTS.T1.T16Px.SemiBold600.value}>
            {t(tKeys.delete_confirmation_modal.subtitle)}
          </Text>
        </VStack>
        <Button bgColor={COLORS.Error.T500.value} textColor={COLORS.White.T500.value} px={'1rem'} py={'.75rem'} onClick={onDelete} isLoading={isDeleteLoading}>
          {t(tKeys.common.continue)}
        </Button>
      </VStack>
    </Modal>);
};
