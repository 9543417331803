import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    listHeaderContainer: {
        paddingTop: 12,
        paddingBottom: 24,
        alignItems: 'center',
    },
    separateLine: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.Line.value,
        height: 1,
        flex: 1,
    },
});
export default styles;
