import React from 'react';
import { countries } from '../../components/inputs/phone-input/assets/countries-flags-dials';
const useLogic = (country, onChooseCountry) => {
    // Attributes
    const [searchValue, setSearchValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    // Functions
    const onPress = (countryName) => {
        const selectedCountry = searchInCountries(countryName);
        // Update the form field
        onChooseCountry(selectedCountry[0]);
    };
    const onTextChange = (text) => {
        if (text !== undefined) {
            setSearchValue(text);
        }
    };
    const searchInCountries = (value) => {
        return countries?.filter(country => country?.name?.toLocaleLowerCase()?.includes(value.toLocaleLowerCase()));
    };
    // Return
    return {
        onTextChange,
        searchValue,
        setSearchValue,
        onPress,
        options,
        searchInCountries,
        setOptions,
    };
};
export default useLogic;
