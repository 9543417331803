import { HStack, Stack, VStack, chakra } from '@chakra-ui/react';
import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import COLORS from '../../../../../../constants/colors';
import { PublishPattern } from '../../../../../../components/content/icon/icons/publish-pattern/index';
import { renderToStaticMarkup } from 'react-dom/server';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
function PublishInformationsCardComponent() {
    // Atrributes
    const { t } = useTranslation();
    // Render
    return (<HStack width={'full'} background={`url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<PublishPattern />))}")`} backgroundColor={COLORS.Govrn.Blue.T600.value} p={'1.25rem'} borderRadius={'.5rem'}>
      <Stack alignSelf={'flex-start'}>
        <Icon name="publish"/>
      </Stack>
      <VStack width={'full'} alignItems={'flex-start'}>
        <Text font={FONTS.T1.T16Px.Bold700.value} color={COLORS.White.T500.value}>
          {t(tKeys.event.details.publish.title)}
        </Text>
        <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.White.T500.value} numberOfLines={3}>
          {t(tKeys.event.details.publish.subtitle)}
        </Text>
      </VStack>
    </HStack>);
}
export const PublishInformationsCard = chakra(PublishInformationsCardComponent);
