import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
    },
    textContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        marginLeft: 12,
    },
    pressableContainer: {
        paddingVertical: 12,
        paddingLeft: 12,
        borderRadius: 8,
    },
    iconDeleteContainer: {
        borderRadius: 8,
        backgroundColor: COLORS.Govrn.Blue.T100.value,
        width: 30,
        height: 30,
    },
});
export default styles;
