import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import COLORS from '../../../../../../constants/colors';
import Text from '../../../../../../components/content/text';
import { tKeys } from '../../../../../../translations/keys';
import { t } from 'i18next';
import { HStack } from '@chakra-ui/react';
import FONTS from '../../../../../../constants/fonts';
const ButtonAddMessage = ({ onPress, search, }) => {
    // Render
    return (<Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={onPress}>
      <HStack>
        <Icon name="navigation-bar-messages-default" width={20} height={20} fill={COLORS.White.T500.value}/>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} color="white">
          {t(tKeys.home.messages.navHeader.button.title)}
        </Text>
      </HStack>
    </Button>);
};
export default ButtonAddMessage;
