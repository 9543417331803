import { Textarea } from '@chakra-ui/react';
import { t } from 'i18next';
import React from 'react';
import Avatar from '../../../../components/content/avatar';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import Container from '../../../../components/surfaces/container';
import Flex from '../../../../components/surfaces/flex';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { Shape } from '../../../../constants/shape';
import { tKeys } from '../../../../translations/keys';
import getUserName from '../../../../utils/getUserName';
import CreatePostContent from '../components/content/create-post-content';
import ButtonCreatePost from '../components/inputs/button-create-post';
import AcceptLeavingModal from '../components/surfaces/modal/accept-leaving-modal';
import useLogic from './logic.web';
import styles from './styles.web';
const CreatePost = (props) => {
    // Attributes
    const { 
    // Current user data
    me, 
    // Handler
    onClickDelete, onAcceptLeaving, onCancelLeaving, isAcceptLeavingModalOpen, handleOnSubmit, handleAddFile, 
    // Form
    form, isCreatePostLoading, handlePollVisibility, } = useLogic(props);
    const ActionButtons = (<Flex justify={'center'} spacing={6}>
      <Flex>
        {/*  Choose a file in the storage button */}
        <ButtonCreatePost iconSize={16} fontTitle={FONTS.T1.T14Px.SemiBold600.value} title={t(tKeys.home.feed['create-post'].files)} iconName="files" handleOnPress={handleAddFile} isDisabled={form?.imageValue?.value || form.isPollVisible?.value}/>
      </Flex>
      <Flex>
        {/* Create a poll button */}
        <ButtonCreatePost iconSize={16} fontTitle={FONTS.T1.T14Px.SemiBold600.value} title={t(tKeys.home.feed['create-post'].poll)} iconName="poll" handleOnPress={handlePollVisibility} isDisabled={form?.imageValue?.value || form?.documentValue?.value}/>
      </Flex>
    </Flex>);
    // Render
    return (<>
      {/* Avatar and text */}
      <Flex direction="column" style={styles.mainContainer}>
        <Flex direction="row" style={styles.inputContainer} spacing={8}>
          <Avatar imageSource={me?.picture?.uri} borderRadius={6.2} height={31} width={31} placeholderFont={FONTS?.T1?.T12Px?.Bold700?.value} name={getUserName(me)} isOnline={undefined}/>
          <Textarea height={'100%'} width={'100%'} pl={'.25rem'} pt={'.125rem'} value={form?.message?.value} onChange={e => form?.message?.onChange(e?.currentTarget?.value)} placeholder={t(tKeys.common['write-your-message'])} autoFocus={false} borderColor={COLORS.BGFullscreen.value} minH={'4rem'} fontSize={'.95rem'} fontWeight={'400'} fontFamily={'Inter-Regular'}/>
        </Flex>

        {/* Render image, poll or document selected by the user */}
        <Container style={styles.componentsContainer}>
          <CreatePostContent form={form} handlePollVisibility={handlePollVisibility} onClickDelete={onClickDelete}/>
        </Container>
        <Flex direction="row" style={styles.buttonsContainer} align="center">
          <Flex align="flex-start">{ActionButtons}</Flex>
          <Flex align="flex-end" style={styles.actionButton}>
            <Button kind={Kind.PRIMARY} onPress={handleOnSubmit} shape={Shape.PILL} size={Size.DEFAULT} isDisabled={!form?.message?.value || isCreatePostLoading} isLoading={isCreatePostLoading}>
              <Text color={!form?.message?.value || isCreatePostLoading
            ? COLORS.InputText.value
            : COLORS.White.T500.value} font={FONTS.T1.T14Px.SemiBold600.value}>
                {t(tKeys.taskDetail.post)}
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>

      {/* Modals */}
      <AcceptLeavingModal onAccept={onAcceptLeaving} visible={isAcceptLeavingModalOpen} onDismiss={onCancelLeaving}/>
    </>);
};
export default CreatePost;
