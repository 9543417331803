import { Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import Icon from '../../../components/content/icon';
import Text from '../../../components/content/text';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Page from '../../../components/surfaces/page';
import RenderMenu from '../../../components/utils/render-menu';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import getUserName from '../../../utils/getUserName';
import Search from '../../search/index.web';
import ProfileHeader from '../components/navigation/profile-header';
import useLogic from './logic.web';
const UserProfile = () => {
    // Attributes
    const { handleNavigateTo, me, t, isUpdatePersonLoading, handleOpenFilePicker, handleSignOut, } = useLogic();
    const menusItems = [
        {
            items: [
                {
                    startEnhancer: (<Icon name={'profile'} width={36} height={36} testID={'userProfileMyInformationMenu'}/>),
                    title: t(tKeys.profile['user-profile']['my-information']),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/company/profile/user-profile/my-informations'),
                },
            ],
        },
        {
            items: [
                {
                    startEnhancer: (<Icon name={'messages'} width={36} height={36} testID={'userProfileEmailAddressMenu'} fill={'transparent'}/>),
                    title: t(tKeys.profile['user-profile']['email-address'].title),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/company/profile/user-profile/email-address'),
                },
            ],
        },
        {
            items: [
                {
                    startEnhancer: (<Icon name={'mobile-phone'} width={36} height={36} testID={'userProfileMobilePhoneMenu'} fill={'transparent'}/>),
                    title: t(tKeys.profile['user-profile']['mobile-phone'].title),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/company/profile/user-profile/mobile-phone'),
                },
            ],
        },
        {
            items: [
                {
                    startEnhancer: (<Icon name={'password'} width={36} height={36} testID={'userProfilePasswordMenu'}/>),
                    title: t(tKeys.common.password.label),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/company/profile/user-profile/change-password'),
                },
            ],
        },
        {
            items: [
                {
                    startEnhancer: (<Icon name={'notification'} width={36} height={36} testID={'userProfileNotificationsMenu'}/>),
                    title: t(tKeys.profile['user-profile'].notification.title),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/company/profile/user-profile/notifications'),
                },
            ],
        },
        {
            items: [
                {
                    startEnhancer: (<Icon name={'sign-out'} width={36} height={36} testID={'userProfileNotificationsMenu'}/>),
                    title: t(tKeys.profile['user-profile'].logout.title),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: handleSignOut,
                },
            ],
        },
    ];
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader endEnhancer={<Search />}>
        {t(tKeys.profile['user-profile']['my-information'])}
      </NavigationHeader>

      <VStack width={'full'} alignItems={'flex-start'} p={'1rem'} height={'full'} bgColor={COLORS.BG.value} overflow={'auto'} spacing={'.75rem'}>
        <ProfileHeader profileImageUri={me?.picture?.uri} profileName={getUserName(me)} isUpdatePersonLoading={isUpdatePersonLoading} onPressPicture={handleOpenFilePicker}>
          <Stack pt={'.75rem'}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {getUserName(me)}
            </Text>
          </Stack>
        </ProfileHeader>

        <RenderMenu menuItems={menusItems}/>
      </VStack>
    </Page>);
};
export default UserProfile;
