import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
import CUSTOM_SHADOWS from '../../../../utils/shadows';
const styles = StyleSheet.create({
    mainContent: {
        borderRadius: 12,
        width: '100%',
        height: '100%',
        ...CUSTOM_SHADOWS.FormControlContainer,
        minWidth: 257,
    },
    imageContainer: {
        height: 144,
        backgroundColor: COLORS.Line.value,
        width: '100%',
        overflow: 'hidden',
        borderTopRightRadius: 12,
        borderTopLeftRadius: 12,
    },
    innerImageContainer: {
        width: '100%',
        height: '100%',
        minHeight: '9rem',
        minWidth: '16.125rem',
        justifyContent: 'center',
    },
    footerContainer: {
        height: '100%',
        backgroundColor: COLORS.White.T500.value,
        borderBottomEndRadius: 12,
        borderBottomStartRadius: 12,
        padding: 12,
        maxWidth: 257,
    },
});
export default styles;
