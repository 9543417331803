import React from 'react';
import Icon from '../../components/content/icon';
import LoadingWrapper from '../../components/utility/loading-wrapper';
import COLORS from '../../constants/colors';
import useLogic from './logic.web';
import { DocumentKindEnum } from './type';
import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, HStack, Stack, VStack, } from '@chakra-ui/react';
import DocumentViewer from '../document-viewer/index.web';
import SignDocument from '../sign-document/index.web';
import DocumentEdit from '../document-edit/index.web';
import ShareInMessages from '../share-in-messages/index.web';
import WebView from '../web-view/index.web';
import Page from '../../components/surfaces/page/index.web';
import NavigationHeader from '../../components/navigation/navigation-header/index.web';
import { tKeys } from '../../translations/keys';
import { DocumentPreview } from '../document-viewer/document-preview/index.web';
import { DocumentDetailsHeader } from './components/content/header/index.web';
import { DocumentDetailsMainInformations } from './components/content/main-informations/index.web';
import Text from '../../components/content/text';
import FONTS from '../../constants/fonts';
import DocumentSectionSignedBy from './components/content/signed-by-section/index.web';
import { DocumentDetailsPermissions } from './document-details-permissions/index.web';
const DocumentDetails = () => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { isLoading, isDownloading, documentEntity, documentHistory, userCanEditDocument, handleOnPressEditDocument, isUserMustSignDocument, handleSignDocument, isUserAlreadySigned, onFullScreenPress, isOpenDocumentViewer, onCloseDocumentViewer, selectedDocument, isOpenSignDocument, onCloseSignDocument, documentToEdit, isOpenDocumentEdit, onCloseDocumentEdit, refetchDocument, handleOnCloseShareInMessage, handleOnPressMessage, isOpenShareInMessage, shareInMessageEntity, fetchRedirectLink, handleOnClickSign, isOpenWebView, onCloseShareInMessage, handleOnDownloadPress, handleGoBack, isDeleteDocumentLoading, handleOnPressDelete, documentDetails, currentDocumentType, creator, event, handleAddSigners, isUpdateDocumentLoading, documentCanHaveSigners, searchedEnrolments, searchSigner, handleSetSearch, handleCloseWebView, handleSetWebviewerInstance, handleChooseVersion, currentDocumentVersionId, handleClickNewFile, selectedTemplate, isPDF, isPermissionsLoading, permissions, permissionsDisclosure, refetchThePermissions, isAddSignersLoading, } = useLogic();
    // Render
    return (<Page backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader startEnhancer={<ChakraButton p={'.25rem'} bgColor={COLORS.Tag.value} borderRadius={'.5rem'} onClick={handleGoBack}>
            <Icon name="chevron-left-default" height={15} width={15}/>
          </ChakraButton>} endEnhancer={<DocumentDetailsHeader 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleDownloadPress={handleOnDownloadPress} handleOnPressDelete={handleOnPressDelete} handleOnPressEditDocument={handleOnPressEditDocument} handleOnPressMessage={handleOnPressMessage} isDeleteDocumentLoading={isDeleteDocumentLoading} isDownloadable={documentEntity?.isDownloadable} isTemplate={documentEntity?.kind === DocumentKindEnum.TEMPLATE} isDownloading={isDownloading} userCanEditDocument={userCanEditDocument} isOriginalFileDownloadable={documentDetails?.isOriginalFileDownloadAllowed} isPDF={isPDF} handleDocumentPermissionsOpen={permissionsDisclosure.onOpen}/>}>
        {t(tKeys.more.page.menu.repository.header.title)}
      </NavigationHeader>
      <LoadingWrapper isLoading={isLoading}>
        <HStack width={'full'} spacing={0} overflow={'auto'}>
          <VStack width={'25%'} alignItems={'flex-start'} p={'1.25rem'} height={'full'} borderRightWidth={1} spacing={'2rem'}>
            <DocumentDetailsMainInformations document={documentDetails ?? selectedTemplate} documentType={currentDocumentType} creator={creator} linkedEvent={event} documentHistory={documentHistory} onChooseVersion={handleChooseVersion} currentSelectedVersionId={currentDocumentVersionId} onClickUploadNewVersion={handleClickNewFile} isTemplate={documentEntity?.kind === DocumentKindEnum.TEMPLATE}/>
            {documentCanHaveSigners &&
            documentEntity?.kind !== DocumentKindEnum.TEMPLATE && (<DocumentSectionSignedBy documentEntity={documentEntity} isUserAlreadySigned={isUserAlreadySigned} peopleInTheCompany={searchedEnrolments?.items} isUpdateDocumentLoading={isAddSignersLoading} 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAddSigners={handleAddSigners} userCanEditDocument={userCanEditDocument} search={searchSigner} setSearch={handleSetSearch}/>)}
          </VStack>
          {/* Document Previewer */}
          <DocumentPreview selectedDocument={selectedDocument} isSmallPreview width={'50%'} onFullScreenPress={onFullScreenPress} onClickSign={handleSignDocument} isUserMustSignDocument={isUserMustSignDocument} setPDFtronInstance={handleSetWebviewerInstance} selectedTemplate={selectedTemplate}/>
          {/* New feature */}
          <Stack width={'25%'} height={'full'} borderLeftWidth={1}>
            <VStack width={'full'} p={'2.5rem'} height={'full'} justify={'center'}>
              <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Text.T300.value}>
                {t(tKeys.common.coming_soon)}
              </Text>
              <Text font={FONTS.T1.T14Px.Bold.value} color={COLORS.Govrn.Blue.T500.value} numberOfLines={4} style={{ textAlign: 'center' }}>
                {t(tKeys.document.details.history.coming_soon)}
              </Text>
            </VStack>
          </Stack>
        </HStack>
      </LoadingWrapper>

      {/* Modal */}
      <DocumentViewer isOpen={isOpenDocumentViewer} onClose={onCloseDocumentViewer} selectedDocument={selectedDocument} setPDFtronInstance={handleSetWebviewerInstance}/>
      <SignDocument isOpen={isOpenSignDocument} onClose={onCloseSignDocument} onClickSign={handleOnClickSign}/>
      <DocumentEdit isOpen={isOpenDocumentEdit} onClose={onCloseDocumentEdit} onCloseDetails={handleGoBack} editDocumentEntity={documentToEdit} refetchDocument={refetchDocument}/>
      <ShareInMessages isOpen={isOpenShareInMessage} onCloseCreateMessage={handleOnCloseShareInMessage} onClose={onCloseShareInMessage} shareInMessageEntity={shareInMessageEntity}/>
      <WebView isOpen={isOpenWebView} onClose={handleCloseWebView} uri={fetchRedirectLink()}/>
      <DocumentDetailsPermissions documentId={documentDetails?.id} isOpen={permissionsDisclosure.isOpen} onClose={permissionsDisclosure.onClose} isPermissionsLoading={isPermissionsLoading} permissions={permissions} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    refetchPermissions={refetchThePermissions}/>
    </Page>);
};
export default DocumentDetails;
