import { useCompaniesContext } from '../../contexts/companies';
import useRouter from '../../hooks/router/useRouter';
import { useReadCompany } from '../../services/online/companies/companies';
import { useReadPost } from '../../services/online/news-feed/news-feed';
import { useReadMe } from '../../services/online/people/people';
import getStaleTime from '../../utils/getStaleTime';
import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = (postId, onClose) => {
    // Attributes
    const { push } = useRouter();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [shareInMessageEntity, setShareInMessageEntity] = useState();
    const { isOpen: isOpenShareInMessage, onOpen: onOpenShareInMessage, onClose: onCloseShareInMessages, } = useDisclosure();
    // Queries
    const { data: currentUser } = useReadMe({
        query: { staleTime: getStaleTime() },
    });
    const { data: companyData } = useReadCompany(selectedCompanyId, {
        query: { enabled: !!selectedCompanyId },
    });
    const { data: post, isLoading } = useReadPost(postId, {
        query: { enabled: !!postId },
    });
    function handleOnPressMember(memberId) {
        push('/profile/other-user-profile', {
            personId: memberId,
        });
    }
    function handleOnPressMessage(post, postType) {
        const params = {
            data: post,
            type: postType,
            isPost: true,
        };
        setShareInMessageEntity(params);
        onOpenShareInMessage();
    }
    function handleOnCloseCreateMessage() {
        onCloseShareInMessages();
        onClose();
    }
    // Return
    return {
        post,
        currentUser,
        handleOnPressMember,
        handleOnPressMessage,
        companyData,
        selectedEnrolment,
        isLoading,
        shareInMessageEntity,
        isOpenShareInMessage,
        onCloseShareInMessages,
        handleOnCloseCreateMessage,
    };
};
export default useLogic;
