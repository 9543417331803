import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';
const Editable = (props) => (<Svg viewBox="0 0 18 18" accessibilityRole="image" {...props}>
    <G clipPath="url(#a)">
      <Path d="m10.689 3.561-8.485 8.485a.333.333 0 0 0-.095.189l-.546 3.825a.333.333 0 0 0 .377.377l3.825-.546a.333.333 0 0 0 .189-.095l8.523-8.523m-3.788-3.712 1.825-1.825c.13-.13.341-.13.472 0l3.278 3.278c.13.13.13.342 0 .472l-1.787 1.787m-3.788-3.712 3.788 3.712" stroke={props.color ?? '#393360'} strokeWidth={1.8}/>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h18v18H0z"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default Editable;
