import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../../components/content/text';
import Button from '../../../../../../../components/inputs/button';
import { Kind } from '../../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../../components/inputs/button/constants/size';
import Flex from '../../../../../../../components/surfaces/flex';
import BaseModal from '../../../../../../../components/surfaces/modal/base-modal';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { Shape } from '../../../../../../../constants/shape';
import { tKeys } from '../../../../../../../translations/keys';
import useLogic from './logic';
import styles from './styles';
const AcceptLeavingModal = (props) => {
    // Attributes
    const { visible } = props;
    const { t } = useTranslation();
    // Logic
    const { onLeavePress, onCancelPress } = useLogic(props);
    // Return
    return (<BaseModal visible={visible} onDismiss={onCancelPress} animationIn={'slideInUp'} animationOut={'slideOutDown'} style={{}}>
      <Flex direction="column" justify="center" align="center" spacing={8} style={styles.container}>
        {/* Header */}
        <Text font={FONTS.T1.T16Px.SemiBold600.value} color={COLORS.Text.T400.value}>
          {t(tKeys.accept_leaving.title)}
        </Text>

        <Flex direction="column" spacing={24}>
          {/* Content */}
          <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.Text.T400.value} numberOfLines={0} style={styles.content}>
            {t(tKeys.accept_leaving.content)}
          </Text>

          {/* Footer */}
          <Flex direction="column" spacing={8}>
            <Button kind={Kind.PRIMARY} shape={Shape.DEFAULT} size={Size.DEFAULT} backgroundColorPerso={COLORS.Error.T500.value} onPress={onLeavePress}>
              {t(tKeys.accept_leaving.cta.leave)}
            </Button>
            <Button kind={Kind.WHITE} shape={Shape.DEFAULT} size={Size.DEFAULT} onPress={onCancelPress} textColor={COLORS.InputText.value}>
              {t(tKeys.accept_leaving.cta.cancel)}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </BaseModal>);
};
export default AcceptLeavingModal;
