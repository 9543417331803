import React from 'react';
import { Td, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../../translations/keys';
import COLORS from '../../../../../../../constants/colors';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
export const GroupListTableHeader = ({ isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Thead position={'sticky'} top={0} zIndex={2} backgroundColor={COLORS.White.T500.value}>
      <Tr>
        {/* Name */}
        <Td pl={'.75rem'} pr={'.5rem'} py={'.75rem'} width={'50%'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.name.label)}
          </Text>
        </Td>
        {/* # Members */}
        <Td px={'.5rem'} py={'.75rem'} width={'50%'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common['#_members'])}
          </Text>
        </Td>
        {/* Menu */}
        {isAdmin && (<Td pr={'.75rem'} pl={'.5rem'} py={'.75rem'} width={'4rem'}></Td>)}
      </Tr>
    </Thead>);
};
