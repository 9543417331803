import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    list: {
        height: ' 100%',
    },
    flatlistContent: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        height: '100%',
    },
    subTextContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 8,
    },
    placeholder: { paddingTop: '50%' },
    mainContainer: {
        flex: 1,
        backgroundColor: COLORS.BG.value,
        paddingHorizontal: 32,
        paddingTop: 32,
    },
    postContainer: {
        maxWidth: 750,
        width: '100%',
        height: '100%',
    },
    feedListContainer: {
        maxWidth: 750,
        width: '100%',
        height: '100%',
    },
    eventCardContainer: {
        maxWidth: 400,
        width: '100%',
        position: 'absolute',
        right: 32,
        top: 32,
    },
});
export default styles;
