import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../components/content/list';
import Text from '../../components/content/text';
import NavigationHeader from '../../components/navigation/navigation-header';
import Page from '../../components/surfaces/page';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import useLogic from './logic';
import styles from './styles';
const SelectApplicationLanguage = () => {
    // Attributes
    const { languages, handleOnPressBackButton, selectedLanguage, handleOnPress, } = useLogic();
    const { t } = useTranslation();
    const options = useMemo(() => languages?.map(language => {
        return {
            id: language?.value,
            children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
              {language?.label}
            </Text>),
        };
    }), [languages]);
    // Render
    return (<Page backgroundColor={COLORS.White.T500.value} testID={'selectLanguagePage'}>
      <NavigationHeader onGoBack={handleOnPressBackButton} divider testID="languagePageGoBackButton">
        {t(tKeys.profile['user-profile']['select-language'])}
      </NavigationHeader>
      <List name={'id'} value={selectedLanguage} onChange={handleOnPress} options={options} testID={'languageValue'} style={styles.listContainer}/>
    </Page>);
};
export default SelectApplicationLanguage;
