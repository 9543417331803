/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterIndicator from '../../../components/content/filter-indicator';
import Text from '../../../components/content/text';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Container from '../../../components/surfaces/container';
import Flex from '../../../components/surfaces/flex';
import Page from '../../../components/surfaces/page';
import PopOver from '../../../components/surfaces/pop-over/index.web';
import LoadingWrapper from '../../../components/utility/loading-wrapper';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import Search from '../../search/index.web';
import ShareInMessages from '../../share-in-messages/index.web';
import NewsFeedList from './components/content/news-feed-list/index.web';
import Filter from './filter/index.web';
import useLogic from './logic.web';
import styles from './style';
const Feed = () => {
    // Attributes
    const { selectedEnrolment, companyData, sideNavigationVisible, newsFeedData, refetchNewsFeedData, fetchNextPageNewsFeedData, isNewsFeedDataFetching, isNewsFeedDataLoading, currentPageNewsFeed, getPostType, me, markPostAsRead, activeFiltersNumber, handleOnPressMessage, isReadMeLoading, isReadCompanyLoading, form, values, onResetForm, isOpenShareInMessage, onCloseShareInMessage, shareInMessageEntity, isOpenFilter, onToggleFilter, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page showSideBar barStyle="dark-content" backgroundColor={COLORS.White.T500.value} testID={'feedPage'} hideStatusBar={sideNavigationVisible}>
      <NavigationHeader justifyTitle="flex-start" endEnhancer={<Flex spacing={20} align="center">
            <PopOver placement="bottom-end" from={<FilterIndicator filters={activeFiltersNumber}/>} isOpen={isOpenFilter} onToggle={onToggleFilter}>
              <Filter form={form} values={values} resetForm={onResetForm}/>
            </PopOver>
            <Search />
          </Flex>}>
        <Text font={FONTS.T1.T16Px.Bold700.value} color={COLORS.Text.T400.value}>
          {t(tKeys.page.menu['news-feed'])}
        </Text>
      </NavigationHeader>

      <LoadingWrapper isLoading={isReadMeLoading || isReadCompanyLoading || isNewsFeedDataLoading}>
        <Container style={styles.mainContainer}>
          <NewsFeedList me={me} companyData={companyData} currentPageNewsFeed={currentPageNewsFeed} fetchNextPageNewsFeedData={fetchNextPageNewsFeedData} getPostType={getPostType} handleOnPressMessage={handleOnPressMessage} isNewsFeedDataFetching={isNewsFeedDataFetching} isNewsFeedDataLoading={isNewsFeedDataLoading &&
            !(isReadMeLoading || isReadCompanyLoading)} markPostAsRead={markPostAsRead} newsFeedData={newsFeedData} refetchNewsFeedData={refetchNewsFeedData} selectedEnrolment={selectedEnrolment} activeFiltersNumber={activeFiltersNumber}/>
        </Container>
      </LoadingWrapper>
      {/* Modals */}
      <ShareInMessages isOpen={isOpenShareInMessage} onClose={onCloseShareInMessage} onCloseCreateMessage={onCloseShareInMessage} shareInMessageEntity={shareInMessageEntity}/>
    </Page>);
};
export default Feed;
