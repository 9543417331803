import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
const NavigationBarCalendarWhite = (props) => (<Svg width={props.width || '100%'} height={props.height || '100%'} viewBox="0 0 16 16" fill="none" {...props}>
    <Path fill="#fff" d="M2.737 3.74h10.169v9.633H2.737z"/>
    <Path fill="#fff" d="M8 12.08a.58.58 0 1 0 0-1.159.58.58 0 0 0 0 1.16Zm2.899 0a.58.58 0 1 0 0-1.16.58.58 0 0 0 0 1.16Zm0-2.318a.58.58 0 1 0 0-1.16.58.58 0 0 0 0 1.16ZM8 9.762a.58.58 0 1 0 0-1.16.58.58 0 0 0 0 1.16Zm4.059-6.958h-.58v-.58a.58.58 0 1 0-1.16 0v.58H5.681v-.58c-.072.435-.133.287-.242.179a.58.58 0 0 0-.82 0c-.109.108-.17.256-.098-.179v.58h-.58a1.74 1.74 0 0 0-1.739 1.74v8.117a1.74 1.74 0 0 0 1.74 1.739h8.117a1.74 1.74 0 0 0 1.739-1.74V4.545a1.74 1.74 0 0 0-1.74-1.74Zm.58 9.857a.58.58 0 0 1-.58.58H3.941a.58.58 0 0 1-.58-.58V7.443h9.276v5.218Zm0-6.378H3.361v-1.74a.58.58 0 0 1 .58-.58h.58v.58a.58.58 0 1 0 1.159 0v-.58h4.638v.58a.58.58 0 1 0 1.16 0v-.58h.58a.58.58 0 0 1 .58.58v1.74ZM5.1 9.762a.58.58 0 1 0 0-1.16.58.58 0 0 0 0 1.16Zm0 2.319a.58.58 0 1 0 0-1.16.58.58 0 0 0 0 1.16Z"/>
    <Path fill="#0B1F2C" d="M8.322 11.983a.58.58 0 1 1-.644-.964.58.58 0 0 1 .644.964ZM11.22 11.983a.58.58 0 1 1-.644-.965.58.58 0 0 1 .645.965ZM11.22 9.664a.58.58 0 1 1-.644-.965.58.58 0 0 1 .645.965ZM8.322 9.664a.58.58 0 1 1-.644-.964.58.58 0 0 1 .644.964ZM5.423 9.664a.58.58 0 1 1-.644-.964.58.58 0 0 1 .644.964ZM5.423 11.983a.58.58 0 1 1-.644-.964.58.58 0 0 1 .644.964Z"/>
    <Rect width={1.91} height={3.784} x={9.944} y={1.314} fill="#fff" stroke="#0B1F2C" strokeWidth={0.572} rx={0.955}/>
    <Rect width={1.91} height={3.784} x={4.057} y={1.314} fill="#fff" stroke="#0B1F2C" strokeWidth={0.572} rx={0.955}/>
  </Svg>);
export default NavigationBarCalendarWhite;
