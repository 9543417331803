import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    startEnhancerContainer: {},
    titleContainer: {
        flex: 1,
        flexGrow: 1,
    },
    endEnhancerContainer: {
        marginRight: 12,
    },
});
export default styles;
