import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, View } from 'react-native';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import FormControl from '../../../../components/inputs/form-control';
import InputTransparent from '../../../../components/inputs/transparent-input';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Flex from '../../../../components/surfaces/flex';
import Page from '../../../../components/surfaces/page';
import KeyboardAvoidingViewWrapper from '../../../../components/surfaces/page/wrapper';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import MultiSelectItem from '../../feed/components/pickers/multi-select-item';
import useLogic from './logic';
import styles from './style';
const CreateGroupChannel = () => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { goBack, form, allCompanyMembers, isMemberSelected, onSelectMember, isCreateChannelLoading, handleOnSubmit, } = useLogic();
    // Render ListHeaderComponent
    function ListHeaderComponent() {
        return (<Flex direction="column" style={styles.listHeader} spacing={24}>
        <FormControl label={t(tKeys.messages.groupName.label)} error={form?.groupName?.meta?.error}>
          <InputTransparent {...form.groupName} placeholder={t(tKeys.messages.groupName.placeholder)}/>
        </FormControl>

        <FormControl label={t(tKeys.more.page.menu.members.menu_title)} error={form?.participantIds?.meta?.error}/>
      </Flex>);
    }
    // Render
    return (<Page barStyle="default" backgroundColor={COLORS.White.T500.value}>
      {/* Header */}
      <NavigationHeader divider onGoBack={goBack} endEnhancer={<Button kind={Kind.WHITE} size={Size.MINI} onPress={handleOnSubmit} isLoading={isCreateChannelLoading}>
            {t(tKeys.common.create)}
          </Button>}>
        {t(tKeys.messages.createNewGroup.title)}
      </NavigationHeader>

      <KeyboardAvoidingViewWrapper>
        <FlatList style={styles.flatlist} contentContainerStyle={styles.contentContainerStyle} keyExtractor={key => key?.id} showsVerticalScrollIndicator={false} data={allCompanyMembers} ListHeaderComponent={ListHeaderComponent()} keyboardShouldPersistTaps="handled" renderItem={({ item }) => (<MultiSelectItem item={item} showStatus isSelected={isMemberSelected(item)} onPressSelected={() => onSelectMember(item)}/>)} ItemSeparatorComponent={() => <View style={styles.separator}/>}/>
      </KeyboardAvoidingViewWrapper>
    </Page>);
};
export default CreateGroupChannel;
