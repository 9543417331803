import { useCompaniesContext } from '../../../../contexts/companies';
import { getReadDocumentsQueryKey, useCreateDocument, useDeleteDocument, useReadDocumentTypes, useReadDocuments, } from '../../../../services/online/repository/repository';
import { DocumentKindEnum, } from '../../../document-details/type';
import useRouter from '../../../../hooks/router/useRouter.web';
import { useQueryClient } from '@tanstack/react-query';
import { getReadAgendaItemsQueryKey } from '../../../../services/online/calendar/calendar';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../translations/keys';
import { Toast } from '../../../../components/content/use-toast/toast/index.web';
export const useAgendaItemDocumentsLogic = (agendaItemId, isOpen, onClose, event) => {
    // Attributes
    const { t } = useTranslation();
    const { me } = useCompaniesContext();
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const queryClient = useQueryClient();
    // Queries
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId && isOpen,
        },
    });
    const defaultDocumentType = documentTypes?.find(documentType => documentType.name === '_FT_BOARDAPPE')?.id;
    const { data: agendaItemDocuments, isLoading: isAgendaItemDocumentsLoading, refetch: refetchAgendaItemDocuments, } = useReadDocuments({
        'company-id': selectedCompanyId,
        'document-types': documentTypes?.map(d => d.id),
        'agenda-item-id': agendaItemId,
        'event-id': event.id,
    }, {
        query: {
            enabled: !!selectedCompanyId && !!documentTypes && isOpen,
        },
    });
    const { mutateAsync: createRepositoryDocument, isLoading: isCreateDocumentLoading, } = useCreateDocument();
    const { mutateAsync: deleteDocument, isLoading: isDeleteDocumentLoading } = useDeleteDocument();
    const documents = agendaItemDocuments?.flatMap(category => category.items);
    // Functions
    function handleRedirectToDocumentDetails(documentId) {
        const params = {
            documentKind: DocumentKindEnum.MINUTES,
            eventId: event.id,
            documentId,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...params,
        });
        onClose();
    }
    async function handleCreateNewFile(file) {
        if (!file)
            return;
        try {
            await createRepositoryDocument({
                data: {
                    typeId: defaultDocumentType,
                    companyId: selectedCompanyId,
                    creatorId: me?.id,
                    description: '',
                    file: {
                        content: file?.base64,
                        mimeType: file?.type,
                        filename: file?.name,
                    },
                    title: file?.name,
                    eventId: event.id,
                    agendaItemId,
                    issuedOn: event?.startDate,
                },
            }, {
                onError: () => {
                    Toast({
                        status: 'error',
                        title: t(tKeys.validation.document.upload.error),
                    });
                },
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                        'event-id': event?.id,
                        'document-types': documentTypes?.map(d => d?.id),
                        'company-id': selectedCompanyId,
                    }));
                    Toast({
                        status: 'success',
                        title: t(tKeys.validation.document.upload.success),
                    });
                },
            });
        }
        catch (err) {
            console.error('Error', err);
        }
    }
    const handleOnDrop = async (acceptedFiles) => {
        const readFileAsBase64 = async (file) => {
            return await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        };
        const filesWithBase64 = await Promise.all(acceptedFiles.map(async (file, index) => {
            const base64 = await readFileAsBase64(file);
            return {
                path: acceptedFiles[index].path,
                lastModified: acceptedFiles[index].lastModified,
                lastModifiedDate: acceptedFiles[index].lastModifiedDate,
                name: acceptedFiles[index].name,
                size: acceptedFiles[index].size,
                type: acceptedFiles[index].type,
                webkitRelativePath: acceptedFiles[index].webkitRelativePath,
                base64,
            };
        }));
        await Promise.all(filesWithBase64.map(async (file) => await handleCreateNewFile(file)));
        void queryClient.invalidateQueries(getReadAgendaItemsQueryKey({ 'event-id': event.id ?? '' }));
    };
    async function handleDeleteDocumentClick(documentId) {
        await deleteDocument({ documentId });
        await refetchAgendaItemDocuments();
        void queryClient.invalidateQueries(getReadAgendaItemsQueryKey({ 'event-id': event.id ?? '' }));
    }
    const isOdd = (documents?.length ?? 0) % 2 !== 0;
    // Return
    return {
        documents,
        isAgendaItemDocumentsLoading,
        handleRedirectToDocumentDetails,
        isDeleteDocumentLoading,
        handleDeleteDocumentClick,
        isOdd,
        handleOnDrop,
        isCreateDocumentLoading,
    };
};
