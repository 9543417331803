import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {},
    textContainer: {
        paddingHorizontal: 16,
        alignContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        height: '100%',
    },
    progressBar: {
        position: 'absolute',
    },
});
export default styles;
