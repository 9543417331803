import React, { useMemo } from 'react';
import Button from '../../../../../../../components/inputs/button/index.web';
import { Kind } from '../../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../../components/utility/loading/size';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { Shape } from '../../../../../../../constants/shape';
import Text from '../../../../../../../components/content/text';
const PeriodTimeButton = ({ selectedValue, value, title, handleOnPress, }) => {
    // Attributes
    const isSelected = useMemo(() => {
        return selectedValue === value;
    }, [selectedValue, value]);
    // Render
    return (<Button kind={isSelected ? Kind.SECONDARY : Kind.WHITE} size={Size.MINI} shape={Shape.ROUND} borderColor={COLORS?.Stroke.value} borderWidth={isSelected ? 0 : 1} onPress={(index) => handleOnPress(index)} width={'full'}>
      <Text font={FONTS.T1.T12Px.Regular400.value} color={isSelected ? COLORS?.White.T500.value : COLORS?.Text.T400.value}>
        {title}
      </Text>
    </Button>);
};
export default PeriodTimeButton;
