import React, { useCallback, useMemo } from 'react';
import { FlatList, TouchableHighlight, View } from 'react-native';
import COLORS from '../../../constants/colors';
import ListItem from '../list-item';
import styles from './style';
const List = ({ options, onChange, value, testID, title, style, spacing = 4, showSelectedItemFirst = false, }) => {
    // Attributes
    const colorSelected = COLORS.IconsBg.value;
    const colorDefault = COLORS.White.T500.value;
    // Functions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keyExtractor = useCallback((item) => item?.id?.toString(), []);
    const renderItem = ({ item, index }) => {
        const { id, children, startEnhancer } = item;
        const isSelected = value === id;
        // Render item
        return (<TouchableHighlight onPress={() => onChange(id)} underlayColor={COLORS.Tag.value} key={`container-${id}`} style={{
                ...styles.itemContainer,
                backgroundColor: isSelected ? colorSelected : colorDefault,
            }} testID={testID}>
        <ListItem id={id} startEnhancer={startEnhancer} key={index} isSelected={isSelected}>
          {children}
        </ListItem>
      </TouchableHighlight>);
    };
    const optionsFiltered = useMemo(() => {
        if (showSelectedItemFirst) {
            // Find the index of selected item
            const index = options?.findIndex(o => o?.id === value); // value needs to be the id
            // If there's a selected item
            // Remove the selected item from array and place it in first position
            if (index !== undefined && index !== -1) {
                const optionsCopy = options;
                const selectedItem = optionsCopy[index];
                optionsCopy?.splice(index, 1);
                return [selectedItem, ...optionsCopy];
            }
        }
        // Else return options
        return options;
    }, [options, value, showSelectedItemFirst]);
    // Render
    return (<FlatList ListHeaderComponent={title} data={optionsFiltered} renderItem={renderItem} keyExtractor={keyExtractor} windowSize={2} initialNumToRender={30} maxToRenderPerBatch={1} style={{ ...styles.list, ...style }} showsVerticalScrollIndicator={false} ItemSeparatorComponent={() => <View style={{ height: spacing }}/>} keyboardShouldPersistTaps="handled"/>);
};
export default List;
