import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarDetails from '../../../../components/content/avatar-details';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import { PressableType } from '../../../../components/inputs/button/type';
import PressableWrapper from '../../../../components/inputs/button/wrapper';
import Flex from '../../../../components/surfaces/flex';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import Modal from '../../../../components/surfaces/modal/index.web';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/utility/loading/size';
import { VStack, Switch, Checkbox } from '@chakra-ui/react';
import MenuItem from '../../../../components/pickers/menu/menu-item';
import styles from './style';
import { useWebLogic } from './logic.web';
import Input from '../../../../components/inputs/input';
const CreateChannel = ({ isOpen, onClose, onMemberPress, onCreateGroup, }) => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { allCompanyMembers, isCreateGroup, onChangeSwitchCreateGroup, handleOnPressMember, isCreateButtonDisabled, form, handleOnCreateGroup, onSelectMember, } = useWebLogic(onMemberPress, onClose, onCreateGroup);
    // Render Members
    const renderMembers = ({ item: person }) => {
        return (<PressableWrapper pressableType={PressableType.SCALE} activeScale={1} onPress={() => handleOnPressMember(person)} style={{ width: '100%' }}>
        <Flex justify="space-between" align="center">
          <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
          {isCreateGroup ? (<Checkbox onChange={() => {
                    onSelectMember(person);
                }}/>) : (<Icon name="chevron-right" width={24} height={24}/>)}
        </Flex>
      </PressableWrapper>);
    };
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.messages.createDiscussion.title)} hasCloseButton actionButton={<Button size={Size.MINI} kind={Kind.SECONDARY} isDisabled={isCreateButtonDisabled} onPress={handleOnCreateGroup}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color={isCreateButtonDisabled
                ? COLORS.Black.T200.value
                : COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
            {t(tKeys.common.create)}
          </Text>
        </Button>}>
      <VStack alignItems={'flex-start'} spacing={4} width={'full'} px={'0.75rem'} pt={'.5rem'} overflow={'auto'} py={'1.25rem'}>
        <MenuItem startEnhancer={<Icon name="members" width={36} height={36}/>} title={t(tKeys.messages.createNewGroup.title)} titleFont={FONTS.T1.T14Px.Medium500.value} endEnhancer={<Switch size={'md'} _checked={{
                '& .chakra-switch__track': {
                    backgroundColor: `${COLORS.Success.T500.value}!important`,
                },
            }} isChecked={isCreateGroup} onChange={onChangeSwitchCreateGroup}/>} endEnhancerStyle={styles.endEnhancer} startEnhancerStyle={styles.menuItemStartEnhancer}/>
        {isCreateGroup && (<Input name={'groupNameInput'} value={form.groupName.value} onChange={e => form?.groupName?.onChange?.(e?.currentTarget?.value)} style={{ padding: '1rem', margin: 0 }} placeholder={t(tKeys.messages.groupName.placeholder)}/>)}
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.more.page.menu.members.menu_title)}
        </Text>
        {allCompanyMembers?.map((person, index) => {
            return renderMembers({
                item: person,
                index,
                separators: undefined,
            });
        })}
      </VStack>
    </Modal>);
};
export default CreateChannel;
