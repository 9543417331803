import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/inputs/button';
import FormControl from '../../../../components/inputs/form-control';
import { tKeys } from '../../../../translations/keys';
import useLogic from './logic.web';
import { PageWebAuth } from '../../../../components/surfaces/page-auth/index.web';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import TagField from '../../../home/calendar/components/inputs/tag-field/index.web';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../../../home/calendar/components/inputs/tag-field/tag-item/index.web';
import { getLanguageLabel } from '../../../../utils/getLanguageValue';
import SelectDocumentLanguages from '../../../home/calendar/document-language/index.web';
import SelectTimezone from '../../../select-time-zone/index.web';
import Input from '../../../../components/inputs/input/index.web';
const SignUpCreateAccount = () => {
    // Attributes
    const { handleOnPressBackButton, form, handlerOnSubmit, isOpenLanguage, onToggleLanguage, handleOnChangeTimezone, isOpenTimezone, onToggleTimezone, handleOnChangeLanguage, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<PageWebAuth onGoBack={handleOnPressBackButton} title={t(tKeys.sign_up.account_informations.title)}>
      {/* Sign-up form */}
      <VStack padding={'2.5rem'} width={'full'} alignItems={'flex-start'} spacing={'.5rem'}>
        <HStack width={'full'} spacing={'.75rem'}>
          {/*  First name  */}
          <FormControl label={t(tKeys.common['first-name'].label)} spacing={2}>
            <Input value={form?.firstname?.value} onChange={e => form?.firstname?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common['first-name'].placeholder)}/>
          </FormControl>

          {/* Last name */}
          <FormControl label={t(tKeys.common['last-name'].label)} spacing={2}>
            <Input value={form?.lastname?.value} onChange={e => form?.lastname?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common['last-name'].placeholder)}/>
          </FormControl>
        </HStack>

        {/* Language */}
        <TagField title={t(tKeys.profile['user-profile'].myinfo['app-lang'])} style={{ px: 0, py: 0 }} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(getLanguageLabel(form?.cultureName?.value))} hasEndEnhancer/>} isOpen={isOpenLanguage} onToggle={onToggleLanguage}>
              <SelectDocumentLanguages onSelectLanguage={handleOnChangeLanguage} selectedLanguage={form?.cultureName?.value}/>
            </PopOver>}/>

        {/* Time zone */}
        <Stack pb={'1.5rem'} width={'full'}>
          <TagField title={t(tKeys.profile['user-profile'].myinfo['time-zone'])} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={form?.timeZoneId?.value} hasEndEnhancer/>} isOpen={isOpenTimezone} onToggle={onToggleTimezone}>
                <SelectTimezone timezoneId={form?.timeZoneId?.value} onChangeTimezoneId={handleOnChangeTimezone}/>
              </PopOver>} isMandatory style={{ px: 0, py: 0 }} error={form?.timeZoneId?.meta?.error}/>
        </Stack>

        {/* Button */}
        <Button onPress={handlerOnSubmit} width={'full'}>
          {t(tKeys['create-account']['confirm-and-create-button'])}
        </Button>
      </VStack>
    </PageWebAuth>);
};
export default SignUpCreateAccount;
