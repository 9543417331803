import React from 'react';
import Modal from '../../components/surfaces/modal/index.web';
import { DocumentPreview } from './document-preview/index.web';
const DocumentViewer = ({ isOpen, onClose, selectedDocument, setPDFtronInstance, }) => {
    // Render
    return (<Modal size={'6xl'} isOpen={isOpen} onClose={onClose} hasCloseButton hasFooter={false}>
      <DocumentPreview selectedDocument={selectedDocument} setPDFtronInstance={setPDFtronInstance}/>
    </Modal>);
};
export default DocumentViewer;
