import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import Tag from '../../../content/tag';
import Text from '../../../content/text';
import { PressableType } from '../../../inputs/button/type';
import PressableWrapper from '../../../inputs/button/wrapper';
import Flex from '../../flex';
import styles from './style';
import { Image } from '@chakra-ui/react';
const DocumentCardDefault = ({ title, eventName, imageURI, updateDate, titleNbOfLines, onPress, type, }) => {
    const { t } = useTranslation();
    const DateTag = () => {
        const dateFormated = moment(new Date(updateDate)).format('DD/MM/yyyy');
        return (<Tag font={FONTS.T1.T10Px.Medium500.value} color={COLORS.Govrn.Blue.T500.value} height={21} backgroundColor={COLORS.IconsBg.value}>
        {`${t(tKeys.document.card.update.date)} ${dateFormated}`}
      </Tag>);
    };
    // Render
    return (<PressableWrapper pressableType={PressableType.SCALE} onPress={onPress}>
      <Flex direction={'column'} style={styles.mainContent}>
        {/* Document preview */}
        <Flex style={{
            ...styles.imageContainer,
        }} flex={1}>
          <Flex style={{
            ...styles.innerImageContainer,
        }} flex={1}>
            <Image src={imageURI ?? require('../../../../assets/images/pdf_preview.png')} maxH={'9rem'} maxW={'16.125rem'} alignItems={'center'} objectFit={'contain'}/>
          </Flex>
        </Flex>

        {/* Footer with informations */}
        <Flex style={{
            ...styles.footerContainer,
            minHeight: type === 'Repository' ? 115 : 55,
        }} direction={'column'} justify="space-between">
          <Flex direction="column" spacing={4}>
            {/* Document Title */}

            <Text font={FONTS.T1.T12Px.SemiBold600.value} numberOfLines={titleNbOfLines}>
              {title}
            </Text>

            {/* Event's related name */}

            <Text font={FONTS.T1.T10Px.Medium500.value} color={COLORS.InputText.value}>
              {eventName}
            </Text>
          </Flex>

          {/* Document updated date */}
          {updateDate ? <Flex>{DateTag()}</Flex> : null}
        </Flex>
      </Flex>
    </PressableWrapper>);
};
export default DocumentCardDefault;
