import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/content/text';
import { tKeys } from '../../../../translations/keys';
import { Grid, GridItem, Stack, VStack } from '@chakra-ui/react';
import { useAgendaItemDocumentsLogic } from './logic.web';
import FONTS from '../../../../constants/fonts';
import COLORS from '../../../../constants/colors';
import DocumentCard from '../../../../components/surfaces/document-card-event';
import Icon from '../../../../components/content/icon';
import { BusyIndicator } from '../../../../components/utility/busy-indicator';
import LoadingAnimation from '../../../../components/utility/loading';
import TagField from '../components/inputs/tag-field';
import DropzoneInput from '../../../../components/inputs/dropzone/index.web';
const AgendaItemDocuments = ({ isOpen, onClose, agendaItemId, event, isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    const { documents, isAgendaItemDocumentsLoading, handleRedirectToDocumentDetails, handleDeleteDocumentClick, isDeleteDocumentLoading, isOdd, handleOnDrop, isCreateDocumentLoading, } = useAgendaItemDocumentsLogic(agendaItemId, isOpen, onClose, event);
    // Render
    return (<VStack width={'100%'} alignItems={'flex-start'}>
      <TagField title={t(tKeys.common.repository)} startEnhancer={<Icon name="files-blue"/>} endEnhancer={<></>}/>
      <DropzoneInput isLoading={isCreateDocumentLoading} onDrop={handleOnDrop}/>
      <Stack width={'100%'} height={'100%'} minHeight={'14rem'}>
        <BusyIndicator isLoading={isAgendaItemDocumentsLoading || isDeleteDocumentLoading} loading={<Stack width={'100%'} height={'14rem'} alignItems={'center'} justifyContent={'center'}>
              <LoadingAnimation />
            </Stack>} isEmpty={!documents?.length} placeholder={<VStack width={'100%'} height={'20rem'} alignItems={'center'} justifyContent={'center'} spacing={'.5rem'}>
              <Text font={FONTS.T1.T16Px.SemiBold600.value}>
                {t(tKeys.agenda_item.documents.placeholder.title)}
              </Text>
              <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.InputText.value}>
                {t(tKeys.agenda_item.documents.placeholder.subtitle)}
              </Text>
            </VStack>}>
          <Grid alignItems={'flex-start'} templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} width={'full'} px={'1rem'} columnGap={'.5rem'}>
            {documents?.map((document, index) => (<GridItem key={document.id} colSpan={isOdd && index === documents.length - 1 ? 2 : 1} onClick={() => handleRedirectToDocumentDetails(document.id)} cursor={'pointer'}>
                <DocumentCard startEnhancer={<Stack borderRadius={'.375rem'} p={'.375rem'} backgroundColor={COLORS.Line.value}>
                      <Icon name="files" height={14} width={14} fill={COLORS.InputText.value}/>
                    </Stack>} endEnhancer={isAdmin && (<Stack borderRadius={'.375rem'} p={'.375rem'} cursor={'pointer'} backgroundColor={COLORS.Text.T500.value} 
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async (e) => {
                    e.stopPropagation();
                    await handleDeleteDocumentClick(document.id);
                }}>
                        <Icon name="cross" height={10} width={10} fill={COLORS.White.T500.value}/>
                      </Stack>)} width={'100%'}>
                  {document?.title ?? document?.file?.filename}
                </DocumentCard>
              </GridItem>))}
          </Grid>
        </BusyIndicator>
      </Stack>
    </VStack>);
};
export default AgendaItemDocuments;
