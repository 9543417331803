import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { getReadChannelsQueryKey, useDeleteChannel, useReadChannel, useUpdateChannel, } from '../../../../services/online/chat/chat';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { useReadMe } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getStaleTime from '../../../../utils/getStaleTime';
import { validationSchema } from './validation';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { useFilePicker } from 'use-file-picker';
import { extractBase64FromDataUrl, onSelectedFileSuccess, } from '../../../../utils/selectFile.web';
const useLogic = (channelId, onClose) => {
    // Attributes
    const { t } = useTranslation();
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const queryClient = useQueryClient();
    const toast = useToast();
    const [image, setImage] = useState();
    // Queries
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    });
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: ['image/jpeg', 'image/png', 'image/jpg'],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile),
    });
    const { data: currentUser } = useReadMe({
        query: { staleTime: getStaleTime() },
    });
    const peopleInTheCompany = useMemo(() => {
        return enrolment?.items
            ?.map(enrolment => enrolment.person)
            .filter(person => person?.id !== currentUser?.person?.id);
    }, [enrolment]);
    const { data: channel, isLoading: isChannelLoading } = useReadChannel(channelId);
    const { mutateAsync: deleteChannel, isLoading: isDeleteLoading } = useDeleteChannel();
    const { mutateAsync: updateChannel, isLoading: isSubmitLoading } = useUpdateChannel();
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            name: channel?.name,
            members: channel?.participants
                ?.filter(p => p.personId !== currentUser?.person?.id)
                .map(p => p?.personId),
            picture: channel?.picture,
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema(t),
        enableReinitialize: true,
    });
    const form = createForm(values, rest);
    function handleClose() {
        onClose();
        rest?.resetForm();
        setImage(undefined);
    }
    function handleSubmit() {
        void updateChannel({
            data: {
                id: channel?.id,
                companyId: channel?.companyId,
                creatorId: channel?.creatorId,
                isGroup: channel?.isGroup,
                name: form?.name?.value,
                picture: form?.picture?.value,
                participantIds: [currentUser?.person?.id, ...form?.members?.value],
            },
        }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadChannelsQueryKey({ 'company-id': selectedCompanyId }));
                handleClose();
                toast({
                    status: 'success',
                    title: t(tKeys.edit_group_settings.success),
                });
            },
            onError: () => {
                toast({
                    status: 'error',
                    title: t(tKeys.edit_group_settings.error),
                });
            },
        });
    }
    function handleOnSelectFile(value) {
        try {
            const file = {};
            if (value) {
                // Convert to base64
                const imageBase64 = extractBase64FromDataUrl(value?.data);
                const byteCharacters = atob(imageBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: value?.documentMetaData?.mime,
                });
                const objectURL = URL.createObjectURL(blob);
                setImage(objectURL);
                file.content = imageBase64;
                file.filename = value?.documentMetaData?.filename;
                file.mimeType = value?.documentMetaData?.mime;
                form?.picture?.onChange(file);
            }
        }
        catch (error) {
            toast({
                status: 'error',
                title: t(tKeys.validation.message.document.error),
            });
            console.error(error);
        }
    }
    function onMemberPress(personId) {
        handleClose();
        push('/company/profile/other-user-profile', {
            companyId: selectedCompanyId,
            personId,
        });
    }
    function onDeleteGroupPress() {
        void deleteChannel({ channelId: channel?.id }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadChannelsQueryKey({ 'company-id': selectedCompanyId }));
                handleClose();
                toast({
                    status: 'success',
                    title: t(tKeys.delete_group.success),
                });
            },
        });
    }
    function handleOpenFilePicker() {
        openFilePicker();
    }
    function isMemberSelected(member) {
        return values?.members?.some(id => id === member?.id);
    }
    const onSelectMember = (member) => {
        let updatedIds;
        if (isMemberSelected(member)) {
            updatedIds = values?.members?.filter(id => id !== member.id);
        }
        else {
            updatedIds = values?.members?.concat(member?.id);
        }
        form?.members?.onChange(updatedIds);
    };
    // Render
    return {
        channel,
        form,
        handleOnSubmit: form?.submitForm,
        onMemberPress,
        onDeleteGroupPress,
        isDeleteLoading,
        isEditable: currentUser?.person?.id === channel?.creatorId,
        isSubmitLoading,
        isChannelLoading,
        handleOpenFilePicker,
        values,
        image,
        peopleInTheCompany,
        onSelectMember,
        isMemberSelected,
    };
};
export default useLogic;
