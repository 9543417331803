/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { DocumentTypes } from '../../../../../components/surfaces/document-modal/props';
import { useCompaniesContext } from '../../../../../contexts/companies';
import useRouter from '../../../../../hooks/router/useRouter';
import { useReadEnrolments } from '../../../../../services/online/enrolments/enrolments';
import { useReadGroups } from '../../../../../services/online/groups/groups';
import { getReadDocumentsQueryKey, useCreateDocument, useReadDocumentTypes, } from '../../../../../services/online/repository/repository';
import { tKeys } from '../../../../../translations/keys';
import { createForm } from '../../../../../utils/formik';
import getStaleTime from '../../../../../utils/getStaleTime';
import { DocumentKindEnum } from '../../../../document-details/type';
import { validationSchema } from './validations';
const useLogic = (createDocumentEntity, onClose, refetchDocuments) => {
    // Attributes
    const { push } = useRouter();
    const toast = useToast();
    const [search, setSearch] = useState('');
    const { isOpen: isOpenFileType, onToggle: onToggleFileType } = useDisclosure();
    // Queries
    // - Getters
    const { me } = useCompaniesContext();
    const { selectedCompanyId } = useCompaniesContext();
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': search,
    });
    const { data: groupsInTheCompany } = useReadGroups({
        'company-id': selectedCompanyId,
        'search-term': search,
    });
    // Attributes
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { mutateAsync: createRepositoryDocument, isLoading: isCreateDocumentLoading, } = useCreateDocument();
    // Functions
    function handleOnChangeFileType(value) {
        form?.fileType?.onChange?.(value);
        onToggleFileType();
    }
    function extractBase64FromDataUrl(dataUrl) {
        return dataUrl.split(',')[1];
    }
    async function handleOnSubmit() {
        try {
            await createRepositoryDocument({
                data: {
                    typeId: form?.fileType?.value?.id,
                    companyId: selectedCompanyId,
                    creatorId: me?.id,
                    description: form?.description?.value,
                    file: {
                        content: createDocumentEntity?.dataType === DocumentTypes.PICTURE_GALLERY
                            ? extractBase64FromDataUrl(createDocumentEntity?.data)
                            : createDocumentEntity?.data,
                        mimeType: form?.file?.value?.mime ?? form?.file?.value?.type,
                        filename: form?.file?.value?.filename ?? form?.file?.value?.name,
                    },
                    title: form?.title?.value,
                    eventId: createDocumentEntity?.eventId,
                    agendaItemId: createDocumentEntity?.agendaItemId,
                    signerIds: values?.signedBy?.map(person => person?.id),
                    issuedOn: form?.issuedOn.value,
                    groupIds: form?.groupIds.value,
                    personIds: form?.personIds.value,
                },
            }, {
                onSuccess: res => {
                    if (createDocumentEntity?.eventId) {
                        void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                            'event-id': createDocumentEntity?.eventId,
                            'document-types': documentTypes?.map(d => d?.id),
                            'company-id': selectedCompanyId,
                        }));
                    }
                    refetchDocuments();
                    onClose();
                    resetForm();
                    toast({
                        status: 'success',
                        title: t(tKeys.validation.document.upload.success),
                    });
                    push(`/documents-details`, {
                        documentKind: DocumentKindEnum.DOCUMENT,
                        documentId: res.value,
                    });
                },
                onError: err => {
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.document.upload.error),
                    });
                    console.error('Error when uploading a file : ', err);
                },
            });
        }
        catch (err) {
            console.error('Error when uploading a new document in the repository : ', err);
        }
    }
    function handleSearchChange(value) {
        setSearch(value);
    }
    function isPersonSelected(person) {
        return values?.personIds?.some(id => id === person?.id);
    }
    function isPersonAlreadyInEvent(person) {
        return createDocumentEntity?.eventPersonIds?.some(id => id === person?.id);
    }
    function isGroupAlreadyInEvent(group) {
        return createDocumentEntity?.eventGroupIds?.some(id => id === group?.id);
    }
    function handlePersonSelect(person) {
        const personIds = [...values.personIds];
        if (isPersonSelected(person)) {
            const personsSelectedFiltered = personIds.filter(id => id !== person.id);
            form.personIds.onChange(personsSelectedFiltered);
            return;
        }
        personIds.push(person.id ?? '');
        form.personIds.onChange?.(personIds);
    }
    function isGroupSelected(group) {
        return values?.groupIds?.some(id => id === group?.id);
    }
    function handleGroupSelect(group) {
        const groupsIds = [...values.groupIds];
        if (isGroupSelected(group)) {
            const groupsSelectedFiltered = groupsIds.filter(id => id !== group.id);
            form.groupIds.onChange(groupsSelectedFiltered);
            return;
        }
        groupsIds.push(group.id ?? '');
        form.groupIds.onChange?.(groupsIds);
    }
    const initialsValues = {
        title: createDocumentEntity?.title ??
            createDocumentEntity?.documentMetaData?.name ??
            createDocumentEntity?.documentMetaData?.filename ??
            '',
        fileType: documentTypes?.[0],
        description: '',
        file: createDocumentEntity?.documentMetaData,
        signedBy: [],
        issuedOn: createDocumentEntity?.issuedOn ?? '',
        groupIds: [],
        personIds: [],
    };
    // Form
    const { values, resetForm, ...rest } = useFormik({
        initialValues: initialsValues,
        enableReinitialize: true,
        onSubmit: handleOnSubmit,
        validationSchema: validationSchema(t),
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    const disabledSaveButton = !values?.title || !values?.fileType;
    const peopleInTheCompany = useMemo(() => {
        return enrolments?.items?.map(enrolment => enrolment.person);
    }, [enrolments]);
    // Return
    return {
        form,
        dataType: createDocumentEntity?.dataType,
        documentTypes,
        handleOnSubmit: handleSubmit,
        disabledSaveButton,
        isCreateDocumentLoading,
        imageBase64: values?.file?.data,
        values,
        isOpenFileType,
        onToggleFileType,
        handleOnChangeFileType,
        peopleInTheCompany,
        groupsInTheCompany,
        handleSearchChange,
        search,
        isGroupSelected,
        isPersonSelected,
        handleGroupSelect,
        handlePersonSelect,
        isPersonAlreadyInEvent,
        isGroupAlreadyInEvent,
    };
};
export default useLogic;
