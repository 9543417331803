import { useEffect, useState } from 'react';
import useRouter from '../../../hooks/router/useRouter';
export const useBoardPackItemsLogic = (agendaItem, parentIndexes) => {
    // Attributes
    const { params } = useRouter();
    const agendaItemIdURL = params?.agendaItemId;
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const displayIndexes = parentIndexes.join('.').concat('.');
    // Effects
    useEffect(() => {
        if (!agendaItemIdURL)
            return;
        setAccordionOpen(agendaItemIdURL === agendaItem.id);
    }, [agendaItemIdURL, agendaItem.id]);
    return { isAccordionOpen, setAccordionOpen, displayIndexes };
};
