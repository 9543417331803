import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
const styles = StyleSheet.create({
    container: {
        width: '100%',
        backgroundColor: COLORS.White.T500.value,
        paddingHorizontal: 20,
        paddingTop: 20,
        paddingBottom: 12,
        borderRadius: 16,
    },
    content: {
        textAlign: 'center',
    },
});
export default styles;
