import React from 'react';
import Text from '../../../../../../components/content/text';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import TaskDetailSection from '../../../../../home/more/components/content/task-detail-section';
import TaskTag from '../../../../../../components/surfaces/task-tag';
import { DocumentKindEnum } from '../../../type';
import Tag from '../../../../../../components/content/tag';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
const DocumentSectionInformation = ({ documentEntity, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    const renderTag = () => {
        if (documentEntity?.kind === DocumentKindEnum.MINUTES)
            return (<Tag backgroundColor={COLORS.Tag.value}>
          {t(tKeys.documentDetails.boardMinutes)}
        </Tag>);
        if (documentEntity?.taskType)
            return <TaskTag taskType={documentEntity?.taskType}/>;
        return null;
    };
    // Return
    return (<TaskDetailSection title={documentEntity?.title} subtitle={documentEntity?.eventTitle} rightContent={renderTag()} isAlreadyEnableOffline={true}>
      {/* Task description */}
      {documentEntity?.description && (<Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value} numberOfLines={null}>
          {documentEntity?.description}
        </Text>)}
    </TaskDetailSection>);
};
export default DocumentSectionInformation;
