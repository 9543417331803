import Filter from '.';
import { stackNavigator } from '../../../../routes/navigators';
import KnowledgeBaseFilters from '../../more/knowledge-base/filter';
import FilterSearchMembers from './filter-search-members';
const filterRoutes = [
    {
        name: '/filter',
        deepLink: '/filter',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/news-feed',
                deepLink: '/news-feed',
                component: Filter,
            },
            {
                name: '/filter-search-members',
                deepLink: '/filter-search-members',
                component: FilterSearchMembers,
            },
            {
                name: '/knowledge-base',
                deepLink: '/knowledge-base',
                component: KnowledgeBaseFilters,
            },
        ],
    },
];
export default filterRoutes;
