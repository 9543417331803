const useLogic = ({ onAccept, onDismiss }) => {
    // Functions
    function onLeavePress() {
        onAccept();
        onDismiss();
    }
    function onCancelPress() {
        onDismiss();
    }
    return {
        onLeavePress,
        onCancelPress,
    };
};
export default useLogic;
