import { useRef, useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { t } from 'i18next';
import useRouter from '../../../hooks/router/useRouter';
import { getAllAnnotationsByDocumentId, getDocumentById, saveAnnotation, saveDocument, } from '../../../services/offline/db-service';
import { tKeys } from '../../../translations/keys';
import parseAnnotation from '../../../utils/parseAnnotation';
const useLogic = () => {
    // Attributes
    const viewerRef = useRef();
    const { params, goBack } = useRouter();
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
    const documentId = params?.documentId;
    const [documentData, setDocumentData] = useState();
    const [annotationsData, setAnnotationsData] = useState();
    const [isDocumentLoading, setIsDocumentLoading] = useState(false);
    const documentType = documentData?.title?.split('.')?.[1];
    // Functions
    function updateDocumentAnnotations({ xfdfCommand }) {
        if (xfdfCommand === undefined)
            return;
        saveAnnotation({
            annotation: xfdfCommand,
            creationDate: new Date().toString(),
            documentId,
        })
            .then(() => fetchAnnotations())
            .catch(err => {
            console.log('err', err);
            Toast.show({
                type: 'error',
                text1: t(tKeys.document_viewer.annotation.update.error),
            });
        });
        saveDocument({
            ...documentData,
            offlineUpdated: 1,
        })
            .then(() => fetchDocument())
            .catch(err => {
            console.log('err', err);
            Toast.show({
                type: 'error',
                text1: t(tKeys.document_viewer.annotation.update.error),
            });
        });
    }
    function fetchDocument() {
        getDocumentById(documentId).then(document => {
            setDocumentData(document);
            setIsDocumentLoading(false);
        });
    }
    function fetchAnnotations() {
        getAllAnnotationsByDocumentId(documentId).then(annotations => {
            setAnnotationsData(annotations);
        });
    }
    // Effect
    useEffect(() => {
        setIsDocumentLoading(true);
        fetchDocument();
        fetchAnnotations();
    }, [documentId]);
    function onDocumentLoaded() {
        setIsDocumentLoaded(true);
    }
    useEffect(() => {
        if (!documentData && !documentData?.initialXfdfString && !isDocumentLoaded)
            return;
        try {
            const finalXfdfString = parseAnnotation(documentData, annotationsData);
            void viewerRef?.current
                ?.importAnnotations?.(finalXfdfString)
                ?.catch(err => console.error('Error when importing annotations : ', err));
        }
        catch (err) {
            console.error('Error when importing annotations : ', err);
        }
    }, [documentData, annotationsData, isDocumentLoaded]);
    return {
        documentData,
        isDocumentLoading,
        viewerRef,
        goBack,
        documentType,
        updateDocumentAnnotations,
        onDocumentLoaded,
    };
};
export default useLogic;
