import VerifyMobilePhone from '.';
const verifyMobileCodeUserProfileRoutes = [
    {
        name: '/verify-mobile-code-user-profile',
        deepLink: '/verify-mobile-code-user-profile',
        tabBarLabel: 'profile.user-profile.myinfo.my-information',
        component: VerifyMobilePhone,
    },
];
export default verifyMobileCodeUserProfileRoutes;
