import { useFormik } from 'formik';
import { countries } from '../../../components/inputs/phone-input/assets/countries-flags-dials';
import useRouter from '../../../hooks/router/useRouter';
import { useAcceptInvitation, useReadInvitation, } from '../../../services/online/enrolments/enrolments';
import { createForm } from '../../../utils/formik';
import { useEffect } from 'react';
import { useToast } from '../../../components/content/use-toast/index.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
const useLogic = () => {
    // Attributes
    const { push, params } = useRouter();
    const { t } = useTranslation();
    const toast = useToast();
    const invitationId = params?.invitationId;
    const signUpEntity = {
        invitationId: '',
        phoneNumber: '',
        smsCode: '',
        country: undefined,
    };
    // Queries
    const { mutateAsync: acceptInvitation, isLoading: isAcceptInvitationLoading, } = useAcceptInvitation();
    const { data: invitation } = useReadInvitation(invitationId, {
        query: { enabled: !!invitationId },
    });
    // Form
    const handleOnSubmit = async () => {
        const phoneNumber = `${form?.country?.value?.dialCode}${form?.phoneNumber?.value}`;
        await acceptInvitation({
            invitationId,
            params: {
                phone: phoneNumber,
            },
        }, {
            onSuccess: () => {
                signUpEntity.invitationId = invitationId;
                signUpEntity.phoneNumber = phoneNumber;
                signUpEntity.country = form?.country?.value;
                push('/auth/verification-code', {
                    sourcePageName: '/sign-up',
                    signUpEntity,
                });
            },
            onError: err => {
                console.error('Error with the confirm invitation : ', err);
            },
        });
    };
    const { values, ...rest } = useFormik({
        initialValues: {
            phoneNumber: '',
            country: {
                // TODO: get the current location (By default it's Belgium for now.)
                ...countries[21],
            },
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Functions
    function handleOnChangeCountry(value) {
        form?.country?.onChange?.(value);
    }
    // Effects
    useEffect(() => {
        if (!invitation?.confirmedOn)
            return;
        toast({
            status: 'success',
            title: t(tKeys.sign_up.already_confirmed.alert),
        });
        push('/auth/sign-in');
    }, [invitation]);
    // Return
    return {
        form,
        handleOnSubmit,
        isAcceptInvitationLoading,
        handleOnChangeCountry,
        companyName: invitation?.companyName ?? '',
        inviterName: invitation
            ? `${invitation?.inviterFirstName} ${invitation?.inviterLastName}`
            : '',
    };
};
export default useLogic;
