import { Platform } from 'react-native';
import RNPermissions, { check, PERMISSIONS, } from 'react-native-permissions';
const handleFilePermission = async () => {
    let status;
    // Check permission by platform
    if (Platform.OS === 'android') {
        status = await check(PERMISSIONS.ANDROID.ACCESS_MEDIA_LOCATION);
        status = await check(PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE);
        status = await check(PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE);
    }
    else {
        status = await check(PERMISSIONS.IOS.PHOTO_LIBRARY);
    }
    // Check if granted or limited
    if (status === 'granted' || status === 'limited') {
        return true;
    }
    // If permission is not granded so re-ask to the user
    let permission;
    if (Platform.OS === 'android') {
        permission = await RNPermissions.request(PERMISSIONS.ANDROID.ACCESS_MEDIA_LOCATION);
        permission = await RNPermissions.request(PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE);
        permission = await RNPermissions.request(PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE);
    }
    else {
        permission = await RNPermissions.request(PERMISSIONS.IOS.PHOTO_LIBRARY);
    }
    if (permission !== 'granted') {
        return false;
    }
    return true;
};
export default handleFilePermission;
