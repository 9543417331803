import React from 'react';
import Button from '../../../../../../components/inputs/button';
import NavigationHeader from '../../../../../../components/navigation/navigation-header';
import Page from '../../../../../../components/surfaces/page';
import COLORS from '../../../../../../constants/colors';
import styles from './style';
import Container from '../../../../../../components/surfaces/container';
import FormControl from '../../../../../../components/inputs/form-control';
import Input from '../../../../../../components/inputs/input';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic';
const ModifyEventType = () => {
    // Attributes
    const { handleOnPressBackButton, handleOnPressButton, isUpdateEventLoading, dirty, form, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader onGoBack={handleOnPressBackButton} endEnhancer={<Button onPress={handleOnPressButton} isLoading={isUpdateEventLoading} kind={Kind.WHITE} size={Size.MINI}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value} color={!dirty ? COLORS.InputText.value : COLORS.Govrn.Blue.T500.value}>
              {t(tKeys.common.save)}
            </Text>
          </Button>}>
        {t(tKeys.settings['manage-events']['modify-event-type']['navigation-header'])}
      </NavigationHeader>

      <Container style={styles.mainContainer}>
        <FormControl label={t(tKeys.common.name.label)}>
          <Input {...form?.eventName} autoFocus={true}/>
        </FormControl>
      </Container>
    </Page>);
};
export default ModifyEventType;
