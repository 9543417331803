import DocumentPicker, { types, } from 'react-native-document-picker';
import handleFilePermission from './handleFilePermission';
export const FileTypes = [
    types.pdf,
    types.doc,
    types.docx,
    types.ppt,
    types.pptx,
    types.xls,
    types.xlsx,
    types.csv,
];
export async function handlePickFile() {
    // Check permissions
    const permission = await handleFilePermission();
    if (!permission) {
        return undefined;
    }
    // Opens the file picker and waits for the result
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleFileFormat = async () => {
        return await DocumentPicker.pickSingle({
            presentationStyle: 'fullScreen',
            copyTo: 'cachesDirectory',
            type: FileTypes,
        });
    };
    try {
        return await handleFileFormat();
    }
    catch (err) {
        console.error('User canceled the document picker: ', err);
    }
}
