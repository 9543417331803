import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../../components/content/text';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
import { HStack, Stack, VStack, Text as ChakraText } from '@chakra-ui/react';
import { useCardNextEventLogic } from './logic.web';
import Placeholder from '../../../../../../../components/utility/placeholder/index.web';
import Button from '../../../../../../../components/inputs/button/index.web';
import Icon from '../../../../../../../components/content/icon';
import { Kind } from '../../../../../../../components/inputs/button/constants/kind';
import CreateEvent from '../../../../../calendar/create-event/index.web';
import EventCard from '../../../../../../../components/surfaces/event-card/index.web';
const CardNextEvent = ({ events, handleOnPressEvent, }) => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { isAdmin, isOpenCreateEvent, onCloseCreateEvent, onOpenCreateEvent, handleRedirectCalendarPage, allEventsTypes, } = useCardNextEventLogic();
    const { fontFamily, fontSize, fontWeight } = FONTS.T1.T14Px.Medium500.value;
    // Render
    if (!events.length) {
        return (<Stack width={'full'} borderRadius={'.75rem'} backgroundColor={COLORS.White.T500.value} borderWidth={1} borderColor={COLORS.Line.value} spacing={'.75rem'} pb={'2rem'}>
        <Placeholder imageType={'monocle-emoji'} title={t(tKeys.homepage.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.homepage.placeholder.board_admin.subtitle)
                : t(tKeys.homepage.placeholder.board_member.subtitle)} endEnhancer={isAdmin && (<Stack marginTop={'1.25rem'}>
                <Button kind={Kind.SECONDARY} onPress={onOpenCreateEvent} startEnhancer={() => (<Icon name="add" width={20} height={20} fill={COLORS.White.T500.value}/>)}>
                  <Stack pl={'.5rem'}>
                    <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                      {t(tKeys.calendar.createEvent.title)}
                    </Text>
                  </Stack>
                </Button>
              </Stack>)} style={{ paddingTop: '2rem' }}/>
        {/* Modal Create Event */}
        <CreateEvent isOpen={isOpenCreateEvent} onClose={onCloseCreateEvent} additionalFunctionOnCreate={handleRedirectCalendarPage}/>
      </Stack>);
    }
    return (<VStack width={'full'} alignItems={'flex-start'} spacing={'1rem'}>
      {/* Title */}
      <HStack width={'full'} justifyContent={'space-between'}>
        <Text font={FONTS.T1.T16Px.Bold700.value}>
          {t(tKeys.homepage.next_events.title)}
        </Text>
        <Stack onClick={handleRedirectCalendarPage} cursor={'pointer'} _hover={{ textColor: COLORS.Govrn.Blue.T500.value }}>
          <ChakraText fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} color={COLORS.Text.T400.value} _hover={{ color: COLORS.Govrn.Blue.T500.value }}>
            {t(tKeys.homepage.see_all_events)}
          </ChakraText>
        </Stack>
      </HStack>
      {/* Events list */}
      <VStack width={'full'} alignItems={'flex-start'} spacing={'.75rem'}>
        {events.map(event => (<EventCard onLayout={() => undefined} key={event?.id} event={event} eventType={allEventsTypes?.find(eventType => eventType?.id === event?.typeId)} isPublished={!!event?.publishedOn?.length} onClick={() => handleOnPressEvent(event?.id)}/>))}
      </VStack>
    </VStack>);
};
export default CardNextEvent;
