import React from 'react';
import { View } from 'react-native';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import Text from '../../../../content/text';
import Icon from '../../../../content/icon';
import styles from './style';
import Image from '../../../../content/image';
import { HStack, Stack } from '@chakra-ui/react';
import Avatar from '../../../../content/avatar';
const SideBarItem = ({ children, onSelect, active, notification, icon, endEnhancer, style, image, avatar, }) => {
    const renderNotification = () => {
        if (!notification) {
            return null;
        }
        return (<View style={styles.notificationContainer}>
        <Text style={styles.notificationText} font={FONTS.T1.T12Px.SemiBold600.value}>
          {notification}
        </Text>
      </View>);
    };
    // Render
    return (<HStack justify="space-between" align="center" width={'100%'} onClick={onSelect} backgroundColor={active ? '#ffffff33' : 'none'} px={'1.25rem'} py={'.5rem'} cursor={'pointer'} borderRadius={'.25rem'}>
      <HStack spacing={avatar ? '1rem ' : '1.25rem'} width={'100%'}>
        {icon && (<Icon name={icon} style={styles.icon} width={'1.5rem'} height={'1.5rem'} fill={COLORS.White.T500.value}/>)}
        {image && !avatar && (<Image imageURI={image} width={'3.25rem'} maxHeight={'1rem'}/>)}
        {image && avatar && (<Avatar imageSource={image} name={children} width={'1.5rem'} height={'1.5rem'} borderRadius={100}/>)}
        <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.White.T500.value}>
          {children}
        </Text>
      </HStack>
      {renderNotification()}
      {endEnhancer && <Stack minWidth={'fit-content'}>{endEnhancer}</Stack>}
    </HStack>);
};
export default SideBarItem;
