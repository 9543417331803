import { Box, HStack, VStack } from '@chakra-ui/react';
import Text from '../../../../../../components/content/text';
import Button from '../../../../../../components/inputs/button';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import React from 'react';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { usePreviewMinutesLogic } from './logic.web';
import PopOver from '../../../../../../components/surfaces/pop-over/index.web';
export const EventDetailsPreviewMinutes = ({ eventId, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isOpenPopover, onTogglePopover, isLoading, handleGenerateClick } = usePreviewMinutesLogic(eventId);
    // Render
    return (<HStack width={'full'} justifyContent={'space-between'} bgColor={COLORS.White.T500.value} borderRadius={'.5rem'} borderWidth={1} borderColor={COLORS.Line.value} p={'1.25rem'}>
      <Text font={FONTS.T1.T14Px.SemiBold600.value} numberOfLines={2}>
        {t(tKeys.event.details.preview_minutes.title)}
      </Text>
      <PopOver from={<Button kind={Kind.SECONDARY} backgroundColorPerso={COLORS.Govrn.Blue.T800.value} isLoading={isLoading} onPress={onTogglePopover}>
            {t(tKeys.common.generate)}
          </Button>} placement="bottom-start" isOpen={isOpenPopover} onToggle={onTogglePopover}>
        <VStack alignItems={'flex-start'} width={'full'} py={'.25rem'} spacing={0}>
          <Box py={'.5rem'} px={'1rem'} width={'full'} _hover={{ backgroundColor: COLORS.Tag.value }} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onClick={async () => await handleGenerateClick('fr-FR')} cursor={'pointer'}>
            <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T400.value}>
              {t(tKeys.calendar.event_details.generateMinutesFrench)}
            </Text>
          </Box>
          <Box py={'.5rem'} px={'1rem'} width={'full'} _hover={{ backgroundColor: COLORS.Tag.value }} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onClick={async () => await handleGenerateClick('en-GB')} cursor={'pointer'}>
            <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T400.value}>
              {t(tKeys.calendar.event_details.generateMinutesEnglish)}
            </Text>
          </Box>
          <Box py={'.5rem'} px={'1rem'} width={'full'} _hover={{ backgroundColor: COLORS.Tag.value }} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onClick={async () => await handleGenerateClick('nl-NL')} cursor={'pointer'}>
            <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T400.value}>
              {t(tKeys.calendar.event_details.generateMinutesNL)}
            </Text>
          </Box>
        </VStack>
      </PopOver>
    </HStack>);
};
