import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        marginTop: 20,
    },
    textContainer: {
        marginVertical: 8,
        marginHorizontal: 16,
    },
    calendarContainer: {
        marginTop: 8,
    },
    dateTitleContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    collapsedContainer: {
        height: 350,
        width: '100%',
    },
});
export default styles;
