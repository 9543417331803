import { t } from 'i18next';
import React, { useMemo } from 'react';
import FormControl from '../../../../../components/inputs/form-control';
import ShareInPrivateCard from '../../../../../components/surfaces/share-in-private-card';
import { tKeys } from '../../../../../translations/keys';
import ShareInMessageList from '../share-in-messages-list';
import { Input, Stack } from '@chakra-ui/react';
const ShareInMessagesListHeader = ({ shareInMessageEntity, form, channels, isChannelsLoading, getParticipant, onChannelPress, allMembersInCompany, isCompanyEnrolementsLoading, }) => {
    // Attributes
    // Functions
    const getDataToDisplay = useMemo(() => {
        if (shareInMessageEntity?.isPost) {
            return shareInMessageEntity?.data?.content;
        }
        if (shareInMessageEntity?.isEvent) {
            return shareInMessageEntity?.data?.name;
        }
        return shareInMessageEntity?.data?.title;
    }, [shareInMessageEntity]);
    // Render
    return (<Stack width={'full'} spacing={'1rem'}>
      <ShareInPrivateCard isNewChat={false} title={getDataToDisplay} kind={shareInMessageEntity?.type} width="100%"/>

      <Stack width={'full'} pb={'1rem'}>
        <FormControl error={form?.messageContent?.meta?.error}>
          <Input defaultValue={form?.messageContent.value} onChange={e => {
            form?.messageContent.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.messages.chatBox.placeholder)} autoFocus={true} fontSize={'0.875rem'} fontWeight={400} paddingLeft={0} borderWidth={0} _focusVisible={{ borderWidth: 0 }}/>
        </FormControl>
      </Stack>

      <ShareInMessageList channels={channels} isChannelsLoading={isChannelsLoading} handleOnPress={onChannelPress} getParticipant={getParticipant}/>
    </Stack>);
};
export default ShareInMessagesListHeader;
