import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilePicker } from 'use-file-picker';
import { useToast } from '../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../contexts/companies/index.web';
import useRouter from '../../../hooks/router/useRouter';
import { getReadMeQueryKey, useUpdatePerson, } from '../../../services/online/people/people';
import { tKeys } from '../../../translations/keys';
import { removeTokens } from '../../../utils/handleTokens';
import { onSelectedFileSuccess } from '../../../utils/selectFile.web';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { push } = useRouter();
    const queryClient = useQueryClient();
    const [selectedImage, setSelectedImage] = useState();
    const toast = useToast();
    const { selectedCompanyId, me } = useCompaniesContext();
    // Queries
    const { mutateAsync: updatePerson, isLoading: isUpdatePersonLoading } = useUpdatePerson({
        mutation: {
            retry: 3,
        },
    });
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: ['image/jpeg', 'image/png', 'image/jpg'],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile),
    });
    // Functions
    function handleOpenFilePicker() {
        openFilePicker();
    }
    function handleOnSelectFile(value) {
        setSelectedImage(value);
    }
    const handleNavigateTo = (routeName) => {
        push(routeName, { companyId: selectedCompanyId });
    };
    function extractBase64FromDataUrl(dataUrl) {
        return dataUrl.split(',')[1];
    }
    const handleChoosePictureFromGallery = async () => {
        const base64 = extractBase64FromDataUrl(selectedImage?.data);
        await updatePerson({
            data: {
                ...me,
                picture: {
                    content: base64,
                    mimeType: selectedImage?.documentMetaData?.mime,
                    filename: selectedImage?.documentMetaData?.filename,
                },
            },
        }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadMeQueryKey());
            },
            onError: () => {
                toast({
                    status: 'error',
                    title: t(tKeys.profile['user-profile']['update-person'].error),
                });
            },
        });
    };
    async function handleSignOut() {
        await removeTokens();
        push('/auth/sign-in');
    }
    // UseEffects
    useEffect(() => {
        if (!selectedImage)
            return;
        void handleChoosePictureFromGallery();
    }, [selectedImage]);
    // Return
    return {
        handleNavigateTo,
        me,
        t,
        isUpdatePersonLoading,
        handleOpenFilePicker,
        handleSignOut,
    };
};
export default useLogic;
