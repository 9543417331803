/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a post to be published onto the news feed of a company
 */
export const createPost = (createPostBody, options) => {
    return customInstance({ url: `/news-feed`, method: 'post', data: createPostBody }, options);
};
export const useCreatePost = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createPost(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a paginated list of posts with filtering support
 */
export const readPosts = (params, options, signal) => {
    return customInstance({ url: `/news-feed`, method: 'get', params, signal }, options);
};
export const getReadPostsQueryKey = (params) => [
    `/news-feed`,
    ...(params ? [params] : []),
];
export const useReadPosts = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadPostsQueryKey(params);
    const queryFn = ({ signal, }) => readPosts(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates the content of a post
 */
export const updatePost = (updatePostBody, options) => {
    return customInstance({ url: `/news-feed`, method: 'put', data: updatePostBody }, options);
};
export const useUpdatePost = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updatePost(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a post
 */
export const readPost = (postId, options, signal) => {
    return customInstance({ url: `/news-feed/${postId}`, method: 'get', signal }, options);
};
export const getReadPostQueryKey = (postId) => [`/news-feed/${postId}`];
export const useReadPost = (postId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadPostQueryKey(postId);
    const queryFn = ({ signal, }) => readPost(postId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, { enabled: !!postId, ...queryOptions });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Deletes a post
 */
export const deletePost = (postId, options) => {
    return customInstance({ url: `/news-feed/${postId}`, method: 'delete' }, options);
};
export const useDeletePost = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { postId } = props ?? {};
        return deletePost(postId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Marks a post as read by a specific person
 */
export const markPostAsRead = (readPost, options) => {
    return customInstance({
        url: `/news-feed/read`,
        method: 'patch',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: readPost,
    }, options);
};
export const useMarkPostAsRead = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return markPostAsRead(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Marks a post as liked by a specific person
 */
export const markPostAsLiked = (like, options) => {
    return customInstance({
        url: `/news-feed/like`,
        method: 'patch',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: like,
    }, options);
};
export const useMarkPostAsLiked = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return markPostAsLiked(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Marks a post as liked by a specific person
 */
export const unmarkPostAsLiked = (like, options) => {
    return customInstance({
        url: `/news-feed/unlike`,
        method: 'patch',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: like,
    }, options);
};
export const useUnmarkPostAsLiked = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return unmarkPostAsLiked(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Registers a vote for a question's answer
 */
export const vote = (answerId, options) => {
    return customInstance({ url: `/news-feed/vote/${answerId}`, method: 'patch' }, options);
};
export const useVote = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { answerId } = props ?? {};
        return vote(answerId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
