import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
const styles = StyleSheet.create({
    selectedTag: {
        backgroundColor: COLORS.Disabled.value,
        borderColor: COLORS.Line.value,
        borderWidth: 1,
        borderRadius: 100,
        height: 31,
    },
});
export default styles;
