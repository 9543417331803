import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../contexts/companies';
import { useReadEnrolments } from '../../../services/online/enrolments/enrolments';
import { useDeleteTask, useUpdateTask, } from '../../../services/online/tasks/tasks';
import { createForm } from '../../../utils/formik';
import { validationSchema } from './validations';
const useLogic = (task, onClose) => {
    // Queries
    const { mutateAsync: deleteTask, isLoading: isDeleteLoading } = useDeleteTask();
    const { mutateAsync: updateTask, isLoading: isUpdateLoading } = useUpdateTask();
    // Attributes
    const { t } = useTranslation();
    const isEditable = true; // For now everybody can edit the tasks
    const { selectedCompanyId } = useCompaniesContext();
    const queryClient = useQueryClient();
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    });
    const peopleInTheCompany = useMemo(() => {
        return enrolment?.items?.map(enrolment => enrolment.person);
    }, [enrolment]);
    const initialValues = {
        title: task?.title ?? '',
        description: task?.description ?? '',
        assignedId: task?.personId ?? '',
        completed: !!task?.completedOn,
        creationDate: task?.createdOn ? new Date(task?.createdOn) : new Date(),
        creatorId: task?.creatorId,
        dueDate: task?.deadline ? new Date(task?.deadline) : new Date(),
    };
    // Handlers
    const handleOnSubmit = () => {
        const data = {
            ...task,
            title: values?.title,
            personId: values?.assignedId,
            deadline: values?.dueDate.toISOString(),
            description: values?.description,
            completedOn: values?.completed ? new Date().toISOString() : undefined,
        };
        void updateTask({ data }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(['/tasks']);
                onClose();
            },
        });
    };
    const onDeleteTaskPress = () => {
        void deleteTask({ taskId: task?.id }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(['/tasks']);
                onClose();
            },
        });
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues,
        onSubmit: handleOnSubmit,
        validationSchema: validationSchema(t),
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        onDeleteTaskPress,
        form,
        dirty,
        isEditable,
        isUpdateLoading,
        isDeleteLoading,
        handleOnSubmit: handleSubmit,
        peopleInTheCompany,
    };
};
export default useLogic;
