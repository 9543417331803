import { useEffect, useState } from 'react';
import useRouter from '../../hooks/router/useRouter';
import { useReadTimeZones } from '../../services/online/others/others';
const useLogic = (timezoneId, onChangeTimeZone) => {
    // Attributes
    const { goBack } = useRouter();
    const [selectedTimezone, setSelectedTimezone] = useState(timezoneId);
    const [searchValue, setSearchValue] = useState('');
    // Queries
    const { data: timezones, isLoading: isTimezoneDataLoading } = useReadTimeZones();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPress = (timezoneId) => {
        setSelectedTimezone(timezoneId);
    };
    function handleOnChooseTimeZone(value) {
        onChangeTimeZone(value);
        setSearchValue('');
    }
    // Effects
    useEffect(() => {
        setSelectedTimezone(timezoneId);
    }, [timezoneId]);
    return {
        isTimezoneDataLoading,
        selectedTimezone,
        searchValue,
        handleOnPressBackButton,
        handleOnPress,
        setSearchValue,
        timezones,
        handleOnChooseTimeZone,
    };
};
export default useLogic;
