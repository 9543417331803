import { HStack, Stack, VStack, chakra } from '@chakra-ui/react';
import React from 'react';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
import { useEventDetailsTasksSectionLogic } from './logic.web';
import COLORS from '../../../../../../constants/colors';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper/index.web';
import { TaskTypeEnum } from '../../../../../../enum/TaskType';
import Tag from '../../../../../../components/content/tag/index.web';
import CustomTaskDetail from '../../../../../task-details/custom-task-detail/index.web';
import VoteResults from '../../../../../vote-results/index.web';
import { PublishPattern } from '../../../../../../components/content/icon/icons/publish-pattern';
import { renderToStaticMarkup } from 'react-dom/server';
function EventDetailsTasksSectionComponent({ eventId, refetchEvent, eventTasks, isTasksLoading, refetchEventTasks, }) {
    // Attributes
    const { t } = useTranslation();
    const { handleTaskPress, isOpenTaskDetails, isOpenVoteResult, handleCloseTaskDetails, handleCloseVoteResult, selectedTask, voteResultParams, agendaItems, } = useEventDetailsTasksSectionLogic(eventId, refetchEvent, refetchEventTasks);
    // Functions
    const renderTag = (task) => {
        switch (TaskTypeEnum[task?.type]) {
            case 'Proxy':
                return (<Tag height={20} backgroundColor={COLORS.Govrn.Yellow.T100.value} color={COLORS.Govrn.Yellow.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.PROXY).toUpperCase()}
          </Tag>);
            case 'Vote':
                return (<Tag height={20} backgroundColor={'#E9D6F6'} color={'#AA55E3'} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.VOTES).toUpperCase()}
          </Tag>);
            case 'Read':
                return (<Tag height={20} backgroundColor={COLORS.Govrn.PastelGreen.T100.value} color={COLORS.Govrn.PastelGreen.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.READ).toUpperCase()}
          </Tag>);
            case 'Sign':
                return (<Tag height={20} backgroundColor={COLORS.Warning.T100.value} color={COLORS.Warning.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.SIGN).toUpperCase()}
          </Tag>);
            case 'RSVP':
                return (<Tag height={20} backgroundColor={COLORS.Govrn.Pink.T100.value} color={COLORS.Govrn.Pink.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.RSVP).toUpperCase()}
          </Tag>);
            case 'Custom':
                return (<Tag height={20} backgroundColor={COLORS.Govrn.DarkBlue.T200.value} color={COLORS.Govrn.DarkBlue.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.ACTION).toUpperCase()}
          </Tag>);
        }
    };
    // Render
    if (isTasksLoading)
        return <></>;
    if (!eventTasks?.[0].items.length)
        return (<HStack width={'full'} backgroundColor={COLORS.White.T500.value} p={'1.25rem'} borderWidth={1} borderRadius={'.5rem'} spacing={'1.25rem'}>
        <Stack bgColor={COLORS.Govrn.PastelGreen.T200.value} px={'.5rem'} py={'.35rem'} borderRadius={'.5rem'}>
          <>✅</>
        </Stack>
        <VStack alignItems={'flex-start'} width={'full'}>
          <Text font={FONTS.T1.T16Px.Bold700.value}>
            {t(tKeys.event.details.tasks.placeholder.title)}
          </Text>
          <Text font={FONTS.T1.T14Px.Regular400.value} numberOfLines={2}>
            {t(tKeys.event.details.tasks.placeholder.subtitle)}
          </Text>
        </VStack>
      </HStack>);
    return (<VStack alignItems={'flex-start'} width={'full'} borderRadius={'.5rem'} borderWidth={1} background={`url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<PublishPattern />))}")`} borderColor={COLORS.Line.value} backgroundColor={COLORS.Govrn.Blue.T600.value} py={'1.25rem'} spacing={'1.125rem'}>
      <Stack px={'1.25rem'}>
        <Text color={COLORS.White.T500.value} font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.event.details.tasks.title)}
        </Text>
      </Stack>
      <LoadingWrapper isLoading={isTasksLoading}>
        <VStack alignItems={'flex-start'} width={'full'} spacing={0} px="1.25rem">
          {eventTasks?.[0]?.items?.map((task, index) => {
            const agendaItem = agendaItems?.find(i => i?.id === task?.agendaItemId);
            return (<HStack width={'full'} key={task?.id} 
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => await handleTaskPress(task, agendaItem?.question)} cursor={'pointer'} borderRadius=".5rem" px={'.5rem'} py={'.5rem'} _hover={{ bgColor: COLORS.Govrn.Blue.T300.value }} transition="all 100ms ease-in-out">
                <Stack minW={'fit-content'}>
                  <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.White.T500.value}>
                    {index + 1}
                  </Text>
                </Stack>
                <>{renderTag(task)}</>
                <Text color={COLORS.White.T500.value} font={FONTS.T1.T14Px.Regular400.value} numberOfLines={1}>
                  {task?.title ?? agendaItem?.title}
                </Text>
              </HStack>);
        })}
        </VStack>
      </LoadingWrapper>
      {/* Modals */}
      <CustomTaskDetail task={selectedTask} isOpen={isOpenTaskDetails} onClose={handleCloseTaskDetails}/>
      <VoteResults isOpen={isOpenVoteResult} onClose={handleCloseVoteResult} voteResultParams={voteResultParams}/>
    </VStack>);
}
export const EventDetailsTasksSection = chakra(EventDetailsTasksSectionComponent);
