import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    messageBubbleContainer: { height: '100%', bottom: 1 },
    hiddenAvatarContainer: { width: 28, height: 28 },
    checkBox: { marginLeft: 4 },
    shareContentContainer: {
        marginTop: 5,
        marginBottom: 3,
        marginLeft: 32,
    },
});
export default styles;
