import React from 'react';
import Text from '../../../components/content/text';
import Button from '../../../components/inputs/button';
import { Kind } from '../../../components/inputs/button/constants/kind';
import FormControl from '../../../components/inputs/form-control';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import TaskDetailGroupField from '../../home/more/components/content/group-field/index.web';
import useLogic from './logic.web';
import { Size } from '../../../components/inputs/button/constants/size';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import Icon from '../../../components/content/icon';
import Modal from '../../../components/surfaces/modal/index.web';
import { Box, Checkbox, HStack, Stack, StackDivider, VStack, } from '@chakra-ui/react';
import Input from '../../../components/inputs/input/index.web';
const CustomTaskDetail = ({ isOpen, onClose, task, }) => {
    // Attributes
    const { form, dirty, isEditable, onDeleteTaskPress, isDeleteLoading, isUpdateLoading, handleOnSubmit, peopleInTheCompany, } = useLogic(task, onClose);
    const { t } = useTranslation();
    // Return
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.tasks.task_detail.header.title)} hasCloseButton actionButton={isEditable && (<HStack width={'full'} spacing={'1rem'}>
            <Button kind={Kind.TRANSPARENT} onPress={onDeleteTaskPress} isLoading={isDeleteLoading} backgroundColorPerso={COLORS.White.T500.value} borderColor={COLORS.Error.T500.value}>
              <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                {t(tKeys.tasks.delete.task)}
              </Text>
            </Button>
            <Button size={Size.DEFAULT} kind={Kind.SECONDARY} onPress={handleOnSubmit} isLoading={isUpdateLoading} isDisabled={isUpdateLoading || !dirty}>
              <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
                {t(tKeys.common.save)}
              </Text>
            </Button>
          </HStack>)}>
      <VStack alignItems={'flex-start'} width={'full'} overflow={'auto'} divider={<StackDivider />}>
        {/* Title Input */}
        <VStack p={'1rem'} width={'full'}>
          <FormControl label={t(tKeys.common.title)} isMandatory error={form?.title?.meta?.error} spacing={2}>
            <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder)} autoFocus/>
          </FormControl>
        </VStack>

        {/* Task Creator / Assigned / Creation date / Due date */}
        <Box px={'1rem'} py={'1.25rem'} width={'full'}>
          <TaskDetailGroupField form={form} isEditable={isEditable} peopleInTheCompany={peopleInTheCompany}/>
        </Box>

        {/* Task description */}
        <HStack p={'1rem'} spacing={'.75rem'} width={'full'}>
          <Icon name="sections" width={32} height={32}/>
          <FormControl isMandatory error={form?.description?.meta?.error}>
            <Input value={form?.description.value} onChange={e => {
            form?.description.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.description.placeholder)} autoFocus/>
          </FormControl>
        </HStack>

        {/* Checkbox */}
        <Stack p={'1rem'}>
          <Checkbox isChecked={form?.completed?.value} onChange={e => form?.completed.onChange(!form?.completed.value)}>
            {t(tKeys.task_details.completed)}
          </Checkbox>
        </Stack>
      </VStack>
    </Modal>);
};
export default CustomTaskDetail;
