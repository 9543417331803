import React from 'react';
import Container from '../../../components/surfaces/container';
import styles from './style';
import PageTitle from '../../../components/surfaces/page-title';
import FormControl from '../../../components/inputs/form-control';
import PhoneInput from '../../../components/inputs/phone-input/index.web';
import Text from '../../../components/content/text';
import Button from '../../../components/inputs/button';
import FONTS from '../../../constants/fonts';
import COLORS from '../../../constants/colors';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import useLogic from './logic.web';
import { PageWebAuth } from '../../../components/surfaces/page-auth/index.web';
import { VStack } from '@chakra-ui/react';
import { Kind } from '../../../components/inputs/button/constants/kind';
const SignUpPage = () => {
    // Attributes
    const { form, handleOnSubmit, isAcceptInvitationLoading, handleOnChangeCountry, companyName, inviterName, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<PageWebAuth>
      <VStack width={'full'} alignItems={'flex-start'} px={'2.5rem'} pb={'2.5rem'} pt={'2rem'}>
        {/*  Page title */}
        <PageTitle title={`${t(tKeys['sign-up'].page.title)} ${companyName}`}/>

        {/* Page text */}
        <VStack width={'full'} alignItems={'flex-start'} pt={'1rem'} spacing={'.5rem'} pb={'1.5rem'}>
          <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T500.value} numberOfLines={2}>
            {`${inviterName} ${t(tKeys['sign-up'].page['invitation-message'])}`}
          </Text>
          <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T500.value} numberOfLines={2}>
            {t(tKeys['sign-up'].page['verification-message'])}
          </Text>
        </VStack>

        {/* Your mobile phone input */}
        <FormControl label={t(tKeys['sign-up'].page['mobile-phone'].label)} spacing={4}>
          <PhoneInput {...form?.phoneNumber} onCountryChange={handleOnChangeCountry} country={form?.country.value} autoFocus={true}/>
        </FormControl>

        {/*  Informative text */}
        <Container style={styles.informativeText}>
          <Text font={FONTS.T1.T12Px.Light300.value} color={COLORS.InputText.value}>
            {t(tKeys['sign-up'].page['informative-text'])}
          </Text>
        </Container>

        {/* Button send SMS */}
        <Button onPress={handleOnSubmit} isDisabled={!form?.phoneNumber?.value?.length} isLoading={isAcceptInvitationLoading} kind={Kind.PRIMARY} width={'100%'}>
          {t(tKeys['sign-up'].page['send-verification-code-button'])}
        </Button>
      </VStack>
    </PageWebAuth>);
};
export default SignUpPage;
