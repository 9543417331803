import DoubleAuthentification from './double-authentification';
import CreatePassword from './create-password';
import signInRoutes from './sign-in/routes';
import signUpRoutes from './sign-up/routes';
import { stackNavigator } from '../../routes/navigators';
import { TransitionPresets } from '@react-navigation/stack';
const authRoutes = [
    {
        name: '/auth',
        deepLink: '/auth',
        defaultRoute: '/sign-in',
        component: stackNavigator,
        transition: TransitionPresets.ModalTransition,
        subRoutes: [
            {
                name: '/verification-code',
                deepLink: '/verification-code',
                component: DoubleAuthentification,
                transition: TransitionPresets.ModalTransition,
            },
            {
                name: '/create-password',
                deepLink: '/create-password',
                component: CreatePassword,
            },
            ...signInRoutes,
            ...signUpRoutes,
        ],
    },
];
export default authRoutes;
