import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    modalContainer: {
        marginTop: 32,
        borderTopStartRadius: 14,
        borderTopEndRadius: 14,
        overflow: 'hidden',
    },
    mainContainer: {
        padding: 32,
    },
    applicationLogo: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 24,
    },
    phoneInputContainer: {
        width: 'inherit',
        flex: 1,
        flexGrow: 1,
        alignItems: 'center',
    },
    passwordInputContainer: {
        marginVertical: 28,
        flex: 1,
        flexGrow: 1,
        flexWrap: 'wrap',
    },
    informativeText: {
        color: COLORS.InputText.value,
        marginVertical: 12,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pageParagrapheTextContainer: {
        marginTop: 24,
    },
    pageParagrapheText: {
        paddingVertical: 8,
    },
    buttonContainer: {
        marginTop: 44,
    },
    registerButton: {
        alignSelf: 'flex-end',
    },
});
export default styles;
