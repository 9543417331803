import React from 'react';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { Circle, HStack, Stack } from '@chakra-ui/react';
import Text from '../../../../content/text';
import Image from '../../../../content/image';
import { useCompanyPopupItemLogic } from './logic.web';
export const CompaniesPopupItem = ({ workspace, selectedCompanyId, updateCurrentCompany, isPopUpOpen, }) => {
    // Attributes
    function handleOnChangeOrganisation(workspaceId) {
        updateCurrentCompany(workspaceId);
    }
    const { hasCompanyNews } = useCompanyPopupItemLogic(workspace.id, isPopUpOpen);
    // Render
    return (<HStack alignItems={'center'} px={'1rem'} py={'0.75rem'} spacing={'0.5rem'} _hover={{ backgroundColor: '#F3F3F3' }} onClick={() => handleOnChangeOrganisation(workspace.id)} cursor={'pointer'} width={'20rem'}>
      <Stack borderColor={workspace.id === selectedCompanyId
            ? COLORS.Govrn.Blue.T500.value
            : 'none'} borderWidth={'0.125rem'} borderRadius={'0.4375rem'} padding={'0.125rem'} position="relative" // added to position the circle within this stack
    >
        <Image imageURI={workspace.picture?.uri} width={30} height={30} borderRadius={5} resizeMode="cover"/>
        {hasCompanyNews && (<Circle position={'absolute'} bottom={'-.3125rem'} right={'-.3125rem'} bgColor={COLORS.Error.T500.value} borderWidth={'0.0625rem'} borderColor={COLORS.White.T500.value} size={'.625rem'}/>)}
      </Stack>
      <Text font={hasCompanyNews
            ? FONTS.T1.T14Px.Bold.value
            : FONTS.T1.T14Px.Medium500.value} color={COLORS.Text.T500.value}>
        {workspace.name}
      </Text>
    </HStack>);
};
