import { HStack, Stack, Table, TableContainer, Tbody, VStack, } from '@chakra-ui/react';
import React from 'react';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
import DocumentCard from '../../../../../../../components/surfaces/document-card';
import { ListLayoutEnum } from '../../../../repository/type';
import { Size as DocumentCardSize } from '../../../../../../../components/surfaces/document-card/constants/size';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../../../../../constants/colors';
import { DocumentListTableHeader } from './document-list-table-header/index.web';
import { DocumentListTableRow } from './document-list-table-row/index.web';
export const DocumentListSection = ({ data, title, listLayoutType, kind, onDocumentPress, orderBy, setOrderBy, optionsOrder, }) => {
    // Attributes
    const { t } = useTranslation();
    const displayOneColumn = listLayoutType === ListLayoutEnum.ROW;
    const cardSize = displayOneColumn
        ? DocumentCardSize.COMPACT
        : DocumentCardSize.DEFAULT;
    const isTitleUncategorized = title === 'Uncategorized';
    const isKindDocument = kind === 'document';
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} height={'100%'}>
      {data?.length && (<>
          {!isTitleUncategorized && (<Stack width={'full'} px={'2rem'} py={'1.625rem'}>
              <Text font={FONTS.T1.T16Px.SemiBold600.value}>{t(title)}</Text>
            </Stack>)}

          <VStack alignItems={'flex-start'} width={'100%'} height={'95%'} px={displayOneColumn ? '2rem' : undefined} paddingBottom={'2rem'}>
            {displayOneColumn ? (<TableContainer borderRadius={'.5rem'} borderWidth={1} width={'100%'} overflowY={'auto'} overflowX={'visible'} minHeight={'fit-content'} maxHeight={'100%'} whiteSpace={'wrap'} backgroundColor={COLORS.White.T500.value}>
                <Table>
                  <DocumentListTableHeader orderBy={orderBy} setOrderBy={setOrderBy} optionsOrder={optionsOrder}/>
                  <Tbody>
                    {/* Members list */}
                    {data?.map(document => (<DocumentListTableRow key={document.id} document={document} onClick={onDocumentPress}/>))}
                  </Tbody>
                </Table>
              </TableContainer>) : (<HStack width={'full'} spacing={'1rem'} wrap={'wrap'} px={'2rem'}>
                {data?.map(document => (<DocumentCard key={document.id} size={cardSize} title={document?.title} updateDate={isKindDocument
                        ? document.createdOn
                        : undefined} eventId={isKindDocument
                        ? document.eventId
                        : undefined} onPress={() => onDocumentPress(document)} imageURI={document?.file?.thumbnailUri} titleNbOfLines={2} type={isKindDocument ? 'Repository' : 'KnowledgeBase'}/>))}
              </HStack>)}
          </VStack>
        </>)}
    </VStack>);
};
