import React from 'react';
import Text from '../../components/content/text';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import useSelectBoardRoleLogic from './logic.web';
import { Stack, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
const SelectBoardRole = ({ selectedBoardRoleId, onChange, }) => {
    // Attributes
    const { boardRoles } = useSelectBoardRoleLogic();
    // Render
    return (<VStack minWidth={'17.5rem'} overflow={'auto'} maxHeight={'20rem'} alignItems={'flex-start'} py={'.25rem'} spacing={0}>
      {boardRoles?.map(boardRole => (<Stack _hover={{ backgroundColor: COLORS.BG.value }} backgroundColor={boardRole.id === selectedBoardRoleId ? COLORS.BG.value : undefined} key={boardRole.id} width={'full'} onClick={() => onChange(boardRole)} py={'.5rem'} px={'1rem'} cursor={'pointer'}>
          <Text font={FONTS.T1.T14Px.Regular400.value}>
            {t(boardRole.name)}
          </Text>
        </Stack>))}
    </VStack>);
};
export default SelectBoardRole;
