import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    container: {
        borderRightWidth: 1,
        borderColor: COLORS.Line.value,
    },
    containerChannel: {
        borderColor: COLORS.Line.value,
        backgroundColor: COLORS.BG.value,
    },
    mainContainer: {
        height: '92vh',
        width: '100%',
    },
});
export default styles;
