import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { useCreateChannel, useCreateMessage, } from '../../../../services/online/chat/chat';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { useReadMe } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getStaleTime from '../../../../utils/getStaleTime';
import { validationSchema } from './validations';
import useIsTablet from '../../../../hooks/is-tablet';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { goBack, push, params, getCurrent } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const createMessageBodyEntity = params?.createMessageBodyEntity;
    const isTablet = useIsTablet();
    // Queries
    const { data: me } = useReadMe({ query: { staleTime: getStaleTime() } });
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { mutateAsync: createChannel, isLoading: isCreateChannelLoading } = useCreateChannel();
    const { mutateAsync: createMessage } = useCreateMessage();
    // Data
    const allCompanyMembers = useMemo(() => enrolments?.items
        ?.map(enrolment => enrolment?.person)
        ?.filter(p => p?.id !== me?.person?.id), [enrolments]);
    // Form
    const { values, dirty, isValid, ...rest } = useFormik({
        initialValues: {
            groupName: '',
            participantIds: [],
        },
        onSubmit: handleOnSubmit,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit, resetForm } = rest;
    const form = createForm(values, rest);
    // Functions
    function handleOnSubmit() {
        void createChannel({
            data: {
                companyId: selectedCompanyId,
                name: values?.groupName,
                isGroup: true,
                participantIds: values?.participantIds,
                creatorId: me?.person?.id,
            },
        }, {
            onSuccess: channelId => {
                const params = {
                    channel: {
                        companyId: selectedCompanyId,
                        name: values?.groupName,
                        participantIds: values?.participantIds,
                        creatorId: me?.person?.id,
                        isGroup: true,
                        id: channelId.value,
                    },
                    backSteps: 3,
                };
                if (createMessageBodyEntity) {
                    void handleCreateMessage({ ...createMessageBodyEntity }, { ...params });
                }
                else
                    push('/channel', params);
            },
        });
    }
    async function handleCreateMessage(data, params) {
        // Send the message to the new created channel or exisiting one
        await createMessage({
            data: {
                ...data,
                channelId: params?.channel?.id,
            },
        }, {
            onSuccess: () => {
                if (isTablet) {
                    push('/home/messages', params);
                    return;
                }
                push('/channel', {
                    ...params,
                    backSteps: 3,
                    previousRoute: getCurrent(),
                });
            },
            onError: err => {
                console.error('Error when creating a message when share content : ', err);
                Toast.show({
                    type: 'error',
                    text1: t(tKeys.validation.sharing_content.error),
                });
            },
        });
    }
    function isMemberSelected(member) {
        return values?.participantIds?.some(id => id === member?.id);
    }
    const onSelectMember = (member) => {
        let updatedIds;
        if (isMemberSelected(member)) {
            updatedIds = values?.participantIds?.filter(id => id !== member.id);
        }
        else {
            updatedIds = values?.participantIds?.concat(member?.id);
        }
        form?.participantIds?.onChange(updatedIds);
    };
    // Return
    return {
        goBack,
        form,
        allCompanyMembers,
        isMemberSelected,
        onSelectMember,
        isCreateChannelLoading,
        isSubmitDisabled: !dirty || !isValid,
        handleOnSubmit: handleSubmit,
        resetForm,
    };
};
export default useLogic;
