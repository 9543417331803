import { t } from 'i18next';
import React from 'react';
import { FlatList } from 'react-native';
import Icon from '../../../../../../components/content/icon';
import DocumentCard from '../../../../../../components/surfaces/document-card-event';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import Placeholder from '../../../../../../components/utility/placeholder';
import COLORS from '../../../../../../constants/colors';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic.web';
import styles from './style';
import { Box, HStack, Stack, VStack } from '@chakra-ui/react';
import CreateDocument from '../../../../more/repository/create-document/index.web';
import DropzoneInput from '../../../../../../components/inputs/dropzone/index.web';
const EventDetailsFiles = ({ 
// eslint-disable-next-line react/prop-types
event, 
// eslint-disable-next-line react/prop-types
isAdmin, }) => {
    // Attributes
    const { documents, isDocumentLoading, handleCreateDocument, handleDeleteDocument, handleOnPressDocument, handleOnDrop, selectedFile, isOpenCreateDocument, isCreateDocumentLoading, onCloseCreateDocument, refetchDocs, isLoadingDeleteDocument, } = useLogic(event);
    // Functions
    const ListHeaderComponent = () => {
        if (!documents?.[0]?.items?.length) {
            return <></>;
        }
        return (<VStack w="full" py={'1.25rem'}>
        {isAdmin && (<DropzoneInput isLoading={isCreateDocumentLoading} onDrop={handleOnDrop}/>)}
      </VStack>);
    };
    function ListEmptyComponent() {
        if (isDocumentLoading) {
            return <></>;
        }
        return (<Placeholder imageType="monocle-emoji" hasImage={false} title={t(tKeys.calendar.files.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.calendar.files.placeholder.subTitle)
                : t(tKeys.calendar.files.placeholder.subTitle_invitees)} endEnhancer={isAdmin && (<DropzoneInput isLoading={isCreateDocumentLoading} onDrop={handleOnDrop}/>)}/>);
    }
    function renderDocumentsItems({ item, index, }) {
        return (<Box px={'1rem'} key={index} cursor={'pointer'} onClick={() => handleOnPressDocument(item?.id)}>
        <DocumentCard startEnhancer={<Stack style={styles.documentIcon}>
              <Icon name="files" height={14} width={14} fill={COLORS.InputText.value}/>
            </Stack>} subtitle={item.agendaItemTitle} endEnhancer={<HStack spacing={'.75rem'}>
              {/* Delete document */}
              <Stack borderRadius={'.375rem'} p={'.375rem'} cursor={'pointer'} backgroundColor={COLORS.Text.T500.value} 
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async (e) => {
                    e.stopPropagation(); // Prevent the click from propagating to the parent Box
                    await handleDeleteDocument(item.id);
                }}>
                <Icon name="cross" height={10} width={10} fill={COLORS.White.T500.value}/>
              </Stack>
            </HStack>} width={'100%'}>
          {item?.title ?? item?.file?.filename}
        </DocumentCard>
      </Box>);
    }
    // Render
    return (<Box width={'full'} pb={'2rem'} overflow="scroll">
      {/* Documents list */}

      <LoadingWrapper isLoading={isDocumentLoading || isLoadingDeleteDocument}>
        {/* Header */}
        <FlatList data={documents?.flatMap(i => i?.items) ?? []} renderItem={renderDocumentsItems} ListHeaderComponent={ListHeaderComponent} ListEmptyComponent={ListEmptyComponent} style={{ width: '100%' }}/>
      </LoadingWrapper>

      {/* Modal */}
      <CreateDocument isOpen={isOpenCreateDocument} onClose={onCloseCreateDocument} selectedFile={selectedFile} refetchDocuments={refetchDocs}/>
    </Box>);
};
export default EventDetailsFiles;
