import { useFocusEffect } from '@react-navigation/native';
import React, { useState } from 'react';
import { useCompaniesContext } from '../../../../../contexts/companies';
import { useReadEventTypes, useDeleteEventType, } from '../../../../../services/online/calendar/calendar';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { tKeys } from '../../../../../translations/keys';
const useLogic = () => {
    // Queries
    const { t } = useTranslation();
    const toast = useToast();
    const { selectedCompanyId } = useCompaniesContext();
    const { data: eventTypes, refetch: refetchEventTypes, isLoading: isReadEventTypeLoading, } = useReadEventTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { mutateAsync: deleteEventType } = useDeleteEventType();
    // Attributes
    const [selectedEventType, setSelectedEventType] = useState();
    const { isOpen: isOpenEventType, onOpen: onOpenEventType, onClose: onCloseEventType, } = useDisclosure();
    // Effects
    useFocusEffect(React.useCallback(() => {
        void refetchEventTypes();
    }, []));
    // Functions
    const handleOnPressModifyEventType = (eventType) => {
        setSelectedEventType(eventType);
        onOpenEventType();
    };
    const handleOnPressAddEventType = () => {
        setSelectedEventType(undefined);
        onOpenEventType();
    };
    const handleOnPressDeleteEventType = (eventTypeId) => {
        void deleteEventType({
            eventTypeId,
        }, {
            onSuccess: () => {
                void refetchEventTypes();
                toast({
                    status: 'success',
                    title: t(tKeys.settings.event_item.deleted.success),
                });
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.validation.common.error_occuried),
                });
                console.error('Error when delete a event type : ', err);
            },
        });
    };
    const handleOnPressEditableMenu = (item) => {
        if (!item.isEditable) {
            return undefined;
        }
        return handleOnPressModifyEventType(item);
    };
    function handleRefetchEventTypes() {
        void refetchEventTypes();
    }
    // Return
    return {
        handleOnPressEditableMenu,
        handleOnPressDeleteEventType,
        eventTypes,
        handleOnPressAddEventType,
        isReadEventTypeLoading,
        selectedEventType,
        isOpenEventType,
        onCloseEventType,
        handleRefetchEventTypes,
    };
};
export default useLogic;
