import React from 'react';
import { Checkbox, HStack, Stack, StackDivider, Switch, VStack, } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import Modal from '../../../../../../components/surfaces/modal/index.web';
import { useEditGroupLogic } from './logic.web';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import FormControl from '../../../../../../components/inputs/form-control';
import Input from '../../../../../../components/inputs/input/index.web';
import Icon from '../../../../../../components/content/icon';
import TagField from '../../../../calendar/components/inputs/tag-field';
import COLORS from '../../../../../../constants/colors';
import AvatarDetails from '../../../../../../components/content/avatar-details';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
export const EditGroup = ({ isOpen, onClose, refetchGroups, group, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isCreateGroupLoading, isEditMode, isUpdateGroupLoading, handleSubmit, setSearch, peopleInTheCompany, handleModalClose, form, isPersonSelected, allowedHistoricalDataForPerson, handlePersonCheck, handleAllowHistoricalDataGiven, } = useEditGroupLogic(group, refetchGroups, onClose);
    // Render
    return (<Modal isOpen={isOpen} onClose={handleModalClose} size={'3xl'} title={isEditMode ? t(tKeys.groups.edit) : t(tKeys.groups.add)} actionButton={<HStack spacing={'.5rem'}>
          <Button kind={Kind.SECONDARY} onPress={handleSubmit} isLoading={isCreateGroupLoading || isUpdateGroupLoading}>
            {t(tKeys.common.save)}
          </Button>
        </HStack>} hasCloseButton>
      {/* Name of the group */}
      <VStack width={'100%'} alignItems={'flex-start'} divider={<StackDivider />} minHeight={'32rem'}>
        <Stack p={'1rem'} width={'100%'}>
          <FormControl label={t(tKeys.common.name.label)} isMandatory error={form?.name?.meta?.error} spacing={4}>
            <Input value={form?.name.value} onChange={e => form?.name?.onChange(e.currentTarget.value)} placeholder={t(tKeys.messages.groupName.placeholder)} autoFocus/>
          </FormControl>
        </Stack>
        {/* Selection of persons for the group */}
        <VStack width={'100%'} alignItems={'flex-start'}>
          <TagField title={t(tKeys.more.page.menu.members.menu_title)} startEnhancer={<Icon name="invitees"/>} endEnhancer={<HStack spacing={'.5rem'}>
                <Input name={'search'} onChange={e => setSearch(e?.currentTarget?.value)} placeholder={t(tKeys.search.placeholder.no_results.title)} width={'20rem'} height={'2.5rem'} backgroundColor={COLORS.SearchFullScreen.value}/>
              </HStack>} error={form?.persons?.meta?.error}/>

          {/* Members list */}
          <VStack alignItems={'flex-start'} width={'full'} px={'1.75rem'} maxHeight={'18.75rem'} overflow={'auto'} zIndex={1} spacing={'1rem'}>
            {peopleInTheCompany.map(person => (<HStack justify="space-between" key={person.id} width={'100%'}>
                <AvatarDetails person={person} showStatus avatarSize={'2.125rem'} showBoardRole/>
                <HStack>
                  {isPersonSelected(person.id) && (<HStack py={'.375rem'} px={'.5rem'} borderRadius={'6.25rem'} borderWidth={1} borderColor={COLORS.Line.value} spacing={'.5rem'} width={'max-content'}>
                      <Switch isChecked={allowedHistoricalDataForPerson(person.id)} onChange={() => handleAllowHistoricalDataGiven(person.id)}/>
                      <Text font={FONTS.T1.T12Px.Medium500.value}>
                        {t(tKeys.groups.allow_historical_data)}
                      </Text>
                    </HStack>)}
                  <Checkbox onChange={() => handlePersonCheck(person.id)} isChecked={isPersonSelected(person.id)}/>
                </HStack>
              </HStack>))}
          </VStack>
        </VStack>
      </VStack>
    </Modal>);
};
