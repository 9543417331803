import React from 'react';
import COLORS from '../../../../../../../../constants/colors';
import FONTS from '../../../../../../../../constants/fonts';
import Text from '../../../../../../../../components/content/text';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../../../translations/keys';
import { HStack, Stack } from '@chakra-ui/react';
import Input from '../../../../../../../../components/inputs/input/index.web';
function CardCreatePollInput({ onPressDelete, onChange, value, placeholder, }) {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<HStack justify="space-between" width={'full'} spacing={'1rem'}>
      {/* Input */}
      <Input value={value} onChange={e => onChange(e?.currentTarget?.value)} placeholder={placeholder}/>

      {/* Delete button */}
      <Stack onClick={onPressDelete} cursor={'pointer'} px={'.5rem'}>
        <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText.value}>
          {t(tKeys.news_feed.delete_post.title)}
        </Text>
      </Stack>
    </HStack>);
}
export default CardCreatePollInput;
