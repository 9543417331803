import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { useCompaniesContext } from '../../../contexts/companies';
import { useReadPerson } from '../../../services/online/people/people';
import getUserName from '../../../utils/getUserName';
import Avatar from '../avatar';
import Text from '../text';
import { useTranslation } from 'react-i18next';
import { HStack, Stack, VStack } from '@chakra-ui/react';
const AvatarDetails = ({ personId, avatarSize = 38, person, subtitleColor = COLORS.InputText.value, titleColor = COLORS.Text.T400.value, showBoardRole, avatarFont = FONTS.T1.T10Px.SemiBold600.value, subTitleEnhancer, title, showStatus, titleFont = FONTS.T1.T12Px.Medium500.value, subtitleFont = FONTS.T1.T12Px.Regular400.value, picture, }) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { t } = useTranslation();
    // Query
    const { data: selectedPersonData } = useReadPerson(personId ?? '', {
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!personId } });
    const personData = person ?? selectedPersonData;
    // Functions
    function displayBoardRoleName() {
        if (!showBoardRole || !personData)
            return;
        if (personData?.boardRoleName) {
            return (<Text font={subtitleFont} color={subtitleColor}>
          {t(personData?.boardRoleName)}
        </Text>);
        }
        if (personData?.enrolments?.[0]?.boardRoleName) {
            return (<Text font={subtitleFont} color={subtitleColor}>
          {t(personData?.enrolments?.[0]?.boardRoleName)}
        </Text>);
        }
    }
    // Return
    return (<HStack spacing={'.5rem'} width={'100%'}>
      <Stack minH={avatarSize} minW={avatarSize}>
        <Avatar width={avatarSize} height={avatarSize} name={personData ? getUserName(personData) : title} imageSource={picture ?? personData?.picture?.uri} placeholderFont={avatarFont} isOnline={showStatus ? personData?.status : null}/>
      </Stack>

      <VStack width={'100%'} alignItems={'flex-start'} spacing={'.25rem'}>
        <Text font={titleFont} color={titleColor}>
          {personData ? getUserName(personData) : title}
        </Text>

        {/* Show board role name */}
        {displayBoardRoleName()}

        {subTitleEnhancer || null}
      </VStack>
    </HStack>);
};
export default AvatarDetails;
