import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    itemContainer: {
        borderRadius: 8,
        padding: 2,
    },
    list: {
        marginTop: 8,
    },
    header: { marginBottom: 20 },
});
export default styles;
