/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { t } from 'i18next';
import React from 'react';
import { FlatList, View } from 'react-native';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import SearchInput from '../../../../components/inputs/search-input';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Container from '../../../../components/surfaces/container';
import Page from '../../../../components/surfaces/page';
import PlaceholderNoInvitees from '../../../../components/utility/placeholder-no-invitees';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import getStatusFromInvitee from '../../../../utils/getStatusFromInvitee';
import EventDetailsInformationsInvitees from '../event-details-informations-invitees';
import useLogic from './logic';
import styles from './style';
const EventDetailsInviteesPage = () => {
    // Attributes
    const { event, goBack, form, handlePressOnAddInvitees, isAdmin, inviteesPeople, isEventPast, } = useLogic();
    const renderItems = ({ item, index }) => {
        // Attribute
        const userStatus = getStatusFromInvitee(item?.personId, !!item?.proxyId, event?.presentAttendeeIds, event?.absentAttendeeIds, event?.remoteAttendeeIds, item);
        // Render
        return (<Container style={styles.mainContainer}>
        <EventDetailsInformationsInvitees personId={item?.id ?? item?.personId} userStatus={userStatus} proxyPersonId={item?.proxyId} key={index}/>
      </Container>);
    };
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader onGoBack={goBack} leftIconName={'chevron-left'} justifyTitle={'center'} endEnhancer={isAdmin &&
            !isEventPast && (<Button kind={Kind.TRANSPARENT} onPress={handlePressOnAddInvitees}>
              <Icon name="add" width={24} height={24}/>
            </Button>)}>
        <Container marginLeft={8} maxWidth={250}>
          <Text font={FONTS.T1.T16Px.Bold700.value}>
            {`${t(tKeys.home.calendar.event_details.invitees.status.title)} (${inviteesPeople?.length ?? 0})`}
          </Text>
        </Container>
      </NavigationHeader>

      {/* Main content */}
      <SearchInput {...form?.searchValue} placeholder={t(tKeys.common.search)} isClearable={form?.searchValue?.value?.length > 0} onPressClear={() => form?.searchValue?.onChange('')} testID={'manageEventSearchInput'}/>

      <FlatList contentContainerStyle={styles.flatList} data={inviteesPeople ?? []} renderItem={renderItems} ItemSeparatorComponent={() => <View style={styles.separator}/>} showsVerticalScrollIndicator={false} ListEmptyComponent={!inviteesPeople?.length ? <PlaceholderNoInvitees /> : null}/>
    </Page>);
};
export default EventDetailsInviteesPage;
