import React from 'react';
import Messages from '.';
import Icon from '../../../components/content/icon';
import { stackNavigator } from '../../../routes/navigators';
import { RouteEnum } from '../routeEnum';
const messagesRoutes = [
    {
        name: '/messages',
        deepLink: '/messages',
        defaultRoute: '/default',
        component: stackNavigator,
        activeIcon: (<Icon name="navigation-bar-messages-selected" width={24} height={24}/>),
        inactiveIcon: (<Icon name="navigation-bar-messages-default" width={24} height={24}/>),
        routeEnum: RouteEnum.Messages,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                tabBarLabel: 'home.messages.navHeader.title',
                component: Messages,
            },
        ],
    },
];
export default messagesRoutes;
