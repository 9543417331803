// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Shape = {
    DEFAULT: 'DEFAULT',
    ROUND: 'ROUND',
    PILL: 'PILL',
    ROUNDED_LARGE: 'ROUNDED_LARGE',
};
const Shapes = {
    [Shape.DEFAULT]: {
        borderRadius: 8,
    },
    [Shape.ROUNDED_LARGE]: {
        borderRadius: 12,
    },
    [Shape.ROUND]: {
        borderRadius: 100,
    },
    [Shape.PILL]: {
        borderRadius: 50,
    },
};
export default Shapes;
