import React from 'react';
import More from '.';
import Icon from '../../../components/content/icon';
import { stackNavigator } from '../../../routes/navigators';
import Disconnect from './disconnect';
import Repository from './repository';
import KnowledgeBase from './knowledge-base';
import settingsRoutes from './settings/routes';
import Members from './members';
const moreRoutes = [
    // More route
    {
        name: '/more',
        deepLink: '/more',
        component: stackNavigator,
        activeIcon: (<Icon name="navigation-bar-more-selected" width={24} height={24}/>),
        inactiveIcon: (<Icon name="navigation-bar-more-default" width={24} height={24} testID={'moreButton'}/>),
        tabBarLabel: 'common.more',
        defaultRoute: '/default',
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: More,
            },
            {
                name: '/repository',
                deepLink: '/repository',
                tabBarLabel: 'more.page.menu.repository.menu_title',
                component: Repository,
            },
            {
                name: '/knowledge-base',
                deepLink: '/knowledge-base',
                tabBarLabel: 'more.page.menu.knowledge-base.menu_title',
                component: KnowledgeBase,
            },
            {
                name: '/members',
                deepLink: '/members',
                tabBarLabel: 'more.page.menu.members.menu_title',
                component: Members,
            },
            ...settingsRoutes,
            {
                name: '/disconnect',
                deepLink: '/disconnect',
                component: Disconnect,
            },
        ],
    },
];
export default moreRoutes;
