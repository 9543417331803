import * as Yup from 'yup';
import { tKeys } from '../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        name: Yup.string()
            .min(3, t(tKeys.validation.createGroupChannel.name.minLength))
            .required(t(tKeys.validation.createGroupChannel.name.required)),
        picture: Yup.object().nullable(),
        members: Yup.array().min(2, t(tKeys.validation.createGroupChannel.participants.minLength)),
    });
};
