import * as Yup from 'yup';
import { tKeys } from '../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        newMemberEmail: Yup.string()
            .email()
            .required(t(tKeys.validation.enter_valid_email)),
        newMemberRole: Yup.string().required(t(tKeys.invitation.select_member_role.error)),
    });
};
