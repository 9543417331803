import { Platform, View } from 'react-native';
import React from 'react';
const Container = ({ children, style, flex = 0, flexGrow = 0, testID, ...rest }) => {
    // Style specific to the web
    const webStyles = Platform.OS === 'web' ? { minHeight: 'auto', minWidth: 'auto' } : {};
    return (<View style={[
            { flex, flexDirection: 'column', flexGrow },
            webStyles,
            style,
            rest, // It's generally not a good idea to spread `rest` into `style`. It's better to define explicitly what props you are accepting
        ]} testID={testID}>
      {children}
    </View>);
};
export default Container;
