import React from 'react';
import { Stack, Td, Tr } from '@chakra-ui/react';
import FONTS from '../../../../../../../../constants/fonts';
import Text from '../../../../../../../../components/content/text/index.web';
import moment from 'moment';
import { dateFormatFrench } from '../../../../../../../../constants/date-format';
import Image from '../../../../../../../../components/content/image';
import { useReadEvent } from '../../../../../../../../services/online/calendar/calendar';
import { tKeys } from '../../../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
export const DocumentListTableRow = ({ document, onClick, }) => {
    // Attributes
    const { t } = useTranslation();
    // Queries
    const { data: eventData } = useReadEvent(document.eventId, {
        query: {
            enabled: !!document.eventId,
            staleTime: 60000, // 1 minute of delay
        },
    });
    // Render
    return (<Tr width={'100%'} onClick={() => onClick(document)} cursor={'pointer'} role="group">
      <Td pl={'.75rem'} pr={'1rem'} py={'.75rem'}>
        <Stack height={'2.25rem'} width={'1.75rem'} minW={'1.75rem'} minH={'2.25rem'}>
          <Image borderRadius={4} imageURI={document.file?.thumbnailUri ??
            require('../../../../../../../../assets/images/pdf_preview.png')} width={'100%'} height={'100%'} isLocalImage={!document.file?.thumbnailUri}/>
        </Stack>
      </Td>
      {/* Document title */}
      <Td px={'1rem'} py={'.75rem'} maxWidth={'25rem'} _groupHover={{ textDecor: 'underline' }}>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} numberOfLines={1}>
          {document?.title}
        </Text>
      </Td>
      {/* Document type On */}
      <Td px={'1rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Medium500.value} maxWidth={'20rem'} numberOfLines={3}>
          {t(document.typeName)}
        </Text>
      </Td>
      {/* Issued On */}
      <Td px={'1rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Medium500.value}>
          {moment(document.issuedOn).format(dateFormatFrench)}
        </Text>
      </Td>
      {/* Updated date */}
      <Td px={'1rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Medium500.value}>
          {moment(eventData?.startDate ?? document.createdOn).format(dateFormatFrench)}
        </Text>
      </Td>
      {/* Event linked */}
      <Td px={'1rem'} py={'.75rem'}>
        <Text font={eventData
            ? FONTS.T1.T12Px.Medium500.value
            : FONTS.T1.T12Px.SemiBold600.value} maxWidth={'20rem'} numberOfLines={3}>
          {eventData?.name ?? t(tKeys.documents.no_event_linked)}
        </Text>
      </Td>
    </Tr>);
};
