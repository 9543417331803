import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Pressable, ScrollView } from 'react-native';
import Avatar from '../../../components/content/avatar';
import Icon from '../../../components/content/icon';
import Text from '../../../components/content/text';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Container from '../../../components/surfaces/container';
import Page from '../../../components/surfaces/page';
import RenderMenu from '../../../components/utils/render-menu';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { Role } from '../../../services/types';
import { tKeys } from '../../../translations/keys';
import getUserName from '../../../utils/getUserName';
import useLogic from './logic';
import styles from './style';
const More = () => {
    // Attributes
    const { handleNavigateTo, currentUserData, isReadMeLoading, selectedEnrolment, onSearchPress, } = useLogic();
    const { t } = useTranslation();
    const menuItems = [
        //  First menu with news feed, knowledge base and members
        {
            name: t(tKeys.common.menu),
            items: [
                {
                    startEnhancer: (<Container borderRadius={8} padding={8} backgroundColor={'#E7F3FB'}>
              <Icon name="navigation-bar-home-selected" width={32} height={32}/>
            </Container>),
                    title: t(tKeys.page.menu['news-feed']),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/home/more/feed/default'),
                },
                {
                    startEnhancer: <Icon name="knowledge-base" width={36} height={36}/>,
                    title: t(tKeys.more.page.menu['knowledge-base'].menu_title),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/home/more/knowledge-base'),
                    titleEnhancer: (<Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
              {t(tKeys.more.page.menu.knowledge_base.explanations)}
            </Text>),
                },
                {
                    startEnhancer: <Icon name="members" width={36} height={36}/>,
                    title: t(tKeys.more.page.menu.members.menu_title),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/home/more/members'),
                    titleEnhancer: (<Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
              {t(tKeys.more.page.menu.members_explanations)}
            </Text>),
                },
            ],
        },
        // Second menu with user info and settings
        {
            name: t(tKeys.common.parameters),
            items: [
                {
                    startEnhancer: (<Avatar name={getUserName(currentUserData?.person)} width={36} height={36} borderRadius={8} placeholderFont={FONTS.T1.T16Px.Medium500.value} imageSource={isReadMeLoading ? '' : currentUserData?.person?.picture?.uri} isOnline={currentUserData?.person?.status}/>),
                    title: !isReadMeLoading
                        ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            getUserName(currentUserData?.person)
                        : '',
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} testID={'userProfileButton'} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/profile'),
                    titleEnhancer: (<Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
              {t(tKeys.more.page.menu.parameters.explanations)}
            </Text>),
                },
                // Only show if user is admin
                selectedEnrolment?.role === Role?.[0]
                    ? {
                        startEnhancer: (<Icon name="group-settings" width={36} height={36} testID={'groupSettingsButtons'}/>),
                        title: t(tKeys.more.page.parameters['group-settings']),
                        endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                        onPress: () => handleNavigateTo('/home/more/settings'),
                        titleEnhancer: (<Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
                  {t(tKeys.more.page.parameters.group_settings.explanation)}
                </Text>),
                    }
                    : null,
            ],
        },
        // Third menu with helpdesk
        {
            name: t(tKeys.common['need-help']),
            items: [
                {
                    startEnhancer: <Icon name="helpdesk" width={36} height={36}/>,
                    title: t(tKeys.more.page['need-help'].helpdesk),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onPress: async () => await Linking.openURL('https://www.govrn.com/r/Fuz'),
                    titleEnhancer: (<Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
              {t(tKeys.more.page.helpdesk.explanation)}
            </Text>),
                },
            ],
        },
        // Fourth menu with disconnect
        {
            name: t(tKeys.more.page.leaving.title),
            items: [
                {
                    startEnhancer: <Icon name="disconnect" width={36} height={36}/>,
                    titleEnhancer: undefined,
                    title: t(tKeys.more.page.leaving.disconnect),
                    endEnhancer: (<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>),
                    onPress: () => handleNavigateTo('/home/more/disconnect'),
                },
            ],
        },
    ];
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.BG.value} showBottomNavBar testID={'morePage'}>
      <NavigationHeader justifyTitle={'flex-start'} endEnhancer={<Pressable hitSlop={8} onPress={onSearchPress}>
            <Icon name="search" width={24} height={24} color={COLORS.Text.T500.value}/>
          </Pressable>}>
        {t(tKeys.common.more)}
      </NavigationHeader>

      <Container style={styles.mainContainer}>
        <ScrollView showsVerticalScrollIndicator={false} style={styles.flatList}>
          <RenderMenu menuItems={menuItems}/>
        </ScrollView>
      </Container>
    </Page>);
};
export default More;
