import React from 'react';
import List from '../../components/content/list';
import useLogic from './logic.web';
import { VStack } from '@chakra-ui/react';
const SelectEventType = ({ eventType, onChange, onToggle, }) => {
    // Logic
    const { options, handleOnClick } = useLogic(onChange, eventType, onToggle);
    // Return
    return (<VStack px={'1rem'} minWidth={'15rem'} maxHeight={'20rem'} overflow={'auto'}>
      <List name={'id'} onChange={handleOnClick} value={eventType?.id} options={options} style={{ minWidth: '15rem' }}/>
    </VStack>);
};
export default SelectEventType;
