import { Platform } from 'react-native';
import RNFetchBlob from 'rn-fetch-blob';
async function getDocumentData(document) {
    // Determine the URI of the selected document
    const documentUri = Platform?.OS === 'android'
        ? document?.uri
        : decodeURIComponent(document?.uri)?.replace('file://', '');
    // Initialize a variable to store the base64-encoded data of the document
    let base64Data;
    try {
        // Read the file from the device and store the base64-encoded data in the base64Data variable
        await RNFetchBlob.fs
            .readFile(documentUri, 'base64')
            .then(data => {
            base64Data = data;
        })
            .catch(err => {
            console.error('Error when getting the base64 data from document : ', err);
        });
    }
    catch (err) {
        console.error('Error when getting the base64 data from document : ', err);
    }
    // Return the base64-encoded data of the document
    return base64Data;
}
export default getDocumentData;
