import React, { useMemo } from 'react';
import Text from '../../../../../../../components/content/text';
import Card from '../../../../../../../components/surfaces/card/index.web';
import Container from '../../../../../../../components/surfaces/container';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import NewsFeedPostModal from '../../modal/news-feed-modal/index.web';
import CardFooter from '../card-footer';
import CardHeader from '../card-header/index.web';
import CardPreviewWebLink from '../card-preview-web-link';
import { regexUrl } from '../../../../../../../utils/regexUrl';
import { t } from 'i18next';
import Icon from '../../../../../../../components/content/icon';
import { Stack } from '@chakra-ui/react';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
const CardPost = ({ styles, index, itemPost, currentUserId, handleOnPressMessage, creator, isCreatorLoading, isPostLikedByUser, handleIconPress, showPostModal, handleEditPost, handleDeletePost, handleDismissModal, handleLike, isDeletePostLoading, onPressMemberName, footerSeparator, isPostDetails, }) => {
    // Attributes
    const postDescription = useMemo(() => itemPost?.content.replaceAll(regexUrl, function (url, b, c) {
        const uriFormatted = c?.toLocaleLowerCase()?.trim();
        const url2 = uriFormatted === 'www.'
            ? 'https://' + url
            : url?.replaceAll('http://', 'https://');
        return url2?.trim()?.toLocaleLowerCase();
    }), [itemPost?.content]);
    const postHasURL = postDescription.match(regexUrl);
    // Render
    if (isCreatorLoading) {
        return <></>;
    }
    // Render
    return (<Card isPostDetails={isPostDetails} header={<CardHeader imageUri={creator?.picture?.uri} title={`${creator?.firstname ?? ''} ${creator?.lastname ?? ''}`} titleFont={FONTS.T1.T14Px.SemiBold600.value} subTitle={t(creator?.enrolments[0]?.boardRoleName)} imageHeight={33} imageWidth={33} onPressMemberName={onPressMemberName} memberId={creator?.id} actionButton={currentUserId === creator?.id && (<PopOver from={<Icon name={'navigation-bar-more-default'} width={24} height={24}/>} isPortal={!isPostDetails}>
                <NewsFeedPostModal handleEditPost={handleEditPost} handleDeletePost={handleDeletePost} handleOnDismiss={handleDismissModal} isDeletePostLoading={isDeletePostLoading}/>
              </PopOver>)}/>} footer={<CardFooter date={itemPost?.createdOn ? new Date(itemPost?.createdOn) : new Date()} currentUserId={currentUserId} postId={itemPost?.id} onPressMessage={handleOnPressMessage} isInsightfulSelected={isPostLikedByUser} handleLikePost={handleLike} footerSeparator={footerSeparator}/>}>
      {postDescription && (<Stack width={'full'} p={'1rem'}>
          <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T400.value} numberOfLines={100}>
            {postDescription?.replace(regexUrl, '')}
          </Text>
        </Stack>)}
      <Container style={styles.cardContainerContainer}>
        <CardPreviewWebLink content={postDescription} regExpressionUrl={regexUrl}/>
      </Container>
    </Card>);
};
export default CardPost;
