import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    dateContainer: {
        flex: 1,
        flexGrow: 1,
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    commentsPressable: {
        marginHorizontal: 8,
    },
    separator: { marginHorizontal: -24, marginBottom: 8, marginTop: 28 },
});
export default styles;
