import ManageEvents from '.';
import { stackNavigator } from '../../../../../routes/navigators';
import AddEventType from './add-event-type';
import ModifyEventType from './modify-event-type';
const manageEventsRoutes = [
    {
        name: '/manage-events',
        deepLink: '/manage-events',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: ManageEvents,
            },
            {
                name: '/add-event-type',
                deepLink: '/add-event-type',
                component: AddEventType,
            },
            {
                name: '/modify-event-type',
                deepLink: '/modify-event-type',
                component: ModifyEventType,
            },
        ],
    },
];
export default manageEventsRoutes;
