import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.BG.value,
        flex: 1,
        flexGrow: 1,
    },
    filterContainer: {
        alignItems: 'center',
        zIndex: 50,
    },
    contentContainerStyle: {
        marginVertical: 6,
    },
    taskTitleContainer: {
        marginBottom: 16,
    },
    flatlistContent: {
        paddingTop: 28,
        paddingHorizontal: 32,
    },
    tabsContainer: {
        marginTop: -2, // To hide the grey line between tab and navigation header
    },
    listStyle: {
        width: '100%',
        height: '100%',
        paddingBottom: '1rem',
    },
    listHeaderComponentStyle: {
        zIndex: 50,
        marginBottom: 12,
    },
    filterButton: { padding: 6, justifyContent: 'center', borderRadius: 6 },
});
export default styles;
