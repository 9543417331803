import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../../components/inputs/form-control';
import Input from '../../../../components/inputs/input/index.web';
import { tKeys } from '../../../../translations/keys';
import useLogic from './logic';
import { PageWebAuth } from '../../../../components/surfaces/page-auth/index.web';
import { VStack, Stack, Button } from '@chakra-ui/react';
import COLORS from '../../../../constants/colors';
const SignInForgotPasswordAddressMail = () => {
    // Attributes
    const { handleOnPressBackButton, form, handlerOnSubmit, isForgotPasswordLoading, } = useLogic();
    const { t } = useTranslation();
    const ConfirmButton = (<Stack pt={'3.5rem'} width={'full'}>
      <Button 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onClick={handlerOnSubmit} isDisabled={!form?.email?.value?.length} isLoading={isForgotPasswordLoading} width="full" textColor={COLORS.White.T500.value} fontSize={14} bgColor={COLORS.Govrn.Blue.T500.value} _hover={{ bgColor: COLORS.Govrn.Blue.T600.value }}>
        {t(tKeys.common.confirm)}
      </Button>
    </Stack>);
    // Render
    return (<PageWebAuth onGoBack={handleOnPressBackButton} title={t(tKeys['sign-in-forgot-mail']['navigation-header'])}>
      <VStack alignItems={'flex-start'} width={'full'} p={'2.5rem'}>
        {/*  Email address  */}
        <FormControl label={t(tKeys['sign-in-forgot-mail']['email-address'].label)} helper={t(tKeys['sign-in-forgot-mail']['email-address'].helper)} error={form?.email?.meta?.error} spacing={2}>
          <Input onChange={e => form?.email?.onChange?.(e?.currentTarget?.value)} type="emailAddress" value={form?.email.value} placeholder={t(tKeys['sign-in-forgot-mail']['email-address'].placeholder)} autoFocus/>
        </FormControl>
        {ConfirmButton}
      </VStack>
      {/* <Toast position="top" config={ToastConfiguration} topOffset={60} /> */}
    </PageWebAuth>);
};
export default SignInForgotPasswordAddressMail;
