import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Container from '../../../../../../components/surfaces/container';
import Placeholder from '../../../../../../components/utility/placeholder';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import AgendaItem from '../agenda-item/index.web';
import useLogic from './logic.web';
import { Box, HStack, Stack, StackDivider, VStack } from '@chakra-ui/react';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper/index.web';
import EditAgendaItem from '../../../edit-agenda-item/index.web';
import VoteResults from '../../../../../vote-results/index.web';
import { useDrop } from 'react-dnd';
import { AddButtonSubAgendaItem } from '../agenda-item/add-sub-agenda-item/index.web';
import { BusyIndicator } from '../../../../../../components/utility/busy-indicator';
const EventDetailsAgenda = ({ event, isCurrentUserInvited, isCurrentUserPresent, refetchEventTasks, isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    const { agendaItems, onEditAgendaItem, onSelectAgendaItem, onAddPress, isAgendaItemsLoading, isVoteEnabled, onVotePress, isOpenEditAgendaItem, onCloseEditAgendaItem, agendaItemEntity, refetchAgendaItems, isOpenVoteResult, onCloseVoteResult, voteResultParams, handleDropAgendaItem, handleAgendaItemsRefetch, redirectToBoardPack, } = useLogic(event);
    const [{ isOver }, drop] = useDrop({
        accept: 'agendaItem',
        collect: monitor => ({
            isOver: !!monitor.isOver({ shallow: true }),
        }),
        drop: (item, monitor) => {
            if (!monitor.isOver({ shallow: true }))
                return;
            void handleDropAgendaItem(item.agendaItemId, null, 0, true);
        },
    });
    // Render
    return (<LoadingWrapper isLoading={isAgendaItemsLoading}>
      <VStack alignItems={'flex-start'} width={'full'} spacing={'0.75rem'} pb={'2rem'} overflow={'auto'}>
        <BusyIndicator isEmpty={!agendaItems || agendaItems?.length <= 0} placeholder={<Placeholder imageType="monocle-emoji" hasImage={false} title={t(tKeys.calendar.agenda.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.calendar.agenda.placeholder.subtitle)
                : t(tKeys.calendar.agenda.placeholder.subtitle_invitees)} endEnhancer={isAdmin && (<Box marginTop={'1.25rem'}>
                    <Button kind={Kind.SECONDARY} onPress={onAddPress} startEnhancer={() => (<Container marginLeft={8}>
                          <Icon name="add" width={20} height={20} fill={COLORS.White.T500.value}/>
                        </Container>)}>
                      <Stack pl={'.5rem'}>
                        <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                          {t(tKeys.calendar.agenda.placeholder.button)}
                        </Text>
                      </Stack>
                    </Button>
                  </Box>)}/>}>
          <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
            <HStack justify="space-between" px={'1rem'} py={'1.25rem'} width={'full'}>
              <Text font={FONTS.T1.T16Px.SemiBold600.value}>
                {t(tKeys.common.agenda)}
              </Text>
              <Button onPress={redirectToBoardPack} kind={Kind.OUTLINE}>
                {t(tKeys.agenda_items.view_board_pack)}
              </Button>
            </HStack>
            <VStack width={'full'} px={'1rem'} py={'1rem'} divider={isOver && (<StackDivider borderColor={COLORS.Govrn.Blue.T500.value}/>)}>
              {isAdmin && agendaItems.length > 0 && (<Stack ref={drop} width={'100%'}>
                  <AddButtonSubAgendaItem isOver={isOver} title={t(tKeys.agenda_item.drop_in_first)}/>
                </Stack>)}
              <VStack width={'100%'} spacing={0}>
                {agendaItems.map((agendaItem, index) => (<AgendaItem key={agendaItem.id} onSelect={onSelectAgendaItem} eventId={event?.id} eventStartDate={event?.startDate} agendaItem={agendaItem} isSelected={agendaItem.isCompleted ?? false} onEditPress={onEditAgendaItem} onSeeResultsPress={onVotePress} isVoteEnabled={isVoteEnabled} onVotePress={onVotePress} isCurrentUserInvited={isCurrentUserInvited} isCurrentUserPresent={isCurrentUserPresent} index={index + 1} onSubAgendaItemAdd={onAddPress} level={1} parentIndexes={[index + 1]} handleDropAgendaItem={handleDropAgendaItem} isAdmin={isAdmin} 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        refetchAgendaItems={handleAgendaItemsRefetch}/>))}
                {isAdmin && (<HStack width={'100%'} px={'.75rem'} py={'.75rem'} borderWidth={1} borderColor={COLORS.Stroke.value} borderStyle={'dashed'} borderRadius={'.5rem'} cursor={'pointer'} onClick={() => onAddPress()} backgroundColor={isOver && COLORS.Govrn.Blue.T100.value} textColor={isOver && COLORS.Govrn.Blue.T500.value}>
                    <Text>{t(tKeys.agenda_item.main_agenda_item.add)}</Text>
                  </HStack>)}
              </VStack>
            </VStack>
          </VStack>
        </BusyIndicator>
        {/* Modals */}
        <EditAgendaItem isOpen={isOpenEditAgendaItem} onClose={onCloseEditAgendaItem} agendaItemEntity={agendaItemEntity} refetchAgendaItems={() => {
            void refetchAgendaItems();
            refetchEventTasks();
        }} isAdmin={isAdmin} event={event}/>
        <VoteResults isOpen={isOpenVoteResult} onClose={onCloseVoteResult} voteResultParams={voteResultParams}/>
      </VStack>
    </LoadingWrapper>);
};
export default EventDetailsAgenda;
