import { useDisclosure } from '@chakra-ui/react';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
import { TaskTypeEnum } from '../../../../../../enum/TaskType';
import useRouter from '../../../../../../hooks/router/useRouter';
import { useUpdateTask } from '../../../../../../services/online/tasks/tasks';
import { DocumentKindEnum, } from '../../../../../document-details/type';
import { useState } from 'react';
import { useReadAgendaItems } from '../../../../../../services/online/calendar/calendar';
export const useEventDetailsTasksSectionLogic = (eventId, refetchEvent, refetchEventTasks) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { push } = useRouter();
    const [selectedTask, setSelectedTask] = useState();
    const [voteResultParams, setVoteResultParams] = useState();
    const { isOpen: isOpenTaskDetails, onOpen: onOpenTaskDetails, onClose: onCloseTaskDetails, } = useDisclosure();
    const { isOpen: isOpenVoteResult, onOpen: onOpenVoteResult, onClose: onCloseVoteResult, } = useDisclosure();
    // Fetch agendaItems
    const { data: agendaItems } = useReadAgendaItems({ 'event-id': eventId });
    const { mutateAsync: updateTask } = useUpdateTask();
    // Functions
    async function handleTaskPress(task, question) {
        if (task.type === TaskTypeEnum?.Custom) {
            setSelectedTask(task);
            onOpenTaskDetails();
            return;
        }
        if (task.type === TaskTypeEnum?.Vote) {
            const params = {
                question,
                eventId: task?.eventId,
                canUserVote: true,
            };
            setVoteResultParams(params);
            onOpenVoteResult();
            return;
        }
        if (task.type === TaskTypeEnum.RSVP) {
            const params = { eventId: task?.eventId };
            push('/company/event-details', {
                companyId: selectedCompanyId,
                ...params,
            });
            return;
        }
        // Set the task as completed when redirecting to document details
        void updateTask({
            data: { ...task, completedOn: new Date().toISOString() },
        });
        refetchEventTasks();
        const documentParams = {
            documentKind: DocumentKindEnum.DOCUMENT,
            taskType: task.type,
            documentId: task.documentId,
            eventId: task.eventId,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...documentParams,
        });
    }
    function handleCloseTaskDetails() {
        onCloseTaskDetails();
        refetchEvent();
        refetchEventTasks();
    }
    function handleCloseVoteResult() {
        onCloseVoteResult();
        refetchEvent();
        refetchEventTasks();
    }
    // Return
    return {
        handleTaskPress,
        selectedTask,
        handleCloseTaskDetails,
        isOpenTaskDetails,
        voteResultParams,
        isOpenVoteResult,
        handleCloseVoteResult,
        agendaItems,
    };
};
