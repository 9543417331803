import React from 'react';
import { Button, HStack, Popover, PopoverContent, PopoverTrigger, Stack, StackDivider, VStack, useDisclosure, } from '@chakra-ui/react';
import Text from '../../../../../components/content/text';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../translations/keys';
import FONTS from '../../../../../constants/fonts';
import { DocumentDetailsInformationBox } from './informations-box/index.web';
import useFormatDate from '../../../../../hooks/format-date/useFormatDate';
import { dateFormatFrench } from '../../../../../constants/date-format';
import Tag from '../../../../../components/content/tag/index.web';
import COLORS from '../../../../../constants/colors';
import Icon from '../../../../../components/content/icon';
export const DocumentDetailsMainInformations = ({ document, documentType, creator, linkedEvent, documentHistory, onChooseVersion, currentSelectedVersionId, onClickUploadNewVersion, isTemplate, }) => {
    // Attributes
    const { t } = useTranslation();
    const { formatDate } = useFormatDate();
    const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'}>
      {!isTemplate && (<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
          <PopoverTrigger>
            <Button variant={'ghost'} _hover={{ backgroundColor: 'transparent' }} onClick={onToggle} px={0}>
              <Tag hasEndEnhancer backgroundColor={COLORS.Tag.value} style={{ borderRadius: 100 }} color={COLORS.Text.T400.value}>
                {`v${document?.version}`}
              </Tag>
            </Button>
          </PopoverTrigger>
          <PopoverContent overflow={'hidden'} width="full">
            <VStack alignItems={'flex-start'} width={'18.75rem'} spacing={0}>
              {documentHistory?.map(document => (<Stack px={'1rem'} py={'.5rem'} key={document?.versionId} bgColor={document?.versionId === currentSelectedVersionId
                    ? COLORS.Tag.value
                    : undefined} _hover={{ bgColor: COLORS.Tag.value }} cursor={'pointer'} width={'full'} onClick={() => {
                    onChooseVersion(document?.versionId);
                    onToggle();
                }}>
                  <Text>{`v${document?.version}`}</Text>
                </Stack>))}
              <StackDivider borderWidth={0.5}/>
              <HStack width={'full'} px={'.875rem'} py={'.75rem'} _hover={{ bgColor: COLORS.Tag.value }} cursor={'pointer'} onClick={onClickUploadNewVersion}>
                <Icon name="add" height={16} width={16} fill={COLORS.Text.T400.value}/>
                <Text font={FONTS.T1.T14Px.Medium500.value}>
                  {t(tKeys.document.details.upload)}
                </Text>
              </HStack>
            </VStack>
          </PopoverContent>
        </Popover>)}
      {/* Title */}
      <HStack width={'full'} pb={'.375rem'} spacing={'1rem'} justifyContent={'space-between'}>
        <Text numberOfLines={10} font={FONTS.T1.T20Px.Bold700.value}>
          {document?.title}
        </Text>
      </HStack>
      {/* Description */}
      <Stack width={'full'} pb={'.5rem'}>
        <Text numberOfLines={100} font={FONTS.T1.T12Px.Regular400.value}>
          {document?.description}
        </Text>
      </Stack>
      {/* File type */}
      {!isTemplate && (<DocumentDetailsInformationBox title={t(tKeys.document.details.file_type)} information={t(documentType?.name)}/>)}
      {/* Issued on */}
      <DocumentDetailsInformationBox title={t(tKeys.common.issued_on)} information={formatDate({
            date: document?.issuedOn,
            formatWithTimezone: false,
            format: dateFormatFrench,
        })}/>
      {/* Created by */}
      {!isTemplate && (<DocumentDetailsInformationBox title={t(tKeys.taskDetail.taskCard.createdBy)} information={`${creator?.firstname} ${creator?.lastname}`}/>)}
      {/* Linked Event */}
      {linkedEvent && !isTemplate && (<DocumentDetailsInformationBox title={t(tKeys.document.details.linked_event)} information={linkedEvent?.name}/>)}
    </VStack>);
};
