import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, SectionList } from 'react-native';
import BannerMessage from '../../../../components/content/banner-message';
import ListItemSeparator from '../../../../components/content/list-item-separator';
import Flex from '../../../../components/surfaces/flex';
import LoadingWrapper from '../../../../components/utility/loading-wrapper';
import Placeholder from '../../../../components/utility/placeholder';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import ChatBox from '../components/content/chat-box';
import MessageListItemComponent from '../components/list/message-list-item-component';
import useLogic from './logic.web';
import styles from './style';
const Channel = ({ selectedChannel }) => {
    // Attributes
    const { t } = useTranslation();
    const { form, isSendDisabled, isSendMessageLoading, formattedMessages, companyEnrolements, me, isMessagesLoading, onDeleteIconPress, onConfirmDeleteMessages, onEphemeralPress, isDeleteMessageLoading, handleOnSelectDocument, onAddFilePress, isDocumentModalOpen, listRef, setShowScrollButton, } = useLogic({
        selectedChannel,
    });
    const isSendMessageDisabled = isSendDisabled ||
        isSendMessageLoading ||
        (form?.message.value === '' && !form?.file);
    // Render ListEmptyComponent
    const renderPlaceholder = () => {
        if (isMessagesLoading)
            return <></>;
        return (<Flex justify="center" style={{ ...styles.placeholder }}>
        <Placeholder imageType="writing-hand-emoji" title={t(tKeys.private_chat.placeholder.title)} subTitle={t(tKeys.private_chat.placeholder.subtitle)}/>
      </Flex>);
    };
    // Return
    return (<>
      <LoadingWrapper isLoading={isMessagesLoading}>
        <Stack justifyContent={'space-between'} height={'full'}>
          {!isMessagesLoading && formattedMessages?.length === 0 ? (renderPlaceholder()) : (<>
              {form?.isEphemeral?.value ? (<BannerMessage text={t(tKeys.messages.chat.ephemeral.banner)} backgroundColor={COLORS.Govrn.Blue.T100.value} textColor={COLORS.Govrn.Blue.T500.value} textFont={FONTS.T1.T12Px.Medium500.value}/>) : null}
              <SectionList ref={listRef} showsVerticalScrollIndicator={false} stickySectionHeadersEnabled={false} keyExtractor={(_, index) => `${index}`} sections={formattedMessages ?? []} inverted onScroll={event => {
                setShowScrollButton(event.nativeEvent.contentOffset.y > 100);
            }} renderItem={({ item: allMessages }) => (<FlatList data={allMessages} keyExtractor={(_, index) => `${index}`} renderItem={({ item: message, index }) => (<MessageListItemComponent message={message} allMessages={allMessages} index={index} companyEnrolements={companyEnrolements} currentUser={me} form={form}/>)}/>)} renderSectionFooter={({ section }) => (<ListItemSeparator title={section.title}/>)}/>
            </>)}
          <ChatBox form={form} onDeleteIconPress={onDeleteIconPress} isSendMessageDisabled={isSendMessageDisabled} isSendMessageLoading={isSendMessageLoading} onConfirmDeleteMessages={onConfirmDeleteMessages} onEphemeralPress={onEphemeralPress} isDeleteMessageLoading={isDeleteMessageLoading} handleOnSelectDocument={handleOnSelectDocument} onAddFilePress={onAddFilePress} isDocumentModalOpen={isDocumentModalOpen}/>
        </Stack>
      </LoadingWrapper>
    </>);
};
export default Channel;
