import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        marginHorizontal: 12,
        width: '100%',
        height: '100%',
    },
    sectionContainer: {
        marginVertical: 10,
    },
    sectionRulesContainer: {
        marginVertical: 6,
    },
});
export default styles;
