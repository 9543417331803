import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        borderTopWidth: 1,
        borderTopColor: COLORS.Line.value,
        borderBottomColor: COLORS.White.T500.value,
        width: '100%',
        backgroundColor: COLORS.White.T500.value,
        paddingTop: 16,
        paddingHorizontal: 16,
        paddingBottom: 24,
    },
    container: {
        paddingHorizontal: 12,
        marginBottom: 14,
    },
    text: {
        color: COLORS.InputText.value,
        paddingHorizontal: 6,
    },
});
export default styles;
