import React from 'react';
import CardActiveTasks from '../card-active-tasks/index.web';
import CardNextEvent from '../card-next-event/index.web';
import useLogic from './logic.web';
import { VStack } from '@chakra-ui/react';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import CustomTaskDetail from '../../../../../../task-details/custom-task-detail/index.web';
import VoteResults from '../../../../../../vote-results/index.web';
import LoadingWrapper from '../../../../../../../components/utility/loading-wrapper/index.web';
const CardUserTasksAndNextEvent = ({ closestEvents, isLoadingEvent, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isLoading, onTaskPress, userTasks, handleOnPressEvent, currentUser, isOpenTaskDetails, isOpenVoteResult, onCloseTaskDetails, onCloseVoteResult, selectedTask, voteResultParams, } = useLogic();
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} spacing={'2rem'}>
      {/* Title */}
      <VStack width={'full'} alignItems={'flex-start'}>
        <Text font={FONTS.T1.T16Px.Bold700.value}>
          {t(tKeys.homepage.hello, { value: currentUser?.firstname })}
        </Text>
        <Text font={FONTS.T1.T14Px.Regular400.value}>
          {t(tKeys.homepage.description)}
        </Text>
      </VStack>

      <LoadingWrapper isLoading={isLoadingEvent && isLoading}>
        <VStack width={'full'} alignItems={'flex-start'} spacing={'2rem'}>
          <CardNextEvent events={closestEvents} handleOnPressEvent={handleOnPressEvent}/>

          {/*  Active Tasks */}
          {userTasks?.length > 0 && (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <CardActiveTasks onTaskPress={onTaskPress} tasks={userTasks}/>)}
        </VStack>
      </LoadingWrapper>
      {/* Modals */}
      <CustomTaskDetail task={selectedTask} isOpen={isOpenTaskDetails} onClose={onCloseTaskDetails}/>
      <VoteResults isOpen={isOpenVoteResult} onClose={onCloseVoteResult} voteResultParams={voteResultParams}/>
    </VStack>);
};
export default CardUserTasksAndNextEvent;
