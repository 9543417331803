import React from 'react';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
import { Box, HStack, Stack } from '@chakra-ui/react';
const FieldItem = ({ onPress, label, children, isDisabled = false, renderValue, }) => {
    // Handler
    const handlePress = () => {
        if (!isDisabled) {
            if (onPress)
                onPress();
        }
    };
    // Render
    return (<>
      <HStack onClick={handlePress} width={'full'} cursor={'pointer'} justifyContent={'space-between'} p={'.75rem'} height={'3rem'}>
        <Stack minW={'fit-content'}>
          <Text font={FONTS.T1.T12Px.Medium500.value}>{label}</Text>
        </Stack>
        {renderValue}
      </HStack>
      <Box width={'full'}>{children}</Box>
    </>);
};
export default FieldItem;
