import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    innerButtonContainer: {
        justifyContent: 'center',
        minWidth: 110,
    },
    textContainer: {
        marginLeft: 8,
    },
});
export default styles;
