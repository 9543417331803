/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a custom task
 */
export const createTask = (task, options) => {
    return customInstance({
        url: `/tasks`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: task,
    }, options);
};
export const useCreateTask = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createTask(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of tasks for a specific person
 */
export const readTasks = (params, options, signal) => {
    return customInstance({ url: `/tasks`, method: 'get', params, signal }, options);
};
export const getReadTasksQueryKey = (params) => [
    `/tasks`,
    ...(params ? [params] : []),
];
export const useReadTasks = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadTasksQueryKey(params);
    const queryFn = ({ signal, }) => readTasks(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a task
 */
export const updateTask = (task, options) => {
    return customInstance({
        url: `/tasks`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: task,
    }, options);
};
export const useUpdateTask = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateTask(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes a task
 */
export const deleteTask = (taskId, options) => {
    return customInstance({ url: `/tasks/${taskId}`, method: 'delete' }, options);
};
export const useDeleteTask = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { taskId } = props ?? {};
        return deleteTask(taskId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
