import React, { useState } from 'react';
import Icon from '../../../../../components/content/icon';
import { Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import FONTS from '../../../../../constants/fonts';
function PasswordInput(props) {
    // Attributes
    const { placeholder, value } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const { fontFamily, fontSize, fontWeight } = FONTS.T1.T14Px.Regular400.value;
    // Functions
    const handleOnPress = () => {
        setShowPassword(!showPassword);
    };
    function handleOnFocus() {
        setTimeout(() => setIsReadOnly(false), 100);
    }
    function handleOnBlur() {
        setIsReadOnly(true);
    }
    const renderShowPasswordComponent = () => {
        if (!value) {
            return <></>;
        }
        // Render
        return (<Stack onClick={handleOnPress}>
        {showPassword ? (<Icon name="show-password-disabled" width={20} height={20}/>) : (<Icon name="show-password-enabled" width={20} height={20}/>)}
      </Stack>);
    };
    // Render
    return (<InputGroup>
      {props.hasAutoComplete ? (<Input value={props?.value} onChange={e => props.onChange?.(e?.currentTarget?.value)} type={showPassword ? 'text' : 'password'} placeholder={placeholder} pl={'.5rem'} fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} onKeyDown={props.onKeyDown}/>) : (<Input value={props?.value} onChange={e => props.onChange?.(e?.currentTarget?.value)} type={showPassword ? 'text' : 'password'} placeholder={placeholder} pl={'.5rem'} autoComplete="new-password" onFocus={handleOnFocus} onBlur={handleOnBlur} readOnly={isReadOnly} fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} onKeyDown={props.onKeyDown}/>)}
      <InputRightElement>{renderShowPasswordComponent()}</InputRightElement>
    </InputGroup>);
}
export default PasswordInput;
