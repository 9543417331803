import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    menuItemContainer: {
        marginHorizontal: 16,
    },
    innerItem: {
        margin: 16,
    },
});
export default styles;
