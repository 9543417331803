import { useState } from 'react';
import { useAddGroupToDocument, useAddPersonToDocument, useRemoveGroupFromDocument, useRemovePersonFromDocument, } from '../../../services/online/repository/repository';
import { useCompaniesContext } from '../../../contexts/companies/index.web';
import { useReadEnrolments } from '../../../services/online/enrolments/enrolments';
import { useReadGroups } from '../../../services/online/groups/groups';
import { useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';
export const useDocumentDetailsPermissionsLogic = (permissions, documentId, refetchPermissions) => {
    // Attributes
    const [search, setSearch] = useState('');
    const { selectedCompanyId } = useCompaniesContext();
    const searchPopoverDisclosure = useDisclosure();
    // Queries
    const { mutateAsync: addGroupToDocument, isLoading: isAddGroupLoading } = useAddGroupToDocument();
    const { mutateAsync: addPersonToDocument, isLoading: isAddPersonLoading } = useAddPersonToDocument();
    const { mutateAsync: removeGroupFromDocument } = useRemoveGroupFromDocument();
    const { mutateAsync: removePersonFromDocument } = useRemovePersonFromDocument();
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': search,
    });
    const peopleNotInvited = enrolments?.items?.filter(people => !permissions?.persons
        .map(person => person.id)
        .includes(people?.person?.id));
    const { data: groups } = useReadGroups({
        'company-id': selectedCompanyId,
        'search-term': search,
    });
    const groupsNotInvited = groups?.filter(group => !permissions?.groups.map(group => group.id).includes(group.id));
    // Functions
    const handleSearchChange = _.debounce((value) => {
        setSearch(value);
    }, 500);
    async function handleGroupAddToDocument(groupId) {
        try {
            await addGroupToDocument({
                data: {
                    companyId: selectedCompanyId,
                    documentId,
                    groupId,
                },
            });
            refetchPermissions();
            searchPopoverDisclosure.onClose();
        }
        catch (err) {
            console.error(`something goes wrong when add the group : ${groupId} to the document
        err : `, err);
        }
    }
    async function handlePersonAddToDocument(personId) {
        try {
            await addPersonToDocument({
                data: {
                    companyId: selectedCompanyId,
                    documentId,
                    personId,
                },
            });
            refetchPermissions();
            searchPopoverDisclosure.onClose();
        }
        catch (err) {
            console.error(`something goes wrong when add the person : ${personId} to the document
        err : `, err);
        }
    }
    async function handleGroupRemoveFromDocument(groupId) {
        try {
            await removeGroupFromDocument({ documentId, groupId });
            refetchPermissions();
        }
        catch (err) {
            console.error(`something goes wrong when remove the group : ${groupId} from the document
        err : `, err);
        }
    }
    async function handlePersonRemoveFromDocument(personId) {
        try {
            await removePersonFromDocument({ documentId, personId });
            refetchPermissions();
        }
        catch (err) {
            console.error(`something goes wrong when remove the person : ${personId} from the document
        err : `, err);
        }
    }
    // Return
    return {
        isAddGroupLoading,
        isAddPersonLoading,
        handleSearchChange,
        peopleNotInvited,
        groupsNotInvited,
        searchPopoverDisclosure,
        handleGroupAddToDocument,
        handleGroupRemoveFromDocument,
        handlePersonAddToDocument,
        handlePersonRemoveFromDocument,
    };
};
