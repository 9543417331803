import React from 'react';
export class DocumentView extends React.Component {
    render() {
        return (<div style={{ width: '100%', height: '100%', backgroundColor: 'lightgray' }}>
        <p style={{ textAlign: 'center', paddingTop: '50%' }}>
          Le composant PDFTron n'est pas disponible sur le Web.
        </p>
      </div>);
    }
}
export function initialize() {
    console.warn('PDFTron initialize() ne fait rien sur le Web.');
}
module.exports = {
    RNPdftron: {
        initialize: () => { },
        enableJavaScript: () => { },
        // include any other functions you use
    },
};
