import React from 'react';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
import { Stack, VStack, useDisclosure } from '@chakra-ui/react';
import COLORS from '../../../../../../../constants/colors';
import Icon from '../../../../../../../components/content/icon';
import { ShowMembersFilterRow } from './show-members-filter-row/index.web';
import { useTranslation } from 'react-i18next';
import { ShowMembers } from '../../../../members/types';
import { tKeys } from '../../../../../../../translations/keys';
export const ShowMembersFilter = ({ onShowMembersFilterChange, showMembersFilter, }) => {
    // Attributes
    const { t } = useTranslation();
    const showMembersFilterDiscolure = useDisclosure();
    // Render
    return (<PopOver isOpen={showMembersFilterDiscolure.isOpen} onToggle={showMembersFilterDiscolure.onToggle} from={<Stack p={'.5rem'} borderWidth={1} borderRadius={'.5rem'} bgColor={COLORS.White.T500.value} cursor={'pointer'}>
          <Icon name="filter" height={24} width={20} fill={COLORS.White.T500.value}/>
        </Stack>} placement="bottom-start">
      <VStack alignItems={'flex-start'} spacing={0} py={'.5rem'} minW={'18rem'}>
        <ShowMembersFilterRow title={t(tKeys.members.filter.show_all)} onShowMembersFilterChange={onShowMembersFilterChange} currentShowMembersFilter={showMembersFilter} rowFilter={ShowMembers.ALL}/>
        <ShowMembersFilterRow title={t(tKeys.members.filter.show_active)} onShowMembersFilterChange={onShowMembersFilterChange} currentShowMembersFilter={showMembersFilter} rowFilter={ShowMembers.ACTIVE}/>
        <ShowMembersFilterRow title={t(tKeys.members.filter.show_inactive)} onShowMembersFilterChange={onShowMembersFilterChange} currentShowMembersFilter={showMembersFilter} rowFilter={ShowMembers.INACTIVE}/>
      </VStack>
    </PopOver>);
};
