import React from 'react';
import Icon from '../../../content/icon';
import Container from '../../../surfaces/container';
import Flex from '../../../surfaces/flex';
import Toast from 'react-native-toast-message';
import { Pressable } from 'react-native';
import COLORS from '../../../../constants/colors';
import Text from '../../../content/text';
import FONTS from '../../../../constants/fonts';
import styles from './style';
// const ToastConfiguration = {
//   // Overwrite 'success' type
//   success: (props: ToastShowParams) => (
//     <Flex
//       style={{
//         ...styles.toastContainer,
//         backgroundColor: COLORS.White.T500.value,
//       }}
//       justify="space-between"
//     >
//       {/* Icon success */}
//       <Flex direction="row" flex={1} flexGrow={1}>
//         <Container
//           style={{
//             ...styles.iconContainer,
//             backgroundColor: COLORS.Success.T500.value,
//           }}
//         >
//           <Icon name="toast-success" width={18} height={18} />
//         </Container>
//         {/* Text */}
//         <Flex flex={1} flexGrow={1} align="center">
//           <Text
//             font={FONTS.T1.T12Px.Medium500.value}
//             color={COLORS.Text.T500.value}
//             numberOfLines={2}
//           >
//             {props?.text1}
//           </Text>
//         </Flex>
//       </Flex>
//       {/* Close icon */}
//       <Container style={styles.crossIconContainer}>
//         <Pressable onPress={() => Toast.hide()}>
//           <Icon
//             name="close-cross"
//             width={20}
//             height={20}
//             fill={COLORS.Text.T500.value}
//           />
//         </Pressable>
//       </Container>
//     </Flex>
//   ),
//   // Overwrite 'error' type
//   error: (props: ToastShowParams) => (
//     <Flex
//       style={{
//         ...styles.toastContainer,
//         backgroundColor: COLORS.White.T500.value,
//       }}
//       justify="space-between"
//     >
//       <Flex direction="row" flex={1} flexGrow={1} spacing={16}>
//         {/* Icon error */}
//         <Container
//           style={{
//             ...styles.iconContainer,
//             backgroundColor: COLORS.Error.T500.value,
//           }}
//         >
//           <Icon name="toast-error" width={18} height={18} />
//         </Container>
//         {/* Text */}
//         <Flex flex={1} flexGrow={1} align="center">
//           <Text
//             font={FONTS.T1.T12Px.Medium500.value}
//             color={COLORS.Text.T500.value}
//             numberOfLines={2}
//           >
//             {props?.text1}
//           </Text>
//         </Flex>
//       </Flex>
//       {/* Close icon */}
//       <Container style={styles.crossIconContainer}>
//         <Pressable onPress={() => Toast.hide()}>
//           <Icon
//             name="close-cross"
//             width={20}
//             height={20}
//             fill={COLORS.Text.T500.value}
//           />
//         </Pressable>
//       </Container>
//     </Flex>
//   ),
// };
const ToastConfiguration = {
    // Overwrite 'success' type
    success: (props) => (<Pressable onPress={props.onPress}>
      <Flex style={{
            ...styles.toastContainer,
            backgroundColor: COLORS.White.T500.value,
        }} justify="space-between">
        {/* Icon success */}
        <Flex direction="row" flex={1} flexGrow={1}>
          <Container style={{
            ...styles.iconContainer,
            backgroundColor: COLORS.Success.T500.value,
        }}>
            <Icon name="toast-success" width={18} height={18}/>
          </Container>

          {/* Text */}
          <Flex flex={1} flexGrow={1} direction="column">
            <Flex flex={1} flexGrow={1} align="center">
              <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T500.value} numberOfLines={2}>
                {props?.text1}
              </Text>
            </Flex>
            {props?.text2 && (<Flex flex={1} flexGrow={1} align="center">
                <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T500.value} numberOfLines={2} style={{
                textDecorationLine: 'underline',
                textDecorationStyle: 'solid',
            }}>
                  {props?.text2}
                </Text>
              </Flex>)}
          </Flex>
        </Flex>

        {/* Close icon */}
        <Container style={styles.crossIconContainer}>
          <Pressable onPress={() => Toast.hide()}>
            <Icon name="close-cross" width={20} height={20} fill={COLORS.Text.T500.value}/>
          </Pressable>
        </Container>
      </Flex>
    </Pressable>),
    // Overwrite 'error' type
    error: (props) => (<Pressable onPress={props.onPress}>
      <Flex style={{
            ...styles.toastContainer,
            backgroundColor: COLORS.White.T500.value,
        }} justify="space-between">
        <Flex direction="row" flex={1} flexGrow={1} spacing={16}>
          {/* Icon error */}
          <Container style={{
            ...styles.iconContainer,
            backgroundColor: COLORS.Error.T500.value,
        }}>
            <Icon name="toast-error" width={18} height={18}/>
          </Container>

          {/* Text */}
          <Flex flex={1} flexGrow={1} direction="column">
            <Flex flex={1} flexGrow={1} align="center">
              <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T500.value} numberOfLines={2}>
                {props?.text1}
              </Text>
            </Flex>
            {props?.text2 && (<Flex flex={1} flexGrow={1} align="center">
                <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T500.value} numberOfLines={2} style={{
                textDecorationLine: 'underline',
                textDecorationStyle: 'solid',
            }}>
                  {props?.text2}
                </Text>
              </Flex>)}
          </Flex>
        </Flex>
        {/* Close icon */}
        <Container style={styles.crossIconContainer}>
          <Pressable onPress={() => Toast.hide()}>
            <Icon name="close-cross" width={20} height={20} fill={COLORS.Text.T500.value}/>
          </Pressable>
        </Container>
      </Flex>
    </Pressable>),
};
export default ToastConfiguration;
