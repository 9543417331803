import React from 'react';
import Flex from '../../surfaces/flex';
export const BusyIndicator = ({ isLoading, isEmpty, placeholder, loading, children, }) => {
    // Render
    if (isLoading && loading) {
        return (<Flex justify="center" align="center">
        {loading}
      </Flex>);
    }
    if (isEmpty && placeholder) {
        return placeholder;
    }
    return children;
};
