import React from 'react';
import { HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { GroupAvatars } from '../../../../../../../components/content/group-avatars/index.web';
import Icon from '../../../../../../../components/content/icon';
import COLORS from '../../../../../../../constants/colors';
export const EventDetailsInviteesGroupRow = ({ canRemoveGroup, groupId, onGroupRemove, }) => {
    // Attributes
    const removeGroupDisclosure = useDisclosure();
    // Render
    return (<HStack key={groupId} width={'100%'} justifyContent={'space-between'} _hover={{ bgColor: COLORS.Tag.value }} py={'.25rem'} px={'1.25rem'} onMouseEnter={removeGroupDisclosure.onOpen} onMouseLeave={removeGroupDisclosure.onClose}>
      <GroupAvatars groupId={groupId} showNumberOfMembers/>
      {/* Remove group */}
      {canRemoveGroup && removeGroupDisclosure.isOpen && (<Stack cursor={'pointer'} onClick={() => {
                onGroupRemove(groupId);
            }} borderRadius={'1.5rem'} borderWidth={1} p={'.25rem'}>
          <Icon name={'close-cross'} height={20} width={20}/>
        </Stack>)}
    </HStack>);
};
