import { useState } from 'react';
import useCreateGroupChannelLogic from '../create-group-channel/logic';
export const useWebLogic = (onMemberPress, onClose, onCreateGroup) => {
    // Atrirbutes
    const [isCreateGroup, setIsCreateGroup] = useState(false);
    // Logic
    const { onSelectMember, form, allCompanyMembers, resetForm } = useCreateGroupChannelLogic();
    // Functions
    function onChangeSwitchCreateGroup() {
        // Remove all participants previously selected if we don't create a group
        if (isCreateGroup)
            form.participantIds.onChange?.([]);
        setIsCreateGroup(!isCreateGroup);
    }
    function handleOnPressMember(person) {
        if (isCreateGroup)
            return;
        onMemberPress(person);
        onClose();
    }
    function resetInformations() {
        void resetForm();
        setIsCreateGroup(false);
    }
    function handleOnCreateGroup() {
        void onCreateGroup(form.groupName.value, form.participantIds.value);
        onClose();
        resetInformations();
    }
    const hasParticipantsSelected = form.participantIds.value.length > 0;
    const isCreateButtonDisabled = !isCreateGroup || !hasParticipantsSelected || form.groupName.value === '';
    // Return
    return {
        allCompanyMembers,
        isCreateGroup,
        onChangeSwitchCreateGroup,
        handleOnPressMember,
        isCreateButtonDisabled,
        form,
        handleOnCreateGroup,
        onSelectMember,
    };
};
