export function findClosestEventFromToday(events) {
    // Get the current date and time
    const currentDate = new Date();
    // Copy the eventData object and filter it to include only future events
    const eventDataCopy = Object.assign([], events).filter(event => {
        const eventDate = new Date(event.startDate);
        return eventDate > currentDate;
    });
    // Sort the events by ascending order of difference from the current date
    eventDataCopy.sort((a, b) => {
        const eventATimestamp = new Date(a.startDate).getTime();
        const eventBTimestamp = new Date(b.startDate).getTime();
        const eventADiff = Math.abs(currentDate.getTime() - eventATimestamp);
        const eventBDiff = Math.abs(currentDate.getTime() - eventBTimestamp);
        return eventADiff - eventBDiff;
    });
    // Return the first event in the sorted list when no publish
    return eventDataCopy.find(event => event.publishedOn);
}
export function findClosestEventsFromToday(events) {
    // Get the current date and time
    const currentDate = new Date();
    // Copy the eventData object and filter it to include only future events
    const eventDataCopy = Object.assign([], events).filter(event => {
        const eventDate = new Date(event.startDate);
        return eventDate > currentDate;
    });
    // Sort the events by ascending order of difference from the current date
    eventDataCopy.sort((a, b) => {
        const eventATimestamp = new Date(a.startDate).getTime();
        const eventBTimestamp = new Date(b.startDate).getTime();
        const eventADiff = Math.abs(currentDate.getTime() - eventATimestamp);
        const eventBDiff = Math.abs(currentDate.getTime() - eventBTimestamp);
        return eventADiff - eventBDiff;
    });
    // Return the first event in the sorted list when no publish
    return eventDataCopy.filter(event => event.publishedOn).slice(0, 3);
}
