import { HStack, Stack, VStack } from '@chakra-ui/react';
import Button from '../../../../../components/inputs/button/index.web';
import React from 'react';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../translations/keys';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import Icon from '../../../../../components/content/icon';
import Text from '../../../../../components/content/text';
import FONTS from '../../../../../constants/fonts';
import COLORS from '../../../../../constants/colors';
export const DocumentDetailsHeader = ({ isDownloadable, isTemplate, isDownloading, handleDownloadPress, handleOnPressMessage, userCanEditDocument, handleOnPressEditDocument, handleOnPressDelete, isDeleteDocumentLoading, isOriginalFileDownloadable, isPDF, handleDocumentPermissionsOpen, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<HStack spacing={'1rem'}>
      {(isDownloadable || isTemplate) && (<>
          {isTemplate ? (<Button kind={Kind.SECONDARY} isLoading={isDownloading} onPress={() => handleDownloadPress(true, false)}>
              <HStack>
                <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                  {t(tKeys.common.download)}
                </Text>
              </HStack>
            </Button>) : (<PopOver from={<Button kind={Kind.SECONDARY} isLoading={isDownloading}>
                  <HStack>
                    <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                      {t(tKeys.common.download)}
                    </Text>
                    <Icon name="chevron-down" stroke={COLORS.White.T500.value} height={20} width={20}/>
                  </HStack>
                </Button>}>
              <VStack py={'.5rem'} spacing={0}>
                {isOriginalFileDownloadable && !isPDF && (<Stack onClick={() => handleDownloadPress(true, false)} cursor={'pointer'} _hover={{ bgColor: COLORS.Tag.value }} p={'.5rem'} width={'full'}>
                    <Text>{t(tKeys.common['download-original'])}</Text>
                  </Stack>)}
                <Stack onClick={() => handleDownloadPress(false, false)} cursor={'pointer'} _hover={{ bgColor: COLORS.BG.value }} p={'.5rem'} width={'full'}>
                  <Text>{t(tKeys.common['download-pdf'])}</Text>
                </Stack>
                <Stack onClick={() => handleDownloadPress(false, true)} cursor={'pointer'} _hover={{ bgColor: COLORS.BG.value }} p={'.5rem'} width={'full'}>
                  <Text>{t(tKeys.common['download-annotations'])}</Text>
                </Stack>
              </VStack>
            </PopOver>)}
        </>)}
      {/* Share the event in private message */}
      <Button kind={Kind.OUTLINE} onPress={handleOnPressMessage}>
        {t(tKeys.common.share)}
      </Button>
      {userCanEditDocument && (<PopOver from={<Icon name="more-vertical" height={20} width={20}/>} placement="bottom-start">
          <VStack width={'full'} alignItems={'flex-start'} minW={'12rem'} px={'.5rem'} py={'.25rem'}>
            {/* Document permissions */}
            <Stack onClick={handleDocumentPermissionsOpen} width={'full'} p={'.5rem'} cursor={'pointer'}>
              <Text font={FONTS.T1.T12Px.Medium500.value}>
                {t(tKeys.common.permissions)}
              </Text>
            </Stack>
            {/* Edit document */}
            <Stack onClick={handleOnPressEditDocument} width={'full'} p={'.5rem'} cursor={'pointer'}>
              <Text font={FONTS.T1.T12Px.Medium500.value}>
                {t(tKeys.news_feed.edit_post)}
              </Text>
            </Stack>

            {/* Remove document */}
            <Stack onClick={!isDeleteDocumentLoading && handleOnPressDelete} width={'full'} p={'.5rem'} cursor={'pointer'}>
              <Text font={FONTS.T1.T12Px.Medium500.value}>
                {t(tKeys.news_feed.delete_post.title)}
              </Text>
            </Stack>
          </VStack>
        </PopOver>)}
    </HStack>);
};
