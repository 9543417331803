import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import Page from '../../components/surfaces/page';
import { useCompaniesContext } from '../../contexts/companies';
import useRouter from '../../hooks/router/useRouter';
import { checkTokens } from '../../utils/handleTokens';
const Splash = () => {
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    useEffect(() => {
        async function init() {
            const value = await checkTokens();
            if (!value) {
                return push('/auth/sign-in');
            }
            if (Platform.OS === 'web') {
                // Redirect to the home page
                push('/company/home/default', {
                    companyId: selectedCompanyId,
                });
            }
        }
        void init();
    }, []);
    // Render
    return (<Page barStyle="light-content" testID={'signUpPhonePage'}>
      <></>
    </Page>);
};
export default Splash;
