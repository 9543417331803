import { useEffect, useState } from 'react';
import { useCompaniesContext } from '../../contexts/companies';
import useRouter from '../../hooks/router/useRouter';
import { useReadAgendaItems, useReadEvent, } from '../../services/online/calendar/calendar';
import { useReadDocumentTypes, useReadDocuments, } from '../../services/online/repository/repository';
import { Role } from '../../services/types';
import { tKeys } from '../../translations/keys';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateFormat } from '../../utils/date';
import { useToast } from '../../components/content/use-toast/index.web';
const useBoardpackLogic = () => {
    // Attributes
    const { params, goBack } = useRouter();
    const { t } = useTranslation();
    const eventId = params?.eventId;
    const agendaItemId = params?.agendaItemId;
    const agendaItemName = params?.agendaItemName;
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedDocument, setSelectedDocument] = useState();
    const [pdftronWebviewerInstance, setPdftronWebviewInstance] = useState();
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const toast = useToast();
    // Queries
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { data: event, isLoading: isEventLoading } = useReadEvent(eventId, {
        query: { enabled: !!eventId },
    });
    const { data: agendaItems } = useReadAgendaItems({ 'event-id': eventId });
    const { data: documents } = useReadDocuments({
        'company-id': selectedCompanyId,
        'document-types': documentTypes?.map(d => d?.id),
        'event-id': event?.id,
    }, {
        query: {
            enabled: !!selectedCompanyId && !!documentTypes && !!event?.id,
        },
    });
    const { me } = useCompaniesContext();
    // Functions
    async function createFirstPage(newDoc, PDFNet) {
        const blankPage = await newDoc.pageCreate();
        await newDoc.pagePushBack(blankPage);
        const writer = await PDFNet.ElementWriter.create();
        const builder = await PDFNet.ElementBuilder.create();
        await writer.beginOnPage(blankPage, PDFNet.ElementWriter.WriteMode.e_overlay);
        const cropBox = await blankPage.getCropBox();
        const pageWidth = await cropBox.width();
        const pageHeight = await cropBox.height();
        const texts = [
            {
                content: `Board pack - ${event?.name}`,
                fontSize: 20,
            },
            {
                content: moment(event?.startDate)
                    .locale(me?.cultureName)
                    .format(DateFormat.FullFormat),
                fontSize: 16,
            },
            { content: event?.location, fontSize: 16 },
        ];
        const image = await PDFNet.Image.createFromURL(newDoc, selectedEnrolment?.company?.picture?.uri);
        const predefinedLogoWidth = 75;
        const predefinedLogoHeight = 75;
        const logoX = (pageWidth - predefinedLogoWidth) / 2;
        const logoY = pageHeight - predefinedLogoHeight - 200;
        const matrix = await PDFNet.Matrix2D.create(predefinedLogoWidth, 0, 0, predefinedLogoHeight, logoX, logoY);
        const imageElement = await builder.createImageFromMatrix(image, matrix);
        await writer.writePlacedElement(imageElement);
        await builder.reset();
        let currentY = logoY - 100;
        for (const text of texts) {
            const font = await PDFNet.Font.create(newDoc, PDFNet.Font.StandardType1Font.e_helvetica_bold);
            const element = await builder.createTextBeginWithFont(font, text.fontSize);
            await writer.writeElement(element);
            const textElement = await builder.createUnicodeTextRun(text.content);
            const textBBox = await textElement.getBBox();
            const textWidth = await textBBox.width();
            const textHeight = await textBBox.height();
            const x = (pageWidth - textWidth) / 2;
            const y = currentY - textHeight / 2;
            await textElement.setTextMatrixEntries(1, 0, 0, 1, x, y);
            await writer.writeElement(textElement);
            await writer.writeElement(await builder.createTextEnd());
            currentY -= textHeight + 15;
        }
        await writer.end();
    }
    async function createPageWithCenteredText(newDoc, PDFNet, title) {
        const page = await newDoc.pageCreate();
        const elementBuilder = await PDFNet.ElementBuilder.create();
        const elementWriter = await PDFNet.ElementWriter.create();
        await elementWriter.beginOnPage(page);
        const font = await PDFNet.Font.create(newDoc, PDFNet.Font.StandardType1Font.e_helvetica_bold);
        await elementWriter.writeElement(await elementBuilder.createTextBeginWithFont(font, 24));
        const textElement = await elementBuilder.createUnicodeTextRun(title);
        // Obtenir les dimensions de la page
        const cropBox = await page.getCropBox();
        const pageWidth = await cropBox.width();
        const pageHeight = await cropBox.height();
        // Obtenir les dimensions du texte
        const bbox = await textElement.getBBox();
        const textWidth = await bbox.width();
        const textHeight = await bbox.height();
        const x = (pageWidth - textWidth) / 2;
        const y = (pageHeight - textHeight) / 2;
        const matrix2D = await PDFNet.Matrix2D.create(1, 0, 0, 1, x, y);
        await textElement.setTextMatrix(matrix2D);
        await elementWriter.writeElement(textElement);
        await elementWriter.writeElement(await elementBuilder.createTextEnd());
        await elementWriter.end();
        await newDoc.pagePushBack(page);
    }
    async function addDocumentToPDFNet(newDoc, PDFNet, agendaItemsWithDocuments, parentIndexes, officeToPDFBuffer) {
        for (const [index, item] of agendaItemsWithDocuments.entries()) {
            const indexes = [...parentIndexes, index + 1];
            if (item.documents && item.documents.length > 0) {
                const displayTitle = indexes.join('.').concat(`. ${item.title}`);
                await createPageWithCenteredText(newDoc, PDFNet, displayTitle);
            }
            for (const document of item.documents) {
                if (document.file.mimeType.includes('image')) {
                    await createPageForImage(newDoc, PDFNet, document.file.uri);
                    continue;
                }
                let doc;
                if (document.file.mimeType.includes('office')) {
                    const docBuffer = await officeToPDFBuffer(document?.file?.uri, undefined);
                    doc = await PDFNet.PDFDoc.createFromBuffer(docBuffer);
                }
                else {
                    doc = await PDFNet.PDFDoc.createFromURL(document.file.uri);
                }
                await doc.initSecurityHandler();
                await newDoc.insertPages((await newDoc.getPageCount()) + 1, doc, 1, await doc.getPageCount(), PDFNet.PDFDoc.InsertFlag.e_none);
            }
            if (!item.subAgendaItems || item.subAgendaItems.length <= 0)
                continue;
            await addDocumentToPDFNet(newDoc, PDFNet, item.subAgendaItems, indexes, officeToPDFBuffer);
        }
    }
    async function downloadAllFiles() {
        try {
            if (!pdftronWebviewerInstance) {
                toast({
                    status: 'warning',
                    title: t(tKeys.boardpack.download.toast),
                });
                return;
            }
            setIsDownloadLoading(true);
            const { PDFNet, officeToPDFBuffer } = pdftronWebviewerInstance.Core;
            await PDFNet.initialize(process.env.PDFTRON_LICENCE_KEY);
            const newDoc = await PDFNet.PDFDoc.create();
            await createFirstPage(newDoc, PDFNet);
            await addDocumentToPDFNet(newDoc, PDFNet, linkedDocumentToAgendaItems, [], officeToPDFBuffer);
            // Create "a" element in the document to download all
            const buffer = await newDoc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);
            const blob = new Blob([buffer], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${t(tKeys.boardpack.page.title, {
                eventName: event?.name,
                interpolation: { escapeValue: false },
            })}.pdf`;
            link.click();
        }
        catch (error) {
            console.error('Erreur lors du téléchargement du fichier PDF :', error);
        }
        finally {
            setIsDownloadLoading(false);
        }
    }
    async function createPageForImage(newDoc, PDFNet, imageUrl) {
        const image = await PDFNet.Image.createFromURL(newDoc, imageUrl);
        const page = await newDoc.pageCreate();
        const builder = await PDFNet.ElementBuilder.create();
        const writer = await PDFNet.ElementWriter.create();
        await writer.beginOnPage(page);
        const element = await builder.createImageScaled(image, 0, 0, await (await page.getCropBox()).width(), await (await page.getCropBox()).height());
        await writer.writePlacedElement(element);
        await writer.end();
        await newDoc.pagePushBack(page);
    }
    function handleSelectFile(document) {
        setSelectedFile(document);
    }
    function linkDocumentsToAgendaItems(documents, agendaItems) {
        if (!documents || !agendaItems)
            return;
        const agendaItemIdToFilesMap = documents.reduce((map, doc) => {
            if (doc.agendaItemId) {
                if (!map[doc.agendaItemId]) {
                    map[doc.agendaItemId] = [];
                }
                map[doc.agendaItemId].push(doc);
            }
            return map;
        }, {});
        const addFileToAgendaItem = (agendaItem) => {
            const itemWithDocs = {
                ...agendaItem,
                documents: agendaItemIdToFilesMap[agendaItem.id] || [],
            };
            if (itemWithDocs.subAgendaItems) {
                itemWithDocs.subAgendaItems =
                    itemWithDocs.subAgendaItems.map(addFileToAgendaItem);
            }
            return itemWithDocs;
        };
        return agendaItems.map(addFileToAgendaItem);
    }
    function handleSetWebviewerInstance(value) {
        setPdftronWebviewInstance(value);
    }
    const linkedDocumentToAgendaItems = linkDocumentsToAgendaItems(documents?.[0]?.items, agendaItems);
    // Effects
    useEffect(() => {
        if (!selectedFile)
            return;
        setSelectedDocument({
            documentUri: selectedFile?.file?.uri,
            documentTitle: selectedFile?.file?.filename,
            document: selectedFile,
        });
    }, [selectedFile]);
    // Effects
    useEffect(() => {
        if (!documents || !agendaItemId)
            return;
        const firstDocument = documents?.[0].items?.find(document => document.agendaItemId === agendaItemId);
        setSelectedFile(firstDocument);
    }, [agendaItemId, documents]);
    useEffect(() => {
        if (!selectedEnrolment || !event)
            return;
        // Used a setTimeout to prevent a flash from happening when the page loads
        setTimeout(() => (document.title = `${selectedEnrolment.company?.name} | ${t(tKeys.boardpack.page.title, {
            eventName: event?.name,
            interpolation: { escapeValue: false },
        })}`), 200);
    }, [selectedEnrolment, event]);
    // Return
    return {
        event,
        linkedDocumentToAgendaItems,
        goBack,
        isPageLoading: isEventLoading,
        selectedDocument,
        handleSelectFile,
        handleSetWebviewerInstance,
        selectedFile,
        isAdmin,
        agendaItemId,
        agendaItemName,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        downloadAllFiles,
        isDownloadLoading,
    };
};
export default useBoardpackLogic;
