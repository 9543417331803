export const dateFormatEnglish = 'YYYY/MM/DD';
export const dateFormatFrench = 'DD/MM/YYYY';
export function getFullFormatDate(language) {
    switch (language) {
        case 'en-GB':
        case 'nl-NL':
        case 'es-ES':
            return dateFormatEnglish;
        case 'fr-FR':
            return dateFormatFrench;
        default:
            return dateFormatFrench;
    }
}
