import { t } from 'i18next';
import React, { useRef } from 'react';
import { SectionList } from 'react-native';
import NavigationHeader from '../../../components/navigation/navigation-header';
import EventCard from '../../../components/surfaces/event-card/index.web';
import Page from '../../../components/surfaces/page';
import Placeholder from '../../../components/utility/placeholder';
import COLORS from '../../../constants/colors';
import { tKeys } from '../../../translations/keys';
import ButtonAddEvent from './components/inputs/button-add-event';
import useLogic from './logic.web';
import styles from './style.web';
import LoadingWrapper from '../../../components/utility/loading-wrapper';
import ListItemSeparator from '../../../components/content/list-item-separator';
import CreateEvent from './create-event/index.web';
import { Stack, HStack, useDisclosure } from '@chakra-ui/react';
import FilterIndicator from '../../../components/content/filter-indicator/index.web';
import PopOver from '../../../components/surfaces/pop-over/index.web';
import CalendarFilter from './filter/index.web';
import Search from '../../search/index.web';
import Tabs from '../../../components/navigation/tabs/index.web';
import Tab from '../../../components/navigation/tabs/tab/index.web';
const Calendar = () => {
    // Attributes
    const { eventData, allEventsTypes, isEventDataLoading, isRefetching, handleOnPressEventCard, handleOnLayout, cardHeight, currentUser, isAdmin, selectedCalendarFilters, form, resetForm, selectedTab, handleOnPressTab, futurEventLength, pastEventLength, } = useLogic();
    const listRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenFilters, onToggle: onToggleFilters } = useDisclosure();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const itemLayout = (_, index) => ({
        length: cardHeight ?? 0,
        offset: (cardHeight ?? 0) * index,
        index,
    });
    // Functions render
    function CardComponent({ item, index, }) {
        if (!isAdmin &&
            !item?.publishedOn &&
            !item?.inviteeIds?.includes(currentUser?.id)) {
            return <></>;
        }
        return (<Stack pb={'1rem'} key={index}>
        <EventCard event={item} eventType={allEventsTypes?.find(eventType => eventType?.id === item?.typeId)} isPublished={!!item?.publishedOn?.length} onLayout={handleOnLayout} onClick={() => handleOnPressEventCard(item?.id)}/>
      </Stack>);
    }
    // Render
    return (<Page showSideBar barStyle="dark-content" backgroundColor={COLORS.BG.value}>
      <NavigationHeader justifyTitle="flex-start" endEnhancer={<HStack spacing={'.5rem'} style={{ height: '2.5rem' }}>
            <PopOver from={<Stack width={'2.25rem'} height={'2.25rem'} alignItems={'center'} justifyContent={'center'}>
                  <FilterIndicator filters={selectedCalendarFilters}/>
                </Stack>} placement="bottom-end" isOpen={isOpenFilters} onToggle={onToggleFilters}>
              <CalendarFilter allEventsTypes={allEventsTypes} calendarFiltersForm={form} resetForm={resetForm}/>
            </PopOver>

            <Search />
            {/* Button to add a post */}
            {isAdmin && <ButtonAddEvent onClickButtonAddEvent={onOpen}/>}
          </HStack>}>
        {t(tKeys.calendar.header.title)}
      </NavigationHeader>
      <Stack width={'full'}>
        <Tabs activeKey={selectedTab} onChange={activeKey => handleOnPressTab(activeKey)}>
          <Tab title={t(tKeys.calendar.future, { value: futurEventLength })} onSelect={() => handleOnPressTab(0)}/>
          <Tab title={t(tKeys.calendar.past, { value: pastEventLength })} onSelect={() => handleOnPressTab(1)}/>
        </Tabs>
      </Stack>

      <LoadingWrapper isLoading={isEventDataLoading || isRefetching}>
        {/* Main content */}
        <SectionList ref={listRef} showsVerticalScrollIndicator={false} stickySectionHeadersEnabled={false} contentContainerStyle={styles.contentContainer} sections={eventData ?? []} style={styles.listContainer} renderItem={CardComponent} renderSectionHeader={({ section }) => (<ListItemSeparator title={section?.title}/>)} getItemLayout={itemLayout} 
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onScrollToIndexFailed={() => { }} ListEmptyComponent={() => !isEventDataLoading && (<Placeholder imageType={'monocle-emoji'} title={t(tKeys.calendar.placeholder.title)} subTitle={t(tKeys.calendar.placeholder.subTitle)}/>)}/>
      </LoadingWrapper>
      {/* Modal Create Event */}
      <CreateEvent isOpen={isOpen} onClose={onClose}/>
    </Page>);
};
export default Calendar;
