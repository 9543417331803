import React from 'react';
import { RadioGroup, Stack } from '@chakra-ui/react';
import ModalSelectItem from './select-item';
const SelectModal = ({ children, options, value: selectedOption, onChange, }) => {
    // Attributes
    const isOptionSelected = (option) => {
        return (selectedOption && selectedOption.value === option.value) ?? false;
    };
    const renderOptions = () => {
        if (options !== undefined) {
            return options?.map((option, index) => (<ModalSelectItem key={index} label={option?.label} onPress={() => {
                    onChange(option);
                    if (option?.onPress)
                        option.onPress();
                }} isSelected={isOptionSelected(option)}/>));
        }
        return children;
    };
    return (<RadioGroup padding={'.5rem'} value={`${selectedOption.value}`} width="fit-content" gap={'.625rem'}>
      <Stack direction="column">{renderOptions()}</Stack>
    </RadioGroup>);
};
export default SelectModal;
