import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import Text from '../../../components/content/text';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Tabs from '../../../components/navigation/tabs';
import Tab from '../../../components/navigation/tabs/tab';
import Container from '../../../components/surfaces/container';
import Flex from '../../../components/surfaces/flex';
import Page from '../../../components/surfaces/page';
import SelectModal from '../../../components/surfaces/select-modal';
import TaskCard from '../../../components/surfaces/task-card';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import FilterIndicator from '../../../components/content/filter-indicator';
import ButtonAddTask from '../more/components/input/button-add-task';
import TasksFilterModal from '../more/components/navigation/tasks-filter-modal';
import useLogic from './logic.web';
import styles from './style';
import { TaskStatus } from './types';
import { Role } from '../../../services/types';
import Icon from '../../../components/content/icon';
import Placeholder from '../../../components/utility/placeholder';
import LoadingWrapper from '../../../components/utility/loading-wrapper';
import { HStack, Stack, VStack, useDisclosure } from '@chakra-ui/react';
import CreateTask from './create-task/index.web';
import PopOver from '../../../components/surfaces/pop-over/index.web';
import Search from '../../search/index.web';
import CustomTaskDetail from '../../task-details/custom-task-detail/index.web';
import VoteResults from '../../vote-results/index.web';
const Tasks = () => {
    // Attributes
    const { isTasksLoading, userTasks, isTasksRefetching, selectedTab, setSelectedTab, optionsGroupBy, optionsOrder, userTaskLength, handleOnPressTab, handleResetFilterVisibility, form, selectedTasksTypes, selectedEnrolment, handleTaskPress, sortingForm, filterIndicatorLength, isOpenTaskDetails, onCloseTaskDetails, selectedTask, isOpenVoteResult, onCloseVoteResult, voteResultParams, } = useLogic();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenFilter, onToggle: onToggleFilter } = useDisclosure();
    const ListHeaderComponent = () => (<MenuProvider>
      <Flex justify="space-between" style={styles.filterContainer}>
        <Stack width={'full'}>
          <Text font={FONTS.T1.T16Px.SemiBold600.value} color={COLORS.Text.T400.value}>
            {selectedTab === TaskStatus.Open
            ? t(tKeys.tasks.filter.active)
            : t(tKeys.tasks.filter.completed)}
          </Text>
        </Stack>
        {/* Filters by order and type */}
        {/* Filter by order */}
        <HStack width={'full'} justify="flex-end" spacing={'1rem'}>
          {/*  Filter by group */}
          <PopOver from={<Flex direction="row" align="center" spacing={4}>
                <Icon name="filter-order" width={16} height={16} fill={COLORS.Text.T300.value} stroke={COLORS.Text.T300.value}/>
                <Text color={COLORS.Text.T300.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                  {sortingForm?.orderBy?.value?.label}
                </Text>
              </Flex>}>
            <SelectModal {...sortingForm.orderBy} options={optionsOrder}/>
          </PopOver>

          {/*  Filter by group */}
          <PopOver from={<Flex direction="row" align="center" spacing={4}>
                <Icon name="filter-group-by" width={16} height={16} fill={COLORS.Text.T300.value} stroke={COLORS.Text.T300.value}/>
                <Text color={COLORS.Text.T300.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                  {sortingForm?.groupBy?.value?.label}
                </Text>
              </Flex>}>
            <SelectModal {...sortingForm.groupBy} options={optionsGroupBy}/>
          </PopOver>
        </HStack>
      </Flex>
    </MenuProvider>);
    const ContentComponent = () => {
        return (<FlatList keyboardShouldPersistTaps="always" style={styles.listStyle} data={userTaskLength === 0 && selectedTab === TaskStatus.Open
                ? []
                : userTasks} showsVerticalScrollIndicator={false} renderItem={renderItem} contentContainerStyle={styles.flatlistContent} refreshing={true} ListHeaderComponent={!isTasksRefetching && ListHeaderComponent} ListHeaderComponentStyle={styles.listHeaderComponentStyle} ListEmptyComponent={<Placeholder imageType={selectedTab === TaskStatus.Open ? 'thumb-up' : 'monocle-emoji'} title={t(tKeys.home.tasks.placeholder.title)} subTitle={selectedTab === TaskStatus.Open
                    ? t(tKeys.home.tasks.placeholder.subTitle)
                    : t(tKeys.home.tasks.completed.placeholder.subTitle)}/>}/>);
    };
    // Render item for the flatlist
    const renderItem = ({ item, index }) => {
        return (<>
        {/*  Category name */}
        {/* selectedOptionGroupBy?.value === 0 means that no filter "group-by" is apply */}
        {sortingForm?.groupBy?.value &&
                sortingForm?.groupBy?.value?.value !== 0 &&
                item?.categoryName &&
                !!item?.items?.length && (<Container style={[
                    styles.taskTitleContainer,
                    {
                        marginTop: index === 0 ? 0 : 28,
                    },
                ]}>
              <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                {t(item?.categoryName)}
              </Text>
            </Container>)}

        {/* All tasks */}
        {item?.items?.map((task, index) => {
                return (<Container style={styles.contentContainerStyle} key={index}>
              <TaskCard task={task} title={task?.title} tag={task?.type} dueDate={task?.deadline} eventId={task?.eventId} creatorId={task?.creatorId} assigneeId={task?.personId} agendatItemId={task?.agendaItemId} handleTaskPress={handleTaskPress}/>
            </Container>);
            })}
      </>);
    };
    // Render
    return (<Page showSideBar barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader style={{ zIndex: 100 }} justifyTitle={'flex-start'} endEnhancer={<Flex spacing={20} align="center">
            <PopOver from={<FilterIndicator filters={filterIndicatorLength - selectedTasksTypes?.length}/>} isOpen={isOpenFilter} onToggle={onToggleFilter}>
              <TasksFilterModal form={form} onClickResetButton={handleResetFilterVisibility} title={t(tKeys.common.filter_by)}/>
            </PopOver>
            <Search />
            {/* Button to add a post */}
            {/* selectedEnrolment?.role === 0 means it's an Admin */}
            {selectedEnrolment?.role === Role?.[0] && (<>
                <ButtonAddTask onClickButtonAddTask={onOpen}/>
                <CreateTask isOpen={isOpen} onClose={onClose}/>
              </>)}
          </Flex>}>
        {t(tKeys.common.tasks)}
      </NavigationHeader>

      {/* Tabs */}
      <LoadingWrapper isLoading={isTasksLoading}>
        <VStack width={'full'} alignItems={'flex-start'} overflow={'auto'} bgColor={COLORS.BG.value} height={'full'}>
          <Stack width={'full'}>
            <Tabs activeKey={parseInt(selectedTab.toString()) - 1} onChange={activeKey => handleOnPressTab(activeKey)}>
              <Tab title={`${t(tKeys.tasks.filter.active)} ${userTaskLength > 0 ? `(${userTaskLength})` : ''}`} onSelect={() => setSelectedTab(TaskStatus.Open)}/>
              <Tab title={t(tKeys.tasks.filter.completed)} onSelect={() => setSelectedTab(TaskStatus.Completed)}/>
            </Tabs>
          </Stack>
          <ContentComponent />
        </VStack>
      </LoadingWrapper>
      {/* Modals */}
      <CustomTaskDetail task={selectedTask} isOpen={isOpenTaskDetails} onClose={onCloseTaskDetails}/>
      <VoteResults isOpen={isOpenVoteResult} onClose={onCloseVoteResult} voteResultParams={voteResultParams}/>
    </Page>);
};
export default Tasks;
