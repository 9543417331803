import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/content/text';
import Button from '../../../components/inputs/button';
import { Kind } from '../../../components/inputs/button/constants/kind';
import FormControl from '../../../components/inputs/form-control';
import PageParagraphText from '../../../components/surfaces/page-text';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import useLogic from './logic';
import { HStack, PinInput, PinInputField, Stack, VStack, } from '@chakra-ui/react';
import { PageWebAuth } from '../../../components/surfaces/page-auth/index.web';
const DoubleAuthentification = () => {
    // Attributes
    const { handleOnPressBackButton, form, maximumLength, isConfirmInvitationIsLoading, isConfirmForgotPasswordIsLoading, isConfirmAuthenticationLoading, isAuthenticatePersonLoading, isButtonDisabled, onButtonClickResendCode, handlerOnSubmit, phoneNumber, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<PageWebAuth onGoBack={handleOnPressBackButton} title={t(tKeys.common.double_authentification.title)}>
      <VStack alignItems={'flex-start'} width={'full'} padding={'2.5rem'}>
        {/* Title */}
        <Stack pb={'1rem'}>
          <PageParagraphText font={FONTS.T1.T16Px.SemiBold600.value} text={t(tKeys['double-auth'].page.title)}/>
        </Stack>

        {/*  Text */}
        <VStack width={'full'} alignItems={'flex-start'} pb={'3.5rem'}>
          <PageParagraphText color={COLORS.InputText.value} font={FONTS.T1.T14Px.Regular400.value} text={t(tKeys['double-auth'].page['send-code-message'].title)}/>
          <Text font={FONTS?.T1.T14Px.Medium500.value}>{phoneNumber}</Text>
        </VStack>

        {/* Verification code inputs */}
        <Stack width={'full'} pb={'4.5rem'}>
          <FormControl error={form?.smsCode?.meta?.error}>
            <HStack width={'full'} justifyContent={'space-between'}>
              <PinInput type="alphanumeric" value={form?.smsCode?.value} onChange={form?.smsCode.onChange} size={'lg'} placeholder="">
                {Array.from({ length: maximumLength }, (_, index) => {
            return (<PinInputField key={index} borderRadius={0} borderWidth={0} borderBottomWidth={1} _focusVisible={{
                    borderRadius: 0,
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: COLORS.DarkGrey.value,
                }} autoFocus={index === 0}/>);
        })}
              </PinInput>
            </HStack>
          </FormControl>
        </Stack>
        {/*  Send verify code button */}
        <Button onPress={handlerOnSubmit} isLoading={isConfirmInvitationIsLoading ||
            isConfirmForgotPasswordIsLoading ||
            isConfirmAuthenticationLoading} testID={'verifyCodeButton'} isDisabled={!form?.smsCode.value || isButtonDisabled()} width={'full'}>
          <Text font={FONTS.T1.T14Px.Medium500.value} color={form?.smsCode.value &&
            !isButtonDisabled() &&
            COLORS.White.T500.value}>
            {t(tKeys['double-auth'].page['verify-code'])}
          </Text>
        </Button>
        {/*  Resend code button */}
        <Button onPress={onButtonClickResendCode} kind={Kind.WHITE} isLoading={isAuthenticatePersonLoading} isDisabled={isAuthenticatePersonLoading} width={'full'}>
          <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Govrn.Blue.T500.value}>
            {t(tKeys['double-auth'].page['resend-code'].title)}
          </Text>
        </Button>
      </VStack>
    </PageWebAuth>);
};
export default DoubleAuthentification;
