import React from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import Icon from '../../../components/content/icon';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Flex from '../../../components/surfaces/flex';
import Page from '../../../components/surfaces/page';
import LoadingWrapper from '../../../components/utility/loading-wrapper';
import COLORS from '../../../constants/colors';
import styles from './style';
import useLogic from './logic';
import DocumentDetailsPreviewer from '../../home/more/components/content/document-previewer';
import DocumentSectionInformation from './components/content/info-section';
import Container from '../../../components/surfaces/container';
import Toast from 'react-native-toast-message';
import ToastConfiguration from '../../../components/progression-and-validation/toast/toast-configuration';
import MoreModal from './components/surfaces/modal/more-modal';
import OfflineRedirectCard from '../../../components/surfaces/offline-redirect-card';
import { tKeys } from '../../../translations/keys';
import { useTranslation } from 'react-i18next';
const DocumentDetails = () => {
    const { t } = useTranslation();
    // Logic
    const { onGoBack, onFullScreenPress, isLoading, documentEntity, showMoreModal, handleOpenMoreModal, handleCloseMoreModal, handleDisableOffline, connectionState, isSync, } = useLogic();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      {/* Header */}
      <NavigationHeader onGoBack={onGoBack} endEnhancer={<Flex spacing={16}>
            <Pressable onPress={handleOpenMoreModal}>
              <Icon name="more-vertical" width={20} height={20} color={COLORS.Text.T400.value} fill={'transparent'}/>
            </Pressable>
          </Flex>}/>

      <LoadingWrapper isLoading={isLoading}>
        <Container style={styles.mainContainer}>
          <ScrollView contentContainerStyle={styles.scrollview}>
            {/* Document Previewer */}
            <DocumentDetailsPreviewer url={documentEntity?.fileThumbnailPath} onFullScreenPress={onFullScreenPress} isSync={isSync}/>

            {/* Task section - global infos  */}
            <DocumentSectionInformation documentEntity={documentEntity}/>

            {/* Empty container to override bgColor of scrollview  */}
            <View style={styles.emptyContainer}/>
          </ScrollView>
        </Container>
      </LoadingWrapper>

      {connectionState && (<OfflineRedirectCard text1={t(tKeys.toast.online.redirect.text1)} text2={t(tKeys.toast.online.redirect.text2)}/>)}

      <MoreModal isOpen={showMoreModal} onDismiss={handleCloseMoreModal} onDisableOffline={handleDisableOffline}/>
      <Toast position="top" config={ToastConfiguration} topOffset={60}/>
    </Page>);
};
export default DocumentDetails;
