import moment from 'moment';
import { DocumentKindEnum } from './type';
export function mapToDocumentEntityFromTemplate(template) {
    return {
        kind: DocumentKindEnum.TEMPLATE,
        title: template?.title,
        description: template?.description,
        signers: [],
        file: template?.file,
    };
}
export function mapToDocumentEntityFromDocument(document, taskType, event) {
    return {
        kind: DocumentKindEnum.DOCUMENT,
        title: document?.title,
        description: document?.description,
        eventTitle: getEventTitle(event),
        isDownloadable: document?.isDownloadable,
        signerIds: document?.signerIds,
        versionId: document?.versionId,
        taskType,
        signers: document?.signers,
        file: document?.file,
    };
}
export function mapToDocumentEntityFromMinutes(document, event) {
    return {
        kind: DocumentKindEnum.MINUTES,
        title: document?.title,
        description: document?.description,
        eventTitle: getEventTitle(event),
        isDownloadable: document?.isDownloadable,
        signerIds: document?.signerIds,
        versionId: document?.versionId,
        signers: document?.signers,
        file: document?.file,
    };
}
// Helper function
function getEventTitle(event) {
    if (!event)
        return '';
    return `In ${event.name} ${moment(new Date(event.startDate)).format('D/MM/yyyy')}`;
}
