import React from 'react';
import { FlatList } from 'react-native';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import Flex from '../../../../components/surfaces/flex';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import styles from './style';
import useLogic from './logic.web';
import FilterHeader from '../../../../components/surfaces/filter-header/index.web';
import CalendarFilterEventTypes from '../components/content/list-item/calendar-filter-event-types';
import Text from '../../../../components/content/text';
import FONTS from '../../../../constants/fonts';
import { useTranslation } from 'react-i18next';
import { HStack, Switch, VStack } from '@chakra-ui/react';
const CalendarFilter = ({ calendarFiltersForm, allEventsTypes, resetForm, }) => {
    // Attributes
    const { initialFilters, onPressPeriodTimeButton, onPressFromDateButton, onPressToDateButton, onSelectAllEventTypes, isAllEventTypesSelected, handleOnChangeSwitchButton, isAdmin, resetFilters, } = useLogic(calendarFiltersForm, allEventsTypes, resetForm);
    const { t } = useTranslation();
    // Render
    return (<VStack alignItems={'flex-start'} width={'30rem'} maxH={'45rem'} pb={'1rem'}>
      <HStack justifyContent={'space-between'} width={'full'} pl={'1rem'} pt={'1rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.common.filters)}
        </Text>
        <Button onPress={resetFilters} kind={Kind.TRANSPARENT}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Govrn.Blue.T500.value}>
            {t(tKeys.common.reset)}
          </Text>
        </Button>
      </HStack>

      {/* Main content */}
      <FlatList style={styles.mainContent} contentContainerStyle={styles.flatlistContent} showsVerticalScrollIndicator={false} data={calendarFiltersForm.eventTypes.value ?? []} renderItem={({ item }) => (<CalendarFilterEventTypes label={t(item?.label)} id={item?.id} isSelected={item?.isSelected} handleOnChangeSwitchButton={() => handleOnChangeSwitchButton(item?.id)}/>)} ListHeaderComponent={<>
            {/* Only for admin users */}
            {isAdmin ? (<Flex justify="space-between" style={styles.showEventSwitchContainer}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(tKeys.calendar.filter.event_not_invited)}
                </Text>
                <Switch isChecked={calendarFiltersForm.isInvited.value} onChange={() => {
                    calendarFiltersForm?.isInvited.onChange(!calendarFiltersForm.isInvited.value);
                }} _checked={{
                    '& .chakra-switch__track': {
                        backgroundColor: COLORS.Success.T500.value,
                    },
                }}/>
              </Flex>) : null}

            <FilterHeader title={t(tKeys.home.more.repository.filter.header.title)} 
        // From date props
        fromDateValue={calendarFiltersForm.fromDate.value ?? ''} onPressFromDateButton={onPressFromDateButton} isFromDateDirty={calendarFiltersForm?.fromDate.value !==
                initialFilters.fromDate && !calendarFiltersForm?.period.value} closeFromDateCalendar={!!calendarFiltersForm?.period?.value} 
        // To date props
        toDateValue={calendarFiltersForm?.toDate?.value ?? ''} onPressToDateButton={onPressToDateButton} isToDateDirty={calendarFiltersForm?.toDate?.value !== initialFilters.toDate &&
                !calendarFiltersForm?.period?.value} closeToDateCalendar={!!calendarFiltersForm?.period?.value} 
        // Period group buttons props
        selectedPeriodValue={calendarFiltersForm?.period?.value} handleOnPressPeriodTimeButton={period => onPressPeriodTimeButton(period)} 
        // Options props
        handleOnSelect={onSelectAllEventTypes} isAllOptionsSelected={isAllEventTypesSelected}/>
          </>}/>
    </VStack>);
};
export default CalendarFilter;
