import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size';
import FormControl from '../../../../../components/inputs/form-control';
import NavigationHeader from '../../../../../components/navigation/navigation-header';
import Page from '../../../../../components/surfaces/page';
import COLORS from '../../../../../constants/colors';
import { tKeys } from '../../../../../translations/keys';
import useLogic from './logic.web';
import { Stack } from '@chakra-ui/react';
import { boxSectionStyle } from '../../my-informations/style.web';
import Input from '../../../../../components/inputs/input/index.web';
const VerifyMobilePhone = () => {
    // Attributes
    const { t } = useTranslation();
    const { handleOnPressButton, isUpdatePersonLoading, form, handleOnPressBackButton, } = useLogic();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={handleOnPressBackButton} endEnhancer={<Button onPress={handleOnPressButton} isLoading={isUpdatePersonLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
            {t(tKeys.common.confirm)}
          </Button>}>
        {`${t(tKeys.profile['user-profile']['my-information'])} / ${t(tKeys.profile.user_profile.mobile.verify_code.navigation_header)}`}
      </NavigationHeader>

      <Stack width={'full'} height={'full'} bgColor={COLORS.BG.value} p={'1rem'}>
        <Stack {...boxSectionStyle}>
          {/* Verify code input */}
          <FormControl label={t(tKeys.profile.user_profile.mobile.verify_code.enter_code_msg)} spacing={4}>
            <Input value={form?.code?.value} onChange={e => form?.code?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys['double-auth'].page['send-code-message'].title)}/>
          </FormControl>
        </Stack>
      </Stack>
    </Page>);
};
export default VerifyMobilePhone;
