import { useState } from 'react';
import { useCompaniesContext } from '../../../../../../../contexts/companies';
import { TaskTypeEnum } from '../../../../../../../enum/TaskType';
import useRouter from '../../../../../../../hooks/router/useRouter';
import { useReadMe } from '../../../../../../../services/online/people/people';
import { useReadTasks } from '../../../../../../../services/online/tasks/tasks';
import { TaskType } from '../../../../../../../services/types';
import getStaleTime from '../../../../../../../utils/getStaleTime';
import { DocumentKindEnum } from '../../../../../../document-details/type';
import { TaskStatus } from '../../../../../tasks/types';
import { readAgendaItems } from '../../../../../../../services/online/calendar/calendar';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = () => {
    // Attributes
    const { push } = useRouter();
    const { selectedEnrolment, isCurrentUserLoading, selectedCompanyId } = useCompaniesContext();
    const [selectedTask, setSelectedTask] = useState();
    const [voteResultParams, setVoteResultParams] = useState();
    const { isOpen: isOpenTaskDetails, onOpen: onOpenTaskDetails, onClose: onCloseTaskDetails, } = useDisclosure();
    const { isOpen: isOpenVoteResult, onOpen: onOpenVoteResult, onClose: onCloseVoteResult, } = useDisclosure();
    const { data: me } = useReadMe({
        query: { staleTime: getStaleTime() },
    });
    const { data: openTasks, isLoading: isTasksLoading } = useReadTasks({
        'person-id': selectedEnrolment?.personId,
        'company-id': selectedCompanyId,
        status: TaskStatus.Open,
        types: [TaskType?.[3], TaskType?.[4], TaskType?.[5], TaskType?.[6]],
        limit: 1000,
    }, { query: { enabled: !!selectedCompanyId && !!selectedEnrolment } });
    // Handlers
    async function onTaskPress(task) {
        if (task.type === TaskTypeEnum?.Custom) {
            setSelectedTask(task);
            onOpenTaskDetails();
            return;
        }
        if (task.type === TaskTypeEnum?.Vote) {
            // Fetch agendaItems
            const agendaItems = await readAgendaItems({ 'event-id': task?.eventId });
            // Doing this because there is no getAgendaItemById in the back-end
            const question = agendaItems?.find(i => i?.id === task?.agendaItemId).question;
            const params = {
                question,
                eventId: task?.eventId,
                canUserVote: true,
            };
            setVoteResultParams(params);
            onOpenVoteResult();
            return;
        }
        push('/company/document-details', {
            companyId: selectedCompanyId,
            documentKind: DocumentKindEnum.DOCUMENT,
            taskType: task.type,
            documentId: task.documentId,
            eventId: task.eventId,
        });
    }
    function handleOnPressEvent(eventId) {
        push('/company/event-details', {
            companyId: selectedCompanyId,
            eventId,
        });
    }
    const isLoading = isCurrentUserLoading || isTasksLoading;
    return {
        userTasks: openTasks?.[0]?.items,
        onTaskPress,
        isLoading,
        handleOnPressEvent,
        currentUser: me?.person,
        selectedTask,
        voteResultParams,
        isOpenTaskDetails,
        onCloseTaskDetails,
        isOpenVoteResult,
        onCloseVoteResult,
    };
};
export default useLogic;
