import React from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import Text from '../../../../../../../../components/content/text';
import FONTS from '../../../../../../../../constants/fonts';
import COLORS from '../../../../../../../../constants/colors';
import Tag from '../../../../../../../../components/content/tag';
export const MemberRowGroups = ({ groups }) => {
    // Attributes
    const [firstGroup, secondGroup, ...remainingGroups] = groups;
    // Render
    return (<HStack width={'100%'} spacing={'.25rem'}>
      <Tag backgroundColor={COLORS.Tag.value} color={COLORS.Text.T400.value}>
        {firstGroup.name}
      </Tag>
      {secondGroup && (<Tag backgroundColor={COLORS.Tag.value} color={COLORS.Text.T400.value}>
          {secondGroup.name}
        </Tag>)}
      {remainingGroups.length > 0 && (<Stack px={'.5rem'} py={'.25rem'} borderRadius={'.5rem'} bgColor={COLORS.Text.T500.value}>
          <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.White.T500.value}>
            {remainingGroups.length}
          </Text>
        </Stack>)}
    </HStack>);
};
