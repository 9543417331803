import { VStack } from '@chakra-ui/react';
import React from 'react';
import useRouter from '../../../../hooks/router/useRouter';
import { CompaniesPopupItem } from './companies-popup-item';
export const CompaniesPopup = ({ selectedCompanyId, workspaces, updateCurrentCompany, isPopUpOpen, }) => {
    // Attributes
    const { replace, getCurrent } = useRouter();
    function handleOnChangeOrganisation(workspaceId) {
        updateCurrentCompany(workspaceId);
    }
    // Render
    return (<VStack textAlign="left" backgroundColor={'#FFF'} scrollBehavior={'smooth'} overflowY={'auto'} maxHeight={'30rem'}>
      {workspaces?.map(workspace => (<CompaniesPopupItem updateCurrentCompany={updateCurrentCompany} workspace={workspace} selectedCompanyId={selectedCompanyId} isPopUpOpen={isPopUpOpen}/>))}
    </VStack>);
};
