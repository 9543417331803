import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        paddingLeft: '2rem',
        paddingBottom: '2rem',
    },
    listContainer: {
        paddingHorizontal: '2rem',
        width: '100%',
    },
    contentContainer: {
        paddingBottom: '2rem',
    },
    cardComponentContainer: {
        paddingBottom: 12,
    },
});
export default styles;
