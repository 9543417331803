import { t } from 'i18next';
import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Button from '../../../../../../components/inputs/button';
import FormControl from '../../../../../../components/inputs/form-control';
import { Size } from '../../../../../../components/utility/loading/size';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic.web';
import Modal from '../../../../../../components/surfaces/modal/index.web';
import { HStack, Stack, StackDivider, Textarea, VStack, } from '@chakra-ui/react';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Input from '../../../../../../components/inputs/input/index.web';
const EventDetailsResolutionPage = ({ isOpen, onClose, refetchResolutions, resolutionEntity, }) => {
    // Attributes
    const { isChangeResolutionLoading, handleOnSubmit, form, isDeleteResolutionLoading, handleOnDeleteResolution, disableResolution, } = useLogic(resolutionEntity, onClose, refetchResolutions);
    // Render
    return (<Modal size={'2xl'} hasCloseButton isOpen={isOpen} onClose={onClose} title={resolutionEntity?.id
            ? t(tKeys.calendar.resolution.edit_a_resolution)
            : t(tKeys.calendar.resolution.add_a_resolution)} actionButton={<HStack>
          {resolutionEntity?.id && (<Button kind={Kind.TRANSPARENT} onPress={handleOnDeleteResolution} isLoading={isDeleteResolutionLoading} backgroundColorPerso="white" borderColor="red" borderWidth={1}>
              <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                {t(tKeys.calendar.resolution.delete)}
              </Text>
            </Button>)}
          <Button size={Size.DEFAULT} kind={Kind.SECONDARY} onPress={async () => await handleOnSubmit()} isLoading={isChangeResolutionLoading} isDisabled={disableResolution}>
            <Text font={FONTS.T1.T12Px.SemiBold600.value} color={disableResolution
                ? COLORS.Text.T300.value
                : COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
              {t(tKeys.common.save)}
            </Text>
          </Button>
        </HStack>}>
      <VStack width={'full'} alignItems={'flex-start'} divider={<StackDivider />} p={'1rem'}>
        <FormControl label={t(tKeys.common.title)} spacing={2}>
          <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder)} autoFocus/>
        </FormControl>

        <HStack spacing={'.75rem'} width={'full'}>
          <Stack alignSelf={'flex-start'}>
            <Icon name="sections" width={32} height={32}/>
          </Stack>
          <FormControl error={form?.description?.meta?.error} spacing={0}>
            <Textarea value={form?.description.value} onChange={e => {
            form?.description.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.description.placeholder)} fontSize={'0.875rem'} height={'10rem'} fontWeight={400} paddingLeft={'.25rem'}/>
          </FormControl>
        </HStack>
      </VStack>
    </Modal>);
};
export default EventDetailsResolutionPage;
