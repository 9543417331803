export var EventDetailsTabs;
(function (EventDetailsTabs) {
    EventDetailsTabs[EventDetailsTabs["calendar.event_details.tabs.agenda"] = 0] = "calendar.event_details.tabs.agenda";
    EventDetailsTabs[EventDetailsTabs["calendar.event_details.tabs.files"] = 1] = "calendar.event_details.tabs.files";
    EventDetailsTabs[EventDetailsTabs["calendar.event_details.tabs.tasks"] = 2] = "calendar.event_details.tabs.tasks";
    EventDetailsTabs[EventDetailsTabs["calendar.event_details.tabs.resolutions"] = 3] = "calendar.event_details.tabs.resolutions";
    EventDetailsTabs[EventDetailsTabs["event.details.AI-summary"] = 4] = "event.details.AI-summary";
})(EventDetailsTabs || (EventDetailsTabs = {}));
export var PresenceStatus;
(function (PresenceStatus) {
    PresenceStatus[PresenceStatus["Present"] = 0] = "Present";
    PresenceStatus[PresenceStatus["Proxy"] = 1] = "Proxy";
    PresenceStatus[PresenceStatus["Absent"] = 2] = "Absent";
    PresenceStatus[PresenceStatus["Pending"] = 3] = "Pending";
    PresenceStatus[PresenceStatus["Remote"] = 4] = "Remote";
})(PresenceStatus || (PresenceStatus = {}));
