import * as Yup from 'yup';
import { tKeys } from '../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        assignedId: Yup.string().required(t(tKeys.validation.createGroupChannel.participants.minLength_1)),
        completed: Yup.boolean().notRequired(),
        creationDate: Yup.string().required(),
        creatorId: Yup.string().required(),
        description: Yup.string().notRequired(),
        dueDate: Yup.string().required(t(tKeys.validation.due_date)),
        title: Yup.string().required(t(tKeys.validation.title)),
    });
};
