import * as React from 'react';
import Svg, { Path, G, Defs, Rect } from 'react-native-svg';
const ChevronRightDefault = (props) => (<Svg width={props.width ?? 14} height={props.height ?? 14} fill={props.fill ?? 'none'} {...props}>
    <G clipPath="url(#clip0_3290_13337)">
      <Path fillRule="evenodd" clipRule="evenodd" d="M3.00014 1.46601C2.99702 1.85081 3.13997 2.22248 3.40014 2.50601L7.65214 7.00001L3.40514 11.494C3.14463 11.7767 3 12.1471 3 12.5315C3 12.9159 3.14463 13.2863 3.40514 13.569C3.52974 13.705 3.68125 13.8135 3.85004 13.8878C4.01884 13.962 4.20123 14.0004 4.38564 14.0004C4.57005 14.0004 4.75245 13.962 4.92124 13.8878C5.09004 13.8135 5.24155 13.705 5.36614 13.569L10.5931 8.03701C10.8548 7.75546 11.0002 7.38535 11.0002 7.00101C11.0002 6.61668 10.8548 6.24657 10.5931 5.96501L5.36614 0.431013C5.24155 0.295063 5.09004 0.186509 4.92124 0.112246C4.75245 0.0379831 4.57005 -0.000366211 4.38564 -0.000366211C4.20123 -0.000366211 4.01884 0.0379831 3.85004 0.112246C3.68125 0.186509 3.52974 0.295063 3.40514 0.431013C3.14347 0.711977 2.99865 1.08207 3.00014 1.46601Z" fill={props.fill ?? 'black'}/>
    </G>
    <Defs>
      <clipPath id="clip0_3290_13337">
        <Rect width={props.height ?? 14} height={props.height ?? 14} fill={props.fill ?? 'white'}/>
      </clipPath>
    </Defs>
  </Svg>);
export default ChevronRightDefault;
