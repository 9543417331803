import React from 'react';
import { Divider, HStack, Stack, VStack } from '@chakra-ui/react';
import { AddButtonSubAgendaItem } from './add-sub-agenda-item/index.web';
import { AgendaItemInformations } from './agenda-item-informations/index.web';
import { tKeys } from '../../../../../../translations/keys';
import { useAgendaItemLogic } from './logic.web';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../../../../constants/colors';
import { DeleteConfirmationModal } from '../../../../../../components/surfaces/modal/delete-confirmation-modal/index.web';
import Dropzone from 'react-dropzone';
import Text from '../../../../../../components/content/text';
import Icon from '../../../../../../components/content/icon';
import FONTS from '../../../../../../constants/fonts';
import LoadingAnimation from '../../../../../../components/utility/loading';
const AgendaItem = ({ agendaItem, onEditPress, onSelect, isSelected, onSeeResultsPress, onVotePress, isVoteEnabled, isCurrentUserInvited, isCurrentUserPresent, index, eventId, eventStartDate, onSubAgendaItemAdd, level, parentIndexes, handleDropAgendaItem, isAdmin, refetchAgendaItems, }) => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { drag, dropAfterAgendaItem, dropInAgendaItem, isDragging, isOverAfterAgendaItem, isOverInAgendaItem, preview, handleAgendaItemDelete, isDeleteLoading, agendaItemConfirmationDeleteDisclosure, redirectToBoardPack, handleOnDropFiles, setDragFileActive, isDragFileActive, isCreateDocumentLoading, } = useAgendaItemLogic(agendaItem, handleDropAgendaItem, index, refetchAgendaItems, eventId);
    console.log('isCreateDocumentLoading', isCreateDocumentLoading);
    // Render
    return (<VStack width={'100%'} alignItems={'flex-start'} ref={dropAfterAgendaItem}>
      <Dropzone noClick={true} onDrop={handleOnDropFiles} onDragEnter={() => setDragFileActive(true)} onDragLeave={() => setDragFileActive(false)}>
        {({ getRootProps, getInputProps }) => (isDragFileActive ? (<VStack width={'full'}>
              <HStack width={'full'} rounded="0.75rem" height={'3.75rem'} 
        // height="7rem"
        bgColor={COLORS.Govrn.Blue.T50.value} border="0.0625rem" borderColor={COLORS.Stroke.value} borderStyle="dashed" justifyContent="center" {...getRootProps()}>
                {isCreateDocumentLoading ? (<Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <LoadingAnimation />
                  </Stack>) : (<HStack px={'1.25rem'} py={'.5rem'}>

                  <Icon name="picture-blue"/>
                  <Text numberOfLines={100} font={FONTS.T1.T12Px.Medium500.value} style={{ maxWidth: '14rem' }}>
                    "Drop To Upload"
                  </Text>
                </HStack>)}
                

                <input {...getInputProps()}/>

              </HStack>
              {/* add space  */}
              <VStack height={'3.15rem'}></VStack>


            </VStack>) : (<VStack width={'100%'} alignItems={'flex-start'}>
              <Stack {...getRootProps()} width={'full'} ref={isAdmin ? drag : undefined} display={isDragging ? 'none' : 'flex'} cursor={isAdmin ? 'grab' : 'default'}>
                <Stack ref={preview}>
                  <AgendaItemInformations agendaItem={agendaItem} isCurrentUserInvited={isCurrentUserInvited} isCurrentUserPresent={isCurrentUserPresent} isDeleteLoading={isDeleteLoading} isSelected={isSelected} isVoteEnabled={isVoteEnabled} onDeletePress={agendaItemConfirmationDeleteDisclosure.onOpen} onEditPress={onEditPress} onSeeResultsPress={onSeeResultsPress} onSelect={onSelect} onVotePress={onVotePress} parentIndexes={parentIndexes} isAdmin={isAdmin} redirectToBoardPack={redirectToBoardPack}/>
                </Stack>
                {isOverAfterAgendaItem && (<Divider borderColor={COLORS.Govrn.Blue.T500.value}/>)}
              </Stack>
              {!isDragging && (<Stack width={'100%'} pl={'3rem'}>
                  <VStack width={'full'} spacing={0} ref={dropInAgendaItem}>
                    {level < 4 && isAdmin && (<Stack pb={'.5rem'} width={'100%'}>
                        <AddButtonSubAgendaItem onClick={() => onSubAgendaItemAdd(agendaItem.id)} isOver={isOverInAgendaItem} title={t(tKeys.agenda_item.sub_agenda_item.add)}/>
                        {isOverInAgendaItem && (<Divider borderColor={COLORS.Govrn.Blue.T500.value}/>)}
                      </Stack>)}
                    <VStack width={'100%'} spacing={0}>
                      {agendaItem.subAgendaItems?.map((agendaItem, subIndex) => (<AgendaItem key={agendaItem.id} onSelect={onSelect} eventId={eventId} eventStartDate={eventStartDate} agendaItem={agendaItem} isSelected={agendaItem.isCompleted ?? false} onEditPress={onEditPress} onSeeResultsPress={onVotePress} isVoteEnabled={isVoteEnabled} onVotePress={onVotePress} isCurrentUserInvited={isCurrentUserInvited} isCurrentUserPresent={isCurrentUserPresent} index={subIndex + 1} onSubAgendaItemAdd={onSubAgendaItemAdd} level={level + 1} parentIndexes={[...parentIndexes, subIndex + 1]} handleDropAgendaItem={handleDropAgendaItem} isAdmin={isAdmin} refetchAgendaItems={refetchAgendaItems}/>))}
                    </VStack>
                  </VStack>
                </Stack>)}
              <input {...getInputProps()}/>

            </VStack>))}
      </Dropzone>
      <DeleteConfirmationModal isOpen={agendaItemConfirmationDeleteDisclosure.isOpen} onClose={agendaItemConfirmationDeleteDisclosure.onClose} isDeleteLoading={isDeleteLoading} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDelete={async () => await handleAgendaItemDelete(agendaItem?.id)} title={t(tKeys.agenda_item.delete_confirmation.title)}/>
    </VStack>);
};
export default AgendaItem;
