import React, { useEffect, useRef } from 'react';
import Avatar from '../../../../components/content/avatar';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import useLogic from './logic.web';
import getUserName from '../../../../utils/getUserName';
import Text from '../../../../components/content/text';
import { t } from 'i18next';
import { tKeys } from '../../../../translations/keys';
import Modal from '../../../../components/surfaces/modal/index.web';
import { HStack, Textarea } from '@chakra-ui/react';
const EditPost = ({ isOpen, itemPost, onClose, }) => {
    // Attributes
    const ref = useRef(null);
    const { form, isEditPostLoading, currentUserData, handleOnSubmit, disabledSaveButton, dirty, } = useLogic({
        itemPost,
        isOpen,
        onClose,
    });
    useEffect(() => {
        // Little hack to keep the keyboard opened
        // Autofocus doesn't work because the input gets unfocused after being mounted
        setTimeout(() => ref?.current?.focus(), 550);
    }, []);
    // Render
    return (<Modal isOpen={isOpen} onClose={onClose} size={'2xl'} hasCloseButton title={t(tKeys.news_feed.edit_post)} actionButton={<Button onPress={handleOnSubmit} isLoading={isEditPostLoading} isDisabled={disabledSaveButton} kind={Kind.WHITE} size={Size.MINI}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value} color={!dirty ? COLORS.InputText.value : COLORS.Govrn.Blue.T500.value}>
            {t(tKeys.common.save)}
          </Text>
        </Button>}>
      {/* Avatar and text */}
      <HStack p={'1rem'} spacing={'.5rem'} height={'20rem'} alignItems={'flex-start'}>
        <Avatar imageSource={currentUserData?.person?.picture?.uri} borderRadius={6.2} height={31} width={31} name={getUserName(currentUserData.person)} placeholderFont={FONTS.T1.T10Px.SemiBold600.value} isOnline={undefined}/>

        <Textarea value={form?.text?.value} onChange={e => form?.text?.onChange(e?.currentTarget?.value)} defaultValue={form?.text?.value} placeholder={t(tKeys.common['write-your-message'])} height={'15rem'} paddingLeft={'.25rem'} paddingTop={'.25rem'}/>
      </HStack>
    </Modal>);
};
export default EditPost;
