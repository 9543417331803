import React from 'react';
import TasksFilterContentModal from '../tasks-filter-content-modal';
import ModalHeader from '../tasks-filter-header-modal/index.web';
import { VStack } from '@chakra-ui/react';
const TasksFilterModal = ({ form, onClickResetButton, title, }) => {
    // Render
    return (<VStack width={'18.75rem'} alignItems={'flex-start'}>
      {/* Header */}
      <ModalHeader onClickResetButton={onClickResetButton} title={title}/>

      {/* Content */}
      <TasksFilterContentModal form={form}/>
    </VStack>);
};
export default TasksFilterModal;
