import { useFormik } from 'formik';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { createForm } from '../../../../../../utils/formik';
import { t } from 'i18next';
import { validationSchema } from './validations';
import { useCreateBoardRole, useUpdateBoardRole, } from '../../../../../../services/online/enrolments/enrolments';
const useUpsertBoardRoleLogic = (onClose, refetchBoardRoles, boardRole) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { mutateAsync: createBoardRole, isLoading: isCreateBoardRoleLoading } = useCreateBoardRole();
    const { mutateAsync: updateBoardRole, isLoading: isUpdateBoardRoleLoading } = useUpdateBoardRole();
    // Functions
    const handleOnSubmit = async () => {
        if (form?.boardRoleName?.value?.length) {
            if (boardRole) {
                await updateBoardRole({
                    data: {
                        companyId: selectedCompanyId,
                        id: boardRole?.id,
                        name: form?.boardRoleName?.value,
                    },
                }, {
                    onSuccess: () => {
                        refetchBoardRoles();
                        onClose();
                    },
                    onError: err => console.error('Error when updating file type : ', err),
                });
                return;
            }
            await createBoardRole({
                data: {
                    companyId: selectedCompanyId,
                    name: form?.boardRoleName?.value,
                },
            }, {
                onSuccess: () => {
                    refetchBoardRoles();
                    onClose();
                },
                onError: err => console.error('Error when creating board role : ', err),
            });
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            boardRoleName: boardRole?.name ?? '',
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnSubmit: handleSubmit,
        isCreateBoardRoleLoading,
        isUpdateBoardRoleLoading,
        dirty,
        form,
    };
};
export default useUpsertBoardRoleLogic;
