import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, VStack } from '@chakra-ui/react';
import PeriodTimeButton from './period-time-button';
import FONTS from '../../../../../../constants/fonts';
import COLORS from '../../../../../../constants/colors';
import Text from '../../../../../../components/content/text';
import { tKeys } from '../../../../../../translations/keys';
const PeriodTimeButtonGroup = ({ selectedValue, periodTimes, handleOnPressPeriodTimeButton, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} px={'.75rem'} spacing={'.75rem'}>
      <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS?.Text.T400.value}>
        {t(tKeys.news_feed.period_time.title)}
      </Text>
      <HStack justifyContent={'space-between'} width={'full'}>
        {periodTimes.map((item, index) => (<PeriodTimeButton key={index} value={item} title={t(tKeys.common[item])} selectedValue={selectedValue} handleOnPress={() => handleOnPressPeriodTimeButton(item)}/>))}
      </HStack>
    </VStack>);
};
export default PeriodTimeButtonGroup;
