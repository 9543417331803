import React from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import Icon from '../../content/icon';
import Tag from '../../content/tag';
import Text from '../../content/text';
import { PressableType } from '../../inputs/button/type';
import PressableWrapper from '../../inputs/button/wrapper';
import Flex from '../flex';
import styles from './style';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import { getPlatformRoleLabel } from '../../../utils/platformRole';
const InvitationCard = ({ handleOnDelete, handleOnResendInvitation, userEmail, userRole, confirmationDate, expirationDate, canRemoveInvitation, }) => {
    // Attributes
    const { t } = useTranslation();
    const getTagProps = () => {
        if (confirmationDate) {
            return {
                children: `${t(tKeys.common.sent_on)} ${confirmationDate}`,
                backgroundColor: COLORS.Success.T100.value,
                color: COLORS.Success.T500.value,
            };
        }
        if (expirationDate) {
            return {
                children: t(tKeys.invitation.status.expired),
                backgroundColor: COLORS.Error.T100.value,
                color: COLORS.Error.T500.value,
            };
        }
        return {
            children: t(tKeys.invitation.status.pending),
            backgroundColor: COLORS.Warning.T100.value,
            color: COLORS.Warning.T500.value,
        };
    };
    // Render
    return (<HStack p={'1rem'} justify="space-between" spacing={'.5rem'} width={'full'} bgColor={COLORS.White.T500.value} borderRadius={'.75rem'} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'}>
      <VStack width={'full'} alignItems={'flex-start'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>{userEmail}</Text>
        <HStack align="center" spacing={'0.375rem'}>
          <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value}>
            {getPlatformRoleLabel(userRole)}
          </Text>
          <Tag {...getTagProps()} height={19} style={styles.tag}/>
        </HStack>
      </VStack>
      <Flex align="center" spacing={12}>
        <PressableWrapper pressableType={PressableType.SCALE} hitSlop={{ bottom: 12, left: 12, right: 12, top: 12 }} onPress={handleOnResendInvitation}>
          <Stack p={'.5rem'} borderWidth={1} borderRadius={'1.5rem'}>
            <Icon name="rotate-right" width={20} height={20} stroke={COLORS.Text.T500.value}/>
          </Stack>
        </PressableWrapper>
        {canRemoveInvitation && (<PressableWrapper pressableType={PressableType.SCALE} hitSlop={{ bottom: 12, left: 12, right: 12, top: 12 }} onPress={handleOnDelete}>
            <Icon name="close-cross" width={24} height={24} stroke={COLORS.InputText.value}/>
          </PressableWrapper>)}
      </Flex>
    </HStack>);
};
export default InvitationCard;
