/* eslint-disable react/prop-types */
import React from 'react';
import { HStack, Stack, VStack, chakra } from '@chakra-ui/react';
import EventCardDate from '../../../../../../components/surfaces/event-card/event-card-date/index.web';
import COLORS from '../../../../../../constants/colors';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
import Tag from '../../../../../../components/content/tag/index.web';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../../../../hooks/format-date/useFormatDate';
import moment from 'moment';
import { tKeys } from '../../../../../../translations/keys';
import ButtonPresenceStatus from '../../../components/inputs/button-present-status/index.web';
import { PresenceStatus } from '../../types';
import Icon from '../../../../../../components/content/icon';
function EventDetailsMainInformationsComponent({ event, isAdmin, isCurrentUserInvited, handlePresenceStatus, currentUserInvitee, }) {
    // Attributes
    const { t } = useTranslation();
    const { formatDateTime } = useFormatDate();
    // Render
    function DisplayPresencesButtons() {
        if (!event?.publishedOn || moment(event?.startDate).isBefore(moment())) {
            return <></>;
        }
        if (isAdmin && !isCurrentUserInvited) {
            return <></>;
        }
        if (!isCurrentUserInvited) {
            return <></>;
        }
        return (<VStack alignItems={'flex-start'} bgColor={COLORS.Tag.value} p="1rem" borderRadius={'.5rem'}>
        <Stack pb={'.25rem'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.home.calendar.event_details.information.presence.title)}
          </Text>
        </Stack>
        <HStack>
          {/* Present */}
          <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .present)} onPress={() => handlePresenceStatus(PresenceStatus?.Present)} iconName={'check'} isSelected={!!currentUserInvitee?.acceptedOn && !currentUserInvitee?.isRemote} colors={COLORS.Success.T500.value} height={'2rem'}/>

          {/* Remote */}
          <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .remote)} onPress={() => handlePresenceStatus(PresenceStatus?.Remote)} iconName={'remote'} isSelected={currentUserInvitee?.isRemote} colors={COLORS.Warning.T500.value} height={'2rem'}/>
        </HStack>

        <HStack>
          {/* Proxy */}
          <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .proxy)} onPress={() => handlePresenceStatus(PresenceStatus?.Proxy, currentUserInvitee?.proxyId)} iconName={'proxy'} isSelected={!!currentUserInvitee?.proxyId} colors={COLORS.Warning.T500.value} height={'2rem'}/>

          {/* Absent */}
          <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .absent)} onPress={() => handlePresenceStatus(PresenceStatus?.Absent)} iconName={'close-cross'} isSelected={!!currentUserInvitee?.refusedOn && !currentUserInvitee?.proxyId} colors={COLORS.Error.T500.value} height={'2rem'}/>
        </HStack>
      </VStack>);
    }
    return (<VStack width={'full'} alignItems={'flex-start'} borderRadius={'.75rem'} p={'1.25rem'} backgroundColor={COLORS.White.T500.value} borderWidth={1} borderColor={COLORS.Line.value} spacing={'.75rem'}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <HStack spacing={'.75rem'}>
          {/*  Event Date */}
          <EventCardDate date={event?.startDate} py={'1.5rem'}/>

          <VStack width={'full'} alignItems={'flex-start'}>
            {/*  Event Name */}
            <Text font={FONTS.T1.T20Px.Bold700.value}>{event?.name}</Text>

            {/*  Event Hours */}
            <HStack align="center" spacing={'1.25rem'}>
              <Tag font={FONTS.T1.T12Px.Medium500.value} backgroundColor={COLORS.Line.value} color={COLORS.Text.T400.value}>
                {t(event?.type?.name)}
              </Tag>
              <HStack>
                <Icon name="clock-black" height={20} width={20}/>
                <Text font={FONTS.T1.T12Px.Regular400.value}>
                  {`${formatDateTime({
            date: moment.utc(event?.startDate).toDate(),
            formatWithTimezone: true,
            format: 'HH:mm',
        })} - ${formatDateTime({
            date: moment.utc(event?.endDate).toDate(),
            formatWithTimezone: true,
            format: 'HH:mm',
        })}`}
                </Text>
              </HStack>
            </HStack>
          </VStack>
        </HStack>
        {/* Presence */}
        <>{DisplayPresencesButtons()}</>
      </HStack>
    </VStack>);
}
export const EventDetailsMainInformations = chakra(EventDetailsMainInformationsComponent);
