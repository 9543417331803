import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    listHeader: {
        marginVertical: 20,
    },
    flatlist: {
        paddingTop: 20,
        backgroundColor: COLORS.BG.value,
    },
    contentContainer: {
        paddingBottom: 50,
        paddingHorizontal: 12,
    },
    columnWrapper: {
        flex: 1,
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    iconContainer: {
        marginRight: 6,
    },
    filterButton: { padding: 6, justifyContent: 'center', borderRadius: 6 },
});
export default styles;
