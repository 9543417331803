import { Pressable } from 'react-native';
import COLORS from '../../../constants/colors';
import useRouter from '../../../hooks/router/useRouter';
import Container from '../container';
import Flex from '../flex';
import styles from './style';
import Icon from '../../content/icon';
import Text from '../../content/text';
import FONTS from '../../../constants/fonts';
const OfflineRedirectCard = ({ text1, text2 }) => {
    const { replace } = useRouter();
    return (<Container style={{
            ...styles.container,
        }}>
      <Pressable onPress={() => {
            replace('/home/feed');
        }}>
        <Flex justify="space-between">
          {/* Icon success */}
          <Flex direction="row" flex={1} flexGrow={1}>
            <Container style={{
            ...styles.iconContainer,
            backgroundColor: COLORS.Success.T500.value,
        }}>
              <Icon name="check-circle" width={18} height={18}/>
            </Container>

            {/* Text */}
            <Flex flex={1} flexGrow={1} direction="column">
              <Flex flex={1} flexGrow={1} align="center">
                <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T500.value} numberOfLines={2}>
                  {text1}
                </Text>
              </Flex>
              <Flex flex={1} flexGrow={1} align="center">
                <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T500.value} numberOfLines={2} style={{
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
        }}>
                  {text2}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Pressable>
    </Container>);
};
export default OfflineRedirectCard;
