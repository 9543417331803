import React, { useEffect, useMemo, useState } from 'react';
import CalendarPicker from '../calendar';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
import styles from './style.web';
import FilterButton from './button/index.web';
import moment from 'moment';
import { Box, Collapse, HStack, VStack } from '@chakra-ui/react';
function FilterDateAndHours({ title, close, dateTitleFont = FONTS.T1.T14Px.Medium500.value, name, value, onChange, onOpen, isTimeEnabled, dateFormat = 'DD MMM YYYY', timeFormat = 'HH:mm', error, startEnhancer, placeholderEnabled = false, timezoneId, minDate, }) {
    // Attributes
    const [openedPicker, setOpenPicker] = useState();
    const [renderChildrenCollapsed, setRenderChildrenCollapsed] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initDate, _] = useState(() => {
        const initDate = new Date();
        initDate.setHours(0, 0, 0, 0);
        return initDate;
    });
    const [date, setDate] = useState();
    const showPlaceholder = useMemo(() => {
        if (!placeholderEnabled)
            return false;
        if (date)
            return moment(date).isSame(initDate);
        return true;
    }, [placeholderEnabled, initDate, date]);
    // Dirty states
    const [isCalendarDirty, setCalendarDirty] = useState(false);
    const [isTimeDirty, setTimeDirty] = useState(false);
    // Handlers
    const onTogglePress = (type) => {
        if (!openedPicker)
            onOpen?.(); // Custom handler from props
        // if we press on the already opened picker then we close it
        if (openedPicker === type) {
            setOpenPicker(undefined);
            setRenderChildrenCollapsed(false);
            return;
        }
        setOpenPicker(type);
        setRenderChildrenCollapsed(true);
    };
    const handleTimeChange = (value) => {
        if (!value) {
            date.setHours(0);
            date.setMinutes(0);
            onChange(date);
            return;
        }
        // Split the time value into hours and minutes
        const [hours, minutes] = value.split(':');
        // Set the hours and minutes of the new Date object
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        // Update the state with the modified date
        onChange(date);
    };
    // On press on the calendar dates
    const onDayPress = (value) => {
        // Update Date value
        if (isTimeEnabled) {
            if (!isTimeDirty) {
                value.setHours(14, 0, 0, 0);
            }
            else {
                value.setHours(date?.getHours(), value?.getMinutes(), 0, 0);
            }
        }
        setDate(value);
        // Handle next step
        setOpenPicker(undefined);
        // set Dirty states
        setCalendarDirty(true);
    };
    // Effects
    useEffect(() => {
        if (close) {
            setOpenPicker(undefined);
        }
    }, [close]);
    // Update Form value
    useEffect(() => {
        if (isCalendarDirty) {
            if (typeof value === 'string')
                onChange?.(new Date(date));
            else
                onChange?.(date);
        }
    }, [date]);
    // Update date value
    useEffect(() => {
        if (value) {
            const overrideDate = typeof value === 'string' ? new Date(value) : value;
            setDate(overrideDate);
            setCalendarDirty(true);
            setTimeDirty(true);
        }
    }, [value]);
    // Render
    const PickerComponents = () => {
        switch (openedPicker) {
            case 'date':
                return (<CalendarPicker name={name} value={date} onChange={(value) => onDayPress(value)} error={error} minDate={minDate}/>);
            default:
                return null;
        }
    };
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'}>
      <HStack spacing={'.5rem'} width={'full'}>
        {startEnhancer}

        <HStack justify="space-between" spacing={'.5rem'} width={'full'}>
          <Text font={dateTitleFont} color={COLORS?.Text.T400.value}>
            {title}
          </Text>

          <HStack spacing={'.5rem'}>
            <FilterButton date={date} dateFormat={dateFormat} isDirty={isCalendarDirty} isFocused={openedPicker === 'date'} onPress={() => onTogglePress('date')} showPlaceholder={showPlaceholder} timezoneId={timezoneId}/>

            {isTimeEnabled && (<FilterButton date={date} dateFormat={timeFormat} isDirty={isTimeDirty} isFocused={openedPicker === 'time'} isDisabled={showPlaceholder} onPress={() => undefined} showPlaceholder={showPlaceholder} timezoneId={timezoneId} isHourPicker onChangeHours={handleTimeChange}/>)}
          </HStack>
        </HStack>
      </HStack>

      {error ? (<Box marginTop={'0.75rem'}>
          <Text color={error ? COLORS.Error.T500.value : COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value}>
            {error}
          </Text>
        </Box>) : null}

      <Collapse in={renderChildrenCollapsed} style={styles.collapsedContainer}>
        <PickerComponents />
      </Collapse>
    </VStack>);
}
export default FilterDateAndHours;
