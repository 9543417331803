import React from 'react';
import { Td, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../../translations/keys';
import COLORS from '../../../../../../../constants/colors';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
export const MemberListTableHeader = ({ isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Thead position={'sticky'} top={0} zIndex={2} backgroundColor={COLORS.White.T500.value}>
      <Tr>
        {/* Name */}
        <Td pl={'.75rem'} pr={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.name.label)}
          </Text>
        </Td>
        {/* Email */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys['sign-in-forgot-mail']['email-address'].label)}
          </Text>
        </Td>
        {/* Phone */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.profile['user-profile'].mobile['navigation-header'])}
          </Text>
        </Td>
        {/* Groups */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.groups)}
          </Text>
        </Td>
        {/* Member since */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.profile.user_profile.member_since)}
          </Text>
        </Td>
        {/* Board role */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.board_role)}
          </Text>
        </Td>
        {/* Platform role */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.platform_role)}
          </Text>
        </Td>
        {/* Menu */}
        {isAdmin && <Td pr={'.75rem'} pl={'.5rem'} py={'.75rem'}></Td>}
      </Tr>
    </Thead>);
};
