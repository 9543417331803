import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
    },
    pressableContainer: {
        paddingVertical: 12,
        paddingLeft: 12,
        borderRadius: 8,
    },
    textContainer: {
        marginLeft: 12,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default styles;
