/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { t } from 'i18next';
import React from 'react';
import { FlatList } from 'react-native';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import SearchInput from '../../../../components/inputs/search-input';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Container from '../../../../components/surfaces/container';
import Page from '../../../../components/surfaces/page';
import Placeholder from '../../../../components/utility/placeholder';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import styles from './style';
import useLogic from './logic';
import EventDetailsInviteesPresenceStatus from '../components/content/event-details-invitees-presence-status';
const EventDetailsInviteesPresence = () => {
    // Attributes
    const { goBack, event, handlePressOnAddInvitees, form, handlePresenceStatus, } = useLogic();
    const renderItems = ({ item, index, }) => {
        if (event?.invitees?.filter(invite => invite?.proxyId === item?.personId)
            ?.length) {
            return <></>;
        }
        return (<Container style={styles.mainContainer} key={`${index} - ${item?.personId}`}>
        <EventDetailsInviteesPresenceStatus invitee={item} handlePresenceStatus={handlePresenceStatus} event={event}/>
      </Container>);
    };
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader onGoBack={goBack} leftIconName={'chevron-left'} justifyTitle={'center'} endEnhancer={<Button kind={Kind.TRANSPARENT} onPress={handlePressOnAddInvitees}>
            <Icon name="add" width={24} height={24}/>
          </Button>}>
        <Container marginLeft={8} maxWidth={250}>
          <Text font={FONTS.T1.T16Px.Bold700.value}>
            {`${t(tKeys.home.calendar.event_details.invitees.status.title)} (${event?.invitees?.length ?? 0})`}
          </Text>
        </Container>
      </NavigationHeader>

      {/* Main content */}
      <SearchInput {...form?.searchValue} placeholder={t(tKeys.common.search)} isClearable={form?.searchValue?.value?.length > 0} onPressClear={() => form?.searchValue?.onChange('')} autofocus={form?.searchValue?.value?.length > 0}/>

      <FlatList data={event?.invitees ?? []} renderItem={renderItems} showsVerticalScrollIndicator={false} style={styles.flatlist} ListEmptyComponent={!event?.invitees ? (<Placeholder imageType={'think-emoji'} title={t(tKeys.create_event.placeholder.title)} subTitle={t(tKeys.create_event.placeholder.body)}/>) : null}/>
    </Page>);
};
export default EventDetailsInviteesPresence;
