import { useEffect, useState } from 'react';
import NetInfo from '@react-native-community/netinfo';
const useConnectionState = () => {
    const [connectionState, setConnectionState] = useState(undefined);
    // Effects
    useEffect(() => {
        const networkListener = NetInfo.addEventListener(state => {
            setConnectionState(state.isConnected);
        });
        return () => {
            networkListener();
        };
    }, []);
    return { connectionState };
};
export default useConnectionState;
