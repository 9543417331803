import React from 'react';
import { useReadEvent } from '../../../services/online/calendar/calendar';
import { Size } from './constants/size';
import DocumentCardCompact from './document-card-compact';
import DocumentCardDefault from './document-card-default';
const DocumentCard = ({ size, title, imageURI, eventId, updateDate, titleNbOfLines = 1, onPress, creationDate, type, }) => {
    // Queries
    const { data: eventData } = useReadEvent(eventId, {
        query: {
            enabled: !!eventId,
            staleTime: 60000, // 1 minute of delay
        },
    });
    // Render
    if (size === Size.COMPACT) {
        // Return the Document card compact component
        return (<DocumentCardCompact imageURI={imageURI} title={title} eventDate={eventData?.startDate} eventName={eventData?.name} updateDate={updateDate} onPress={onPress} titleNbOfLines={titleNbOfLines} isAlreadyEnableOffline={false}/>);
    }
    // Return the Document card default component
    return (<DocumentCardDefault title={title} eventDate={eventData?.startDate} eventName={eventData?.name} imageURI={imageURI} titleNbOfLines={titleNbOfLines} updateDate={updateDate} onPress={onPress} creationDate={creationDate} type={type} isAlreadyEnableOffline={false}/>);
};
export default DocumentCard;
