import React from 'react';
import { Stack, VStack } from '@chakra-ui/react';
import Text from '../../../components/content/text/index.web';
import FONTS from '../../../constants/fonts';
import COLORS from '../../../constants/colors';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import DropzoneInput from '../../../components/inputs/dropzone/index.web';
import { useBoardPackDocumentPreviewPlaceholderLogic } from './logic.web';
export const BoardPackDocumentPreviewPlaceholder = ({ isAdmin, event, agendaItemId, agendaItemName, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isCreateDocumentLoading, handleDropDocument } = useBoardPackDocumentPreviewPlaceholderLogic(event, agendaItemId);
    // Render
    return (<VStack width={'100%'} height={'100%'} bgColor={COLORS.Tag.value} justifyContent={'center'} spacing={'1.5rem'}>
      <Text font={FONTS.T1.T14Px.SemiBold600.value}>
        {t(tKeys.board_pack.placeholder.title, { name: agendaItemName })}
      </Text>
      {isAdmin && (<Stack width={'50%'}>
          <DropzoneInput isLoading={isCreateDocumentLoading} onDrop={handleDropDocument}/>
        </Stack>)}
    </VStack>);
};
