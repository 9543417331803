import { useCompaniesContext } from '../../../../../../contexts/companies';
import { getReadDocumentsQueryKey, useCreateDocument, useDeleteDocument, useReadDocuments, useReadDocumentTypes, } from '../../../../../../services/online/repository/repository';
import { isMinute } from '../../../../../../utils/getDocumentKind';
import { DocumentKindEnum, } from '../../../../../document-details/type';
import { useFilePicker } from 'use-file-picker';
import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { onSelectedFileSuccess } from '../../../../../../utils/selectFile.web';
import useRouter from '../../../../../../hooks/router/useRouter';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import { Toast } from '../../../../../../components/content/use-toast/toast/index.web';
import { useQueryClient } from '@tanstack/react-query';
const useLogic = (event) => {
    // Attributes
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { selectedCompanyId } = useCompaniesContext();
    const [selectedFile, setSelectedFile] = useState();
    const { push } = useRouter();
    const { isOpen: isOpenCreateDocument, onOpen: onOpenCreateDocument, onClose: onCloseCreateDocument, } = useDisclosure();
    // Queries
    // File picker
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: [
            'image/jpeg',
            'image/png',
            'image/gif',
            '.txt',
            '.pdf',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.csv',
        ],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile, onOpenCreateDocument, event?.id),
    });
    const { me } = useCompaniesContext();
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const defaultDocumentType = documentTypes?.find(documentType => documentType.name === '_FT_BOARDAPPE')?.id;
    const { data: documents, refetch: refetchDocuments, isLoading: isDocumentLoading, } = useReadDocuments({
        'company-id': selectedCompanyId,
        'document-types': documentTypes?.map(d => d?.id),
        'event-id': event?.id,
    }, {
        query: {
            enabled: !!selectedCompanyId && !!documentTypes && !!event?.id,
        },
    });
    const { mutateAsync: createRepositoryDocument, isLoading: isCreateDocumentLoading, } = useCreateDocument();
    const { mutateAsync: deleteDocument, isLoading: isLoadingDeleteDocument } = useDeleteDocument();
    // Functions
    function handleOnSelectFile(value) {
        setSelectedFile({
            ...value,
            issuedOn: event.startDate ?? '',
            eventGroupIds: event.groupIds,
            eventPersonIds: event.inviteeIds,
        });
    }
    function refetchDocs() {
        void refetchDocuments();
    }
    function handleCreateDocument() {
        openFilePicker();
    }
    async function handleDeleteDocument(fileId) {
        try {
            // Update the selected document by removing the event where it's registered
            await deleteDocument({
                documentId: fileId,
            }, {
                onSuccess: () => {
                    void refetchDocuments();
                },
            });
        }
        catch (err) {
            console.error('Error when updating an file event : ', err);
        }
    }
    function handleOnPressDocument(documentId) {
        const document = documents
            ?.flatMap(category => category.items)
            ?.find(document => document.id === documentId);
        const params = {
            documentKind: isMinute(document)
                ? DocumentKindEnum.MINUTES
                : DocumentKindEnum?.DOCUMENT,
            documentId,
            eventId: event?.id,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...params,
        });
    }
    async function handleCreateNewFile(file) {
        if (!file)
            return;
        try {
            await createRepositoryDocument({
                data: {
                    typeId: defaultDocumentType,
                    companyId: selectedCompanyId,
                    creatorId: me?.id,
                    description: '',
                    file: {
                        content: file?.base64,
                        mimeType: file?.type,
                        filename: file?.name,
                    },
                    title: file?.name,
                    eventId: event.id,
                    agendaItemId: undefined,
                    issuedOn: event?.startDate,
                },
            }, {
                onError: () => {
                    Toast({
                        status: 'error',
                        title: t(tKeys.validation.document.upload.error),
                    });
                },
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                        'event-id': event?.id,
                        'document-types': documentTypes?.map(d => d?.id),
                        'company-id': selectedCompanyId,
                    }));
                    Toast({
                        status: 'success',
                        title: t(tKeys.validation.document.upload.success),
                    });
                },
            });
        }
        catch (err) {
            console.error('Error', err);
        }
    }
    const handleOnDrop = async (acceptedFiles) => {
        const readFileAsBase64 = async (file) => {
            return await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        };
        const filesWithBase64 = await Promise.all(acceptedFiles.map(async (file, index) => {
            const base64 = await readFileAsBase64(file);
            return {
                path: acceptedFiles[index].path,
                lastModified: acceptedFiles[index].lastModified,
                lastModifiedDate: acceptedFiles[index].lastModifiedDate,
                name: acceptedFiles[index].name,
                size: acceptedFiles[index].size,
                type: acceptedFiles[index].type,
                webkitRelativePath: acceptedFiles[index].webkitRelativePath,
                base64,
            };
        }));
        await Promise.all(filesWithBase64.map(async (file) => await handleCreateNewFile(file)));
    };
    // Return
    return {
        documents,
        isDocumentLoading,
        handleCreateDocument,
        handleDeleteDocument,
        handleOnPressDocument,
        handleOnDrop,
        selectedFile,
        isOpenCreateDocument,
        isCreateDocumentLoading,
        onCloseCreateDocument,
        refetchDocs,
        isLoadingDeleteDocument,
    };
};
export default useLogic;
