import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: COLORS.White.T500.value,
        padding: 16,
        borderRadius: 12,
    },
    nameContainer: {
        backgroundColor: COLORS.Tag.value,
        borderRadius: 100,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
});
export default styles;
