import { getLinkPreview } from 'link-preview-js';
import React, { useEffect, useState } from 'react';
import { Linking, Pressable } from 'react-native';
import Image from '../../../../../../../components/content/image';
import Text from '../../../../../../../components/content/text';
import Container from '../../../../../../../components/surfaces/container';
import Flex from '../../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import styles from './style';
const CardPreviewWebLink = ({ content, regExpressionUrl, }) => {
    // Attributes
    const [webSiteURI, setWebSiteURI] = useState();
    const [data, setData] = useState();
    const [isDataAlreadyFetched, setIsDataAlreadyFetched] = useState(false);
    // Functions
    const extractInfoFromWebSite = () => {
        try {
            if (!webSiteURI.match(regExpressionUrl)) {
                return;
            }
            void getLinkPreview(webSiteURI.trim(), {
                followRedirects: `manual`,
                headers: {
                    'user-agent': 'googlebot',
                },
                handleRedirects: (baseURL, forwardedURL) => {
                    const urlObj = new URL(baseURL);
                    const forwardedURLObj = new URL(forwardedURL);
                    // Same as SSRF, following redirections is dangerous, the library errors by default when the response tries to redirect the user.
                    // There are however some simple redirections which are valid (e.g. http to https) and you might want to allow, you can do it via :
                    return (forwardedURLObj.hostname === urlObj.hostname ||
                        forwardedURLObj.hostname === 'www.' + urlObj.hostname ||
                        'www.' + forwardedURLObj.hostname === urlObj.hostname);
                },
            })
                .then(data => {
                setIsDataAlreadyFetched(true);
                setData(data);
            })
                .catch(err => console.error('Error when fetching data from a web link : ', err));
        }
        catch (err) {
            console.error('Error when fetching data from a web link : ', err);
        }
    };
    const handleOnPress = async () => {
        const supported = await Linking.canOpenURL(webSiteURI.trim());
        if (supported)
            await Linking.openURL(webSiteURI);
    };
    // Effect
    useEffect(() => {
        if (content && regExpressionUrl) {
            setWebSiteURI(content?.match(regExpressionUrl)?.[0]);
        }
    }, [content, regExpressionUrl]);
    useEffect(() => {
        if (webSiteURI && !isDataAlreadyFetched) {
            void extractInfoFromWebSite();
        }
    }, [webSiteURI]);
    useEffect(() => {
        if (data && !isDataAlreadyFetched) {
            void extractInfoFromWebSite();
        }
    }, [data]);
    // Render if no image given
    if (!data) {
        return <></>;
    }
    // Render
    return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Pressable onPress={async () => await handleOnPress()}>
      <Flex direction={'column'}>
        {/* Image */}
        <Container style={styles.imageContainer} alignSelf="flex-start">
          <Image imageURI={data?.images?.[0]} width={'100%'} height={250}/>
        </Container>

        {/* Website URI and link */}
        <Container style={styles.infoContainer}>
          <Container style={styles.textContainer}>
            <Text font={FONTS.T1.T12Px.SemiBold600.value}>{data?.title}</Text>
          </Container>

          <Container style={styles.textContainer}>
            <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value}>
              {webSiteURI}
            </Text>
          </Container>
        </Container>
      </Flex>
    </Pressable>);
};
export default CardPreviewWebLink;
