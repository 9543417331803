import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Button from '../../inputs/button';
import { Kind } from '../../inputs/button/constants/kind';
import Flex from '../../surfaces/flex';
import StatusComponent from '../status';
import Text from '../text';
import Image from '../image';
import { Stack, Image as ChakraImage } from '@chakra-ui/react';
const Avatar = ({ height = '2.625rem', width = '2.625rem', borderRadius = 42, imageSource, name, placeholderFont = FONTS.T1.T28Px.Medium500.value, isLoading, isOnline, backgroundColor = COLORS.Warning.T100.value, textColor = COLORS.Warning.T500.value, isLocalImage, isSmallAvatar = true, }) => {
    // Functions
    /* Will take every words and take only the first letters of thoses words to return only the the first and the second letters.
     * Examples :
     * If name equals:
     *   - 'John Doe Jhonson' => 'JD'
     *   - 'John Doe' => 'JD'
     *   - 'John' => J
     *   - '' => ''
     */
    const getInitials = () => {
        if (!name || name.includes('undefined')) {
            return '';
        }
        const words = name.split(' ');
        const initials = words.map(word => word[0]);
        return initials.slice(0, 2).join('').toUpperCase();
    };
    const renderAvatar = () => {
        if (isLoading) {
            return (<Stack width={width / 2} height={height / 2} align="center" justify={'center'}>
          <Button isLoading kind={Kind.TRANSPARENT}/>
        </Stack>);
        }
        // If there is no image given or placeholder is set to true it return an initials composed by the given name
        if (!imageSource && !isLocalImage) {
            return (<Flex style={{
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 100,
                }}>
          <Text color={textColor} font={placeholderFont} style={{ textAlign: 'center' }}>
            {getInitials()}
          </Text>
        </Flex>);
        }
        const getImageSource = () => {
            if (typeof imageSource === 'string') {
                return imageSource;
            }
            if (imageSource?.uri)
                return imageSource?.uri;
            if (imageSource?.content)
                return `data:image/png;base64,${imageSource?.content}`;
        };
        return isSmallAvatar ? (<Image imageURI={getImageSource()} width={width} height={height} borderRadius={borderRadius} isLocalImage={isLocalImage} resizeMode="cover"/>) : (<ChakraImage src={getImageSource()} borderRadius={borderRadius} maxH={height} maxW={width} alignItems={'center'} objectFit={'contain'}/>);
    };
    // Render
    return (<Stack height={height} width={width} borderRadius={borderRadius} bgColor={backgroundColor} align={'center'} justify={'center'} position={'relative'}>
      {renderAvatar()}
      {isOnline !== null ? (<StatusComponent isOnline={isOnline} borderRadius={borderRadius}/>) : null}
    </Stack>);
};
export default Avatar;
