import React from 'react';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import { TableContainer, Table, Tbody, VStack, HStack, useDisclosure, } from '@chakra-ui/react';
import { MemberListTableHeader } from './member-list-table-header/index.web';
import { MemberListTableRow } from './member-list-table-row/index.web';
import COLORS from '../../../../../../constants/colors';
import Text from '../../../../../../components/content/text';
import { tKeys } from '../../../../../../translations/keys';
import FONTS from '../../../../../../constants/fonts';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/inputs/button/index.web';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Icon from '../../../../../../components/content/icon';
import InviteMember from '../../../../../invite-member/index.web';
import { ShowMembersFilter } from './show-members-filter/index.web';
const MembersList = ({ enrolments, isEnrolmentsLoading, handleOnPressMember, isAdmin, onEnrolmentUpdate, refetchAfterInvite, onTabPress, onShowMembersFilterChange, showMembersFilter, }) => {
    // Attributes
    const { t } = useTranslation();
    const inviteMemberDisclosure = useDisclosure();
    // Render
    return (<VStack p={'1.25rem'} overflow={'auto'} spacing={'1rem'}>
      <HStack width={'100%'} justifyContent={'space-between'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.more.page.menu.members.menu_title)}
        </Text>
        <HStack spacing={'.75rem'}>
          <ShowMembersFilter onShowMembersFilterChange={onShowMembersFilterChange} showMembersFilter={showMembersFilter}/>
          <Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={inviteMemberDisclosure.onOpen}>
            <HStack>
              <Icon name="add-member" width={26} height={26} fill={'transparent'}/>
              <Text color={COLORS.White.T500.value}>
                {t(tKeys.home.more.members.addbutton.title)}
              </Text>
            </HStack>
          </Button>
        </HStack>
      </HStack>
      <LoadingWrapper isLoading={isEnrolmentsLoading}>
        <TableContainer borderRadius={'.5rem'} borderWidth={1} width={'100%'} maxHeight={'100%'} overflowY={'auto'} overflowX={'auto'} minHeight={'fit-content'} backgroundColor={COLORS.White.T500.value}>
          <Table>
            <MemberListTableHeader isAdmin={isAdmin}/>
            <Tbody>
              {/* Members list */}
              {enrolments.map(enrolment => (<MemberListTableRow enrolment={enrolment} key={enrolment.id} onClick={handleOnPressMember} isAdmin={isAdmin} onEnrolmentUpdate={onEnrolmentUpdate}/>))}
            </Tbody>
          </Table>
        </TableContainer>
      </LoadingWrapper>
      {/* Modals */}
      <InviteMember isOpen={inviteMemberDisclosure.isOpen} onClose={inviteMemberDisclosure.onClose} refetchAfterInvite={refetchAfterInvite} redirectionAfterInvite={onTabPress}/>
    </VStack>);
};
export default MembersList;
