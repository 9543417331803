import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    tabBarContainer: {
        paddingTop: 16,
        paddingHorizontal: 4,
        backgroundColor: COLORS.White.T500.value,
        width: '100%',
        borderBottomWidth: 1,
        borderColor: COLORS.Line.value,
    },
});
export default styles;
