import { useTranslation } from 'react-i18next';
import useIsTablet from '../../../hooks/is-tablet';
import useLogic from './logic';
import Page from '../../../components/surfaces/page';
import NavigationHeader from '../../../components/navigation/navigation-header';
import COLORS from '../../../constants/colors';
import LoadingWrapper from '../../../components/utility/loading-wrapper';
import React from 'react';
import { tKeys } from '../../../translations/keys';
import MessageContent from './components/content/message-content';
import Flex from '../../../components/surfaces/flex';
import ButtonAddMessage from './components/inputs/button-add-message/index.web';
import styles from './style.web';
import { useDisclosure } from '@chakra-ui/react';
import CreateChannel from './create-channel/index.web';
import Search from '../../search/index.web';
const Messages = () => {
    // Attributes
    const { t } = useTranslation();
    const IsTablet = useIsTablet();
    // Logic
    const { channels, isModuleAvailable, search, setSearch, onCreateChannelPress, refetchChannels, isChannelsLoading, isPageLoading, onChannelPress, getParticipant, onSearchPress, selectedChannel, handleOnMemberPress, isCreateChannelLoading, handleOnCreateGroup, } = useLogic();
    const { onOpen, onClose, isOpen } = useDisclosure();
    // Render
    return (<Page showSideBar barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader justifyTitle="flex-start" endEnhancer={<Flex spacing={20} style={styles.container}>
            <Search />
            <ButtonAddMessage onPress={onOpen} search={search}/>
          </Flex>} divider={!isModuleAvailable}>
        {t(tKeys.home.messages.navHeader.title)}
      </NavigationHeader>

      <LoadingWrapper isLoading={isPageLoading || isCreateChannelLoading} fullScreen>
        <MessageContent isModuleAvailable={isModuleAvailable} IsTablet={IsTablet} channels={channels} isChannelsLoading={isChannelsLoading} refetchChannels={refetchChannels} search={search} setSearch={setSearch} onCreateChannelPress={onCreateChannelPress} onChannelPress={onChannelPress} getParticipant={getParticipant} selectedChannel={selectedChannel}/>
      </LoadingWrapper>
      {/* Modal create channel */}
      <CreateChannel isOpen={isOpen} onClose={onClose} onMemberPress={handleOnMemberPress} onCreateGroup={handleOnCreateGroup}/>
    </Page>);
};
export default Messages;
