import React from 'react';
import { Stack, Td, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import FONTS from '../../../../../../../constants/fonts';
import Text from '../../../../../../../components/content/text';
import COLORS from '../../../../../../../constants/colors';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
import Icon from '../../../../../../../components/content/icon';
import { GroupAvatars } from '../../../../../../../components/content/group-avatars/index.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../../translations/keys';
export const GroupListTableRow = ({ group, onGroupDelete, onGroupEdit, isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    const popoverMenuDisclosure = useDisclosure();
    // Render
    return (<Tr width={'100%'}>
      {/* Avatar(s) and group name */}
      <Td pl={'.75rem'} pr={'.5rem'} py={'.75rem'}>
        <GroupAvatars group={group}/>
      </Td>
      {/* Number of members in the group */}
      <Td px={'.5rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Regular400.value}>
          {group.persons?.length ?? 0}
        </Text>
      </Td>
      {/* Menu */}
      {isAdmin && (<Td pr={'.75rem'} pl={'.5rem'} py={'.75rem'}>
          <PopOver placement={'bottom-end'} from={<Icon name="more-vertical" height={20} width={20}/>} isOpen={popoverMenuDisclosure.isOpen} onToggle={popoverMenuDisclosure.onToggle} backgroundColorHover={COLORS.Tag.value}>
            <VStack minWidth={'17.5rem'} alignItems={'flex-start'} py={'.25rem'} spacing={0}>
              {/* Edit group */}
              <Stack _hover={{ backgroundColor: COLORS.BG.value }} width={'100%'} onClick={() => onGroupEdit(group)} py={'.5rem'} px={'1rem'} cursor={'pointer'}>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {t(tKeys.common.edit)}
                </Text>
              </Stack>

              {/* Delete group */}
              <Stack _hover={{ backgroundColor: COLORS.BG.value }} width={'100%'} onClick={() => onGroupDelete(group.id)} py={'.5rem'} px={'1rem'} cursor={'pointer'}>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {t(tKeys.boardEvaluation.overview.surveyBox.menu.delete)}
                </Text>
              </Stack>
            </VStack>
          </PopOver>
        </Td>)}
    </Tr>);
};
