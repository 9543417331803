import React from 'react';
import { Container, HStack } from '@chakra-ui/react';
import Icon from '../../../../../components/content/icon';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import Text from '../../../../../components/content/text';
import { Pressable } from 'react-native';
import COLORS from '../../../../../constants/colors';
import CalendarPicker from '../../../../../components/pickers/calendar';
import { DateFormat, formatDate } from '../../../../../utils/date';
export const FilterDate = ({ date, handleOnDateChange, togglePopover, onTogglePopOver, textDate, }) => {
    return (<PopOver from={<HStack border="1px solid #E7E9F4" spacing={4} align="center" backgroundColor="#fff" color={COLORS.Text.T400.value} borderRadius="8px" padding="20px" height={'32px'}>
          <Text color={date ? COLORS.Text.T400.value : COLORS.Text.T300.value}>
            {textDate}:{' '}
          </Text>
          {date && (<HStack spacing={4} backgroundColor={COLORS.Govrn.Blue.T100.value} paddingLeft="0.5rem" paddingRight="0.5rem" paddingTop="0.25rem" paddingBottom="0.25rem" borderRadius="0.125rem">
              <Text>{formatDate(date, DateFormat.ShortFormat, '')}</Text>
              <Pressable onPress={() => handleOnDateChange(null)}>
                <Icon name="close-cross" width={14} height={14} fill={COLORS.Text.T500.value}/>
              </Pressable>
            </HStack>)}
          <Icon name="chevron-down" width="1rem" stroke={'#ccc'}/>
        </HStack>} placement="bottom-start" isOpen={togglePopover} onToggle={onTogglePopOver}>
      <Container>
        <CalendarPicker name={'dateCalendar'} value={date} onChange={handleOnDateChange}/>
      </Container>
    </PopOver>);
};
