import React from 'react';
import { Box, Checkbox, HStack, Stack, VStack } from '@chakra-ui/react';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
import { Kind } from '../../../../../../../components/inputs/button/constants/kind';
import Tag from '../../../../../../../components/content/tag/index.web';
import { getAgendaItemTypeLabel } from '../../../../../../../utils/agendaItem';
import styles from '../style';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../components/content/icon';
import Text from '../../../../../../../components/content/text';
import Button from '../../../../../../../components/inputs/button';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
export const AgendaItemInformations = ({ agendaItem, onSelect, parentIndexes, isSelected, isCurrentUserInvited, isCurrentUserPresent, onEditPress, isDeleteLoading, isVoteEnabled, onSeeResultsPress, onVotePress, onDeletePress, isAdmin, redirectToBoardPack, }) => {
    // Attributes
    const { t } = useTranslation();
    const displayIndexes = parentIndexes.join('.');
    const total = (agendaItem?.question?.answers?.filter(a => a.text !== 'None') ?? [])?.reduce((acc, cur) => acc + cur.votes?.length, 0);
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} px={'1.25rem'} py={'.5rem'} bgColor={COLORS.BG.value} borderWidth={1} borderColor={COLORS.Line.value} borderRadius={'.5rem'}>
      <HStack justify="space-between" spacing={'.5rem'} width={'full'}>
        <HStack>
          <Box>
            <Icon name="draggable-item" height={16} width={16}/>
          </Box>
          <Checkbox name={agendaItem?.id} onChange={() => onSelect(agendaItem?.id, agendaItem?.agendaItemParentId)} isChecked={isSelected} color={COLORS.Success.T500.value}/>
          <Box>
            <Text font={FONTS.T1.T14Px.Bold.value}>{displayIndexes}</Text>
          </Box>
          {/* Title */}
          <Stack onClick={() => redirectToBoardPack(agendaItem.id, agendaItem.title)} _hover={{ textDecor: 'underline' }} cursor={'pointer'}>
            <Text font={FONTS.T1.T12Px.Medium500.value} numberOfLines={1}>
              {agendaItem?.title}
            </Text>
          </Stack>
        </HStack>
        <HStack spacing={'.75rem'}>
          {/* Count documents */}
          {agendaItem.filesCount == 0 ? (<HStack spacing={'.25rem'} _hover={{ textDecor: 'underline' }} onClick={() => onEditPress(agendaItem)}>

            <Icon name="add-document-blue" fill={COLORS.Text.T400.value} width={20} height={20}/>
            <Text font={FONTS.T1.T12Px.Regular400.value}>
              Click or drop your file
            </Text>
          </HStack>) : (<HStack spacing={'.25rem'} _hover={{ textDecor: 'underline' }} onClick={() => onEditPress(agendaItem)}>

            <Icon name="repoDefault" fill={COLORS.Text.T400.value} width={20} height={20}/>
            <Text font={FONTS.T1.T12Px.Regular400.value}>
              {agendaItem.filesCount}
            </Text>
          </HStack>)}

          {/* Timing */}
          {agendaItem.duration && (<HStack spacing={'.25rem'}>
              <Icon name="clock-black" width={20} height={20}/>
              <Text font={FONTS.T1.T12Px.Regular400.value}>
                {t(tKeys.agenda_item.timing.information, {
                value: agendaItem.duration,
            })}
              </Text>
            </HStack>)}
          {/* Type */}
          <Tag backgroundColor={COLORS.Govrn.Blue.T100.value} color={COLORS.Govrn.Blue.T500.value} style={styles.agendaItemTypeTag}>
            {getAgendaItemTypeLabel(agendaItem.type)}
          </Tag>
          <PopOver from={<Icon name="more-vertical" height={20} width={20}/>} placement="bottom-start">
            <VStack width={'full'} alignItems={'flex-start'} minW={'12rem'} spacing={0} py={'.5rem'}>
              {/* Edit post */}

              <Stack alignItems={'flex-start'} width={'full'} onClick={() => onEditPress(agendaItem)} _hover={{ backgroundColor: COLORS.Tag.value }} cursor={'pointer'} p={'.5rem'}>
                <Text font={FONTS.T1.T14Px.Medium500.value}>
                  {isAdmin ? t(tKeys.news_feed.edit_post) : t(tKeys.tasks.open)}
                </Text>
              </Stack>

              {isAdmin && (<Stack alignItems={'flex-start'} width={'full'} onClick={onDeletePress} _hover={{ backgroundColor: COLORS.Tag.value }} cursor={'pointer'} p={'.5rem'}>
                  <Text font={FONTS.T1.T14Px.Medium500.value} color={isDeleteLoading
                ? COLORS.InputText.value
                : COLORS.Text.T800.value}>
                    {t(tKeys.news_feed.delete_post.title)}
                  </Text>
                </Stack>)}
            </VStack>
          </PopOver>
        </HStack>
      </HStack>
      {agendaItem?.question && (<VStack spacing={'1.25rem'} pt={'.5rem'} width={'full'} alignItems={'flex-start'}>
          <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.Text.T400.value} numberOfLines={undefined} style={{ paddingHorizontal: 4 }}>
            {agendaItem?.question?.text}
          </Text>
          {isVoteEnabled(agendaItem.question) &&
                isCurrentUserInvited &&
                isCurrentUserPresent ? (<Stack width={'full'} alignItems={'flex-end'}>
              <Button kind={Kind.SECONDARY} onPress={() => onVotePress(agendaItem.question)}>
                {t(tKeys.event_details.agenda.vote.button)}
              </Button>
            </Stack>) : (<HStack justify="space-between" width={'full'}>
              <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value}>
                {t(tKeys.calendar.agenda.item.totalVotes, { total })}
              </Text>
              <Box onClick={() => onSeeResultsPress(agendaItem.question)} cursor={'pointer'}>
                <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Govrn.Blue.T500.value}>
                  {t(tKeys.calendar.agenda.item.seeResults)}
                </Text>
              </Box>
            </HStack>)}
        </VStack>)}
    </VStack>);
};
