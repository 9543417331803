import { t } from 'i18next';
import moment from 'moment';
import { useMemo } from 'react';
import COLORS from '../../../constants/colors';
import { useCompaniesContext } from '../../../contexts/companies/index.web';
import { PresenceStatus } from '../../../screens/home/calendar/event-details/types';
import { TaskStatus } from '../../../screens/home/tasks/types';
import { useReadEvent } from '../../../services/online/calendar/calendar';
import { useReadTasks } from '../../../services/online/tasks/tasks';
import { TaskGroupBy, TaskType } from '../../../services/types';
import { tKeys } from '../../../translations/keys';
export const useEventCardLogic = (event) => {
    // Attributes
    const isEventPast = moment(moment(event?.startDate)).isBefore(new Date());
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { me } = useCompaniesContext();
    const { data: currentEvent } = useReadEvent(event?.id, {
        query: { enabled: !!event?.id && !isEventPast },
    });
    const isCurrentUserInvited = useMemo(() => {
        return currentEvent?.invitees?.some(invitee => invitee?.personId === me?.id);
    }, [currentEvent, me]);
    const { data: eventTasks, isLoading: isTasksLoading } = useReadTasks({
        'company-id': selectedCompanyId,
        'person-id': me.id,
        status: TaskStatus.Open,
        types: Object.values(TaskType),
        // 1 is for grouping by event
        'group-by': TaskGroupBy?.[1],
        'event-id': event?.id,
    }, {
        query: {
            enabled: !!event?.id && !isEventPast,
        },
    });
    const tasksToDoLength = eventTasks
        ?.flatMap(t => t?.items)
        ?.filter(i => i?.eventId === event?.id).length;
    const currentUserInvitee = currentEvent?.invitees?.find(invitee => invitee?.personId === me?.id);
    // Functions
    function getPresenceStatusFromInvitee() {
        if (currentUserInvitee?.isRemote) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.remote),
                iconName: 'remote',
                bgColor: COLORS.Success.T100.value,
                colors: COLORS.Success.T500.value,
                status: PresenceStatus.Remote,
            };
        }
        if (currentUserInvitee?.proxyId) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.proxy),
                iconName: 'proxy',
                bgColor: COLORS.Warning.T100.value,
                colors: COLORS.Warning.T500.value,
                status: PresenceStatus.Proxy,
            };
        }
        if (currentUserInvitee?.acceptedOn) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.present),
                iconName: 'check',
                bgColor: COLORS.Success.T100.value,
                colors: COLORS.Success.T500.value,
                status: PresenceStatus.Present,
            };
        }
        if (currentUserInvitee?.refusedOn && !currentUserInvitee.proxyId) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.absent),
                iconName: 'close-cross',
                bgColor: COLORS.Error.T100.value,
                colors: COLORS.Error.T500.value,
                status: PresenceStatus.Absent,
            };
        }
    }
    // Return
    return {
        isEventPast,
        getPresenceStatusFromInvitee,
        isTasksLoading,
        tasksToDoLength,
        isCurrentUserInvited,
    };
};
