import React, { useRef } from 'react';
import { VStack, HStack, Stack, useOutsideClick } from '@chakra-ui/react';
import Text from '../../../../components/content/text';
import FONTS from '../../../../constants/fonts';
import { GroupAvatars } from '../../../../components/content/group-avatars/index.web';
import AvatarDetails from '../../../../components/content/avatar-details';
import Icon from '../../../../components/content/icon';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../translations/keys';
import COLORS from '../../../../constants/colors';
import { SelectPeople } from '../../../select-people/index.web';
export const DocumentDetailsListPermissions = ({ groups, isDocumentPermissions, persons, onGroupRemoveFromDocument, onPersonRemoveFromDocument, title, groupsNotInvited, isSelectPeopleLoading, isSelectPeopleOpen, onGroupAddToDocument, onPersonAddToDocument, onSelectPeopleToggle, peopleNotInvited, onSearchChange, }) => {
    // Attributes
    const { t } = useTranslation();
    const selectPeopleRef = useRef(null);
    useOutsideClick({
        ref: selectPeopleRef,
        handler: () => setTimeout(() => {
            isSelectPeopleOpen && onSelectPeopleToggle?.();
        }, 100),
    });
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'} spacing={'.75rem'} borderRadius={'.5rem'} bgColor={COLORS.Disabled.value} borderWidth={1} borderColor={COLORS.Line.value} p={'.75rem'}>
      <HStack width={'100%'} spacing={'5rem'} justifyContent={'space-between'}>
        <Stack minWidth={'fit-content'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>{title}</Text>
        </Stack>

        {/* Search */}
        {isDocumentPermissions && (<SelectPeople isLoading={isSelectPeopleLoading} isPopoverOpen={isSelectPeopleOpen} onPopoverToggle={onSelectPeopleToggle} onSelectGroup={onGroupAddToDocument} onSelectPeople={onPersonAddToDocument} setSearch={onSearchChange} groups={groupsNotInvited} peoples={peopleNotInvited} ref={selectPeopleRef} isPortal={false} searchColor={COLORS.White.T500.value} paddingX={0} placeholder={t(tKeys.document.permissions.search)}/>)}
      </HStack>

      {/* Groups list */}
      {groups.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'.75rem'} bgColor={COLORS.White.T500.value} borderRadius={'.25rem'} py={'.75rem'} px={'1.25rem'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.common.groups_number, {
                value: groups.length,
            })}
          </Text>
          <VStack alignItems={'flex-start'} width={'full'}>
            {groups.map(group => (<HStack key={group.id} width={'100%'} justifyContent={'space-between'} py={'.25rem'}>
                <GroupAvatars group={group} showNumberOfMembers/>
                {/* Remove group */}
                {isDocumentPermissions && (<Stack cursor={'pointer'} onClick={() => onGroupRemoveFromDocument?.(group.id)} borderRadius={'1.5rem'} borderWidth={1} p={'.25rem'}>
                    <Icon name={'close-cross'} height={20} width={20}/>
                  </Stack>)}
              </HStack>))}
          </VStack>
        </VStack>)}
      {/* Members list */}
      {persons.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'.75rem'} bgColor={COLORS.White.T500.value} borderRadius={'.25rem'} py={'.75rem'} px={'1.25rem'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.common.members_number, {
                value: persons.length,
            })}
          </Text>
          <VStack alignItems={'flex-start'} width={'full'} spacing={0}>
            {persons.map(person => (<HStack key={person.id} justify="space-between" width={'full'} py={'.25rem'} position={'relative'}>
                {/* Avatar and user name */}
                <AvatarDetails person={person} avatarSize={'2.25rem'} avatarFont={FONTS.T1.T14Px.Medium500.value} titleFont={FONTS.T1.T14Px.Medium500.value} showBoardRole/>

                {/* Remove person */}
                {isDocumentPermissions && (<Stack cursor={'pointer'} onClick={() => onPersonRemoveFromDocument?.(person.id)} borderRadius={'1.5rem'} borderWidth={1} p={'.25rem'}>
                    <Icon name={'close-cross'} height={20} width={20}/>
                  </Stack>)}
              </HStack>))}
          </VStack>
        </VStack>)}
    </VStack>);
};
