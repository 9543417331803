import { Platform } from 'react-native';
import RNPermissions, { check, PERMISSIONS, } from 'react-native-permissions';
const handleCameraPermission = async () => {
    let status;
    // Check permission by platform
    if (Platform.OS === 'android') {
        status = await check(PERMISSIONS.ANDROID.CAMERA);
    }
    else {
        status = await check(PERMISSIONS.IOS.CAMERA);
    }
    // Check if granted or limited
    if (status === 'granted' || status === 'limited') {
        return true;
    }
    // If permission is not granded so re-ask to the user
    let permission;
    if (Platform.OS === 'android') {
        permission = await RNPermissions.request(PERMISSIONS.ANDROID.CAMERA);
    }
    else {
        permission = await RNPermissions.request(PERMISSIONS.IOS.CAMERA);
    }
    if (permission === 'granted') {
        return true;
    }
    return false;
};
export default handleCameraPermission;
