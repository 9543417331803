import { useReadNotificationsSettings, useUpdateNotificationSetting, } from '../../../../../services/online/enrolments/enrolments';
import { createForm } from '../../../../../utils/formik';
import { useFormik } from 'formik';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = ({ enrolment }) => {
    // Attributes
    const { isOpen: isOpenNotificationModal, onOpen: onOpenNotificationModal, onClose: onCloseNotificationModal, } = useDisclosure();
    // Queries
    const { data: notificationData } = useReadNotificationsSettings(enrolment?.id, {
        query: {
            enabled: !!enrolment?.id,
        },
    });
    const { mutateAsync: updateNotificationSetting } = useUpdateNotificationSetting();
    // Functions
    const handleOnPress = () => {
        onOpenNotificationModal();
    };
    const handleSwitchOnChange = async (value) => {
        try {
            form?.switch?.onChange(value);
            await updateNotificationSetting({
                data: {
                    enrolmentId: enrolment?.id,
                    id: notificationData?.[0]?.id,
                    value,
                },
            });
        }
        catch (err) {
            console.error('Error when updating the notification : ', err);
        }
    };
    const handleChangeNotificationfrequency = async (value) => {
        try {
            form?.notificationFrequency?.onChange(value);
            await updateNotificationSetting({
                data: {
                    enrolmentId: enrolment?.id,
                    id: notificationData?.[1]?.id,
                    value,
                },
            });
        }
        catch (err) {
            console.error('Error when updating the notification settings : ', err);
        }
    };
    // Form
    const { values, dirty, initialValues, ...rest } = useFormik({
        initialValues: {
            switch: notificationData?.[0]?.value,
            notificationFrequency: notificationData?.[1]?.value,
        },
        onSubmit: () => undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    return {
        handleOnPress,
        handleSwitchOnChange,
        handleChangeNotificationfrequency,
        form,
        isOpenNotificationModal,
        onCloseNotificationModal,
    };
};
export default useLogic;
