import * as Yup from 'yup';
export const validationSchema = (t) => {
    return Yup.object({
        file: Yup.object().nullable(true),
        message: Yup.string().when('file', {
            is: undefined,
            then: Yup.string().required('').min(1),
            otherwise: Yup.string().notRequired(),
        }),
        deletedMessageIds: Yup.array(),
        fileType: Yup.number().nullable(true),
        isDeleteMode: Yup.boolean(),
        isEphemeral: Yup.boolean(),
    });
};
