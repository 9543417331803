import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: COLORS.Tag.value,
        borderRadius: 8,
        paddingHorizontal: 8,
        marginBottom: 12,
        paddingVertical: 8,
        marginHorizontal: 12,
    },
    deleteBtnContainer: {
        backgroundColor: COLORS.Error.T500.value,
        borderRadius: 8,
        width: 90,
        marginRight: 8,
        marginBottom: 12,
        flex: 1,
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    documentCountContainer: {
        textDecorationLine: 'underline',
    },
});
export default styles;
