import React, { useRef } from 'react';
import { Button, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Portal, useOutsideClick, } from '@chakra-ui/react';
import COLORS from '../../../constants/colors';
import SHADOWS from '../../../constants/shadows';
import { Pressable } from 'react-native';
export default function PopOver({ from, children, header, placement, isPortal = true, isOpen, onToggle, backgroundColorHover, isPressable, }) {
    // Attributes
    const popoverRef = useRef(null);
    useOutsideClick({
        ref: popoverRef,
        handler: () => setTimeout(() => isOpen && onToggle(), 100), // Put a timeout resolve the close of the popover when click on the button
    });
    // Render
    return (<Popover isOpen={isOpen} placement={placement} onClose={onToggle} closeOnBlur>
      <PopoverTrigger>
        {!isPressable ? (<Button variant={'ghost'} _hover={{ backgroundColor: backgroundColorHover ?? 'transparent' }} onClick={onToggle} minW={0} px={0}>
            {from}
          </Button>) : (<Pressable onPress={onToggle} 
        // set style and hover
        style={{
                width: '100%',
            }}>
            {from}
          </Pressable>)}
      </PopoverTrigger>
      {isPortal ? (<Portal>
          <PopoverContent width={'fit-content'} borderColor={COLORS.Line.value} boxShadow={SHADOWS.Sidebar.value} overflow={'hidden'} ref={popoverRef}>
            {header && <PopoverHeader>{header}</PopoverHeader>}
            <PopoverBody padding={0}>{children}</PopoverBody>
          </PopoverContent>
        </Portal>) : (<PopoverContent width={'fit-content'} borderColor={COLORS.Line.value} boxShadow={SHADOWS.Sidebar.value} ref={popoverRef}>
          {header && <PopoverHeader>{header}</PopoverHeader>}
          <PopoverBody padding={0}>{children}</PopoverBody>
        </PopoverContent>)}
    </Popover>);
}
