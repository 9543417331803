import * as Yup from 'yup';
import { tKeys } from '../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        newPassword: Yup.string()
            .min(6, t(tKeys.validation.forgotPasswordCreatePassword.newPassword.length))
            .required(t(tKeys.validation.forgotPasswordCreatePassword.newPassword.required)),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword')], t(tKeys.validation.forgotPasswordCreatePassword.confirmNewPassword
            .not_equal))
            .required(t(tKeys.validation.forgotPasswordCreatePassword.confirmNewPassword
            .required)),
    });
};
