import React, { useEffect, useState } from 'react';
import Page from '../../../components/surfaces/page';
import COLORS from '../../../constants/colors';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Search from '../../search/index.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EnumTokenService } from '../../auth/type';
import { useCompaniesContext } from '../../../contexts/companies/index.web';
export const AIAssist = () => {
    // Attributes
    const { t } = useTranslation();
    const [bearerToken, setBearerToken] = useState(null);
    const { selectedCompanyId } = useCompaniesContext();
    // Effect to get the access token from storage
    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const tokenToUse = await AsyncStorage.getItem(EnumTokenService.ACCESS_TOKEN);
                setBearerToken(tokenToUse);
            }
            catch (error) {
                console.error('Failed to fetch access token', error);
            }
        };
        void getAccessToken();
    }, []);
    // Render
    return (<Page showSideBar backgroundColor={COLORS.BG.value}>
      <NavigationHeader justifyTitle="flex-start" endEnhancer={<Search />}>
        {t(tKeys.home.navigation_bar.ai_assist)}
      </NavigationHeader>
      {bearerToken && (<iframe src={`${process.env.AI_ASSIST_URL}?bearer_token=${bearerToken}&company_id=${selectedCompanyId}`} width={'100%'} height={'100%'} title="AI Assist"/>)}
    </Page>);
};
