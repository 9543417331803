import COLORS from '../../../../../constants/colors';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Kind = {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    TERTIARY: 'TERTIARY',
    WHITE: 'WHITE',
    GRAY: 'GRAY',
    TRANSPARENT: 'TRANSPARENT',
    OUTLINE: 'OUTLINE',
};
const KINDS = {
    [Kind.PRIMARY]: {
        backgroundColor: COLORS.Govrn.Blue.T500.value,
        textColor: COLORS.White.T500.value,
        disabledBackgroundColor: COLORS.Tag.value,
        disabledTextColor: COLORS.InputText.value,
    },
    [Kind.SECONDARY]: {
        backgroundColor: COLORS.Govrn.Blue.T900.value,
        textColor: COLORS.White.T500.value,
        disabledBackgroundColor: COLORS.Tag.value,
        disabledTextColor: COLORS.InputText.value,
    },
    [Kind.TERTIARY]: {
        backgroundColor: COLORS.Govrn.Blue.T600.value,
        textColor: COLORS.White.T500.value,
        disabledBackgroundColor: COLORS.Tag.value,
        disabledTextColor: COLORS.InputText.value,
    },
    [Kind.WHITE]: {
        backgroundColor: COLORS.White.T500.value,
        textColor: COLORS.Govrn.Blue.T500.value,
        disabledBackgroundColor: COLORS.White.T500.value,
        disabledTextColor: COLORS.InputText.value,
    },
    [Kind.GRAY]: {
        backgroundColor: COLORS.Line.value,
        textColor: COLORS.White.T500.value,
        disabledBackgroundColor: COLORS.Disabled.value,
        disabledTextColor: COLORS.Govrn.Blue.T500.value,
    },
    [Kind.TRANSPARENT]: {
        backgroundColor: 'transparent',
        textColor: COLORS.White.T500.value,
        disabledBackgroundColor: COLORS.Tag.value,
        disabledTextColor: COLORS.InputText.value,
    },
    [Kind.OUTLINE]: {
        backgroundColor: 'transparent',
        textColor: COLORS.Text.T500.value,
        disabledBackgroundColor: COLORS.Tag.value,
        disabledTextColor: COLORS.InputText.value,
    },
};
export default KINDS;
