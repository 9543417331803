const mock = {
    authorizeEventStore: () => Promise.resolve('authorized'),
    fetchAllEvents: () => Promise.resolve([]),
    findCalendars: () => Promise.resolve([]),
    findEventById: () => Promise.resolve(null),
    saveCalendar: () => Promise.resolve('1'),
    saveEvent: () => Promise.resolve('1'),
    removeCalendar: () => Promise.resolve(true),
    removeEvent: () => Promise.resolve(true),
};
export default mock;
