import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Icon from '../../content/icon';
import Image from '../../content/image';
import Text from '../../content/text';
import THEMES, { Theme } from './constants/theme';
import { PostKind } from './props';
import { HStack, Stack, VStack } from '@chakra-ui/react';
const ShareInPrivateCard = ({ isNewChat, title, titleEnhancer, iconName, kind, width = 264, }) => {
    // Attributes
    const { iconColor, leftColor, rightColor } = THEMES[isNewChat ? Theme.LIGHT : Theme.DARK];
    const getIconName = () => {
        switch (kind) {
            case PostKind.EVENT:
                return 'navigation-bar-calendar-default';
            case PostKind.FILE:
                return 'files';
            case PostKind.LINK:
                return 'picture';
            case PostKind.POLL:
                return 'bar-chart';
            case PostKind.POST:
                return 'navigation-bar-messages-default';
            default:
                return 'navigation-bar-messages-default';
        }
    };
    const renderTitleEnhancer = () => {
        if (!titleEnhancer)
            return null;
        if (typeof titleEnhancer === 'string')
            return (<Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
          {titleEnhancer}
        </Text>);
        return titleEnhancer;
    };
    // Render
    return (<HStack width={width} overflow={'hidden'} height={'4.375rem'} borderRadius={'0.75rem'} spacing={0}>
      <Stack bgColor={leftColor} height={'4rem'} p={'1.25rem'}>
        {kind === PostKind.INVITATION ? (<Image imageURI={require('../../../assets/images/invitation.png')} width={'2.5rem'} height={'2.5rem'} isLocalImage={true} resizeMode={'contain'}/>) : (<Icon name={iconName ?? getIconName()} width={24} height={24} color={iconColor} fill={iconColor}/>)}
      </Stack>
      <VStack align="flex-start" backgroundColor={rightColor} spacing={'.25rem'} pl={'1rem'} height={'4rem'} width={'full'} justifyContent={'center'}>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} numberOfLines={2}>
          {title}
        </Text>
        {renderTitleEnhancer()}
      </VStack>
    </HStack>);
};
export default ShareInPrivateCard;
