/* eslint-disable react/prop-types */
import { t } from 'i18next';
import React from 'react';
import { FlatList } from 'react-native';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Container from '../../../../../../components/surfaces/container';
import LineSeparator from '../../../../../../components/utility/line-separator';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import Placeholder from '../../../../../../components/utility/placeholder';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import EventDetailsTaskCard from '../event-details-task-card/index.web';
import useLogic from './logic.web';
import { Box, HStack, Stack } from '@chakra-ui/react';
import CreateTask from '../../../../tasks/create-task/index.web';
import { Size } from '../../../../../../components/inputs/button/constants/size';
const EventDetailsTasks = ({ event, onClose, isAdmin, }) => {
    // Attributes
    const { tasks, isTasksLoading, handleAddNewTask, isOpenCreateTask, onCloseCreateTask, } = useLogic(event);
    // Functions
    const ListHeaderComponent = () => {
        if (!tasks?.length) {
            return <></>;
        }
        return (<HStack justify="space-between" width={'full'} px={'1rem'} py={'1.25rem'}>
        <Text font={FONTS.T1.T16Px.SemiBold600.value}>
          {`${t(tKeys.event.detail.title)} (${tasks?.length})`}
        </Text>
        {isAdmin && (<Button onPress={handleAddNewTask} kind={Kind.OUTLINE} size={Size.DEFAULT}>
            {t(tKeys.common.add)}
          </Button>)}
      </HStack>);
    };
    function ListEmptyComponent() {
        if (isTasksLoading) {
            return <></>;
        }
        return (<Placeholder imageType="monocle-emoji" hasImage={false} title={t(tKeys.calendar.tasks.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.calendar.tasks.placeholder.subTitle)
                : t(tKeys.calendar.tasks.placeholder.subTitle_invitees)} endEnhancer={isAdmin && (<Box marginTop={'1.25rem'}>
              <Button kind={Kind.SECONDARY} onPress={handleAddNewTask} startEnhancer={() => (<Container marginLeft={8}>
                    <Icon name="add" width={20} height={20} fill={COLORS.White.T500.value}/>
                  </Container>)}>
                <Stack pl={'.5rem'}>
                  <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                    {t(tKeys.calendar.tasks.add_a_task)}
                  </Text>
                </Stack>
              </Button>
            </Box>)}/>);
    }
    function renderDocumentsItems({ item, index, }) {
        return (<Stack width={'full'} px={'1rem'}>
        <EventDetailsTaskCard event={event} index={index} task={item} onClose={onClose}/>
      </Stack>);
    }
    // Render
    return (<Box width={'full'} pb={'2rem'}>
      <LoadingWrapper isLoading={isTasksLoading}>
        <FlatList data={tasks ?? []} renderItem={renderDocumentsItems} ListHeaderComponent={ListHeaderComponent} ListEmptyComponent={() => <ListEmptyComponent />} ItemSeparatorComponent={() => (<Container marginVertical={16}>
              <LineSeparator width={1} color={COLORS.Line.value} type="bottom"/>
            </Container>)}/>
      </LoadingWrapper>
      {/* Modals */}
      <CreateTask isOpen={isOpenCreateTask} onClose={onCloseCreateTask} 
    // eslint-disable-next-line react/prop-types
    eventId={event?.id} 
    // eslint-disable-next-line react/prop-types
    inviteesIds={event?.inviteeIds}/>
    </Box>);
};
export default EventDetailsTasks;
