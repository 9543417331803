import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    flatlist: {
        paddingHorizontal: 12,
        paddingTop: 20,
        flex: 1,
        flexGrow: 1,
    },
    contentContainer: {
        paddingHorizontal: 12,
        paddingTop: 20,
    },
    separator: { height: 20 },
    listHeader: { paddingBottom: 16 },
    contentContainerStyle: {
        paddingBottom: 50,
    },
});
export default styles;
