import { Config as PDFTronConfig, DocumentView, Config, } from 'react-native-pdftron';
import React from 'react';
import Toast from 'react-native-toast-message';
import useLogic from './logic';
import useIsTablet from '../../../hooks/is-tablet';
import Container from '../../../components/surfaces/container';
import ToastConfiguration from '../../../components/progression-and-validation/toast/toast-configuration';
const DocumentViewer = () => {
    // Attributes
    const isTablet = useIsTablet();
    // Logic
    const { documentData, isDocumentLoading, viewerRef, goBack, documentType, updateDocumentAnnotations, onDocumentLoaded, } = useLogic();
    // Render if no document
    if (!documentData || isDocumentLoading) {
        return <></>;
    }
    // Render
    return (<Container width={'100%'} height={'100%'}>
      <DocumentView ref={viewerRef} document={`file://${documentData?.filePath}`} onLeadingNavButtonPressed={goBack} documentExtension={documentType} disabledElements={[
            PDFTronConfig.Buttons.moreItemsButton,
            PDFTronConfig.Buttons.shareButton,
            PDFTronConfig.Buttons.cropPageButton,
        ]} annotationToolbars={[
            Config.DefaultToolbars.View,
            Config.DefaultToolbars.Annotate,
        ]} onExportAnnotationCommand={updateDocumentAnnotations} onAnnotationChanged={updateDocumentAnnotations} followSystemDarkMode={true} hideScrollbars={true} bottomToolbarEnabled={false} showLeadingNavButton={true} tabletLayoutEnabled={isTablet} onDocumentLoaded={onDocumentLoaded} inkMultiStrokeEnabled={false}/>
      <Toast position="top" config={ToastConfiguration} topOffset={60}/>
    </Container>);
};
export default DocumentViewer;
