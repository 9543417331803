import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../components/content/text';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import { Checkbox, HStack, VStack } from '@chakra-ui/react';
import { useOtherSettingsLogic } from './logic.web';
const OtherSettings = () => {
    // Attributes
    const { t } = useTranslation();
    const { form, handleSubmit, isUpdateCompanyLoading, values, handleOnChangeIsFileDownloadAllowed, } = useOtherSettingsLogic();
    // Render
    return (<HStack width={'full'} justifyContent={'space-between'}>
      <VStack alignItems={'flex-start'} height={'full'} width={'30%'} spacing={'1.25rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.settings['other-settings']['navigation-header'])}
        </Text>
        <Button size={Size.SMALL} kind={Kind.SECONDARY} isLoading={isUpdateCompanyLoading} onPress={handleSubmit}>
          {t(tKeys.common.save)}
        </Button>
      </VStack>
      <VStack alignItems={'flex-start'} height={'full'} width={'70%'} borderRadius={'.5rem'} overflow={'auto'} bgColor={COLORS.White.T500.value} borderWidth={1} p={'1.25rem'} spacing={'1.25rem'}>
        <Checkbox spacing={'0.75rem'} isChecked={values?.isFileDownloadAllowed} onChange={handleOnChangeIsFileDownloadAllowed}>
          {t(tKeys.settings['other-settings'].isFileDownloadAllowed)}
        </Checkbox>
        <Checkbox spacing={'0.75rem'} isDisabled={!values?.isFileDownloadAllowed} isChecked={values?.isOriginalFileDownloadAllowed} onChange={e => form?.isOriginalFileDownloadAllowed?.onChange(!values?.isOriginalFileDownloadAllowed)}>
          {t(tKeys.settings['other-settings'].isOriginalFileDownloadAllowed)}
        </Checkbox>
        <Checkbox spacing={'0.75rem'} isDisabled={!values?.isFileDownloadAllowed} isChecked={values?.isWatermarkingEnabled} onChange={e => form?.isWatermarkingEnabled?.onChange(!values?.isWatermarkingEnabled)}>
          {t(tKeys.settings['other-settings'].isWatermarkingEnabled)}
        </Checkbox>
      </VStack>
    </HStack>);
};
export default OtherSettings;
