import React from 'react';
import { View } from 'react-native';
import Icon from '../../content/icon';
import Container from '../../surfaces/container';
import Input from '../input/index.web';
import styles from './style.web';
import { Box } from '@chakra-ui/react';
function SearchInput({ onChange, value, isClearable, placeholder, onPressClear, style, isDisabled, testID, autofocus, iconSize = 16, textFont, }) {
    // Render
    return (<View style={{
            ...styles.mainContainer,
            ...style,
        }}>
      {/* Search icon */}
      <Container style={styles.searchIconStyle}>
        <Icon name="search" width={iconSize} height={iconSize}/>
      </Container>

      {/* Text */}
      <Input name={'searchInput'} value={value} onChange={e => onChange(e?.currentTarget?.value)} placeholder={placeholder} style={{
            outline: 'none',
            ...styles.input,
        }} isDisabled={isDisabled} autoFocus={autofocus} _focusVisible={{ borderWidth: 0 }}/>

      {/* Reset button */}
      {isClearable && (<Box onClick={onPressClear} style={styles.searchReset} paddingRight={'1rem'}>
          <Icon name="search-reset" width={20} height={20}/>
        </Box>)}
    </View>);
}
export default SearchInput;
