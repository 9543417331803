import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
const PageParagraphText = ({ text, color = COLORS.Text.T500.value, font = FONTS.T1.T14Px.Regular400.value, }) => {
    return (<Text color={color} font={font}>
      {text}
    </Text>);
};
export default PageParagraphText;
