import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '15%',
        flexGrow: 1,
        backgroundColor: COLORS.White.T500.value,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingHorizontal: 12,
        width: '100%',
    },
    applicationLogo: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 24,
    },
    phoneInputContainer: {
        marginTop: 32,
    },
    informativeText: {
        color: COLORS.InputText.value,
        marginVertical: 12,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pageParagrapheTextContainer: {
        marginTop: 24,
    },
    pageParagrapheText: {
        paddingVertical: 8,
    },
});
export default styles;
