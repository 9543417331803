import * as Yup from 'yup';
import { tKeys } from '../../../../translations/keys';
import { regexUrl } from '../../../../utils/regexUrl';
export const validationSchema = (t) => {
    return Yup.object({
        title: Yup.string()
            .min(2, t(tKeys.create_event.title.length.error))
            .max(100, t(tKeys.create_event.title.length.error))
            .required(t(tKeys.validation.title)),
        endDate: Yup.date()
            .typeError(t(tKeys.validation.end_date_and_hour))
            .required(t(tKeys.validation.end_date_and_hour))
            .min(Yup.ref('startDate'), t(tKeys.validation.end_date_before_start_date.Error)),
        startDate: Yup.date()
            .typeError(t(tKeys.validation.start_date_and_hour))
            .required(t(tKeys.validation.start_date_and_hour)),
        eventType: Yup.object()
            .typeError(t(tKeys.validation.create_event.event_type))
            .required(t(tKeys.validation.create_event.event_type)),
        inviteesIds: Yup.array().notRequired(),
        timezoneId: Yup.string().required(t(tKeys.validation.create_event.timezone)),
    }).shape({
        location: Yup.string().when(['url'], {
            is: url => !url?.length,
            then: Yup.string().required(t(tKeys.validation.create_event.location)),
            otherwise: Yup.string().optional().nullable(),
        }),
        url: Yup.string()
            .matches(regexUrl, t(tKeys.validation.url))
            .when(['location'], {
            is: location => !location?.length,
            then: Yup.string().required(t(tKeys.validation.create_event.url)),
            otherwise: Yup.string().optional().nullable(),
        }),
        // It works but I've no idea why TS isn't happy with this
    }, ['url', 'location']);
};
