import React from 'react';
import { tKeys } from '../../translations/keys';
import { t } from 'i18next';
import Modal from '../../components/surfaces/modal/index.web';
const WebView = ({ isOpen, onClose, uri }) => {
    // Render
    return (<Modal size={'2xl'} title={t(tKeys.sign.go_to_document)} hasCloseButton hasFooter={false} isOpen={isOpen} onClose={onClose}>
      <iframe src={uri} style={{ height: '85vh' }}/>
    </Modal>);
};
export default WebView;
