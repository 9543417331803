import React from 'react';
import { KeyboardAvoidingView, Platform, SafeAreaView } from 'react-native';
import COLORS from '../../../../constants/colors';
import LineSeparator from '../../../utility/line-separator';
import Container from '../../container';
import Flex from '../../flex';
import styles from './style';
const KeyboardAvoidingViewWrapper = ({ children, FloatingActionButton, bottomContainerStyle, backgroundColor = COLORS.White.T500.value, style, keyboardVerticalOffset = 24, divider, }) => {
    return (<KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={[{ flex: 1, backgroundColor }, style]} keyboardVerticalOffset={FloatingActionButton ? keyboardVerticalOffset : undefined}>
      <Flex flex={1} flexGrow={1}>
        {children}
      </Flex>
      {FloatingActionButton && (<>
          {divider && (<Container marginBottom={12}>
              <LineSeparator color={COLORS.Line.value} width={1} type="bottom"/>
            </Container>)}
          <SafeAreaView>
            <Container style={[styles.bottomContainer, bottomContainerStyle]}>
              {FloatingActionButton}
            </Container>
          </SafeAreaView>
        </>)}
    </KeyboardAvoidingView>);
};
export default KeyboardAvoidingViewWrapper;
