import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        paddingHorizontal: 14,
        paddingVertical: 16,
        width: '100%',
        height: 48,
    },
    startEnhancer: {
        marginRight: 12,
        justifyContent: 'center',
    },
    endEnhancerStyle: {
        flex: 1,
        flexGrow: 1,
        flexDirection: 'row-reverse',
    },
    childrenContainer: {
        justifyContent: 'center',
        width: '90%',
    },
});
export default styles;
