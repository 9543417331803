/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a channel
 */
export const createChannel = (channel, options) => {
    return customInstance({
        url: `/chat/channels`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: channel,
    }, options);
};
export const useCreateChannel = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createChannel(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves all the channels where a specific person is a participant
 */
export const readChannels = (params, options, signal) => {
    return customInstance({ url: `/chat/channels`, method: 'get', params, signal }, options);
};
export const getReadChannelsQueryKey = (params) => [
    `/chat/channels`,
    ...(params ? [params] : []),
];
export const useReadChannels = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadChannelsQueryKey(params);
    const queryFn = ({ signal, }) => readChannels(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a channel
 */
export const updateChannel = (channel, options) => {
    return customInstance({
        url: `/chat/channels`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: channel,
    }, options);
};
export const useUpdateChannel = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateChannel(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a specific channel
 */
export const readChannel = (channelId, options, signal) => {
    return customInstance({ url: `/chat/channels/${channelId}`, method: 'get', signal }, options);
};
export const getReadChannelQueryKey = (channelId) => [
    `/chat/channels/${channelId}`,
];
export const useReadChannel = (channelId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadChannelQueryKey(channelId);
    const queryFn = ({ signal, }) => readChannel(channelId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!channelId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Deletes a channel
 */
export const deleteChannel = (channelId, options) => {
    return customInstance({ url: `/chat/channels/${channelId}`, method: 'delete' }, options);
};
export const useDeleteChannel = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { channelId } = props ?? {};
        return deleteChannel(channelId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Marks a channel as read by a specific person
 */
export const markChannelAsRead = (channelId, options) => {
    return customInstance({ url: `/chat/channels/${channelId}/read`, method: 'post' }, options);
};
export const useMarkChannelAsRead = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { channelId } = props ?? {};
        return markChannelAsRead(channelId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Creates a message
 */
export const createMessage = (message, options) => {
    return customInstance({
        url: `/chat/messages`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: message,
    }, options);
};
export const useCreateMessage = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createMessage(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of messages for a specific channel
 */
export const readMessages = (params, options, signal) => {
    return customInstance({ url: `/chat/messages`, method: 'get', params, signal }, options);
};
export const getReadMessagesQueryKey = (params) => [
    `/chat/messages`,
    ...(params ? [params] : []),
];
export const useReadMessages = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadMessagesQueryKey(params);
    const queryFn = ({ signal, }) => readMessages(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Delete one or more messages
 */
export const deleteMessage = (params, options) => {
    return customInstance({ url: `/chat/messages`, method: 'delete', params }, options);
};
export const useDeleteMessage = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { params } = props ?? {};
        return deleteMessage(params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Marks an ephemeral message as read by a specific person
 */
export const markEphemeralMessageAsRead = (messageId, options) => {
    return customInstance({ url: `/chat/messages/${messageId}/read-ephemeral`, method: 'post' }, options);
};
export const useMarkEphemeralMessageAsRead = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { messageId } = props ?? {};
        return markEphemeralMessageAsRead(messageId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
