import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        flex: 1,
    },
    placeholderContainer: {
        width: 100,
        height: 100,
        borderRadius: 26,
    },
    placeholderTextContainer: {
        marginTop: 32,
        justifyContent: 'center',
    },
    subTitleContainer: {
        marginTop: 8,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    text: {
        textAlign: 'center',
    },
});
export default styles;
