import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        paddingTop: 12,
    },
    footerContainer: {
        paddingBottom: 8.5,
        paddingLeft: 12,
        paddingRight: 12,
    },
    textContainer: {
        paddingLeft: 12,
        paddingRight: 8,
    },
});
export const borderRadiusCurrentUser = {
    first: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 2,
        borderBottomLeftRadius: 16,
    },
    last: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16,
    },
    single: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16,
    },
    betweenFirstAndLast: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        borderBottomLeftRadius: 16,
    },
};
export const borderRadiusOtherUser = {
    first: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 2,
    },
    last: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16,
    },
    single: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16,
    },
    betweenFirstAndLast: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 2,
    },
};
export default styles;
