import * as Yup from 'yup';
import { tKeys } from '../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        email: Yup.string()
            .email(t(tKeys.validation.signIn.email.format))
            .required(t(tKeys.validation.signIn.email.required)),
        password: Yup.string()
            .min(6, t(tKeys.validation.signIn.password.length))
            .required(t(tKeys.validation.signIn.password.required)),
    });
};
