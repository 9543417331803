import { useFormik } from 'formik';
import { useUpdatePost } from '../../../../services/online/news-feed/news-feed';
import { useReadMe } from '../../../../services/online/people/people';
import { createForm } from '../../../../utils/formik';
import newsFeedLogic from '../logic';
const useLogic = ({ itemPost, onClose }) => {
    // Attributes
    const { refetchNewsFeedData } = newsFeedLogic();
    // Queries
    // - Mutations
    const { mutateAsync: editPost, isLoading: isEditPostLoading } = useUpdatePost();
    // - Getters
    // Queries
    const { data: currentUserData } = useReadMe({
        query: {
            retry: 3,
        },
    });
    // Functions
    // - Handlers
    const handleOnSubmit = async () => {
        if (dirty) {
            await editPost({
                data: {
                    content: form?.text?.value?.replace(/\n+/g, '\n'),
                    id: itemPost?.id,
                },
            }, {
                onSuccess: () => {
                    void refetchNewsFeedData();
                    onClose();
                },
            });
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            text: itemPost?.content,
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    const disabledSaveButton = !dirty && !form?.text?.value && isEditPostLoading;
    return {
        form,
        dirty,
        isEditPostLoading,
        currentUserData,
        handleOnSubmit,
        disabledSaveButton,
    };
};
export default useLogic;
