import COLORS from '../../../../../constants/colors';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Theme = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
};
const THEMES = {
    [Theme.LIGHT]: {
        iconColor: COLORS.InputText.value,
        leftColor: COLORS.Line.value,
        rightColor: COLORS.Disabled.value,
    },
    [Theme.DARK]: {
        iconColor: COLORS.InputText.value,
        leftColor: '#DFE4F1',
        rightColor: COLORS.Chat.value,
    },
};
export default THEMES;
