import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { getReadResolutionsQueryKey, useCreateResolution, useDeleteResolution, useUpdateResolution, } from '../../../../../../services/online/calendar/calendar';
import { createForm } from '../../../../../../utils/formik';
const useLogic = (resolutionEntity, onClose, refetchResolutions) => {
    // Attributes
    const queryClient = useQueryClient();
    // Queries
    const { mutateAsync: createResolution, isLoading: isCreateResolutionLoading, } = useCreateResolution();
    const { mutateAsync: updateResolution, isLoading: isUpdateresolutionLoading, } = useUpdateResolution();
    const { mutateAsync: deleteResolution, isLoading: isDeleteResolutionLoading, } = useDeleteResolution();
    const { me } = useCompaniesContext();
    // Form
    const { values, resetForm, ...rest } = useFormik({
        initialValues: {
            title: resolutionEntity?.title ?? '',
            description: resolutionEntity?.description ?? '',
        },
        onSubmit: () => undefined,
        enableReinitialize: true,
    });
    const form = createForm(values, rest);
    const disableResolution = !values.title || !values.description;
    async function handleOnSubmit() {
        // That means it's an update from an already existing Resolution
        if (resolutionEntity.id) {
            try {
                await updateResolution({
                    data: {
                        ...resolutionEntity,
                        title: form?.title?.value,
                        description: form?.description?.value,
                    },
                }, {
                    onSuccess: () => {
                        void queryClient.invalidateQueries(getReadResolutionsQueryKey({
                            eventId: resolutionEntity?.eventId,
                        }));
                        onClose();
                        refetchResolutions();
                    },
                });
            }
            catch (err) {
                console.error('Error when updating an resolution : ', err);
            }
            return;
        }
        // That means that's a new Resolution
        try {
            await createResolution({
                data: {
                    eventId: resolutionEntity?.eventId,
                    creatorId: me?.id,
                    description: form?.description?.value,
                    title: form?.title?.value,
                },
            }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadResolutionsQueryKey({
                        eventId: resolutionEntity?.eventId,
                    }));
                    onClose();
                    resetForm();
                    refetchResolutions();
                },
            });
        }
        catch (err) {
            console.error('Error when create a resolution : ', err);
        }
    }
    async function handleOnDeleteResolution() {
        await deleteResolution({
            resolutionId: resolutionEntity?.id,
        }, {
            onSuccess: () => {
                refetchResolutions();
                onClose();
            },
        });
    }
    // return
    return {
        form,
        isChangeResolutionLoading: isCreateResolutionLoading || isUpdateresolutionLoading,
        handleOnSubmit,
        handleOnDeleteResolution,
        isDeleteResolutionLoading,
        disableResolution,
    };
};
export default useLogic;
