import React from 'react';
import { Stack } from '@chakra-ui/react';
import FONTS from '../../../../../../../../constants/fonts';
import Text from '../../../../../../../../components/content/text';
import COLORS from '../../../../../../../../constants/colors';
export const ShowMembersFilterRow = ({ onShowMembersFilterChange, currentShowMembersFilter, title, rowFilter, }) => {
    // Render
    return (<Stack _hover={{ bgColor: COLORS.Tag.value }} p={'.75rem'} cursor={'pointer'} width={'full'} onClick={() => onShowMembersFilterChange(rowFilter)} bgColor={currentShowMembersFilter === rowFilter ? COLORS.Tag.value : undefined}>
      <Text font={FONTS.T1.T14Px.Regular400.value}>{title}</Text>
    </Stack>);
};
