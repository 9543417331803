import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Flex from '../../surfaces/flex';
import styles from './style.web';
import Icon from '../../content/icon';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
const NavigationHeader = ({ children, endEnhancer, style, backgroundColor = COLORS.White.T500.value, divider = true, onGoBack, startEnhancer, }) => {
    // Functions
    const renderChildren = () => {
        if (typeof children === 'string') {
            const font = FONTS.T1.T16Px.Bold700.value;
            return (<Trans>
          <Text fontFamily={font.fontFamily} fontSize={font.fontSize} fontWeight={font.fontWeight} color={COLORS.Text.T400.value} noOfLines={1}>
            {children}
          </Text>
        </Trans>);
        }
        return children;
    };
    // Render
    return (<Flex style={[
            styles.mainContainer,
            { backgroundColor, borderLeftWidth: 0 },
            divider ? styles.divider : undefined,
            style,
        ]}>
      <HStack justify="flex-start" spacing={'1rem'}>
        {onGoBack && (<Stack onClick={onGoBack} cursor={'pointer'}>
            <Icon name="chevron-left" height={24} width={24} fill={'transparent'}/>
          </Stack>)}
        {startEnhancer && <Stack>{startEnhancer}</Stack>}
        <>{renderChildren()}</>
      </HStack>
      <Flex justify="flex-end">{endEnhancer}</Flex>
    </Flex>);
};
export default NavigationHeader;
