import React from 'react';
import Text from '../../components/content/text';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { Stack, VStack } from '@chakra-ui/react';
import { AgendaItemType } from '../../services/types';
import { tKeys } from '../../translations/keys';
import { useTranslation } from 'react-i18next';
const SelectAgendaItemType = ({ agendaItemType, onChange, }) => {
    // Attributes
    const { t } = useTranslation();
    const agendaItemTypes = [
        {
            label: t(tKeys.common.informations),
            value: AgendaItemType[0],
        },
        {
            label: t(tKeys.common.decision),
            value: AgendaItemType[1],
        },
        {
            label: t(tKeys.common.discussion),
            value: AgendaItemType[2],
        },
    ];
    // Render
    return (<VStack minWidth={'17.5rem'} alignItems={'flex-start'} py={'.25rem'} spacing={0}>
      {agendaItemTypes.map(type => (<Stack _hover={{ backgroundColor: COLORS.BG.value }} backgroundColor={type.value === agendaItemType ? COLORS.BG.value : undefined} key={type.value} width={'full'} onClick={() => onChange(type.value)} py={'.5rem'} px={'1rem'} cursor={'pointer'}>
          <Text font={FONTS.T1.T14Px.Regular400.value}>{type.label}</Text>
        </Stack>))}
    </VStack>);
};
export default SelectAgendaItemType;
