import React from 'react';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Page from '../../../components/surfaces/page';
import COLORS from '../../../constants/colors';
import Text from '../../../components/content/text';
import FONTS from '../../../constants/fonts';
import { useTranslation } from 'react-i18next';
import Search from '../../search/index.web';
import { tKeys } from '../../../translations/keys';
import CardUserTasksAndNextEvent from '../feed/components/surfaces/card/card-user-events-tasks/index.web';
import { VStack } from '@chakra-ui/react';
import { useHomepageLogic } from './logic.web';
const Homepage = () => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { closestEvents, isEventsLoading } = useHomepageLogic();
    // Render
    return (<Page showSideBar barStyle="dark-content" backgroundColor={COLORS.BG.value}>
      <NavigationHeader justifyTitle="flex-start" endEnhancer={<Search />}>
        <Text font={FONTS.T1.T16Px.Bold700.value} color={COLORS.Text.T400.value}>
          {t(tKeys.homepage.title)}
        </Text>
      </NavigationHeader>
      <VStack width={'full'} alignItems={'flex-start'} px={'2rem'} pb={'2rem'} pt={'1.25rem'} overflow={'auto'}>
        <CardUserTasksAndNextEvent closestEvents={closestEvents} isLoadingEvent={isEventsLoading}/>
      </VStack>
    </Page>);
};
export default Homepage;
