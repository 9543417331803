/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../contexts/companies';
import { TaskTypeEnum } from '../../../enum/TaskType';
import useRouter from '../../../hooks/router/useRouter';
import { useReadTasks, useUpdateTask, } from '../../../services/online/tasks/tasks';
import { tKeys } from '../../../translations/keys';
import { createForm } from '../../../utils/formik';
import { DocumentKindEnum, } from '../../document-details/type';
import { TaskStatus } from './types';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = () => {
    // Form
    const { values, dirty, resetForm, ...rest } = useFormik({
        initialValues: {
            filesSwitch: true,
            rsvpSwitch: true,
            proxySwitch: true,
            votesSwitch: true,
            signatureSwitch: true,
            customSwitch: true,
        },
        onSubmit: undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Disclosure
    const { isOpen: isOpenTaskDetails, onOpen: onOpenTaskDetails, onClose: onCloseTaskDetails, } = useDisclosure();
    const { isOpen: isOpenVoteResult, onOpen: onOpenVoteResult, onClose: onCloseVoteResult, } = useDisclosure();
    // State
    const [selectedTask, setSelectedTask] = useState();
    const [voteResultParams, setVoteResultParams] = useState();
    // Query
    const { mutateAsync: updateTask } = useUpdateTask();
    // Attributes
    const { push } = useRouter();
    const { t } = useTranslation();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const selectedTasksTypes = Object.values(values)
        .map((value, index) => (value ? (index + 1) : undefined))
        .filter(value => value);
    const [filterIndicatorLength, _] = useState(selectedTasksTypes?.length);
    // States
    const [selectedTab, setSelectedTab] = useState(TaskStatus.Open);
    const [userTaskLength, setUserTasksLength] = useState();
    const optionsOrder = [
        {
            label: t(tKeys.filter.order.last_updated_first),
            value: 1,
        },
        {
            label: t(tKeys.filter.order.last_updated_last),
            value: 0,
        },
        {
            label: t(tKeys.filter.order.alphabetically.desc),
            value: 2,
        },
        {
            label: t(tKeys.filter.order.alphabetically.asc),
            value: 3,
        },
    ];
    const optionsGroupBy = [
        {
            label: t(tKeys.tasks.grouping.noGrouping),
            value: 0,
        },
        {
            label: t(tKeys.filter.order_by.task.by_event),
            value: 1,
        },
        {
            label: t(tKeys.filter.order_by.task.by_event_type),
            value: 2,
        },
        {
            label: t(tKeys.filter.order_by.task.by_due_date),
            value: 3,
        },
        {
            label: t(tKeys.filter.order_by.task.by_assignee),
            value: 4,
        },
        {
            label: t(tKeys.filter.order_by.task.by_creator),
            value: 5,
        },
    ];
    // Form
    const { values: sortingValues, ...other } = useFormik({
        initialValues: {
            orderBy: optionsOrder[0],
            groupBy: optionsGroupBy[3],
        },
        onSubmit: undefined,
        enableReinitialize: true,
    });
    const sortingForm = createForm(sortingValues, other);
    // Functions
    const handleOnPressTab = (activeKey) => {
        setSelectedTab(parseInt(activeKey.toString()) + 1);
    };
    const handleResetFilterVisibility = () => {
        resetForm();
    };
    // @params: agendaItem used only for task type voting
    const handleTaskPress = (task, question) => {
        if (task.type === TaskTypeEnum?.Custom) {
            setSelectedTask(task);
            onOpenTaskDetails();
            return;
        }
        if (task.type === TaskTypeEnum?.Vote) {
            const params = {
                question,
                eventId: task?.eventId,
                canUserVote: true,
            };
            setVoteResultParams(params);
            onOpenVoteResult();
            return;
        }
        if (task.type === TaskTypeEnum.RSVP) {
            const params = { eventId: task?.eventId };
            push('/company/event-details', {
                companyId: selectedCompanyId,
                ...params,
            });
            return;
        }
        // Set the task as completed when redirecting to document details
        void updateTask({
            data: { ...task, completedOn: new Date().toISOString() },
        });
        void refetchTasks();
        const documentParams = {
            documentKind: DocumentKindEnum.DOCUMENT,
            taskType: task.type,
            documentId: task.documentId,
            eventId: task.eventId,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...documentParams,
        });
    };
    const { data: userTasks, refetch: refetchTasks, isRefetching: isTasksRefetching, isLoading: isTasksLoading, } = useReadTasks({
        'company-id': selectedCompanyId,
        status: selectedTab,
        types: selectedTasksTypes ?? [],
        'group-by': sortingForm?.groupBy?.value?.value,
        'sort-by': sortingForm?.orderBy?.value?.value,
    }, {
        query: {
            onSuccess: res => {
                if (selectedTab === TaskStatus.Open) {
                    const totalTasks = res
                        ?.map(task => task?.items?.length)
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        .reduce((a, b) => a + b, 0);
                    setUserTasksLength(totalTasks);
                }
            },
            enabled: !!selectedCompanyId,
        },
    });
    // Return
    return {
        userTasks,
        isTasksRefetching,
        isTasksLoading,
        selectedTab,
        setSelectedTab,
        optionsGroupBy,
        optionsOrder,
        userTaskLength,
        handleOnPressTab,
        handleResetFilterVisibility,
        form,
        sortingForm,
        selectedTasksTypes,
        selectedEnrolment,
        handleTaskPress,
        filterIndicatorLength,
        onCloseTaskDetails,
        isOpenTaskDetails,
        selectedTask,
        isOpenVoteResult,
        onCloseVoteResult,
        voteResultParams,
    };
};
export default useLogic;
