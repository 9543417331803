import moment from 'moment';
import React from 'react';
import { PressableType } from '../../../../../../components/inputs/button/type';
import PressableWrapper from '../../../../../../components/inputs/button/wrapper';
import ShareInPrivateCard from '../../../../../../components/surfaces/share-in-private-card';
import { PostKind } from '../../../../../../components/surfaces/share-in-private-card/props';
import { dateFormatFrench } from '../../../../../../constants/date-format';
import useLogic from './logic.web';
import DocumentViewer from '../../../../../document-viewer/index.web';
import PostDetails from '../../../../../post-details/index.web';
const SharedContentInPrivate = ({ eventData, documentRepositoryData, isCurrentUserMessage, file, postId, }) => {
    // Attributes
    const { handleOnPressPost, handleOnPressDocument, handleOnPressEvent, handleOnFilePress, postData, documentPreview, isOpenDocumentPreview, onCloseDocumentPreview, isOpenPostDetails, onClosePostDetails, } = useLogic({
        eventData,
        documentRepositoryData,
        isCurrentUserMessage,
        postId,
    });
    if (eventData) {
        return (<>
        <PressableWrapper onPress={handleOnPressEvent} pressableType={PressableType.SCALE}>
          <ShareInPrivateCard titleEnhancer={moment(eventData?.startDate).format(dateFormatFrench)} isNewChat={false} title={eventData?.name} kind={PostKind?.EVENT}/>
        </PressableWrapper>
      </>);
    }
    if (documentRepositoryData) {
        return (<>
        <PressableWrapper onPress={handleOnPressDocument} pressableType={PressableType.SCALE}>
          <ShareInPrivateCard titleEnhancer={moment(documentRepositoryData?.createdOn).format(dateFormatFrench)} isNewChat={false} title={documentRepositoryData?.title} kind={PostKind?.FILE}/>
        </PressableWrapper>
      </>);
    }
    if (file) {
        return (<>
        <PressableWrapper onPress={() => handleOnFilePress(file)} pressableType={PressableType.SCALE}>
          <ShareInPrivateCard isNewChat={false} title={file.filename} kind={PostKind?.FILE}/>
        </PressableWrapper>
        <DocumentViewer selectedDocument={documentPreview} isOpen={isOpenDocumentPreview} onClose={onCloseDocumentPreview}/>
      </>);
    }
    if (postId) {
        // Attributes
        const postDataTyped = postData;
        const postKind = () => {
            if (postDataTyped?.question)
                return PostKind.POLL;
            if (postDataTyped?.document)
                return PostKind.FILE;
            if (postDataTyped?.invitation)
                return PostKind.INVITATION;
            return PostKind?.POST;
        };
        // Render
        return (<>
        <PressableWrapper onPress={() => handleOnPressPost(postId)} pressableType={PressableType.SCALE}>
          <ShareInPrivateCard isNewChat={false} title={postDataTyped?.content} kind={postKind()}/>
        </PressableWrapper>
        <PostDetails postId={postId} isOpen={isOpenPostDetails} onClose={onClosePostDetails}/>
      </>);
    }
    return <></>;
};
export default SharedContentInPrivate;
