import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    textContainer: {
        marginVertical: 8,
        marginHorizontal: 16,
        minWidth: 30,
    },
    calendarContainer: {
        marginTop: 8,
    },
    dateTitleContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
export default styles;
