import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    documentIcon: {
        height: 24,
        width: 24,
        backgroundColor: COLORS.PlaceholderBG.value,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    documentDeleteButton: {
        height: 20,
        width: 20,
        backgroundColor: COLORS.Black.T500.value,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    textContainer: {
        paddingHorizontal: 12,
    },
    documentNameContainer: {
        width: 150,
    },
    containerDocument: {
        maxWidth: 230,
    },
});
export default styles;
