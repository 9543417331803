import { stackNavigator } from '../../../../../routes/navigators';
import ManageBoardRoles from '.';
import UpsertBoardRole from './upsert-board-role';
const manageBoardRolesRoutes = [
    {
        name: '/manage-board-roles',
        deepLink: '/manage-board-roles',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: ManageBoardRoles,
            },
            {
                name: '/upsert-board-role',
                deepLink: '/upsert-board-role',
                component: UpsertBoardRole,
            },
        ],
    },
];
export default manageBoardRolesRoutes;
