import { t } from 'i18next';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../../contexts/companies/index.web';
import useRouter from '../../../../hooks/router/useRouter';
import { useRequestEmailChange } from '../../../../services/online/enrolments/enrolments';
import { useReadMe } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
const useLogic = () => {
    // Attributes
    const { goBack, push } = useRouter();
    const toast = useToast();
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { me } = useCompaniesContext();
    const { data: currentUserData } = useReadMe();
    const { mutateAsync: requestEmailChange } = useRequestEmailChange();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnRequestEmailChange = (enrolment, newEmail) => {
        try {
            if (!newEmail) {
                toast({
                    status: 'error',
                    title: t(tKeys?.email?.empty?.error),
                });
                return;
            }
            if (enrolment?.email !== newEmail) {
                void requestEmailChange({
                    enrolmentId: enrolment?.id,
                    params: {
                        email: newEmail,
                    },
                }, {
                    onSuccess: () => {
                        // redirect to the verify code
                        push('/company/profile/user-profile/email-address/verify-code-user-profile', {
                            companyId: selectedCompanyId,
                            enrolment,
                            newEmail,
                        });
                    },
                });
            }
        }
        catch (err) {
            console.error('Error with the request email change : ', err);
        }
    };
    // Return
    return {
        currentUserData,
        handleOnRequestEmailChange,
        handleOnPressBackButton,
    };
};
export default useLogic;
