import { useFormik } from 'formik';
import useRouter from '../../../../hooks/router/useRouter';
import { createForm } from '../../../../utils/formik';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = () => {
    // Attributes
    const { push, goBack, params } = useRouter();
    const signUpCreateNewAccountEntity = params?.signUpCreateNewAccountEntity ?? {};
    const { isOpen: isOpenLanguage, onToggle: onToggleLanguage } = useDisclosure();
    const { isOpen: isOpenTimezone, onToggle: onToggleTimezone } = useDisclosure();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    function handleOnChangeTimezone(value) {
        form?.timeZoneId?.onChange?.(value);
        onToggleTimezone();
    }
    function handleOnChangeLanguage(value) {
        form?.cultureName?.onChange?.(value);
        onToggleLanguage();
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handlerOnSubmit = () => {
        const signUpCreateNewAccountEntityTyped = signUpCreateNewAccountEntity;
        signUpCreateNewAccountEntityTyped.firstName = form?.firstname?.value;
        signUpCreateNewAccountEntityTyped.lastName = form?.lastname?.value;
        signUpCreateNewAccountEntityTyped.cultureName = form?.cultureName?.value;
        signUpCreateNewAccountEntityTyped.timezoneId = form?.timeZoneId?.value;
        push('/auth/create-password', {
            sourcePageName: '/sign-up-create-account',
            signUpCreateNewAccountEntity,
        });
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            firstname: signUpCreateNewAccountEntity?.firstName ?? '',
            lastname: signUpCreateNewAccountEntity?.lastName ?? '',
            cultureName: signUpCreateNewAccountEntity?.cultureName ?? 'fr-FR',
            timeZoneId: signUpCreateNewAccountEntity?.timezoneId ?? 'Europe/Brussels',
        },
        onSubmit: handlerOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        form,
        handlerOnSubmit,
        isOpenLanguage,
        onToggleLanguage,
        isOpenTimezone,
        onToggleTimezone,
        handleOnChangeTimezone,
        handleOnChangeLanguage,
    };
};
export default useLogic;
