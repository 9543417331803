import * as Yup from 'yup';
import { tKeys } from '../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        assigneePerson: Yup.object().required(t(tKeys.validation.create_task.assignee)),
        date: Yup.date()
            .typeError(t(tKeys.validation.due_date))
            .required(t(tKeys.validation.due_date)),
        description: Yup.string().required(),
        title: Yup.string().required(t(tKeys.validation.title)),
    });
};
