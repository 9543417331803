import React from 'react';
import NavigationHeader from '../../../components/navigation/navigation-header';
import Flex from '../../../components/surfaces/flex';
import Page from '../../../components/surfaces/page';
import COLORS from '../../../constants/colors';
import { tKeys } from '../../../translations/keys';
import DocumentList from '../../home/more/components/list/offline-document-list';
import useLogic from './logic';
import styles from './style';
import Toast from 'react-native-toast-message';
import ToastConfiguration from '../../../components/progression-and-validation/toast/toast-configuration';
import OfflineRedirectCard from '../../../components/surfaces/offline-redirect-card';
const Repository = () => {
    // Attributes
    const { t, documentsData, listLayoutType, onDocumentPress, onLayoutIconPress, connectionState, isDocumentDataLoading, } = useLogic();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader>{t(tKeys.common.offline_repository)}</NavigationHeader>

      {/* Main content */}
      <Flex style={styles.mainContainer}>
        <DocumentList documentsData={documentsData} isDocumentDataLoading={isDocumentDataLoading} listLayoutType={listLayoutType} onDocumentPress={onDocumentPress} onLayoutIconPress={onLayoutIconPress}/>
      </Flex>

      {connectionState && (<OfflineRedirectCard text1={t(tKeys.toast.online.redirect.text1)} text2={t(tKeys.toast.online.redirect.text2)}/>)}
      <Toast position="top" config={ToastConfiguration} topOffset={60}/>
    </Page>);
};
export default Repository;
