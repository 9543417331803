import React from 'react';
import Container from '../../../components/surfaces/container';
import styles from './style';
import FormControl from '../../../components/inputs/form-control';
import COLORS from '../../../constants/colors';
import PasswordInput from '../components/inputs/password-input/index.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import useLogic from './logic.web';
import { VStack, Text, Button, Stack } from '@chakra-ui/react';
import FONTS from '../../../constants/fonts';
import { PageWebAuth } from '../../../components/surfaces/page-auth/index.web';
import Input from '../../../components/inputs/input/index.web';
const SignInPage = () => {
    // Attributes
    const { t } = useTranslation();
    const { form, handleOnPressForgotPassword, handlerOnSubmit, isSubmitButtonDisabled, isAuthenticatePersonLoading, continueButtonRef, handleKeyPress, } = useLogic();
    const ContinueButton = (<VStack w="full" pt="3.5rem">
      <Button ref={continueButtonRef} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onClick={handlerOnSubmit} isDisabled={isSubmitButtonDisabled} isLoading={isAuthenticatePersonLoading} width="full" textColor={COLORS.White.T500.value} fontSize={14} bgColor={COLORS.Govrn.Blue.T500.value} _hover={{ bgColor: COLORS.Govrn.Blue.T600.value }}>
        {t(tKeys.common.continue)}
      </Button>
    </VStack>);
    // Render
    return (<PageWebAuth>
      <Stack width={'full'} px={'2.5rem'} pb={'2.5rem'} pt={'2rem'}>
        {/*  Page title */}
        <Text color={COLORS.Text.T400.value} fontWeight={600} fontSize={24} alignSelf="start" pb="3.5rem" pt="1.5rem">
          {t(tKeys?.['sign-in'].page.title)}
        </Text>

        {/* Identity input */}
        <Container style={{
            ...styles.phoneInputContainer,
            paddingBottom: '1rem',
        }}>
          <FormControl label={t(tKeys['sign-in'].page['identity-input'].label)} error={form?.email?.meta?.error} spacing={2}>
            <Input type="email" value={form?.email?.value} onChange={e => form?.email?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys['sign-in'].page['identity-input'].placeholder)}/>
          </FormControl>
        </Container>

        {/*  Password input */}
        <Container style={{
            ...styles.phoneInputContainer,
        }} testID={'signInPasswordInput'}>
          <FormControl label={t(tKeys.common.password.label)} error={form?.password?.meta?.error} spacing={2} endHelperEnhancer={<Stack onClick={handleOnPressForgotPassword} cursor={'pointer'}>
                <Text color={COLORS.InputText.value} fontFamily={FONTS.T1.T12Px.Regular400.value.fontFamily} fontSize={FONTS.T1.T12Px.Regular400.value.fontSize} fontWeight={FONTS.T1.T12Px.Regular400.value.fontWeight}>
                  {t(tKeys['sign-in'].page['password-input'].forgot)}
                </Text>
              </Stack>}>
            <PasswordInput {...form?.password} style={{
            paddingVertical: 12,
            borderRadius: '.5rem',
        }} placeholder={t(tKeys['sign-in'].page['password-input'].placeholder)} hasAutoComplete onKeyDown={handleKeyPress}/>
          </FormControl>
        </Container>
        {ContinueButton}
      </Stack>
    </PageWebAuth>);
};
export default SignInPage;
