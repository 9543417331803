import { DocumentTypes } from '../components/surfaces/document-modal/props';
import { mapDocumentPickerResponseToIFileType, mapDocumentReactImageToIimageType, } from '../screens/home/feed/create-post/types';
export function extractBase64FromDataUrl(dataUrl) {
    return dataUrl.split(',')[1];
}
export async function onSelectedFileSuccess(chosenDocuments, setSelectedFile, onOpenModalCreateDocument, eventId, agendaItemId) {
    if (chosenDocuments !== null) {
        const createDocumentEntity = {
            documentMetaData: undefined,
            data: '',
            dataType: DocumentTypes.FILE,
            eventId,
            agendaItemId,
            allFileTypes: [],
            description: '',
            selectedDocumentType: undefined,
            title: '',
        };
        if (chosenDocuments.plainFiles?.[0]?.type.includes('image')) {
            // For image
            const image = {
                mime: chosenDocuments.plainFiles?.[0]?.type,
                path: chosenDocuments.plainFiles?.[0]?.path,
                size: chosenDocuments.plainFiles?.[0]?.size,
                filename: chosenDocuments.plainFiles?.[0]?.name,
                content: chosenDocuments.filesContent?.[0]?.content,
            };
            const ReactImageObject = mapDocumentReactImageToIimageType(image);
            setSelectedFile({
                ...createDocumentEntity,
                data: ReactImageObject?.data,
                dataType: DocumentTypes.PICTURE_GALLERY,
                documentMetaData: ReactImageObject,
            });
        }
        else {
            // For document
            const file = {
                name: chosenDocuments.filesContent?.[0]?.name,
                size: chosenDocuments.plainFiles?.[0]?.size,
                type: chosenDocuments.plainFiles?.[0]?.type,
                content: chosenDocuments.filesContent?.[0]?.content,
            };
            const documentPickerResponseObject = mapDocumentPickerResponseToIFileType(file);
            const base64Data = extractBase64FromDataUrl(file.content);
            setSelectedFile({
                ...createDocumentEntity,
                documentMetaData: documentPickerResponseObject,
                data: base64Data,
                dataType: DocumentTypes.FILE,
            });
        }
        onOpenModalCreateDocument?.();
    }
}
