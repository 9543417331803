import React from 'react';
export const PublishPattern = () => (<svg width="1280" height="103" viewBox="0 0 1280 103" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_16249_16183)">
      <path d="M-15.3924 -5.834L-12.839 4.40732L-3.16439 7.43415L-6.72497 11.0414L-16.3996 8.01456L-18.953 -2.22676L-15.3924 -5.834ZM-3.16439 7.43415L-4.58155 1.75022L-8.88675 0.403279L-5.71784 -2.80717L-0.880538 -1.29375L0.396185 3.82691L-3.16439 7.43415ZM-11.8319 -9.44124L-2.15726 -6.41441L-5.71784 -2.80717L-15.3924 -5.834L-11.8319 -9.44124Z" fill="#327FB6"/>
      <path d="M9.59609 -12.0645L12.1495 -1.82315L21.8241 1.20368L18.2636 4.81092L8.58896 1.78409L6.03551 -8.45722L9.59609 -12.0645ZM21.8241 1.20368L20.407 -4.48025L16.1018 -5.82719L19.2707 -9.03763L24.108 -7.52422L25.3847 -2.40356L21.8241 1.20368ZM13.1567 -15.6717L22.8313 -12.6449L19.2707 -9.03763L9.59609 -12.0645L13.1567 -15.6717Z" fill="#327FB6"/>
      <path d="M34.5849 -18.2949L37.1383 -8.05362L46.8129 -5.02679L43.2523 -1.41955L33.5777 -4.44638L31.0243 -14.6877L34.5849 -18.2949ZM46.8129 -5.02679L45.3957 -10.7107L41.0905 -12.0577L44.2595 -15.2681L49.0968 -13.7547L50.3735 -8.63403L46.8129 -5.02679ZM38.1454 -21.9022L47.82 -18.8753L44.2595 -15.2681L34.5849 -18.2949L38.1454 -21.9022Z" fill="#327FB6"/>
      <path d="M-8.24278 22.8411L-5.68933 33.0824L3.98527 36.1092L0.42469 39.7164L-9.24991 36.6896L-11.8034 26.4483L-8.24278 22.8411ZM3.98527 36.1092L2.5681 30.4253L-1.73709 29.0783L1.43182 25.8679L6.26912 27.3813L7.54584 32.502L3.98527 36.1092ZM-4.6822 19.2338L4.9924 22.2606L1.43182 25.8679L-8.24278 22.8411L-4.6822 19.2338Z" fill="#327FB6"/>
      <path d="M16.7455 16.6108L19.2989 26.8521L28.9735 29.879L25.413 33.4862L15.7384 30.4594L13.1849 20.2181L16.7455 16.6108ZM28.9735 29.879L27.5564 24.195L23.2512 22.8481L26.4201 19.6377L31.2574 21.1511L32.5341 26.2717L28.9735 29.879ZM20.3061 13.0036L29.9807 16.0304L26.4201 19.6377L16.7455 16.6108L20.3061 13.0036Z" fill="#327FB6"/>
      <path d="M41.7348 10.3804L44.2882 20.6217L53.9628 23.6485L50.4022 27.2557L40.7276 24.2289L38.1742 13.9876L41.7348 10.3804ZM53.9628 23.6485L52.5456 17.9646L48.2404 16.6176L51.4094 13.4072L56.2467 14.9206L57.5234 20.0413L53.9628 23.6485ZM45.2953 6.77312L54.9699 9.79995L51.4094 13.4072L41.7348 10.3804L45.2953 6.77312Z" fill="#327FB6"/>
      <path d="M66.7235 4.14989L69.277 14.3912L78.9516 17.418L75.391 21.0253L65.7164 17.9984L63.163 7.75713L66.7235 4.14989ZM78.9516 17.418L77.5344 11.7341L73.2292 10.3872L76.3981 7.17672L81.2354 8.69014L82.5122 13.8108L78.9516 17.418ZM70.2841 0.542649L79.9587 3.56948L76.3981 7.17672L66.7235 4.14989L70.2841 0.542649Z" fill="#327FB6"/>
      <path d="M91.7123 -2.08058L94.2657 8.16073L103.94 11.1876L100.38 14.7948L90.7052 11.768L88.1517 1.52666L91.7123 -2.08058ZM103.94 11.1876L102.523 5.50364L98.218 4.1567L101.387 0.946252L106.224 2.45967L107.501 7.58032L103.94 11.1876ZM95.2729 -5.68782L104.947 -2.66099L101.387 0.946252L91.7123 -2.08058L95.2729 -5.68782Z" fill="#327FB6"/>
      <path d="M116.701 -8.3108L119.255 1.93051L128.929 4.95734L125.369 8.56458L115.694 5.53775L113.14 -4.70356L116.701 -8.3108ZM128.929 4.95734L127.512 -0.726589L123.207 -2.07353L126.376 -5.28397L131.213 -3.77056L132.49 1.3501L128.929 4.95734ZM120.262 -11.918L129.936 -8.89121L126.376 -5.28397L116.701 -8.3108L120.262 -11.918Z" fill="#327FB6"/>
      <path d="M141.69 -14.5413L144.243 -4.29996L153.918 -1.27313L150.357 2.33411L140.683 -0.692719L138.129 -10.934L141.69 -14.5413ZM153.918 -1.27313L152.501 -6.95706L148.196 -8.304L151.364 -11.5144L156.202 -10.001L157.478 -4.88037L153.918 -1.27313ZM145.25 -18.1485L154.925 -15.1217L151.364 -11.5144L141.69 -14.5413L145.25 -18.1485Z" fill="#327FB6"/>
      <path d="M-1.09336 51.5168L1.46008 61.7581L11.1347 64.785L7.5741 68.3922L-2.10049 65.3654L-4.65394 55.1241L-1.09336 51.5168ZM11.1347 64.785L9.71752 59.101L5.41232 57.7541L8.58123 54.5437L13.4185 56.0571L14.6953 61.1777L11.1347 64.785ZM2.46721 47.9096L12.1418 50.9364L8.58123 54.5437L-1.09336 51.5168L2.46721 47.9096Z" fill="#327FB6"/>
      <path d="M23.8954 45.2866L26.4489 55.5279L36.1234 58.5548L32.5629 62.162L22.8883 59.1352L20.3348 48.8938L23.8954 45.2866ZM36.1234 58.5548L34.7063 52.8708L30.4011 51.5239L33.57 48.3134L38.4073 49.8269L39.684 54.9475L36.1234 58.5548ZM27.456 41.6794L37.1306 44.7062L33.57 48.3134L23.8954 45.2866L27.456 41.6794Z" fill="#327FB6"/>
      <path d="M48.8842 39.0561L51.4376 49.2975L61.1122 52.3243L57.5516 55.9315L47.877 52.9047L45.3236 42.6634L48.8842 39.0561ZM61.1122 52.3243L59.6951 46.6404L55.3899 45.2934L58.5588 42.083L63.3961 43.5964L64.6728 48.717L61.1122 52.3243ZM52.4448 35.4489L62.1193 38.4757L58.5588 42.083L48.8842 39.0561L52.4448 35.4489Z" fill="#327FB6"/>
      <path d="M73.8729 32.8257L76.4264 43.067L86.101 46.0938L82.5404 49.7011L72.8658 46.6742L70.3124 36.4329L73.8729 32.8257ZM86.101 46.0938L84.6838 40.4099L80.3786 39.0629L83.5475 35.8525L88.3848 37.3659L89.6616 42.4866L86.101 46.0938ZM77.4335 29.2184L87.1081 32.2453L83.5475 35.8525L73.8729 32.8257L77.4335 29.2184Z" fill="#327FB6"/>
      <path d="M98.8622 26.5952L101.416 36.8365L111.09 39.8633L107.53 43.4706L97.8551 40.4438L95.3016 30.2024L98.8622 26.5952ZM111.09 39.8633L109.673 34.1794L105.368 32.8325L108.537 29.622L113.374 31.1354L114.651 36.2561L111.09 39.8633ZM102.423 22.988L112.097 26.0148L108.537 29.622L98.8622 26.5952L102.423 22.988Z" fill="#327FB6"/>
      <path d="M123.85 20.365L126.404 30.6063L136.079 33.6331L132.518 37.2404L122.843 34.2135L120.29 23.9722L123.85 20.365ZM136.079 33.6331L134.661 27.9492L130.356 26.6023L133.525 23.3918L138.362 24.9052L139.639 30.0259L136.079 33.6331ZM127.411 16.7577L137.086 19.7846L133.525 23.3918L123.85 20.365L127.411 16.7577Z" fill="#327FB6"/>
      <path d="M148.839 14.1345L151.393 24.3758L161.067 27.4027L157.507 31.0099L147.832 27.9831L145.279 17.7418L148.839 14.1345ZM161.067 27.4027L159.65 21.7187L155.345 20.3718L158.514 17.1613L163.351 18.6748L164.628 23.7954L161.067 27.4027ZM152.4 10.5273L162.074 13.5541L158.514 17.1613L148.839 14.1345L152.4 10.5273Z" fill="#327FB6"/>
      <path d="M173.829 7.90404L176.382 18.1454L186.057 21.1722L182.496 24.7794L172.821 21.7526L170.268 11.5113L173.829 7.90404ZM186.057 21.1722L184.639 15.4883L180.334 14.1413L183.503 10.9309L188.34 12.4443L189.617 17.5649L186.057 21.1722ZM177.389 4.2968L187.064 7.32363L183.503 10.9309L173.829 7.90404L177.389 4.2968Z" fill="#327FB6"/>
      <path d="M198.817 1.67357L201.371 11.9149L211.045 14.9417L207.485 18.549L197.81 15.5221L195.257 5.28081L198.817 1.67357ZM211.045 14.9417L209.628 9.25779L205.323 7.91085L208.492 4.7004L213.329 6.21382L214.606 11.3345L211.045 14.9417ZM202.378 -1.93367L212.052 1.09316L208.492 4.7004L198.817 1.67357L202.378 -1.93367Z" fill="#327FB6"/>
      <path d="M223.806 -4.55665L226.359 5.68466L236.034 8.71149L232.474 12.3187L222.799 9.2919L220.245 -0.949412L223.806 -4.55665ZM236.034 8.71149L234.617 3.02756L230.312 1.68062L233.481 -1.52982L238.318 -0.0164072L239.595 5.10425L236.034 8.71149ZM227.367 -8.16389L237.041 -5.13706L233.481 -1.52982L223.806 -4.55665L227.367 -8.16389Z" fill="#327FB6"/>
      <path d="M248.795 -10.7871L251.348 -0.545809L261.023 2.48102L257.462 6.08826L247.788 3.06143L245.234 -7.17988L248.795 -10.7871ZM261.023 2.48102L259.606 -3.20291L255.301 -4.54985L258.469 -7.76029L263.307 -6.24688L264.583 -1.12622L261.023 2.48102ZM252.355 -14.3944L262.03 -11.3675L258.469 -7.76029L248.795 -10.7871L252.355 -14.3944Z" fill="#327FB6"/>
      <path d="M273.784 -17.0176L276.337 -6.77628L286.012 -3.74945L282.451 -0.142206L272.776 -3.16904L270.223 -13.4103L273.784 -17.0176ZM286.012 -3.74945L284.594 -9.43338L280.289 -10.7803L283.458 -13.9908L288.295 -12.4773L289.572 -7.35669L286.012 -3.74945ZM277.344 -20.6248L287.019 -17.598L283.458 -13.9908L273.784 -17.0176L277.344 -20.6248Z" fill="#327FB6"/>
      <path d="M6.05654 80.1931L8.60998 90.4344L18.2846 93.4612L14.724 97.0685L5.04941 94.0417L2.49596 83.8003L6.05654 80.1931ZM18.2846 93.4612L16.8674 87.7773L12.5622 86.4304L15.7311 83.2199L20.5684 84.7333L21.8452 89.854L18.2846 93.4612ZM9.61711 76.5859L19.2917 79.6127L15.7311 83.2199L6.05654 80.1931L9.61711 76.5859Z" fill="#327FB6"/>
      <path d="M31.0453 73.9626L33.5988 84.2039L43.2734 87.2308L39.7128 90.838L30.0382 87.8112L27.4847 77.5699L31.0453 73.9626ZM43.2734 87.2308L41.8562 81.5468L37.551 80.1999L40.7199 76.9895L45.5572 78.5029L46.8339 83.6235L43.2734 87.2308ZM34.6059 70.3554L44.2805 73.3822L40.7199 76.9895L31.0453 73.9626L34.6059 70.3554Z" fill="#327FB6"/>
      <path d="M56.0341 67.7324L58.5875 77.9737L68.2621 81.0006L64.7015 84.6078L55.0269 81.581L52.4735 71.3397L56.0341 67.7324ZM68.2621 81.0006L66.845 75.3166L62.5398 73.9697L65.7087 70.7592L70.546 72.2727L71.8227 77.3933L68.2621 81.0006ZM59.5947 64.1252L69.2693 67.152L65.7087 70.7592L56.0341 67.7324L59.5947 64.1252Z" fill="#327FB6"/>
      <path d="M81.0228 61.5019L83.5763 71.7433L93.2509 74.7701L89.6903 78.3773L80.0157 75.3505L77.4623 65.1092L81.0228 61.5019ZM93.2509 74.7701L91.8337 69.0862L87.5285 67.7392L90.6974 64.5288L95.5347 66.0422L96.8115 71.1628L93.2509 74.7701ZM84.5834 57.8947L94.258 60.9215L90.6974 64.5288L81.0228 61.5019L84.5834 57.8947Z" fill="#327FB6"/>
      <path d="M106.012 55.2715L108.565 65.5128L118.24 68.5396L114.679 72.1469L105.004 69.12L102.451 58.8787L106.012 55.2715ZM118.24 68.5396L116.822 62.8557L112.517 61.5087L115.686 58.2983L120.524 59.8117L121.8 64.9324L118.24 68.5396ZM109.572 51.6642L119.247 54.6911L115.686 58.2983L106.012 55.2715L109.572 51.6642Z" fill="#327FB6"/>
      <path d="M131 49.041L133.554 59.2823L143.228 62.3091L139.668 65.9164L129.993 62.8896L127.44 52.6482L131 49.041ZM143.228 62.3091L141.811 56.6252L137.506 55.2783L140.675 52.0678L145.512 53.5812L146.789 58.7019L143.228 62.3091ZM134.561 45.4338L144.236 48.4606L140.675 52.0678L131 49.041L134.561 45.4338Z" fill="#327FB6"/>
      <path d="M155.989 42.8105L158.543 53.0518L168.217 56.0787L164.657 59.6859L154.982 56.6591L152.429 46.4178L155.989 42.8105ZM168.217 56.0787L166.8 50.3947L162.495 49.0478L165.664 45.8374L170.501 47.3508L171.778 52.4714L168.217 56.0787ZM159.55 39.2033L169.224 42.2301L165.664 45.8374L155.989 42.8105L159.55 39.2033Z" fill="#327FB6"/>
      <path d="M180.978 36.5803L183.531 46.8216L193.206 49.8485L189.645 53.4557L179.971 50.4289L177.417 40.1876L180.978 36.5803ZM193.206 49.8485L191.789 44.1645L187.484 42.8176L190.653 39.6071L195.49 41.1206L196.767 46.2412L193.206 49.8485ZM184.539 32.9731L194.213 35.9999L190.653 39.6071L180.978 36.5803L184.539 32.9731Z" fill="#327FB6"/>
      <path d="M205.967 30.3498L208.52 40.5912L218.195 43.618L214.634 47.2252L204.96 44.1984L202.406 33.9571L205.967 30.3498ZM218.195 43.618L216.778 37.9341L212.472 36.5871L215.641 33.3767L220.479 34.8901L221.755 40.0107L218.195 43.618ZM209.527 26.7426L219.202 29.7694L215.641 33.3767L205.967 30.3498L209.527 26.7426Z" fill="#327FB6"/>
      <path d="M230.955 24.1194L233.509 34.3607L243.184 37.3875L239.623 40.9948L229.948 37.9679L227.395 27.7266L230.955 24.1194ZM243.184 37.3875L241.766 31.7036L237.461 30.3566L240.63 27.1462L245.467 28.6596L246.744 33.7803L243.184 37.3875ZM234.516 20.5121L244.191 23.539L240.63 27.1462L230.955 24.1194L234.516 20.5121Z" fill="#327FB6"/>
      <path d="M255.944 17.8889L258.498 28.1302L268.172 31.157L264.612 34.7643L254.937 31.7375L252.384 21.4961L255.944 17.8889ZM268.172 31.157L266.755 25.4731L262.45 24.1262L265.619 20.9157L270.456 22.4291L271.733 27.5498L268.172 31.157ZM259.505 14.2817L269.179 17.3085L265.619 20.9157L255.944 17.8889L259.505 14.2817Z" fill="#327FB6"/>
      <path d="M280.933 11.6587L283.487 21.9L293.162 24.9268L289.601 28.5341L279.926 25.5072L277.373 15.2659L280.933 11.6587ZM293.162 24.9268L291.744 19.2429L287.439 17.896L290.608 14.6855L295.445 16.1989L296.722 21.3196L293.162 24.9268ZM284.494 8.05144L294.169 11.0783L290.608 14.6855L280.933 11.6587L284.494 8.05144Z" fill="#327FB6"/>
      <path d="M305.922 5.42821L308.475 15.6695L318.15 18.6964L314.589 22.3036L304.915 19.2768L302.361 9.03545L305.922 5.42821ZM318.15 18.6964L316.733 13.0124L312.427 11.6655L315.596 8.45504L320.434 9.96846L321.71 15.0891L318.15 18.6964ZM309.482 1.82097L319.157 4.8478L315.596 8.45504L305.922 5.42821L309.482 1.82097Z" fill="#327FB6"/>
      <path d="M330.911 -0.802259L333.464 9.43905L343.139 12.4659L339.578 16.0731L329.904 13.0463L327.35 2.80498L330.911 -0.802259ZM343.139 12.4659L341.722 6.78196L337.417 5.43502L340.586 2.22457L345.423 3.73799L346.7 8.85864L343.139 12.4659ZM334.472 -4.4095L344.146 -1.38267L340.586 2.22457L330.911 -0.802259L334.472 -4.4095Z" fill="#327FB6"/>
      <path d="M355.9 -7.03273L358.453 3.20859L368.128 6.23542L364.567 9.84266L354.893 6.81583L352.339 -3.42549L355.9 -7.03273ZM368.128 6.23542L366.711 0.551488L362.405 -0.795452L365.574 -4.0059L370.412 -2.49248L371.688 2.62817L368.128 6.23542ZM359.46 -10.64L369.135 -7.61314L365.574 -4.0059L355.9 -7.03273L359.46 -10.64Z" fill="#327FB6"/>
      <path d="M380.889 -13.263L383.442 -3.02164L393.117 0.00519226L389.556 3.61243L379.881 0.585602L377.328 -9.65571L380.889 -13.263ZM393.117 0.00519226L391.699 -5.67874L387.394 -7.02568L390.563 -10.2361L395.4 -8.72271L396.677 -3.60205L393.117 0.00519226ZM384.449 -16.8702L394.124 -13.8434L390.563 -10.2361L380.889 -13.263L384.449 -16.8702Z" fill="#327FB6"/>
      <path d="M38.1947 102.638L40.7482 112.879L50.4228 115.906L46.8622 119.513L37.1876 116.486L34.6341 106.245L38.1947 102.638ZM50.4228 115.906L49.0056 110.222L44.7004 108.875L47.8693 105.665L52.7066 107.178L53.9833 112.299L50.4228 115.906ZM41.7553 99.0307L51.4299 102.058L47.8693 105.665L38.1947 102.638L41.7553 99.0307Z" fill="#327FB6"/>
      <path d="M63.1835 96.4075L65.7369 106.649L75.4115 109.676L71.851 113.283L62.1764 110.256L59.6229 100.015L63.1835 96.4075ZM75.4115 109.676L73.9944 103.992L69.6892 102.645L72.8581 99.4343L77.6954 100.948L78.9721 106.068L75.4115 109.676ZM66.7441 92.8002L76.4187 95.827L72.8581 99.4343L63.1835 96.4075L66.7441 92.8002Z" fill="#327FB6"/>
      <path d="M88.1723 90.177L90.7257 100.418L100.4 103.445L96.8397 107.052L87.1651 104.026L84.6117 93.7842L88.1723 90.177ZM100.4 103.445L98.9831 97.7612L94.6779 96.4143L97.8469 93.2038L102.684 94.7172L103.961 99.8379L100.4 103.445ZM91.7328 86.5697L101.407 89.5966L97.8469 93.2038L88.1723 90.177L91.7328 86.5697Z" fill="#327FB6"/>
      <path d="M113.161 83.9468L115.714 94.1881L125.389 97.2149L121.828 100.822L112.154 97.7953L109.6 87.554L113.161 83.9468ZM125.389 97.2149L123.972 91.531L119.667 90.184L122.836 86.9736L127.673 88.487L128.95 93.6077L125.389 97.2149ZM116.722 80.3395L126.396 83.3664L122.836 86.9736L113.161 83.9468L116.722 80.3395Z" fill="#327FB6"/>
      <path d="M138.15 77.7163L140.703 87.9576L150.378 90.9844L146.817 94.5917L137.143 91.5648L134.589 81.3235L138.15 77.7163ZM150.378 90.9844L148.961 85.3005L144.655 83.9536L147.824 80.7431L152.662 82.2565L153.938 87.3772L150.378 90.9844ZM141.71 74.1091L151.385 77.1359L147.824 80.7431L138.15 77.7163L141.71 74.1091Z" fill="#327FB6"/>
      <path d="M163.139 71.4858L165.692 81.7271L175.367 84.754L171.806 88.3612L162.131 85.3344L159.578 75.0931L163.139 71.4858ZM175.367 84.754L173.949 79.07L169.644 77.7231L172.813 74.5127L177.65 76.0261L178.927 81.1467L175.367 84.754ZM166.699 67.8786L176.374 70.9054L172.813 74.5127L163.139 71.4858L166.699 67.8786Z" fill="#327FB6"/>
      <path d="M188.128 65.2554L190.681 75.4967L200.356 78.5235L196.795 82.1307L187.121 79.1039L184.567 68.8626L188.128 65.2554ZM200.356 78.5235L198.939 72.8396L194.634 71.4926L197.802 68.2822L202.64 69.7956L203.916 74.9163L200.356 78.5235ZM191.688 61.6481L201.363 64.6749L197.802 68.2822L188.128 65.2554L191.688 61.6481Z" fill="#327FB6"/>
      <path d="M213.117 59.0251L215.67 69.2664L225.345 72.2933L221.784 75.9005L212.109 72.8737L209.556 62.6324L213.117 59.0251ZM225.345 72.2933L223.927 66.6093L219.622 65.2624L222.791 62.052L227.628 63.5654L228.905 68.686L225.345 72.2933ZM216.677 55.4179L226.352 58.4447L222.791 62.052L213.117 59.0251L216.677 55.4179Z" fill="#327FB6"/>
      <path d="M238.105 52.7947L240.659 63.036L250.333 66.0628L246.773 69.67L237.098 66.6432L234.545 56.4019L238.105 52.7947ZM250.333 66.0628L248.916 60.3789L244.611 59.0319L247.78 55.8215L252.617 57.3349L253.894 62.4556L250.333 66.0628ZM241.666 49.1874L251.341 52.2143L247.78 55.8215L238.105 52.7947L241.666 49.1874Z" fill="#327FB6"/>
      <path d="M263.094 46.5642L265.648 56.8055L275.322 59.8323L271.762 63.4396L262.087 60.4128L259.534 50.1714L263.094 46.5642ZM275.322 59.8323L273.905 54.1484L269.6 52.8015L272.769 49.591L277.606 51.1044L278.883 56.2251L275.322 59.8323ZM266.655 42.957L276.329 45.9838L272.769 49.591L263.094 46.5642L266.655 42.957Z" fill="#327FB6"/>
      <path d="M288.083 40.3337L290.636 50.575L300.311 53.6019L296.75 57.2091L287.076 54.1823L284.522 43.941L288.083 40.3337ZM300.311 53.6019L298.894 47.9179L294.589 46.571L297.758 43.3606L302.595 44.874L303.872 49.9946L300.311 53.6019ZM291.643 36.7265L301.318 39.7533L297.758 43.3606L288.083 40.3337L291.643 36.7265Z" fill="#327FB6"/>
      <path d="M313.072 34.1035L315.625 44.3448L325.3 47.3716L321.739 50.9789L312.065 47.9521L309.511 37.7107L313.072 34.1035ZM325.3 47.3716L323.883 41.6877L319.577 40.3408L322.746 37.1303L327.584 38.6437L328.86 43.7644L325.3 47.3716ZM316.632 30.4963L326.307 33.5231L322.746 37.1303L313.072 34.1035L316.632 30.4963Z" fill="#327FB6"/>
      <path d="M338.06 27.873L340.614 38.1143L350.288 41.1412L346.728 44.7484L337.053 41.7216L334.5 31.4803L338.06 27.873ZM350.288 41.1412L348.871 35.4572L344.566 34.1103L347.735 30.8999L352.572 32.4133L353.849 37.5339L350.288 41.1412ZM341.621 24.2658L351.296 27.2926L347.735 30.8999L338.06 27.873L341.621 24.2658Z" fill="#327FB6"/>
      <path d="M363.049 21.6426L365.603 31.8839L375.277 34.9107L371.717 38.5179L362.042 35.4911L359.489 25.2498L363.049 21.6426ZM375.277 34.9107L373.86 29.2268L369.555 27.8798L372.724 24.6694L377.561 26.1828L378.838 31.3035L375.277 34.9107ZM366.61 18.0353L376.284 21.0622L372.724 24.6694L363.049 21.6426L366.61 18.0353Z" fill="#327FB6"/>
      <path d="M388.038 15.4121L390.591 25.6534L400.266 28.6802L396.705 32.2875L387.031 29.2607L384.477 19.0193L388.038 15.4121ZM400.266 28.6802L398.849 22.9963L394.544 21.6494L397.713 18.4389L402.55 19.9523L403.827 25.073L400.266 28.6802ZM391.599 11.8049L401.273 14.8317L397.713 18.4389L388.038 15.4121L391.599 11.8049Z" fill="#327FB6"/>
      <path d="M413.027 9.18187L415.58 19.4232L425.255 22.45L421.694 26.0573L412.02 23.0304L409.466 12.7891L413.027 9.18187ZM425.255 22.45L423.838 16.7661L419.532 15.4191L422.701 12.2087L427.539 13.7221L428.815 18.8428L425.255 22.45ZM416.587 5.57463L426.262 8.60146L422.701 12.2087L413.027 9.18187L416.587 5.57463Z" fill="#327FB6"/>
      <path d="M438.016 2.9514L440.569 13.1927L450.244 16.2195L446.683 19.8268L437.008 16.8L434.455 6.55864L438.016 2.9514ZM450.244 16.2195L448.826 10.5356L444.521 9.18868L447.69 5.97823L452.527 7.49165L453.804 12.6123L450.244 16.2195ZM441.576 -0.655837L451.251 2.37099L447.69 5.97823L438.016 2.9514L441.576 -0.655837Z" fill="#327FB6"/>
      <path d="M463.004 -3.27907L465.558 6.96225L475.232 9.98908L471.672 13.5963L461.997 10.5695L459.444 0.328176L463.004 -3.27907ZM475.232 9.98908L473.815 4.30515L469.51 2.95821L472.679 -0.252234L477.516 1.26118L478.793 6.38184L475.232 9.98908ZM466.565 -6.88631L476.239 -3.85948L472.679 -0.252234L463.004 -3.27907L466.565 -6.88631Z" fill="#327FB6"/>
      <path d="M487.993 -9.50929L490.547 0.732023L500.221 3.75885L496.661 7.36609L486.986 4.33926L484.432 -5.90205L487.993 -9.50929ZM500.221 3.75885L498.804 -1.92507L494.499 -3.27201L497.668 -6.48246L502.505 -4.96904L503.782 0.151612L500.221 3.75885ZM491.554 -13.1165L501.228 -10.0897L497.668 -6.48246L487.993 -9.50929L491.554 -13.1165Z" fill="#327FB6"/>
      <path d="M512.982 -15.7398L515.535 -5.49845L525.21 -2.47161L521.649 1.13563L511.975 -1.89121L509.421 -12.1325L512.982 -15.7398ZM525.21 -2.47161L523.793 -8.15554L519.488 -9.50248L522.656 -12.7129L527.494 -11.1995L528.77 -6.07886L525.21 -2.47161ZM516.542 -19.347L526.217 -16.3202L522.656 -12.7129L512.982 -15.7398L516.542 -19.347Z" fill="#327FB6"/>
      <path d="M145.299 106.392L147.853 116.633L157.527 119.66L153.967 123.267L144.292 120.241L141.739 109.999L145.299 106.392ZM157.527 119.66L156.11 113.976L151.805 112.629L154.974 109.419L159.811 110.932L161.088 116.053L157.527 119.66ZM148.86 102.785L158.534 105.812L154.974 109.419L145.299 106.392L148.86 102.785Z" fill="#327FB6"/>
      <path d="M170.288 100.162L172.841 110.403L182.516 113.43L178.955 117.037L169.281 114.01L166.727 103.769L170.288 100.162ZM182.516 113.43L181.099 107.746L176.794 106.399L179.963 103.188L184.8 104.702L186.077 109.823L182.516 113.43ZM173.849 96.5544L183.523 99.5812L179.963 103.188L170.288 100.162L173.849 96.5544Z" fill="#327FB6"/>
      <path d="M195.277 93.9311L197.831 104.172L207.505 107.199L203.945 110.807L194.27 107.78L191.717 97.5384L195.277 93.9311ZM207.505 107.199L206.088 101.515L201.783 100.168L204.952 96.958L209.789 98.4714L211.066 103.592L207.505 107.199ZM198.838 90.3239L208.512 93.3507L204.952 96.958L195.277 93.9311L198.838 90.3239Z" fill="#327FB6"/>
      <path d="M220.266 87.7007L222.819 97.942L232.494 100.969L228.933 104.576L219.259 101.549L216.705 91.3079L220.266 87.7007ZM232.494 100.969L231.077 95.2849L226.772 93.9379L229.941 90.7275L234.778 92.2409L236.055 97.3616L232.494 100.969ZM223.827 84.0934L233.501 87.1203L229.941 90.7275L220.266 87.7007L223.827 84.0934Z" fill="#327FB6"/>
      <path d="M245.255 81.4704L247.808 91.7118L257.483 94.7386L253.922 98.3458L244.248 95.319L241.694 85.0777L245.255 81.4704ZM257.483 94.7386L256.066 89.0547L251.76 87.7077L254.929 84.4973L259.767 86.0107L261.043 91.1313L257.483 94.7386ZM248.815 77.8632L258.49 80.89L254.929 84.4973L245.255 81.4704L248.815 77.8632Z" fill="#327FB6"/>
      <path d="M270.244 75.24L272.797 85.4813L282.472 88.5081L278.911 92.1154L269.236 89.0885L266.683 78.8472L270.244 75.24ZM282.472 88.5081L281.054 82.8242L276.749 81.4773L279.918 78.2668L284.755 79.7802L286.032 84.9009L282.472 88.5081ZM273.804 71.6327L283.479 74.6596L279.918 78.2668L270.244 75.24L273.804 71.6327Z" fill="#327FB6"/>
      <path d="M295.232 69.0095L297.786 79.2508L307.46 82.2777L303.9 85.8849L294.225 82.8581L291.672 72.6168L295.232 69.0095ZM307.46 82.2777L306.043 76.5937L301.738 75.2468L304.907 72.0363L309.744 73.5498L311.021 78.6704L307.46 82.2777ZM298.793 65.4023L308.467 68.4291L304.907 72.0363L295.232 69.0095L298.793 65.4023Z" fill="#327FB6"/>
      <path d="M320.221 62.7793L322.775 73.0206L332.449 76.0474L328.889 79.6547L319.214 76.6278L316.661 66.3865L320.221 62.7793ZM332.449 76.0474L331.032 70.3635L326.727 69.0166L329.896 65.8061L334.733 67.3195L336.01 72.4402L332.449 76.0474ZM323.782 59.172L333.456 62.1989L329.896 65.8061L320.221 62.7793L323.782 59.172Z" fill="#327FB6"/>
      <path d="M345.21 56.5488L347.763 66.7901L357.438 69.817L353.877 73.4242L344.203 70.3974L341.649 60.1561L345.21 56.5488ZM357.438 69.817L356.021 64.133L351.716 62.7861L354.884 59.5756L359.722 61.0891L360.998 66.2097L357.438 69.817ZM348.77 52.9416L358.445 55.9684L354.884 59.5756L345.21 56.5488L348.77 52.9416Z" fill="#327FB6"/>
      <path d="M370.199 50.3183L372.752 60.5597L382.427 63.5865L378.866 67.1937L369.191 64.1669L366.638 53.9256L370.199 50.3183ZM382.427 63.5865L381.01 57.9026L376.704 56.5556L379.873 53.3452L384.711 54.8586L385.987 59.9792L382.427 63.5865ZM373.759 46.7111L383.434 49.7379L379.873 53.3452L370.199 50.3183L373.759 46.7111Z" fill="#327FB6"/>
      <path d="M395.187 44.0879L397.741 54.3292L407.415 57.356L403.855 60.9633L394.18 57.9364L391.627 47.6951L395.187 44.0879ZM407.415 57.356L405.998 51.6721L401.693 50.3252L404.862 47.1147L409.699 48.6281L410.976 53.7488L407.415 57.356ZM398.748 40.4806L408.423 43.5075L404.862 47.1147L395.187 44.0879L398.748 40.4806Z" fill="#327FB6"/>
      <path d="M420.176 37.8577L422.73 48.099L432.404 51.1258L428.844 54.733L419.169 51.7062L416.616 41.4649L420.176 37.8577ZM432.404 51.1258L430.987 45.4419L426.682 44.0949L429.851 40.8845L434.688 42.3979L435.965 47.5186L432.404 51.1258ZM423.737 34.2504L433.411 37.2772L429.851 40.8845L420.176 37.8577L423.737 34.2504Z" fill="#327FB6"/>
      <path d="M445.165 31.6272L447.718 41.8685L457.393 44.8953L453.832 48.5026L444.158 45.4757L441.604 35.2344L445.165 31.6272ZM457.393 44.8953L455.976 39.2114L451.671 37.8645L454.84 34.654L459.677 36.1674L460.954 41.2881L457.393 44.8953ZM448.726 28.0199L458.4 31.0468L454.84 34.654L445.165 31.6272L448.726 28.0199Z" fill="#327FB6"/>
      <path d="M470.154 25.3967L472.707 35.638L482.382 38.6649L478.821 42.2721L469.147 39.2453L466.593 29.004L470.154 25.3967ZM482.382 38.6649L480.965 32.9809L476.659 31.634L479.828 28.4235L484.666 29.937L485.942 35.0576L482.382 38.6649ZM473.714 21.7895L483.389 24.8163L479.828 28.4235L470.154 25.3967L473.714 21.7895Z" fill="#327FB6"/>
      <path d="M495.142 19.1662L497.696 29.4076L507.371 32.4344L503.81 36.0416L494.135 33.0148L491.582 22.7735L495.142 19.1662ZM507.371 32.4344L505.953 26.7505L501.648 25.4035L504.817 22.1931L509.654 23.7065L510.931 28.8271L507.371 32.4344ZM498.703 15.559L508.378 18.5858L504.817 22.1931L495.142 19.1662L498.703 15.559Z" fill="#327FB6"/>
      <path d="M520.131 12.9358L522.685 23.1771L532.359 26.2039L528.799 29.8112L519.124 26.7843L516.571 16.543L520.131 12.9358ZM532.359 26.2039L530.942 20.52L526.637 19.1731L529.806 15.9626L534.643 17.476L535.92 22.5967L532.359 26.2039ZM523.692 9.32854L533.366 12.3554L529.806 15.9626L520.131 12.9358L523.692 9.32854Z" fill="#327FB6"/>
      <path d="M545.12 6.70555L547.673 16.9469L557.348 19.9737L553.787 23.5809L544.113 20.5541L541.559 10.3128L545.12 6.70555ZM557.348 19.9737L555.931 14.2898L551.626 12.9428L554.795 9.73238L559.632 11.2458L560.909 16.3665L557.348 19.9737ZM548.681 3.09831L558.355 6.12514L554.795 9.73238L545.12 6.70555L548.681 3.09831Z" fill="#327FB6"/>
      <path d="M570.109 0.475085L572.662 10.7164L582.337 13.7432L578.776 17.3505L569.102 14.3236L566.548 4.08233L570.109 0.475085ZM582.337 13.7432L580.92 8.0593L576.614 6.71236L579.783 3.50192L584.621 5.01533L585.897 10.136L582.337 13.7432ZM573.669 -3.13216L583.344 -0.105325L579.783 3.50192L570.109 0.475085L573.669 -3.13216Z" fill="#327FB6"/>
      <path d="M595.098 -5.75538L597.651 4.48593L607.326 7.51276L603.765 11.12L594.09 8.09317L591.537 -2.14814L595.098 -5.75538ZM607.326 7.51276L605.908 1.82883L601.603 0.481892L604.772 -2.72855L609.609 -1.21514L610.886 3.90552L607.326 7.51276ZM598.658 -9.36262L608.333 -6.33579L604.772 -2.72855L595.098 -5.75538L598.658 -9.36262Z" fill="#327FB6"/>
      <path d="M620.086 -11.9859L622.64 -1.74454L632.314 1.28229L628.754 4.88953L619.079 1.8627L616.526 -8.37861L620.086 -11.9859ZM632.314 1.28229L630.897 -4.40164L626.592 -5.74858L629.761 -8.95902L634.598 -7.44561L635.875 -2.32495L632.314 1.28229ZM623.647 -15.5931L633.321 -12.5663L629.761 -8.95902L620.086 -11.9859L623.647 -15.5931Z" fill="#327FB6"/>
      <path d="M645.075 -18.2161L647.629 -7.97476L657.303 -4.94793L653.743 -1.34069L644.068 -4.36752L641.515 -14.6088L645.075 -18.2161ZM657.303 -4.94793L655.886 -10.6319L651.581 -11.9788L654.75 -15.1892L659.587 -13.6758L660.864 -8.55517L657.303 -4.94793ZM648.636 -21.8233L658.31 -18.7965L654.75 -15.1892L645.075 -18.2161L648.636 -21.8233Z" fill="#327FB6"/>
      <path d="M277.393 103.916L279.947 114.157L289.621 117.184L286.061 120.791L276.386 117.765L273.833 107.523L277.393 103.916ZM289.621 117.184L288.204 111.5L283.899 110.153L287.068 106.943L291.905 108.456L293.182 113.577L289.621 117.184ZM280.954 100.309L290.629 103.336L287.068 106.943L277.393 103.916L280.954 100.309Z" fill="#327FB6"/>
      <path d="M302.382 97.6855L304.936 107.927L314.61 110.954L311.05 114.561L301.375 111.534L298.822 101.293L302.382 97.6855ZM314.61 110.954L313.193 105.27L308.888 103.923L312.057 100.712L316.894 102.226L318.171 107.346L314.61 110.954ZM305.943 94.0783L315.617 97.1051L312.057 100.712L302.382 97.6855L305.943 94.0783Z" fill="#327FB6"/>
      <path d="M327.371 91.4551L329.924 101.696L339.599 104.723L336.038 108.33L326.364 105.304L323.81 95.0623L327.371 91.4551ZM339.599 104.723L338.182 99.0393L333.877 97.6923L337.046 94.4819L341.883 95.9953L343.16 101.116L339.599 104.723ZM330.932 87.8478L340.606 90.8747L337.046 94.4819L327.371 91.4551L330.932 87.8478Z" fill="#327FB6"/>
      <path d="M352.36 85.2246L354.913 95.4659L364.588 98.4927L361.027 102.1L351.353 99.0732L348.799 88.8318L352.36 85.2246ZM364.588 98.4927L363.171 92.8088L358.865 91.4619L362.034 88.2514L366.872 89.7648L368.148 94.8855L364.588 98.4927ZM355.92 81.6174L365.595 84.6442L362.034 88.2514L352.36 85.2246L355.92 81.6174Z" fill="#327FB6"/>
      <path d="M377.349 78.9944L379.902 89.2357L389.577 92.2625L386.016 95.8698L376.341 92.8429L373.788 82.6016L377.349 78.9944ZM389.577 92.2625L388.159 86.5786L383.854 85.2316L387.023 82.0212L391.86 83.5346L393.137 88.6553L389.577 92.2625ZM380.909 75.3871L390.584 78.414L387.023 82.0212L377.349 78.9944L380.909 75.3871Z" fill="#327FB6"/>
      <path d="M402.337 72.7639L404.891 83.0052L414.565 86.032L411.005 89.6393L401.33 86.6125L398.777 76.3711L402.337 72.7639ZM414.565 86.032L413.148 80.3481L408.843 79.0012L412.012 75.7907L416.849 77.3041L418.126 82.4248L414.565 86.032ZM405.898 69.1567L415.572 72.1835L412.012 75.7907L402.337 72.7639L405.898 69.1567Z" fill="#327FB6"/>
      <path d="M427.326 66.5334L429.88 76.7747L439.554 79.8016L435.994 83.4088L426.319 80.382L423.765 70.1407L427.326 66.5334ZM439.554 79.8016L438.137 74.1176L433.832 72.7707L437.001 69.5603L441.838 71.0737L443.115 76.1943L439.554 79.8016ZM430.887 62.9262L440.561 65.953L437.001 69.5603L427.326 66.5334L430.887 62.9262Z" fill="#327FB6"/>
      <path d="M452.315 60.303L454.868 70.5443L464.543 73.5711L460.982 77.1784L451.308 74.1515L448.754 63.9102L452.315 60.303ZM464.543 73.5711L463.126 67.8872L458.821 66.5402L461.989 63.3298L466.827 64.8432L468.103 69.9639L464.543 73.5711ZM455.875 56.6957L465.55 59.7226L461.989 63.3298L452.315 60.303L455.875 56.6957Z" fill="#327FB6"/>
      <path d="M477.304 54.0727L479.858 64.3141L489.532 67.3409L485.972 70.9481L476.297 67.9213L473.744 57.68L477.304 54.0727ZM489.532 67.3409L488.115 61.657L483.81 60.31L486.979 57.0996L491.816 58.613L493.093 63.7336L489.532 67.3409ZM480.865 50.4655L490.539 53.4923L486.979 57.0996L477.304 54.0727L480.865 50.4655Z" fill="#327FB6"/>
      <path d="M502.292 47.842L504.846 58.0833L514.52 61.1102L510.96 64.7174L501.285 61.6906L498.732 51.4493L502.292 47.842ZM514.52 61.1102L513.103 55.4262L508.798 54.0793L511.967 50.8689L516.804 52.3823L518.081 57.5029L514.52 61.1102ZM505.853 44.2348L515.528 47.2616L511.967 50.8689L502.292 47.842L505.853 44.2348Z" fill="#327FB6"/>
      <path d="M527.281 41.6116L529.835 51.8529L539.509 54.8797L535.949 58.4869L526.274 55.4601L523.721 45.2188L527.281 41.6116ZM539.509 54.8797L538.092 49.1958L533.787 47.8488L536.956 44.6384L541.793 46.1518L543.07 51.2725L539.509 54.8797ZM530.842 38.0043L540.516 41.0311L536.956 44.6384L527.281 41.6116L530.842 38.0043Z" fill="#327FB6"/>
      <path d="M552.27 35.3813L554.823 45.6226L564.498 48.6495L560.937 52.2567L551.263 49.2299L548.709 38.9886L552.27 35.3813ZM564.498 48.6495L563.081 42.9656L558.776 41.6186L561.945 38.4082L566.782 39.9216L568.059 45.0422L564.498 48.6495ZM555.83 31.7741L565.505 34.8009L561.945 38.4082L552.27 35.3813L555.83 31.7741Z" fill="#327FB6"/>
      <path d="M577.259 29.1511L579.812 39.3924L589.487 42.4193L585.926 46.0265L576.252 42.9997L573.698 32.7584L577.259 29.1511ZM589.487 42.4193L588.07 36.7353L583.764 35.3884L586.933 32.1779L591.771 33.6914L593.047 38.812L589.487 42.4193ZM580.819 25.5439L590.494 28.5707L586.933 32.1779L577.259 29.1511L580.819 25.5439Z" fill="#327FB6"/>
      <path d="M602.248 22.9206L604.801 33.162L614.476 36.1888L610.915 39.796L601.241 36.7692L598.687 26.5279L602.248 22.9206ZM614.476 36.1888L613.059 30.5049L608.754 29.1579L611.923 25.9475L616.76 27.4609L618.037 32.5815L614.476 36.1888ZM605.809 19.3134L615.483 22.3402L611.923 25.9475L602.248 22.9206L605.809 19.3134Z" fill="#327FB6"/>
      <path d="M627.236 16.6899L629.79 26.9312L639.464 29.9581L635.904 33.5653L626.229 30.5385L623.676 20.2972L627.236 16.6899ZM639.464 29.9581L638.047 24.2741L633.742 22.9272L636.911 19.7168L641.748 21.2302L643.025 26.3508L639.464 29.9581ZM630.797 13.0827L640.471 16.1095L636.911 19.7168L627.236 16.6899L630.797 13.0827Z" fill="#327FB6"/>
      <path d="M652.225 10.4597L654.778 20.701L664.453 23.7278L660.892 27.3351L651.218 24.3083L648.664 14.0669L652.225 10.4597ZM664.453 23.7278L663.036 18.0439L658.731 16.697L661.9 13.4865L666.737 15L668.014 20.1206L664.453 23.7278ZM655.786 6.85246L665.46 9.87929L661.9 13.4865L652.225 10.4597L655.786 6.85246Z" fill="#327FB6"/>
      <path d="M677.214 4.22924L679.767 14.4705L689.442 17.4974L685.881 21.1046L676.207 18.0778L673.653 7.83648L677.214 4.22924ZM689.442 17.4974L688.025 11.8135L683.719 10.4665L686.888 7.25607L691.726 8.76948L693.002 13.8901L689.442 17.4974ZM680.774 0.621995L690.449 3.64883L686.888 7.25607L677.214 4.22924L680.774 0.621995Z" fill="#327FB6"/>
      <path d="M702.203 -2.00123L704.756 8.24008L714.431 11.2669L710.87 14.8742L701.195 11.8473L698.642 1.60601L702.203 -2.00123ZM714.431 11.2669L713.013 5.58298L708.708 4.23604L711.877 1.0256L716.714 2.53901L717.991 7.65967L714.431 11.2669ZM705.763 -5.60847L715.438 -2.58164L711.877 1.0256L702.203 -2.00123L705.763 -5.60847Z" fill="#327FB6"/>
      <path d="M727.191 -8.2317L729.745 2.00961L739.419 5.03644L735.859 8.64368L726.184 5.61685L723.631 -4.62446L727.191 -8.2317ZM739.419 5.03644L738.002 -0.647487L733.697 -1.99443L736.866 -5.20487L741.703 -3.69146L742.98 1.4292L739.419 5.03644ZM730.752 -11.8389L740.426 -8.81211L736.866 -5.20487L727.191 -8.2317L730.752 -11.8389Z" fill="#327FB6"/>
      <path d="M752.181 -14.4619L754.734 -4.22061L764.409 -1.19378L760.848 2.41346L751.173 -0.613373L748.62 -10.8547L752.181 -14.4619ZM764.409 -1.19378L762.991 -6.87771L758.686 -8.22465L761.855 -11.4351L766.692 -9.92168L767.969 -4.80102L764.409 -1.19378ZM755.741 -18.0692L765.416 -15.0423L761.855 -11.4351L752.181 -14.4619L755.741 -18.0692Z" fill="#327FB6"/>
      <path d="M384.498 107.67L387.051 117.911L396.726 120.938L393.165 124.545L383.491 121.518L380.937 111.277L384.498 107.67ZM396.726 120.938L395.309 115.254L391.004 113.907L394.173 110.696L399.01 112.21L400.287 117.331L396.726 120.938ZM388.059 104.062L397.733 107.089L394.173 110.696L384.498 107.67L388.059 104.062Z" fill="#327FB6"/>
      <path d="M409.487 101.439L412.04 111.681L421.715 114.708L418.154 118.315L408.48 115.288L405.926 105.047L409.487 101.439ZM421.715 114.708L420.298 109.024L415.992 107.677L419.161 104.466L423.999 105.98L425.275 111.1L421.715 114.708ZM413.047 97.8322L422.722 100.859L419.161 104.466L409.487 101.439L413.047 97.8322Z" fill="#327FB6"/>
      <path d="M434.475 95.209L437.029 105.45L446.704 108.477L443.143 112.084L433.468 109.058L430.915 98.8162L434.475 95.209ZM446.704 108.477L445.286 102.793L440.981 101.446L444.15 98.2358L448.987 99.7492L450.264 104.87L446.704 108.477ZM438.036 91.6017L447.711 94.6286L444.15 98.2358L434.475 95.209L438.036 91.6017Z" fill="#327FB6"/>
      <path d="M459.464 88.9785L462.018 99.2198L471.692 102.247L468.132 105.854L458.457 102.827L455.904 92.5857L459.464 88.9785ZM471.692 102.247L470.275 96.5627L465.97 95.2158L469.139 92.0053L473.976 93.5187L475.253 98.6394L471.692 102.247ZM463.025 85.3713L472.699 88.3981L469.139 92.0053L459.464 88.9785L463.025 85.3713Z" fill="#327FB6"/>
      <path d="M484.453 82.748L487.006 92.9893L496.681 96.0162L493.12 99.6234L483.446 96.5966L480.892 86.3553L484.453 82.748ZM496.681 96.0162L495.264 90.3322L490.959 88.9853L494.128 85.7749L498.965 87.2883L500.242 92.4089L496.681 96.0162ZM488.014 79.1408L497.688 82.1676L494.128 85.7749L484.453 82.748L488.014 79.1408Z" fill="#327FB6"/>
      <path d="M509.442 76.5178L511.995 86.7591L521.67 89.786L518.109 93.3932L508.435 90.3664L505.881 80.1251L509.442 76.5178ZM521.67 89.786L520.253 84.102L515.947 82.7551L519.116 79.5446L523.954 81.0581L525.23 86.1787L521.67 89.786ZM513.002 72.9106L522.677 75.9374L519.116 79.5446L509.442 76.5178L513.002 72.9106Z" fill="#327FB6"/>
      <path d="M534.431 70.2873L536.984 80.5287L546.659 83.5555L543.098 87.1627L533.423 84.1359L530.87 73.8946L534.431 70.2873ZM546.659 83.5555L545.241 77.8716L540.936 76.5246L544.105 73.3142L548.942 74.8276L550.219 79.9482L546.659 83.5555ZM537.991 66.6801L547.666 69.7069L544.105 73.3142L534.431 70.2873L537.991 66.6801Z" fill="#327FB6"/>
      <path d="M559.419 64.0571L561.973 74.2984L571.647 77.3253L568.087 80.9325L558.412 77.9057L555.859 67.6644L559.419 64.0571ZM571.647 77.3253L570.23 71.6413L565.925 70.2944L569.094 67.0839L573.931 68.5974L575.208 73.718L571.647 77.3253ZM562.98 60.4499L572.655 63.4767L569.094 67.0839L559.419 64.0571L562.98 60.4499Z" fill="#327FB6"/>
      <path d="M584.408 57.8266L586.962 68.068L596.636 71.0948L593.076 74.702L583.401 71.6752L580.848 61.4339L584.408 57.8266ZM596.636 71.0948L595.219 65.4109L590.914 64.0639L594.083 60.8535L598.92 62.3669L600.197 67.4875L596.636 71.0948ZM587.969 54.2194L597.643 57.2462L594.083 60.8535L584.408 57.8266L587.969 54.2194Z" fill="#327FB6"/>
      <path d="M609.397 51.5962L611.95 61.8375L621.625 64.8643L618.064 68.4716L608.39 65.4447L605.836 55.2034L609.397 51.5962ZM621.625 64.8643L620.208 59.1804L615.903 57.8335L619.071 54.623L623.909 56.1364L625.185 61.2571L621.625 64.8643ZM612.957 47.9889L622.632 51.0158L619.071 54.623L609.397 51.5962L612.957 47.9889Z" fill="#327FB6"/>
      <path d="M634.386 45.3657L636.939 55.607L646.614 58.6339L643.053 62.2411L633.379 59.2143L630.825 48.973L634.386 45.3657ZM646.614 58.6339L645.197 52.9499L640.891 51.603L644.06 48.3925L648.898 49.906L650.174 55.0266L646.614 58.6339ZM637.946 41.7585L647.621 44.7853L644.06 48.3925L634.386 45.3657L637.946 41.7585Z" fill="#327FB6"/>
      <path d="M659.374 39.1355L661.928 49.3768L671.602 52.4036L668.042 56.0109L658.367 52.984L655.814 42.7427L659.374 39.1355ZM671.602 52.4036L670.185 46.7197L665.88 45.3728L669.049 42.1623L673.886 43.6757L675.163 48.7964L671.602 52.4036ZM662.935 35.5282L672.61 38.5551L669.049 42.1623L659.374 39.1355L662.935 35.5282Z" fill="#327FB6"/>
      <path d="M684.363 32.905L686.917 43.1463L696.591 46.1732L693.031 49.7804L683.356 46.7536L680.803 36.5123L684.363 32.905ZM696.591 46.1732L695.174 40.4892L690.869 39.1423L694.038 35.9318L698.875 37.4453L700.152 42.5659L696.591 46.1732ZM687.924 29.2978L697.598 32.3246L694.038 35.9318L684.363 32.905L687.924 29.2978Z" fill="#327FB6"/>
      <path d="M709.352 26.6745L711.905 36.9159L721.58 39.9427L718.019 43.5499L708.345 40.5231L705.791 30.2818L709.352 26.6745ZM721.58 39.9427L720.163 34.2588L715.858 32.9118L719.027 29.7014L723.864 31.2148L725.141 36.3355L721.58 39.9427ZM712.913 23.0673L722.587 26.0941L719.027 29.7014L709.352 26.6745L712.913 23.0673Z" fill="#327FB6"/>
      <path d="M734.341 20.4441L736.894 30.6854L746.569 33.7122L743.008 37.3195L733.334 34.2926L730.78 24.0513L734.341 20.4441ZM746.569 33.7122L745.152 28.0283L740.846 26.6814L744.015 23.4709L748.853 24.9843L750.129 30.105L746.569 33.7122ZM737.901 16.8368L747.576 19.8637L744.015 23.4709L734.341 20.4441L737.901 16.8368Z" fill="#327FB6"/>
      <path d="M759.33 14.2136L761.883 24.4549L771.558 27.4818L767.997 31.089L758.323 28.0622L755.769 17.8209L759.33 14.2136ZM771.558 27.4818L770.141 21.7978L765.836 20.4509L769.005 17.2404L773.842 18.7539L775.119 23.8745L771.558 27.4818ZM762.891 10.6064L772.565 13.6332L769.005 17.2404L759.33 14.2136L762.891 10.6064Z" fill="#327FB6"/>
      <path d="M784.318 7.98339L786.872 18.2247L796.546 21.2515L792.986 24.8588L783.311 21.8319L780.758 11.5906L784.318 7.98339ZM796.546 21.2515L795.129 15.5676L790.824 14.2207L793.993 11.0102L798.83 12.5236L800.107 17.6443L796.546 21.2515ZM787.879 4.37615L797.553 7.40298L793.993 11.0102L784.318 7.98339L787.879 4.37615Z" fill="#327FB6"/>
      <path d="M809.307 1.75292L811.86 11.9942L821.535 15.0211L817.974 18.6283L808.3 15.6015L805.746 5.36016L809.307 1.75292ZM821.535 15.0211L820.118 9.33713L815.813 7.99019L818.982 4.77975L823.819 6.29316L825.096 11.4138L821.535 15.0211ZM812.868 -1.85432L822.542 1.17251L818.982 4.77975L809.307 1.75292L812.868 -1.85432Z" fill="#327FB6"/>
      <path d="M834.296 -4.47731L836.85 5.76401L846.524 8.79084L842.964 12.3981L833.289 9.37125L830.736 -0.870066L834.296 -4.47731ZM846.524 8.79084L845.107 3.10691L840.802 1.75997L843.971 -1.45048L848.808 0.0629385L850.085 5.18359L846.524 8.79084ZM837.857 -8.08455L847.531 -5.05772L843.971 -1.45048L834.296 -4.47731L837.857 -8.08455Z" fill="#327FB6"/>
      <path d="M859.285 -10.7078L861.838 -0.466464L871.513 2.56037L867.953 6.16761L858.278 3.14078L855.724 -7.10054L859.285 -10.7078ZM871.513 2.56037L870.096 -3.12356L865.791 -4.4705L868.96 -7.68095L873.797 -6.16753L875.074 -1.04687L871.513 2.56037ZM862.846 -14.315L872.52 -11.2882L868.96 -7.68095L859.285 -10.7078L862.846 -14.315Z" fill="#327FB6"/>
      <path d="M884.274 -16.9382L886.827 -6.69693L896.502 -3.6701L892.941 -0.0628608L883.267 -3.08969L880.713 -13.331L884.274 -16.9382ZM896.502 -3.6701L895.085 -9.35403L890.78 -10.701L893.948 -13.9114L898.786 -12.398L900.062 -7.27734L896.502 -3.6701ZM887.834 -20.5455L897.509 -17.5187L893.948 -13.9114L884.274 -16.9382L887.834 -20.5455Z" fill="#327FB6"/>
      <path d="M516.592 105.193L519.145 115.435L528.82 118.461L525.259 122.069L515.585 119.042L513.031 108.801L516.592 105.193ZM528.82 118.461L527.403 112.778L523.097 111.431L526.266 108.22L531.104 109.734L532.38 114.854L528.82 118.461ZM520.152 101.586L529.827 104.613L526.266 108.22L516.592 105.193L520.152 101.586Z" fill="#327FB6"/>
      <path d="M541.58 98.9629L544.134 109.204L553.809 112.231L550.248 115.838L540.573 112.811L538.02 102.57L541.58 98.9629ZM553.809 112.231L552.391 106.547L548.086 105.2L551.255 101.99L556.092 103.503L557.369 108.624L553.809 112.231ZM545.141 95.3556L554.816 98.3825L551.255 101.99L541.58 98.9629L545.141 95.3556Z" fill="#327FB6"/>
      <path d="M566.569 92.7327L569.123 102.974L578.797 106.001L575.237 109.608L565.562 106.581L563.009 96.3399L566.569 92.7327ZM578.797 106.001L577.38 100.317L573.075 98.9699L576.244 95.7595L581.081 97.2729L582.358 102.394L578.797 106.001ZM570.13 89.1254L579.804 92.1522L576.244 95.7595L566.569 92.7327L570.13 89.1254Z" fill="#327FB6"/>
      <path d="M591.558 86.5022L594.111 96.7435L603.786 99.7703L600.225 103.378L590.551 100.351L587.997 90.1094L591.558 86.5022ZM603.786 99.7703L602.369 94.0864L598.064 92.7395L601.233 89.529L606.07 91.0424L607.347 96.1631L603.786 99.7703ZM595.119 82.8949L604.793 85.9218L601.233 89.529L591.558 86.5022L595.119 82.8949Z" fill="#327FB6"/>
      <path d="M616.547 80.2717L619.101 90.513L628.775 93.5399L625.215 97.1471L615.54 94.1203L612.987 83.879L616.547 80.2717ZM628.775 93.5399L627.358 87.8559L623.053 86.509L626.222 83.2985L631.059 84.812L632.336 89.9326L628.775 93.5399ZM620.108 76.6645L629.782 79.6913L626.222 83.2985L616.547 80.2717L620.108 76.6645Z" fill="#327FB6"/>
      <path d="M641.536 74.0412L644.089 84.2826L653.764 87.3094L650.203 90.9166L640.528 87.8898L637.975 77.6485L641.536 74.0412ZM653.764 87.3094L652.346 81.6255L648.041 80.2785L651.21 77.0681L656.047 78.5815L657.324 83.7021L653.764 87.3094ZM645.096 70.434L654.771 73.4608L651.21 77.0681L641.536 74.0412L645.096 70.434Z" fill="#327FB6"/>
      <path d="M666.524 67.811L669.078 78.0523L678.752 81.0792L675.192 84.6864L665.517 81.6596L662.964 71.4183L666.524 67.811ZM678.752 81.0792L677.335 75.3952L673.03 74.0483L676.199 70.8379L681.036 72.3513L682.313 77.4719L678.752 81.0792ZM670.085 64.2038L679.759 67.2306L676.199 70.8379L666.524 67.811L670.085 64.2038Z" fill="#327FB6"/>
      <path d="M691.513 61.5806L694.067 71.8219L703.741 74.8487L700.181 78.4559L690.506 75.4291L687.953 65.1878L691.513 61.5806ZM703.741 74.8487L702.324 69.1648L698.019 67.8178L701.188 64.6074L706.025 66.1208L707.302 71.2415L703.741 74.8487ZM695.074 57.9733L704.748 61.0001L701.188 64.6074L691.513 61.5806L695.074 57.9733Z" fill="#327FB6"/>
      <path d="M716.502 55.3501L719.055 65.5914L728.73 68.6182L725.169 72.2255L715.495 69.1986L712.941 58.9573L716.502 55.3501ZM728.73 68.6182L727.313 62.9343L723.008 61.5874L726.176 58.3769L731.014 59.8903L732.29 65.011L728.73 68.6182ZM720.062 51.7428L729.737 54.7697L726.176 58.3769L716.502 55.3501L720.062 51.7428Z" fill="#327FB6"/>
      <path d="M741.491 49.1196L744.044 59.3609L753.719 62.3878L750.158 65.995L740.483 62.9682L737.93 52.7269L741.491 49.1196ZM753.719 62.3878L752.302 56.7038L747.996 55.3569L751.165 52.1464L756.003 53.6599L757.279 58.7805L753.719 62.3878ZM745.051 45.5124L754.726 48.5392L751.165 52.1464L741.491 49.1196L745.051 45.5124Z" fill="#327FB6"/>
      <path d="M766.48 42.8894L769.033 53.1307L778.708 56.1575L775.147 59.7648L765.473 56.7379L762.919 46.4966L766.48 42.8894ZM778.708 56.1575L777.291 50.4736L772.986 49.1267L776.154 45.9162L780.992 47.4296L782.268 52.5503L778.708 56.1575ZM770.04 39.2822L779.715 42.309L776.154 45.9162L766.48 42.8894L770.04 39.2822Z" fill="#327FB6"/>
      <path d="M791.468 36.6589L794.022 46.9002L803.696 49.9271L800.136 53.5343L790.461 50.5075L787.908 40.2662L791.468 36.6589ZM803.696 49.9271L802.279 44.2431L797.974 42.8962L801.143 39.6858L805.98 41.1992L807.257 46.3198L803.696 49.9271ZM795.029 33.0517L804.703 36.0785L801.143 39.6858L791.468 36.6589L795.029 33.0517Z" fill="#327FB6"/>
      <path d="M816.457 30.4285L819.01 40.6698L828.685 43.6966L825.124 47.3038L815.45 44.277L812.896 34.0357L816.457 30.4285ZM828.685 43.6966L827.268 38.0127L822.963 36.6657L826.132 33.4553L830.969 34.9687L832.246 40.0894L828.685 43.6966ZM820.018 26.8212L829.692 29.848L826.132 33.4553L816.457 30.4285L820.018 26.8212Z" fill="#327FB6"/>
      <path d="M841.446 24.1982L844 34.4395L853.674 37.4664L850.114 41.0736L840.439 38.0468L837.886 27.8055L841.446 24.1982ZM853.674 37.4664L852.257 31.7824L847.952 30.4355L851.121 27.2251L855.958 28.7385L857.235 33.8591L853.674 37.4664ZM845.007 20.591L854.681 23.6178L851.121 27.2251L841.446 24.1982L845.007 20.591Z" fill="#327FB6"/>
      <path d="M866.435 17.9678L868.988 28.2091L878.663 31.2359L875.102 34.8431L865.428 31.8163L862.874 21.575L866.435 17.9678ZM878.663 31.2359L877.246 25.552L872.941 24.205L876.11 20.9946L880.947 22.508L882.224 27.6287L878.663 31.2359ZM869.996 14.3605L879.67 17.3874L876.11 20.9946L866.435 17.9678L869.996 14.3605Z" fill="#327FB6"/>
      <path d="M891.424 11.7373L893.977 21.9786L903.652 25.0054L900.091 28.6127L890.417 25.5858L887.863 15.3445L891.424 11.7373ZM903.652 25.0054L902.235 19.3215L897.929 17.9746L901.098 14.7641L905.936 16.2775L907.212 21.3982L903.652 25.0054ZM894.984 8.13005L904.659 11.1569L901.098 14.7641L891.424 11.7373L894.984 8.13005Z" fill="#327FB6"/>
      <path d="M916.412 5.50682L918.966 15.7481L928.641 18.775L925.08 22.3822L915.405 19.3554L912.852 9.11406L916.412 5.50682ZM928.641 18.775L927.223 13.091L922.918 11.7441L926.087 8.53365L930.924 10.0471L932.201 15.1677L928.641 18.775ZM919.973 1.89958L929.648 4.92641L926.087 8.53365L916.412 5.50682L919.973 1.89958Z" fill="#327FB6"/>
      <path d="M941.401 -0.723401L943.955 9.51791L953.629 12.5447L950.069 16.152L940.394 13.1252L937.841 2.88384L941.401 -0.723401ZM953.629 12.5447L952.212 6.86081L947.907 5.51387L951.076 2.30343L955.913 3.81684L957.19 8.9375L953.629 12.5447ZM944.962 -4.33064L954.636 -1.30381L951.076 2.30343L941.401 -0.723401L944.962 -4.33064Z" fill="#327FB6"/>
      <path d="M966.39 -6.95387L968.943 3.28744L978.618 6.31427L975.058 9.92151L965.383 6.89468L962.829 -3.34663L966.39 -6.95387ZM978.618 6.31427L977.201 0.630345L972.896 -0.716594L976.065 -3.92704L980.902 -2.41362L982.179 2.70703L978.618 6.31427ZM969.951 -10.5611L979.625 -7.53428L976.065 -3.92704L966.39 -6.95387L969.951 -10.5611Z" fill="#327FB6"/>
      <path d="M991.379 -13.1843L993.932 -2.94303L1003.61 0.0838055L1000.05 3.69105L990.372 0.664215L987.818 -9.5771L991.379 -13.1843ZM1003.61 0.0838055L1002.19 -5.60012L997.884 -6.94706L1001.05 -10.1575L1005.89 -8.64409L1007.17 -3.52344L1003.61 0.0838055ZM994.939 -16.7916L1004.61 -13.7647L1001.05 -10.1575L991.379 -13.1843L994.939 -16.7916Z" fill="#327FB6"/>
      <path d="M648.685 102.717L651.238 112.958L660.913 115.985L657.352 119.592L647.678 116.565L645.124 106.324L648.685 102.717ZM660.913 115.985L659.496 110.301L655.191 108.954L658.36 105.744L663.197 107.257L664.474 112.378L660.913 115.985ZM652.246 99.1095L661.92 102.136L658.36 105.744L648.685 102.717L652.246 99.1095Z" fill="#327FB6"/>
      <path d="M673.674 96.4866L676.227 106.728L685.902 109.755L682.341 113.362L672.667 110.335L670.113 100.094L673.674 96.4866ZM685.902 109.755L684.485 104.071L680.179 102.724L683.348 99.5134L688.186 101.027L689.462 106.147L685.902 109.755ZM677.234 92.8793L686.909 95.9061L683.348 99.5134L673.674 96.4866L677.234 92.8793Z" fill="#327FB6"/>
      <path d="M698.662 90.2561L701.216 100.497L710.891 103.524L707.33 107.131L697.655 104.105L695.102 93.8633L698.662 90.2561ZM710.891 103.524L709.473 97.8403L705.168 96.4934L708.337 93.2829L713.174 94.7963L714.451 99.917L710.891 103.524ZM702.223 86.6489L711.898 89.6757L708.337 93.2829L698.662 90.2561L702.223 86.6489Z" fill="#327FB6"/>
      <path d="M723.651 84.0256L726.205 94.2669L735.879 97.2938L732.319 100.901L722.644 97.8742L720.091 87.6329L723.651 84.0256ZM735.879 97.2938L734.462 91.6098L730.157 90.2629L733.326 87.0525L738.163 88.5659L739.44 93.6865L735.879 97.2938ZM727.212 80.4184L736.886 83.4452L733.326 87.0525L723.651 84.0256L727.212 80.4184Z" fill="#327FB6"/>
      <path d="M748.64 77.7952L751.193 88.0365L760.868 91.0633L757.308 94.6705L747.633 91.6437L745.079 81.4024L748.64 77.7952ZM760.868 91.0633L759.451 85.3794L755.146 84.0324L758.315 80.822L763.152 82.3354L764.429 87.4561L760.868 91.0633ZM752.201 74.1879L761.875 77.2147L758.315 80.822L748.64 77.7952L752.201 74.1879Z" fill="#327FB6"/>
      <path d="M773.629 71.5647L776.183 81.806L785.857 84.8328L782.297 88.4401L772.622 85.4132L770.069 75.1719L773.629 71.5647ZM785.857 84.8328L784.44 79.1489L780.135 77.802L783.304 74.5915L788.141 76.1049L789.418 81.2256L785.857 84.8328ZM777.19 67.9574L786.864 70.9843L783.304 74.5915L773.629 71.5647L777.19 67.9574Z" fill="#327FB6"/>
      <path d="M798.618 65.3345L801.171 75.5758L810.846 78.6026L807.285 82.2098L797.61 79.183L795.057 68.9417L798.618 65.3345ZM810.846 78.6026L809.428 72.9187L805.123 71.5717L808.292 68.3613L813.129 69.8747L814.406 74.9954L810.846 78.6026ZM802.178 61.7272L811.853 64.754L808.292 68.3613L798.618 65.3345L802.178 61.7272Z" fill="#327FB6"/>
      <path d="M823.606 59.104L826.16 69.3453L835.834 72.3721L832.274 75.9794L822.599 72.9525L820.046 62.7112L823.606 59.104ZM835.834 72.3721L834.417 66.6882L830.112 65.3413L833.281 62.1308L838.118 63.6442L839.395 68.7649L835.834 72.3721ZM827.167 55.4968L836.842 58.5236L833.281 62.1308L823.606 59.104L827.167 55.4968Z" fill="#327FB6"/>
      <path d="M848.596 52.8738L851.149 63.1151L860.824 66.1419L857.263 69.7492L847.588 66.7223L845.035 56.481L848.596 52.8738ZM860.824 66.1419L859.406 60.458L855.101 59.111L858.27 55.9006L863.107 57.414L864.384 62.5347L860.824 66.1419ZM852.156 49.2665L861.831 52.2934L858.27 55.9006L848.596 52.8738L852.156 49.2665Z" fill="#327FB6"/>
      <path d="M873.584 46.6433L876.138 56.8846L885.812 59.9114L882.252 63.5187L872.577 60.4919L870.024 50.2505L873.584 46.6433ZM885.812 59.9114L884.395 54.2275L880.09 52.8806L883.259 49.6701L888.096 51.1835L889.373 56.3042L885.812 59.9114ZM877.145 43.0361L886.82 46.0629L883.259 49.6701L873.584 46.6433L877.145 43.0361Z" fill="#327FB6"/>
      <path d="M898.573 40.4128L901.127 50.6541L910.801 53.681L907.241 57.2882L897.566 54.2614L895.013 44.0201L898.573 40.4128ZM910.801 53.681L909.384 47.997L905.079 46.6501L908.248 43.4397L913.085 44.9531L914.362 50.0737L910.801 53.681ZM902.134 36.8056L911.808 39.8324L908.248 43.4397L898.573 40.4128L902.134 36.8056Z" fill="#327FB6"/>
      <path d="M923.562 34.1824L926.115 44.4237L935.79 47.4505L932.229 51.0577L922.555 48.0309L920.001 37.7896L923.562 34.1824ZM935.79 47.4505L934.373 41.7666L930.068 40.4196L933.237 37.2092L938.074 38.7226L939.351 43.8433L935.79 47.4505ZM927.122 30.5751L936.797 33.602L933.237 37.2092L923.562 34.1824L927.122 30.5751Z" fill="#327FB6"/>
      <path d="M948.551 27.9521L951.104 38.1934L960.779 41.2203L957.218 44.8275L947.544 41.8007L944.99 31.5594L948.551 27.9521ZM960.779 41.2203L959.362 35.5364L955.056 34.1894L958.225 30.979L963.063 32.4924L964.339 37.613L960.779 41.2203ZM952.111 24.3449L961.786 27.3717L958.225 30.979L948.551 27.9521L952.111 24.3449Z" fill="#327FB6"/>
      <path d="M973.539 21.7217L976.093 31.963L985.767 34.9898L982.207 38.5971L972.532 35.5702L969.979 25.3289L973.539 21.7217ZM985.767 34.9898L984.35 29.3059L980.045 27.9589L983.214 24.7485L988.051 26.2619L989.328 31.3826L985.767 34.9898ZM977.1 18.1144L986.775 21.1413L983.214 24.7485L973.539 21.7217L977.1 18.1144Z" fill="#327FB6"/>
      <path d="M998.528 15.4912L1001.08 25.7325L1010.76 28.7593L1007.2 32.3666L997.521 29.3398L994.968 19.0984L998.528 15.4912ZM1010.76 28.7593L1009.34 23.0754L1005.03 21.7285L1008.2 18.518L1013.04 20.0314L1014.32 25.1521L1010.76 28.7593ZM1002.09 11.884L1011.76 14.9108L1008.2 18.518L998.528 15.4912L1002.09 11.884Z" fill="#327FB6"/>
      <path d="M1023.52 9.26073L1026.07 19.502L1035.75 22.5289L1032.18 26.1361L1022.51 23.1093L1019.96 12.868L1023.52 9.26073ZM1035.75 22.5289L1034.33 16.8449L1030.02 15.498L1033.19 12.2876L1038.03 13.801L1039.31 18.9216L1035.75 22.5289ZM1027.08 5.65349L1036.75 8.68032L1033.19 12.2876L1023.52 9.26073L1027.08 5.65349Z" fill="#327FB6"/>
      <path d="M1048.51 3.03051L1051.06 13.2718L1060.73 16.2986L1057.17 19.9059L1047.5 16.8791L1044.95 6.63775L1048.51 3.03051ZM1060.73 16.2986L1059.32 10.6147L1055.01 9.26778L1058.18 6.05734L1063.02 7.57075L1064.29 12.6914L1060.73 16.2986ZM1052.07 -0.576736L1061.74 2.45009L1058.18 6.05734L1048.51 3.03051L1052.07 -0.576736Z" fill="#327FB6"/>
      <path d="M1073.49 -3.19996L1076.05 7.04135L1085.72 10.0682L1082.16 13.6754L1072.49 10.6486L1069.93 0.407277L1073.49 -3.19996ZM1085.72 10.0682L1084.31 4.38425L1080 3.03731L1083.17 -0.173133L1088.01 1.34028L1089.28 6.46094L1085.72 10.0682ZM1077.06 -6.8072L1086.73 -3.78037L1083.17 -0.173133L1073.49 -3.19996L1077.06 -6.8072Z" fill="#327FB6"/>
      <path d="M1098.48 -9.43043L1101.04 0.81088L1110.71 3.83771L1107.15 7.44495L1097.48 4.41812L1094.92 -5.82319L1098.48 -9.43043ZM1110.71 3.83771L1109.29 -1.84622L1104.99 -3.19316L1108.16 -6.4036L1113 -4.89019L1114.27 0.23047L1110.71 3.83771ZM1102.04 -13.0377L1111.72 -10.0108L1108.16 -6.4036L1098.48 -9.43043L1102.04 -13.0377Z" fill="#327FB6"/>
      <path d="M1123.47 -15.6611L1126.03 -5.41983L1135.7 -2.393L1132.14 1.21424L1122.47 -1.81259L1119.91 -12.0539L1123.47 -15.6611ZM1135.7 -2.393L1134.28 -8.07693L1129.98 -9.42387L1133.15 -12.6343L1137.98 -11.1209L1139.26 -6.00024L1135.7 -2.393ZM1127.03 -19.2684L1136.71 -16.2416L1133.15 -12.6343L1123.47 -15.6611L1127.03 -19.2684Z" fill="#327FB6"/>
      <path d="M755.79 106.471L758.343 116.712L768.018 119.739L764.457 123.346L754.783 120.319L752.229 110.078L755.79 106.471ZM768.018 119.739L766.601 114.055L762.296 112.708L765.465 109.498L770.302 111.011L771.579 116.132L768.018 119.739ZM759.351 102.864L769.025 105.891L765.465 109.498L755.79 106.471L759.351 102.864Z" fill="#327FB6"/>
      <path d="M780.779 100.24L783.333 110.482L793.007 113.509L789.447 117.116L779.772 114.089L777.219 103.848L780.779 100.24ZM793.007 113.509L791.59 107.825L787.285 106.478L790.454 103.267L795.291 104.781L796.568 109.901L793.007 113.509ZM784.34 96.6332L794.014 99.6601L790.454 103.267L780.779 100.24L784.34 96.6332Z" fill="#327FB6"/>
      <path d="M805.767 94.0102L808.321 104.252L817.996 107.278L814.435 110.886L804.76 107.859L802.207 97.6175L805.767 94.0102ZM817.996 107.278L816.578 101.594L812.273 100.248L815.442 97.0371L820.279 98.5505L821.556 103.671L817.996 107.278ZM809.328 90.403L819.003 93.4298L815.442 97.0371L805.767 94.0102L809.328 90.403Z" fill="#327FB6"/>
      <path d="M830.756 87.7798L833.31 98.0211L842.984 101.048L839.424 104.655L829.749 101.628L827.196 91.387L830.756 87.7798ZM842.984 101.048L841.567 95.364L837.262 94.017L840.431 90.8066L845.268 92.32L846.545 97.4407L842.984 101.048ZM834.317 84.1725L843.991 87.1994L840.431 90.8066L830.756 87.7798L834.317 84.1725Z" fill="#327FB6"/>
      <path d="M855.746 81.5495L858.299 91.7909L867.974 94.8177L864.413 98.4249L854.738 95.3981L852.185 85.1568L855.746 81.5495ZM867.974 94.8177L866.556 89.1338L862.251 87.7868L865.42 84.5764L870.257 86.0898L871.534 91.2105L867.974 94.8177ZM859.306 77.9423L868.981 80.9691L865.42 84.5764L855.746 81.5495L859.306 77.9423Z" fill="#327FB6"/>
      <path d="M880.734 75.3191L883.288 85.5604L892.962 88.5872L889.402 92.1945L879.727 89.1676L877.174 78.9263L880.734 75.3191ZM892.962 88.5872L891.545 82.9033L887.24 81.5564L890.409 78.3459L895.246 79.8593L896.523 84.98L892.962 88.5872ZM884.295 71.7118L893.969 74.7387L890.409 78.3459L880.734 75.3191L884.295 71.7118Z" fill="#327FB6"/>
      <path d="M905.723 69.0886L908.276 79.3299L917.951 82.3568L914.391 85.964L904.716 82.9372L902.162 72.6959L905.723 69.0886ZM917.951 82.3568L916.534 76.6728L912.229 75.3259L915.398 72.1154L920.235 73.6289L921.512 78.7495L917.951 82.3568ZM909.284 65.4814L918.958 68.5082L915.398 72.1154L905.723 69.0886L909.284 65.4814Z" fill="#327FB6"/>
      <path d="M930.712 62.8581L933.265 73.0995L942.94 76.1263L939.379 79.7335L929.705 76.7067L927.151 66.4654L930.712 62.8581ZM942.94 76.1263L941.523 70.4424L937.217 69.0954L940.386 65.885L945.224 67.3984L946.5 72.519L942.94 76.1263ZM934.272 59.2509L943.947 62.2777L940.386 65.885L930.712 62.8581L934.272 59.2509Z" fill="#327FB6"/>
      <path d="M955.701 56.6279L958.254 66.8692L967.929 69.8961L964.368 73.5033L954.693 70.4765L952.14 60.2352L955.701 56.6279ZM967.929 69.8961L966.511 64.2121L962.206 62.8652L965.375 59.6547L970.212 61.1682L971.489 66.2888L967.929 69.8961ZM959.261 53.0207L968.936 56.0475L965.375 59.6547L955.701 56.6279L959.261 53.0207Z" fill="#327FB6"/>
      <path d="M980.689 50.3974L983.243 60.6388L992.917 63.6656L989.357 67.2728L979.682 64.246L977.129 54.0047L980.689 50.3974ZM992.917 63.6656L991.5 57.9817L987.195 56.6347L990.364 53.4243L995.201 54.9377L996.478 60.0584L992.917 63.6656ZM984.25 46.7902L993.925 49.817L990.364 53.4243L980.689 50.3974L984.25 46.7902Z" fill="#327FB6"/>
      <path d="M1005.68 44.167L1008.23 54.4083L1017.91 57.4351L1014.35 61.0424L1004.67 58.0155L1002.12 47.7742L1005.68 44.167ZM1017.91 57.4351L1016.49 51.7512L1012.18 50.4043L1015.35 47.1938L1020.19 48.7072L1021.47 53.8279L1017.91 57.4351ZM1009.24 40.5597L1018.91 43.5866L1015.35 47.1938L1005.68 44.167L1009.24 40.5597Z" fill="#327FB6"/>
      <path d="M1030.67 37.9365L1033.22 48.1778L1042.89 51.2047L1039.33 54.8119L1029.66 51.7851L1027.11 41.5438L1030.67 37.9365ZM1042.89 51.2047L1041.48 45.5207L1037.17 44.1738L1040.34 40.9633L1045.18 42.4768L1046.46 47.5974L1042.89 51.2047ZM1034.23 34.3293L1043.9 37.3561L1040.34 40.9633L1030.67 37.9365L1034.23 34.3293Z" fill="#327FB6"/>
      <path d="M1055.66 31.7063L1058.21 41.9476L1067.88 44.9744L1064.32 48.5817L1054.65 45.5548L1052.1 35.3135L1055.66 31.7063ZM1067.88 44.9744L1066.47 39.2905L1062.16 37.9436L1065.33 34.7331L1070.17 36.2465L1071.44 41.3672L1067.88 44.9744ZM1059.22 28.099L1068.89 31.1259L1065.33 34.7331L1055.66 31.7063L1059.22 28.099Z" fill="#327FB6"/>
      <path d="M1080.64 25.4758L1083.2 35.7171L1092.87 38.744L1089.31 42.3512L1079.64 39.3244L1077.08 29.0831L1080.64 25.4758ZM1092.87 38.744L1091.46 33.06L1087.15 31.7131L1090.32 28.5026L1095.16 30.0161L1096.43 35.1367L1092.87 38.744ZM1084.21 21.8686L1093.88 24.8954L1090.32 28.5026L1080.64 25.4758L1084.21 21.8686Z" fill="#327FB6"/>
      <path d="M1105.63 19.2453L1108.19 29.4867L1117.86 32.5135L1114.3 36.1207L1104.63 33.0939L1102.07 22.8526L1105.63 19.2453ZM1117.86 32.5135L1116.44 26.8296L1112.14 25.4826L1115.31 22.2722L1120.15 23.7856L1121.42 28.9063L1117.86 32.5135ZM1109.19 15.6381L1118.87 18.6649L1115.31 22.2722L1105.63 19.2453L1109.19 15.6381Z" fill="#327FB6"/>
      <path d="M1130.62 13.0149L1133.18 23.2562L1142.85 26.283L1139.29 29.8903L1129.62 26.8634L1127.06 16.6221L1130.62 13.0149ZM1142.85 26.283L1141.43 20.5991L1137.13 19.2522L1140.3 16.0417L1145.13 17.5551L1146.41 22.6758L1142.85 26.283ZM1134.18 9.40764L1143.86 12.4345L1140.3 16.0417L1130.62 13.0149L1134.18 9.40764Z" fill="#327FB6"/>
      <path d="M1155.61 6.78441L1158.16 17.0257L1167.84 20.0526L1164.28 23.6598L1154.6 20.633L1152.05 10.3917L1155.61 6.78441ZM1167.84 20.0526L1166.42 14.3686L1162.12 13.0217L1165.29 9.81124L1170.12 11.3247L1171.4 16.4453L1167.84 20.0526ZM1159.17 3.17717L1168.85 6.204L1165.29 9.81124L1155.61 6.78441L1159.17 3.17717Z" fill="#327FB6"/>
      <path d="M1180.6 0.554187L1183.15 10.7955L1192.83 13.8223L1189.27 17.4296L1179.59 14.4027L1177.04 4.16143L1180.6 0.554187ZM1192.83 13.8223L1191.41 8.1384L1187.11 6.79146L1190.27 3.58102L1195.11 5.09443L1196.39 10.2151L1192.83 13.8223ZM1184.16 -3.05305L1193.83 -0.0262236L1190.27 3.58102L1180.6 0.554187L1184.16 -3.05305Z" fill="#327FB6"/>
      <path d="M1205.59 -5.67628L1208.14 4.56503L1217.82 7.59186L1214.26 11.1991L1204.58 8.17227L1202.03 -2.06904L1205.59 -5.67628ZM1217.82 7.59186L1216.4 1.90793L1212.09 0.560994L1215.26 -2.64945L1220.1 -1.13604L1221.38 3.98462L1217.82 7.59186ZM1209.15 -9.28352L1218.82 -6.25669L1215.26 -2.64945L1205.59 -5.67628L1209.15 -9.28352Z" fill="#327FB6"/>
      <path d="M1230.58 -11.9068L1233.13 -1.66544L1242.81 1.36139L1239.24 4.96863L1229.57 1.9418L1227.02 -8.29951L1230.58 -11.9068ZM1242.81 1.36139L1241.39 -4.32254L1237.08 -5.66948L1240.25 -8.87992L1245.09 -7.3665L1246.37 -2.24585L1242.81 1.36139ZM1234.14 -15.514L1243.81 -12.4872L1240.25 -8.87992L1230.58 -11.9068L1234.14 -15.514Z" fill="#327FB6"/>
      <path d="M1255.57 -18.137L1258.12 -7.89566L1267.79 -4.86883L1264.23 -1.26159L1254.56 -4.28842L1252.01 -14.5297L1255.57 -18.137ZM1267.79 -4.86883L1266.38 -10.5528L1262.07 -11.8997L1265.24 -15.1101L1270.08 -13.5967L1271.35 -8.47607L1267.79 -4.86883ZM1259.13 -21.7442L1268.8 -18.7174L1265.24 -15.1101L1255.57 -18.137L1259.13 -21.7442Z" fill="#327FB6"/>
      <path d="M887.884 103.995L890.437 114.236L900.112 117.263L896.551 120.87L886.877 117.843L884.323 107.602L887.884 103.995ZM900.112 117.263L898.695 111.579L894.389 110.232L897.558 107.022L902.396 108.535L903.672 113.656L900.112 117.263ZM891.444 100.388L901.119 103.414L897.558 107.022L887.884 103.995L891.444 100.388Z" fill="#327FB6"/>
      <path d="M912.872 97.7644L915.426 108.006L925.101 111.033L921.54 114.64L911.865 111.613L909.312 101.372L912.872 97.7644ZM925.101 111.033L923.683 105.349L919.378 104.002L922.547 100.791L927.384 102.305L928.661 107.425L925.101 111.033ZM916.433 94.1572L926.108 97.184L922.547 100.791L912.872 97.7644L916.433 94.1572Z" fill="#327FB6"/>
      <path d="M937.861 91.5339L940.415 101.775L950.089 104.802L946.529 108.409L936.854 105.382L934.301 95.1412L937.861 91.5339ZM950.089 104.802L948.672 99.1181L944.367 97.7712L947.536 94.5608L952.373 96.0742L953.65 101.195L950.089 104.802ZM941.422 87.9267L951.096 90.9535L947.536 94.5608L937.861 91.5339L941.422 87.9267Z" fill="#327FB6"/>
      <path d="M962.85 85.3037L965.403 95.545L975.078 98.5718L971.517 102.179L961.843 99.1523L959.289 88.9109L962.85 85.3037ZM975.078 98.5718L973.661 92.8879L969.356 91.541L972.525 88.3305L977.362 89.8439L978.639 94.9646L975.078 98.5718ZM966.411 81.6965L976.085 84.7233L972.525 88.3305L962.85 85.3037L966.411 81.6965Z" fill="#327FB6"/>
      <path d="M987.839 79.0732L990.392 89.3145L1000.07 92.3414L996.506 95.9486L986.832 92.9218L984.278 82.6805L987.839 79.0732ZM1000.07 92.3414L998.65 86.6574L994.344 85.3105L997.513 82.1001L1002.35 83.6135L1003.63 88.7341L1000.07 92.3414ZM991.399 75.466L1001.07 78.4928L997.513 82.1001L987.839 79.0732L991.399 75.466Z" fill="#327FB6"/>
      <path d="M1012.83 72.8428L1015.38 83.0841L1025.06 86.1109L1021.5 89.7181L1011.82 86.6913L1009.27 76.45L1012.83 72.8428ZM1025.06 86.1109L1023.64 80.427L1019.33 79.08L1022.5 75.8696L1027.34 77.383L1028.62 82.5037L1025.06 86.1109ZM1016.39 69.2355L1026.06 72.2624L1022.5 75.8696L1012.83 72.8428L1016.39 69.2355Z" fill="#327FB6"/>
      <path d="M1037.82 66.6123L1040.37 76.8536L1050.04 79.8804L1046.48 83.4877L1036.81 80.4608L1034.26 70.2195L1037.82 66.6123ZM1050.04 79.8804L1048.63 74.1965L1044.32 72.8496L1047.49 69.6391L1052.33 71.1525L1053.6 76.2732L1050.04 79.8804ZM1041.38 63.0051L1051.05 66.0319L1047.49 69.6391L1037.82 66.6123L1041.38 63.0051Z" fill="#327FB6"/>
      <path d="M1062.81 60.3821L1065.36 70.6234L1075.03 73.6502L1071.47 77.2575L1061.8 74.2306L1059.24 63.9893L1062.81 60.3821ZM1075.03 73.6502L1073.62 67.9663L1069.31 66.6193L1072.48 63.4089L1077.32 64.9223L1078.59 70.043L1075.03 73.6502ZM1066.37 56.7748L1076.04 59.8017L1072.48 63.4089L1062.81 60.3821L1066.37 56.7748Z" fill="#327FB6"/>
      <path d="M1087.79 54.1516L1090.35 64.3929L1100.02 67.4197L1096.46 71.027L1086.79 68.0002L1084.23 57.7588L1087.79 54.1516ZM1100.02 67.4197L1098.6 61.7358L1094.3 60.3889L1097.47 57.1784L1102.31 58.6918L1103.58 63.8125L1100.02 67.4197ZM1091.35 50.5444L1101.03 53.5712L1097.47 57.1784L1087.79 54.1516L1091.35 50.5444Z" fill="#327FB6"/>
      <path d="M1112.78 47.9211L1115.34 58.1624L1125.01 61.1893L1121.45 64.7965L1111.78 61.7697L1109.22 51.5284L1112.78 47.9211ZM1125.01 61.1893L1123.59 55.5053L1119.29 54.1584L1122.46 50.948L1127.29 52.4614L1128.57 57.582L1125.01 61.1893ZM1116.34 44.3139L1126.02 47.3407L1122.46 50.948L1112.78 47.9211L1116.34 44.3139Z" fill="#327FB6"/>
      <path d="M1137.77 41.6909L1140.33 51.9322L1150 54.959L1146.44 58.5663L1136.76 55.5395L1134.21 45.2981L1137.77 41.6909ZM1150 54.959L1148.58 49.2751L1144.28 47.9282L1147.45 44.7177L1152.28 46.2312L1153.56 51.3518L1150 54.959ZM1141.33 38.0837L1151.01 41.1105L1147.45 44.7177L1137.77 41.6909L1141.33 38.0837Z" fill="#327FB6"/>
      <path d="M1162.76 35.4604L1165.31 45.7017L1174.99 48.7286L1171.43 52.3358L1161.75 49.309L1159.2 39.0677L1162.76 35.4604ZM1174.99 48.7286L1173.57 43.0447L1169.27 41.6977L1172.44 38.4873L1177.27 40.0007L1178.55 45.1213L1174.99 48.7286ZM1166.32 31.8532L1176 34.88L1172.44 38.4873L1162.76 35.4604L1166.32 31.8532Z" fill="#327FB6"/>
      <path d="M1187.75 29.23L1190.3 39.4713L1199.98 42.4981L1196.42 46.1054L1186.74 43.0785L1184.19 32.8372L1187.75 29.23ZM1199.98 42.4981L1198.56 36.8142L1194.25 35.4672L1197.42 32.2568L1202.26 33.7702L1203.54 38.8909L1199.98 42.4981ZM1191.31 25.6227L1200.98 28.6496L1197.42 32.2568L1187.75 29.23L1191.31 25.6227Z" fill="#327FB6"/>
      <path d="M1212.74 22.9995L1215.29 33.2408L1224.97 36.2676L1221.41 39.8749L1211.73 36.8481L1209.18 26.6067L1212.74 22.9995ZM1224.97 36.2676L1223.55 30.5837L1219.24 29.2368L1222.41 26.0263L1227.25 27.5397L1228.53 32.6604L1224.97 36.2676ZM1216.3 19.3923L1225.97 22.4191L1222.41 26.0263L1212.74 22.9995L1216.3 19.3923Z" fill="#327FB6"/>
      <path d="M1237.73 16.769L1240.28 27.0103L1249.95 30.0372L1246.39 33.6444L1236.72 30.6176L1234.17 20.3763L1237.73 16.769ZM1249.95 30.0372L1248.54 24.3532L1244.23 23.0063L1247.4 19.7959L1252.24 21.3093L1253.52 26.4299L1249.95 30.0372ZM1241.29 13.1618L1250.96 16.1886L1247.4 19.7959L1237.73 16.769L1241.29 13.1618Z" fill="#327FB6"/>
      <path d="M1262.72 10.5388L1265.27 20.7801L1274.94 23.807L1271.38 27.4142L1261.71 24.3874L1259.15 14.146L1262.72 10.5388ZM1274.94 23.807L1273.53 18.123L1269.22 16.7761L1272.39 13.5656L1277.23 15.0791L1278.5 20.1997L1274.94 23.807ZM1266.28 6.93156L1275.95 9.9584L1272.39 13.5656L1262.72 10.5388L1266.28 6.93156Z" fill="#327FB6"/>
      <path d="M994.989 107.749L997.542 117.99L1007.22 121.017L1003.66 124.624L993.982 121.597L991.428 111.356L994.989 107.749ZM1007.22 121.017L1005.8 115.333L1001.49 113.986L1004.66 110.776L1009.5 112.289L1010.78 117.41L1007.22 121.017ZM998.549 104.142L1008.22 107.168L1004.66 110.776L994.989 107.749L998.549 104.142Z" fill="#327FB6"/>
      <path d="M1019.98 101.518L1022.53 111.76L1032.21 114.786L1028.64 118.394L1018.97 115.367L1016.42 105.126L1019.98 101.518ZM1032.21 114.786L1030.79 109.103L1026.48 107.756L1029.65 104.545L1034.49 106.059L1035.77 111.179L1032.21 114.786ZM1023.54 97.9111L1033.21 100.938L1029.65 104.545L1019.98 101.518L1023.54 97.9111Z" fill="#327FB6"/>
      <path d="M1044.97 95.2878L1047.52 105.529L1057.19 108.556L1053.63 112.163L1043.96 109.136L1041.41 98.8951L1044.97 95.2878ZM1057.19 108.556L1055.78 102.872L1051.47 101.525L1054.64 98.3147L1059.48 99.8281L1060.75 104.949L1057.19 108.556ZM1048.53 91.6806L1058.2 94.7074L1054.64 98.3147L1044.97 95.2878L1048.53 91.6806Z" fill="#327FB6"/>
      <path d="M1069.95 89.0576L1072.51 99.2989L1082.18 102.326L1078.62 105.933L1068.95 102.906L1066.39 92.6648L1069.95 89.0576ZM1082.18 102.326L1080.77 96.6418L1076.46 95.2949L1079.63 92.0844L1084.47 93.5979L1085.74 98.7185L1082.18 102.326ZM1073.52 85.4504L1083.19 88.4772L1079.63 92.0844L1069.95 89.0576L1073.52 85.4504Z" fill="#327FB6"/>
      <path d="M1094.94 82.8271L1097.5 93.0684L1107.17 96.0953L1103.61 99.7025L1093.94 96.6757L1091.38 86.4344L1094.94 82.8271ZM1107.17 96.0953L1105.75 90.4114L1101.45 89.0644L1104.62 85.854L1109.46 87.3674L1110.73 92.488L1107.17 96.0953ZM1098.5 79.2199L1108.18 82.2467L1104.62 85.854L1094.94 82.8271L1098.5 79.2199Z" fill="#327FB6"/>
      <path d="M1119.93 76.5967L1122.49 86.838L1132.16 89.8648L1128.6 93.4721L1118.93 90.4452L1116.37 80.2039L1119.93 76.5967ZM1132.16 89.8648L1130.74 84.1809L1126.44 82.8339L1129.61 79.6235L1134.44 81.1369L1135.72 86.2576L1132.16 89.8648ZM1123.49 72.9894L1133.17 76.0163L1129.61 79.6235L1119.93 76.5967L1123.49 72.9894Z" fill="#327FB6"/>
      <path d="M1144.92 70.366L1147.48 80.6073L1157.15 83.6341L1153.59 87.2413L1143.91 84.2145L1141.36 73.9732L1144.92 70.366ZM1157.15 83.6341L1155.73 77.9502L1151.43 76.6032L1154.6 73.3928L1159.43 74.9062L1160.71 80.0269L1157.15 83.6341ZM1148.48 66.7587L1158.16 69.7855L1154.6 73.3928L1144.92 70.366L1148.48 66.7587Z" fill="#327FB6"/>
      <path d="M1169.91 64.1357L1172.46 74.377L1182.14 77.4039L1178.58 81.0111L1168.9 77.9843L1166.35 67.743L1169.91 64.1357ZM1182.14 77.4039L1180.72 71.7199L1176.42 70.373L1179.59 67.1626L1184.42 68.676L1185.7 73.7966L1182.14 77.4039ZM1173.47 60.5285L1183.15 63.5553L1179.59 67.1626L1169.91 64.1357L1173.47 60.5285Z" fill="#327FB6"/>
      <path d="M1194.9 57.9055L1197.45 68.1468L1207.13 71.1736L1203.57 74.7809L1193.89 71.7541L1191.34 61.5127L1194.9 57.9055ZM1207.13 71.1736L1205.71 65.4897L1201.4 64.1428L1204.57 60.9323L1209.41 62.4458L1210.69 67.5664L1207.13 71.1736ZM1198.46 54.2983L1208.13 57.3251L1204.57 60.9323L1194.9 57.9055L1198.46 54.2983Z" fill="#327FB6"/>
      <path d="M1219.89 51.675L1222.44 61.9163L1232.12 64.9432L1228.56 68.5504L1218.88 65.5236L1216.33 55.2823L1219.89 51.675ZM1232.12 64.9432L1230.7 59.2593L1226.39 57.9123L1229.56 54.7019L1234.4 56.2153L1235.68 61.3359L1232.12 64.9432ZM1223.45 48.0678L1233.12 51.0946L1229.56 54.7019L1219.89 51.675L1223.45 48.0678Z" fill="#327FB6"/>
      <path d="M1244.88 45.4446L1247.43 55.6859L1257.1 58.7127L1253.54 62.32L1243.87 59.2931L1241.32 49.0518L1244.88 45.4446ZM1257.1 58.7127L1255.69 53.0288L1251.38 51.6818L1254.55 48.4714L1259.39 49.9848L1260.66 55.1055L1257.1 58.7127ZM1248.44 41.8373L1258.11 44.8642L1254.55 48.4714L1244.88 45.4446L1248.44 41.8373Z" fill="#327FB6"/>
      <path d="M1269.87 39.2143L1272.42 49.4557L1282.09 52.4825L1278.53 56.0897L1268.86 53.0629L1266.3 42.8216L1269.87 39.2143ZM1282.09 52.4825L1280.68 46.7986L1276.37 45.4516L1279.54 42.2412L1284.38 43.7546L1285.65 48.8752L1282.09 52.4825ZM1273.43 35.6071L1283.1 38.6339L1279.54 42.2412L1269.87 39.2143L1273.43 35.6071Z" fill="#327FB6"/>
      <path d="M1127.08 105.272L1129.64 115.514L1139.31 118.54L1135.75 122.148L1126.07 119.121L1123.52 108.879L1127.08 105.272ZM1139.31 118.54L1137.89 112.856L1133.59 111.509L1136.76 108.299L1141.59 109.812L1142.87 114.933L1139.31 118.54ZM1130.64 101.665L1140.32 104.692L1136.76 108.299L1127.08 105.272L1130.64 101.665Z" fill="#327FB6"/>
      <path d="M1152.07 99.0417L1154.62 109.283L1164.3 112.31L1160.74 115.917L1151.06 112.89L1148.51 102.649L1152.07 99.0417ZM1164.3 112.31L1162.88 106.626L1158.58 105.279L1161.75 102.069L1166.58 103.582L1167.86 108.703L1164.3 112.31ZM1155.63 95.4345L1165.31 98.4613L1161.75 102.069L1152.07 99.0417L1155.63 95.4345Z" fill="#327FB6"/>
      <path d="M1177.06 92.8113L1179.61 103.053L1189.29 106.079L1185.73 109.687L1176.05 106.66L1173.5 96.4185L1177.06 92.8113ZM1189.29 106.079L1187.87 100.395L1183.57 99.0485L1186.73 95.8381L1191.57 97.3515L1192.85 102.472L1189.29 106.079ZM1180.62 89.204L1190.3 92.2309L1186.73 95.8381L1177.06 92.8113L1180.62 89.204Z" fill="#327FB6"/>
      <path d="M1202.05 86.581L1204.6 96.8224L1214.28 99.8492L1210.72 103.456L1201.04 100.43L1198.49 90.1883L1202.05 86.581ZM1214.28 99.8492L1212.86 94.1653L1208.55 92.8183L1211.72 89.6079L1216.56 91.1213L1217.84 96.2419L1214.28 99.8492ZM1205.61 82.9738L1215.28 86.0006L1211.72 89.6079L1202.05 86.581L1205.61 82.9738Z" fill="#327FB6"/>
      <path d="M1227.04 80.3506L1229.59 90.5919L1239.27 93.6187L1235.7 97.226L1226.03 94.1991L1223.48 83.9578L1227.04 80.3506ZM1239.27 93.6187L1237.85 87.9348L1233.54 86.5878L1236.71 83.3774L1241.55 84.8908L1242.83 90.0115L1239.27 93.6187ZM1230.6 76.7433L1240.27 79.7702L1236.71 83.3774L1227.04 80.3506L1230.6 76.7433Z" fill="#327FB6"/>
      <path d="M1252.03 74.1201L1254.58 84.3614L1264.25 87.3882L1260.69 90.9955L1251.02 87.9687L1248.47 77.7273L1252.03 74.1201ZM1264.25 87.3882L1262.84 81.7043L1258.53 80.3574L1261.7 77.1469L1266.54 78.6604L1267.81 83.781L1264.25 87.3882ZM1255.59 70.5129L1265.26 73.5397L1261.7 77.1469L1252.03 74.1201L1255.59 70.5129Z" fill="#327FB6"/>
      <path d="M1277.02 67.8899L1279.57 78.1312L1289.24 81.158L1285.68 84.7653L1276.01 81.7384L1273.45 71.4971L1277.02 67.8899ZM1289.24 81.158L1287.83 75.4741L1283.52 74.1272L1286.69 70.9167L1291.53 72.4301L1292.8 77.5508L1289.24 81.158ZM1280.58 64.2826L1290.25 67.3095L1286.69 70.9167L1277.02 67.8899L1280.58 64.2826Z" fill="#327FB6"/>
      <path d="M1259.18 102.796L1261.73 113.037L1271.4 116.064L1267.84 119.671L1258.17 116.644L1255.62 106.403L1259.18 102.796ZM1271.4 116.064L1269.99 110.38L1265.68 109.033L1268.85 105.823L1273.69 107.336L1274.96 112.457L1271.4 116.064ZM1262.74 99.1886L1272.41 102.215L1268.85 105.823L1259.18 102.796L1262.74 99.1886Z" fill="#327FB6"/>
      <path d="M1284.16 96.5657L1286.72 106.807L1296.39 109.834L1292.83 113.441L1283.16 110.414L1280.6 100.173L1284.16 96.5657ZM1296.39 109.834L1294.98 104.15L1290.67 102.803L1293.84 99.5925L1298.68 101.106L1299.95 106.227L1296.39 109.834ZM1287.73 92.9584L1297.4 95.9853L1293.84 99.5925L1284.16 96.5657L1287.73 92.9584Z" fill="#327FB6"/>
    </g>
    <defs>
      <filter id="filter0_f_16249_16183" x="-70.6677" y="-406.852" width="1422.34" height="917.704" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_16249_16183"/>
      </filter>
    </defs>
  </svg>);
