import { t } from 'i18next';
import React from 'react';
import { FlatList, SectionList, View } from 'react-native';
import Placeholder from '../../../../../../components/utility/placeholder';
import { tKeys } from '../../../../../../translations/keys';
import { Size as DocumentCardSize } from '../../../../../../components/surfaces/document-card/constants/size';
import { ListLayoutEnum } from '../../../repository/type';
import DocumentCard from '../../../../../../components/surfaces/offline-document-card';
import styles from './style';
import COLORS from '../../../../../../constants/colors';
import Icon from '../../../../../../components/content/icon';
import { PressableType } from '../../../../../../components/inputs/button/type';
import PressableWrapper from '../../../../../../components/inputs/button/wrapper';
import Flex from '../../../../../../components/surfaces/flex';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
import Container from '../../../../../../components/surfaces/container';
import useIsTablet from '../../../../../../hooks/is-tablet';
const DocumentList = ({ listLayoutType, isDocumentDataLoading, onLayoutIconPress, onDocumentPress, documentsData: data, }) => {
    // Attributes
    const isTablet = useIsTablet();
    // Functions
    function getCardSize() {
        if (listLayoutType === ListLayoutEnum.GRID) {
            return DocumentCardSize.DEFAULT;
        }
        return DocumentCardSize.COMPACT;
    }
    function getDocumentCardStyle(isTitleUncategorized) {
        if (listLayoutType === ListLayoutEnum.ROW)
            return { width: '100%' };
        // Grid Layout
        if (!isTitleUncategorized) {
            // card style for section list with titles
            return { width: 170, marginRight: 12 };
        }
        else {
            // card style for the main list without titles (All documents)
            return { width: isTablet ? '23%' : '48%' };
        }
    }
    // Render functions
    // - List text header
    function ListHeader({ title }) {
        return (<Container style={styles.listHeader}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>{title}</Text>
      </Container>);
    }
    // - List item separator
    function ListItemSeparator() {
        return (<View style={{
                height: listLayoutType === ListLayoutEnum.ROW ? 16 : 20,
            }}/>);
    }
    // - List item
    function ListItem({ item, key, isTitleUncategorized, }) {
        return (<View key={key} style={getDocumentCardStyle(isTitleUncategorized)}>
        <DocumentCard size={getCardSize()} title={item?.title} updateDate={item?.createdOn} eventName={item?.eventName} onPress={() => onDocumentPress(item)} imageURI={item?.fileThumbnailPath} titleNbOfLines={2} type="Repository"/>
      </View>);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function ListSectionHeader({ section: { title, data, key } }) {
        // Attributes
        const isTitleUncategorized = title === 'Uncategorized';
        const isFlatlistHorizontal = listLayoutType === ListLayoutEnum.GRID && !isTitleUncategorized;
        // Render
        return (<Flex direction="column">
        {data?.length ? (<ListHeader title={isTitleUncategorized ? t(tKeys.common.recent_document) : t(title)}/>) : null}

        {/* Can't do that in an other way because changing the number of column directly for a Flatlist is not authorized by React-native,
             so I've to render two different lists */}
        {listLayoutType !== ListLayoutEnum.GRID ? (<FlatList key={'_'} data={data} horizontal={isFlatlistHorizontal} showsHorizontalScrollIndicator={false} numColumns={1} ItemSeparatorComponent={ListItemSeparator} renderItem={({ item }) => ListItem({ item, key, isTitleUncategorized })}/>) : (<FlatList key={'#'} data={data} horizontal={false} showsHorizontalScrollIndicator={false} numColumns={isTablet ? 4 : 2} columnWrapperStyle={isTitleUncategorized ? styles.columnWrapper : undefined} ItemSeparatorComponent={ListItemSeparator} renderItem={({ item }) => ListItem({ item, key, isTitleUncategorized })}/>)}
      </Flex>);
    }
    // Page header component
    function RepositoryHeaderButtons() {
        return (<Flex justify="flex-end" align="center">
        {/* Change display */}
        <PressableWrapper pressableType={PressableType.SCALE} onPress={onLayoutIconPress} style={styles.iconContainer}>
          <Icon name={listLayoutType === ListLayoutEnum.GRID ? 'list-row' : 'list-grid'} width={20} height={20} color={COLORS.InputText.value}/>
        </PressableWrapper>
      </Flex>);
    }
    // Render
    return (<>
      <SectionList 
    // Option props
    showsVerticalScrollIndicator={false} key={`documentData_${listLayoutType}`} stickySectionHeadersEnabled={false} contentContainerStyle={styles.contentContainer} style={styles.flatlist} 
    // Component props
    ListHeaderComponent={<RepositoryHeaderButtons />} renderSectionHeader={ListSectionHeader} sections={data} 
    // Because of the specific design of this page the renderItem props is not used and the components will be rendered in the renderSectionHeader props
    renderItem={() => null} ListEmptyComponent={!isDocumentDataLoading ? (<Placeholder imageType="monocle-emoji" title={t(tKeys.home.more.repository.placeholder.title)} subTitle={t(tKeys.home.more.offline_repository.placeholder.subTitle)}/>) : null}/>
    </>);
};
export default DocumentList;
