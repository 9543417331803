import { useFormik } from 'formik';
import useRouter from '../../../hooks/router/useRouter';
import { useCreatePerson } from '../../../services/online/people/people';
import { createForm } from '../../../utils/formik';
const useLogic = () => {
    // Attributes
    const { push, goBack, params } = useRouter();
    const sourcePageName = params?.sourcePageName;
    const signUpCreateNewAccountEntity = params?.signUpCreateNewAccountEntity;
    // Queries
    const { mutateAsync: createPerson, isLoading } = useCreatePerson();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    // Functions
    const handleOnSuccess = () => {
        // Redirect to the Sign in page
        push('/auth/sign-in/default');
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleOnSubmit = async () => {
        if (sourcePageName !== '/sign-up-create-account') {
            return;
        }
        if (signUpCreateNewAccountEntity === null ||
            signUpCreateNewAccountEntity === undefined) {
            return;
        }
        const signUpCreateNewAccountEntityTyped = signUpCreateNewAccountEntity;
        await createPerson({
            data: {
                firstname: signUpCreateNewAccountEntityTyped.firstName,
                lastname: signUpCreateNewAccountEntityTyped.lastName,
                cultureName: signUpCreateNewAccountEntityTyped.cultureName ?? 'fr-FR',
                timeZoneId: signUpCreateNewAccountEntityTyped.timezoneId,
                phone: signUpCreateNewAccountEntityTyped.phoneNumber,
                password: form?.password?.value,
            },
            params: {
                'invitation-id': signUpCreateNewAccountEntityTyped.invitationId,
                'sms-code': signUpCreateNewAccountEntityTyped.smsCode,
            },
        }, {
            onSuccess: () => {
                handleOnSuccess();
            },
            onError: err => console.error('Error with create a person : ', err),
        });
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        form,
        handleOnSubmit,
        isLoading,
    };
};
export default useLogic;
