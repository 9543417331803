/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a document
 */
export const createDocument = (document, options) => {
    return customInstance({
        url: `/repository`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: document,
    }, options);
};
export const useCreateDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createDocument(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a paginated list of documents with filtering support
 */
export const readDocuments = (params, options, signal) => {
    return customInstance({ url: `/repository`, method: 'get', params, signal }, options);
};
export const getReadDocumentsQueryKey = (params) => [
    `/repository`,
    ...(params ? [params] : []),
];
export const useReadDocuments = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadDocumentsQueryKey(params);
    const queryFn = ({ signal, }) => readDocuments(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a document
 */
export const updateDocument = (document, options) => {
    return customInstance({
        url: `/repository`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: document,
    }, options);
};
export const useUpdateDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateDocument(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Annotates a document
 */
export const annotateDocument = (annotation, options) => {
    return customInstance({
        url: `/repository`,
        method: 'patch',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: annotation,
    }, options);
};
export const useAnnotateDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return annotateDocument(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a document
 */
export const readDocument = (documentId, params, options, signal) => {
    return customInstance({ url: `/repository/${documentId}`, method: 'get', params, signal }, options);
};
export const getReadDocumentQueryKey = (documentId, params) => [`/repository/${documentId}`, ...(params ? [params] : [])];
export const useReadDocument = (documentId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadDocumentQueryKey(documentId, params);
    const queryFn = ({ signal, }) => readDocument(documentId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!documentId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Deletes a document
 */
export const deleteDocument = (documentId, options) => {
    return customInstance({ url: `/repository/${documentId}`, method: 'delete' }, options);
};
export const useDeleteDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentId } = props ?? {};
        return deleteDocument(documentId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Shares a document
 */
export const shareDocument = (documentId, options) => {
    return customInstance({ url: `/repository/${documentId}/share`, method: 'post' }, options);
};
export const useShareDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentId } = props ?? {};
        return shareDocument(documentId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Downloads a document
 */
export const downloadDocument = (documentId, params, options, signal) => {
    return customInstance({
        url: `/repository/${documentId}/download`,
        method: 'get',
        params,
        signal,
    }, options);
};
export const getDownloadDocumentQueryKey = (documentId, params) => [`/repository/${documentId}/download`, ...(params ? [params] : [])];
export const useDownloadDocument = (documentId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getDownloadDocumentQueryKey(documentId, params);
    const queryFn = ({ signal }) => downloadDocument(documentId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!documentId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Creates a document type
 */
export const createDocumentType = (documentType, options) => {
    return customInstance({
        url: `/repository/document-types`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: documentType,
    }, options);
};
export const useCreateDocumentType = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createDocumentType(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of document types
 */
export const readDocumentTypes = (params, options, signal) => {
    return customInstance({ url: `/repository/document-types`, method: 'get', params, signal }, options);
};
export const getReadDocumentTypesQueryKey = (params) => [`/repository/document-types`, ...(params ? [params] : [])];
export const useReadDocumentTypes = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadDocumentTypesQueryKey(params);
    const queryFn = ({ signal }) => readDocumentTypes(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a document type
 */
export const updateDocumentType = (documentType, options) => {
    return customInstance({
        url: `/repository/document-types`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: documentType,
    }, options);
};
export const useUpdateDocumentType = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateDocumentType(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes a document type
 */
export const deleteDocumentType = (documentTypeId, options) => {
    return customInstance({ url: `/repository/document-types/${documentTypeId}`, method: 'delete' }, options);
};
export const useDeleteDocumentType = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentTypeId } = props ?? {};
        return deleteDocumentType(documentTypeId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves versions of a document
 */
export const readDocumentHistory = (documentId, options, signal) => {
    return customInstance({ url: `/repository/${documentId}/history`, method: 'get', signal }, options);
};
export const getReadDocumentHistoryQueryKey = (documentId) => [
    `/repository/${documentId}/history`,
];
export const useReadDocumentHistory = (documentId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadDocumentHistoryQueryKey(documentId);
    const queryFn = ({ signal }) => readDocumentHistory(documentId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!documentId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Add a group to a document
 */
export const addGroupToDocument = (documentGroup, options) => {
    return customInstance({
        url: `/repository/groups`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: documentGroup,
    }, options);
};
export const useAddGroupToDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return addGroupToDocument(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Add a person to a document
 */
export const addPersonToDocument = (documentPerson, options) => {
    return customInstance({
        url: `/repository/persons`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: documentPerson,
    }, options);
};
export const useAddPersonToDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return addPersonToDocument(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Remove group from document
 */
export const removeGroupFromDocument = (documentId, groupId, options) => {
    return customInstance({ url: `/repository/${documentId}/groups/${groupId}`, method: 'delete' }, options);
};
export const useRemoveGroupFromDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentId, groupId } = props ?? {};
        return removeGroupFromDocument(documentId, groupId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Remove person from document
 */
export const removePersonFromDocument = (documentId, personId, options) => {
    return customInstance({ url: `/repository/${documentId}/persons/${personId}`, method: 'delete' }, options);
};
export const useRemovePersonFromDocument = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentId, personId } = props ?? {};
        return removePersonFromDocument(documentId, personId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves the permissions persons and groups for a document
 */
export const readDocumentPermissions = (documentId, options, signal) => {
    return customInstance({ url: `/repository/${documentId}/permissions`, method: 'get', signal }, options);
};
export const getReadDocumentPermissionsQueryKey = (documentId) => [
    `/repository/${documentId}/permissions`,
];
export const useReadDocumentPermissions = (documentId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadDocumentPermissionsQueryKey(documentId);
    const queryFn = ({ signal }) => readDocumentPermissions(documentId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!documentId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
