import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size';
import FormControl from '../../../../../components/inputs/form-control';
import NavigationHeader from '../../../../../components/navigation/navigation-header';
import Page from '../../../../../components/surfaces/page';
import COLORS from '../../../../../constants/colors';
import { tKeys } from '../../../../../translations/keys';
import useLogic from './logic.web';
import { Stack } from '@chakra-ui/react';
import { boxSectionStyle } from '../../my-informations/style.web';
import Input from '../../../../../components/inputs/input/index.web';
const VerifyCode = () => {
    // Attributes
    const { handleOnPressButton, isUpdateEnrolmentLoading, form, handleOnPressBackButton, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader justifyTitle="center" onGoBack={handleOnPressBackButton} endEnhancer={<Button onPress={handleOnPressButton} isLoading={isUpdateEnrolmentLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
            {t(tKeys.common.confirm)}
          </Button>}>
        {`${t(tKeys.profile['user-profile']['my-information'])} / ${t(tKeys.profile['user-profile']['email-address']['navigation-header'])}`}
      </NavigationHeader>

      <Stack width={'full'} height={'full'} bgColor={COLORS.BG.value} p={'1rem'}>
        <Stack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile['user-profile'].mail['verify-code']['enter-code-msg'])} spacing={4}>
            <Input value={form?.code?.value} onChange={e => form?.code?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys?.profile['user-profile'].mail['verify-code']['enter-code-msg'])}/>
          </FormControl>
        </Stack>
      </Stack>
    </Page>);
};
export default VerifyCode;
