import * as Yup from 'yup';
import { tKeys } from '../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        // Global
        message: Yup.string().required(t(tKeys.validation.create_post.errors.message.required)),
        // Image
        imageValue: Yup.object().optional().nullable(),
        // Document
        documentValue: Yup.object().optional().nullable(),
        // Poll
        isPollVisible: Yup.bool().optional(),
        title: Yup.string().when('isPollVisible', (isPollVisible, schema) => {
            return isPollVisible
                ? schema.required(t(tKeys.validation.create_post.errors.title.required))
                : schema.optional();
        }),
        pollOptions: Yup.array(Yup.string()).when('isPollVisible', (isPollVisible, schema) => {
            return isPollVisible
                ? schema.min(2, t(tKeys.validation.create_post.errors.poll.min))
                : schema.optional();
        }),
    });
};
