import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import Placeholder from '../../../../../../components/utility/placeholder';
import { tKeys } from '../../../../../../translations/keys';
import { VStack } from '@chakra-ui/react';
import MessagesListItem from './components/messages-list-item/index.web';
const MessagesList = ({ search, isChannelsLoading, channels, onChannelPress, getParticipant, selectedChannel, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render ListEmptyComponent
    const renderPlaceholder = () => {
        if (isChannelsLoading)
            return <></>;
        if (search !== '')
            return (<Placeholder imageType="search-emoji" title={t(tKeys.channels.search.placeholder.title)} subTitle={t(tKeys.channels.search.placeholder.subtitle)} maxWidth={219} style={{ marginTop: 350 }}/>);
        return (<Placeholder imageType="think-emoji" title={t(tKeys.private_chat.empty.title)} subTitle={t(tKeys.private_chat.placeholder.subtitle)} maxWidth={219}/>);
    };
    // Render
    return (<LoadingWrapper isLoading={isChannelsLoading} fullScreen>
      <VStack h="full" w="full" alignItems={'flex-start'} alignSelf={'center'} overflow={'auto'} style={{
            scrollbarWidth: 'none',
        }}>
        {channels && channels.length > 0 ? (<>
            {channels.map((channel, index) => (<MessagesListItem key={index} channel={channel} getParticipant={getParticipant} onChannelPress={onChannelPress} selectedChannelId={selectedChannel?.id}/>))}
          </>) : (<>{renderPlaceholder()}</>)}
      </VStack>
    </LoadingWrapper>);
};
export default MessagesList;
