import { useFormik } from 'formik';
import { t } from 'i18next';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { useCreateEventType, useUpdateEventType, } from '../../../../../../services/online/calendar/calendar';
import { createForm } from '../../../../../../utils/formik';
import { validationSchema } from './validations';
const useLogic = (onClose, refetchEventTypes, eventType) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { mutateAsync: createEventType, isLoading: isCreateEventLoading } = useCreateEventType();
    const { mutateAsync: updateEventType, isLoading: isUpdateEventLoading } = useUpdateEventType();
    // Functions
    const handleOnSubmit = async () => {
        if (form?.eventName?.value?.length) {
            if (eventType) {
                await updateEventType({
                    data: {
                        companyId: selectedCompanyId,
                        id: eventType?.id,
                        name: form?.eventName?.value,
                    },
                }, {
                    onSuccess: () => {
                        refetchEventTypes();
                        onClose();
                    },
                    onError: err => console.error('Error when updating event type : ', err),
                });
                return;
            }
            await createEventType({
                data: {
                    companyId: selectedCompanyId,
                    name: form?.eventName?.value,
                },
            }, {
                onSuccess: () => {
                    refetchEventTypes();
                    onClose();
                },
                onError: err => console.error('Error when creating event type : ', err),
            });
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            eventName: eventType?.name ?? '',
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnSubmit: handleSubmit,
        isCreateEventLoading,
        isUpdateEventLoading,
        dirty,
        form,
    };
};
export default useLogic;
