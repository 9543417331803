import VerifyCode from '.';
const verifyCodeUserProfileRoutes = [
    {
        name: '/verify-code-user-profile',
        deepLink: '/verify-code-user-profile',
        tabBarLabel: 'profile.user-profile.myinfo.my-information',
        component: VerifyCode,
    },
];
export default verifyCodeUserProfileRoutes;
