import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    newsFeedCardContainer: {
        marginVertical: 8,
    },
    cardContainerContainer: {
        marginVertical: 4,
    },
});
export default styles;
