import React from 'react';
import Tag from '../../../../../../../components/content/tag/index.web';
import COLORS from '../../../../../../../constants/colors';
import styles from './styles';
import { Box, HStack } from '@chakra-ui/react';
const TagFieldItem = ({ value, hasEndEnhancer }) => {
    if (Array.isArray(value)) {
        return (<HStack flexWrap="wrap" marginTop={'.75rem'}>
        {value?.map(value => {
                if (!value) {
                    return null;
                }
                return (<Box key={value} marginRight={'.5rem'}>
              <Tag kind="ROUNDED" style={styles.selectedTag} color={COLORS.Text.T400.value}>
                {value}
              </Tag>
            </Box>);
            })}
      </HStack>);
    }
    return (<Tag kind="ROUNDED" style={styles.selectedTag} color={COLORS.Text.T400.value} hasEndEnhancer={hasEndEnhancer}>
      {value}
    </Tag>);
};
export default TagFieldItem;
