/* eslint-disable @typescript-eslint/no-misused-promises */
import { useDisclosure } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useCompaniesContext } from '../../../contexts/companies';
import useRouter from '../../../hooks/router/useRouter';
import { useReadCompany } from '../../../services/online/companies/companies';
import { useReadEnrolments } from '../../../services/online/enrolments/enrolments';
import { getReadPostsQueryKey, readPosts, useDeletePost, useMarkPostAsRead, useVote, } from '../../../services/online/news-feed/news-feed';
import { refresh, useReadMe } from '../../../services/online/people/people';
import { createForm } from '../../../utils/formik';
import { getPostType } from '../../../utils/getPostType';
import getStaleTime from '../../../utils/getStaleTime';
import { deleteTokensFromStorage, saveTokens, } from '../../../utils/handleTokens';
const useLogic = () => {
    // Attributes
    const { push } = useRouter();
    const [activeSideBarItem, setActiveSideBarItem] = useState(0);
    const { selectedCompanyId, selectedEnrolment, allUserCompanies, updateCurrentCompany, me, } = useCompaniesContext();
    const { isOpen: isOpenShareInMessage, onOpen: onOpenShareInMessage, onClose: onCloseShareInMessage, } = useDisclosure();
    const { isOpen: isOpenFilter, onToggle: onToggleFilter } = useDisclosure();
    const [sideNavigationVisible, setSideNavigationVisible] = useState(false);
    const [shareInMessageEntity, setShareInMessageEntity] = useState();
    const [currentPageNewsFeed, setCurrentPageNewsFeed] = useState(0);
    // Queries
    // - Mutations
    const { mutateAsync: vote, isLoading: isVoteLoading } = useVote();
    const { mutateAsync: markPostAsRead } = useMarkPostAsRead();
    const { mutateAsync: deletePost } = useDeletePost();
    // - Getters
    const { data: currentUserData, refetch: refetchMe, isLoading: isReadMeLoading, } = useReadMe();
    const { data: companyData, refetch: refetchReadCompany, isLoading: isReadCompanyLoading, } = useReadCompany(selectedCompanyId, {
        query: { enabled: !!selectedCompanyId },
    });
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            enabled: !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    // Data
    const allCompanyMembersId = useMemo(() => enrolments?.items?.map(enrolment => enrolment.person.id), [enrolments]);
    // Form
    const { values, dirty, getFieldMeta, setValues, ...rest } = useFormik({
        initialValues: {
            fromDate: undefined,
            toDate: undefined,
            period: undefined,
            publishedBy: allCompanyMembersId,
        },
        onSubmit: () => undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { resetForm } = rest;
    const form = createForm(values, rest);
    const activeFiltersNumber = useMemo(() => {
        const isAllMembersSelected = !!allCompanyMembersId &&
            values?.publishedBy?.length === allCompanyMembersId?.length;
        const isPeriodSelected = !!values?.period;
        const allFilters = Object.values(values).filter(value => !!value)?.length;
        return allFilters + (isAllMembersSelected && -1) + (isPeriodSelected && -2);
    }, [values]);
    // Queries
    // - Getters
    const { data: newsFeedData, isFetching: isNewsFeedDataFetching, isLoading: isNewsFeedDataLoading, refetch: refetchNewsFeedData, fetchNextPage: fetchNextPageNewsFeedData, } = useInfiniteQuery(getReadPostsQueryKey({
        'company-id': selectedCompanyId,
        'published-by': values?.publishedBy,
        'date-from': values?.fromDate,
        'date-to': values?.toDate,
    }), async ({ pageParam }) => {
        setCurrentPageNewsFeed(pageParam);
        // Read the next page
        return await readPosts({
            'company-id': selectedCompanyId,
            'published-by': values.publishedBy,
            'page-index': pageParam ?? 1,
            'date-from': values?.fromDate,
            'date-to': values?.toDate,
        });
    }, {
        getNextPageParam: ({ pagination, totalNumberOfPages }) => {
            if (pagination?.pageIndex < totalNumberOfPages) {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return pagination?.pageIndex + 1;
            }
            return undefined;
        },
        enabled: !!selectedCompanyId && !!values.publishedBy,
    });
    // Functions
    function onResetForm() {
        resetForm();
    }
    function onImageClick() {
        setSideNavigationVisible(true);
    }
    async function onCompanyClick(index, companyId) {
        try {
            const res = await refresh({ useRefreshToken: true });
            if (res) {
                await saveTokens({
                    accessToken: res.accessToken,
                    refreshToken: res.refreshToken,
                });
            }
        }
        catch (err) {
            console.error('Error when fetching new tokens : ', err);
            await deleteTokensFromStorage();
        }
        await refetchMe();
        updateCurrentCompany(companyId);
        setActiveSideBarItem(index);
        setSideNavigationVisible(false);
    }
    const handleOnPressMessage = (post, postType) => {
        const shareInMessageEntity = {
            data: post,
            type: postType,
            isPost: true,
        };
        setShareInMessageEntity(shareInMessageEntity);
        onOpenShareInMessage();
    };
    const onClickButtonAddPost = () => {
        push('/create-post', { companyId: companyData?.id });
    };
    function onSearchPress() {
        push('/search');
    }
    // Effect
    useEffect(() => {
        if (selectedCompanyId) {
            void refetchReadCompany();
            setActiveSideBarItem(allUserCompanies.findIndex(company => selectedCompanyId === company.id));
        }
    }, [selectedCompanyId]);
    // Return
    return {
        selectedEnrolment,
        companyData,
        allUserCompanies,
        selectedCompanyId,
        sideNavigationVisible,
        setSideNavigationVisible,
        newsFeedData,
        refetchNewsFeedData,
        fetchNextPageNewsFeedData,
        isNewsFeedDataFetching,
        isNewsFeedDataLoading,
        currentPageNewsFeed,
        getPostType,
        me,
        push,
        vote,
        isVoteLoading,
        markPostAsRead,
        activeFiltersNumber,
        deletePost,
        onImageClick,
        onCompanyClick,
        handleOnPressMessage,
        onClickButtonAddPost,
        onSearchPress,
        activeSideBarItem,
        isReadMeLoading,
        isReadCompanyLoading,
        form,
        values,
        onResetForm,
        isOpenShareInMessage,
        onCloseShareInMessage,
        shareInMessageEntity,
        isOpenFilter,
        onToggleFilter,
    };
};
export default useLogic;
