import * as Yup from 'yup';
import { tKeys } from '../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        biography: Yup.string().notRequired(),
        cultureName: Yup.string().required(t(tKeys.validation.profile.my_information.language)),
        firstname: Yup.string().required(t(tKeys.validation.profile.my_information.first_name)),
        lastname: Yup.string().required(t(tKeys.validation.profile.my_information.last_name)),
        nickname: Yup.string().required(t(tKeys.validation.profile.my_information.nickname)),
        timeZoneId: Yup.string().required(),
    });
};
