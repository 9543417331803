import React from 'react';
import Tasks from '.';
import Icon from '../../../components/content/icon';
import { RouteEnum } from '../routeEnum';
const taskRoutes = [
    // Tasks route
    {
        name: '/tasks',
        deepLink: '/tasks',
        component: Tasks,
        defaultRoute: 'default',
        tabBarLabel: 'page.menu.actions',
        activeIcon: (<Icon name="navigation-bar-tasks-selected" width={24} height={24}/>),
        inactiveIcon: (<Icon name="navigation-bar-tasks-default" width={24} height={24}/>),
        routeEnum: RouteEnum.Tasks,
    },
];
export default taskRoutes;
