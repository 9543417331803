import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    mainContent: {
        backgroundColor: COLORS.White.T500.value,
        width: '100%',
        height: '100%',
        paddingTop: '1rem',
        paddingHorizontal: '.25rem',
    },
    filterAllFileTypesContainer: {
        marginTop: 12,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
    },
    flatlistContent: {
        paddingBottom: 16,
    },
    bottomButton: {
        bottom: 0,
        left: 0,
        right: 0,
        paddingHorizontal: 12,
        paddingBottom: 16,
        marginVertical: 12,
        width: '100%',
    },
    showEventSwitchContainer: {
        marginHorizontal: 12,
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: 32,
    },
});
export default styles;
