import React from 'react';
import Container from '../../../../components/surfaces/container';
import styles from './style.web';
import FormControl from '../../../../components/inputs/form-control';
import COLORS from '../../../../constants/colors';
// import PasswordInput from '../../components/inputs/password-input/index.web';
import { useTranslation } from 'react-i18next';
// import useLogic from './logic.web';
import { VStack, Text, Button, Stack } from '@chakra-ui/react';
import { PageWebAuth } from '../../../../components/surfaces/page-auth/index.web';
import Input from '../../../../components/inputs/input/index.web';
import useLogic from './logic.web';
const PortalEventDocuments = () => {
    // Attributes
    const { t } = useTranslation();
    const { isDocumentDownloaded, shortPhoneNumber, documentName, handleSubmit, smsSent, smsCode, handleSubmitSmsCode, setSmsCode, isRequestSmsLoading, isConfirmSmsLoading } = useLogic();
    const ContinueButton = (<VStack w="full" pt="3.5rem">
      <Button onClick={handleSubmit} isLoading={isRequestSmsLoading} width="full" textColor={COLORS.White.T500.value} fontSize={14} bgColor={COLORS.Govrn.Blue.T500.value} _hover={{ bgColor: COLORS.Govrn.Blue.T600.value }}>
        Send SMS
      </Button>
    </VStack>);
    // Render
    return (<PageWebAuth>
      <Stack width={'full'} px={'2.5rem'} pb={'2.5rem'} pt={'2rem'}>
        {/* Page title */}
        <Text color={COLORS.Text.T400.value} fontWeight={600} fontSize={24} alignSelf="start" pb="3.5rem" pt="1.5rem">
          Download the file {documentName}
        </Text>

        {/* Phone number confirmation */}
        <Container style={{
            ...styles.phoneInputContainer,
            paddingBottom: '1rem',
        }}>
            <Text color={COLORS.Text.T400.value} fontWeight={500} fontSize={16} alignSelf="start">
            {isDocumentDownloaded
            ? "The document has been successfully downloaded."
            : `We will send an SMS to XXXXXX${shortPhoneNumber} to verify your identity.`}
            </Text>
        </Container>

        {/* Send SMS button */}
        {!smsSent && (<Container style={{
                ...styles.phoneInputContainer,
            }} testID={'sendSMSButton'}>
            <FormControl spacing={2}>{ContinueButton}</FormControl>
          </Container>)}
        {/* File download info */}
        {!smsSent && (<Container style={{
                ...styles.phoneInputContainer,
                paddingTop: '1.5rem',
            }}>
            <Text color={COLORS.Text.T400.value} fontWeight={500} fontSize={14}>
              Once you receive the SMS, you can download the file.
            </Text>
          </Container>)}
        {(smsSent && !isDocumentDownloaded) && (<Container style={{
                ...styles.phoneInputContainer,
                paddingTop: '2rem',
            }}>
            <FormControl spacing={2}>
              {/* Boîte d'entrée pour le code SMS */}
              <Input placeholder="Enter SMS Code" value={smsCode} onChange={(e) => setSmsCode(e.target.value)} mb="1rem"/>
              {/* Bouton pour soumettre le code SMS */}
              <VStack w="full" pt="3.5rem">
                <Button 
        // ref={continueButtonRef}
        // // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleSubmitSmsCode} 
        // isDisabled={isSubmitButtonDisabled}
        isLoading={isConfirmSmsLoading} width="full" textColor={COLORS.White.T500.value} fontSize={14} bgColor={COLORS.Govrn.Blue.T500.value} _hover={{ bgColor: COLORS.Govrn.Blue.T600.value }}>
                  Submit Code
                </Button>
              </VStack>
            </FormControl>
          </Container>)}


      </Stack>
    </PageWebAuth>);
};
export default PortalEventDocuments;
