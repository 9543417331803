export var MembersTabs;
(function (MembersTabs) {
    MembersTabs[MembersTabs["Members"] = 0] = "Members";
    MembersTabs[MembersTabs["Groups"] = 1] = "Groups";
    MembersTabs[MembersTabs["Invitations"] = 2] = "Invitations";
})(MembersTabs || (MembersTabs = {}));
export var ShowMembers;
(function (ShowMembers) {
    ShowMembers[ShowMembers["ALL"] = 0] = "ALL";
    ShowMembers[ShowMembers["ACTIVE"] = 1] = "ACTIVE";
    ShowMembers[ShowMembers["INACTIVE"] = 2] = "INACTIVE";
})(ShowMembers || (ShowMembers = {}));
