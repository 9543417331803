import { useCompaniesContext } from '../../../../../contexts/companies/index.web';
import { countNews } from '../../../../../services/online/companies/companies';
import { useQuery } from '@tanstack/react-query';
export const useCompanyPopupItemLogic = (companyId, isPopUpOpen) => {
    // Attributes
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    // Queries
    const { data: companyNews } = useQuery(['companyNews', companyId], fetchCompanyNews, {
        enabled: !!companyId && !!selectedEnrolment?.personId && !!isPopUpOpen,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });
    // Functions
    async function fetchCompanyNews() {
        const companyNews = await countNews(companyId, {
            'person-id': selectedEnrolment?.personId,
        });
        return companyNews;
    }
    const hasCompanyNews = !!companyNews?.actionCount ||
        !!companyNews?.eventCount ||
        !!companyNews?.messageCount ||
        !!companyNews?.postCount;
    // Return
    return { hasCompanyNews, selectedCompanyId };
};
