import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../../hooks/router/useRouter';
import { useUpdateEnrolment } from '../../../../../services/online/enrolments/enrolments';
import { tKeys } from '../../../../../translations/keys';
import { createForm } from '../../../../../utils/formik';
import { useQueryClient } from '@tanstack/react-query';
import { getReadMeQueryKey } from '../../../../../services/online/people/people';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../../../contexts/companies';
const useLogic = () => {
    // Attributes
    const { goBack, push, params } = useRouter();
    const { t } = useTranslation();
    const toast = useToast();
    const { selectedCompanyId } = useCompaniesContext();
    const enrolment = params?.enrolment;
    const newEmail = params?.newEmail;
    const queryClient = useQueryClient();
    // Queries
    const { mutateAsync: updateEnrollement, isLoading: isUpdateEnrolmentLoading, } = useUpdateEnrolment();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPressButton = async () => {
        if (dirty) {
            try {
                await updateEnrollement({
                    data: {
                        ...enrolment,
                        email: newEmail,
                    },
                    params: {
                        'email-code': form?.code?.value,
                    },
                }, {
                    onSuccess: () => {
                        void queryClient?.invalidateQueries(getReadMeQueryKey());
                        push('/company/profile/user-profile/email-address', {
                            companyId: selectedCompanyId,
                            enrolment,
                        });
                        toast({
                            status: 'success',
                            title: t(tKeys.common['verify-code']['success-msg']),
                        });
                    },
                });
            }
            catch (err) {
                console.error('Error with updating the email : ', err);
                toast({
                    status: 'error',
                    title: t(tKeys.common['verify-code']['error-msg']),
                });
            }
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: handleOnPressButton,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        handleOnPressButton,
        isUpdateEnrolmentLoading,
        dirty,
        form,
    };
};
export default useLogic;
