import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        flexGrow: 1,
        backgroundColor: COLORS.White.T500.value,
        width: '100%',
        heigth: '100%',
    },
    emptyContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: COLORS.White.T500.value,
    },
    scrollview: {
        paddingBottom: 32,
    },
});
export default styles;
