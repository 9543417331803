import { useReadBoardRoles } from '../../services/online/enrolments/enrolments';
import { useCompaniesContext } from '../../contexts/companies/index.web';
const useSelectBoardRoleLogic = () => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: boardRoles } = useReadBoardRoles({
        'company-id': selectedCompanyId,
    });
    // Return
    return {
        boardRoles,
    };
};
export default useSelectBoardRoleLogic;
