import React from 'react';
import Button from '../../../../../../components/inputs/button';
import FormControl from '../../../../../../components/inputs/form-control';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import useLogic from './logic.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import Modal from '../../../../../../components/surfaces/modal/index.web';
import { Stack } from '@chakra-ui/react';
import Input from '../../../../../../components/inputs/input/index.web';
const AddFileType = ({ isOpen, onClose, refetchFilesType, fileType, }) => {
    // Attributes
    const { handleOnSubmit, isCreateFileTypeLoading, form, isUpdateFileTypeLoading, } = useLogic(onClose, refetchFilesType, fileType);
    const { t } = useTranslation();
    // Render
    return (<Modal size={'2xl'} hasCloseButton isOpen={isOpen} onClose={onClose} title={fileType
            ? t(tKeys.settings['manage-files']['modify-file-type']['navigation-header'])
            : t(tKeys.settings['manage-files']['add-file-type']['navigation-header'])} actionButton={<Button onPress={handleOnSubmit} isLoading={isCreateFileTypeLoading || isUpdateFileTypeLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
          {fileType ? t(tKeys.common.save) : t(tKeys.common.create)}
        </Button>}>
      <Stack width={'full'} p={'1rem'}>
        <FormControl label={t(tKeys.common.name.label)} error={form?.fileTypeName?.meta?.error} spacing={4}>
          <Input value={form?.fileTypeName?.value} onChange={e => form?.fileTypeName?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common.name.placeholder)} autoFocus/>
        </FormControl>
      </Stack>
    </Modal>);
};
export default AddFileType;
