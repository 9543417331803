import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    status: {
        borderRadius: 100,
        borderWidth: 3,
        borderColor: COLORS.White.T500.value,
        position: 'absolute',
        width: 14,
        height: 14,
    },
    onlineStatus: {
        backgroundColor: COLORS.Success.T500.value,
    },
    offlineStatus: {
        backgroundColor: COLORS.Text.T100.value,
    },
});
export default styles;
