import { useState } from 'react';
import useRouter from '../../../../../hooks/router/useRouter';
const useLogic = () => {
    // Attributes
    const { goBack, params } = useRouter();
    const filterAllMembersEntity = {
        allMembers: params?.allMembers,
        allMembersSelected: params?.allMembersSelected,
    };
    const [isSelectedAll, setIsSelectedAll] = useState(!!filterAllMembersEntity?.allMembersSelected?.length);
    const [searchValue, setSearchValue] = useState();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPressSelected = () => {
        setIsSelectedAll(!isSelectedAll);
    };
    // Return
    return {
        handleOnPressBackButton,
        handleOnPressSelected,
        isSelectedAll,
        searchValue,
        setSearchValue,
        filterAllMembersEntity,
    };
};
export default useLogic;
