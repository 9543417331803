/**
 * This hook manages the lifecycle of a PDF document viewer using PDFTron WebViewer.
 * It handles loading documents, importing annotations, and updating annotations
 * when changes are made. Here's a breakdown of the useEffect flow and event triggers:
 *
 * Functions:
 * - `updateDocumentAnnotations`: Updates the document annotations on the backend.
 * - `handleAnnotationChanged`: Sets the state to trigger annotation update.
 *
 * Document Load Sequence:
 * 1. **Document Selection:**
 *    - When a new document is selected (`selectedDocument` changes), `useEffect` 3 removes
 *      any existing event listeners and updates the `doc` state.
 *
 * 2. **Document Data Fetch:**
 *    - The `useReadDocument` query fetches the data for the new document. When this data
 *      is received (`documentData` changes), `useEffect` 1 initializes the document annotations.
 *
 * 3. **WebViewer Initialization:**
 *    - If the WebViewer instance is not already initialized, `useEffect` 5 initializes it
 *      and loads the document. If it is already initialized, it closes the current document
 *      and loads the new one, importing the annotations.
 *
 * 4. **Annotations Import:**
 *    - After the document is loaded, `useEffect` 4 and the part of `useEffect` 5 dealing with
 *      `documentLoaded` handle the import of annotations into the viewer.
 *
 * 5. **Annotations Update Handling:**
 *    - Annotations are continuously monitored. Any changes trigger `handleAnnotationChanged`,
 *      setting the state to update annotations. This is managed by `useEffect` 2, which ensures
 *      updates are saved to the backend.
 */
import { useEffect, useRef, useState } from 'react';
import { useAnnotateDocument, useReadDocument, } from '../../../services/online/repository/repository';
import { tKeys } from '../../../translations/keys';
import { t } from 'i18next';
import { DocumentKindEnum, } from '../../document-details/type';
import WebViewer from '@pdftron/webviewer';
import { useToast } from '../../../components/content/use-toast/index.web';
import useRouter from '../../../hooks/router/useRouter';
import { useCompaniesContext } from '../../../contexts/companies';
const useLogic = (selectedDocument, setPDFtronInstance, selectedTemplate) => {
    // Attributes
    const { params } = useRouter();
    const { documentKind } = params ?? {
        documentKind: DocumentKindEnum.DOCUMENT,
    };
    const viewerRef = useRef(null);
    const [documentAnnotations, setDocumentAnnotations] = useState();
    const toast = useToast();
    const [webviewerInstance, setWebviewerInstance] = useState();
    const [doc, setDoc] = useState();
    const documentTitle = selectedDocument?.documentTitle;
    const documentTitleSplitted = documentTitle?.split('.');
    const documentType = documentTitleSplitted?.[documentTitleSplitted?.length - 1];
    // Queries
    const { mutateAsync: updateDocument } = useAnnotateDocument();
    const { me } = useCompaniesContext();
    const { data: documentData, isLoading: isDocumentLoading } = useReadDocument(doc?.id, { 'version-id': doc?.versionId }, {
        query: {
            enabled: !!doc?.id,
            cacheTime: 0,
        },
    });
    // Functions
    async function updateDocumentAnnotations(xfdfCommand, documentData) {
        void updateDocument({
            data: {
                documentId: documentData?.id,
                documentVersionId: documentData?.versionId,
                personId: me?.id,
                xfdfString: xfdfCommand,
            },
        }, {
            onError: () => {
                toast({
                    status: 'error',
                    title: t(tKeys.document_viewer.annotation.update.error),
                });
            },
            onSuccess: () => {
                setDocumentAnnotations({
                    documentAnnotations: xfdfCommand,
                    needUpdate: false,
                });
            },
        });
    }
    /**
     * useEffect 2: Update Annotations on Annotation Change
     * - Trigger: `documentAnnotations` changes
     * - Action: Exports the annotations and updates the document annotations.
     */
    useEffect(() => {
        if (documentAnnotations?.needUpdate) {
            const { documentViewer } = webviewerInstance?.Core;
            const annotationsManager = documentViewer?.getAnnotationManager();
            void annotationsManager
                ?.exportAnnotationCommand()
                .then(async (xfdfCommand) => {
                await updateDocumentAnnotations(xfdfCommand, documentData);
            });
        }
    }, [documentAnnotations]);
    /**
     * useEffect 3: Remove Annotation Changed Event Listener on Document Change
     * - Trigger: `selectedDocument` changes
     * - Action: Removes the `annotationChanged` event listener.
     * - This is to prevent memory leaks.
     */
    useEffect(() => {
        if (webviewerInstance) {
            const annotationsManager = webviewerInstance?.Core.documentViewer.getAnnotationManager();
            annotationsManager.removeEventListener('annotationChanged', handleAnnotationChanged);
        }
        setDoc(selectedDocument?.document);
    }, [selectedDocument]);
    /**
     * useEffect 4: Handle Annotation Changes from WebViewer
     * - Trigger: `annotationChanged` event
     * - Action: Updates the `documentAnnotations` state.
     */
    function handleAnnotationChanged() {
        setDocumentAnnotations({
            documentAnnotations: documentAnnotations?.documentAnnotations,
            needUpdate: true,
        });
    }
    /**
     * useEffect 5: Initialize WebViewer and Handle Document Changes
     * - Trigger: `documentData` changes
     * - Action: Initializes WebViewer if not already initialized.
     * - Loads the document, imports annotations, and adds the `annotationChanged` event listener.
     */
    useEffect(() => {
        if (!viewerRef.current || !documentData)
            return;
        if (webviewerInstance) {
            const { documentViewer } = webviewerInstance?.Core;
            const annotationsManager = documentViewer.getAnnotationManager();
            void documentViewer.closeDocument();
            void documentViewer.loadDocument(doc.file?.uri).then(() => {
                void annotationsManager.importAnnotationCommand(documentData?.xfdfString);
                annotationsManager.addEventListener('annotationChanged', handleAnnotationChanged);
            });
            return;
        }
        void WebViewer({
            path: '/webviewer/lib/',
            initialDoc: documentData.file?.uri,
            licenseKey: 'Govrn SA (govrn.com):OEM:Govrn::B+:AMS(20231229):9ECC071307DAD4F30B313BC9B263192F4E6F7FB766BD8AA5BD7B8A95A7F9D064EAB6F5C7',
            disabledElements: ['moreItemsButton', 'PencilKitDrawing'],
            extension: documentType,
            fullAPI: true,
        }, viewerRef.current).then(instance => {
            setWebviewerInstance(instance);
            setPDFtronInstance(instance);
            const { disableElements, setToolbarGroup, disableFeatures, iframeWindow, } = instance.UI;
            const { documentViewer } = instance.Core;
            const annotationsManager = documentViewer.getAnnotationManager();
            documentViewer.addEventListener('documentLoaded', () => {
                void annotationsManager.importAnnotationCommand(documentData?.xfdfString);
                annotationsManager.addEventListener('annotationChanged', handleAnnotationChanged);
            });
            const mainHeader = iframeWindow.document.querySelector('.MainHeader');
            mainHeader.setAttribute('style', 'background-color: white');
            disableFeatures([
                instance.UI.Feature.Print,
                instance.UI.Feature.Download,
            ]);
            disableElements([
                'toolbarGroup-Edit',
                'toolbarGroup-FillAndSign',
                'toolbarGroup-Forms',
                'toolbarGroup-Insert',
                'toolbarGroup-Measure',
                'toolbarGroup-View',
            ]);
            if (documentKind === DocumentKindEnum.TEMPLATE) {
                disableFeatures([instance.UI.Feature.Annotations]);
            }
            setToolbarGroup('toolbarGroup-Annotate');
            annotationsManager.addEventListener('annotationChanged', handleAnnotationChanged);
        });
    }, [documentData]);
    return {
        documentData,
        isDocumentLoading,
        viewerRef,
        documentType,
        updateDocumentAnnotations,
    };
};
export default useLogic;
