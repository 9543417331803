import { t } from 'i18next';
import React from 'react';
import TouchableScale from 'react-native-touchable-scale';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import MenuItem from '../../../../components/pickers/menu/menu-item';
import DocumentCard from '../../../../components/surfaces/document-card-event';
import { Size } from '../../../../components/utility/loading/size';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import TagField from '../components/inputs/tag-field';
import useLogic from './logic.web';
import styles from './style';
import { HStack, Stack, StackDivider, VStack, Text as ChakraText, } from '@chakra-ui/react';
import Modal from '../../../../components/surfaces/modal/index.web';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../components/inputs/tag-field/tag-item/index.web';
import AvatarDetails from '../../../../components/content/avatar-details';
const EventDetailsProxy = ({ enrolment, isOpen, onClose, proxyEntity, }) => {
    // Attributes
    const { form, handleOnSubmit, isUploadProxyLoading, peopleInTheCompany, handleOnClickNewFile, isOpenAssign, onToggleAssign, } = useLogic(onClose, proxyEntity, enrolment);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} hasCloseButton actionButton={<Button onPress={handleOnSubmit} isLoading={isUploadProxyLoading} size={Size.COMPACT}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.White.T500.value}>
            {t(tKeys.common.generate)}
          </Text>
        </Button>} title={t(tKeys.home.calendar.event_details.upload_proxy.title)}>
      {/* Main content */}
      <VStack alignItems={'flex-start'} width={'full'} divider={<StackDivider />} minH={'30rem'}>
        {/* Assign to */}
        <TagField endEnhancer={<PopOver isPortal={false} from={<TagFieldItem value={form?.peopleAssigned?.value?.email ??
                    t(tKeys.tasks.create_task.assign_to.assigned_members)}/>} isOpen={isOpenAssign} onToggle={onToggleAssign}>
              <VStack alignItems={'flex-start'} width={'full'} px={'1.75rem'} py={'1.25rem'} spacing={'1rem'} maxHeight={'12rem'} overflow={'auto'} zIndex={1}>
                {!!peopleInTheCompany &&
                peopleInTheCompany.map(person => (<HStack justify="space-between" key={person.id} width={'full'} onClick={() => {
                        form?.peopleAssigned?.onChange(person);
                        onToggleAssign();
                    }}>
                      <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
                    </HStack>))}
              </VStack>
            </PopOver>} title={`${t(tKeys.taskDetail.taskCard.assignTo)} `} startEnhancer={<Icon name="invitees" width={32} height={32}/>} error={form?.peopleAssigned?.meta?.error}/>

        {/* Add signed proxy document */}
        <VStack spacing={'1rem'} p={'1rem'} width={'full'} alignItems={'flex-start'}>
          <MenuItem title={t(tKeys.home.calendar.event_details.proxy.title)} startEnhancer={<Icon name="add-document-blue" width={32} height={32}/>} endEnhancer={<Icon name="chevron-right" width={20} height={20} fill={'transparent'}/>} endEnhancerStyle={styles.endEnhancer} startEnhancerStyle={{ marginRight: 12 }} onPress={handleOnClickNewFile}/>

          {form?.document?.value ? (<DocumentCard startEnhancer={<Stack style={styles.documentIcon}>
                  <Icon name="files" height={14} width={14} fill={COLORS.InputText.value}/>
                </Stack>} endEnhancer={<TouchableScale onPress={() => {
                    form?.document?.onChange(undefined);
                    proxyEntity.document = undefined;
                }} style={styles.documentDeleteButton} disabled={false} activeScale={0.98} hitSlop={{ bottom: 12, left: 12, right: 12, top: 12 }}>
                  <Icon name="cross" height={10} width={10} fill={COLORS.White.T500.value}/>
                </TouchableScale>} width={'100%'}>
              <ChakraText isTruncated>
                {form?.document?.value?.documentMetaData?.name ||
                form?.document?.value?.documentMetaData?.filename}
              </ChakraText>
            </DocumentCard>) : null}
        </VStack>
      </VStack>

      {/* Modal */}
      {/* <Toast position="top" config={ToastConfiguration} topOffset={60} /> */}
    </Modal>);
};
export default EventDetailsProxy;
