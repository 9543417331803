import { stackNavigator } from '../../routes/navigators';
import otherUserProfileRoutes from './other-user-profile/routes';
import userProfileRoutes from './user-profile/routes';
const profileRoutes = [
    {
        name: '/profile',
        deepLink: '/profile',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [...userProfileRoutes, ...otherUserProfileRoutes],
    },
];
export default profileRoutes;
