import useRouter from '../../../../hooks/router/useRouter';
import { useReadMe } from '../../../../services/online/people/people';
import getStaleTime from '../../../../utils/getStaleTime';
const useLogic = () => {
    // Attributes
    const { goBack } = useRouter();
    // Queries
    const { data: currentUserData } = useReadMe({
        query: {
            staleTime: getStaleTime(),
            retry: 3,
        },
    });
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    // Return
    return {
        handleOnPressBackButton,
        currentUserData,
    };
};
export default useLogic;
