import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    tabMainContainer: {
        marginHorizontal: 12,
        paddingTop: 0,
        marginTop: 0,
    },
    activeBar: {
        borderWidth: 1,
        borderTopLeftRadius: 100,
        borderTopRightRadius: 100,
        borderColor: COLORS.Govrn.Blue.T500.value,
        backgroundColor: COLORS.Govrn.Blue.T500.value,
        width: '100%',
        height: 3,
        marginTop: 12,
    },
});
export default styles;
