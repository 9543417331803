import React from 'react';
import Avatar from '../../../../../../../components/content/avatar';
import Text from '../../../../../../../components/content/text';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { HStack, Stack, VStack } from '@chakra-ui/react';
const CardHeader = ({ imageUri, title, subTitle, imageWidth = 30, imageHeight = 30, titleFont = FONTS.T1.T14Px.SemiBold600.value, titleColor = COLORS.Text.T400.value, titleNbOfLines = 1, titleMaxWidth = '100%', onPressMemberName, memberId, actionButton, }) => {
    // Render
    return (<HStack width={'full'} justifyContent={'space-between'}>
      {/* Image */}
      <Stack>
        <Avatar imageSource={imageUri} width={imageWidth} height={imageHeight} borderRadius={150} name={title} placeholderFont={FONTS.T1.T12Px.Bold700.value} isOnline={undefined}/>
      </Stack>

      <VStack width={'full'} alignItems="flex-start">
        {/* Title */}
        {title && (<Stack onClick={() => onPressMemberName?.(memberId)}>
            <Text font={titleFont} color={titleColor} numberOfLines={titleNbOfLines} lineBreakMode="tail">
              {title}
            </Text>
          </Stack>)}

        {/* Subtitle */}
        {subTitle && (<Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.InputText.value}>
            {subTitle}
          </Text>)}
      </VStack>

      <Stack>{actionButton}</Stack>
    </HStack>);
};
export default CardHeader;
