import * as React from 'react';
import Svg, { Rect, G, Path, Defs, ClipPath } from 'react-native-svg';
const TaskSign = (props) => (<Svg viewBox="0 0 36 36" fill="none" {...props}>
    <Rect width={36} height={36} rx={8} fill="#E7F3FB"/>
    <G clipPath="url(#clip0_6630_79509)">
      <Path d="M19.8765 11.957L10.4267 21.4068C10.3842 21.4493 10.3566 21.5045 10.3481 21.564L9.71913 25.9668C9.69294 26.1501 9.85007 26.3072 10.0334 26.281L14.4362 25.6521C14.4957 25.6436 14.5508 25.616 14.5933 25.5735L24.0853 16.0816M19.8765 11.957L21.9703 9.86317C22.0788 9.75469 22.2547 9.75469 22.3632 9.86317L26.137 13.637C26.2455 13.7455 26.2455 13.9214 26.137 14.0298L24.0853 16.0816M19.8765 11.957L24.0853 16.0816" stroke="#3999DE" strokeWidth={1.7}/>
    </G>
    <Defs>
      <ClipPath id="clip0_6630_79509">
        <Rect width={20} height={20} fill="white" transform="translate(8 8)"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default TaskSign;
