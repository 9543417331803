import { StyleSheet } from 'react-native';
import COLORS from '../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        height: '100%',
    },
    imageContainer: {
        height: 265,
    },
    innerImageContainer: {
        width: '100%',
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleAndNotificationContainer: {
        alignContent: 'center',
        borderColor: COLORS.Disabled.value,
        borderBottomWidth: 1,
    },
    formControlContainer: {
        backgroundColor: COLORS.White.T500.value,
        paddingTop: 20,
        paddingBottom: 24,
        paddingHorizontal: 12,
    },
    inputContainerStyle: {
        marginVertical: 8,
    },
    descriptionContainer: {
        paddingTop: 24,
        paddingHorizontal: 12,
        minHeight: 500,
    },
    fileTypeContainer: {
        borderColor: COLORS.Disabled.value,
        borderBottomWidth: 1,
        paddingLeft: 12,
        minHeight: 75,
        alignContent: 'center',
        justifyContent: 'center',
        paddingVertical: 24,
    },
    fileType: {
        marginTop: 8,
    },
    scrollView: { paddingBottom: 40 },
    section: { paddingHorizontal: 12, paddingVertical: 24 },
    separator: { width: 12 },
    signersContainer: {
        paddingHorizontal: 12,
        paddingBottom: 24,
    },
    tagContainer: {
        borderWidth: 1,
        borderColor: COLORS.Line.value,
        borderRadius: 100,
    },
    tagFieldContainer: { paddingBottom: 24 },
});
export default styles;
