/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { useDeleteInvitation, useReadEnrolments, useReadInvitations, useResendInvitation, useUpdateEnrolment, } from '../../../../services/online/enrolments/enrolments';
import { useReadMe } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getStaleTime from '../../../../utils/getStaleTime';
import { ShowMembers } from './types';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { Role } from '../../../../services/types';
import { useDeleteGroup, useReadGroups, } from '../../../../services/online/groups/groups';
const useLogic = () => {
    // Attributes
    const { push, params } = useRouter();
    const toast = useToast();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [selectedTab, setSelectedTab] = useState(0);
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    const [showMembersFilter, setShowMembersFilter] = useState(ShowMembers.ACTIVE);
    const [showActive, setShowActive] = useState(true);
    const [showInactive, setShowInactive] = useState(false);
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            searchValue: '',
        },
        onSubmit: undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Queries
    const { data: allEnrolmentForSelectedCompany, refetch: refetchAllEnrolmentForSelectedCompany, isLoading: isEnrolmentsLoading, isRefetching: isEnrolmentsRefetching, } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'search-term': form?.searchValue?.value,
        'page-size': 1000,
        'show-active': showActive,
        'show-intactive': showInactive,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { data: groups, isLoading: isGroupsLoading, refetch: refetchGroups, } = useReadGroups({
        'company-id': selectedCompanyId,
    });
    const { mutateAsync: updateEnrolment } = useUpdateEnrolment();
    const enrolments = allEnrolmentForSelectedCompany?.items;
    const { data: invitations, isLoading: isInvitationsLoading, refetch: refetchInvitations, } = useReadInvitations({
        'company-id': selectedCompanyId,
        'search-term': form?.searchValue?.value,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { data: currentUser } = useReadMe({
        query: { staleTime: getStaleTime() },
    });
    const { mutateAsync: resendInvitation } = useResendInvitation();
    const { mutateAsync: deleteInvitation } = useDeleteInvitation();
    const { mutateAsync: deleteGroup } = useDeleteGroup();
    // Functions
    function handleOnPressTab(activeKey) {
        setSelectedTab(activeKey);
    }
    function handleOnPressMember(memberId) {
        push('/company/profile/other-user-profile', {
            companyId: selectedCompanyId,
            personId: memberId,
        });
    }
    function handleShowMembersFilterChange(filter) {
        const filterConfig = {
            [ShowMembers.ALL]: { showActive: true, showInactive: true },
            [ShowMembers.ACTIVE]: { showActive: true, showInactive: false },
            [ShowMembers.INACTIVE]: { showActive: false, showInactive: true },
            default: { showActive: true, showInactive: true },
        };
        const { showActive, showInactive } = filterConfig[filter] || filterConfig.default;
        setShowMembersFilter(filter);
        setShowActive(showActive);
        setShowInactive(showInactive);
    }
    async function handleOnResendInvitation(invitationId) {
        await resendInvitation({
            invitationId,
        }, {
            onSuccess: () => {
                void refetchInvitations();
                toast({
                    status: 'success',
                    title: t(tKeys.invitation.resend.success),
                });
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.invitation.resend.error),
                });
                console.error('Error when resending an invitation : ', err);
            },
        });
    }
    async function handleOnDeleteMemberInvitation(invitationId) {
        await deleteInvitation({
            invitationId,
        }, {
            onSuccess: () => {
                void refetchInvitations();
                toast({
                    status: 'success',
                    title: t(tKeys.invitation.delete.success),
                });
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.invitation.delete.error),
                });
                console.error('Error when resending an invitation : ', err);
            },
        });
    }
    async function handleGroupDelete(groupId) {
        try {
            await deleteGroup({
                groupId,
                params: { 'company-id': selectedCompanyId },
            });
            await refetchGroups();
            toast({
                status: 'success',
                title: t(tKeys.groups.delete.success),
            });
        }
        catch (err) {
            toast({
                status: 'error',
                title: t(tKeys.groups.delete.error),
            });
            console.error('Error when deleting a group : ', err);
        }
    }
    function refetchAfterInvite() {
        void refetchAllEnrolmentForSelectedCompany();
        void refetchInvitations();
    }
    async function handleEnrolmentUpdate(enrolment, platformRole, boardRoleId, endedOn) {
        try {
            await updateEnrolment({
                data: {
                    ...enrolment,
                    role: platformRole,
                    boardRoleId,
                    endedOn,
                },
            });
            await refetchAllEnrolmentForSelectedCompany();
        }
        catch (err) {
            console.error('Error when updating the enrolment : ', err);
        }
    }
    // Effects
    useEffect(() => {
        void refetchAllEnrolmentForSelectedCompany();
    }, [params]);
    // Return
    return {
        enrolments,
        refetchAllEnrolmentForSelectedCompany,
        isEnrolmentsLoading,
        isEnrolmentsRefetching,
        handleOnPressTab,
        selectedTab,
        setSelectedTab,
        selectedCompanyId,
        form,
        handleOnPressMember,
        isAdmin,
        invitations: invitations?.filter(invitation => enrolments?.find(enrolment => enrolment?.email === invitation?.email) === undefined),
        isInvitationsLoading,
        handleOnResendInvitation,
        handleOnDeleteMemberInvitation,
        currentUserId: currentUser?.person?.id,
        refetchAfterInvite,
        handleEnrolmentUpdate,
        groups,
        isGroupsLoading,
        refetchGroups,
        handleGroupDelete,
        handleShowMembersFilterChange,
        showMembersFilter,
    };
};
export default useLogic;
