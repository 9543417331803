import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';
const Repository = (props) => (<Svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
    <Rect width="36" height="36" rx="8" fill="#E7F3FB"/>
    <Path d="M18 25.3911V17.9697" stroke="#3999DE" strokeWidth={1.6} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M11.5727 14.2593L18.0001 17.9773L24.4275 14.2593" stroke="#3999DE" strokeWidth={1.6} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M24.6262 20.9148V15.0249C24.6259 14.7666 24.5578 14.513 24.4286 14.2895C24.2993 14.0659 24.1136 13.8803 23.89 13.7511L18.7362 10.8062C18.5124 10.6769 18.2585 10.6089 18 10.6089C17.7415 10.6089 17.4876 10.6769 17.2637 10.8062L12.11 13.7511C11.8864 13.8803 11.7007 14.0659 11.5714 14.2895C11.4422 14.513 11.374 14.7666 11.3738 15.0249V20.9148C11.374 21.173 11.4422 21.4266 11.5714 21.6502C11.7007 21.8738 11.8864 22.0594 12.11 22.1885L17.2637 25.1335C17.4876 25.2627 17.7415 25.3308 18 25.3308C18.2585 25.3308 18.5124 25.2627 18.7362 25.1335L23.89 22.1885C24.1136 22.0594 24.2993 21.8738 24.4286 21.6502C24.5578 21.4266 24.6259 21.173 24.6262 20.9148Z" stroke="#3999DE" strokeWidth={1.6} strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M21.3132 16.055L14.687 12.2339" stroke="#3999DE" strokeWidth={1.6} strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>);
export default Repository;
