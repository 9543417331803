import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import styles from './style';
import { Button, useDisclosure } from '@chakra-ui/react';
const ButtonPresenceStatus = ({ title, onPress, iconName, isSelected, colors, strokeWidth = 0.5, height, }) => {
    // Attributes
    const { isOpen: isOnHover, onOpen: onHoverTrue, onClose: onHoverFalse, } = useDisclosure();
    // Render
    return (<Button borderColor={COLORS.Line.value} borderWidth={1} borderRadius={'1.5rem'} onClick={onPress} height={height} p={0} width={'100%'} _hover={{
            bgColor: COLORS.Text.T400.value,
        }} bgColor={isSelected ? COLORS.Text.T400.value : COLORS.White.T500.value} onMouseEnter={onHoverTrue} onMouseLeave={onHoverFalse}>
      <Flex style={styles.innerButtonContainer}>
        <Icon name={iconName} width={16} height={16} color={colors} stroke={colors} strokeWidth={strokeWidth}/>
        <Text font={FONTS.T1.T12Px.Medium500.value} style={styles.textContainer} color={isSelected || isOnHover
            ? COLORS.White.T500.value
            : COLORS.Text.T400.value}>
          {title}
        </Text>
      </Flex>
    </Button>);
};
export default ButtonPresenceStatus;
