import { t } from 'i18next';
import { useMemo, useState } from 'react';
import COLORS from '../../../../../../constants/colors';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { useReadMe, useReadPerson, } from '../../../../../../services/online/people/people';
import { tKeys } from '../../../../../../translations/keys';
import getStaleTime from '../../../../../../utils/getStaleTime';
import { PresenceStatus } from '../../../event-details/types';
const useLogic = (invitee) => {
    // Attributes
    const [isOpen, setIsOpen] = useState();
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: currentUserData } = useReadMe();
    const inviteeIsCurrentUser = useMemo(() => invitee?.personId === currentUserData?.person?.id, [invitee, currentUserData]);
    const { data: person } = useReadPerson(invitee?.personId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!invitee?.personId && !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    const { data: proxy } = useReadPerson(invitee?.proxyId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!invitee?.proxyId && !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    // Functions
    function getPresenceStatusFromInvitee() {
        if (invitee?.isRemote) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.remote),
                iconName: 'remote',
                isSelected: true,
                colors: COLORS.Warning.T500.value,
                status: PresenceStatus.Remote,
            };
        }
        if (invitee?.proxyId) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.proxy),
                iconName: 'proxy',
                isSelected: true,
                colors: COLORS.Warning.T500.value,
                status: PresenceStatus.Proxy,
            };
        }
        if (invitee?.acceptedOn) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.present),
                iconName: 'check',
                isSelected: true,
                colors: COLORS.Success.T500.value,
                status: PresenceStatus.Present,
            };
        }
        if (invitee?.refusedOn && !invitee.proxyId) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.absent),
                iconName: 'close-cross',
                isSelected: true,
                colors: COLORS.Error.T500.value,
                status: PresenceStatus.Absent,
            };
        }
        return {
            title: t(tKeys.home.calendar.event_details.information.presence.status.pending),
            iconName: 'event-status-pending',
            isSelected: true,
            colors: COLORS.InputText.value,
            status: PresenceStatus.Pending,
        };
    }
    function handleOpenState() {
        setIsOpen(prev => !prev);
    }
    // Return
    return {
        isOpen,
        getPresenceStatusFromInvitee,
        person,
        proxy,
        handleOpenState,
        inviteeIsCurrentUser,
        inviteeResponded: !!invitee?.acceptedOn || !!invitee?.refusedOn,
    };
};
export default useLogic;
