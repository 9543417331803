import React from 'react';
import AvatarDetails from '../../../../../components/content/avatar-details';
import FONTS from '../../../../../constants/fonts';
import COLORS from '../../../../../constants/colors';
import { tKeys } from '../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/react';
import Tag from '../../../../../components/content/tag/index.web';
const UserSignStatus = ({ id, isUserAlreadySigned, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<HStack justifyContent={'space-between'} width={'full'} py={'.5rem'}>
      <AvatarDetails avatarSize={'2.25rem'} avatarFont={FONTS.T1.T10Px.Medium500.value} showBoardRole personId={id}/>

      {/* Tag when already signed */}
      {isUserAlreadySigned(id) && (<Tag backgroundColor={COLORS.Success.T100.value} iconName="check" iconColor={COLORS.Success.T500.value} color={COLORS.Text.T400.value} style={{ borderRadius: 100 }}>
          {t(tKeys.common.signed)}
        </Tag>)}
    </HStack>);
};
export default UserSignStatus;
