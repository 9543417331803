import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, languages } from './config';
import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';
import es from './es.json';
const locales = { en, fr, nl, es };
const getResources = async (languages) => {
    let defaultLocales = locales;
    try {
        // Retrieves translations
        const data = await axios.get(`https://translation-backend-mhg6wf2o7a-ew.a.run.app/organisations/25/projects/62/export`);
        // Verifies HTTP status for request
        if (data.status === 200 && data.data) {
            defaultLocales = {};
            // Group by languages
            data.data.forEach(row => {
                if (!defaultLocales[row.language])
                    defaultLocales[row.language] = {};
                defaultLocales[row.language][row.term] = row.translation;
            });
        }
    }
    catch (e) {
        console.warn(e);
        defaultLocales = locales;
    }
    return languages?.reduce((acc, language) => ({
        ...acc,
        [language]: {
            translation: {
                ...defaultLocales[language],
            },
        },
    }), {});
};
export const getI18nInstance = () => {
    async function init() {
        void i18n.use(initReactI18next).init({
            compatibilityJSON: 'v3',
            lng: defaultLanguage,
            fallbackLng: defaultLanguage,
            resources: await getResources(languages),
            keySeparator: false,
            returnEmptyString: false,
        });
    }
    void init();
    return i18n;
};
