export const mapDocumentPickerResponseToIFileType = (document) => ({
    name: document.name,
    size: document.size,
    type: document.type,
    content: document.content,
    fileCopyUri: '',
    uri: '',
    copyError: '',
});
export const mapDocumentReactImageToIimageType = (document) => ({
    mime: document.mime,
    path: document.path,
    size: document.size,
    filename: document.filename,
    data: document.content,
    height: 0,
    width: 0,
});
