import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    groupIconContainer: {
        backgroundColor: COLORS.Line.value,
        borderRadius: 44,
        justifyContent: 'center',
        alignItems: 'center',
    },
    alertDot: {
        width: 6,
        height: 6,
        borderRadius: 6,
        backgroundColor: COLORS.Error.T500.value,
    },
});
export default styles;
