import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../hooks/router/useRouter';
import { useConfirmInvitation, useAcceptInvitation, } from '../../../services/online/enrolments/enrolments';
import { useConfirmForgotPassword, useConfirmAuthentication, useForgotPassword, useAuthenticatePerson, } from '../../../services/online/people/people';
import { tKeys } from '../../../translations/keys';
import { createForm } from '../../../utils/formik';
import { saveTokens } from '../../../utils/handleTokens';
import { ToConfirmAuthenticationParams, ToConfirmForgotPasswordParams, ToConfirmInvitationParams, } from './mappers';
import { validationSchema } from './validations';
import { useToast } from '../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../contexts/companies';
const MAX_SMS_CODE_LENGTH = 5;
const useLogic = () => {
    // Attributes
    const { push, goBack, reset, params } = useRouter();
    const { t } = useTranslation();
    const toast = useToast();
    const sourcePageName = params?.sourcePageName;
    const signInEntity = params?.signInEntity;
    const signUpEntity = params?.signUpEntity;
    // const userPhoneNumber = ''; // TODO: Get the phone number related to the entered email and state it with: const [userPhoneNumber, setUserPhoneNumber] = useState<string>('')
    // Queries
    const { mutateAsync: confirmInvitation, isLoading: isConfirmInvitationIsLoading, } = useConfirmInvitation();
    const { mutateAsync: confirmForgotPassword, isLoading: isConfirmForgotPasswordIsLoading, } = useConfirmForgotPassword();
    const { mutateAsync: confirmAuthentication, isLoading: isConfirmAuthenticationLoading, } = useConfirmAuthentication({ request: { useRefreshToken: true } });
    const { mutateAsync: acceptInvitation } = useAcceptInvitation();
    const { mutateAsync: forgotPassword } = useForgotPassword();
    const { mutateAsync: authenticatePerson, isLoading: isAuthenticatePersonLoading, } = useAuthenticatePerson({
        mutation: {
            retry: 3,
        },
    });
    const { refetchMe } = useCompaniesContext();
    // Function on submit
    const handlerOnSubmit = async (values) => {
        switch (sourcePageName) {
            case '/sign-up':
                await handleConfirmInvitation(values);
                break;
            case '/sign-in-forgot-address-mail':
                await handleConfirmForgotPassword(values);
                break;
            default:
                await handleSignIn(values);
        }
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            smsCode: '',
        },
        onSubmit: handlerOnSubmit,
        validationSchema: validationSchema(t),
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        onReset(values) {
            values.smsCode = '';
        },
    });
    const { submitForm, setFieldError } = rest;
    const form = createForm(values, rest);
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const onButtonClickResendCode = async () => {
        // Reset the form sms code
        form?.smsCode?.onChange?.('');
        // Focus on the first input field
        document.getElementsByTagName('input')?.[0].focus();
        switch (sourcePageName) {
            case '/sign-up':
                await acceptInvitation({
                    invitationId: signUpEntity.invitationId,
                    params: {
                        phone: signUpEntity.phoneNumber,
                    },
                }, {
                    onError: err => {
                        setFieldError('smsCode', t(tKeys.validation['2fa'].smsCode.invalid));
                        console.error('Error with the confirm invitation : ', err);
                    },
                });
                break;
            case '/sign-in-forgot-address-mail':
                // TODO: Indicate success "An email has been sent" + string in green
                await forgotPassword({
                    params: {
                        email: signInEntity?.email,
                    },
                }, {
                    onSuccess: () => {
                        toast({
                            status: 'success',
                            title: t(tKeys['sign-in-forgot-mail'].success.sms_sent),
                        });
                    },
                    onError: err => {
                        console.error('Error with the forgot password initialization : ', err);
                        toast({
                            status: 'error',
                            title: t(tKeys['sign-in-forgot-mail'].errors.email_not_sent),
                        });
                    },
                });
                break;
            default:
                await authenticatePerson({
                    data: {
                        email: signInEntity?.email,
                        password: signInEntity?.password,
                    },
                }, {
                    onSuccess: response => {
                        signInEntity.accessToken = response?.accessToken;
                        signInEntity.refreshToken = response?.refreshToken;
                        toast({
                            status: 'success',
                            title: t(tKeys['sign-in-forgot-mail'].success.sms_sent),
                        });
                    },
                    onError: err => {
                        console.error('Error with authenticate person : ', err);
                    },
                });
                break;
        }
    };
    const handleConfirmInvitation = async (values) => {
        // confirm invitation after a sign-up flow
        await confirmInvitation({
            invitationId: signUpEntity.invitationId,
            params: ToConfirmInvitationParams(values),
        }, {
            onSuccess: (res) => {
                // If the user exist then redirect to sign-in
                if (res.person) {
                    push('/auth/sign-in/default');
                    return;
                }
                // Else redirect to the /auth/sign-up/create-account
                // with the sms-code, invitationId and phoneNumber (that are needed to create a person)
                const signUpCreateNewAccountEntity = {
                    email: '',
                    firstName: '',
                    cultureName: 'fr-FR',
                    lastName: '',
                    password: '',
                    smsCode: form?.smsCode?.value,
                    timezoneId: 'Europe/Brussels',
                    invitationId: signUpEntity.invitationId,
                    phoneNumber: signUpEntity.phoneNumber,
                };
                push('/auth/sign-up/create-account', {
                    signUpCreateNewAccountEntity,
                });
            },
            onError: err => {
                setFieldError('smsCode', t(tKeys.validation['2fa'].smsCode.invalid));
                console.error('Error with double authentification : ', err);
            },
        });
    };
    const handleConfirmForgotPassword = async (values) => {
        // It's a request for a forgot password
        await confirmForgotPassword({
            params: ToConfirmForgotPasswordParams(values, signInEntity.email),
        }, {
            onSuccess: res => {
                const signInForgotPasswordEntity = {
                    email: signInEntity.email,
                    token: res?.value,
                    smsCode: values.smsCode,
                    newPassword: '',
                };
                // It's a reset password flow
                push('/auth/sign-in/forgot-password/forgot-password-create-new-password', {
                    signInForgotPasswordEntity,
                });
            },
            onError: err => {
                setFieldError('smsCode', t(tKeys.validation['2fa'].smsCode.invalid));
                console.error('Error with forgot confirm password : ', err);
            },
        });
    };
    const handleSignIn = async (values) => {
        // Then it's a sign-in
        await confirmAuthentication({
            params: ToConfirmAuthenticationParams(values),
        }, {
            onSuccess: (res) => {
                // Save the news tokens
                void saveTokens({
                    accessToken: res?.accessToken,
                    refreshToken: res?.refreshToken,
                });
                refetchMe('/company/home/default');
                // Redirect to the home page
                // push('/company/home/default');
            },
            onError: err => {
                setFieldError('smsCode', t(tKeys.validation['2fa'].smsCode.invalid));
                console.error('Error with double authentification : ', err);
            },
        });
    };
    function isButtonDisabled() {
        return (isConfirmInvitationIsLoading ||
            isConfirmForgotPasswordIsLoading ||
            isConfirmAuthenticationLoading);
    }
    // Effects
    useEffect(() => {
        if (form?.smsCode?.value?.length === MAX_SMS_CODE_LENGTH) {
            void submitForm();
        }
    }, [form?.smsCode?.value?.length]);
    // Return
    return {
        handleOnPressBackButton,
        form,
        maximumLength: MAX_SMS_CODE_LENGTH,
        isConfirmInvitationIsLoading,
        isConfirmForgotPasswordIsLoading,
        isConfirmAuthenticationLoading,
        isAuthenticatePersonLoading,
        isButtonDisabled,
        onButtonClickResendCode,
        handlerOnSubmit: submitForm,
        phoneNumber: signUpEntity?.phoneNumber,
    };
};
export default useLogic;
