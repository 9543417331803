/**
 * Converts an array of people to an array of invitees for an event.
 * If the people array contains a person with an ID that matches an existing invitee,
 * the existing invitee is added to the returned array.
 * If the people array does not contain a matching person, a new invitee is created for each person.
 * @param {Person[]} people - The array of people to be converted to invitees.
 * @param {Invitee[]} existingInvitees - The array of existing invitees for the event.
 * @param {string} eventId - The ID of the event.
 * @return {Invitee[]} The array of invitees.
 */
function personToInvitee(people, existingInvitees, eventId) {
    // create an empty array to store the invitees
    let invitees = [];
    // check if existingInvitees is an array and if it has a length
    if (typeof existingInvitees === 'object' && existingInvitees?.length) {
        // loop through the existing invitees
        existingInvitees?.forEach(invitee => {
            // check if people is an array
            if (typeof people === 'object') {
                // filter the people array to find the person with a matching id
                const person = people.filter(p => p?.id === invitee?.personId)?.[0];
                // if a matching person is found, add the invitee to the invitees array
                if (person) {
                    invitees = invitees?.concat(invitee);
                }
            }
        });
    }
    // if there are no existing invitees or people is not an array, create new invitees for each person
    if (typeof people === 'object') {
        // filter the people array to exclude people with matching IDs to existing invitees
        const filteredPeople = people?.filter(p => {
            let found = false;
            // loop through the existing invitees
            invitees?.forEach(i => {
                // if the person's ID matches an existing invitee's person ID, set found to true
                if (i?.personId !== p?.id) {
                    found = true;
                }
            });
            // if found is true, include the person in the filtered array
            if (found) {
                return p;
            }
        });
        let newInvitees = [];
        if (!filteredPeople?.length) {
            newInvitees = people?.map(person => ({
                eventId,
                personId: person?.id,
                proxyId: null,
                refusedOn: null,
                acceptedOn: null,
                isRemote: null,
            }));
        }
        else {
            // create new invitees for each person in the filtered array
            newInvitees = filteredPeople?.map(person => ({
                eventId,
                personId: person?.id,
                proxyId: null,
                refusedOn: null,
                acceptedOn: null,
                isRemote: null,
            }));
        }
        // combine the new invitees with the existing invitees
        invitees = invitees.concat(newInvitees.filter(nI => !invitees.some(i => i.personId === nI.personId)));
    }
    // return the array of invitees
    return invitees;
}
export default personToInvitee;
