import { useEffect } from 'react';
import personToInvitee from '../../../../mappers/personToInvitee';
import getInviteesFromEvent from '../../../../utils/getInviteesFromEvent';
/**
 * useEffect hook that updates an event's invitees based on selected people.
 * @param {Event} event - The event to update.
 * @param {Person[]} selectedPeople - The selected people to be added as invitees.
 * @param {Invitee[]} existingInvitees - The existing invitees for the event.
 * @param {string} eventId - The ID of the event.
 * @param {Function} setEvent - The function to update the event.
 * @param {Object} params - The params object passed to the component.
 * @param {Function} updateEvent - The function to update the event in the backend.
 * @param {Object} queryClient - The React Query query client.
 */
function useUpdateEventInvitees(event, existingInvitees, setEvent, params, updateEvent, queryClient, selectPeopleEntity) {
    useEffect(() => {
        if (
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        (params && params?.selectPeopleEntity?.selectedPeople) ||
            params?.selectPeopleEntity?.allPeopleInTheCompany) {
            // assign the selected people to the selectPeopleEntity
            selectPeopleEntity.selectedPeople =
                params?.selectPeopleEntity?.selectedPeople;
            // filter the allPeopleInTheCompany array to remove any falsy values
            selectPeopleEntity.allPeopleInTheCompany =
                params?.selectPeopleEntity?.allPeopleInTheCompany?.filter(p => p);
            // convert the selected people to invitees
            const invitees = personToInvitee(selectPeopleEntity.selectedPeople, existingInvitees, event?.id);
            // update the event's invitees and invitee IDs
            const updatedEvent = {
                ...event,
            };
            updatedEvent.inviteeIds = invitees?.map(i => i.personId);
            updatedEvent.invitees = [...invitees];
            // update the event state
            setEvent(updatedEvent);
            const { absentAttendeeIds, presentAttendeeIds, remoteAttendeeIds } = getInviteesFromEvent(event);
            // update the event in the backend
            void updateEvent({
                data: {
                    ...event,
                    absentAttendeeIds,
                    presentAttendeeIds,
                    remoteAttendeeIds,
                    inviteeIds: [...updatedEvent?.inviteeIds],
                },
            }, {
                onSuccess: () => {
                    // invalidate the event query to force a refetch
                    void queryClient.invalidateQueries([
                        `/calendar/events/${event.id}`,
                    ]);
                },
            });
        }
    }, [params]);
}
export default useUpdateEventInvitees;
