import React from 'react';
import Button from '../../../../../../components/inputs/button';
import FormControl from '../../../../../../components/inputs/form-control';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import useUpsertBoardRoleLogic from './logic.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import Modal from '../../../../../../components/surfaces/modal/index.web';
import { Stack } from '@chakra-ui/react';
import Input from '../../../../../../components/inputs/input/index.web';
const UpsertBoardRole = ({ isOpen, onClose, refetchBoardRoles, boardRole, }) => {
    // Attributes
    const { handleOnSubmit, isCreateBoardRoleLoading, form, isUpdateBoardRoleLoading, } = useUpsertBoardRoleLogic(onClose, refetchBoardRoles, boardRole);
    const { t } = useTranslation();
    // Render
    return (<Modal size={'2xl'} hasCloseButton isOpen={isOpen} onClose={onClose} title={boardRole
            ? t(tKeys.settings.board_role.modify.title)
            : t(tKeys.settings.board_roles.add.button)} actionButton={<Button onPress={handleOnSubmit} isLoading={isCreateBoardRoleLoading || isUpdateBoardRoleLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
          {boardRole ? t(tKeys.common.save) : t(tKeys.common.create)}
        </Button>}>
      <Stack width={'full'} p={'1rem'}>
        <FormControl label={t(tKeys.common.name.label)} error={form?.boardRoleName?.meta?.error} spacing={4}>
          <Input value={form?.boardRoleName?.value} onChange={e => form?.boardRoleName?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common.name.placeholder)} autoFocus/>
        </FormControl>
      </Stack>
    </Modal>);
};
export default UpsertBoardRole;
