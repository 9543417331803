import { useCompaniesContext } from '../../../contexts/companies';
import useRouter from '../../../hooks/router/useRouter';
import { useReadMe } from '../../../services/online/people/people';
const useLogic = () => {
    // Attributes
    const { push } = useRouter();
    const { selectedEnrolment } = useCompaniesContext();
    // Queries
    const { data: currentUserData, isLoading: isReadMeLoading } = useReadMe();
    // Functions
    const handleNavigateTo = (routeName) => {
        push(routeName);
    };
    function onSearchPress() {
        push('/search');
    }
    // Return
    return {
        handleNavigateTo,
        currentUserData,
        isReadMeLoading,
        selectedEnrolment,
        onSearchPress,
    };
};
export default useLogic;
