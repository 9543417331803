import React from 'react';
import Text from '../../../../../components/content/text';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import useManageBoardRolesLogic from './logic.web';
import LoadingWrapper from '../../../../../components/utility/loading-wrapper';
import { HStack, Stack, StackDivider, VStack } from '@chakra-ui/react';
import Button from '../../../../../components/inputs/button/index.web';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size';
import UpsertBoardRole from './upsert-board-role/index.web';
const ManageBoardRoles = () => {
    // Attributes
    const { handleBoardRoleDelete, boardRoles, isReadBoardRolesLoading, isBoardRoleOpen, handleBoardRoleClose, handleBoardRolePressAdd, handleBoardRoleRefetch, handlePressEditableMenu, selectedBoardRole, } = useManageBoardRolesLogic();
    const { t } = useTranslation();
    // Render
    return (<HStack width={'full'} height={'28.75rem'} justifyContent={'space-between'}>
      <VStack alignItems={'flex-start'} height={'full'} width={'30%'} spacing={'1.25rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.settings.board_roles.manage)}
        </Text>
        <Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={handleBoardRolePressAdd}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color="white">
            {t(tKeys.settings.board_roles.add.button)}
          </Text>
        </Button>
      </VStack>
      <VStack alignItems={'flex-start'} height={'full'} width={'70%'} borderRadius={'.5rem'} bgColor={COLORS.Tag.value} borderWidth={1} spacing={0} overflow={'hidden'}>
        <Stack width={'full'} py={'1rem'} px={'1.25rem'}>
          <Text font={FONTS.T1.T12Px.Regular400.value}>
            {t(tKeys.common.name.label).toUpperCase()}
          </Text>
        </Stack>
        <LoadingWrapper isLoading={isReadBoardRolesLoading}>
          <VStack alignItems={'flex-start'} width={'full'} spacing={0} divider={<StackDivider />} overflow={'auto'}>
            {boardRoles?.map(boardRole => (<HStack key={boardRole?.id} width={'full'} bgColor={COLORS.White.T500.value} px={'1.25rem'} minHeight={'4rem'} justifyContent={'space-between'}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(boardRole?.name)}
                </Text>
                <HStack spacing={'.75rem'}>
                  {boardRole?.isEditable && (<>
                      <Button size={Size.SMALL} kind={Kind.OUTLINE} textColor={COLORS.Text.T400.value} onPress={() => handlePressEditableMenu(boardRole)}>
                        {t(tKeys.common.edit)}
                      </Button>
                      <Stack cursor="pointer" onClick={() => handleBoardRoleDelete(boardRole?.id)}>
                        <Text font={FONTS?.T1.T12Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                          {t(tKeys.news_feed.delete_post.title)}
                        </Text>
                      </Stack>
                    </>)}
                </HStack>
              </HStack>))}
          </VStack>
        </LoadingWrapper>
      </VStack>
      <UpsertBoardRole isOpen={isBoardRoleOpen} onClose={handleBoardRoleClose} boardRole={selectedBoardRole} refetchBoardRoles={handleBoardRoleRefetch}/>
    </HStack>);
};
export default ManageBoardRoles;
