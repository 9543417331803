import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCompaniesContext } from '../../contexts/companies';
import useRouter from '../../hooks/router/useRouter';
import { useSearch } from '../../services/online/search/search';
import { isMinute } from '../../utils/getDocumentKind';
import { DocumentKindEnum, } from '../document-details/type';
import { SearchResultType } from './components/content/search-result-card/props';
import { getSearchResultType } from './utils';
import { readChannel } from '../../services/online/chat/chat';
import { useDisclosure } from '@chakra-ui/react';
const initialFilters = {
    [SearchResultType.EVENT]: false,
    [SearchResultType.FILE]: false,
    [SearchResultType.MESSAGE]: false,
    [SearchResultType.POST]: false,
};
const initialResults = {
    [SearchResultType.EVENT]: 0,
    [SearchResultType.FILE]: 0,
    [SearchResultType.MESSAGE]: 0,
    [SearchResultType.POST]: 0,
};
const useLogic = () => {
    // Attributes
    const { push } = useRouter();
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [isResultsBlocked, setResultsBlocked] = useState(false);
    const [isFiltersDirty, setFiltersDirty] = useState(false);
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const popoverRef = useRef(null);
    const updateSearchDebounce = useCallback(_.debounce((value) => {
        setDebouncedSearch(value);
    }, 1000), []);
    // Disclosure
    const { isOpen: isOpenPostDetails, onOpen: onOpenPostDetails, onClose: onClosePostDetails, } = useDisclosure();
    const [postId, setPostId] = useState();
    const [activeFilters, setActiveFilters] = useState(initialFilters);
    const [resultsTotal, setResultsTotal] = useState(initialResults);
    // Effects
    useEffect(() => {
        setFiltersDirty(!_.isEqual(activeFilters, initialFilters));
    }, [activeFilters]);
    // Queries
    const { data: searchResult, refetch, isLoading, } = useSearch({
        'company-id': selectedCompanyId,
        'search-term': debouncedSearch,
        'order-by-date': true,
        'search-files': isFiltersDirty
            ? activeFilters[SearchResultType.FILE]
            : true,
        'search-events': isFiltersDirty
            ? activeFilters[SearchResultType.EVENT]
            : true,
        'search-posts': isFiltersDirty
            ? activeFilters[SearchResultType.POST]
            : true,
        'search-chats': isFiltersDirty
            ? activeFilters[SearchResultType.MESSAGE]
            : true,
    }, { query: { enabled: debouncedSearch?.length > 2 && !!selectedCompanyId } });
    useEffect(() => {
        if (!isResultsBlocked) {
            setResultsTotal({
                [SearchResultType.FILE]: searchResult?.filter(result => !!result?.document)?.length ?? 0,
                [SearchResultType.EVENT]: searchResult?.filter(result => !!result?.event)?.length ?? 0,
                [SearchResultType.MESSAGE]: searchResult?.filter(result => !!result?.message)?.length ?? 0,
                [SearchResultType.POST]: searchResult?.filter(result => !!result?.post)?.length ?? 0,
            });
        }
    }, [searchResult]);
    function onFilterPress(filterType) {
        const filtersCopy = { ...activeFilters };
        filtersCopy[filterType] = !filtersCopy[filterType];
        setActiveFilters(filtersCopy);
        setResultsBlocked(true);
    }
    function handleSearch(value) {
        setSearch(value);
        resetFilters();
        if (!value || value === '') {
            setIsOpenPopover(false);
            return;
        }
        setIsOpenPopover(true);
        updateSearchDebounce(value);
    }
    function resetFilters() {
        setActiveFilters(initialFilters);
        setResultsTotal(initialResults);
        setResultsBlocked(false);
        void refetch();
    }
    async function onSearchResultPress(result) {
        switch (getSearchResultType(result)) {
            case SearchResultType.FILE: {
                const params = {
                    documentId: result?.document?.id,
                    eventId: result?.document?.eventId,
                    documentKind: isMinute(result?.document)
                        ? DocumentKindEnum.MINUTES
                        : DocumentKindEnum.DOCUMENT,
                };
                push('/company/document-details', {
                    companyId: selectedCompanyId,
                    ...params,
                });
                break;
            }
            case SearchResultType.EVENT: {
                const params = { eventId: result?.event?.id };
                push('/company/event-details', {
                    companyId: selectedCompanyId,
                    ...params,
                });
                break;
            }
            case SearchResultType.POST: {
                setPostId(result?.post?.id);
                onOpenPostDetails();
                break;
            }
            case SearchResultType.MESSAGE: {
                try {
                    const channel = await readChannel(result?.message?.channelId);
                    const params = {
                        channel,
                    };
                    push('/company/home/messages', {
                        companyId: selectedCompanyId,
                        ...params,
                    });
                    break;
                }
                catch (err) {
                    console.error('Error when fetching the channel : ', err);
                }
            }
        }
        setIsOpenPopover(false);
    }
    function handleOnClickInput() {
        if (!isOpenPopover && !!search && search !== '')
            setIsOpenPopover(true);
    }
    function handleOnClosePopover() {
        setIsOpenPopover(false);
    }
    const total = useMemo(() => {
        return Object.values(resultsTotal).reduce((acc, cur) => acc + cur, 0);
    }, [resultsTotal]);
    // Return
    return {
        search,
        handleSearch,
        activeFilters,
        onFilterPress,
        resultsTotal,
        searchResult,
        isLoading,
        refetch,
        total,
        companyName: selectedEnrolment?.company?.name,
        onSearchResultPress,
        handleOnClickInput,
        isOpenPopover,
        postId,
        isOpenPostDetails,
        onClosePostDetails,
        handleOnClosePopover,
        popoverRef,
    };
};
export default useLogic;
