/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery } from '@tanstack/react-query';
import { customInstance } from '.././config';
export const search = (params, options, signal) => {
    return customInstance({ url: `/search`, method: 'get', params, signal }, options);
};
export const getSearchQueryKey = (params) => [
    `/search`,
    ...(params ? [params] : []),
];
export const useSearch = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getSearchQueryKey(params);
    const queryFn = ({ signal, }) => search(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
