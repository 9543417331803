import { stackNavigator } from '../../routes/navigators';
import { AIAssist } from './AI-Assist/index.web';
import calendarRoutes from './calendar/routes';
import feedRoutes from './feed/routes.web';
import Homepage from './homepage/index.web';
import messagesRoutes from './messages/routes';
import moreRoutes from './more/routes';
import taskRoutes from './tasks/routes';
const homeRoutes = [
    {
        name: '/home',
        deepLink: '/home',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: Homepage,
                tabBarLabel: 'homepage.title',
            },
            // News feed routes
            ...feedRoutes,
            // Tasks route
            ...taskRoutes,
            // Calendar routes
            ...calendarRoutes,
            // Messages routes
            ...messagesRoutes,
            {
                name: '/ai-assist',
                deepLink: '/ai-assist',
                component: AIAssist,
                tabBarLabel: 'home.navigation_bar.ai_assist',
            },
            // More routes
            ...moreRoutes,
        ],
    },
];
export default homeRoutes;
