import React from 'react';
import Button from '../../../components/inputs/button';
import FormControl from '../../../components/inputs/form-control';
import PasswordInput from '../components/inputs/password-input';
import { tKeys } from '../../../translations/keys';
import useLogic from './logic';
import { useTranslation } from 'react-i18next';
import { PageWebAuth } from '../../../components/surfaces/page-auth/index.web';
import { Stack, VStack } from '@chakra-ui/react';
const CreatePassword = () => {
    // Attributes
    const { handleOnPressBackButton, form, handleOnSubmit, isLoading } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<PageWebAuth onGoBack={handleOnPressBackButton} title={t(tKeys['create-password']['navigation-header'])}>
      {/* Create password form */}
      <VStack p={'2.5rem'} alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
        {/*  Password  */}
        <FormControl label={t(tKeys.common.password.label)} spacing={2}>
          <PasswordInput {...form?.password} placeholder={t(tKeys.common.password.placeholder)}/>
        </FormControl>
        {/* Confirm password */}
        <Stack width={'full'} pb={'1.5rem'}>
          <FormControl label={t(tKeys.common['confirm-password'].label)} spacing={2}>
            <PasswordInput {...form?.confirmPassword} placeholder={t(tKeys.common['confirm-password'].placeholder)}/>
          </FormControl>
        </Stack>

        {/* Buttons */}
        <Button onPress={handleOnSubmit} isLoading={isLoading} width={'full'}>
          {t(tKeys.common.confirm)}
        </Button>
      </VStack>
    </PageWebAuth>);
};
export default CreatePassword;
