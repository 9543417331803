import { useState } from 'react';
import { useCompaniesContext } from '../../../../../contexts/companies';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { tKeys } from '../../../../../translations/keys';
import { useDeleteBoardRole, useReadBoardRoles, } from '../../../../../services/online/enrolments/enrolments';
const useManageBoardRolesLogic = () => {
    // Queries
    const { t } = useTranslation();
    const toast = useToast();
    const { selectedCompanyId } = useCompaniesContext();
    const { data: boardRoles, refetch: refetchBoardRoles, isLoading: isReadBoardRolesLoading, } = useReadBoardRoles({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { mutateAsync: deleteBoardRole } = useDeleteBoardRole();
    // Attributes
    const [selectedBoardRole, setSelectedBoardRole] = useState();
    const { isOpen: isBoardRoleOpen, onOpen: handleBoardRoleOpen, onClose: handleBoardRoleClose, } = useDisclosure();
    // Functions
    const handleBoardRolePressModify = (boardRole) => {
        setSelectedBoardRole(boardRole);
        handleBoardRoleOpen();
    };
    const handleBoardRolePressAdd = () => {
        setSelectedBoardRole(undefined);
        handleBoardRoleOpen();
    };
    const handleBoardRoleDelete = (boardRoleId) => {
        void deleteBoardRole({
            boardRoleId,
            params: { 'company-id': selectedCompanyId },
        }, {
            onSuccess: () => {
                void refetchBoardRoles();
                toast({
                    status: 'success',
                    title: t(tKeys.settings.board_role.deleted.success),
                });
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.validation.common.error_occuried),
                });
                console.error('Error when delete a board role : ', err);
            },
        });
    };
    const handlePressEditableMenu = (item) => {
        if (!item.isEditable) {
            return undefined;
        }
        return handleBoardRolePressModify(item);
    };
    function handleBoardRoleRefetch() {
        void refetchBoardRoles();
    }
    // Return
    return {
        handlePressEditableMenu,
        handleBoardRoleDelete,
        boardRoles,
        handleBoardRolePressAdd,
        isReadBoardRolesLoading,
        selectedBoardRole,
        isBoardRoleOpen,
        handleBoardRoleClose,
        handleBoardRoleRefetch,
    };
};
export default useManageBoardRolesLogic;
