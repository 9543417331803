import React from 'react';
import { Input as ChakraInput, InputGroup, InputRightElement, } from '@chakra-ui/react';
function Input({ endEnhancer, ...props }) {
    // Render
    return (<InputGroup>
      <ChakraInput fontSize={'0.875rem'} fontWeight={400} pl={'.5rem'} {...props}/>
      {endEnhancer && (<InputRightElement paddingRight={'.5rem'} minWidth={'fit-content'}>
          {endEnhancer}
        </InputRightElement>)}
    </InputGroup>);
}
export default Input;
