import { t } from 'i18next';
import React from 'react';
import AvatarDetails from '../../../../../components/content/avatar-details';
import Text from '../../../../../components/content/text';
import Container from '../../../../../components/surfaces/container';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
const TaskDetailsHeaderList = ({ selectedPerson, }) => {
    return (<Container paddingHorizontal={12}>
      {/*  Render main components */}
      {selectedPerson && (<>
          <Container>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.tasks.create_task.assign_to.assigned_member)}
            </Text>
          </Container>
          <Container paddingVertical={20}>
            <AvatarDetails person={selectedPerson} showBoardRole/>
          </Container>
        </>)}

      <Container paddingBottom={14}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.tasks.create_task.assign_to.members)}
        </Text>
      </Container>
    </Container>);
};
export default TaskDetailsHeaderList;
