import { t } from 'i18next';
import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import Text from '../../content/text';
import { VStack } from '@chakra-ui/react';
const PlaceholderNoInvitees = () => {
    return (<VStack width={'full'} marginBottom={'1rem'}>
      {/* No invitees title */}
      <Text font={FONTS.T1.T14Px.SemiBold600.value}>
        {t(tKeys.home.calendar.event_details.information.no_invitees.title)}
      </Text>
      {/* No invitees body text */}
      <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.InputText.value} numberOfLines={2}>
        {t(tKeys.home.calendar.event_details.information.no_invitees.body_text)}
      </Text>
    </VStack>);
};
export default PlaceholderNoInvitees;
