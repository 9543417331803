import React from 'react';
import Text from '../../../../../../components/content/text';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { HStack, Stack, VStack } from '@chakra-ui/react';
const TaskDetailSection = ({ title, subtitle, rightContent, children, }) => {
    // Return
    return (<VStack width={'full'} bgColor={COLORS.White.T500.value} alignItems={'flex-start'} borderRadius={'.5rem'} spacing={'1.25rem'} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'} p={'1rem'}>
      <Text color={COLORS.Text.T400.value} font={FONTS.T1.T16Px.Bold700.value} numberOfLines={2} width={'90%'}>
        {title}
      </Text>
      {subtitle && rightContent && (<HStack width={'full'} justify="space-between">
          {subtitle && (<Text color={COLORS.InputText.value} font={FONTS.T1.T12Px.Medium500.value} numberOfLines={1}>
              {subtitle}
            </Text>)}

          {rightContent && <Stack>{rightContent}</Stack>}
        </HStack>)}
      {children}
    </VStack>);
};
export default TaskDetailSection;
