import { useFormik } from 'formik';
import useRouter from '../../../../hooks/router/useRouter';
import { useUpdatePerson } from '../../../../services/online/people/people';
import { createForm } from '../../../../utils/formik';
const useLogic = () => {
    // Attributes
    const { goBack } = useRouter();
    // Queries
    const { mutateAsync: updatePerson, isLoading: isUpdatePersonLoading } = useUpdatePerson({
        mutation: {
            retry: 3,
        },
    });
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPressButton = async () => {
        if (isSaveButtonEnabled()) {
            await updatePerson({
                data: {
                    currentPassword: form?.password?.value,
                    password: form?.newPassword?.value,
                },
            }, {
                onSuccess: () => {
                    goBack();
                },
            });
        }
    };
    const isSaveButtonEnabled = () => {
        return (dirty &&
            form?.newPassword?.value === form?.confirmNewPassword?.value &&
            form?.password?.value !== form?.newPassword?.value);
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        onSubmit: handleOnPressButton,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        handleOnPressButton,
        isUpdatePersonLoading,
        isSaveButtonEnabled,
        form,
    };
};
export default useLogic;
