import React from 'react';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import Text from '../../../content/text';
import { Stack } from '@chakra-ui/react';
import useFormatDate from '../../../../hooks/format-date/useFormatDate';
import moment from 'moment';
const EventCardDate = ({ date, ...props }) => {
    // Attributes
    const { formatDate } = useFormatDate();
    // Render
    return (<Stack p={'.75rem'} alignItems={'center'} borderRadius={'.625rem'} spacing={0} minW={'3rem'} backgroundColor={COLORS.White.T500.value} borderWidth={1} borderColor={COLORS.Line.value}>
      <Text font={FONTS.T1.T20Px.SemiBold600.value} color={COLORS.Error.T500.value}>
        {formatDate({
            date,
            formatWithTimezone: true,
            format: 'DD',
        })}
      </Text>
      <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
        {moment(date).format('MMM')}
      </Text>
      <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
        {moment(date).format('YYYY')}
      </Text>
    </Stack>);
};
export default EventCardDate;
