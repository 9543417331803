import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import Dropzone from 'react-dropzone';
import { Stack, VStack, HStack } from '@chakra-ui/react';
import { BusyIndicator } from '../../utility/busy-indicator';
import LoadingAnimation from '../../utility/loading/index.web';
import { Size } from '../../utility/loading/size';
import COLORS from '../../../constants/colors';
import Icon from '../../content/icon';
import Text from '../../content/text';
import FONTS from '../../../constants/fonts';
const DropzoneInput = ({ isLoading, onDrop }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<VStack width={'full'} paddingX="1rem" cursor={'pointer'}>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (<VStack rounded="0.75rem" width="full" height="7rem" bgColor={COLORS.Govrn.Blue.T50.value} border="0.0625rem" borderColor={COLORS.Stroke.value} borderStyle="dashed" justifyContent="center" {...getRootProps()}>
            <BusyIndicator isLoading={isLoading} loading={<Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
                  <LoadingAnimation size={Size.COMPACT}/>
                </Stack>}>
              <HStack>
                <Icon name="picture-blue"/>
                <Text numberOfLines={100} font={FONTS.T1.T12Px.Medium500.value} style={{ maxWidth: '14rem' }}>
                  <Trans components={[
                <span key={'1'} style={{ color: COLORS.Govrn.Blue.T500.value }}/>,
            ]}>
                    {t(tKeys.agenda_item.documents.dragzone.browse_or_drag)}
                  </Trans>
                </Text>
              </HStack>
              <input {...getInputProps()}/>
            </BusyIndicator>
          </VStack>)}
      </Dropzone>
    </VStack>);
};
export default DropzoneInput;
