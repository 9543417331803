import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    deletedContainer: {
        paddingVertical: 8,
        paddingHorizontal: 12,
        borderColor: COLORS.Line.value,
        borderWidth: 1,
        borderRadius: 20,
    },
});
export default styles;
