import { useFormik } from 'formik';
import { useCompaniesContext } from '../../../../../contexts/companies/index.web';
import { useReadCompany, useUpdateCompanySettings, } from '../../../../../services/online/companies/companies';
import { createForm } from '../../../../../utils/formik';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { tKeys } from '../../../../../translations/keys';
export const useOtherSettingsLogic = () => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { t } = useTranslation();
    const toast = useToast();
    // Queries
    const { data: companyData } = useReadCompany(selectedCompanyId, {
        query: { enabled: !!selectedCompanyId },
    });
    const { mutateAsync: updateCompanySettings, isLoading: isUpdateCompanyLoading, } = useUpdateCompanySettings();
    // Functions
    const handleOnSubmit = async () => {
        await updateCompanySettings({
            companyId: selectedCompanyId,
            data: {
                isFileDownloadAllowed: values?.isFileDownloadAllowed,
                isWatermarkingEnabled: values?.isWatermarkingEnabled,
                isOriginalFileDownloadAllowed: values?.isOriginalFileDownloadAllowed,
            },
        }, {
            onSuccess: () => {
                toast({
                    status: 'success',
                    title: t(tKeys.edit_group_settings.success),
                });
            },
            onError: err => {
                console.error('Error when updating a company details', err);
            },
        });
    };
    function handleOnChangeIsFileDownloadAllowed() {
        // Change the value of isFileDownloadAllowed
        form?.isFileDownloadAllowed?.onChange(!values.isFileDownloadAllowed);
        if (values.isFileDownloadAllowed) {
            form?.isOriginalFileDownloadAllowed?.onChange(false);
            form?.isWatermarkingEnabled?.onChange(false);
        }
    }
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            isFileDownloadAllowed: companyData?.settings?.isFileDownloadAllowed ?? false,
            isWatermarkingEnabled: companyData?.settings?.isWatermarkingEnabled ?? false,
            isOriginalFileDownloadAllowed: companyData?.settings?.isOriginalFileDownloadAllowed ?? false,
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    return {
        handleSubmit,
        form,
        isUpdateCompanyLoading,
        values,
        handleOnChangeIsFileDownloadAllowed,
    };
};
