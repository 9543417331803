import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../../components/content/text';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
import ActiveTaskItem from './task-item';
import { HStack, Stack, VStack, Text as ChakraText } from '@chakra-ui/react';
import useRouter from '../../../../../../../hooks/router/useRouter';
import { useCompaniesContext } from '../../../../../../../contexts/companies/index.web';
const CardActiveTasks = ({ tasks, onTaskPress, }) => {
    // Attributes
    const { t } = useTranslation();
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const { fontFamily, fontSize, fontWeight } = FONTS.T1.T14Px.Medium500.value;
    const [reversedTasks, setReversedTasks] = useState();
    // Functions
    function handleRedirectTasksPage() {
        push('/company/home/tasks', { companyId: selectedCompanyId });
    }
    // Effects
    useEffect(() => {
        const reverseTasks = tasks?.reverse();
        setReversedTasks(reverseTasks);
    }, [tasks]);
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} spacing={'1rem'}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <Text font={FONTS.T1.T16Px.SemiBold600.value}>
          {t(tKeys.homepage.task_to_be_completed, { value: tasks.length })}
        </Text>
        <Stack onClick={handleRedirectTasksPage} cursor={'pointer'} _hover={{ textColor: COLORS.Govrn.Blue.T500.value }}>
          <ChakraText fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} color={COLORS.Text.T400.value} _hover={{ color: COLORS.Govrn.Blue.T500.value }}>
            {t(tKeys.homepage.see_all_tasks)}
          </ChakraText>
        </Stack>
      </HStack>

      <VStack width={'full'} borderRadius={'.75rem'} alignItems={'flex-start'} overflow={'auto'} borderWidth={1} borderColor={COLORS.Line.value} bgColor={COLORS.White.T500.value} spacing={0} py={'.5rem'}>
        {reversedTasks?.slice(0, 5)?.map(task => (<ActiveTaskItem key={task?.id} task={task} onPress={() => onTaskPress(task)}/>))}
      </VStack>
    </VStack>);
};
export default CardActiveTasks;
