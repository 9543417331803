import React from 'react';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import Text from '../../../content/text';
import { Radio } from '@chakra-ui/react';
const ModalSelectItem = ({ label, onPress, isSelected, }) => {
    // Functions
    function getTileTextColor() {
        return isSelected ? COLORS.Govrn.Blue.T500.value : COLORS.Text.T400.value;
    }
    // Render
    return (<Radio colorScheme={'blue'} isChecked={isSelected} onChange={onPress}>
      <Text font={FONTS.T1.T12Px.Regular400.value} color={getTileTextColor()}>
        {label}
      </Text>
    </Radio>);
};
export default ModalSelectItem;
