import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    flatlist: {
        paddingHorizontal: 12,
        paddingTop: 24,
        flex: 1,
        flexGrow: 1,
    },
    contentContainer: {
        paddingHorizontal: 12,
        paddingTop: 20,
        paddingBottom: 40,
    },
    separator: { height: 20 },
    listHeader: { paddingBottom: 20 },
    endEnhancer: { marginRight: 0 },
    menuItemStartEnhancer: { marginRight: 12 },
});
export default styles;
