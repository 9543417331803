import React from 'react';
import { HStack } from '@chakra-ui/react';
import Icon from '../icon';
import styles from './style';
const ListItem = ({ id, children, startEnhancer, isSelected, endEnhancer, }) => {
    // Functions
    const handleRenderEndEnhancer = () => {
        if (isSelected) {
            return <Icon name="check" width={20} height={20}/>;
        }
        if (!endEnhancer) {
            return <></>;
        }
        return endEnhancer;
    };
    // Return
    return (<HStack style={styles.mainContainer} py={'1rem'} px={'.875rem'} justifyContent={'space-between'} width={'100%'}>
      <>
        {/* Start Enhancer */}
        {<>{startEnhancer}</>}

        {/* Children */}
        {children}
      </>

      {/* Icon if the current item is selected */}
      <>{handleRenderEndEnhancer()}</>
    </HStack>);
};
export default ListItem;
