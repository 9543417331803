import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    transparentInput: {
        borderWidth: 0,
        paddingHorizontal: 0,
        marginLeft: 0,
        marginVertical: 0,
        borderRadius: 0,
    },
});
export default styles;
