import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
import Container from '../../surfaces/container';
import { HStack, Stack, VStack } from '@chakra-ui/react';
const FormControl = ({ helper, children, error, label, helperOnLeft = true, startEnhancer, endEnhancer, endHelperEnhancer, spacing, labelFont = FONTS.T1.T14Px.Medium500.value, isMandatory = false, }) => {
    // Functions
    const renderEndEnhancer = () => {
        if (!endEnhancer) {
            return <></>;
        }
        return endEnhancer;
    };
    const renderStartEnhancer = () => {
        if (!startEnhancer) {
            return <></>;
        }
        return startEnhancer;
    };
    const renderEndHelperEnhancer = () => {
        if (!endHelperEnhancer) {
            return <></>;
        }
        return (<Container alignSelf={'flex-end'} style={{ marginTop: helper ? -12 : 8 }}>
        {endHelperEnhancer}
      </Container>);
    };
    // Render
    return (<Stack direction={endEnhancer ? 'row' : 'column'} w={'full'}>
      <VStack alignItems={'flex-start'} width={'full'} spacing={spacing ?? 0}>
        <HStack direction="row" spacing={startEnhancer ? 4 : 0}>
          {renderStartEnhancer()}
          {/* Label */}
          {label && (<HStack spacing={'.25rem'}>
              <Text color={COLORS.Text.T500.value} font={labelFont} style={startEnhancer ? { paddingTop: 6 } : { height: 'fit-content' }}>
                {`${label} `}
              </Text>
              <Text color={COLORS.Error.T500.value} font={labelFont} style={startEnhancer ? { paddingTop: 6 } : {}}>
                {`${isMandatory ? '*' : ''}`}
              </Text>
            </HStack>)}
        </HStack>
        {/* Input */}
        {children ? <>{children}</> : null}
        {/* Caption */}
        {(helper || !!error) && (<Container alignSelf={helperOnLeft ? 'flex-start' : 'flex-end'} style={{ marginTop: 4 }}>
            <Text color={error ? COLORS.Error.T500.value : COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value}>
              {error ?? helper}
            </Text>
          </Container>)}

        {renderEndHelperEnhancer()}
      </VStack>
      {renderEndEnhancer()}
    </Stack>);
};
export default FormControl;
