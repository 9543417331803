import { useMemo, useState } from 'react';
import useRouter from '../../../../../../../../hooks/router/useRouter.web';
import { useCompaniesContext } from '../../../../../../../../contexts/companies/index.web';
import { useDisclosure } from '@chakra-ui/react';
export const useMessagesListItemLogic = (selectedChannelId, channel) => {
    // Attributes
    const [isHover, setIsHover] = useState(false);
    const isSelected = selectedChannelId === channel?.id;
    const { push } = useRouter();
    const { selectedEnrolment, selectedCompanyId } = useCompaniesContext();
    const { isOpen: isOpenChatSettings, onOpen: onOpenChatSettings, onClose: onCloseChatSettings, } = useDisclosure();
    const participants = channel?.participants?.filter(participant => participant.personId !== selectedEnrolment?.personId);
    const currentParticipant = channel.participants.find(participant => participant.personId === selectedEnrolment?.personId);
    const isLastMessageUnread = useMemo(() => {
        if (!channel.lastMessageReceivedOn)
            return false;
        if (!currentParticipant.lastReadOn)
            return true;
        return currentParticipant.lastReadOn < channel.lastMessageReceivedOn;
    }, [currentParticipant, channel]);
    // Functions
    function handleInformationClick() {
        if (channel?.isGroup) {
            onOpenChatSettings();
            setTimeout(() => setIsHover(false), 100);
            return;
        }
        push('/company/profile/other-user-profile', {
            companyId: selectedCompanyId,
            personId: participants?.[0]?.personId,
        });
    }
    // return
    return {
        setIsHover,
        isHover,
        isSelected,
        isOpenChatSettings,
        onCloseChatSettings,
        handleInformationClick,
        isLastMessageUnread,
    };
};
