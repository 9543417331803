import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        marginVertical: 16,
    },
    imageContainer: {
        overflow: 'hidden',
        backgroundColor: COLORS.Govrn.Grey.T100.value,
        width: '100%',
        height: 250,
    },
    infoContainer: {
        height: 70,
        backgroundColor: COLORS.Disabled.value,
        padding: 16,
    },
    textContainer: {
        maxWidth: '90%',
    },
});
export default styles;
