import moment from 'moment';
function formatMessages(isMessagesLoading, messages) {
    if (!messages?.length || isMessagesLoading) {
        return [];
    }
    // Create an empty array to store the formatted messages
    const formattedMessages = [];
    // Get the creatorId of the first message
    let previousCreatorId = messages?.[0].creatorId;
    // Create an empty array to temporarily store messages with the same creatorId
    let previousMessageDay = messages?.[0]?.createdOn;
    let currentGroup = [];
    messages.forEach(message => {
        const isSameDay = moment(previousMessageDay).isSame(message?.createdOn, 'day');
        // Check if the current message's creatorId is the same as the previous message's creatorId
        if (previousCreatorId === message.creatorId) {
            if (!isSameDay) {
                formattedMessages.push(currentGroup);
                currentGroup = [message];
                previousMessageDay = message?.createdOn;
                return;
            }
            // If they are the same, add the current message to the currentGroup
            currentGroup.push(message);
        }
        else {
            // If they are not the same, add the currentGroup to the formattedMessages array
            formattedMessages.push(currentGroup);
            // Update the previousCreatorId variable to the current message's creatorId
            previousCreatorId = message.creatorId;
            // Reset the currentGroup array to only contain the current message
            currentGroup = [message];
        }
    });
    // push the last currentGroup array
    formattedMessages.push(currentGroup);
    // Return the formatted messages
    return formattedMessages;
}
export default formatMessages;
