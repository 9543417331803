import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../hooks/router/useRouter';
import { useForgotPassword } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import { validationSchema } from './validations';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { readInvitationByEmail } from '../../../../services/online/enrolments/enrolments';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { push, goBack } = useRouter();
    const toast = useToast();
    // Call BE
    const { mutateAsync: forgotPassword, isLoading: isForgotPasswordLoading } = useForgotPassword();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handlerOnSubmit = async (values) => {
        // Fetch invitation by email
        try {
            const invitation = await readInvitationByEmail({ email: values.email });
            if (invitation) {
                toast({
                    status: 'success',
                    title: t(tKeys.invitation.resend.success),
                });
                // Redirect to sign-in page
                push('/auth/sign-in/default');
                return;
            }
        }
        catch (err) {
            console.error('Error : ', err);
            toast({
                status: 'error',
                title: t(tKeys.sign_in.no_account_invitation),
            });
            return;
        }
        await forgotPassword({
            params: {
                email: values.email,
            },
        }, {
            onSuccess: () => {
                const signInEntity = {
                    email: values.email,
                    password: '',
                    accessToken: '',
                    refreshToken: '',
                };
                push('/auth/verification-code', {
                    sourcePageName: '/sign-in-forgot-address-mail',
                    signInEntity,
                });
                toast({
                    status: 'success',
                    title: t(tKeys['sign-in-forgot-mail'].success.sms_sent),
                });
            },
            onError: err => {
                console.error('Error with the forgot password initialization : ', err);
                toast({
                    status: 'error',
                    title: t(tKeys['sign-in-forgot-mail'].errors.email_not_sent),
                });
            },
        });
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: handlerOnSubmit,
        validationSchema: validationSchema(t),
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { submitForm } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        form,
        handlerOnSubmit: submitForm,
        isForgotPasswordLoading,
    };
};
export default useLogic;
