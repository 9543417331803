import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
const styles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        maxWidth: '500px',
        minWidth: '200px',
        minHeight: '32px',
        backgroundColor: '#fff',
        border: '1px solid #E7E9F4',
        borderRadius: '8px',
        padding: '0px 10px',
        boxShadow: 'none',
        ':hover': {
            border: '1px solid #E7E9F4',
            cursor: 'pointer', // Hover cursor
        },
        ':focus': {
            border: '1px solid #E7E9F4', // Focus border color
        },
        fontSize: FONTS.T1.T12Px.Regular400.value.fontSize,
        fontWeight: FONTS.T1.T12Px.Regular400.value.fontWeight,
        fontFamily: FONTS.T1.T12Px.Regular400.value.fontFamily,
        color: COLORS.Text.T400.value, // Text color
    }),
    placeholder: baseStyles => ({
        ...baseStyles,
        color: COLORS.Text.T300.value, // Placeholder color
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: FONTS.T1.T12Px.Regular400.value.fontSize,
        fontWeight: state.isSelected
            ? FONTS.T1.T12Px.Bold700.value.fontWeight
            : FONTS.T1.T12Px.Regular400.value.fontWeight,
        fontFamily: FONTS.T1.T12Px.Regular400.value.fontFamily,
        backgroundColor: state.isSelected ? '#e2e2ea' : '#fff',
        ':hover': {
            backgroundColor: '#f2f2f5', // Hover background color
        },
        color: COLORS.Text.T400.value, // Text color
    }),
    indicatorSeparator: baseStyles => ({
        display: 'none', // Remove the indicator separator
    }),
    singleValue: baseStyles => ({
        ...baseStyles,
        fontSize: FONTS.T1.T12Px.Regular400.value.fontSize,
        fontWeight: FONTS.T1.T12Px.Regular400.value.fontWeight,
        fontFamily: FONTS.T1.T12Px.Regular400.value.fontFamily,
        color: COLORS.Text.T400.value, // Text color
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        fontSize: FONTS.T1.T12Px.Regular400.value.fontSize,
        fontWeight: FONTS.T1.T12Px.Regular400.value.fontWeight,
        fontFamily: FONTS.T1.T12Px.Regular400.value.fontFamily,
        color: COLORS.Text.T400.value,
        backgroundColor: COLORS.Govrn.Blue.T100.value, // Background color
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }), // need to be always on top
};
export default styles;
