import { useReadGroupDetails } from '../../../services/online/groups/groups';
import { useCompaniesContext } from '../../../contexts/companies/index.web';
export const useGroupAvatarsLogic = (group, groupId) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: groupDetails } = useReadGroupDetails(groupId ?? '', {
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!groupId } });
    // return
    return { groupDetails: groupDetails ?? group };
};
