import COLORS from '../constants/colors';
export const checkboxControl = {
    '& .chakra-checkbox__control': {
        background: `${COLORS.Success.T500.value}!important`,
        borderColor: `${COLORS.Success.T500.value}!important`,
    },
};
export const switchControl = {
    '& .chakra-switch__track': {
        backgroundColor: `${COLORS.Success.T500.value}!important`,
    },
};
