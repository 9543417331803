import { t } from 'i18next';
import React from 'react';
import Text from '../../../../../components/content/text';
import FONTS from '../../../../../constants/fonts';
import { NotificationFrequency } from '../../../../../services/types';
import { tKeys } from '../../../../../translations/keys';
import Modal from '../../../../../components/surfaces/modal/index.web';
import { HStack, VStack, Switch, RadioGroup, Radio } from '@chakra-ui/react';
import { switchControl } from '../../../../../styles/react-style';
const CompanyNotificationModal = ({ notificationFrequencyValue, switchValue, handleSwitchOnChange, handleChangeNotificationfrequency, isOpen, onClose, }) => {
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} hasCloseButton hasFooter={false} title={t(tKeys.profile['user-profile'].notif['navigation-header'])}>
      <VStack width={'full'} alignItems={'flex-start'} p={'1rem'} spacing={'2.5rem'}>
        {/* Alerts for new messages and actions notification */}
        <HStack justify="space-between" width={'full'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.user_profile.notifications.alert_new_messages)}
          </Text>

          <Switch size={'md'} _checked={switchControl} isChecked={switchValue} onChange={() => handleSwitchOnChange(!switchValue)}/>
        </HStack>

        {/* Digest email with summary */}
        <VStack width={'full'} alignItems={'flex-start'} spacing={'1rem'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.user_profile.notifications.digest_email_with_summary)}
          </Text>
          <RadioGroup name={'notification-radio-group'} value={notificationFrequencyValue?.toString()} 
    // eslint-disable-next-line no-void
    onChange={value => handleChangeNotificationfrequency(NotificationFrequency?.[value])}>
            <VStack width={'full'} alignItems={'flex-start'}>
              <Radio value={NotificationFrequency?.[0]?.toString()} size={'md'}>
                {t(tKeys.user_profile.notifications.daily)}
              </Radio>
              <Radio value={NotificationFrequency?.[1]?.toString()} size={'md'}>
                {'Weekly (on Sunday)'}
              </Radio>
              <Radio value={NotificationFrequency?.[2]?.toString()} size={'md'}>
                {'Never'}
              </Radio>
            </VStack>
          </RadioGroup>
        </VStack>
      </VStack>
    </Modal>);
};
export default CompanyNotificationModal;
