import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, View } from 'react-native';
import Text from '../../../../../components/content/text';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import SearchInput from '../../../../../components/inputs/search-input';
import NavigationHeader from '../../../../../components/navigation/navigation-header';
import Container from '../../../../../components/surfaces/container';
import Page from '../../../../../components/surfaces/page';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import MultiSelectItem from '../../components/pickers/multi-select-item';
import useLogic from './logic';
import styles from './style';
import { tKeys } from '../../../../../translations/keys';
const FilterSearchMembers = () => {
    // Attributes
    const { handleOnPressBackButton, handleOnPressSelected, isSelectedAll, searchValue, setSearchValue, filterAllMembersEntity, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page backgroundColor={COLORS.White.T500.value} testID={'createAccountPage'}>
      <NavigationHeader onGoBack={handleOnPressBackButton} endEnhancer={<Button onPress={handleOnPressSelected} kind={Kind.TRANSPARENT}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Govrn.Blue.T500.value}>
              {isSelectedAll
                ? t(tKeys.common['unselect-all'])
                : t(tKeys.common['select-all'])}
            </Text>
          </Button>}>
        {t(tKeys.common['published-by'])}
      </NavigationHeader>

      <SearchInput name={'search'} value={searchValue} onChange={setSearchValue} placeholder={t(tKeys.search.input.placeholder.title)} isClearable={searchValue?.length > 0} onPressClear={() => setSearchValue('')}/>
      <Container style={styles.mainContainer}>
        <FlatList data={filterAllMembersEntity?.allMembers ?? []} renderItem={({ item }) => <MultiSelectItem item={item}/>} keyExtractor={item => item?.id} ItemSeparatorComponent={() => <View style={styles.separator}/>}/>
      </Container>
    </Page>);
};
export default FilterSearchMembers;
