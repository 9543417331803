import xml2js from 'xml2js';
const parseAnnotation = (documentData, annotationsData) => {
    if (!annotationsData.length)
        return documentData.initialXfdfString;
    const parser = new xml2js.Parser();
    const builder = new xml2js.Builder();
    let finalXfdfString;
    parser.parseString(documentData.initialXfdfString, (err1, parsedInitialXfdfString) => {
        annotationsData.forEach(annotation => {
            parser.parseString(annotation.annotation, (err2, parsedAnnotation) => {
                if (parsedAnnotation.xfdf.add)
                    parsedInitialXfdfString.xfdf.add.push(parsedAnnotation.xfdf.add[0]);
                if (parsedAnnotation.xfdf.delete)
                    parsedInitialXfdfString.xfdf.delete.push(parsedAnnotation.xfdf.delete[0]);
                if (parsedAnnotation.xfdf.modify)
                    parsedInitialXfdfString.xfdf.modify.push(parsedAnnotation.xfdf.modify[0]);
            });
        });
        finalXfdfString = builder.buildObject(parsedInitialXfdfString);
    });
    return finalXfdfString;
};
export default parseAnnotation;
