/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/content/icon';
import Text from '../../components/content/text';
import Button from '../../components/inputs/button';
import { Kind } from '../../components/inputs/button/constants/kind';
import NavigationHeader from '../../components/navigation/navigation-header';
import Flex from '../../components/surfaces/flex';
import Page from '../../components/surfaces/page';
import Placeholder from '../../components/utility/placeholder';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import useLogic from './logic.web';
import { Checkbox, VStack, Switch, Stack } from '@chakra-ui/react';
import AvatarDetails from '../../components/content/avatar-details';
import { PressableType } from '../../components/inputs/button/type';
import PressableWrapper from '../../components/inputs/button/wrapper';
import { Size } from '../../components/utility/loading/size';
import Modal from '../../components/surfaces/modal/index.web';
import MenuItem from '../../components/pickers/menu/menu-item/index.web';
import ShareInMessagesListHeader from './components/content/share-in-messages-list-header/index.web';
import { checkboxControl, switchControl } from '../../styles/react-style';
import Input from '../../components/inputs/input/index.web';
const ShareInMessages = ({ isOpen, onClose, onCloseCreateMessage, shareInMessageEntity, }) => {
    // Attributes
    const { form, allMembersInCompany, isModuleAvailable, channels, isChannelsLoading, getParticipant, onChannelPress, isCompanyEnrolementsLoading, isCreateGroup, onChangeSwitchCreateGroup, isCreateButtonDisabled, onSelectMember, handleOnCreateGroup, handleOnPressMember, isCreateMessageLoading, } = useLogic(shareInMessageEntity, onCloseCreateMessage);
    const { t } = useTranslation();
    // Render Members
    const renderMembers = ({ item: person }) => {
        return (<PressableWrapper pressableType={PressableType.SCALE} activeScale={1} onPress={async () => await handleOnPressMember(person)} style={{ width: '100%' }}>
        <Flex justify="space-between" align="center">
          <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
          {isCreateGroup ? (<Checkbox onChange={() => onSelectMember(person)} _checked={checkboxControl}/>) : (<Icon name="chevron-right" width={24} height={24}/>)}
        </Flex>
      </PressableWrapper>);
    };
    // Show an error if the module is not available for the company
    if (!isModuleAvailable && isOpen) {
        return (<Page backgroundColor={COLORS.White.T500.value}>
        <NavigationHeader divider leftIconName="close-cross">
          <Text font={FONTS.T1.T16Px.SemiBold600.value} color={COLORS.Black.T400.value}>
            {t(tKeys.messages.moduleDisabled.title)}
          </Text>
        </NavigationHeader>
        <Flex>
          <Placeholder imageType="monocle-emoji" title={t(tKeys.messages.moduleDisabled.title)} subTitle={t(tKeys.messages.moduleDisabled.subtitle)} maxWidth={320}/>
        </Flex>
      </Page>);
    }
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.messages.createDiscussion.title)} hasCloseButton actionButton={<Button size={Size.MINI} kind={Kind.SECONDARY} isDisabled={isCreateButtonDisabled} onPress={handleOnCreateGroup} isLoading={isCreateMessageLoading}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color={isCreateButtonDisabled
                ? COLORS.Black.T200.value
                : COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
            {t(tKeys.common.create)}
          </Text>
        </Button>}>
      <VStack alignItems={'flex-start'} spacing={'1.5rem'} width={'full'} px={'0.75rem'} pt={'.5rem'} overflow={'auto'} py={'1.25rem'}>
        <ShareInMessagesListHeader allMembersInCompany={allMembersInCompany} shareInMessageEntity={shareInMessageEntity} channels={channels} form={form} getParticipant={getParticipant} isChannelsLoading={isChannelsLoading} isCompanyEnrolementsLoading={isCompanyEnrolementsLoading} onChannelPress={onChannelPress}/>
        <Stack width={'full'} spacing={'1rem'}>
          <MenuItem startEnhancer={<Icon name="members" width={36} height={36}/>} title={t(tKeys.messages.createNewGroup.title)} titleFont={FONTS.T1.T14Px.Medium500.value} endEnhancer={<Switch size={'md'} _checked={switchControl} isChecked={isCreateGroup} onChange={onChangeSwitchCreateGroup}/>}/>
          {isCreateGroup && (<Input value={form.groupName.value} onChange={e => form.groupName.onChange(e.currentTarget.value)} placeholder={t(tKeys.messages.groupName.placeholder)}/>)}
          {(isCreateGroup || allMembersInCompany?.length > 0) && (<Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.more.page.menu.members.menu_title)}
            </Text>)}
          {allMembersInCompany?.map((person, index) => {
            return renderMembers({
                item: person,
                index,
                separators: undefined,
            });
        })}
        </Stack>
      </VStack>
    </Modal>);
};
export default ShareInMessages;
