import React from 'react';
import COLORS from '../../../../../constants/colors';
import { tKeys } from '../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import LoadingWrapper from '../../../../../components/utility/loading-wrapper';
import useLogic from './logic.web';
import { HStack, Stack, StackDivider, VStack } from '@chakra-ui/react';
import FONTS from '../../../../../constants/fonts';
import Text from '../../../../../components/content/text';
import Button from '../../../../../components/inputs/button/index.web';
import { Size } from '../../../../../components/inputs/button/constants/size';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import AddFileType from './add-file-type/index.web';
const ManageFiles = () => {
    // Attributes
    const { handleOnPressEditableMenu, handleOnPressDeleteFileType, filesType, handleOnPressAddFileType, isFileTypesLoading, handleRefetchFilesType, isOpenFileType, onCloseFileType, selectedFileType, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<HStack width={'full'} height={'28.75rem'} justifyContent={'space-between'}>
      <VStack alignItems={'flex-start'} height={'full'} width={'30%'} spacing={'1.25rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.settings.page.button['manage-files'])}
        </Text>
        <Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={handleOnPressAddFileType}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color="white">
            {t(tKeys.settings.file_type.add.button)}
          </Text>
        </Button>
      </VStack>
      <VStack alignItems={'flex-start'} height={'full'} width={'70%'} borderRadius={'.5rem'} bgColor={COLORS.Tag.value} borderWidth={1} overflow={'hidden'} spacing={0}>
        <Stack width={'full'} py={'1rem'} px={'1.25rem'}>
          <Text font={FONTS.T1.T12Px.Regular400.value}>
            {t(tKeys.common.name.label).toUpperCase()}
          </Text>
        </Stack>
        <LoadingWrapper isLoading={isFileTypesLoading}>
          <VStack alignItems={'flex-start'} width={'full'} spacing={0} divider={<StackDivider />} overflow={'auto'}>
            {filesType?.map(fileType => (<HStack key={fileType?.id} width={'full'} bgColor={COLORS.White.T500.value} px={'1.25rem'} minHeight={'4rem'} justifyContent={'space-between'}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(fileType?.name)}
                </Text>
                <HStack spacing={'.75rem'}>
                  {fileType?.isEditable && (<>
                      <Button size={Size.SMALL} kind={Kind.OUTLINE} textColor={COLORS.Text.T400.value} onPress={() => handleOnPressEditableMenu(fileType)}>
                        {t(tKeys.common.edit)}
                      </Button>
                      <Stack cursor="pointer" onClick={() => handleOnPressDeleteFileType(fileType?.id)}>
                        <Text font={FONTS?.T1.T12Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                          {t(tKeys.news_feed.delete_post.title)}
                        </Text>
                      </Stack>
                    </>)}
                </HStack>
              </HStack>))}
          </VStack>
        </LoadingWrapper>
      </VStack>
      <AddFileType isOpen={isOpenFileType} onClose={onCloseFileType} fileType={selectedFileType} refetchFilesType={handleRefetchFilesType}/>
    </HStack>);
};
export default ManageFiles;
