import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/content/text';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import useRouter from '../../../../hooks/router/useRouter';
import { useReadCultures } from '../../../../services/online/others/others';
import { getLanguageLabel } from '../../../../utils/getLanguageValue';
import { RedirectRoute, } from '../generate-event-document/type';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { goBack, push, params } = useRouter();
    const [selectedLanguage, setSelectedLanguage] = useState();
    const { generateEventEntity, documentDetailsEntity, redirectRoute } = params ?? {};
    const culture = redirectRoute === RedirectRoute.DOCUMENT_DETAILS
        ? documentDetailsEntity?.culture
        : generateEventEntity?.culture;
    // Query
    const { data: cultures } = useReadCultures();
    // Effect
    useEffect(() => {
        setSelectedLanguage(culture);
    }, [culture]);
    // Attributes (again)
    const languages = useMemo(() => {
        return cultures?.map(culture => ({ label: culture, value: culture })) ?? [];
    }, [cultures]);
    const options = useMemo(() => languages?.map(language => {
        return {
            id: language?.value,
            children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
              {t(getLanguageLabel(language?.label))}
            </Text>),
        };
    }), [languages]);
    // Functions
    function onSelectLanguage(value) {
        setSelectedLanguage(value);
        if (redirectRoute === RedirectRoute.GENERATE_DOCUMENT) {
            push('/generate-event-document/default', {
                ...generateEventEntity,
                culture: value,
            });
            return;
        }
        if (redirectRoute === RedirectRoute.DOCUMENT_DETAILS) {
            push('/document-details', { ...documentDetailsEntity, culture: value });
        }
    }
    // Return
    return { onGoBack: goBack, options, selectedLanguage, onSelectLanguage };
};
export default useLogic;
