import React from 'react';
import { Pressable } from 'react-native';
import Text from '../../../../../../components/content/text';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import styles from './styles';
import { tKeys } from '../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/react';
const ModalHeader = ({ title, onClickResetButton, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<HStack justify="space-between" padding={'1rem'} width={'full'}>
      {title && (<Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Text.T400.value}>
          {title}
        </Text>)}

      <Pressable onPress={onClickResetButton} style={styles.titleContainer}>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Govrn.Blue.T500.value}>
          {t(tKeys.common.reset)}
        </Text>
      </Pressable>
    </HStack>);
};
export default ModalHeader;
