import { useTranslation } from 'react-i18next';
import COLORS from '../../../../../constants/colors';
import useFormatDate from '../../../../../hooks/format-date/useFormatDate';
import { tKeys } from '../../../../../translations/keys';
import { getSearchResultType } from '../../../utils';
import { SearchResultType } from './props';
const useLogic = (result) => {
    // Attributes
    const { formatDate } = useFormatDate();
    const { t } = useTranslation();
    const resultType = getSearchResultType(result);
    function getProps() {
        switch (resultType) {
            case SearchResultType.FILE:
                return {
                    tagProps: {
                        children: t(tKeys.common.file),
                        backgroundColor: COLORS.Warning.T100.value,
                        color: COLORS.Warning.T500.value,
                    },
                    title: result?.document?.title,
                    subtitle: result?.document?.file?.filename +
                        ' - ' +
                        t(tKeys.search.eventCard.date, {
                            startDate: formatDate({
                                date: result?.document?.createdOn,
                                formatWithTimezone: true,
                            }),
                            interpolation: { escapeValue: false },
                        }),
                };
            case SearchResultType.EVENT:
                return {
                    tagProps: {
                        children: t(tKeys.common.event),
                        backgroundColor: COLORS.Govrn.Pink.T100.value,
                        color: COLORS.Govrn.Pink.T500.value,
                    },
                    title: result?.event?.name,
                    subtitle: t(tKeys.search.eventCard.date, {
                        startDate: formatDate({
                            date: result?.event?.startDate,
                            formatWithTimezone: true,
                        }),
                        interpolation: { escapeValue: false },
                    }),
                };
            case SearchResultType.POST:
                return {
                    tagProps: {
                        children: t(tKeys.common.post),
                        backgroundColor: COLORS.Govrn.PastelGreen.T100.value,
                        color: COLORS.Govrn.PastelGreen.T500.value,
                    },
                    title: result?.post?.content,
                    subtitle: t(tKeys.search.eventCard.date, {
                        startDate: formatDate({
                            date: result?.post?.createdOn,
                            formatWithTimezone: true,
                        }),
                        interpolation: { escapeValue: false },
                    }),
                };
            case SearchResultType.MESSAGE:
                return {
                    tagProps: {
                        children: t(tKeys.common.message),
                        backgroundColor: COLORS.Govrn.DarkBlue.T100.value,
                        color: COLORS.Govrn.DarkBlue.T500.value,
                    },
                    title: result?.message?.content,
                    subtitle: t(tKeys.search.eventCard.date, {
                        startDate: formatDate({
                            date: result?.message?.createdOn,
                            formatWithTimezone: true,
                        }),
                        interpolation: { escapeValue: false },
                    }),
                };
        }
    }
    return {
        tagProps: getProps()?.tagProps,
        title: getProps()?.title,
        subtitle: getProps()?.subtitle,
    };
};
export default useLogic;
