import React from 'react';
import { TouchableHighlight, View } from 'react-native';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import getUserName from '../../../utils/getUserName';
import Flex from '../../surfaces/flex';
import Avatar from '../avatar';
import Icon from '../icon';
import Text from '../text';
import useLogic from './logic';
import styles from './style';
import { Box, HStack, Stack, VStack } from '@chakra-ui/react';
const AvatarMessagePreview = ({ isLastMessagesUnread, lastMessageDate, person, personId, isGroupChat = false, conversationName, channelId, onPress, picture, avatarSize = 44, }) => {
    // Attributes
    const { messageDate, personData, lastMessage } = useLogic(personId, person, lastMessageDate, channelId);
    // Return if no person
    if (!person && !personData)
        return <></>;
    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return text.substring(0, maxLength - 3) + '...';
        }
        return text;
    }
    // Render
    return (<TouchableHighlight onPress={onPress} underlayColor={COLORS.BG.value}>
      <Flex spacing={12} align="center">
        {isGroupChat && !picture ? (<Flex style={{
                backgroundColor: COLORS.Line.value,
                justifyContent: 'center',
                alignItems: 'center',
                width: { avatarSize },
                padding: 10,
                borderRadius: 100,
            }}>
            <Icon name="users" width={avatarSize / 2} height={avatarSize / 2}/>
          </Flex>) : (<Box>
            <Avatar width={'2.625rem'} height={'2.625rem'} borderRadius={51} name={isGroupChat ? conversationName : getUserName(personData)} imageSource={isGroupChat ? picture : personData?.picture?.uri} isOnline={isGroupChat ? null : personData?.status} placeholderFont={avatarSize < 20
                ? FONTS.T1.T10Px.SemiBold600.value
                : FONTS.T1.T16Px.SemiBold600.value}/>
          </Box>)}
        <VStack width={'100%'} alignItems={'flex-start'} spacing={'.25rem'}>
          <HStack width={'100%'} justifyContent="space-between">
            <HStack width={'100%'} spacing={'.25rem'}>
              <Text font={FONTS.T1.T12Px.SemiBold600.value}>
                {truncateText(conversationName, 20)}
              </Text>
              {isLastMessagesUnread && <View style={styles.alertDot}/>}
            </HStack>
            <Stack minW={'fit-content'}>
              <Text font={FONTS.T1.T10Px.Medium500.value} color={COLORS.InputText.value}>
                {messageDate}
              </Text>
            </Stack>
          </HStack>
          <Text font={isLastMessagesUnread
            ? FONTS.T1.T12Px.Medium500.value
            : FONTS.T1.T12Px.Regular400.value} color={isLastMessagesUnread
            ? COLORS.Text.T400.value
            : COLORS.InputText.value}>
            {truncateText(lastMessage, 30) ?? ''}
          </Text>
        </VStack>
      </Flex>
    </TouchableHighlight>);
};
export default AvatarMessagePreview;
