import path from 'path';
export const sanitizePath = (dir, filename) => {
    const regex = /[^a-zA-Z0-9.]+/g;
    const sanitizedFilename = filename.replaceAll(regex, '-');
    const sanitizedPath = path.join(dir, sanitizedFilename);
    return sanitizedPath
        .replaceAll(' ', '_')
        .normalize('NFD')
        .replaceAll(/[\u0300-\u036f]/g, '');
};
