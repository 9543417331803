import { StyleSheet } from 'react-native';
import COLORS from '../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        height: '100%',
    },
    imageContainer: {
        height: 265,
    },
    innerImageContainer: {
        width: '100%',
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollView: { paddingBottom: 40 },
    section: { paddingHorizontal: 12, paddingVertical: 24 },
    deleteButton: {
        marginTop: 25,
        justifyContent: 'center',
        alignContent: 'center',
        height: 25,
    },
    extraDescription: {
        paddingHorizontal: 12,
        paddingBottom: 20,
    },
    description: {
        paddingLeft: 12,
        paddingTop: 12,
    },
    newFileButton: {
        marginTop: 20,
        marginHorizontal: 12,
    },
    separator: { width: 12 },
    signersContainer: {
        paddingHorizontal: 12,
        paddingBottom: 24,
    },
    tagContainer: {
        borderWidth: 1,
        borderColor: COLORS.Line.value,
        borderRadius: 100,
    },
    tagFieldContainer: { paddingBottom: 24 },
});
export default styles;
