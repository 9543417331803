import { t } from 'i18next';
import React from 'react';
import { TouchableHighlight } from 'react-native';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import Icon from '../../content/icon';
import Text from '../../content/text';
import Container from '../container';
import Flex from '../flex';
import Modal from '../modal';
import { DocumentTypes } from './props';
import styles from './style';
const DocumentModal = ({ isOpen, onDismiss, handleOnSelect, modalTitle = t(tKeys.home.more.repository.create_document.title), fileIcon = 'add-files', pictureIcon = 'add-picture', cameraIcon = 'add-picture-camera', }) => {
    // Render
    return (<Modal visible={isOpen} animationIn={'slideInUp'} animationOut={'slideOutDown'} title={modalTitle} onDismiss={onDismiss}>
      <Flex direction="column" style={styles.mainContainer}>
        {/* Upload a file */}
        <TouchableHighlight onPress={() => {
            void handleOnSelect(DocumentTypes.FILE);
        }} underlayColor={COLORS.Tag.value} style={styles.pressableContainer}>
          <Flex>
            {/* Icon */}
            <Icon name={fileIcon} width={30} height={30}/>

            <Container style={styles.textContainer}>
              <Text font={FONTS.T1.T14Px.Medium500.value}>
                {t(tKeys.home.more.create_document_modal.upload_a_file)}
              </Text>
            </Container>
          </Flex>
        </TouchableHighlight>

        {/* Upload a picture */}
        <TouchableHighlight onPress={() => {
            void handleOnSelect(DocumentTypes.PICTURE_GALLERY);
        }} underlayColor={COLORS.Tag.value} style={styles.pressableContainer}>
          <Flex>
            {/* Icon */}
            <Icon name={pictureIcon} width={30} height={30}/>
            <Container style={styles.textContainer}>
              <Text font={FONTS.T1.T14Px.Medium500.value}>
                {t(tKeys.home.more.create_document_modal.upload_a_picture)}
              </Text>
            </Container>
          </Flex>
        </TouchableHighlight>

        {/* Use camera */}
        <TouchableHighlight onPress={() => {
            void handleOnSelect(DocumentTypes.CAMERA);
        }} underlayColor={COLORS.Tag.value} style={styles.pressableContainer}>
          <Flex>
            {/* Icon */}
            <Icon name={cameraIcon} width={30} height={30}/>
            <Container style={styles.textContainer}>
              <Text font={FONTS.T1.T14Px.Medium500.value}>
                {t(tKeys.home.more.create_document_modal.use_camera)}
              </Text>
            </Container>
          </Flex>
        </TouchableHighlight>
      </Flex>
    </Modal>);
};
export default DocumentModal;
