import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    flatlist: {
        paddingTop: 20,
        paddingBottom: 40,
    },
    search: {
        marginBottom: 10,
    },
});
export default styles;
