import i18next from 'i18next';
export var language;
(function (language) {
    language["en"] = "en";
    language["fr"] = "fr";
    language["nl"] = "nl";
    language["es"] = "es";
    language["unknown"] = "unknown";
})(language || (language = {}));
export const languages = Object.keys(language);
export const defaultLanguage = language.en;
export function getCurrentLanguage() {
    const locale = i18next.language;
    const lang = locale?.split('-')?.[0];
    if (languages.includes(lang))
        return lang;
    return defaultLanguage;
}
