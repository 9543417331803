/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a person, usually after they have clicked the invitation link
 */
export const createPerson = (person, params, options) => {
    return customInstance({
        url: `/people`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: person,
        params,
    }, options);
};
export const useCreatePerson = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data, params } = props ?? {};
        return createPerson(data, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a paginated list of people with filtering support
 */
export const readPeople = (params, options, signal) => {
    return customInstance({ url: `/people`, method: 'get', params, signal }, options);
};
export const getReadPeopleQueryKey = (params) => [
    `/people`,
    ...(params ? [params] : []),
];
export const useReadPeople = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadPeopleQueryKey(params);
    const queryFn = ({ signal, }) => readPeople(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a person
 */
export const updatePerson = (person, params, options) => {
    return customInstance({
        url: `/people`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: person,
        params,
    }, options);
};
export const useUpdatePerson = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data, params } = props ?? {};
        return updatePerson(data, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves current person
 */
export const readMe = (options, signal) => {
    return customInstance({ url: `/people/me`, method: 'get', signal }, options);
};
export const getReadMeQueryKey = () => [`/people/me`];
export const useReadMe = (options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadMeQueryKey();
    const queryFn = ({ signal, }) => readMe(requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves a person
 */
export const readPerson = (personId, params, options, signal) => {
    return customInstance({ url: `/people/${personId}`, method: 'get', params, signal }, options);
};
export const getReadPersonQueryKey = (personId, params) => [`/people/${personId}`, ...(params ? [params] : [])];
export const useReadPerson = (personId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadPersonQueryKey(personId, params);
    const queryFn = ({ signal, }) => readPerson(personId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, { enabled: !!personId, ...queryOptions });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Requests phone update
 */
export const requestPhoneChange = (params, options) => {
    return customInstance({ url: `/people/phone-update`, method: 'post', params }, options);
};
export const useRequestPhoneChange = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { params } = props ?? {};
        return requestPhoneChange(params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Authenticates a person by sending an authentication request, initiates the creation of a code that'll be sent by
SMS
 */
export const authenticatePerson = (authentication, options) => {
    return customInstance({
        url: `/people/authentication`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: authentication,
    }, options);
};
export const useAuthenticatePerson = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return authenticatePerson(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Confirms an authentication request by providing a valid code received by SMS
 */
export const confirmAuthentication = (params, options) => {
    return customInstance({ url: `/people/authentication/confirm`, method: 'post', params }, options);
};
export const useConfirmAuthentication = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { params } = props ?? {};
        return confirmAuthentication(params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Refreshes access and refresh tokens
 */
export const refresh = (options, signal) => {
    return customInstance({ url: `/people/authentication/refresh`, method: 'get', signal }, options);
};
export const getRefreshQueryKey = () => [`/people/authentication/refresh`];
export const useRefresh = (options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getRefreshQueryKey();
    const queryFn = ({ signal, }) => refresh(requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Forgot password, initiates the creation of a code that'll be sent by SMS
 */
export const forgotPassword = (params, options) => {
    return customInstance({ url: `/people/forgot-password`, method: 'post', params }, options);
};
export const useForgotPassword = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { params } = props ?? {};
        return forgotPassword(params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Confirms a forgot password request by providing a valid code received by SMS
 */
export const confirmForgotPassword = (params, options) => {
    return customInstance({ url: `/people/forgot-password/confirm`, method: 'post', params }, options);
};
export const useConfirmForgotPassword = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { params } = props ?? {};
        return confirmForgotPassword(params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Resets a forgotten password by providing the new password as well as the confirmation token
 */
export const resetForgotPassword = (passwordReset, options) => {
    return customInstance({
        url: `/people/forgot-password/reset`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: passwordReset,
    }, options);
};
export const useResetForgotPassword = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return resetForgotPassword(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
export const impersonateUser = (params, options) => {
    return customInstance({ url: `/people/authentication/impersonate`, method: 'post', params }, options);
};
export const useImpersonateUser = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { params } = props ?? {};
        return impersonateUser(params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
