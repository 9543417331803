import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../components/content/list';
import Text from '../../components/content/text';
import SearchInput from '../../components/inputs/search-input';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import useLogic from './logic.web';
import { HStack, VStack } from '@chakra-ui/react';
const ChooseCountry = ({ country: selectedCountry, onChooseCountry, }) => {
    // Attributes
    const { onTextChange, searchValue, onPress, options, searchInCountries, setOptions, } = useLogic(selectedCountry, onChooseCountry);
    const { t } = useTranslation();
    // Effects
    useEffect(() => {
        const toSearch = searchInCountries(searchValue);
        if (searchValue !== undefined && Array.isArray(toSearch)) {
            const filteredCountries = searchInCountries(searchValue);
            const newOptions = filteredCountries?.map((country) => {
                return {
                    id: country?.name,
                    children: (<HStack width={'100%'} spacing={'.5rem'}>
              <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Text.T400.value}>
                {country?.name}
              </Text>
              <Text font={FONTS.T1.T14Px.Medium500.value} color={country?.dialCode === selectedCountry?.dialCode
                            ? COLORS.Govrn.Blue.T500.value
                            : COLORS.InputText.value}>
                {country?.dialCode}
              </Text>
            </HStack>),
                    startEnhancer: (<Text font={FONTS.T1.T16Px.Regular400.value}>{country?.flag}</Text>),
                };
            });
            setOptions(newOptions);
        }
    }, [searchValue, selectedCountry]);
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} spacing={'.5rem'} p={'1rem'} overflow={'auto'} maxHeight={'30rem'} maxWidth={'30rem'}>
      <SearchInput name={'search'} value={searchValue} onChange={onTextChange} placeholder={t(tKeys['choose-country'].search.placeholder)} style={{ width: '100%' }}/>

      <List name={'id'} value={selectedCountry?.name} onChange={onPress} options={options}/>
    </VStack>);
};
export default ChooseCountry;
