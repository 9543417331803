import React from 'react';
import useLogic from './logic.web';
import Text from '../../../components/content/text';
const Impersonate = () => {
    // Attributes
    const {} = useLogic();
    // Render
    return <Text> Impersonate</Text>;
};
export default Impersonate;
