import { useEffect, useMemo, useState } from 'react';
import { useCompaniesContext } from '../../../contexts/companies';
import { ModulesEnum } from '../../../enum/Modules';
import useRouter from '../../../hooks/router/useRouter';
import { getReadChannelsQueryKey, useCreateChannel, useMarkChannelAsRead, useReadChannels, } from '../../../services/online/chat/chat';
import { useReadMe } from '../../../services/online/people/people';
import getStaleTime from '../../../utils/getStaleTime';
import useIsTablet from '../../../hooks/is-tablet';
import isChannelExisiting from '../../../utils/isChannelExist';
import getUserName from '../../../utils/getUserName';
import { Platform } from 'react-native';
import { useQueryClient } from '@tanstack/react-query';
import { getCountNewsQueryKey } from '../../../services/online/companies/companies';
const useLogic = () => {
    // Attributes
    const { push, params } = useRouter();
    const { selectedCompanyId, modules, isModulesLoading, isCurrentUserLoading } = useCompaniesContext();
    const [search, setSearch] = useState('');
    const isTablet = useIsTablet();
    const [selectedChannel, setSelectedChannel] = useState();
    const { channel } = params ?? {};
    const queryClient = useQueryClient();
    const isModuleAvailable = useMemo(() => {
        return modules?.some(m => m.name === ModulesEnum.DISCUSS);
    }, [modules]);
    // Queries
    const { data: me } = useReadMe({ query: { staleTime: getStaleTime() } });
    const { mutateAsync: markChannelAsRead } = useMarkChannelAsRead();
    const { data: channels, refetch: refetchChannels, isLoading: isChannelsLoading, } = useReadChannels({
        'company-id': selectedCompanyId,
        'search-term': search?.length > 2 ? search : undefined,
    }, {
        query: {
            enabled: isModuleAvailable && !!selectedCompanyId,
            onSuccess: res => {
                if (search) {
                    setSelectedChannel(selectedChannel);
                    return;
                }
                if (selectedChannel &&
                    selectedChannel?.companyId === selectedCompanyId)
                    return;
                setSelectedChannel(res?.[0]);
                handleChannelMarkedAsRead(res?.[0].id);
            },
        },
    });
    const { mutateAsync: createChannel, isLoading: isCreateChannelLoading } = useCreateChannel();
    // Functions
    function onCreateChannelPress() {
        push('/create-channel');
    }
    function handleChannelMarkedAsRead(channelId) {
        void markChannelAsRead({ channelId });
        void queryClient?.invalidateQueries(getCountNewsQueryKey(selectedCompanyId));
        void queryClient?.invalidateQueries(getReadChannelsQueryKey({ 'company-id': selectedCompanyId }));
    }
    function onChannelPress(channel) {
        if (isTablet || Platform.OS === 'web') {
            setSelectedChannel(channel);
            handleChannelMarkedAsRead(channel.id);
            return;
        }
        push('/channel', { channel });
        handleChannelMarkedAsRead(channel.id);
    }
    function getParticipant(channel) {
        return (channel?.participants?.find(p => p?.personId !== me?.person?.id)
            ?.personId ?? '');
    }
    function onSearchPress() {
        push('/search');
    }
    function handleOnMemberPress(person) {
        const channel = isChannelExisiting(channels, [person?.id], false, me?.person?.id);
        if (channel) {
            // if channel already exists we send the channel only
            setSelectedChannel(channel);
            handleChannelMarkedAsRead(channel.id);
            return;
        }
        // Create channel if not exist
        const newChannel = {
            name: getUserName(person),
            companyId: selectedCompanyId,
            isGroup: false,
            participantIds: [person?.id, me?.person?.id],
            creatorId: me?.person?.id,
        };
        void createChannel({
            data: newChannel,
        }, {
            onSuccess: channelId => {
                void refetchChannels();
                setSelectedChannel({ ...newChannel, id: channelId.value });
                handleChannelMarkedAsRead(channelId.value);
            },
        });
    }
    function handleOnCreateGroup(groupName, participantIds) {
        // Create channel if not exist
        const newChannel = {
            companyId: selectedCompanyId,
            name: groupName,
            isGroup: true,
            participantIds,
            creatorId: me?.person?.id,
        };
        void createChannel({
            data: newChannel,
        }, {
            onSuccess: channelId => {
                void refetchChannels();
                setSelectedChannel({ ...newChannel, id: channelId.value });
                handleChannelMarkedAsRead(channelId.value);
            },
        });
    }
    // Effects
    useEffect(() => {
        if (!channel)
            return;
        setSelectedChannel(channel);
    }, [channel]);
    // Return
    return {
        channels,
        isModuleAvailable,
        search,
        setSearch,
        onCreateChannelPress,
        onChannelPress,
        refetchChannels: () => {
            void refetchChannels();
        },
        isChannelsLoading,
        isPageLoading: isModulesLoading || isCurrentUserLoading,
        getParticipant,
        onSearchPress,
        selectedChannel,
        handleOnMemberPress,
        isCreateChannelLoading,
        handleOnCreateGroup,
    };
};
export default useLogic;
