import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../contexts/companies';
import useRouter from '../../../hooks/router/useRouter';
import { getReadEnrolmentsQueryKey, useReadEnrolments, useUpdateEnrolment, } from '../../../services/online/enrolments/enrolments';
import { getReadMeQueryKey, useReadPerson, } from '../../../services/online/people/people';
import { Role } from '../../../services/types';
import { tKeys } from '../../../translations/keys';
import { createForm } from '../../../utils/formik';
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '../../../components/content/use-toast/index.web';
const useLogic = () => {
    // Attributes
    const { goBack, params } = useRouter();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const personId = params?.personId;
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    const { isOpen: isOpenMemberRole, onToggle: onToggleMemberRole } = useDisclosure();
    const { isOpen: isOpenBoardRole, onToggle: onToggleBoardRole } = useDisclosure();
    const toast = useToast();
    // Queries
    const { data: personData } = useReadPerson(personId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!personId && !!selectedCompanyId,
        },
    });
    const { data: companyEnrolements } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'show-intactive': true,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { mutateAsync: updateEnrolment, isLoading: isUpdateEnrolmentLoading } = useUpdateEnrolment();
    // Functions
    const personEnrolement = useMemo(() => {
        return companyEnrolements?.items?.find(companyEnrolement => companyEnrolement?.personId === personId);
    }, [companyEnrolements, personId]);
    // Functions
    async function handleOnSubmit() {
        if (!dirty)
            return;
        try {
            await updateEnrolment({
                data: {
                    ...personEnrolement,
                    role: form?.memberRole?.value,
                    endedOn: form?.govrnUserUntil?.value,
                    boardRoleId: form?.boardRoleId?.value,
                },
            }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadEnrolmentsQueryKey({
                        'company-id': selectedCompanyId,
                    }));
                    // Refetch data
                    void queryClient.invalidateQueries(getReadMeQueryKey());
                    void queryClient.invalidateQueries(getReadEnrolmentsQueryKey());
                    toast({
                        status: 'success',
                        title: t(tKeys.other_member.update.successfull),
                    });
                    goBack();
                },
                onError: () => {
                    toast({
                        status: 'error',
                        title: t(tKeys.other_member.update.error),
                    });
                },
            });
        }
        catch (err) {
            console.error('Error when updating the enrolement : ', err);
        }
    }
    function handleSuspendUser() {
        form?.govrnUserUntil?.onChange(moment().toISOString());
    }
    function handleOnChooseRole(role) {
        form?.memberRole?.onChange?.(role);
        onToggleMemberRole();
    }
    function handleChooseBoardRole(boardRole) {
        form?.boardRoleId?.onChange?.(boardRole.id);
        form?.boardRoleName?.onChange?.(boardRole.name);
        onToggleBoardRole();
    }
    // Form
    const { initialValues, values, dirty, ...rest } = useFormik({
        initialValues: {
            memberRole: personEnrolement?.role,
            boardRoleId: personEnrolement?.boardRoleId,
            boardRoleName: personEnrolement?.boardRoleName,
            govrnUserUntil: personEnrolement?.endedOn,
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
    });
    const form = createForm(values, rest);
    const isMemberSuspended = useMemo(() => {
        return moment(form?.govrnUserUntil?.value || personEnrolement?.endedOn).isSameOrBefore(moment());
    }, [form?.govrnUserUntil?.value, personEnrolement?.endedOn]);
    // Effects
    useEffect(() => {
        if (!personData)
            return;
        setTimeout(() => (document.title = `${selectedEnrolment.company?.name} | ${t(personData?.firstname)} ${t(personData?.lastname)}`), 200);
    }, [personData, selectedEnrolment]);
    // Return
    return {
        personData,
        form,
        personEnrolement,
        isAdmin,
        handleOnSubmit,
        isUpdateEnrolmentLoading,
        handleSuspendUser,
        isMemberSuspended,
        isOpenMemberRole,
        onToggleMemberRole,
        handleOnChooseRole,
        goBack,
        handleChooseBoardRole,
        isOpenBoardRole,
        onToggleBoardRole,
    };
};
export default useLogic;
