import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import FormControl from '../../../../components/inputs/form-control';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import { getLanguageLabel } from '../../../../utils/getLanguageValue';
import TagField from '../../../home/calendar/components/inputs/tag-field';
import useLogic from './logic.web';
import { boxSectionStyle } from './style.web';
import { Stack, Textarea, VStack } from '@chakra-ui/react';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../../../home/calendar/components/inputs/tag-field/tag-item/index.web';
import SelectDocumentLanguages from '../../../home/calendar/document-language/index.web';
import SelectTimezone from '../../../select-time-zone/index.web';
import Input from '../../../../components/inputs/input/index.web';
const MyInformations = () => {
    // Attributes
    const { handleOnSubmit, isUpdatePersonLoading, form, isOpenLanguage, onToggleLanguage, isOpenTimezone, onToggleTimezone, handleOnChangeTimezone, goBack, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={goBack} endEnhancer={<Button onPress={handleOnSubmit} isLoading={isUpdatePersonLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
            {t(tKeys.common.save)}
          </Button>}>
        {`${t(tKeys.profile['user-profile'].myinfo['my-information'])} / ${t(tKeys.calendar.event_details.tabs.informations)}`}
      </NavigationHeader>

      <VStack width={'full'} height={'full'} alignItems={'flex-start'} overflow={'auto'} bgColor={COLORS.BG.value} p={'1rem'}>
        {/* Firstname, lastname and nickname inputs */}
        <VStack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile['user-profile'].myinfo['your-name'])} isMandatory spacing={4}>
            {/* Firstname input */}
            <FormControl error={form?.firstname?.meta?.error}>
              <Input value={form?.firstname?.value} onChange={e => form?.firstname?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common['first-name'].placeholder)}/>
            </FormControl>

            {/* Lastname input */}
            <FormControl error={form?.lastname?.meta?.error}>
              <Input value={form?.lastname?.value} onChange={e => form?.lastname?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common['last-name'].placeholder)}/>
            </FormControl>

            {/* Nickname input */}
            <FormControl error={form?.nickname?.meta?.error}>
              <Input value={form?.nickname?.value} onChange={e => form?.nickname?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common.nickname.placeholder)}/>
            </FormControl>
          </FormControl>
        </VStack>

        <VStack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile['user-profile'].myinfo.bio.label)} error={form?.biography?.meta?.error} spacing={4}>
            <Textarea value={form?.biography?.value} onChange={e => form?.biography?.onChange?.(e?.currentTarget?.value)} height={'12rem'} placeholder={t(tKeys.profile['user-profile'].myinfo.bio.label)}/>
          </FormControl>
        </VStack>

        {/*  App language */}
        <Stack {...boxSectionStyle} p={0}>
          <TagField title={t(tKeys.profile['user-profile'].myinfo['app-lang'])} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(getLanguageLabel(form?.cultureName?.value))} hasEndEnhancer/>} isOpen={isOpenLanguage} onToggle={onToggleLanguage}>
                <SelectDocumentLanguages onSelectLanguage={form?.cultureName?.onChange} selectedLanguage={form?.cultureName?.value}/>
              </PopOver>}/>
        </Stack>

        {/*  Timezone */}
        <Stack {...boxSectionStyle} p={0}>
          <TagField title={t(tKeys.profile['user-profile'].myinfo['time-zone'])} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={form?.timeZoneId?.value} hasEndEnhancer/>} isOpen={isOpenTimezone} onToggle={onToggleTimezone}>
                <SelectTimezone timezoneId={form?.timeZoneId?.value} onChangeTimezoneId={handleOnChangeTimezone}/>
              </PopOver>} isMandatory error={form?.timeZoneId?.meta?.error}/>
        </Stack>
      </VStack>
    </Page>);
};
export default MyInformations;
