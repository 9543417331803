/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a company
 */
export const createCompany = (company, options) => {
    return customInstance({
        url: `/companies`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: company,
    }, options);
};
export const useCreateCompany = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createCompany(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a paginated list of companies with filtering support
 */
export const readCompanies = (params, options, signal) => {
    return customInstance({ url: `/companies`, method: 'get', params, signal }, options);
};
export const getReadCompaniesQueryKey = (params) => [
    `/companies`,
    ...(params ? [params] : []),
];
export const useReadCompanies = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadCompaniesQueryKey(params);
    const queryFn = ({ signal, }) => readCompanies(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a company
 */
export const updateCompany = (company, options) => {
    return customInstance({
        url: `/companies`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: company,
    }, options);
};
export const useUpdateCompany = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateCompany(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a company
 */
export const readCompany = (companyId, options, signal) => {
    return customInstance({ url: `/companies/${companyId}`, method: 'get', signal }, options);
};
export const getReadCompanyQueryKey = (companyId) => [
    `/companies/${companyId}`,
];
export const useReadCompany = (companyId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadCompanyQueryKey(companyId);
    const queryFn = ({ signal, }) => readCompany(companyId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!companyId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Deletes a company
 */
export const deleteCompany = (companyId, options) => {
    return customInstance({ url: `/companies/${companyId}`, method: 'delete' }, options);
};
export const useDeleteCompany = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { companyId } = props ?? {};
        return deleteCompany(companyId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Updates a company
 */
export const updateCompanySettings = (companyId, companySettings, options) => {
    return customInstance({
        url: `/companies/${companyId}/settings`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: companySettings,
    }, options);
};
export const useUpdateCompanySettings = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { companyId, data } = props ?? {};
        return updateCompanySettings(companyId, data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Creates a contract
 */
export const createContract = (contract, options) => {
    return customInstance({
        url: `/companies/contracts`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: contract,
    }, options);
};
export const useCreateContract = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createContract(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Updates a contract
 */
export const updateContract = (contract, options) => {
    return customInstance({
        url: `/companies/contracts`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: contract,
    }, options);
};
export const useUpdateContract = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateContract(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a contract
 */
export const readContract = (contractId, options, signal) => {
    return customInstance({ url: `/companies/contracts/${contractId}`, method: 'get', signal }, options);
};
export const getReadContractQueryKey = (contractId) => [
    `/companies/contracts/${contractId}`,
];
export const useReadContract = (contractId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadContractQueryKey(contractId);
    const queryFn = ({ signal, }) => readContract(contractId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!contractId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves a list of contracts for a specific company
 */
export const readContracts = (companyId, options, signal) => {
    return customInstance({ url: `/companies/${companyId}/contracts`, method: 'get', signal }, options);
};
export const getReadContractsQueryKey = (companyId) => [
    `/companies/${companyId}/contracts`,
];
export const useReadContracts = (companyId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadContractsQueryKey(companyId);
    const queryFn = ({ signal, }) => readContracts(companyId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!companyId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retreives a list of modules
 */
export const readModules = (companyId, options, signal) => {
    return customInstance({ url: `/companies/${companyId}/modules`, method: 'get', signal }, options);
};
export const getReadModulesQueryKey = (companyId) => [
    `/companies/${companyId}/modules`,
];
export const useReadModules = (companyId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadModulesQueryKey(companyId);
    const queryFn = ({ signal, }) => readModules(companyId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!companyId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Count the
 */
export const countNews = (companyId, params, options, signal) => {
    return customInstance({
        url: `/companies/${companyId}/count-news`,
        method: 'get',
        params,
        signal,
    }, options);
};
export const getCountNewsQueryKey = (companyId, params) => [`/companies/${companyId}/count-news`, ...(params ? [params] : [])];
export const useCountNews = (companyId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getCountNewsQueryKey(companyId, params);
    const queryFn = ({ signal, }) => countNews(companyId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, { enabled: !!companyId, ...queryOptions });
    query.queryKey = queryKey;
    return query;
};
