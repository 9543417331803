import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    filterByMembersContainer: {
        marginTop: 12,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
    },
});
export default styles;
