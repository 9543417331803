import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
const EditWhite = (props) => (<Svg {...props}>
    <G clipPath="url(#a)">
      <Path d="m11.877 3.957-9.45 9.45a.278.278 0 0 0-.079.157l-.629 4.402a.278.278 0 0 0 .314.315l4.403-.63a.278.278 0 0 0 .157-.078l9.492-9.492m-4.209-4.124 2.094-2.094a.278.278 0 0 1 .393 0l3.774 3.774a.278.278 0 0 1 0 .393l-2.052 2.05m-4.209-4.124 4.21 4.124" stroke={props.stroke ?? '#fff'} strokeWidth={2}/>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill={props.fill ?? '#fff'} d="M0 0h20v20H0z"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default EditWhite;
