import UserProfile from '.';
import { stackNavigator } from '../../../routes/navigators';
import profileUserEmailAddressesRoutes from './email-addresses/routes';
import profileUserMobilePhoneRoutes from './mobile-phone/routes';
import MyInformations from './my-informations';
import Notifications from './notifications';
import UserPasswordChange from './password';
const userProfileRoutes = [
    {
        name: '/user-profile',
        deepLink: '/user-profile',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                tabBarLabel: 'profile.user-profile.myinfo.my-information',
                component: UserProfile,
            },
            {
                name: '/my-informations',
                deepLink: '/my-informations',
                tabBarLabel: 'profile.user-profile.myinfo.my-information',
                component: MyInformations,
            },
            ...profileUserEmailAddressesRoutes,
            ...profileUserMobilePhoneRoutes,
            {
                name: '/change-password',
                deepLink: '/change-password',
                tabBarLabel: 'profile.user-profile.myinfo.my-information',
                component: UserPasswordChange,
            },
            {
                name: '/notifications',
                deepLink: '/notifications',
                tabBarLabel: 'profile.user-profile.myinfo.my-information',
                component: Notifications,
            },
        ],
    },
];
export default userProfileRoutes;
