import AsyncStorage from '@react-native-async-storage/async-storage';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { EnumTokenService } from '../screens/auth/type';
import { refresh } from '../services/online/people/people';
export const saveTokens = async ({ accessToken, refreshToken, }) => {
    try {
        await Promise.all([
            AsyncStorage.setItem(EnumTokenService.ACCESS_TOKEN, accessToken),
            AsyncStorage.setItem(EnumTokenService.REFRESH_TOKEN, refreshToken),
        ]);
    }
    catch (err) {
        console.error('Error when store tokens : ', err);
    }
};
export const removeTokens = async () => {
    try {
        await Promise.all([
            AsyncStorage.removeItem(EnumTokenService.ACCESS_TOKEN),
            AsyncStorage.removeItem(EnumTokenService.REFRESH_TOKEN),
        ]);
    }
    catch (err) {
        console.error('Error when store tokens : ', err);
    }
};
export const getTokens = async () => {
    try {
        const [accessToken, refreshToken] = await Promise.all([
            AsyncStorage.getItem(EnumTokenService.ACCESS_TOKEN),
            AsyncStorage.getItem(EnumTokenService.REFRESH_TOKEN),
        ]);
        return { accessToken, refreshToken };
    }
    catch (err) {
        console.error('Error when retrieve tokens : ', err);
    }
    return undefined;
};
export const deleteTokensFromStorage = async () => {
    try {
        await Promise.all([
            AsyncStorage.removeItem(EnumTokenService.ACCESS_TOKEN),
            AsyncStorage.removeItem(EnumTokenService.REFRESH_TOKEN),
        ]);
    }
    catch (err) {
        console.error('Error when delete tokens : ', err);
    }
};
export const checkTokens = async () => {
    const currentDate = moment(new Date());
    const { accessToken, refreshToken } = await getTokens();
    // Those tokens values appears in the Local Storage when we do the E2E tests
    if (accessToken === 'accessToken' || refreshToken === 'refreshToken') {
        await deleteTokensFromStorage();
    }
    if (!accessToken?.length || !refreshToken?.length) {
        console.error('No tokens found');
        await deleteTokensFromStorage();
        return false;
    }
    const decodedAccessToken = jwt_decode(accessToken);
    const decodedRefreshToken = jwt_decode(refreshToken);
    // Decode access and refresh tokens
    const expireAccessTokenDate = moment(decodedAccessToken?.exp * 1000 - 60000); // will set the expired date 1 minute before the true date
    const expireRefreshTokenDate = moment(decodedRefreshToken?.exp * 1000 - 60000); // will set the expired date 1 minute before the true date
    // Check if the token are expired
    const isAccessTokenExpired = expireAccessTokenDate.isBefore(currentDate);
    const isRefreshTokenExpired = expireRefreshTokenDate.isBefore(currentDate);
    // If the token is expired then the user will be redirect to the login page
    if (isRefreshTokenExpired) {
        return false;
    }
    // If the access token is expired then a new one is fetched in the BE
    if (isAccessTokenExpired) {
        try {
            const res = await refresh({ useRefreshToken: true });
            if (res) {
                await saveTokens({
                    accessToken: res.accessToken,
                    refreshToken: res.refreshToken,
                });
            }
        }
        catch (err) {
            console.error('Error when fetching new tokens : ', err);
            await deleteTokensFromStorage();
            return false;
        }
    }
    return true;
};
