import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useCompaniesContext } from '../../../contexts/companies';
import useRouter from '../../../hooks/router/useRouter';
import { useReadEvents, useReadEventTypes, } from '../../../services/online/calendar/calendar';
import { Role } from '../../../services/types/role';
import { findClosestEventFromToday } from '../../../utils/findClosestEventFromToday';
import { useFormik } from 'formik';
import { createForm } from '../../../utils/formik';
const useLogic = () => {
    // Attributes
    const { push, params } = useRouter();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [closestEventId, setClosestEventId] = useState();
    const [eventTypesFilter, setEventTypesFilter] = useState([]);
    const [cardHeight, setCardHeight] = useState();
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    const [selectedTab, setSelectedTab] = useState(0);
    const [futurEventLength, setFuturEventLength] = useState(0);
    const [pastEventLength, setPastEventLength] = useState(0);
    const currentDay = useMemo(() => {
        return moment(new Date()).format('DD MMMM YYYY');
    }, []);
    // Form
    const { values, dirty, getFieldMeta, setValues, ...rest } = useFormik({
        initialValues: {
            fromDate: undefined,
            toDate: undefined,
            period: undefined,
            isInvited: selectedEnrolment?.role === Role?.[0],
            eventTypes: eventTypesFilter,
        },
        onSubmit: () => undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { resetForm } = { ...rest };
    const form = createForm(values, rest);
    // BE - calls
    const { data: allEventsTypes } = useReadEventTypes({
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!selectedCompanyId } });
    // Queries
    const { me } = useCompaniesContext();
    const selectedCalendarFilters = useMemo(() => {
        const isAllEventTypeSelected = values?.eventTypes?.length === allEventsTypes?.length;
        const isPeriodSelected = !!values?.period;
        const allFilters = Object.values(values)?.filter(value => !!value)?.length;
        return (allFilters +
            (isAllEventTypeSelected && -1) +
            (isPeriodSelected && -2) +
            (values?.isInvited ? -1 : isAdmin ? 1 : 0));
    }, [values]);
    const selectedEventTypes = useMemo(() => {
        return (values?.eventTypes
            ?.filter(eventType => {
            return eventType?.isSelected;
        })
            ?.map(documentType => documentType?.id) ?? []);
    }, [values]);
    const { data: eventData, isLoading: isEventDataLoading, refetch: refetchEvents, isRefetching, } = useReadEvents({
        'company-id': selectedCompanyId,
        'is-invited': values.isInvited,
        'date-from': values?.fromDate,
        'date-to': values?.toDate,
        'event-types': selectedEventTypes ?? allEventsTypes?.map(e => e?.id),
    }, {
        query: {
            enabled: !!selectedEventTypes?.length &&
                !!selectedCompanyId &&
                !!selectedEnrolment &&
                !!values?.eventTypes?.length,
        },
    });
    const eventDataFormat = useMemo(() => {
        if (!eventData && !closestEventId) {
            return undefined;
        }
        const filteredEventData = eventData?.filter(event => {
            const today = moment().subtract(1, 'days');
            const eventDate = moment(event?.startDate);
            const previousEventLength = eventData.filter(event => moment(event.startDate).isBefore(today)).length;
            setPastEventLength(previousEventLength);
            return selectedTab === 0
                ? eventDate.isAfter(today)
                : eventDate.isBefore(today);
        });
        if (selectedTab === 0)
            setFuturEventLength(filteredEventData?.length);
        const sorted = _.groupBy(filteredEventData, item => {
            const date = new Date(item.startDate);
            return moment(date).format('MMMM YYYY');
        });
        const eventSorted = Object.keys(sorted).map(date => ({
            title: date,
            data: selectedTab === 0 ? sorted[date] : sorted[date].reverse(),
        }));
        // Reverse the array for the PAST events
        if (selectedTab !== 0)
            return eventSorted.reverse();
        return eventSorted;
    }, [eventData, closestEventId, selectedTab]);
    function findSection(itemId, sectionList) {
        const sectionIndex = sectionList.findIndex(section => {
            return section.data.some(item => item.id === itemId);
        });
        return sectionIndex;
    }
    function handleOnPressEventCard(eventId) {
        push('/company/event-details', {
            companyId: selectedCompanyId,
            eventId,
        });
    }
    function handleOnPressTab(activeKey) {
        setSelectedTab(activeKey);
    }
    function onClickButtonAddEvent() {
        push('/create-event');
    }
    function handleOnLayout(height) {
        setCardHeight(height);
    }
    function onSearchPress() {
        push('/search');
    }
    // Effects
    useEffect(() => {
        if (eventData) {
            setClosestEventId(findClosestEventFromToday(eventData)?.id);
        }
    }, [eventData]);
    useEffect(() => {
        if (allEventsTypes) {
            const eventTypesFilter = allEventsTypes?.map(eventType => {
                return {
                    label: eventType?.name,
                    id: eventType.id,
                    isSelected: true,
                };
            });
            // Set the state
            setEventTypesFilter(eventTypesFilter);
        }
    }, [allEventsTypes]);
    // Refetch anytime the params are changing
    useEffect(() => {
        if (params)
            void refetchEvents();
    }, [params]);
    // Return
    return {
        eventData: eventDataFormat ?? [],
        allEventsTypes,
        isEventDataLoading,
        closestEventId,
        findSection,
        currentDay,
        onClickButtonAddEvent,
        selectedCalendarFilters,
        handleOnPressEventCard,
        handleOnLayout,
        cardHeight,
        currentUser: me,
        isAdmin,
        onSearchPress,
        refetchEventData: () => {
            void refetchEvents();
        },
        isRefetching,
        form,
        resetForm,
        selectedTab,
        handleOnPressTab,
        futurEventLength,
        pastEventLength,
    };
};
export default useLogic;
