/* eslint-disable array-callback-return */
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { useReadEvent, useUpdateEvent, } from '../../../../services/online/calendar/calendar';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { useReadMe } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getInviteesFromEvent from '../../../../utils/getInviteesFromEvent';
import getStaleTime from '../../../../utils/getStaleTime';
import { PresenceStatus } from '../event-details/types';
const useLogic = () => {
    // Attributes
    const { params, goBack, push, getCurrent } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    // Entities
    const selectPeopleEntity = params?.selectPeopleEntity ?? {
        allPeopleInTheCompany: undefined,
        selectedPeople: undefined,
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            searchValue: '',
            searchedPeople: undefined,
        },
        onSubmit: undefined,
        enableReinitialize: true,
    });
    const form = createForm(values, rest);
    // Queries
    const { mutateAsync: updateEvent } = useUpdateEvent();
    const { data: currentUserData } = useReadMe();
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            staleTime: getStaleTime(),
            enabled: !!currentUserData,
        },
    });
    const { data: event, refetch: refetchEvent } = useReadEvent(params?.event?.id, {
        query: {
            enabled: !!params?.event?.id,
        },
    });
    const peopleInTheCompany = useMemo(() => {
        // filter the enrolment items by whether or not the person object is in the inviteesPeople array
        return (enrolment?.items
            ?.filter(enrolment => !event?.inviteeIds?.includes(enrolment?.person?.id))
            // map over the filtered enrolment items and return the person object for each enrolment
            ?.map(enrolment => enrolment?.person));
    }, [enrolment, event?.inviteeIds]);
    // Functions
    function handlePressOnAddInvitees() {
        // Update entity
        selectPeopleEntity.selectedPeople = undefined;
        selectPeopleEntity.allPeopleInTheCompany =
            params?.selectPeopleEntity?.allPeopleInTheCompany ?? peopleInTheCompany;
        push('/select-people', {
            selectPeopleEntity,
            event,
            isMulti: true,
            previousRoute: getCurrent(),
            pageTitle: t(tKeys.taskDetail.taskCard.assignTo),
            selectMembersSectionTitle: t(tKeys.tasks.create_task.assign_to.members),
        });
    }
    async function updateEventWithPresentAttendees(inviteeId, absentAttendsIds, presentAttendsIds, remoteAttendsIds) {
        // Update the list
        presentAttendsIds.push(inviteeId);
        await updateEvent({
            data: {
                ...event,
                presentAttendeeIds: [...presentAttendsIds],
                absentAttendeeIds: [
                    ...absentAttendsIds?.filter(a => a !== inviteeId),
                ],
                remoteAttendeeIds: [
                    ...remoteAttendsIds?.filter(a => a !== inviteeId),
                ],
                invitees: [],
            },
        }, {
            onSuccess: () => {
                void refetchEvent();
            },
        });
    }
    async function updateEventWithAbsentAttendees(inviteeId, absentAttendsIds, presentAttendsIds, remoteAttendsIds) {
        // Update the list
        absentAttendsIds.push(inviteeId);
        await updateEvent({
            data: {
                ...event,
                presentAttendeeIds: [
                    ...presentAttendsIds?.filter(a => a !== inviteeId),
                ],
                absentAttendeeIds: [...absentAttendsIds],
                remoteAttendeeIds: [
                    ...remoteAttendsIds?.filter(a => a !== inviteeId),
                ],
                invitees: [],
            },
        }, {
            onSuccess: () => {
                void refetchEvent();
            },
        });
    }
    async function updateEventWithRemoteAttendees(inviteeId, absentAttendsIds, presentAttendsIds, remoteAttendsIds) {
        // Update the list
        remoteAttendsIds.push(inviteeId);
        await updateEvent({
            data: {
                ...event,
                presentAttendeeIds: [
                    ...presentAttendsIds?.filter(a => a !== inviteeId),
                ],
                absentAttendeeIds: [
                    ...absentAttendsIds?.filter(a => a !== inviteeId),
                ],
                remoteAttendeeIds: [...remoteAttendsIds],
                invitees: [],
            },
        }, {
            onSuccess: () => {
                void refetchEvent();
            },
        });
    }
    async function handlePresenceStatus(presenceStatus, inviteeId) {
        const { absentAttendeeIds, presentAttendeeIds, remoteAttendeeIds } = getInviteesFromEvent(event);
        // determine which function to call based on the presence status
        switch (presenceStatus) {
            case PresenceStatus.Present:
                await updateEventWithPresentAttendees(inviteeId, absentAttendeeIds, presentAttendeeIds, remoteAttendeeIds);
                break;
            case PresenceStatus.Absent:
                await updateEventWithAbsentAttendees(inviteeId, absentAttendeeIds, presentAttendeeIds, remoteAttendeeIds);
                break;
            case PresenceStatus.Remote:
                await updateEventWithRemoteAttendees(inviteeId, absentAttendeeIds, presentAttendeeIds, remoteAttendeeIds);
                break;
        }
    }
    // Effect
    useEffect(() => {
        if (!selectPeopleEntity?.selectedPeople)
            return;
        // Add the new invitees
        const personIds = selectPeopleEntity?.selectedPeople?.map(p => p?.id);
        const { absentAttendeeIds, presentAttendeeIds, remoteAttendeeIds } = getInviteesFromEvent(event);
        void updateEvent({
            data: {
                ...event,
                inviteeIds: [...(event?.inviteeIds ?? []), ...personIds],
                presentAttendeeIds: [...presentAttendeeIds, ...personIds],
                absentAttendeeIds: [...absentAttendeeIds],
                remoteAttendeeIds: [...remoteAttendeeIds],
                invitees: [],
            },
        }, {
            onSuccess: () => {
                void refetchEvent();
            },
        });
    }, [selectPeopleEntity?.selectedPeople]);
    // Return
    return {
        goBack,
        event,
        handlePressOnAddInvitees,
        form,
        handlePresenceStatus,
        currentUserData,
    };
};
export default useLogic;
