import React from 'react';
import AvatarMessagePreview from '../../../../../../../../components/content/avatar-message-preview';
import COLORS from '../../../../../../../../constants/colors';
import { Box } from '@chakra-ui/react';
import Icon from '../../../../../../../../components/content/icon';
import { useMessagesListItemLogic } from './logic.web';
import ChatSettings from '../../../../../chat-settings/index.web';
const MessagesListItem = ({ channel, onChannelPress, selectedChannelId, getParticipant, }) => {
    // Attributes
    const { isHover, isOpenChatSettings, isSelected, onCloseChatSettings, setIsHover, handleInformationClick, isLastMessageUnread, } = useMessagesListItemLogic(selectedChannelId, channel);
    // Render
    return (<Box id={channel?.id} style={{
            backgroundColor: isSelected ? COLORS.BG.value : undefined,
            width: '100%',
        }} px={'1rem'} py={'.5rem'} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} position={'relative'}>
      <AvatarMessagePreview isGroupChat={channel?.isGroup} conversationName={channel?.name} personId={getParticipant(channel)} lastMessageDate={channel?.lastMessageReceivedOn} channelId={channel?.id} isLastMessagesUnread={isLastMessageUnread} onPress={() => onChannelPress(channel)} picture={channel?.picture}/>
      {(isHover || isSelected) && (<Box position={'absolute'} right={'.75rem'} bottom={'.75rem'} p=".5rem" bgColor={COLORS.White.T500.value} borderRadius={'.5rem'} boxShadow={'md'} cursor={'pointer'} onClick={handleInformationClick}>
          <Icon name="info"/>
        </Box>)}
      {/* Modal */}
      <ChatSettings channelId={channel?.id} isOpen={isOpenChatSettings} onClose={onCloseChatSettings}/>
    </Box>);
};
export default MessagesListItem;
