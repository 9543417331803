import React from 'react';
import Button from '../../../../components/inputs/button';
import FormControl from '../../../../components/inputs/form-control';
import PasswordInput from '../../components/inputs/password-input';
import { tKeys } from '../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import useLogic from './logic';
import { PageWebAuth } from '../../../../components/surfaces/page-auth/index.web';
import { Stack, VStack } from '@chakra-ui/react';
import Text from '../../../../components/content/text';
import COLORS from '../../../../constants/colors';
const SignInCreateNewPassword = () => {
    // Attributes
    const { handleOnPressBackButton, form, onButtonClick, resetPaswordIsLoading, } = useLogic();
    const { t } = useTranslation();
    const ConfirmButton = (<Stack pt={'3.5rem'} width={'full'}>
      <Button onPress={onButtonClick} isDisabled={form?.newPassword?.value?.length <= 0 ||
            form?.confirmNewPassword?.value?.length <= 0 ||
            form?.newPassword?.value !== form?.confirmNewPassword?.value} isLoading={resetPaswordIsLoading} width={'full'}>
        <Text color={COLORS.White.T500.value}>{t(tKeys.common.confirm)}</Text>
      </Button>
    </Stack>);
    // Render
    return (<PageWebAuth onGoBack={handleOnPressBackButton} title={t(tKeys.common['enter-your-info'])}>
      {/* Sign-up form */}
      <VStack alignItems={'flex-start'} width={'full'} p={'2.5rem'} spacing={'.75rem'}>
        {/*  Password  */}
        <FormControl label={t(tKeys.common.password.label)} error={form.newPassword?.meta?.error} spacing={2}>
          <PasswordInput {...form?.newPassword} style={{
            paddingVertical: 12,
            borderRadius: '.5rem',
        }} placeholder={t(tKeys.common.password.placeholder)}/>
        </FormControl>
        {/* Confirm password */}
        <FormControl label={t(tKeys.common['confirm-password'].label)} error={form.confirmNewPassword?.meta?.error} spacing={2}>
          <PasswordInput {...form?.confirmNewPassword} style={{
            paddingVertical: 12,
            borderRadius: '.5rem',
        }} placeholder={t(tKeys.common.password.placeholder)}/>
        </FormControl>
        {ConfirmButton}
      </VStack>
    </PageWebAuth>);
};
export default SignInCreateNewPassword;
