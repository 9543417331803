import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    container: {
        height: 60,
        width: '90%',
        zIndex: 99,
        backgroundColor: COLORS.White.T500.value,
        border: '1px solid',
        borderColor: COLORS.Line.value,
        borderRadius: 8,
        position: 'absolute',
        bottom: 0,
        left: 0,
        marginBottom: 40,
        padding: 14,
        marginHorizontal: 12,
        shadowColor: COLORS.Black.T100.value,
        shadowOpacity: 0.2,
        shadowOffset: {
            height: 6,
            width: 0,
        },
        shadowRadius: 20,
    },
    iconContainer: {
        marginRight: 8,
        borderRadius: 8,
        padding: 8,
        alignContent: 'center',
        alignSelf: 'center',
    },
    crossIconContainer: {
        alignContent: 'center',
        justifyContent: 'center',
    },
});
export default styles;
