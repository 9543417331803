import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, View } from 'react-native';
import Text from '../../../../components/content/text';
import FilterHeader from '../../../../components/surfaces/filter-header/index.web';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import useLogic from './logic.web';
import styles from './style';
import { Checkbox, HStack, Stack, VStack } from '@chakra-ui/react';
import AvatarDetails from '../../../../components/content/avatar-details';
const Filter = ({ form, values, resetForm, }) => {
    // Attributes
    const { allCompanyMembers, isMemberSelected, onChooseMember, onPressPeriodTimeButton, onPressFromDateButton, onPressToDateButton, onSelectAllMembers, isAllMembersSelected, } = useLogic(form, values);
    const { t } = useTranslation();
    // Render
    return (<VStack width={'30rem'} maxH={'45rem'} alignItems={'flex-start'}>
      {/* Header */}
      <HStack width={'full'} justifyContent={'space-between'} px={'1rem'} pt={'1rem'}>
        <Text>{t(tKeys.common.filters)}</Text>
        <Stack onClick={resetForm} cursor={'pointer'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Govrn.Blue.T500.value}>
            {t(tKeys.common.reset)}
          </Text>
        </Stack>
      </HStack>

      {/* Main content */}
      <FlatList data={allCompanyMembers} style={{ ...styles.mainContainer, paddingBottom: 0 }} showsVerticalScrollIndicator={false} contentContainerStyle={styles.flatlistContent} ListHeaderComponent={() => (<FilterHeader title={t(tKeys.home.feed.filter['custom-oeriods'].title)} 
        // From date props
        fromDateValue={form?.fromDate?.value ?? ''} onPressFromDateButton={onPressFromDateButton} isFromDateDirty={form?.fromDate?.meta?.touched && !form?.period?.value} closeFromDateCalendar={!!form?.period?.value} 
        // To date props
        toDateValue={form?.toDate?.value ?? ''} onPressToDateButton={onPressToDateButton} isToDateDirty={form?.toDate?.meta?.touched && !form?.period?.value} closeToDateCalendar={!!form?.period?.value} 
        // Period group buttons props
        selectedPeriodValue={form?.period?.value} handleOnPressPeriodTimeButton={period => onPressPeriodTimeButton(period)} 
        // Options props
        handleOnSelect={onSelectAllMembers} isAllOptionsSelected={isAllMembersSelected}/>)} renderItem={({ item }) => (<HStack justify="space-between" key={item?.id} width={'full'} px={'1rem'}>
            <AvatarDetails person={item} showStatus avatarSize={'2.625rem'} showBoardRole/>
            <Checkbox onChange={() => {
                onChooseMember(item);
            }} isChecked={isMemberSelected(item)}/>
          </HStack>)} keyExtractor={item => item?.id} ItemSeparatorComponent={() => <View style={styles.separator}/>}/>
    </VStack>);
};
export default Filter;
