import React from 'react';
import Calendar from '.';
import Icon from '../../../components/content/icon';
import { stackNavigator } from '../../../routes/navigators';
import EventDetails from './event-details';
import { RouteEnum } from '../routeEnum';
const calendarRoutes = [
    // Feed route
    {
        name: '/calendar',
        deepLink: '/calendar',
        defaultRoute: '/default',
        component: stackNavigator,
        activeIcon: (<Icon name="navigation-bar-calendar-selected" width={24} height={24}/>),
        inactiveIcon: (<Icon name="navigation-bar-calendar-default" width={24} height={24} testID={'Calendar'}/>),
        routeEnum: RouteEnum.Calendar,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                tabBarLabel: 'page.menu.calendar',
                component: Calendar,
            },
            {
                name: '/event-details',
                deepLink: '/event-details',
                component: EventDetails,
            },
        ],
    },
];
export default calendarRoutes;
