import { HStack, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import COLORS from '../../../../../constants/colors';
import Icon from '../../../../../components/content/icon';
import Text from '../../../../../components/content/text';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../translations/keys';
import Button from '../../../../../components/inputs/button/index.web';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import FONTS from '../../../../../constants/fonts';
export const DocumentDetailsSignAlertCard = ({ onClickSign, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Stack position={'absolute'} bottom={'1.25rem'} px={'1.75rem'} width={'full'}>
      <HStack p={'1.25rem'} width={'full'} borderRadius={'.75rem'} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'} bgColor={COLORS.White.T500.value} justifyContent={'space-between'}>
        <HStack spacing={'1rem'}>
          <Icon name="sign-icon"/>
          <VStack alignItems={'flex-start'} spacing={'.25rem'}>
            <Text numberOfLines={1} font={FONTS.T1.T14Px.Bold.value}>
              {t(tKeys.document.details.sign.alert.title)}
            </Text>
            <Text numberOfLines={2}>
              {t(tKeys.document.details.sign.alert.subtitle)}
            </Text>
          </VStack>
        </HStack>
        <Button kind={Kind.SECONDARY} width={'fit-content'} onPress={onClickSign}>
          {t(tKeys.document.details.sign.alert.button)}
        </Button>
      </HStack>
    </Stack>);
};
