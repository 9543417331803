import SignUpPage from '.';
import SignUpInvitationExpired from './sign-up-invitation-expired';
import SignUpCreateAccount from './sign-up-create-account';
import { stackNavigator } from '../../../routes/navigators';
const signUpRoutes = [
    {
        name: '/sign-up',
        deepLink: '/sign-up',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: SignUpPage,
            },
            {
                name: '/invitation-expired',
                deepLink: '/invitation-expired',
                component: SignUpInvitationExpired,
            },
            {
                name: '/create-account',
                deepLink: '/create-account',
                component: SignUpCreateAccount,
            },
        ],
    },
];
export default signUpRoutes;
