import React, { useMemo } from 'react';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import NavigationHeader from '../../../../components/navigation/navigation-header/index.web';
import Flex from '../../../../components/surfaces/flex';
import Search from '../../../search/index.web';
import useLogic from './logic.web';
import { tKeys } from '../../../../translations/keys';
import DocumentList from '../components/list/document-list/index.web';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../../calendar/components/inputs/tag-field/tag-item/index.web';
import SelectDocumentLanguages from '../../calendar/document-language/index.web';
import Text from '../../../../components/content/text';
import FONTS from '../../../../constants/fonts';
const KnowledgeBase = () => {
    // Attributes
    const { t, templates, isTemplatesLoading, isDropdownOpen, setDropdownOpen, listLayoutType, onTemplatePress, onLayoutIconPress, optionsOrder, refetchTemplates, form, languages, onSelectLanguage, isOpenLanguage, onToggleLanguage, } = useLogic();
    const removeThumbnail = templates?.map(document => {
        return {
            ...document,
            file: {
                ...document.file,
                thumbnailUri: undefined,
            },
        };
    });
    const options = useMemo(() => languages?.map(language => {
        return {
            id: language?.value,
            children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
              {language?.label}
            </Text>),
        };
    }), [languages]);
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader style={{ zIndex: 900 }} justifyTitle={'flex-start'} endEnhancer={<Flex spacing={20} align="center">
            <PopOver placement={'bottom-end'} from={<TagFieldItem value={form?.cultureName?.value} hasEndEnhancer/>} isOpen={isOpenLanguage} onToggle={onToggleLanguage}>
              <SelectDocumentLanguages onSelectLanguage={onSelectLanguage} selectedLanguage={form?.cultureName?.value} optionsOverride={options}/>
            </PopOver>
            <Search />
          </Flex>}>
        {t(tKeys.more.page.menu['knowledge-base'].menu_title)}
      </NavigationHeader>

      {/* Main content */}
      <DocumentList kind="template" documentsData={[
            {
                title: t(tKeys.common.recent_document),
                data: removeThumbnail ?? [],
            },
        ]} isDocumentDataLoading={isTemplatesLoading} isDropdownOpen={isDropdownOpen} listLayoutType={listLayoutType} onDocumentPress={onTemplatePress} onLayoutIconPress={onLayoutIconPress} optionsOrder={optionsOrder} refetchDocumentData={refetchTemplates} setDropdownOpen={setDropdownOpen} sortingForm={form}/>
    </Page>);
};
export default KnowledgeBase;
