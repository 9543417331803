import React from 'react';
import { HStack, Td, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../../../translations/keys';
import COLORS from '../../../../../../../../constants/colors';
import Text from '../../../../../../../../components/content/text';
import FONTS from '../../../../../../../../constants/fonts';
import Icon from '../../../../../../../../components/content/icon';
import { SortBy } from '../../../../../../../../services/types';
export const DocumentListTableHeader = ({ setOrderBy, orderBy, optionsOrder, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Thead position={'sticky'} top={0} zIndex={2} backgroundColor={COLORS.White.T500.value}>
      <Tr>
        {/* Document thumbnail */}
        <Td pl={'.75rem'} pr={'.5rem'} py={'.75rem'}></Td>
        {/* Document title */}
        <Td pl={'.75rem'} pr={'.5rem'} py={'.75rem'}>
          <HStack alignItems="center" onClick={() => {
            if (orderBy?.value === SortBy?.[2]) {
                setOrderBy(optionsOrder?.[3]);
            }
            else
                setOrderBy(optionsOrder?.[2]);
        }} cursor={'pointer'}>
            <Icon name={orderBy?.value === SortBy?.[2]
            ? 'chevron-up'
            : orderBy?.value === SortBy?.[3]
                ? 'chevron-down'
                : 'filter-order'} width={16} height={16} stroke={orderBy?.value !== SortBy?.[2] && orderBy?.value !== SortBy?.[3]
            ? COLORS.Text.T200.value
            : COLORS.Text.T900.value}/>
            <Text color={orderBy?.value === SortBy?.[2] || orderBy?.value === SortBy?.[3]
            ? COLORS.Text.T900.value
            : COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
              {t(tKeys.common.title)}
            </Text>
          </HStack>
        </Td>
        {/* Document types */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.document_types)}
          </Text>
        </Td>
        {/* Issued On */}
        <Td px={'.5rem'} py={'.75rem'}>
          <HStack alignItems="center" onClick={() => {
            if (orderBy?.value === SortBy?.[0]) {
                setOrderBy(optionsOrder?.[1]);
            }
            else
                setOrderBy(optionsOrder?.[0]);
        }} cursor={'pointer'}>
            <Icon name={orderBy?.value === SortBy?.[0]
            ? 'chevron-up'
            : orderBy?.value === SortBy?.[1]
                ? 'chevron-down'
                : 'filter-order'} width={16} height={16} stroke={orderBy?.value !== SortBy?.[0] && orderBy?.value !== SortBy?.[1]
            ? COLORS.Text.T200.value
            : COLORS.Text.T900.value}/>
            <Text color={orderBy?.value === SortBy?.[0] || orderBy?.value === SortBy?.[1]
            ? COLORS.Text.T900.value
            : COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
              {t(tKeys.common.issued_on)}
            </Text>
          </HStack>
        </Td>
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.common.updated)}
          </Text>
        </Td>
        {/* Linked Event */}
        <Td px={'.5rem'} py={'.75rem'}>
          <Text color={COLORS.Text.T200.value} font={FONTS.T1.T12Px.Medium500.value}>
            {t(tKeys.document.details.linked_event)}
          </Text>
        </Td>
      </Tr>
    </Thead>);
};
