import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import CompanyNotifications from '../../components/surfaces/company-notifications';
import useLogic from './logic';
import { VStack } from '@chakra-ui/react';
const Notifications = () => {
    // Attributes
    const { currentUserData, handleOnPressBackButton } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={handleOnPressBackButton} style={{ minHeight: '3.75rem' }}>
        {`${t(tKeys.profile['user-profile']['my-information'])} / ${t(tKeys.profile['user-profile'].notif['navigation-header'])}`}
      </NavigationHeader>

      <VStack width={'full'} height={'full'} alignItems={'flex-start'} bgColor={COLORS.BG.value} p={'1rem'}>
        {currentUserData?.enrolments?.map(enrolment => (<CompanyNotifications key={enrolment?.id} enrolment={enrolment}/>))}
      </VStack>
    </Page>);
};
export default Notifications;
