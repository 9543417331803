import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import FormControl from '../../../../components/inputs/form-control';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import useLogic from './logic.web';
import Modal from '../../../../components/surfaces/modal/index.web';
import { HStack, Stack, StackDivider, VStack } from '@chakra-ui/react';
import Input from '../../../../components/inputs/input/index.web';
import Icon from '../../../../components/content/icon';
import TagField from '../components/inputs/tag-field';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../components/inputs/tag-field/tag-item';
import { getAgendaItemTypeLabel } from '../../../../utils/agendaItem';
import SelectAgendaItemType from '../../../select-agenda-item-type/index.web';
import { VotingCollapse } from './voting/index.web';
import AgendaItemDocuments from '../agenda-item-documents/index.web';
const EditAgendaItem = ({ isOpen, onClose, agendaItemEntity, refetchAgendaItems, isAdmin, event, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isEditMode, handleSubmit, form, showVotting, setShowVotting, onDeleteAgendaItem, onDeleteVoting, onTerminateVote, isDeleteLoading, isCreateLoading, isCloseQuestionLoading, isDeleteQuestionLoading, isUpdateLoading, isOpenQuestionLoading, isQuestionClosed, onOpenVote, isAnonymousDisabled, showDeleteVotingBtn, showTerminateBtn, resetForm, handleAgendaItemTypeToggle, isAgendaItemTypeOpen, handleAgendaItemTypeChange, isDecisionType, } = useLogic(agendaItemEntity, onClose, refetchAgendaItems);
    // Render
    return (<Modal isOpen={isOpen} onClose={() => {
            setShowVotting(false);
            resetForm();
            onClose();
        }} size={'3xl'} title={isAdmin
            ? isEditMode
                ? t(tKeys.calendar.agenda.item.edit)
                : t(tKeys.calendar.agenda.item.add)
            : agendaItemEntity?.title} actionButton={<HStack width="full" justifyContent={isEditMode ? 'flex-end' : 'space-between'}>
          {!isEditMode && (<HStack px="0.5rem" py="0.25rem" bgColor={COLORS.Warning.T100.value} maxW="24.5rem">
              <HStack>
                <Icon name="warning"/>
              </HStack>
              <Text color={COLORS.Warning.T600.value} numberOfLines={3}>
                We will not be sending notifications to your event invitees. The
                event will be visible on your calendar.
              </Text>
            </HStack>)}
          {isAdmin && (<HStack spacing={'.5rem'}>
              {isEditMode && (<Button kind={Kind.TRANSPARENT} onPress={onDeleteAgendaItem} isLoading={isDeleteLoading} backgroundColorPerso="white" borderColor="red" borderWidth={1}>
                  <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                    {t(tKeys.calendar.agenda.item.delete.title)}
                  </Text>
                </Button>)}
              <Button size={Size.DEFAULT} kind={Kind.SECONDARY} onPress={handleSubmit} isLoading={isCreateLoading || isUpdateLoading}>
                {t(tKeys.common.save)}
              </Button>
            </HStack>)}
        </HStack>} hasCloseButton>
      <VStack alignItems={'flex-start'} width={'full'} overflow={'auto'} divider={<StackDivider />}>
        <HStack width={'full'} px={'1rem'} py={'1.25rem'} spacing={'1.25rem'}>
          {/* Title */}
          <FormControl label={t(tKeys.common.title) ?? ''} spacing={4} isMandatory>
            <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder) ?? ''} isReadOnly={!isAdmin} autoFocus/>
          </FormControl>
          {/* Timing */}
          <Stack width={'50%'}>
            <FormControl label={t(tKeys.common.timing.title) ?? ''} spacing={4}>
              <Input value={form?.duration.value} onChange={e => {
            form?.duration.onChange?.(e?.currentTarget?.value);
        }} type="number" placeholder={t(tKeys.common.timing.title) ?? ''} isReadOnly={!isAdmin}/>
            </FormControl>
          </Stack>
        </HStack>

        {/* Agenda item type */}
        <Stack width={'100%'}>
          <TagField title={t(tKeys.agenda_item.type.title)} startEnhancer={<Icon name="layers"/>} endEnhancer={isAdmin ? (<PopOver placement={'bottom-end'} from={<TagFieldItem value={getAgendaItemTypeLabel(form?.type?.value)} hasEndEnhancer/>} isOpen={isAgendaItemTypeOpen} onToggle={handleAgendaItemTypeToggle} isPortal={false}>
                  <SelectAgendaItemType agendaItemType={form?.type?.value} onChange={handleAgendaItemTypeChange}/>
                </PopOver>) : (<TagFieldItem value={getAgendaItemTypeLabel(form?.type?.value)}/>)} isMandatory error={form?.type?.meta?.error}/>
        </Stack>
        {isDecisionType && !showVotting && isAdmin && (<HStack width={'full'} px={'1rem'} pt={'1.25rem'} pb={'1.5rem'} spacing={'.75rem'} onClick={() => setShowVotting(true)}>
            <Icon name="add-document-blue" width={32} height={32} fill={COLORS.Text.T400.value}/>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.calendar.agenda.item.addVoting)}
            </Text>
          </HStack>)}

        {/* Voting */}
        {showVotting && (<VotingCollapse form={form} isAnonymousDisabled={isAnonymousDisabled} isCloseQuestionLoading={isCloseQuestionLoading} isDeleteQuestionLoading={isDeleteQuestionLoading} isEditMode={isEditMode} isOpenQuestionLoading={isOpenQuestionLoading} isQuestionClosed={isQuestionClosed} onVoteDelete={onDeleteVoting} onVoteOpen={onOpenVote} onVoteTerminate={onTerminateVote} showDeleteVotingBtn={showDeleteVotingBtn} showTerminateBtn={showTerminateBtn} showVotting={showVotting} isAdmin={isAdmin}/>)}

        {/* Documents */}
        {isEditMode && (<AgendaItemDocuments isOpen={isOpen} agendaItemId={agendaItemEntity?.id} event={event} onClose={onClose} isAdmin={isAdmin}/>)}
      </VStack>
    </Modal>);
};
export default EditAgendaItem;
