import React, { forwardRef } from 'react';
import { Button, HStack, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Stack, VStack, } from '@chakra-ui/react';
import { tKeys } from '../../translations/keys';
import COLORS from '../../constants/colors';
import SHADOWS from '../../constants/shadows';
import Input from '../../components/inputs/input/index.web';
import Icon from '../../components/content/icon';
import AvatarDetails from '../../components/content/avatar-details';
import LoadingWrapper from '../../components/utility/loading-wrapper/index.web';
import Text from '../../components/content/text';
import FONTS from '../../constants/fonts';
import { useTranslation } from 'react-i18next';
import { GroupAvatars } from '../../components/content/group-avatars/index.web';
// eslint-disable-next-line react/display-name
export const SelectPeople = forwardRef(({ isPopoverOpen, onPopoverToggle, groups, peoples, onSelectGroup, onSelectPeople, isLoading, setSearch, isPortal = true, searchColor = COLORS.SearchFullScreen.value, paddingX = '1.25rem', placeholder, }, ref) => {
    // Attributes
    const { t } = useTranslation();
    // Render content
    const popoverContent = (<PopoverContent width={'fit-content'} borderColor={COLORS.Line.value} boxShadow={SHADOWS.Sidebar.value} overflow={'hidden'} ref={ref}>
        <PopoverBody>
          <VStack width={'full'} alignItems={'flex-start'} maxHeight={'25rem'} py={'.5rem'} minW={'25rem'} overflow={'auto'} spacing={'1.25rem'}>
            <LoadingWrapper isLoading={isLoading}>
              <>
                {!!groups && groups.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.375rem'}>
                    <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                      {t(tKeys.common.groups_number, {
                value: groups.length,
            })}
                    </Text>
                    <VStack alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
                      {groups.map(group => (<HStack justify="space-between" key={group.id} width={'full'} onClick={() => {
                    void onSelectGroup(group.id ?? '');
                }} cursor={'pointer'}>
                          <GroupAvatars group={group} showNumberOfMembers/>
                        </HStack>))}
                    </VStack>
                  </VStack>)}
                {!!peoples && peoples.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.375rem'}>
                    <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                      {t(tKeys.common.members_number, {
                value: peoples.length,
            })}
                    </Text>
                    <VStack alignItems={'flex-start'} width={'full'} spacing={'.5rem'}>
                      {peoples.map(person => (<HStack justify="space-between" key={person.id} width={'full'} onClick={() => {
                    void onSelectPeople(person.person?.id ?? '');
                }} cursor={'pointer'}>
                          <AvatarDetails person={person?.person} avatarSize={'2.25rem'} showBoardRole/>
                        </HStack>))}
                    </VStack>
                  </VStack>)}
              </>
            </LoadingWrapper>
          </VStack>
        </PopoverBody>
      </PopoverContent>);
    // Render
    return (<Popover placement="bottom-end" isOpen={isPopoverOpen} autoFocus={false} onClose={onPopoverToggle} closeOnBlur>
        <PopoverTrigger>
          <Button variant={'ghost'} _hover={{ backgroundColor: 'transparent' }} onClick={() => {
            !isPopoverOpen && onPopoverToggle();
        }} width={'full'} px={0}>
            <Stack width={'full'} px={paddingX}>
              <HStack width={'full'} bgColor={searchColor} borderRadius={'.5rem'} px={'.5rem'}>
                <Icon name="search" width={24} height={24}/>
                <Input name="searchGlobal" onChange={e => setSearch(e?.currentTarget?.value)} placeholder={placeholder ?? t(tKeys.common.search_members)} isTruncated borderWidth={0} _focusVisible={{ borderWidth: 0 }} style={{ height: 40, width: '100%' }}/>
              </HStack>
            </Stack>
          </Button>
        </PopoverTrigger>
        {isPortal ? <Portal>{popoverContent}</Portal> : popoverContent}
      </Popover>);
});
