import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../components/content/text';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size';
import Flex from '../../../../../components/surfaces/flex';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { Shape } from '../../../../../constants/shape';
import { tKeys } from '../../../../../translations/keys';
import { SearchResultType } from '../search-result-card/props';
import styles from './style';
import { HStack } from '@chakra-ui/react';
const SearchListHeader = ({ resultsTotal, activeFilters, onFilterPress, total, search, }) => {
    // Attributes
    const { t } = useTranslation();
    const renderFilters = (item) => {
        const searchType = item[0]; // key
        const resultLength = item[1]; // value
        const isSelected = activeFilters[searchType];
        const handleTextColor = () => {
            if (isSelected)
                return COLORS.White.T500.value;
            if (resultLength === 0)
                return COLORS.Text.T200.value;
            return COLORS.Text.T500.value;
        };
        return (<Button key={searchType} kind={isSelected ? Kind.SECONDARY : Kind.GRAY} shape={Shape.PILL} size={Size.MINI} isDisabled={resultLength === 0} onPress={() => onFilterPress(Number(searchType))} borderColor={COLORS.Line.value} borderWidth={isSelected ? 0 : 1}>
        <Text font={FONTS.T1.T12Px.Medium500.value} style={styles.buttonText} color={handleTextColor()}>
          {t(tKeys.search.filter?.[SearchResultType[searchType]], {
                count: resultLength,
            })}
        </Text>
      </Button>);
    };
    // Render
    return (<Flex direction="column" spacing={20} style={styles.container}>
      <Text font={FONTS.T1.T16Px.Bold700.value} style={styles.total}>
        {t(tKeys.search.totalResult, { total, search })}
      </Text>
      <HStack width={'full'} justifyContent={'space-around'}>
        {Object.entries(resultsTotal).map(item => (<>{renderFilters(item)}</>))}
      </HStack>
    </Flex>);
};
export default SearchListHeader;
