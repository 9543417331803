import React from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../constants/colors';
import { TaskTypeEnum } from '../../../enum/TaskType';
import { tKeys } from '../../../translations/keys';
import Tag from '../../content/tag';
const TaskTag = ({ taskType }) => {
    // Attributes
    const { t } = useTranslation();
    // Function
    const getDataByTagType = () => {
        switch (TaskTypeEnum[taskType]) {
            case 'Read':
                return {
                    textColor: COLORS.Govrn.PastelGreen.T500.value,
                    backgroundColor: COLORS.Govrn.PastelGreen.T100.value,
                    textValue: t(tKeys.tasks.type.READ),
                };
            case 'RSVP':
                return {
                    textColor: COLORS.Govrn.Pink.T500.value,
                    backgroundColor: COLORS.Govrn.Pink.T100.value,
                    textValue: t(tKeys.tasks.type.RSVP),
                };
            case 'Proxy':
                return {
                    textColor: COLORS.Govrn.Yellow.T500.value,
                    backgroundColor: COLORS.Govrn.Yellow.T100.value,
                    textValue: t(tKeys.tasks.type.PROXY),
                };
            case 'Sign':
                return {
                    textColor: COLORS.Warning.T500.value,
                    backgroundColor: COLORS.Warning.T100.value,
                    textValue: t(tKeys.tasks.type.SIGN),
                };
            case 'Custom':
                return {
                    textColor: COLORS.Govrn.DarkBlue.T500.value,
                    backgroundColor: COLORS.Govrn.DarkBlue.T100.value,
                    textValue: t(tKeys.tasks.type.ACTION),
                };
            default:
                return {
                    textColor: '',
                    backgroundColor: '',
                    textValue: '',
                };
        }
    };
    const { backgroundColor, textColor, textValue } = getDataByTagType();
    // Render
    return (<Tag backgroundColor={backgroundColor} color={textColor}>
      {textValue}
    </Tag>);
};
export default TaskTag;
