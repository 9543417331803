import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Image from '../../content/image';
import Text from '../../content/text';
import Flex from '../../surfaces/flex';
import styles from './style';
import { Box } from '@chakra-ui/react';
const Placeholder = ({ imageType, backgroundColor = COLORS.Line.value, title, subTitle, imageSize = 58, endEnhancer, maxWidth, style, hasImage = true, }) => {
    // Functions
    const getImagePath = () => {
        switch (imageType) {
            case 'folder':
                return require('../../../assets/images/placeholders/folder.png');
            case 'monocle-emoji':
                return require('../../../assets/images/placeholders/monocle-emoji.png');
            case 'think-emoji':
                return require('../../../assets/images/placeholders/think-emoji.png');
            case 'writing-hand-emoji':
                return require('../../../assets/images/placeholders/writing-hand-emoji.png');
            case 'thumb-up':
                return require('../../../assets/images/placeholders/thumb-up.png');
            default:
                return require('../../../assets/images/placeholders/search-emoji.png');
        }
    };
    // Render
    return (<Flex style={[
            {
                ...styles.mainContainer,
                paddingTop: '10%',
            },
            style,
        ]} direction="column">
      {hasImage && (<Box p={'1.5rem'} borderRadius={'1.625rem'} backgroundColor={backgroundColor}>
          <Image isLocalImage={true} imageURI={getImagePath()} width={imageSize} height={imageSize}/>
        </Box>)}

      <Flex style={styles.placeholderTextContainer}>
        <Text font={FONTS.T1.T16Px.SemiBold600.value} color={COLORS.Text.T400.value} style={styles.text} numberOfLines={2}>
          {title}
        </Text>
      </Flex>

      <Flex style={styles.subTitleContainer} justify="center" align="center">
        <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.InputText.value} numberOfLines={4} style={styles.text} maxWidth={maxWidth}>
          {subTitle}
        </Text>
      </Flex>

      {endEnhancer || null}
    </Flex>);
};
export default Placeholder;
