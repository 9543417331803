import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../hooks/router/useRouter';
import { useResetForgotPassword } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import { validationSchema } from './validations';
import { useToast } from '../../../../components/content/use-toast/index.web';
const useLogic = () => {
    // Attributes
    const { push, goBack, params } = useRouter();
    const { t } = useTranslation();
    const signInForgotPasswordEntity = params?.signInForgotPasswordEntity;
    const toast = useToast();
    // Queries
    const { mutateAsync: resetPassword, isLoading: resetPaswordIsLoading } = useResetForgotPassword();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const onButtonClick = (values) => {
        void resetPassword({
            data: {
                email: signInForgotPasswordEntity.email,
                password: values.newPassword,
                token: signInForgotPasswordEntity.token,
            },
        }, {
            onSuccess: () => {
                push('/auth/sign-in/default');
                toast({
                    status: 'success',
                    title: t(tKeys.validation.login_in.reset_password),
                });
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (err) => {
                console.error('Error with reset password : ', err);
                const passwordError = err.response?.data?.errors?.Password?.[0];
                if (passwordError) {
                    setFieldError('newPassword', passwordError);
                    setFieldError('confirmNewPassword', passwordError);
                }
            },
        });
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            newPassword: '',
            confirmNewPassword: '',
        },
        onSubmit: onButtonClick,
        validationSchema: validationSchema(t),
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { submitForm, setFieldError } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        form,
        onButtonClick: submitForm,
        resetPaswordIsLoading,
    };
};
export default useLogic;
