import { useDrag, useDrop } from 'react-dnd';
import { useDeleteAgendaItem } from '../../../../../../services/online/calendar/calendar';
import { useDisclosure } from '@chakra-ui/react';
import useRouter from '../../../../../../hooks/router/useRouter';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
import { Toast } from '../../../../../../components/content/use-toast/toast/index.web';
import { getReadDocumentsQueryKey, useCreateDocument, useReadDocumentTypes } from '../../../../../../services/online/repository/repository';
import { t } from 'i18next';
import { tKeys } from '../../../../../../translations/keys';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
export const useAgendaItemLogic = (agendaItem, handleDropAgendaItem, index, refetchAgendaItems, eventId) => {
    // Attributes
    const agendaItemConfirmationDeleteDisclosure = useDisclosure();
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const { me } = useCompaniesContext();
    const { mutateAsync: createRepositoryDocument, isLoading: isCreateDocumentLoading, } = useCreateDocument();
    const queryClient = useQueryClient();
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const defaultDocumentType = documentTypes?.find(documentType => documentType.name === '_FT_BOARDAPPE')?.id;
    const [isDragFileActive, setDragFileActive] = useState(false);
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'agendaItem',
        item: {
            agendaItemId: agendaItem.id,
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        isDragging: monitor => agendaItem.id === monitor.getItem().agendaItemId,
    });
    const [{ isOverInAgendaItem }, dropInAgendaItem] = useDrop({
        accept: 'agendaItem',
        collect: monitor => ({
            isOverInAgendaItem: monitor.isOver({ shallow: true }),
        }),
        drop: (item, monitor) => {
            if (!monitor.isOver({ shallow: true }))
                return;
            void handleDropAgendaItem(item.agendaItemId, agendaItem.id, 0, true);
        },
    });
    const [{ isOverAfterAgendaItem }, dropAfterAgendaItem] = useDrop({
        accept: 'agendaItem',
        collect: monitor => ({
            isOverAfterAgendaItem: monitor.isOver({ shallow: true }),
        }),
        drop: (item, monitor) => {
            if (!monitor.isOver({ shallow: true }))
                return;
            void handleDropAgendaItem(item.agendaItemId, agendaItem.agendaItemParentId, index);
        },
    });
    // Queries
    const { mutateAsync: deleteAgendaItem, isLoading: isDeleteLoading } = useDeleteAgendaItem();
    // Functions
    async function handleAgendaItemDelete(id) {
        await deleteAgendaItem({ agendaItemId: id });
        agendaItemConfirmationDeleteDisclosure.onClose();
        refetchAgendaItems();
    }
    function redirectToBoardPack(agendaItemId, agendaItemName) {
        push('/company/boardpack', {
            companyId: selectedCompanyId,
            eventId,
            agendaItemId,
            agendaItemName,
        });
    }
    async function handleCreateNewFile(file) {
        if (!file)
            return;
        try {
            await createRepositoryDocument({
                data: {
                    typeId: defaultDocumentType,
                    companyId: selectedCompanyId,
                    creatorId: me?.id,
                    description: '',
                    file: {
                        content: file?.base64,
                        mimeType: file?.type,
                        filename: file?.name,
                    },
                    title: file?.name,
                    eventId: eventId,
                    agendaItemId: agendaItem.id,
                },
            }, {
                onError: () => {
                    Toast({
                        status: 'error',
                        title: t(tKeys.validation.document.upload.error),
                    });
                },
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                        'event-id': eventId,
                        'document-types': documentTypes?.map(d => d?.id),
                        'company-id': selectedCompanyId,
                    }));
                    refetchAgendaItems();
                    setDragFileActive(false);
                    Toast({
                        status: 'success',
                        title: t(tKeys.validation.document.upload.success),
                    });
                },
            });
        }
        catch (err) {
            console.error('Error', err);
        }
    }
    const handleOnDropFiles = async (acceptedFiles) => {
        const readFileAsBase64 = async (file) => {
            return await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        };
        const filesWithBase64 = await Promise.all(acceptedFiles.map(async (file, index) => {
            const base64 = await readFileAsBase64(file);
            return {
                path: acceptedFiles[index].path,
                lastModified: acceptedFiles[index].lastModified,
                lastModifiedDate: acceptedFiles[index].lastModifiedDate,
                name: acceptedFiles[index].name,
                size: acceptedFiles[index].size,
                type: acceptedFiles[index].type,
                webkitRelativePath: acceptedFiles[index].webkitRelativePath,
                base64,
            };
        }));
        await Promise.all(filesWithBase64.map(async (file) => await handleCreateNewFile(file)));
    };
    // return
    return {
        isOverAfterAgendaItem,
        isOverInAgendaItem,
        isDragging,
        drag,
        preview,
        dropAfterAgendaItem,
        dropInAgendaItem,
        isDeleteLoading,
        handleAgendaItemDelete,
        agendaItemConfirmationDeleteDisclosure,
        redirectToBoardPack,
        handleOnDropFiles,
        setDragFileActive,
        isDragFileActive,
        isCreateDocumentLoading,
    };
};
