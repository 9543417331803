import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import SearchInput from '../../components/inputs/search-input';
import LoadingWrapper from '../../components/utility/loading-wrapper';
import Placeholder from '../../components/utility/placeholder';
import COLORS from '../../constants/colors';
import { tKeys } from '../../translations/keys';
import SearchListHeader from './components/content/search-list-header';
import SearchResultCard from './components/content/search-result-card';
import useLogic from './logic.web';
import styles from './style';
import { Button, Divider, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Stack, useOutsideClick, } from '@chakra-ui/react';
import SHADOWS from '../../constants/shadows';
import PostDetails from '../post-details/index.web';
const Search = () => {
    // Attributes
    const { t } = useTranslation();
    const { search, handleSearch, activeFilters, resultsTotal, onFilterPress, searchResult, isLoading, total, onSearchResultPress, isOpenPopover, handleOnClickInput, isOpenPostDetails, onClosePostDetails, postId, handleOnClosePopover, popoverRef, } = useLogic();
    useOutsideClick({
        ref: popoverRef,
        handler: () => setTimeout(() => isOpenPopover && handleOnClosePopover(), 100), // Put a timeout resolve the close of the popover when click on the button
    });
    // Render Item
    const renderItem = ({ item }) => {
        return (<SearchResultCard searchResult={item} 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={async () => await onSearchResultPress(item)}/>);
    };
    const renderListHeader = () => {
        if (search?.length > 2)
            return (<Stack p={0}>
          <SearchListHeader total={total} activeFilters={activeFilters} resultsTotal={resultsTotal} onFilterPress={onFilterPress} search={search}/>
          <Divider />
        </Stack>);
    };
    // Render ListEmptyComponent
    const renderPlaceholder = () => {
        if (search?.length < 3) {
            return (<Placeholder imageType="monocle-emoji" title={t(tKeys.search.placeholder.no_results.title)} subTitle={t(tKeys.search.placeholder.no_results.subtitle)} maxWidth={320}/>);
        }
        if (!isLoading && total === 0)
            return (<Placeholder imageType="think-emoji" title={t(tKeys.search.placeholder.no_search_terms.title)} subTitle={t(tKeys.search.placeholder.no_search_terms.subtitle)} maxWidth={210}/>);
    };
    // Render
    return (<>
      <Popover placement="bottom-end" isOpen={isOpenPopover} autoFocus={false} onClose={handleOnClosePopover} closeOnBlur>
        <PopoverTrigger>
          <Button variant={'ghost'} _hover={{ backgroundColor: 'transparent' }} onClick={handleOnClickInput} isDisabled={isOpenPopover} _disabled={{ opacity: 1 }} px={0}>
            <SearchInput name="searchGlobal" value={search} onChange={handleSearch} placeholder={t(tKeys.search.global.placeholder)} style={{ height: 40 }}/>
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width={'fit-content'} borderColor={COLORS.Line.value} boxShadow={SHADOWS.Sidebar.value} overflow={'hidden'} ref={popoverRef}>
            <PopoverBody>
              <FlatList data={null} renderItem={null} style={{
            paddingVertical: '1rem',
            maxHeight: '40rem',
            maxWidth: '35rem',
            width: '35rem',
        }} ListEmptyComponent={renderPlaceholder} ListHeaderComponent={renderListHeader} ListFooterComponent={<LoadingWrapper isLoading={isLoading && search?.length > 2}>
                    <FlatList data={searchResult} style={{ paddingVertical: '.5rem' }} keyExtractor={(_, index) => `searchResult${index}`} renderItem={renderItem} contentContainerStyle={styles.contentContainer}/>
                  </LoadingWrapper>}/>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {/* Modals */}
      <PostDetails isOpen={isOpenPostDetails} onClose={onClosePostDetails} postId={postId}/>
    </>);
};
export default Search;
