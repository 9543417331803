import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../../constants/colors';
const styles = StyleSheet.create({
    tag: {
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderRadius: 6,
        height: 20,
    },
    iconContainer: {
        width: 36,
        height: 36,
        borderRadius: 8,
        backgroundColor: COLORS.DarkGrey.value,
    },
});
export default styles;
