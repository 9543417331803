import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import FormControl from '../../../../components/inputs/form-control';
import { Size } from '../../../../components/utility/loading/size';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import TagField from '../../calendar/components/inputs/tag-field';
import useLogic from './logic.web';
import FilterCalendar from '../../../../components/pickers/filter-calendar';
import Modal from '../../../../components/surfaces/modal/index.web';
import TagFieldItem from '../../calendar/components/inputs/tag-field/tag-item/index.web';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import { Box, HStack, StackDivider, VStack } from '@chakra-ui/react';
import AvatarDetails from '../../../../components/content/avatar-details';
import Input from '../../../../components/inputs/input/index.web';
const CreateTask = ({ isOpen, onClose, eventId, inviteesIds, }) => {
    // Attributes
    const { form, handleOnSubmit, isCreateTaskLoading, peopleInTheCompany, isOpenSelectPeople, onToggleSelectPeople, disableTaskCreation, } = useLogic(onClose, eventId, inviteesIds);
    const { t } = useTranslation();
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.tasks.create_task.new_task)} hasCloseButton actionButton={<Button size={Size.DEFAULT} kind={Kind.SECONDARY} onPress={handleOnSubmit} isLoading={isCreateTaskLoading} isDisabled={disableTaskCreation}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color={disableTaskCreation
                ? COLORS.Text.T300.value
                : COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
            {t(tKeys.common.create)}
          </Text>
        </Button>}>
      <VStack alignItems={'flex-start'} width={'full'} divider={<StackDivider />}>
        {/* Title Input */}
        <VStack p={'1rem'} width={'full'}>
          <FormControl label={t(tKeys.common.title)} isMandatory error={form?.title?.meta?.error} spacing={2}>
            <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder)} autoFocus/>
          </FormControl>
        </VStack>

        <Box width={'full'} px={'1rem'} mb={'1.25rem'}>
          <FilterCalendar {...form?.date} name={'toDateCalendar'} title={`${t(tKeys.common.due_date)}`} isValueTouched={form?.date?.meta?.touched}/>
        </Box>

        <TagField endEnhancer={<PopOver isPortal={false} from={<TagFieldItem value={form?.assigneePerson?.value?.email ??
                    t(tKeys.tasks.create_task.assign_to.assigned_members)}/>} isOpen={isOpenSelectPeople} onToggle={onToggleSelectPeople}>
              <VStack alignItems={'flex-start'} width={'full'} px={'1.75rem'} py={'1.25rem'} spacing={'1rem'} maxHeight={'12rem'} overflow={'auto'} zIndex={1}>
                {!!peopleInTheCompany &&
                peopleInTheCompany.map(person => (<HStack justify="space-between" key={person.id} width={'full'} onClick={() => {
                        form?.assigneePerson?.onChange(person);
                        onToggleSelectPeople();
                    }} cursor={'pointer'}>
                      <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
                    </HStack>))}
              </VStack>
            </PopOver>} title={`${t(tKeys.taskDetail.taskCard.assignTo)} `} startEnhancer={<Icon name="invitees" width={32} height={32}/>} error={form?.assigneePerson?.meta?.error}/>

        <HStack p={'1rem'} spacing={'.75rem'} width={'full'}>
          <Icon name="sections" width={32} height={32}/>
          <FormControl isMandatory error={form?.description?.meta?.error}>
            <Input value={form?.description.value} onChange={e => {
            form?.description.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.description.placeholder)}/>
          </FormControl>
        </HStack>
      </VStack>
    </Modal>);
};
export default CreateTask;
