import FONTS from '../../../../../constants/fonts';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Size = {
    DEFAULT: 'DEFAULT',
    COMPACT: 'COMPACT',
};
const SIZES = {
    [Size.DEFAULT]: {
        font: FONTS.T1.T12Px.SemiBold600.value,
    },
    [Size.COMPACT]: {
        font: FONTS.T1.T14Px.SemiBold600.value,
    },
};
export default SIZES;
