import React from 'react';
import PageTitle from '../../../../components/surfaces/page-title';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../translations/keys';
import { PageWebAuth } from '../../../../components/surfaces/page-auth/index.web';
import { VStack } from '@chakra-ui/react';
import FONTS from '../../../../constants/fonts';
import COLORS from '../../../../constants/colors';
import Text from '../../../../components/content/text';
const SignUpInvitationExpired = () => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<PageWebAuth>
      <VStack width={'full'} alignItems={'flex-start'} spacing={'1rem'} height={'30rem'} px={'2.5rem'} pb={'2.5rem'} pt={'2rem'}>
        {/*  Page title */}
        <PageTitle title={'Invitation expired'}/>

        {/* Page text */}
        <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T500.value} numberOfLines={2}>
          {t(tKeys['sign-up']['invitation-expired'])}
        </Text>
      </VStack>
    </PageWebAuth>);
};
export default SignUpInvitationExpired;
