import { useTranslation } from 'react-i18next';
import { Role } from '../../services/types';
import { tKeys } from '../../translations/keys';
const useLogic = (memberRole) => {
    // Attributes
    const { t } = useTranslation();
    const userRoles = [
        {
            label: t(tKeys.common.board_admin),
            value: Role[0],
        },
        {
            label: t(tKeys.common.board_member),
            value: Role[1],
        },
        {
            label: t(tKeys.common.board_observer),
            value: Role[2],
        },
    ];
    // Return
    return {
        userRoles,
    };
};
export default useLogic;
