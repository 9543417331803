import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useMemo, useState, } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { validate as UUIDValidate } from 'uuid';
import Container from '../../components/surfaces/container';
import LoadingAnimation from '../../components/utility/loading';
import useRouter from '../../hooks/router/useRouter';
import { countNews, useReadModules, } from '../../services/online/companies/companies';
import { useSwitchEnrolment } from '../../services/online/enrolments/enrolments';
import { useReadMe } from '../../services/online/people/people';
import handleMomentLanguage from '../../utils/handleMomentLanguage';
import { getApplicationLanguageFromStorage, saveApplicationLanguageInStorage, } from '../../utils/handleStorageApplicationLanguage';
import { exceptionsRoutes } from '../../components/utils/tokenStatus';
//  Create hook for use context
const CompaniesContext = createContext({
    selectedCompanyId: '',
    selectedEnrolment: undefined,
    allUserCompanies: [],
    updateCurrentEnrolment: undefined,
    updateCurrentCompany: undefined,
    isCurrentUserLoading: false,
    modules: [],
    companyNews: undefined,
    refetchMe: undefined,
});
export const useCompaniesContext = () => {
    return useContext(CompaniesContext);
};
const CompaniesContextProvider = ({ children, }) => {
    // Queries
    const queryConfig = useMemo(() => ({ retry: 0, staleTime: 5 * 60 * 1000, cacheTime: 5 * 60 * 1000 }), []);
    const { data: currentUserData, isLoading: isCurrentUserLoading, refetch: refetchCurrentUser, } = useReadMe({
        query: queryConfig,
    });
    const { mutateAsync: switchEnrolment } = useSwitchEnrolment();
    // Attributes
    const { i18n: { changeLanguage }, } = useTranslation();
    const { push, getCurrent } = useRouter();
    const queryClient = useQueryClient();
    const [selectedEnrollmentId, setSelectedEnrollmentId] = useState();
    const [isFromUrl, setIsFromUrl] = useState(false);
    const [companyNews, setCompanyNews] = useState();
    const allUserEnrolments = useMemo(() => {
        if (isCurrentUserLoading)
            return null;
        return currentUserData?.enrolments ?? [];
    }, [currentUserData]);
    const me = useMemo(() => {
        if (isCurrentUserLoading)
            return null;
        return currentUserData?.person ?? null;
    }, [currentUserData]);
    const allUserCompanies = useMemo(() => {
        if (isCurrentUserLoading)
            return null;
        return allUserEnrolments.map(item => item?.company) ?? [];
    }, [allUserEnrolments]);
    const selectedCompanyId = useMemo(() => {
        if (isCurrentUserLoading)
            return null;
        return allUserEnrolments?.find(enrolment => enrolment.id === selectedEnrollmentId)?.companyId;
    }, [selectedEnrollmentId, allUserEnrolments]);
    // Query
    const { data: modules, isLoading: isModulesLoading } = useReadModules(selectedCompanyId, { query: { enabled: !!selectedCompanyId } });
    const [isPublicPage, setIsPublicPage] = useState(exceptionsRoutes.includes(getCurrent()) || getCurrent() == undefined);
    // Functions
    const updateCurrentEnrolment = (enrolmentId) => {
        const enrolment = allUserEnrolments?.find(enrolment => enrolment?.id === enrolmentId);
        setSelectedEnrollmentId(enrolmentId);
    };
    const updateCurrentCompany = (companyId) => {
        const enrolment = allUserEnrolments?.find(enrolment => enrolment?.companyId === companyId);
        setSelectedEnrollmentId(enrolment?.id);
    };
    async function getCompanyNews(companyId, personId) {
        if (!companyId || !personId)
            return;
        setCompanyNews(undefined);
        const news = await countNews(companyId, {
            'person-id': personId,
        });
        setCompanyNews(news);
        // void queryClient.invalidateQueries(['companyNews', companyId]);
    }
    async function refetchMe(path) {
        await refetchCurrentUser();
        push(path);
    }
    useEffect(() => {
        const switchEnrollmentAsync = async () => {
            if (!selectedEnrollmentId)
                return;
            try {
                // Switch enrolment
                await switchEnrolment({
                    enrolmentId: selectedEnrollmentId,
                });
                // Fetch company news after enrolment switch
                // Refetch the current user data if needed
                // await refetchCurrentUser();
                await getCompanyNews(selectedCompanyId, me?.id);
                // Update state if needed
                setIsFromUrl(true);
            }
            catch (error) {
                console.error('Error during enrolment switch:', error);
            }
        };
        // Call the async function
        switchEnrollmentAsync();
        // Make sure to include all dependencies in the array
    }, [selectedEnrollmentId]);
    // Effects
    useEffect(() => {
        if (!allUserEnrolments)
            return;
        if (selectedEnrollmentId)
            return;
        if (!allUserEnrolments.length)
            return;
        // Fetch from url
        const path = window?.location?.pathname;
        const splittedPath = path?.split('/');
        const selectedOrganisationId = splittedPath?.[2];
        if (isMobile) {
            const params = window?.location?.search;
            window.location = `be.govrn.app:///${splittedPath
                .slice(1)
                .join('/')}${params}`;
        }
        if (UUIDValidate(selectedOrganisationId)) {
            setIsFromUrl(true);
            const findedEnrolments = allUserEnrolments.find(enrolment => enrolment?.companyId === selectedOrganisationId);
            setSelectedEnrollmentId(findedEnrolments?.id);
            void getCompanyNews(findedEnrolments?.companyId, findedEnrolments?.personId);
            return;
        }
        const findedEnrolments = allUserEnrolments.find(enrolment => enrolment?.isLastUsed);
        setSelectedEnrollmentId(findedEnrolments ? findedEnrolments.id : allUserEnrolments[0].id);
        void getCompanyNews(allUserEnrolments[0].companyId, allUserEnrolments[0].personId);
    }, [allUserEnrolments]);
    useEffect(() => {
        // While loading, we don't do anything
        if (isCurrentUserLoading)
            return;
        getApplicationLanguageFromStorage()
            .then(language => {
            if (!currentUserData) {
                void changeLanguage(language ?? 'en');
            }
            else {
                const languageUser = currentUserData?.person?.cultureName?.split('-')?.[0];
                if (languageUser !== language) {
                    void handleMomentLanguage(language);
                    void changeLanguage(language);
                    void saveApplicationLanguageInStorage({
                        language,
                    });
                }
            }
        })
            .catch(err => console.error('Error when updating language : ', err));
    }, [currentUserData]);
    useEffect(() => {
        if (isFromUrl)
            return setIsFromUrl(false);
        if (selectedCompanyId) {
            push('/company/home/default', {
                companyId: selectedCompanyId,
            });
        }
    }, [selectedCompanyId]);
    // Render
    return (<CompaniesContext.Provider value={{
            selectedCompanyId,
            selectedEnrolment: allUserEnrolments?.find(enrolment => enrolment?.id === selectedEnrollmentId),
            allUserCompanies,
            updateCurrentCompany,
            updateCurrentEnrolment,
            isCurrentUserLoading,
            // isModulesLoading,
            modules,
            companyNews,
            me,
            refetchMe,
        }}>
      {isCurrentUserLoading || (!isPublicPage && isModulesLoading) ? (<Container style={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
          <LoadingAnimation />
        </Container>) : (children)}
    </CompaniesContext.Provider>);
};
export default CompaniesContextProvider;
