import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    header: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.Stroke.value,
        paddingBottom: 16,
        marginBottom: 16,
        width: '100%',
        paddingHorizontal: 24,
        alignContent: 'center',
    },
    handleBar: {
        width: 32,
        height: 4,
        borderRadius: 100,
    },
    iconContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
export default styles;
