import moment from 'moment';
import { useEffect, useState } from 'react';
import { PeriodValues } from '../../../feed/filter/types';
const useLogic = (form) => {
    // States
    const [isAllFileTypesSelected, setAllFileTypesSelected] = useState();
    const [disabledButtons, setDisabledButton] = useState();
    // Functions
    function onPressFromDateButton(fromDate) {
        // Reset the period
        form?.period?.onChange(null);
        form?.fromDate?.onChange(fromDate);
    }
    function onPressToDateButton(toDate) {
        // Reset the period
        form?.period?.onChange(null);
        form?.toDate?.onChange(toDate);
    }
    function onPressPeriodTimeButton(period) {
        if (form?.period?.value === period) {
            form?.period?.onChange(undefined);
            return;
        }
        form?.fromDate?.onChange(PeriodValues[period]?.fromDate);
        form?.toDate?.onChange(PeriodValues[period]?.toDate);
        form?.period?.onChange(period);
    }
    function onSelectAllFileTypes() {
        // Unselect or select all file types
        const tmpFileTypes = [
            ...form?.selectedFileTypes.value,
        ];
        // eslint-disable-next-line array-callback-return
        tmpFileTypes?.map(tmpFileType => {
            tmpFileType.isSelected = isAllFileTypesSelected;
        });
        form?.selectedFileTypes?.onChange(tmpFileTypes);
        setAllFileTypesSelected(prev => !prev);
    }
    // To handle when a switch button is pressed.
    // We need to do it this way because the Back-End don't send us enought informations about which filter are selected or not.
    function handleOnChangeSwitchButton(id) {
        const tmpFileTypes = [
            ...form?.selectedFileTypes.value,
        ];
        // eslint-disable-next-line array-callback-return
        tmpFileTypes?.map(tmpFileType => {
            if (tmpFileType?.id === id) {
                tmpFileType.isSelected = !tmpFileType?.isSelected;
            }
        });
        form?.selectedFileTypes?.onChange(tmpFileTypes);
    }
    // Effects
    // To disabled FROM and TO button if the values are wrong
    useEffect(() => {
        setDisabledButton(moment(form?.fromDate?.value)?.isAfter(form?.toDate?.value));
    }, [form?.fromDate?.value]);
    useEffect(() => {
        setDisabledButton(moment(form?.toDate?.value)?.isBefore(form?.fromDate?.value));
    }, [form?.toDate?.value]);
    // Return
    return {
        form,
        onPressFromDateButton,
        onPressToDateButton,
        onPressPeriodTimeButton,
        onSelectAllFileTypes,
        isAllFileTypesSelected,
        disabledButtons,
        handleOnChangeSwitchButton,
    };
};
export default useLogic;
