import React from 'react';
import Text from '../../../../../../components/content/text';
import MenuItem from '../../../../../../components/pickers/menu/menu-item/index.web';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import styles from './style';
import TagFieldItem from './tag-item';
import { Box, VStack } from '@chakra-ui/react';
import Icon from '../../../../../../components/content/icon';
const TagField = ({ title, startEnhancer, endEnhancer = (<Icon name="chevron-right" width={20} height={20} fill={'transparent'}/>), selectedValue, isMandatory, error, titleEnhancer, style, }) => {
    // Return
    return (<VStack spacing={'1rem'} alignItems={'flex-start'} px={style?.px ?? '1rem'} py={style?.py ?? '1rem'} width={'full'}>
      <MenuItem title={title} titleEnhancer={titleEnhancer} startEnhancer={startEnhancer} endEnhancer={endEnhancer} isMandatory={isMandatory} endEnhancerStyle={styles.endEnhancer} startEnhancerStyle={styles.startEnhancer}/>

      {selectedValue && (<Box>
          <TagFieldItem value={selectedValue}/>
        </Box>)}

      {error ? (<Text color={error ? COLORS.Error.T500.value : COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value}>
          {error}
        </Text>) : null}
    </VStack>);
};
export default TagField;
