import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/content/icon';
import Text from '../../../../../components/content/text';
import FormControl from '../../../../../components/inputs/form-control';
import FilterDateAndHours from '../../../../../components/pickers/filter-hours';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import TagField from '../../components/inputs/tag-field';
import styles from './style.web';
import moment from 'moment';
import { HStack, StackDivider, VStack, useDisclosure } from '@chakra-ui/react';
import TagFieldItem from '../../components/inputs/tag-field/tag-item';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import SelectTimezone from '../../../../select-time-zone/index.web';
import SelectPersonGroupSection from '../../components/content/select-multiple-people-groups/index.web';
import SelectEventType from '../../../../select-event-type/index.web';
import Input from '../../../../../components/inputs/input/index.web';
const CreateEventBody = ({ filterDateOpen, form, setFilterDateOpen, values, onChangeSearchInvitees, searchInvitees, peopleInTheCompany, handleOnSelectInvitee, isInviteeSelected, groupsInTheCompany, isGroupSelected, onGroupSelect, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isOpen: isOpenTimeZone, onToggle: onToggleTimeZone } = useDisclosure();
    const { isOpen: isOpenEventType, onToggle: onToggleEventType } = useDisclosure();
    // Function
    function handleOnChangeTimeZone(timezoneId) {
        form?.timezoneId?.onChange?.(timezoneId);
        onToggleTimeZone();
    }
    function setNewStartDate(newDate) {
        form?.startDate?.onChange(newDate);
        const startDatePlusTwoHours = new Date(moment(newDate)
            .hours(newDate?.getHours() + 2)
            .toISOString());
        form?.endDate?.onChange(startDatePlusTwoHours);
    }
    // Return
    return (<VStack style={styles.mainContainer} overflow={'auto'} width={'full'} alignItems={'flex-start'} divider={<StackDivider borderColor={COLORS.Line.value}/>} spacing={0}>
      {/* Title and Start Date + End Date */}
      <VStack p={'1rem'} width={'full'}>
        <FormControl label={t(tKeys.common.title)} isMandatory error={form?.title?.meta?.error} spacing={2}>
          <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder)} autoFocus/>
        </FormControl>
      </VStack>

      <VStack alignItems={'flex-start'} width={'full'} p={'1rem'}>
        <HStack>
          <Icon name="calendar-blue" width={32} height={32}/>
          <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Text.T400.value}>
            {`${t(tKeys.calendar.createEvent.eventDates.title)} *`}
          </Text>
        </HStack>
        <VStack alignItems={'flex-start'} width={'full'} spacing={'0.75rem'}>
          <FilterDateAndHours isTimeEnabled={true} {...form?.startDate} title={t(tKeys.common.start)} dateTitleFont={FONTS.T1.T12Px.Medium500.value} onOpen={() => setFilterDateOpen('start')} close={filterDateOpen === 'end'} error={form?.startDate?.meta?.error} onChange={newDate => setNewStartDate(newDate)} placeholderEnabled={true}/>
          <FilterDateAndHours {...form?.endDate} isTimeEnabled={true} title={t(tKeys.common.end)} dateTitleFont={FONTS.T1.T12Px.Medium500.value} onOpen={() => setFilterDateOpen('end')} close={filterDateOpen === 'start'} error={form?.endDate?.meta?.error} placeholderEnabled={true} minDate={values?.startDate}/>
        </VStack>
      </VStack>

      {/* TimeZone */}
      <TagField title={t(tKeys.calendar.createEvent.timeZone.title)} startEnhancer={<Icon name="clock"/>} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={values?.timezoneId}/>} isPortal={false} isOpen={isOpenTimeZone} onToggle={onToggleTimeZone}>
            <SelectTimezone timezoneId={values?.timezoneId} onChangeTimezoneId={handleOnChangeTimeZone}/>
          </PopOver>} isMandatory error={form?.timezoneId?.meta?.error}/>

      {/* Location */}
      <HStack p={'1rem'} width={'full'} align="center" spacing={'0.75rem'}>
        <Icon name="map-pin" width={32} height={32}/>

        <FormControl error={form?.location?.meta?.error} spacing={0}>
          <Input value={form?.location.value} onChange={e => {
            form?.location.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.calendar.createEvent.location.placeholder)}/>
        </FormControl>
      </HStack>

      {/* Link */}
      <HStack p={'1rem'} width={'full'} align="center" spacing={'0.75rem'}>
        <Icon name="clip-blue" width={32} height={32}/>

        <FormControl error={form?.url?.meta?.error} spacing={0}>
          <Input value={form?.url.value} onChange={e => {
            form?.url.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.calendar.createEvent.locationUrl.placeholder)}/>
        </FormControl>
      </HStack>

      {/* Event Type */}
      <TagField title={t(tKeys.calendar.createEvent.eventType.title)} startEnhancer={<Icon name="layers"/>} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(values?.eventType?.name)}/>} isPortal={false} isOpen={isOpenEventType} onToggle={onToggleEventType}>
            <SelectEventType eventType={values.eventType} onChange={form?.eventType.onChange} onToggle={onToggleEventType}/>
          </PopOver>} isMandatory error={form?.eventType?.meta?.error}/>

      {/* Invitees */}
      <SelectPersonGroupSection title={t(tKeys.calendar.createEvent.invitees.title)} onPersonSelect={handleOnSelectInvitee} peopleInCompany={peopleInTheCompany} groupsInCompany={groupsInTheCompany} fieldError={form?.inviteesIds?.meta?.error} onChangeSearchValue={onChangeSearchInvitees} searchValue={searchInvitees} isPersonSelected={isInviteeSelected} isGroupSelected={isGroupSelected} onGroupSelect={onGroupSelect}/>
    </VStack>);
};
export default CreateEventBody;
