/**
 * Check if a channel exists with the given participants and group status
 * @param personIds array of person ids who are participants in the channel
 * @param isGroup a boolean indicating if the channel is a group channel
 * @param currentUserId the id of the current user
 * @returns the channel object if it exists, otherwise undefined
 */
function isChannelExisiting(channels, personIds, isGroup, currentUserId) {
    // Create an array of all participants in the channel
    const allParticipants = [...personIds, currentUserId];
    // Filter channels to only those that match the group status and have all the participants
    return channels
        ?.filter(channel => channel.isGroup === isGroup)
        ?.find(channel => channel.participants?.every(participant => allParticipants.includes(participant.personId)));
}
export default isChannelExisiting;
