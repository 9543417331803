import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import { PresenceStatus } from '../../../event-details/types';
const EventDetailsUserStatus = ({ presenceStatus, }) => {
    // Attributes
    const { t } = useTranslation();
    function eventInformation() {
        switch (presenceStatus) {
            case PresenceStatus.Present:
                return {
                    color: COLORS.Success.T500.value,
                    iconName: 'check',
                    title: t(tKeys.home.calendar.event_details.information.presence.status
                        .present),
                };
            case PresenceStatus.Absent:
                return {
                    color: COLORS.Error.T500.value,
                    iconName: 'close-cross',
                    title: t(tKeys.home.calendar.event_details.information.presence.status.absent),
                };
            case PresenceStatus.Pending:
                return {
                    color: COLORS.InputText.value,
                    iconName: 'event-status-pending',
                    title: t(tKeys.home.calendar.event_details.information.presence.status
                        .pending),
                };
            case PresenceStatus.Proxy:
                return {
                    color: COLORS.Warning.T500.value,
                    iconName: 'proxy',
                    isProxy: true,
                };
            case PresenceStatus.Remote:
                return {
                    color: COLORS.Warning.T500.value,
                    iconName: 'remote',
                    title: t(tKeys.home.calendar.event_details.information.presence.status.remote),
                };
        }
    }
    const { color, iconName, isProxy, title } = useMemo(() => eventInformation(), [presenceStatus]);
    // Render
    return (<Flex justify="space-between" spacing={8} align="center">
      {/* Status icon */}
      <Icon name={iconName} width={16} height={16} color={color} stroke={color} strokeWidth={0.3}/>

      {/* Status name */}
      {!isProxy ? (<Text color={color} font={FONTS.T1.T12Px.Medium500.value}>
          {title}
        </Text>) : null}
    </Flex>);
};
export default EventDetailsUserStatus;
