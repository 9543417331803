import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.BGFullscreen.value,
        flexGrow: 1,
        flex: 1,
    },
    navigationHeader: {
        alignItems: 'center',
        borderBottomWidth: 0,
    },
    companyLogoContainer: {
        borderWidth: 1,
        borderColor: COLORS.Line.value,
        borderRadius: 8,
        width: 32,
        height: 32,
        overflow: 'hidden',
        marginRight: 12,
        justifyContent: 'center',
    },
    list: {
        padding: 16,
        height: ' 100%',
    },
    deleteButtonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: COLORS.White.T500.value,
    },
    sideNavigationHelpdesk: {
        marginTop: 12,
        marginLeft: 14,
        alignContent: 'center',
        alignItems: 'center',
    },
});
export default styles;
