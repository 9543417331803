import { PresenceStatus } from '../screens/home/calendar/event-details/types';
function getStatusFromInvitee(personId, isProxy, presentAttendeeIds, absentAttendeeIds, remoteAttendeeIds, invitee) {
    if (isProxy)
        return PresenceStatus?.Proxy;
    if (presentAttendeeIds?.includes(personId) || invitee?.acceptedOn)
        return PresenceStatus.Present;
    if (absentAttendeeIds?.includes(personId) || invitee?.refusedOn)
        return PresenceStatus.Absent;
    if (remoteAttendeeIds?.includes(personId) || invitee.isRemote)
        return PresenceStatus.Remote;
    return PresenceStatus?.Pending;
}
export default getStatusFromInvitee;
