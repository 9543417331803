import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
import CUSTOM_SHADOWS from '../../../../utils/shadows';
const styles = StyleSheet.create({
    mainContent: {
        backgroundColor: COLORS.White.T500.value,
        borderRadius: 12,
        ...CUSTOM_SHADOWS.FormControlContainer,
        padding: 16,
        minHeight: 76,
    },
    imageContainer: {
        width: 44,
        height: 44,
    },
});
export default styles;
