/* eslint-disable @typescript-eslint/promise-function-async */
import { HttpTransportType, HubConnectionBuilder, LogLevel, } from '@microsoft/signalr';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-native';
import Config from 'react-native-config';
import { useFilePicker } from 'use-file-picker';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { DocumentTypes } from '../../../../components/surfaces/document-modal/props';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { getReadChannelsQueryKey, useCreateChannel, useCreateMessage, useDeleteMessage, useMarkChannelAsRead, useReadMessages, } from '../../../../services/online/chat/chat';
import { getCountNewsQueryKey } from '../../../../services/online/companies/companies';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getUserName from '../../../../utils/getUserName';
import handleSelectDocument from '../../../../utils/handleSelectDocument';
import { getTokens } from '../../../../utils/handleTokens';
import { extractBase64FromDataUrl, onSelectedFileSuccess, } from '../../../../utils/selectFile.web';
import formatMessages from './utils';
import { validationSchema } from './validation';
const useLogic = ({ selectedChannel, }) => {
    // Attributes
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { goBack, params, push } = useRouter();
    const { selectedCompanyId, me } = useCompaniesContext();
    const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
    const [channelId, setChannelId] = useState();
    const [showScrollButton, setShowScrollButton] = useState(false);
    const listRef = useRef(null);
    const toast = useToast();
    const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
    const scrollToTopAnim = useRef(new Animated.Value(0)).current;
    const scrollToBottomAnim = useRef(new Animated.Value(0)).current;
    // If we receive the channel, means the channel already exists
    // if we receive the person, means the channel doesn't exist yet
    const { channel, person, backSteps } = params ?? {};
    const [selectedDocument, setSelectedDocument] = useState();
    // Effects
    useEffect(() => {
        if (selectedChannel?.id) {
            setChannelId(selectedChannel?.id);
            return;
        }
        if (channel)
            setChannelId(channel?.id);
    }, [channel, selectedChannel?.id]);
    useEffect(() => {
        return () => {
            scrollToTopAnim.removeAllListeners();
            scrollToBottomAnim.removeAllListeners();
            fadeAnim.removeAllListeners();
        };
    }, []);
    // SignalR connection hub
    const connectionHub = useMemo(() => {
        return new HubConnectionBuilder()
            .configureLogging(LogLevel.Error)
            .withUrl(`${Config.OLD_API_URL}/discussionHub?companyId=${selectedCompanyId}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: async () => {
                const { accessToken } = await getTokens();
                return accessToken;
            },
        })
            .withAutomaticReconnect()
            .build();
    }, []);
    // Queries
    const { mutateAsync: markChannelAsRead } = useMarkChannelAsRead();
    const { data: messages, refetch: refetchMessages, isLoading: isMessagesLoading, } = useReadMessages({ 'channel-id': channelId }, { query: { enabled: !!channelId } });
    const { data: companyEnrolements } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            enabled: !!selectedCompanyId,
        },
    });
    const { mutateAsync: createMessage, isLoading: isCreateMessageLoading } = useCreateMessage();
    const { mutateAsync: createChannel, isLoading: isCreateChannelLoading } = useCreateChannel();
    const { mutateAsync: deleteMessage, isLoading: isDeleteMessageLoading } = useDeleteMessage();
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: [
            'image/jpeg',
            'image/png',
            'image/gif',
            '.txt',
            '.pdf',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.csv',
        ],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile),
    });
    // Attribute
    const participantId = useMemo(() => {
        if (person)
            return person?.id;
        if (selectedChannel?.id) {
            return selectedChannel?.participants?.filter(p => p?.personId !== me?.id)?.[0]?.personId;
        }
        return channel?.participants?.filter(p => p?.personId !== me?.id)?.[0]
            ?.personId;
    }, [channel, person, me, selectedChannel?.id]);
    // Formik
    const { values, dirty, isValid, resetForm, errors, ...rest } = useFormik({
        initialValues: {
            message: '',
            isEphemeral: false,
            file: null,
            fileType: null,
            deletedMessageIds: [],
            isDeleteMode: false,
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema(t),
    });
    const form = createForm(values, { errors, ...rest });
    // Submit handler
    function handleSubmit() {
        // If there's no existing channel we create the channel first
        // Note: only for channels that are not groups (because group channels are already created before)
        if ((!channel || !selectedChannel?.id) && person?.id) {
            void createChannel({
                data: {
                    name: getUserName(person),
                    companyId: selectedCompanyId,
                    isGroup: false,
                    participantIds: [person?.id, me?.id],
                    creatorId: me?.id,
                },
            }, {
                onSuccess: channelId => {
                    // Then we create the message
                    handleSendMessage(channelId.value);
                },
            });
            return;
        }
        // Else we only create the message with the channelId coming from route params
        handleSendMessage(channelId);
    }
    // Functions
    function handleOnSelectFile(value) {
        setSelectedDocument(value);
    }
    async function handleChannelMarkedAsRead(channelId) {
        await markChannelAsRead({ channelId });
        await queryClient?.invalidateQueries(getCountNewsQueryKey(selectedCompanyId));
        await queryClient?.invalidateQueries(getReadChannelsQueryKey({ 'company-id': selectedCompanyId }));
    }
    function handleSendMessage(channelId) {
        void createMessage({
            data: {
                channelId,
                creatorId: me?.id,
                content: values?.message,
                isEphemeral: values?.isEphemeral,
                file: values?.file,
            },
        }, {
            onSuccess: () => {
                resetForm();
                setChannelId(channelId);
                void refetchMessages();
                void handleChannelMarkedAsRead(channelId);
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.validation.message.error),
                });
                console.error('Error when posting a message : ', err);
            },
        });
    }
    function onGoBack() {
        void queryClient.invalidateQueries(getReadChannelsQueryKey({ 'company-id': selectedCompanyId }));
        goBack(backSteps);
    }
    function onDeleteIconPress() {
        form.isDeleteMode.onChange(!values.isDeleteMode);
    }
    function onConfirmDeleteMessages() {
        void deleteMessage({ params: { 'message-id': values?.deletedMessageIds } }, {
            onSuccess: () => {
                void refetchMessages();
                form?.deletedMessageIds.onChange([]);
                form?.isDeleteMode.onChange(false);
            },
        });
    }
    function onAddFilePress() {
        openFilePicker();
        // Reset Delete Mode
        form.isDeleteMode.onChange(false);
    }
    async function handleOnSelectDocument(documentType) {
        try {
            const file = {};
            const resp = await handleSelectDocument(documentType);
            if (resp) {
                switch (documentType) {
                    case DocumentTypes.FILE:
                        file.content = resp?.data;
                        file.filename = resp?.documentMetaData?.name;
                        file.mimeType = resp?.documentMetaData?.type;
                        break;
                    case DocumentTypes.CAMERA:
                    case DocumentTypes.PICTURE_GALLERY:
                        file.content = resp?.data;
                        file.filename = resp?.filename;
                        file.mimeType = resp?.mime;
                }
                setDocumentModalOpen(false);
                form?.file?.onChange(file);
                form?.fileType?.onChange(documentType);
            }
        }
        catch (error) {
            toast({
                status: 'error',
                title: t(tKeys.validation.message.document.error),
            });
            console.error(error);
        }
    }
    function onEphemeralPress() {
        form.isEphemeral.onChange(!values.isEphemeral);
        // Reset Delete Mode
        form.isDeleteMode.onChange(false);
    }
    // Functions
    const formattedMessages = useMemo(() => {
        if (!messages) {
            return;
        }
        const data = formatMessages(isMessagesLoading, messages);
        const reverseData = data.reverse();
        const sorted = _.groupBy(reverseData, item => {
            let date;
            // eslint-disable-next-line array-callback-return
            item.map(i => {
                date = new Date(i?.createdOn);
            });
            return moment(date).format('DD MMMM YYYY');
        });
        // Return data;
        return Object.keys(sorted).map(date => ({
            title: date,
            data: sorted[date],
        }));
    }, [messages]);
    // Function
    function handleOnHeaderPress() {
        if (selectedChannel?.isGroup || channel?.isGroup) {
            const params = {
                chatSettingsEntity: { channelId: selectedChannel?.id ?? channel?.id },
            };
            push('/chat-settings', params);
            return;
        }
        push('/profile/other-user-profile', {
            personId: participantId,
        });
    }
    async function initSocket() {
        try {
            await connectionHub.start();
            connectionHub.on('readChannel', async () => {
                await refetchMessages();
            });
            connectionHub.on('addMessage', async () => {
                await refetchMessages();
            });
        }
        catch (err) {
            console.error('Error with connection hub Signal R : ', err);
        }
    }
    function scrollToTop() {
        if (listRef && formattedMessages) {
            listRef.current?.scrollToLocation({
                animated: true,
                sectionIndex: 0,
                itemIndex: 0,
            });
        }
    }
    // Effects
    // @ts-expect-error because
    useEffect(() => {
        void initSocket();
        return () => connectionHub.stop();
    }, [connectionHub]);
    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: showScrollButton ? 1 : 0,
            duration: 300,
            useNativeDriver: true,
        }).start();
        if (showScrollButton) {
            Animated.timing(scrollToTopAnim, {
                toValue: -25,
                duration: 500,
                useNativeDriver: true,
            }).start();
            scrollToBottomAnim.setValue(0);
            return;
        }
        Animated.timing(scrollToBottomAnim, {
            toValue: 15,
            duration: 200,
            useNativeDriver: true,
        }).start();
        scrollToTopAnim.setValue(0);
    }, [fadeAnim, showScrollButton]);
    useEffect(() => {
        if (!selectedDocument)
            return;
        const file = {};
        if (selectedDocument?.documentMetaData?.mime) {
            file.mimeType = selectedDocument?.documentMetaData?.mime;
            file.uri = '';
            const imageBase64 = extractBase64FromDataUrl(selectedDocument?.data);
            file.content = imageBase64;
            form?.fileType?.onChange(DocumentTypes.PICTURE_GALLERY);
        }
        else {
            file.content = selectedDocument?.data;
            file.mimeType = selectedDocument?.documentMetaData?.type;
            file.filename = selectedDocument?.documentMetaData?.name;
            form?.fileType?.onChange(DocumentTypes.FILE);
        }
        form?.file?.onChange(file);
    }, [selectedDocument]);
    // Return
    return {
        onGoBack,
        participantId,
        channel: selectedChannel?.id ? selectedChannel : channel,
        formattedMessages,
        companyEnrolements,
        me,
        form,
        isSendDisabled: !dirty || !isValid,
        isSendMessageLoading: isCreateChannelLoading || isCreateMessageLoading,
        isMessagesLoading: isMessagesLoading && !!channelId,
        onDeleteIconPress,
        onConfirmDeleteMessages,
        isDeleteMessageLoading,
        handleOnSelectDocument,
        onAddFilePress,
        isDocumentModalOpen,
        listRef,
        onEphemeralPress,
        handleOnHeaderPress,
        scrollToTop,
        showScrollButton,
        setShowScrollButton,
        fadeAnim,
        scrollToTopAnim,
        scrollToBottomAnim,
    };
};
export default useLogic;
