import React from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from '../../../../../../components/content/icon';
import Image from '../../../../../../components/content/image';
import Text from '../../../../../../components/content/text';
import { DocumentTypes } from '../../../../../../components/surfaces/document-modal/props';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import styles from './style';
import { Box } from '@chakra-ui/react';
const ChatboxFilePreview = ({ file, type }) => {
    // Attributes
    if (!file?.value) {
        return <></>;
    }
    if (type === DocumentTypes.CAMERA || type === DocumentTypes.PICTURE_GALLERY) {
        // Render
        return (<Box height={'3.75rem'} width={'3.75rem'} position={'relative'}>
        <Box position={'absolute'} top={'1'} right={'1'} onClick={() => file?.onChange(undefined)}>
          <Icon name="close-cross" width={14} height={14}/>
        </Box>
        <Image height={'3.75rem'} width={'3.75rem'} borderRadius={10} imageURI={`data:image/png;base64,${file?.value?.content}`} isLocalImage={false}/>
      </Box>);
    }
    if (type === DocumentTypes.FILE)
        // Render
        return (<Flex>
        <Flex style={styles.fileContainer} justify="space-between" align="center" flex={1} spacing={8}>
          <Flex spacing={8} align="center" flex={1} flexGrow={1}>
            <Flex style={styles.fileIconContainer}>
              <Icon name="files" width={14} height={14} fill={COLORS.InputText.value}/>
            </Flex>
            <Flex flex={1} flexGrow={1}>
              <Text font={FONTS.T1.T12Px.Medium500.value}>
                {file?.value?.filename}
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <TouchableOpacity activeOpacity={1} onPress={() => file?.onChange(undefined)} style={styles.crossButtonDark}>
              <Icon name="close-cross" width={14} height={14} stroke={COLORS.White.T500.value}/>
            </TouchableOpacity>
          </Flex>
        </Flex>
      </Flex>);
};
export default ChatboxFilePreview;
