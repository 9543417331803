import { t } from 'i18next';
import { AgendaItemType } from '../services/types';
import { tKeys } from '../translations/keys';
export function getAgendaItemTypeLabel(type) {
    switch (type) {
        case AgendaItemType[0]:
            return t(tKeys.common.informations);
        case AgendaItemType[1]:
            return t(tKeys.common.decision);
        case AgendaItemType[2]:
            return t(tKeys.common.discussion);
        default:
            return '';
    }
}
