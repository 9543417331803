import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../hooks/router/useRouter';
import { useReadKnowledgeBaseCultures, useReadTemplates, } from '../../../../services/online/knowledge-base/knowledge-base';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import { DocumentKindEnum, } from '../../../document-details/type';
import { ListLayoutEnum, } from './type.web';
import { useDisclosure } from '@chakra-ui/react';
import { useCompaniesContext } from '../../../../contexts/companies/index.web';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { isOpen: isOpenLanguage, onToggle: onToggleLanguage } = useDisclosure();
    const { selectedCompanyId } = useCompaniesContext();
    const { goBack, push } = useRouter();
    const optionsOrder = useMemo(() => {
        return [
            {
                label: t(tKeys.filter.order.alphabetically.asc),
                value: 2,
            },
            {
                label: t(tKeys.filter.order.alphabetically.desc),
                value: 3,
            },
        ];
    }, []);
    const [listLayoutType, setListLayoutType] = useState(ListLayoutEnum.GRID);
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            orderBy: optionsOrder[1],
            cultureName: 'en-BE',
        },
        onSubmit: undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Queries
    const { data, isLoading: isTemplatesLoading, refetch: refetchTemplates, } = useReadTemplates({
        culture: form.cultureName?.value,
        'sort-by': values?.orderBy?.value,
    });
    function onLayoutIconPress() {
        setListLayoutType(listLayoutType === ListLayoutEnum.GRID
            ? ListLayoutEnum.ROW
            : ListLayoutEnum.GRID);
    }
    function onTemplatePress(template) {
        const params = {
            documentKind: DocumentKindEnum.TEMPLATE,
            documentId: template.id,
            template,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...params,
        });
    }
    // Query
    const { data: cultures } = useReadKnowledgeBaseCultures();
    const languages = useMemo(() => {
        return cultures?.map(culture => ({ label: culture, value: culture })) ?? [];
    }, [cultures]);
    function onSelectLanguage(value) {
        form?.cultureName?.onChange?.(value);
        onToggleLanguage();
    }
    return {
        t,
        form,
        onGoBack: goBack,
        onSelectLanguage,
        languages,
        onLayoutIconPress,
        optionsOrder,
        listLayoutType,
        templates: data?.flatMap(data => data.items),
        isTemplatesLoading,
        filters: 1,
        onTemplatePress,
        refetchTemplates,
        isDropdownOpen,
        setDropdownOpen,
        isOpenLanguage,
        onToggleLanguage,
    };
};
export default useLogic;
