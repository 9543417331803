import React, { useEffect, useMemo } from 'react';
import Text from '../../components/content/text';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { useCompaniesContext } from '../../contexts/companies';
import { useReadEventTypes } from '../../services/online/calendar/calendar';
import getStaleTime from '../../utils/getStaleTime';
import { useTranslation } from 'react-i18next';
const useLogic = (onChange, eventType, onToggle) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { t } = useTranslation();
    // Queries
    const { data: eventTypes } = useReadEventTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    const options = useMemo(() => {
        if (!eventTypes) {
            return undefined;
        }
        return eventTypes?.map(eventType => {
            return {
                id: eventType?.id,
                children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
            {t(eventType?.name)}
          </Text>),
            };
        });
    }, [eventTypes]);
    // Functions
    function handleOnClick(eventTypeId) {
        const eventType = eventTypes?.find(eventType => eventType.id === eventTypeId);
        onChange(eventType);
        onToggle();
    }
    // Effects
    useEffect(() => {
        if (eventType)
            return;
        onChange(eventTypes?.[0]);
    }, []);
    // Return
    return {
        options,
        handleOnClick,
    };
};
export default useLogic;
