import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.White.T500.value,
        flexGrow: 1,
        paddingTop: 20,
        paddingHorizontal: 12,
    },
    navigationHeader: {
        alignItems: 'center',
        width: '100%',
    },
    buttonContainer: { width: 50 },
});
export default styles;
