import React from 'react';
import AvatarDetails from '../../../../components/content/avatar-details';
import FONTS from '../../../../constants/fonts';
import { HStack, Stack, Tooltip, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import { PresenceStatus } from '../event-details/types';
import { useLogic } from './logic.web';
import Tag from '../../../../components/content/tag/index.web';
import Text from '../../../../components/content/text';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import { EventDetailsInviteesPresenceTag } from './presence-tag/index.web';
import Avatar from '../../../../components/content/avatar';
import getUserName from '../../../../utils/getUserName';
import Icon from '../../../../components/content/icon';
const EventDetailsInformationsInvitees = ({ person, personId, proxyPersonId, userStatus, canModifyInviteePresence, event, refetchEvent, tagPresenceStyle, canRemoveInvitees, isAdmin, isEventPast, }) => {
    // Logic
    const { handlePresenceStatus, handleOnToggle, isOpenPopover, proxyPerson, handleRemoveInvitee, isOpenRemove, onCloseRemove, onOpenRemove, } = useLogic(event, refetchEvent, canModifyInviteePresence, proxyPersonId);
    // Render
    return (<HStack justify="space-between" width={'full'} onMouseEnter={onOpenRemove} onMouseLeave={onCloseRemove} py={'.25rem'} px={'1.25rem'} _hover={{ bgColor: COLORS.Tag.value }} position={'relative'}>
      {/* Avatar and user name */}
      <AvatarDetails person={person} avatarSize={'2.25rem'} avatarFont={FONTS.T1.T14Px.Medium500.value} titleFont={FONTS.T1.T14Px.Medium500.value} showBoardRole/>

      <HStack justifyContent={'flex-end'} position={'relative'}>
        {canModifyInviteePresence ? (<PopOver isOpen={isOpenPopover} onToggle={handleOnToggle} from={userStatus !== PresenceStatus?.Pending ? (<Tag backgroundColor={tagPresenceStyle?.bgColor} iconColor={tagPresenceStyle?.colors} color={COLORS.Text.T400.value} iconName={tagPresenceStyle?.iconName} style={{ borderRadius: 100, paddingHorizontal: 8 }} font={FONTS.T1.T12Px.Medium500.value}>
                  {tagPresenceStyle?.title}
                </Tag>) : (<Stack borderWidth={1} borderRadius={'1rem'} p={'.5rem'} width={'max-content'}>
                  <Text font={FONTS.T1.T12Px.Medium500.value}>
                    {tagPresenceStyle.title}
                  </Text>
                </Stack>)} placement="bottom">
            <VStack width={'full'} py={'.5rem'} alignItems={'flex-start'} spacing={0}>
              {/* Present */}
              <EventDetailsInviteesPresenceTag onClick={() => {
                void handlePresenceStatus(PresenceStatus.Present, personId);
            }} iconName="check" iconColor={COLORS.Success.T500.value} title={t(tKeys.home.calendar.event_details.information.presence.status
                .present)}/>
              {/* Remote */}
              <EventDetailsInviteesPresenceTag onClick={() => {
                void handlePresenceStatus(PresenceStatus.Remote, personId);
            }} iconName="remote" iconColor={COLORS.Success.T500.value} title={t(tKeys.home.calendar.event_details.information.presence.status
                .remote)}/>
              {/* Absent */}
              <EventDetailsInviteesPresenceTag onClick={() => {
                void handlePresenceStatus(PresenceStatus.Absent, personId);
            }} iconName="close-cross" iconColor={COLORS.Error.T500.value} title={t(tKeys.home.calendar.event_details.information.presence.status
                .absent)}/>
            </VStack>
          </PopOver>) : (<>
            {userStatus !== PresenceStatus?.Pending ? (<Tag backgroundColor={tagPresenceStyle?.bgColor} iconColor={tagPresenceStyle?.colors} color={COLORS.Text.T400.value} iconName={tagPresenceStyle?.iconName} style={{ borderRadius: 100, paddingHorizontal: 8 }} font={FONTS.T1.T12Px.Medium500.value}>
                {tagPresenceStyle?.title}
              </Tag>) : (<Stack borderWidth={1} borderRadius={'1rem'} p={'.5rem'} width={'max-content'}>
                <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T200.value}>
                  {t(tKeys.common.no_presence)}
                </Text>
              </Stack>)}
          </>)}

        {/* Proxy person */}
        {proxyPersonId && (<Tooltip hasArrow placement="bottom" label={<VStack alignItems={'flex-start'} spacing={'.25rem'} p={'.5rem'}>
                <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.White.T500.value}>
                  {getUserName(proxyPerson)}
                </Text>
                <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.White.T500.value}>
                  {proxyPerson?.email}
                </Text>
              </VStack>} borderRadius={'0.375rem'}>
            <Stack>
              <Avatar width={'2.25rem'} height={'2.25rem'} name={getUserName(proxyPerson) ?? ''} imageSource={proxyPerson?.picture?.uri} placeholderFont={FONTS.T1.T14Px.Medium500.value}/>
            </Stack>
          </Tooltip>)}

        {/* Remove invitee */}
        {canRemoveInvitees && isOpenRemove && (<Stack cursor={'pointer'} onClick={() => {
                void handleRemoveInvitee(personId);
            }} borderRadius={'1.5rem'} borderWidth={1} p={'.25rem'}>
            <Icon name={'close-cross'} height={20} width={20}/>
          </Stack>)}
      </HStack>
    </HStack>);
};
export default EventDetailsInformationsInvitees;
