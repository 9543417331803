import moment from 'moment';
export const PeriodTime = {
    TODAY: 'today',
    CURRENT_WEEK: 'this-week',
    CURRENT_MONTH: 'this-month',
    CURRENT_YEAR: 'this-year',
};
export const PeriodValues = {
    [PeriodTime.TODAY]: {
        fromDate: moment().startOf('day')?.toISOString(),
        toDate: moment().endOf('day')?.toISOString(),
    },
    [PeriodTime.CURRENT_WEEK]: {
        fromDate: moment().startOf('week')?.toISOString(),
        toDate: moment().endOf('week')?.toISOString(),
    },
    [PeriodTime.CURRENT_MONTH]: {
        fromDate: moment().startOf('month')?.toISOString(),
        toDate: moment().endOf('month')?.toISOString(),
    },
    [PeriodTime.CURRENT_YEAR]: {
        fromDate: moment().startOf('year')?.toISOString(),
        toDate: moment().endOf('year')?.toISOString(),
    },
};
