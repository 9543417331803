/* eslint-disable array-callback-return */
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { useUpdateEvent } from '../../../../services/online/calendar/calendar';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { useReadMe } from '../../../../services/online/people/people';
import { Role } from '../../../../services/types';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getStaleTime from '../../../../utils/getStaleTime';
import useUpdateEventInvitees from './hooks';
const useLogic = () => {
    // Attributes
    const { params, goBack, push, getCurrent } = useRouter();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const queryClient = useQueryClient();
    const [event, setEvent] = useState(params?.event);
    const [isEventPast, setIsEventPast] = useState();
    // Entities
    const selectPeopleEntity = params?.selectPeopleEntity ?? {
        allPeopleInTheCompany: undefined,
        selectedPeople: undefined,
    };
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            searchValue: '',
            searchedPeople: undefined,
        },
        onSubmit: undefined,
        enableReinitialize: true,
    });
    const form = createForm(values, rest);
    // Queries
    const { data: currentUserData } = useReadMe();
    const { data: enrolment, isLoading: isEnrolmentLoading } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': form?.searchValue?.value,
    }, {
        query: {
            staleTime: getStaleTime(),
            enabled: !!currentUserData,
        },
    });
    const { mutateAsync: updateEvent } = useUpdateEvent();
    // Get people to display
    const inviteesPeople = useMemo(() => {
        return enrolment?.items
            ?.map(enrolment => {
            let found = false;
            let inviteeStatus = {};
            event?.invitees?.map(invitee => {
                if (invitee?.personId === enrolment?.person?.id) {
                    found = true;
                    inviteeStatus = {
                        acceptedOn: invitee?.acceptedOn,
                        refusedOn: invitee.refusedOn,
                        proxyId: invitee.proxyId,
                    };
                }
            });
            if (found)
                return { ...enrolment?.person, ...inviteeStatus };
        })
            ?.filter(e => e);
    }, [enrolment?.items, event?.invitees]);
    const peopleInTheCompany = useMemo(() => {
        return enrolment?.items?.map(enrolment => {
            if (!inviteesPeople?.find(invitee => invitee?.id === enrolment?.person?.id)) {
                return enrolment?.person;
            }
        });
    }, [enrolment, inviteesPeople]);
    // Functions
    function handlePressOnAddInvitees() {
        // Update entity
        selectPeopleEntity.selectedPeople =
            params?.selectPeopleEntity?.selectedPeople ?? inviteesPeople;
        selectPeopleEntity.allPeopleInTheCompany =
            params?.selectPeopleEntity?.allPeopleInTheCompany ?? peopleInTheCompany;
        push('/select-people', {
            selectPeopleEntity,
            event,
            isMulti: true,
            previousRoute: getCurrent(),
            pageTitle: t(tKeys.calendar.createEvent.invitees.title),
            selectMembersSectionTitle: t(tKeys.tasks.create_task.assign_to.members),
        });
    }
    // Effects
    useEffect(() => {
        if (params?.event) {
            setEvent(params?.event);
        }
    }, [params?.event]);
    useEffect(() => {
        if (event) {
            setIsEventPast(moment(event?.endDate).isBefore(new Date()));
        }
    }, [event]);
    // Hooks
    useUpdateEventInvitees(event, event?.invitees, setEvent, params, updateEvent, queryClient, selectPeopleEntity);
    // Return
    return {
        event,
        inviteesPeople,
        goBack,
        form,
        handlePressOnAddInvitees,
        isAdmin,
        isEnrolmentLoading,
        isEventPast,
    };
};
export default useLogic;
