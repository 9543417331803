/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentGroupBy = {
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
};
