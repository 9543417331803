import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../components/content/text';
import Button from '../../components/inputs/button';
import { Kind } from '../../components/inputs/button/constants/kind';
import FormControl from '../../components/inputs/form-control';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import useLogic from './logic.web';
import Modal from '../../components/surfaces/modal/index.web';
import { Stack, VStack } from '@chakra-ui/react';
import Input from '../../components/inputs/input/index.web';
import { PlatformRoleRadio } from './radio-role/index.web';
import { Role } from '../../services/types';
import TagFieldItem from '../home/calendar/components/inputs/tag-field/tag-item/index.web';
import PopOver from '../../components/surfaces/pop-over/index.web';
import SelectBoardRole from '../select-board-role/index.web';
const InviteMember = ({ isOpen, onClose, refetchAfterInvite, redirectionAfterInvite, }) => {
    // Attributes
    const { form, values, handleOnSubmit, isAddNewMemberLoading, handleRoleChange, isOpenBoardRole, onToggleBoardRole, handleBoardRoleChange, } = useLogic(onClose, refetchAfterInvite, redirectionAfterInvite);
    const { t } = useTranslation();
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} actionButton={<Button onPress={handleOnSubmit} isLoading={isAddNewMemberLoading} kind={Kind.SECONDARY}>
          {t(tKeys.invitation.button.title)}
        </Button>} hasCloseButton title={t(tKeys.invitate.member.title)}>
      <VStack py={'1rem'} width={'full'} alignItems={'flex-start'}>
        <Stack px={'1rem'} width={'full'}>
          <FormControl label={t(tKeys.invitation.email.title)} labelFont={FONTS.T1.T14Px.SemiBold600.value} isMandatory helper={form?.newMemberEmail?.meta?.error
            ? t(tKeys.email.empty.error)
            : ''} error={form?.newMemberEmail?.meta?.error} spacing={4}>
            <Input value={form?.newMemberEmail?.value} onChange={e => {
            form?.newMemberEmail?.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.members.invite.email.placeholder)} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(form?.newBoardRoleName?.value)} hasEndEnhancer/>} isOpen={isOpenBoardRole} onToggle={onToggleBoardRole} isPortal={false}>
                  <SelectBoardRole onChange={handleBoardRoleChange} selectedBoardRoleId={form?.newBoardRoleId?.value}/>
                </PopOver>} autoFocus/>
          </FormControl>
        </Stack>

        <VStack alignItems={'flex-start'} width={'100%'} p={'1rem'} spacing={'1.25rem'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.invitation.platformRole.title)}
          </Text>
          <VStack width={'100%'} spacing={'.75rem'}>
            <PlatformRoleRadio roleName={t(tKeys.common.board_admin)} roleDescription={t(tKeys.platform.role.board_admin.description)} isChecked={values.newMemberRole === Role[0]} onChange={() => handleRoleChange(Role[0])}/>
            <PlatformRoleRadio roleName={t(tKeys.common.board_member)} roleDescription={t(tKeys.platform.role.board_member.description)} isChecked={values.newMemberRole === Role[1]} onChange={() => handleRoleChange(Role[1])}/>
            <PlatformRoleRadio roleName={t(tKeys.common.board_observer)} roleDescription={t(tKeys.platform.role.board_observer.description)} isChecked={values.newMemberRole === Role[2]} onChange={() => handleRoleChange(Role[2])}/>
          </VStack>
        </VStack>
      </VStack>
    </Modal>);
};
export default InviteMember;
