import React from 'react';
import { HStack, Radio, VStack, Text } from '@chakra-ui/react';
import FONTS from '../../../constants/fonts';
import COLORS from '../../../constants/colors';
export const PlatformRoleRadio = ({ roleDescription, roleName, isChecked, onChange, }) => {
    // Attributes
    const { fontSize: fontTitleSize, fontWeight: fontTitleWeight, fontFamily: fontTitleFamily, } = FONTS.T1.T12Px.SemiBold600.value;
    const { fontSize: fontDescriptionSize, fontWeight: fontDescriptionWeight, fontFamily: fontDescriptionFamily, } = FONTS.T1.T12Px.Regular400.value;
    // Return
    return (<HStack alignItems={'flex-start'} p={'1rem'} borderWidth={1} borderRadius={'.5rem'} width={'100%'} spacing={'.75rem'} backgroundColor={isChecked ? COLORS.Govrn.Blue.T100.value : COLORS.White.T500.value} borderColor={isChecked ? COLORS.Govrn.Blue.T500.value : COLORS.Line.value} _hover={{ backgroundColor: isChecked ? undefined : COLORS.BG.value }} cursor={'pointer'} onClick={onChange}>
      <Radio isChecked={isChecked}/>
      <VStack alignItems={'flex-start'} width={'100%'} spacing={'.25rem'}>
        <Text fontFamily={fontTitleFamily} fontSize={fontTitleSize} fontWeight={fontTitleWeight}>
          {roleName}
        </Text>
        <Text fontFamily={fontDescriptionFamily} fontSize={fontDescriptionSize} fontWeight={fontDescriptionWeight} color={COLORS.Text.T300.value} noOfLines={2}>
          {roleDescription}
        </Text>
      </VStack>
    </HStack>);
};
