import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
const PageTitle = ({ title, color = COLORS.Text.T400.value, font = FONTS.T1.T24Px.Bold700.value, testID, }) => {
    return (<Text font={font} color={color} testID={testID} numberOfLines={2}>
      {title}
    </Text>);
};
export default PageTitle;
