import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../hooks/router/useRouter';
import { useReadMe, useUpdatePerson, } from '../../../../services/online/people/people';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import { validationSchema } from './validations';
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '../../../../components/content/use-toast/index.web';
const useLogic = () => {
    // Attributes
    const { goBack } = useRouter();
    const { t } = useTranslation();
    const { isOpen: isOpenLanguage, onToggle: onToggleLanguage } = useDisclosure();
    const { isOpen: isOpenTimezone, onToggle: onToggleTimezone } = useDisclosure();
    const toast = useToast();
    // Queries
    const { data: currentUserData, refetch: refetchReadMe } = useReadMe();
    const { mutateAsync: updatePerson, isLoading: isUpdatePersonLoading } = useUpdatePerson();
    // Functions
    const handleOnSubmit = async () => {
        await updatePerson({
            data: {
                ...values,
            },
        }, {
            onSuccess: () => {
                void refetchReadMe();
                goBack();
                toast({
                    status: 'success',
                    title: t(tKeys.profile['user-profile'].saved),
                });
            },
        });
    };
    function handleOnChangeTimezone(value) {
        form?.timeZoneId?.onChange?.(value);
        onToggleTimezone();
    }
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            firstname: currentUserData?.person.firstname ?? '',
            lastname: currentUserData?.person.lastname ?? '',
            nickname: currentUserData?.person.nickname ?? '',
            biography: currentUserData?.person.biography ?? '',
            cultureName: currentUserData?.person.cultureName ?? '',
            timeZoneId: currentUserData?.person.timeZoneId ?? '',
        },
        onSubmit: handleOnSubmit,
        validationSchema: validationSchema(t),
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnSubmit: handleSubmit,
        isUpdatePersonLoading,
        form,
        dirty,
        isOpenLanguage,
        onToggleLanguage,
        isOpenTimezone,
        onToggleTimezone,
        handleOnChangeTimezone,
        goBack,
    };
};
export default useLogic;
