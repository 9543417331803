import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const Share = (props) => (<Svg viewBox="0 0 20 20" {...props}>
    <Path d="M15.0001 18.3333C16.3808 18.3333 17.5001 17.214 17.5001 15.8333C17.5001 14.4525 16.3808 13.3333 15.0001 13.3333C13.6194 13.3333 12.5001 14.4525 12.5001 15.8333C12.5001 17.214 13.6194 18.3333 15.0001 18.3333Z" stroke={props.color} strokeWidth="1.70833" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M4.99994 12.5C6.38065 12.5 7.49994 11.3807 7.49994 10C7.49994 8.61929 6.38065 7.5 4.99994 7.5C3.61923 7.5 2.49994 8.61929 2.49994 10C2.49994 11.3807 3.61923 12.5 4.99994 12.5Z" stroke={props.color} strokeWidth="1.70833" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M7.15823 11.2583L12.8499 14.575" stroke={props.color} strokeWidth="1.70833" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M15.0001 6.6665C16.3808 6.6665 17.5001 5.54722 17.5001 4.1665C17.5001 2.78579 16.3808 1.6665 15.0001 1.6665C13.6194 1.6665 12.5001 2.78579 12.5001 4.1665C12.5001 5.54722 13.6194 6.6665 15.0001 6.6665Z" stroke={props.color} strokeWidth="1.70833" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M12.8416 5.42505L7.15823 8.74172" stroke={props.color} strokeWidth="1.70833" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>);
export default Share;
