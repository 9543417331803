import React from 'react';
import { Pressable } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import Icon from '../../../../../../../components/content/icon';
import Text from '../../../../../../../components/content/text';
import Container from '../../../../../../../components/surfaces/container';
import Flex from '../../../../../../../components/surfaces/flex';
import styles from './style.web';
import CardCreatePollInput from './card-create-poll-input';
import Textarea from '../../../../../../../components/inputs/textarea';
import TouchableScale from 'react-native-touchable-scale';
import { tKeys } from '../../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { Stack, VStack } from '@chakra-ui/react';
const CardCreatePoll = ({ handleOnClosePoll, questionTitleValue, onChangeTitleValue, optionsValue, onChangeOptions, }) => {
    // Attributes
    const { t } = useTranslation();
    // Functions
    const handleAddOption = () => {
        const optionCopy = [...optionsValue];
        optionCopy.push('');
        onChangeOptions(optionCopy);
    };
    const handleDeletePollAnswer = (index) => {
        const optionCopy = [...optionsValue];
        optionCopy?.splice(index, 1);
        onChangeOptions(optionCopy);
    };
    const updateOption = (value, index) => {
        const optionCopy = [...optionsValue];
        optionCopy[index] = value;
        onChangeOptions(optionCopy);
    };
    // Render
    return (<Container style={styles.mainContainer}>
      <Flex direction="column">
        <Flex direction="row" justify="space-between" align="center" style={styles.pollContainer} spacing={12}>
          <Flex align="flex-start" flex={1} flexGrow={1}>
            {/* Poll title */}
            <Textarea placeholder={t(tKeys.poll.poll_title)} value={questionTitleValue} onChange={onChangeTitleValue} name="question-title" editable={true} numberOfLines={3} padding={1} minHeight={0} font={FONTS.T1.T14Px.Regular400.value}/>
          </Flex>

          {/* Close button */}
          <Flex align="center" justify="center" style={styles.closeButtonContainer}>
            <TouchableScale onPress={handleOnClosePoll} activeScale={0.99} style={styles.closeButton}>
              <Icon name="cross" width={15} height={15}/>
            </TouchableScale>
          </Flex>
        </Flex>

        {/* Inputs */}
        <VStack width={'full'} alignItems={'flex-start'} spacing={'.5rem'}>
          {optionsValue?.map?.((option, index) => (<CardCreatePollInput name={`cardCreateInput${option}`} value={option} onChange={value => updateOption(value, index)} onPressDelete={() => handleDeletePollAnswer(index)} placeholder={t(tKeys.create_task.add_an_option)} key={index}/>))}
        </VStack>

        {/*  Add an option */}
        <Pressable onPress={handleAddOption} style={styles.addOptionContainerButton}>
          <Flex style={styles.addOptionContainer}>
            {/* Add icon */}
            <Icon name="add" width={20} height={20} fill={COLORS?.Black?.T500?.value}/>
            <Stack paddingLeft={'.25rem'}>
              {/* Text */}
              <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.Text.T400.value}>
                {t(tKeys.create_task.add_an_option)}
              </Text>
            </Stack>
          </Flex>
        </Pressable>
      </Flex>
    </Container>);
};
export default CardCreatePoll;
