import { useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
const useLogic = ({ handleOnPressPollButton, isVoteLoading, currentUserId, post, }) => {
    // Attributes
    const { answers } = post?.question;
    const [maxValue, setMaxValue] = useState();
    const [userAlreadyVoted, setUserAlreadyVoted] = useState();
    const [selectedAnswerId, setSelectedAnswerId] = useState();
    const [animateVote, setAnimateVote] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [voteResultParams, setVoteResultParams] = useState();
    const allVotesTotal = useMemo(() => {
        return answers
            ?.map(answer => answer?.votes?.length)
            .reduce((a, b) => a + b, 0);
    }, [answers]);
    // Effects
    useEffect(() => {
        if (!isVoteLoading) {
            // Get the selected vote from data
            const userVote = answers?.find(answer => answer.votes?.map(v => v.personId).includes(currentUserId));
            setMaxValue(allVotesTotal);
            setUserAlreadyVoted(!!userVote);
            setSelectedAnswerId(userVote?.id);
        }
    }, [post, currentUserId]);
    // Handler
    const handlePress = (answerId) => {
        if (!isVoteLoading) {
            setSelectedAnswerId(answerId);
            if (!userAlreadyVoted)
                setMaxValue(allVotesTotal + 1);
            setAnimateVote(true);
            handleOnPressPollButton(answerId);
        }
    };
    const handleSeePollResults = () => {
        const params = {
            question: post?.question,
            canUserVote: true,
        };
        setVoteResultParams(params);
        onOpen();
    };
    // Return
    return {
        animateVote,
        handlePress,
        selectedAnswerId,
        maxValue,
        allVotesTotal: selectedAnswerId && !userAlreadyVoted ? allVotesTotal + 1 : allVotesTotal,
        handleSeePollResults,
        isOpen,
        onClose,
        voteResultParams,
    };
};
export default useLogic;
