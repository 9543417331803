import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
import { Stack, VStack } from '@chakra-ui/react';
const Card = ({ backgroundColor = [COLORS?.White.T500.value, COLORS?.White.T500.value], children, footer, header, isPostDetails, }) => {
    // Functions
    const renderChildren = () => {
        switch (typeof children) {
            case 'string':
                return (<Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T400.value} numberOfLines={5}>
            {children}
          </Text>);
            default:
                return children;
        }
    };
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} bgColor={backgroundColor} borderRadius={'.75rem'} borderWidth={isPostDetails ? 0 : 1} borderColor={COLORS.Line.value}>
      {/* Card Header */}
      {header && (<Stack width={'full'} p={'1rem'}>
          {header}
        </Stack>)}

      {/* Children */}
      {children && <Stack width={'full'}>{renderChildren()}</Stack>}

      {/* Card footer */}
      {footer && (<Stack width={'full'} p={'1rem'}>
          {footer}
        </Stack>)}
    </VStack>);
};
export default Card;
