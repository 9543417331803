import Splash from '../screens/splash';
import authRoutes from '../screens/auth/routes';
import chooseCountryRoutes from '../screens/choose-country/routes';
import homeRoutes from '../screens/home/routes';
import profileRoutes from '../screens/profile/routes';
import SelectApplicationLanguageRoutes from '../screens/select-application-language/routes';
import SelectTimezoneRoutes from '../screens/select-time-zone/routes';
import { stackNavigator } from './navigators';
import CreatePost from '../screens/home/feed/create-post';
import EditPost from '../screens/home/feed/edit-post';
import filterRoutes from '../screens/home/feed/filter/routes';
import CreateTask from '../screens/home/tasks/create-task';
import CustomTaskDetailAssignTo from '../screens/task-details/custom-task-detail/assign-to';
import taskDetailsRoutes from '../screens/task-details/routes';
import DocumentDetails from '../screens/document-details';
import RepositoryFilter from '../screens/home/more/repository/filter';
import KnowledgeBaseFilters from '../screens/home/more/knowledge-base/filter';
import DocumentViewer from '../screens/document-viewer';
import CreateDocument from '../screens/home/more/repository/create-document';
import SelectFileType from '../screens/select-file-type';
import SelectEventTypeRoutes from '../screens/select-event-type/routes';
import CreateEvent from '../screens/home/calendar/create-event';
import CalendarFilter from '../screens/home/calendar/filter';
import EventDetailsProxy from '../screens/home/calendar/event-details-proxy';
import SelectPeople from '../screens/select-people';
import EventDetailsInviteesPage from '../screens/home/calendar/event-details-invitees-page';
import EventDetailsInviteesPresence from '../screens/home/calendar/event-details-invitees-presence';
import EditAgendaItem from '../screens/home/calendar/edit-agenda-item';
import VoteResults from '../screens/vote-results';
import EventDetailsResolutionPage from '../screens/home/calendar/components/content/event-details-resolution-page';
import GenerateEventDocumentRoutes from '../screens/home/calendar/generate-event-document/routes';
import SelectDocumentLanguages from '../screens/home/calendar/document-language';
import InviteMember from '../screens/invite-member';
import SelectMemberRole from '../screens/select-member-role';
import CreateChannel from '../screens/home/messages/create-channel';
import CreateGroupChannel from '../screens/home/messages/create-group-channel';
import Channel from '../screens/home/messages/channel';
import EventDetails from '../screens/home/calendar/event-details';
import ShareInMessages from '../screens/share-in-messages';
import Search from '../screens/search';
import PostDetails from '../screens/post-details';
import DocumentEdit from '../screens/document-edit';
import ChatSettings from '../screens/home/messages/chat-settings';
import SignDocument from '../screens/sign-document';
import WebView from '../screens/web-view';
import offlineModeRoutes from '../screens/offline/routes';
import Boardpack from '../screens/boardpack/index.web';
import Impersonate from '../screens/auth/impersonate/index.web';
// Router entry point
export const routes = [
    {
        name: '/',
        deepLink: '/',
        defaultRoute: '/splash-screen',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/splash-screen',
                deepLink: '/splash-screen',
                component: Splash,
            },
            {
                name: '/impersonate',
                deepLink: '/impersonate',
                component: Impersonate,
            },
            {
                name: '/company',
                deepLink: '/company',
                defaultRoute: '/home',
                component: stackNavigator,
                subRoutes: [
                    // Home (feed, tasks, calendar, more) screens
                    ...homeRoutes,
                    // Profile routes (user profile and other user profile) screens
                    ...profileRoutes,
                    {
                        name: '/document-details',
                        deepLink: '/document-details',
                        component: DocumentDetails,
                    },
                    {
                        name: '/event-details',
                        deepLink: '/event-details',
                        component: EventDetails,
                    },
                    {
                        name: '/boardpack',
                        deepLink: '/boardpack',
                        component: Boardpack,
                    },
                ],
            },
            // Auth (sign-in/ sign-up) screens
            ...authRoutes,
            // Choose country screens
            ...chooseCountryRoutes,
            //  Select application language
            ...SelectApplicationLanguageRoutes,
            //  Select timezone
            ...SelectTimezoneRoutes,
            // Select Event type
            ...SelectEventTypeRoutes,
            // Task details routes
            ...taskDetailsRoutes,
            // Offline mode routes
            ...offlineModeRoutes,
            {
                name: '/create-post',
                deepLink: '/create-post',
                component: CreatePost,
            },
            {
                name: '/edit-post',
                deepLink: '/edit-post',
                component: EditPost,
            },
            {
                name: '/create-task',
                deepLink: '/create-task',
                component: CreateTask,
            },
            {
                name: '/task-details-assign-to',
                deepLink: '/task-details-assign-too',
                component: CustomTaskDetailAssignTo,
            },
            {
                name: '/repository-filter',
                deepLink: '/repository-filter',
                component: RepositoryFilter,
            },
            {
                name: '/knowledge-base-filters',
                deepLink: '/knowledge-base-filters',
                component: KnowledgeBaseFilters,
            },
            ...filterRoutes,
            {
                name: '/document-viewer',
                deepLink: '/document-viewer',
                component: DocumentViewer,
            },
            {
                name: '/create-document',
                deepLink: '/create-document',
                component: stackNavigator,
                defaultRoute: '/default',
                subRoutes: [
                    {
                        name: '/default',
                        deepLink: '/default',
                        component: CreateDocument,
                    },
                ],
            },
            {
                name: '/create-event',
                deepLink: '/create-event',
                component: CreateEvent,
            },
            {
                name: '/calendar-filter',
                deepLink: '/calendar-filter',
                component: CalendarFilter,
            },
            {
                name: '/choose-proxy',
                deepLink: '/choose-proxy',
                component: EventDetailsProxy,
            },
            {
                name: '/select-people',
                deepLink: '/select-people',
                component: SelectPeople,
            },
            {
                name: '/event-detail-invitees-page',
                deepLink: '/event-detail-invitees-page',
                component: EventDetailsInviteesPage,
            },
            {
                name: '/event-detail-invitees-presence',
                deepLink: '/event-detail-invitees-presence',
                component: EventDetailsInviteesPresence,
            },
            {
                name: '/edit-agenda-item',
                deepLink: '/edit-agenda-item',
                component: EditAgendaItem,
            },
            {
                name: '/vote-results',
                deepLink: '/vote-results',
                component: VoteResults,
            },
            {
                name: '/event-resolutions',
                deepLink: '/event-resolutions',
                component: EventDetailsResolutionPage,
            },
            ...GenerateEventDocumentRoutes,
            {
                name: '/select-document-language',
                deepLink: '/select-document-language',
                component: SelectDocumentLanguages,
            },
            {
                name: '/create-channel',
                deepLink: '/create-channel',
                component: CreateChannel,
            },
            {
                name: '/create-group-channel',
                deepLink: '/create-group-channel',
                component: CreateGroupChannel,
            },
            {
                name: '/channel',
                deepLink: '/channel',
                component: Channel,
            },
            {
                name: '/invite-member',
                deepLink: '/invite-member',
                component: InviteMember,
            },
            {
                name: '/select-member-role',
                deepLink: '/select-member-role',
                component: SelectMemberRole,
            },
            {
                name: '/share-in-messages',
                deepLink: '/share-in-messages',
                component: ShareInMessages,
            },
            {
                name: '/search',
                deepLink: '/search',
                component: Search,
            },
            {
                name: '/post-details',
                deepLink: '/post-details',
                component: PostDetails,
            },
            {
                name: '/document-edit',
                deepLink: '/document-edit',
                component: DocumentEdit,
            },
            {
                name: '/select-document-type',
                deepLink: '/select-document-type',
                component: SelectFileType,
            },
            {
                name: '/chat-settings',
                deepLink: '/chat-settings',
                component: ChatSettings,
            },
            {
                name: '/sign-document',
                deepLink: '/sign-document',
                component: SignDocument,
            },
            {
                name: '/web-view',
                deepLink: '/web-view',
                component: WebView,
            },
        ],
    },
];
export function getDefaultRoute(routes) {
    const route = routes.find(route => route.name === '/');
    if (route === undefined) {
        throw Error("No default route set. Think about adding a '/' route.");
    }
    return route;
}
