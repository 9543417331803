/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import Modal from '../../../components/surfaces/modal/index.web';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import { VStack } from '@chakra-ui/react';
import { useDocumentDetailsPermissionsLogic } from './logic.web';
import { BusyIndicator } from '../../../components/utility/busy-indicator';
import Placeholder from '../../../components/utility/placeholder';
import { DocumentDetailsListPermissions } from './list-permissions/index.web';
export const DocumentDetailsPermissions = ({ isPermissionsLoading, isOpen, onClose, permissions, refetchPermissions, documentId, }) => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { isAddGroupLoading, isAddPersonLoading, groupsNotInvited, handleSearchChange, peopleNotInvited, searchPopoverDisclosure, handleGroupAddToDocument, handleGroupRemoveFromDocument, handlePersonAddToDocument, handlePersonRemoveFromDocument, } = useDocumentDetailsPermissionsLogic(permissions, documentId, refetchPermissions);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.common.permissions)} hasFooter={false} hasCloseButton>
      <VStack alignItems={'flex-start'} width={'full'} py={'1.25rem'} spacing={'1rem'} minHeight={'40rem'} overflow={'auto'}>
        {/* Members and groups list */}
        <BusyIndicator isEmpty={!permissions?.groups?.length &&
            !permissions?.persons?.length &&
            !permissions?.eventGroups?.length &&
            !permissions?.eventPersons?.length} placeholder={<Placeholder imageType={'monocle-emoji'} hasImage={false} title={t(tKeys.permissions.placeholder.title)} subTitle={t(tKeys.permissions.placeholder.subtitle)}/>} isLoading={isPermissionsLoading}>
          <VStack px={'1.25rem'} width={'100%'} spacing={'.625rem'}>
            {/* Document permissions */}
            <DocumentDetailsListPermissions groups={permissions?.groups ?? []} persons={permissions?.persons ?? []} title={t(tKeys.document.permissions.file.title)} onGroupRemoveFromDocument={handleGroupRemoveFromDocument} onPersonRemoveFromDocument={handlePersonRemoveFromDocument} isDocumentPermissions isSelectPeopleLoading={isAddGroupLoading || isAddPersonLoading} groupsNotInvited={groupsNotInvited} onSearchChange={handleSearchChange} isSelectPeopleOpen={searchPopoverDisclosure.isOpen} onGroupAddToDocument={handleGroupAddToDocument} onPersonAddToDocument={handlePersonAddToDocument} onSelectPeopleToggle={searchPopoverDisclosure.onToggle} peopleNotInvited={peopleNotInvited}/>

            {/* Event permissions linked to the document */}
            <DocumentDetailsListPermissions groups={permissions?.eventGroups ?? []} persons={permissions?.eventPersons ?? []} title={t(tKeys.document.permissions.event.title)}/>
          </VStack>
        </BusyIndicator>
      </VStack>
    </Modal>);
};
