import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../../../components/surfaces/flex';
import Placeholder from '../../../../../../components/utility/placeholder';
import { tKeys } from '../../../../../../translations/keys';
import Channel from '../../../channel';
import MessagesList from '../messages-list';
import styles from './style.web';
const MessageContent = ({ isModuleAvailable, channels, isChannelsLoading, refetchChannels, search, setSearch, onCreateChannelPress, onChannelPress, getParticipant, selectedChannel, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    if (!isModuleAvailable) {
        return (<Flex>
        <Placeholder imageType="monocle-emoji" title={t(tKeys.messages.moduleDisabled.title)} subTitle={t(tKeys.messages.moduleDisabled.subtitle)} maxWidth={320}/>
      </Flex>);
    }
    return (<Flex style={{ ...styles.mainContainer }} flex={1}>
      <Flex direction="column" style={{
            ...styles.container,
            maxWidth: '17.5rem',
            minWidth: '17.5rem',
        }}>
        <MessagesList channels={channels} isChannelsLoading={isChannelsLoading} refetchChannels={refetchChannels} search={search} setSearch={setSearch} onCreateChannelPress={onCreateChannelPress} onChannelPress={onChannelPress} getParticipant={getParticipant} selectedChannel={selectedChannel}/>
      </Flex>

      <Flex direction="column" style={{ ...styles.containerChannel }} flex={1}>
        <Channel selectedChannel={selectedChannel}/>
      </Flex>
    </Flex>);
};
export default MessageContent;
