import { t } from 'i18next';
import React from 'react';
import Icon from '../../../../../../components/content/icon';
import COLORS from '../../../../../../constants/colors';
import { tKeys } from '../../../../../../translations/keys';
import { HStack, VStack } from '@chakra-ui/react';
import FONTS from '../../../../../../constants/fonts';
import Button from '../../../../../../components/inputs/button/index.web';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Text from '../../../../../../components/content/text';
import PopOver from '../../../../../../components/surfaces/pop-over/index.web';
import LoadingAnimation from '../../../../../../components/utility/loading/index.web';
const EventDetailsButtonsHeader = ({ handlePublishEvent, handleEditEventPress, isPublishEventLoading, isEventPublished, isEventLoading, isInviteeOnEvent, isAdmin, handleOnPressMessage, event, isDeleteLoading, onDeleteEvent, }) => {
    // Functions
    function PublishButtonComponent() {
        if (isEventPublished || isEventLoading || (!isInviteeOnEvent && !isAdmin)) {
            return null;
        }
        return (<Button 
        // eslint-disable-next-line react/prop-types
        onPress={async () => await handlePublishEvent(event?.id)} isLoading={isPublishEventLoading} size={Size.DEFAULT} kind={Kind.SECONDARY}>
        <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.White.T500.value}>
          {t(tKeys.common.publish)}
        </Text>
      </Button>);
    }
    // Render
    return (<HStack justifyContent={'flex-end'} width={'full'} spacing={'1rem'}>
      {/* Display publish button only if the event is not publied yet */}
      <PublishButtonComponent />

      {/* Share the event in private message */}
      <Button kind={Kind.OUTLINE} onPress={handleOnPressMessage}>
        {t(tKeys.common.share)}
      </Button>

      {isAdmin && (<PopOver from={<Icon name="more-vertical" height={20} width={20}/>} placement="bottom-start">
          <VStack width={'full'} alignItems={'flex-start'} minW={'12rem'} px={'.5rem'} py={'.25rem'}>
            {/* Edit post */}
            <Button onPress={handleEditEventPress} kind={Kind.TRANSPARENT} width={'full'} size={Size.COMPACT}>
              <HStack width={'full'}>
                <Icon name="edit" width={30} height={30} fill={'transparent'}/>
                <Text font={FONTS.T1.T14Px.Medium500.value}>
                  {t(tKeys.news_feed.edit_post)}
                </Text>
              </HStack>
            </Button>

            <Button onPress={!isDeleteLoading && onDeleteEvent} kind={Kind.TRANSPARENT} width={'full'} size={Size.COMPACT}>
              <HStack width={'full'}>
                {isDeleteLoading ? (<LoadingAnimation size={Size.MINI}/>) : (<Icon name="delete-bin" width={30} height={30}/>)}
                <Text font={FONTS.T1.T14Px.Medium500.value} color={isDeleteLoading
                ? COLORS.InputText.value
                : COLORS.Text.T800.value}>
                  {t(tKeys.news_feed.delete_post.title)}
                </Text>
              </HStack>
            </Button>
          </VStack>
        </PopOver>)}
    </HStack>);
};
export default EventDetailsButtonsHeader;
