import { useDisclosure } from '@chakra-ui/react';
import useRouter from '../../../../../../hooks/router/useRouter';
import { useReadPost } from '../../../../../../services/online/news-feed/news-feed';
import { isMinute } from '../../../../../../utils/getDocumentKind';
import { DocumentKindEnum, } from '../../../../../document-details/type';
import { useState } from 'react';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
const useLogic = ({ eventData, documentRepositoryData, postId, }) => {
    // Attributes
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const { isOpen: isOpenDocumentPreview, onOpen: onOpenDocumentPreview, onClose: onCloseDocumentPreview, } = useDisclosure();
    const { isOpen: isOpenPostDetails, onOpen: onOpenPostDetails, onClose: onClosePostDetails, } = useDisclosure();
    const [documentPreview, setDocumentPreview] = useState();
    // Queries
    const { data: postData } = useReadPost(postId, {
        query: {
            enabled: !!postId,
        },
    });
    // Functions
    function handleOnPressEvent() {
        push('/company/event-details', {
            companyId: selectedCompanyId,
            eventId: eventData?.id,
        });
    }
    function handleOnPressDocument() {
        const params = {
            documentKind: isMinute(documentRepositoryData)
                ? DocumentKindEnum.MINUTES
                : DocumentKindEnum?.DOCUMENT,
            documentId: documentRepositoryData?.id,
            eventId: documentRepositoryData?.eventId,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...params,
        });
    }
    function handleOnFilePress(file) {
        if (file?.uri) {
            setDocumentPreview({
                documentUri: file?.uri,
                document: file,
                documentTitle: file?.filename,
            });
            onOpenDocumentPreview();
        }
    }
    function handleOnPressPost(postId) {
        onOpenPostDetails();
    }
    // Return
    return {
        handleOnPressPost,
        handleOnPressDocument,
        handleOnPressEvent,
        handleOnFilePress,
        postData,
        isOpenDocumentPreview,
        onCloseDocumentPreview,
        documentPreview,
        isOpenPostDetails,
        onClosePostDetails,
    };
};
export default useLogic;
