import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../components/content/icon';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import Container from '../../../../../../components/surfaces/container';
import DocumentModal from '../../../../../../components/surfaces/document-modal';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import { Shape } from '../../../../../../constants/shape';
import { tKeys } from '../../../../../../translations/keys';
import ChatboxFilePreview from '../chatbox-file-preview';
import styles from './style.web';
import { HStack, Text as ChakraText, Textarea } from '@chakra-ui/react';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
const ChatBox = ({ form, isSendMessageDisabled, isSendMessageLoading, onDeleteIconPress, onConfirmDeleteMessages, handleOnSelectDocument, onEphemeralPress, isDeleteMessageLoading, onAddFilePress, isDocumentModalOpen, }) => {
    // Attributes
    const { t } = useTranslation();
    const isConfirmDeleteButtonDisabled = !form?.deletedMessageIds?.value?.length || isDeleteMessageLoading;
    const ConfirmDeleteButtonLabel = useCallback(() => {
        if (!form?.deletedMessageIds?.value?.length)
            return t(tKeys.messages.chatBox.deleteMessagesButton.label);
        return t(tKeys.messages.chatBox.deleteMessagesButton.enabled.label, {
            count: form?.deletedMessageIds?.value?.length,
        });
    }, [form?.deletedMessageIds?.value]);
    function handleKeyDown(event) {
        if (event.key === 'Enter' && event.shiftKey) {
            event.preventDefault();
            form?.submitForm();
        }
    }
    // Render
    return (<>
      <Flex direction="column" style={{
            ...styles.mainContainer,
        }} spacing={8}>
        {!form.isDeleteMode.value && (<Textarea value={form?.message?.value} onChange={e => form?.message?.onChange(e.currentTarget.value)} placeholder={t(tKeys.messages.chatBox.placeholder)} onKeyDown={handleKeyDown} noOfLines={6} height={'5rem'} borderColor={COLORS.BGFullscreen.value}/>)}

        {form?.file && (<ChatboxFilePreview file={form?.file} type={form?.fileType.value}/>)}

        <Flex justify="space-between" align="center">
          <Flex align="center" spacing={10}>
            {/* Add File button */}
            <Button shape={Shape.ROUND} size={Size.COMPACT} kind={Kind.GRAY} backgroundColorPerso={COLORS.Tag.value} onPress={onAddFilePress}>
              <Icon name="add" width={20} height={20} fill={COLORS.InputText.value}/>
            </Button>

            {/* Delete Mode Button */}
            <Container marginLeft={4}>
              <Button shape={Shape.ROUND} size={Size.COMPACT} kind={form.isDeleteMode.value ? Kind.SECONDARY : Kind.WHITE} onPress={onDeleteIconPress}>
                <Icon name="trash" width={20} height={20} fill={form.isDeleteMode.value
            ? COLORS.White.T500.value
            : COLORS.InputText.value}/>
              </Button>
            </Container>
            {/* Ephemeral Mode Button */}
            <Button shape={Shape.ROUND} size={Size.COMPACT} kind={form.isEphemeral.value ? Kind.SECONDARY : Kind.WHITE} onPress={onEphemeralPress}>
              <Icon name="clock-black" width={18} height={20} fill={form.isEphemeral.value
            ? COLORS.White.T500.value
            : COLORS.InputText.value}/>
            </Button>
          </Flex>

          <HStack spacing={'.5rem'}>
            {form.isDeleteMode.value ? (<Button shape={Shape.ROUND} size={Size.LARGE} isDisabled={isConfirmDeleteButtonDisabled} onPress={onConfirmDeleteMessages} isLoading={isDeleteMessageLoading} width={'fit-content'}>
                {ConfirmDeleteButtonLabel()}
              </Button>) : (<Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.Text.T300.value}>
                {t(tKeys.messages.press_shift_enter)}
              </Text>)}

            <Button shape={Shape.ROUND} size={Size.LARGE} onPress={form?.submitForm} isDisabled={isSendMessageDisabled} isLoading={isSendMessageLoading}>
              <ChakraText color={isSendMessageDisabled
            ? COLORS.DarkGrey.value
            : COLORS.White.T500.value}>
                {t(tKeys.home.messages.chatbox.button.title)}
              </ChakraText>
            </Button>
          </HStack>
        </Flex>
      </Flex>

      <DocumentModal isOpen={isDocumentModalOpen} onDismiss={onAddFilePress} handleOnSelect={handleOnSelectDocument}/>
    </>);
};
export default ChatBox;
