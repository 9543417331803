import { Box } from '@chakra-ui/react';
import useLogic from './logic.web';
import React from 'react';
import Button from '../../../components/inputs/button/index.web';
import { Kind } from '../../../components/inputs/button/constants/kind';
import { Shape } from '../../../constants/shape';
import { Size } from '../../../components/inputs/button/constants/size';
import Icon from '../../../components/content/icon';
import { DocumentDetailsSignAlertCard } from '../../document-details/components/content/sign-alert-card/index.web';
export const DocumentPreview = ({ selectedDocument, height, width, isSmallPreview, onFullScreenPress, onClickSign, isUserMustSignDocument, setPDFtronInstance, selectedTemplate, fullScreenRight = '1rem', fullScreenTop = '7.5rem', }) => {
    // Logic
    const { viewerRef } = useLogic(selectedDocument, setPDFtronInstance, selectedTemplate);
    // Render
    return (<Box width={width ?? 'full'} position={'relative'}>
      <Box id={`viewer-${height}`} ref={viewerRef} height={height ?? '92vh'} width={'full'}/>
      {isSmallPreview && !selectedTemplate && (<>
          {onFullScreenPress && (<Box position={'absolute'} top={fullScreenTop} right={fullScreenRight}>
              <Button shape={Shape.ROUND} size={Size.MINI} kind={Kind.SECONDARY} onPress={onFullScreenPress}>
                <Icon name="full-screen" width={24} height={24}/>
              </Button>
            </Box>)}
          {isUserMustSignDocument && (<DocumentDetailsSignAlertCard onClickSign={onClickSign}/>)}
        </>)}
    </Box>);
};
