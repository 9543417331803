import { t } from 'i18next';
import { Role } from '../services/types';
import { tKeys } from '../translations/keys';
export function getPlatformRoleLabel(role) {
    switch (role) {
        case Role[0]:
            return t(tKeys.common.board_admin);
        case Role[1]:
            return t(tKeys.common.board_member);
        case Role[2]:
            return t(tKeys.common.board_observer);
        default:
            return '';
    }
}
