import React from 'react';
import Icon from '../icon';
import Text from '../text';
import styles from './style';
import Flex from '../../surfaces/flex';
import FONTS from '../../../constants/fonts';
const FilterIndicator = ({ filters = 0, }) => {
    // Render
    return (<Flex>
      <Icon name={'filter'} width={20} height={20} fill={'transparent'}/>
      {filters > 0 && (<Flex style={styles.container} align="center" justify="center">
          <Text font={FONTS.T1.T10Px.SemiBold600.value}>{filters}</Text>
        </Flex>)}
    </Flex>);
};
export default FilterIndicator;
