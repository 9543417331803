import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: COLORS.Line.value,
    },
    inputContainer: {
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 24,
    },
    buttonsContainer: {
        borderTopWidth: 1,
        borderColor: COLORS.Line.value,
        padding: 16,
        justifyContent: 'space-between',
    },
    componentsContainer: {
        marginHorizontal: 11,
        marginBottom: 24,
    },
    actionButton: {
        alignSelf: 'center',
    },
});
export default styles;
