import React, { useState } from 'react';
import Image from '../../../../../components/content/image';
import COLORS from '../../../../../constants/colors';
import { t } from 'i18next';
import { tKeys } from '../../../../../translations/keys';
import { HStack, Stack } from '@chakra-ui/react';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import Button from '../../../../../components/inputs/button/index.web';
import { Size } from '../../../../../components/inputs/button/constants/size';
import Input from '../../../../../components/inputs/input/index.web';
const EmailAddressCompany = ({ enrolment, handleOnEndEditing, }) => {
    // Attributes
    const [emailAddress, setEmailAddress] = useState(enrolment?.email);
    // Render
    return (<HStack width={'full'} justifyContent={'space-between'}>
      {/*  Company image */}
      <HStack>
        <Stack borderWidth={1} borderColor={COLORS.Line.value} borderRadius={8} overflow={'hidden'}>
          <Image imageURI={enrolment?.company?.picture?.uri} width={'2.75rem'} height={'2.5rem'} isLocalImage={false}/>
        </Stack>

        {/* User email address associated to the company */}
        <Input type="email" value={emailAddress} onChange={e => setEmailAddress(e?.currentTarget?.value)} placeholder={t(tKeys?.['sign-in']?.page?.['identity-input']?.placeholder)} width={'30rem'}/>
      </HStack>
      <Button kind={Kind.SECONDARY} size={Size.SMALL} onPress={() => {
            handleOnEndEditing(emailAddress);
        }}>
        {t(tKeys?.common.save)}
      </Button>
    </HStack>);
};
export default EmailAddressCompany;
