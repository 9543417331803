import React, { Children } from 'react';
import Flex from '../../surfaces/flex';
import styles from './style';
const Tabs = ({ children, activeKey, onChange }) => {
    // Render
    const renderTabs = () => {
        return Children.map(children, (child, index) => {
            if (!child)
                return;
            const key = child.key ?? index;
            return React.cloneElement(child, {
                key,
                active: parseInt(key.toString()) === parseInt(activeKey.toString()),
                onSelect: () => onChange?.(key),
                children: child.props.title,
            });
        });
    };
    return (<Flex direction="column" style={styles.tabBarContainer}>
      {/* Tab bar */}
      <Flex align="center">{renderTabs()}</Flex>
    </Flex>);
};
export default Tabs;
