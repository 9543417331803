import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.BG.value,
        flex: 1,
        flexGrow: 1,
    },
    tabsContainer: {
        marginTop: -2, // To hide the grey line between tab and navigation header
    },
});
export default styles;
