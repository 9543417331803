import { useFormik } from 'formik';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { useCreateDocumentType, useUpdateDocumentType, } from '../../../../../../services/online/repository/repository';
import { createForm } from '../../../../../../utils/formik';
import { t } from 'i18next';
import { validationSchema } from './validations';
const useLogic = (onClose, refetchFilesType, fileType) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { mutateAsync: createFileType, isLoading: isCreateFileTypeLoading } = useCreateDocumentType();
    const { mutateAsync: updateFileType, isLoading: isUpdateFileTypeLoading } = useUpdateDocumentType();
    // Functions
    const handleOnSubmit = async () => {
        if (form?.fileTypeName?.value?.length) {
            if (fileType) {
                await updateFileType({
                    data: {
                        companyId: selectedCompanyId,
                        id: fileType?.id,
                        name: form?.fileTypeName?.value,
                    },
                }, {
                    onSuccess: () => {
                        refetchFilesType();
                        onClose();
                    },
                    onError: err => console.error('Error when updating file type : ', err),
                });
                return;
            }
            await createFileType({
                data: {
                    companyId: selectedCompanyId,
                    name: form?.fileTypeName?.value,
                },
            }, {
                onSuccess: () => {
                    refetchFilesType();
                    onClose();
                },
                onError: err => console.error('Error when creating file type : ', err),
            });
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            fileTypeName: fileType?.name ?? '',
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnSubmit: handleSubmit,
        isCreateFileTypeLoading,
        isUpdateFileTypeLoading,
        dirty,
        form,
    };
};
export default useLogic;
