export function ToConfirmAuthenticationParams(values) {
    return {
        'sms-code': values.smsCode?.toLocaleUpperCase(),
    };
}
export function ToConfirmInvitationParams(values) {
    return {
        'sms-code': values.smsCode?.toLocaleUpperCase(),
    };
}
export function ToConfirmForgotPasswordParams(values, email) {
    return {
        'sms-code': values.smsCode?.toLocaleUpperCase(),
        email,
    };
}
