import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: { marginBottom: 20 },
    buttonText: {
        paddingHorizontal: 16,
    },
    total: { marginLeft: 12 },
    separator: { width: 12 },
});
export default styles;
