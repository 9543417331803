import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../contexts/companies';
import useFormatDate from '../../../hooks/format-date/useFormatDate';
import { useReadMessages } from '../../../services/online/chat/chat';
import { useReadMe, useReadPerson, } from '../../../services/online/people/people';
import { tKeys } from '../../../translations/keys';
import getStaleTime from '../../../utils/getStaleTime';
const useLogic = (personId, person, lastMessageDate, channelId) => {
    // Attributes
    const { formatDate } = useFormatDate();
    const { t } = useTranslation();
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: currentUser } = useReadMe({
        query: { staleTime: getStaleTime() },
    });
    const { data: selectedPersonData } = useReadPerson(personId, {
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!personId && !person } });
    const personData = person ?? selectedPersonData;
    const { data: messages } = useReadMessages({ 'channel-id': channelId }, { query: { enabled: !!channelId, refetchOnWindowFocus: true } });
    // Attributes
    const messageDate = useMemo(() => {
        if (!lastMessageDate)
            return '';
        const isToday = moment(lastMessageDate)
            .startOf('day')
            .isSame(moment(new Date()).startOf('day'));
        if (isToday) {
            // Return date in HH:mm format
            return formatDate({
                date: lastMessageDate,
                formatWithTimezone: true,
                format: 'HH:mm',
            });
        }
        // Else return full date
        return formatDate({ date: lastMessageDate, formatWithTimezone: true });
    }, [lastMessageDate]);
    // Get the preview of last message
    const lastMessage = useMemo(() => {
        const msg = messages?.[messages.length - 1];
        if (!msg)
            return '';
        if (msg?.isDeleted)
            return getLastMessage(msg?.creatorId, tKeys.messages.removed.title);
        if (msg?.file)
            return getLastMessage(msg?.creatorId, tKeys.messages.file.placeholder);
        if (msg?.postId)
            return getLastMessage(msg?.creatorId, tKeys.messages.post.placeholder);
        if (msg?.eventId)
            return getLastMessage(msg?.creatorId, tKeys.messages.event.placeholder);
        if (isCurrentUserMessage(msg?.creatorId))
            return `${t(tKeys.common.you)} : ${msg?.content}`;
        else
            return msg?.content;
    }, [messages]);
    // Functions
    function isCurrentUserMessage(id) {
        return currentUser?.person?.id === id;
    }
    function getLastMessage(creatorId, translationKey) {
        return isCurrentUserMessage(creatorId)
            ? t(translationKey?.me)
            : t(translationKey?.other, { name: personData?.firstname });
    }
    // Return
    return {
        personData,
        messageDate,
        lastMessage,
    };
};
export default useLogic;
