import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../../../hooks/router/useRouter';
import { getReadPostsQueryKey, useDeletePost, useMarkPostAsLiked, useUnmarkPostAsLiked, useVote, } from '../../../../../../services/online/news-feed/news-feed';
import { useReadPerson } from '../../../../../../services/online/people/people';
import { useQueryClient } from '@tanstack/react-query';
import { useDisclosure } from '@chakra-ui/react';
import { tKeys } from '../../../../../../translations/keys';
import { useToast } from '../../../../../../components/content/use-toast/index.web';
const useLogic = ({ post, refetchNewsFeedData, currentUserId, company, }) => {
    // Attributes
    const [showPostModal, setShowPostModal] = useState();
    const { push } = useRouter();
    const { t } = useTranslation();
    const toast = useToast();
    const queryClient = useQueryClient();
    const isPostLikedByUser = useMemo(() => {
        return !!post?.likes?.find(like => like?.personId === currentUserId);
    }, [post]);
    const { isOpen: isOpenEditPost, onOpen: onOpenEditPost, onClose: onCloseEditPost, } = useDisclosure();
    // Queries
    const { mutateAsync: likePost } = useMarkPostAsLiked();
    const { mutateAsync: unlikePost } = useUnmarkPostAsLiked();
    const { mutateAsync: vote, isLoading: isVoteLoading } = useVote();
    const { mutateAsync: deletePost, isLoading: isDeletePostLoading } = useDeletePost();
    const { data: creator, isLoading: isCreatorLoading } = useReadPerson(post?.creatorId || post?.invitation?.inviteeId, {
        'company-id': company?.id,
    }, {
        query: {
            enabled: (!!post?.creatorId && !!company?.id) ||
                !!post?.invitation?.inviteeId,
            staleTime: 1 * 3600,
        },
    });
    const handleVote = (answerId) => {
        void vote({ answerId }, {
            onSuccess: () => {
                !!refetchNewsFeedData && refetchNewsFeedData();
            },
        });
    };
    const handleLike = () => {
        if (isPostLikedByUser) {
            void unlikePost({
                data: {
                    personId: currentUserId,
                    postId: post.id,
                },
            });
        }
        else {
            void likePost({
                data: {
                    personId: currentUserId,
                    postId: post.id,
                },
            });
        }
    };
    // Handlers
    const handleIconPress = () => {
        setShowPostModal(true);
    };
    const handleEditPost = () => {
        setShowPostModal(false);
        onOpenEditPost();
    };
    const handleDeletePost = () => {
        void deletePost({
            postId: post.id,
        }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadPostsQueryKey(undefined));
                !!refetchNewsFeedData && refetchNewsFeedData();
                toast({
                    status: 'success',
                    title: t(tKeys.validation.feed.delete_message.success),
                });
            },
            onError: () => {
                toast({
                    status: 'error',
                    title: 'An error an error has occurred, please retry.',
                });
            },
        });
    };
    function handleOnPressMember(memberId) {
        push('/profile/other-user-profile', {
            personId: memberId,
        });
    }
    // Return
    return {
        handleIconPress,
        handleDeletePost,
        handleEditPost,
        showPostModal,
        creator,
        isCreatorLoading,
        isPostLikedByUser,
        handleVote,
        isVoteLoading,
        handleLike,
        isDeletePostLoading,
        handleOnPressMember,
        isOpenEditPost,
        onCloseEditPost,
    };
};
export default useLogic;
