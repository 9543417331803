/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Retrieves groups for the company
 */
export const readGroups = (params, options, signal) => {
    return customInstance({ url: `/groups`, method: 'get', params, signal }, options);
};
export const getReadGroupsQueryKey = (params) => [
    `/groups`,
    ...(params ? [params] : []),
];
export const useReadGroups = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadGroupsQueryKey(params);
    const queryFn = ({ signal, }) => readGroups(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a group
 */
export const createGroup = (groupUpsert, options) => {
    return customInstance({
        url: `/groups`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: groupUpsert,
    }, options);
};
export const useCreateGroup = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createGroup(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Update a group
 */
export const updateGroup = (groupUpsert, options) => {
    return customInstance({
        url: `/groups`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: groupUpsert,
    }, options);
};
export const useUpdateGroup = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateGroup(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves groups for the company
 */
export const readGroupDetails = (groupId, params, options, signal) => {
    return customInstance({ url: `/groups/${groupId}`, method: 'get', params, signal }, options);
};
export const getReadGroupDetailsQueryKey = (groupId, params) => [`/groups/${groupId}`, ...(params ? [params] : [])];
export const useReadGroupDetails = (groupId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadGroupDetailsQueryKey(groupId, params);
    const queryFn = ({ signal }) => readGroupDetails(groupId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!groupId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Delete the company group
 */
export const deleteGroup = (groupId, params, options) => {
    return customInstance({ url: `/groups/${groupId}`, method: 'delete', params }, options);
};
export const useDeleteGroup = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { groupId, params } = props ?? {};
        return deleteGroup(groupId, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves groups for the company
 */
export const readGroupsByPerson = (params, options, signal) => {
    return customInstance({ url: `/groups/person`, method: 'get', params, signal }, options);
};
export const getReadGroupsByPersonQueryKey = (params) => [`/groups/person`, ...(params ? [params] : [])];
export const useReadGroupsByPerson = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadGroupsByPersonQueryKey(params);
    const queryFn = ({ signal }) => readGroupsByPerson(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
