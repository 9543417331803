import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../components/content/text';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import MessageBubbleAndAvatar from '../../content/message-bubble-and-avatar';
import useLogic from './logic';
import styles from './styles';
const MessageListItem = ({ allMessages, message, index, companyEnrolements, currentUser, form, }) => {
    // Attributes
    const { t } = useTranslation();
    // Logic
    const { previousUserId, findMember, isCurrentUserMessage } = useLogic({
        allMessages,
        companyEnrolements,
        currentUser,
        form,
        index,
        message,
    });
    // Render
    return (<>
      {message.isDeleted ? (<Flex justify={isCurrentUserMessage(message?.creatorId) ? 'flex-end' : 'flex-start'} style={{
                marginBottom: 8,
                paddingLeft: isCurrentUserMessage(message?.creatorId) ? 0 : 20,
                paddingRight: 20,
            }}>
          <Flex style={styles.deletedContainer}>
            <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText?.value}>
              {isCurrentUserMessage(message?.creatorId)
                ? t(tKeys.messages.removed.placeholder.me)
                : t(tKeys.messages.removed.placeholder.other, {
                    name: findMember(message?.creatorId)?.firstname,
                })}
            </Text>
          </Flex>
        </Flex>) : (<MessageBubbleAndAvatar key={message?.id} findMember={findMember} index={index} message={message} isCurrentUserMessage={isCurrentUserMessage} previousUserId={previousUserId} isLastMessage={index === allMessages?.length - 1} form={form}/>)}
    </>);
};
export default MessageListItem;
