import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: COLORS.White.T500.value,
        fontSize: FONTS.T1.T14Px.Medium500.value.fontSize,
    },
    textContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
export default styles;
