import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.BG.value,
    },
    navigationHeader: {
        alignItems: 'center',
        width: '100%',
    },
    flatList: {
        paddingTop: 12,
    },
});
export default styles;
