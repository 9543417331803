import { useTranslation } from 'react-i18next';
import { useToast } from '../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../contexts/companies';
import { useReadEventTypes, useReadEvents, } from '../../../services/online/calendar/calendar';
import getStaleTime from '../../../utils/getStaleTime';
import { tKeys } from '../../../translations/keys';
import { findClosestEventsFromToday } from '../../../utils/findClosestEventFromToday.web';
import { useMemo } from 'react';
export const useHomepageLogic = () => {
    // Attributes
    const toast = useToast();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const { t } = useTranslation();
    // Queries
    const { data: allEventsTypes } = useReadEventTypes({
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!selectedCompanyId, staleTime: getStaleTime() } });
    const { data: eventData, isLoading: isEventsLoading } = useReadEvents({
        'company-id': selectedCompanyId,
        'is-invited': false,
        'event-types': allEventsTypes?.map(e => e.id),
    }, {
        query: {
            enabled: !!selectedCompanyId &&
                !!selectedEnrolment &&
                !!allEventsTypes?.length,
            onError: () => {
                toast({
                    status: 'error',
                    title: t(tKeys.validation.feed.next_event.error),
                });
            },
        },
    });
    const closestEventFound = useMemo(() => {
        return findClosestEventsFromToday(eventData);
    }, [eventData]);
    // Return
    return {
        closestEvents: closestEventFound,
        isEventsLoading,
    };
};
