import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../../../contexts/companies';
import { useReadCompany, useUpdateCompany, } from '../../../../../services/online/companies/companies';
import { createForm } from '../../../../../utils/formik';
import getLanguageValue from '../../../../../utils/getLanguageValue';
import { validationSchema } from './validations';
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { tKeys } from '../../../../../translations/keys';
const useLogic = () => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { t } = useTranslation();
    const { isOpen: isOpenLanguage, onToggle: onToggleLanguage } = useDisclosure();
    const toast = useToast();
    // Queries
    const { data: companyData } = useReadCompany(selectedCompanyId);
    const { mutateAsync: updateCompany, isLoading: isUpdateCompanyLoading } = useUpdateCompany();
    // Functions
    const handleOnSubmit = async () => {
        await updateCompany({
            data: {
                id: companyData?.id,
                cultureName: getLanguageValue(form?.cultureName?.value),
                ...values,
            },
        }, {
            onSuccess: () => {
                toast({
                    status: 'success',
                    title: t(tKeys.edit_group_settings.success),
                });
            },
            onError: err => {
                console.error('Error when updating a company details', err);
            },
        });
    };
    function handleOnChangeLanguage(value) {
        form?.cultureName?.onChange?.(value);
        onToggleLanguage();
    }
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            addressLine1: companyData?.addressLine1 ?? '',
            addressLine2: companyData?.addressLine2 ?? '',
            city: companyData?.city ?? '',
            country: companyData?.country ?? '',
            cultureName: companyData?.cultureName ?? '',
            legalName: companyData?.legalName ?? '',
            name: companyData?.name ?? '',
            vatId: companyData?.vatId ?? '',
            postalCode: companyData?.postalCode ?? '',
            postMail: companyData?.postMail ?? '',
            state: companyData?.state ?? '',
        },
        onSubmit: handleOnSubmit,
        validationSchema: validationSchema(t),
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    // Return
    return {
        handleOnSubmit: handleSubmit,
        isUpdateCompanyLoading,
        dirty,
        form,
        isOpenLanguage,
        onToggleLanguage,
        handleOnChangeLanguage,
    };
};
export default useLogic;
