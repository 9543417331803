import { useMemo, useCallback } from 'react';
const useLogic = ({ allMessages, companyEnrolements, currentUser, }) => {
    // Attributes
    const previousUserId = useMemo(() => allMessages?.[0]?.creatorId, [allMessages]);
    // Functions
    const findMember = useCallback((id) => companyEnrolements?.items?.find(member => member?.personId === id)
        ?.person, [companyEnrolements]);
    const isCurrentUserMessage = useCallback((id) => currentUser?.person?.id === id, [currentUser]);
    // Return
    return {
        previousUserId,
        findMember,
        isCurrentUserMessage,
    };
};
export default useLogic;
