import React from 'react';
import { View } from 'react-native';
import styles from './style';
const StatusComponent = ({ isOnline, borderRadius, style, }) => {
    if (isOnline === undefined) {
        return <></>;
    }
    return (<View style={[
            styles.status,
            {
                bottom: -4,
                right: borderRadius > 50 ? 0 : -4,
            },
            isOnline ? styles.onlineStatus : styles.offlineStatus,
            style,
        ]}/>);
};
export default StatusComponent;
