import React from 'react';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import { TableContainer, Table, Tbody, VStack, HStack, Box, Stack, } from '@chakra-ui/react';
import COLORS from '../../../../../../constants/colors';
import Text from '../../../../../../components/content/text';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import FONTS from '../../../../../../constants/fonts';
import Button from '../../../../../../components/inputs/button/index.web';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Icon from '../../../../../../components/content/icon';
import Placeholder from '../../../../../../components/utility/placeholder';
import Container from '../../../../../../components/surfaces/container';
import { EditGroup } from '../../../components/modal/edit-group/index.web';
import { useGroupListLogic } from './logic.web';
import { GroupListTableHeader } from './group-list-table-header/index.web';
import { GroupListTableRow } from './group-list-tablet-row/index.web';
const GroupsList = ({ groups, isGroupsLoading, isAdmin, onGroupDelete, refetchGroups, }) => {
    // Attributes
    const { t } = useTranslation();
    const { handleCreateGroupClick, handleEditGroupClick, selectedGroup, upsertGroupDisclosure, } = useGroupListLogic();
    // Render
    return (<VStack width={'100%'} p={'1.25rem'} overflow={'auto'} spacing={'1rem'}>
      <LoadingWrapper isLoading={isGroupsLoading}>
        {groups?.length <= 0 ? (<Placeholder imageType={'monocle-emoji'} title={t(tKeys.groups.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.groups.placeholder.subtitle_admin)
                : t(tKeys.groups.placeholder.subtitle_member)} endEnhancer={isAdmin && (<Box marginTop={'1.25rem'}>
                  <Button kind={Kind.SECONDARY} onPress={handleCreateGroupClick} startEnhancer={() => (<Container marginLeft={8}>
                        <Icon name="add" width={20} height={20} fill={COLORS.White.T500.value}/>
                      </Container>)}>
                    <Stack pl={'.5rem'}>
                      <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                        {t(tKeys.groups.add)}
                      </Text>
                    </Stack>
                  </Button>
                </Box>)}/>) : (<>
            <HStack width={'100%'} justifyContent={'space-between'}>
              <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                {t(tKeys.common.groups)}
              </Text>
              <Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={handleCreateGroupClick}>
                <HStack>
                  <Icon name="add-member" width={26} height={26} fill={'transparent'}/>
                  <Text color={COLORS.White.T500.value}>
                    {t(tKeys.groups.add)}
                  </Text>
                </HStack>
              </Button>
            </HStack>

            <TableContainer borderRadius={'.5rem'} borderWidth={1} width={'100%'} maxHeight={'100%'} overflowY={'auto'} overflowX={'auto'} minHeight={'fit-content'} backgroundColor={COLORS.White.T500.value}>
              <Table>
                <GroupListTableHeader isAdmin={isAdmin}/>
                <Tbody>
                  {/* Groups list */}
                  {groups?.map(group => (<GroupListTableRow key={group.id} group={group} isAdmin={isAdmin} onGroupDelete={onGroupDelete} onGroupEdit={handleEditGroupClick}/>))}
                </Tbody>
              </Table>
            </TableContainer>
          </>)}
      </LoadingWrapper>
      {/* Modals */}
      <EditGroup isOpen={upsertGroupDisclosure.isOpen} onClose={upsertGroupDisclosure.onClose} refetchGroups={refetchGroups} group={selectedGroup}/>
    </VStack>);
};
export default GroupsList;
