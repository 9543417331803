import React from 'react';
import Container from '../../surfaces/container';
import SIZES, { Size } from './size';
import Lottie from 'react-lottie';
import loading from '../../../assets/loading/govrn-loading-lottie.json';
const LoadingAnimation = ({ size = Size.DEFAULT }) => {
    // Attributes
    const { width, height } = SIZES[size];
    // Attributes
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    // Render
    return (<Container style={{
            width,
            height,
        }}>
      <Lottie options={defaultOptions} height={height} width={width}/>
    </Container>);
};
export default LoadingAnimation;
