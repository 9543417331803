import { tKeys } from '../translations/keys';
const getLanguageValue = (label) => {
    switch (label) {
        case 'English':
            return 'en-GB';
        case 'Dutch':
            return 'nl-NL';
        case 'French':
            return 'fr-FR';
        case 'Spanish':
            return 'es-ES';
        default:
            return '';
    }
};
export const getLanguageLabel = (value) => {
    switch (value) {
        case 'en-GB':
            return tKeys.languages.english;
        case 'nl-NL':
            return tKeys.languages.dutch;
        case 'fr-FR':
            return tKeys.languages.french;
        case 'es-ES':
            return tKeys.languages.spanish;
        default:
            return '';
    }
};
export default getLanguageValue;
