import { useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { openCamera, openPicker, } from 'react-native-image-crop-picker';
import RNFetchBlob from 'rn-fetch-blob';
import { useFilePicker } from 'use-file-picker';
import { DocumentTypes } from '../../../../components/surfaces/document-modal/props';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { useCreatePost } from '../../../../services/online/news-feed/news-feed';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import getFileName from '../../../../utils/getFileName';
import handleCameraPermission from '../../../../utils/handleCameraPermission';
import handleFilePermission from '../../../../utils/handleFilePermission';
import imageExtensionsValid from '../../../../utils/imageExtensionsValid';
import { onSelectedFileSuccess } from '../../../../utils/selectFile.web';
import { handlePickFile } from '../../../../utils/uploadFile';
import { validationSchema } from './validations';
const imagePickerOptions = {
    includeBase64: true,
    mediaType: 'photo',
    enableRotationGesture: false,
    compressImageMaxHeight: 750,
    compressImageMaxWidth: 750,
};
const useLogic = ({ refetchNewsFeedData }) => {
    // Attributes
    const { t } = useTranslation();
    const { goBack } = useRouter();
    const toast = useToast();
    const [isAcceptLeavingModalOpen, setIsAcceptLeavingModalOpen] = useState(false);
    const { selectedCompanyId: companyId, me } = useCompaniesContext();
    const [selectedFile, setSelectedFile] = useState();
    // File picker
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: [
            'image/jpeg',
            'image/png',
            'image/gif',
            '.txt',
            '.pdf',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.csv',
        ],
        onFilesSelected: async (chosenDocuments) => {
            await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile);
        },
    });
    const pollOptionInitalState = [
        t(tKeys.common.yes),
        t(tKeys.common.no_opinion),
        t(tKeys.common.no),
    ];
    const initialValues = {
        // Global
        message: '',
        // Image
        imageValue: null,
        // Document
        documentValue: null,
        // Poll
        isPollVisible: false,
        title: '',
        pollOptions: pollOptionInitalState,
    };
    // Queries
    // - Mutations
    const { mutateAsync: createPost, isLoading: isCreatePostLoading } = useCreatePost({
        mutation: {
            retry: 3,
        },
    });
    // Form
    const { values, ...rest } = useFormik({
        initialValues,
        onSubmit: handleOnSubmit,
        validationSchema: validationSchema(t),
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
    });
    const { resetForm, submitForm, errors, setErrors, dirty } = rest;
    const form = createForm(values, rest);
    const displayPollButton = form?.documentValue?.value ||
        form?.imageValue?.value ||
        form.isPollVisible?.value;
    function handleAddFile() {
        openFilePicker();
    }
    function extractBase64FromDataUrl(dataUrl) {
        return dataUrl.split(',')[1];
    }
    // Handle on form submit
    async function handleOnSubmit(values) {
        if (!values.imageValue && !values.documentValue && values.isPollVisible) {
            // Poll post (PostOfQuestions)
            const answers = values.pollOptions?.map(answer => ({
                text: answer,
            }));
            // eslint-disable-next-line array-callback-return
            const filteredAnswer = answers.filter(a => {
                return a.text?.length;
            });
            const question = {
                answers: filteredAnswer,
                text: values.title,
            };
            const dataToSend = {
                content: values.message.replace(/\n+/g, '\n'),
                companyId,
                creatorId: me?.id,
                question,
            };
            handleCreatePost(dataToSend);
            return;
        }
        // If it's only text (Post type)
        if (!values.imageValue && !values.documentValue) {
            const dataToSend = {
                content: values.message.replace(/\n+/g, '\n'),
                companyId,
                creatorId: me?.id,
            };
            handleCreatePost(dataToSend);
            return;
        }
        // If it's a post with image (PostOfDocument type)
        if (values.imageValue) {
            if (Platform.OS === 'web') {
                const dataToSend = {
                    content: values.message.replace(/\n+/g, '\n'),
                    companyId,
                    document: {
                        title: encodeURIComponent(values.imageValue.filename),
                        file: {
                            content: extractBase64FromDataUrl(values.imageValue.data),
                            mimeType: values.imageValue.mime,
                            filename: values.imageValue.filename,
                        },
                    },
                };
                handleCreatePost(dataToSend);
                return;
            }
            const image = values.imageValue;
            const filename = getFileName(image.mime);
            const dataToSend = {
                content: values.message.replace(/\n+/g, '\n'),
                companyId,
                document: {
                    title: encodeURIComponent(filename),
                    file: {
                        content: image?.data,
                        mimeType: image?.mime,
                        filename,
                    },
                },
            };
            handleCreatePost(dataToSend);
            return;
        }
        // Post with a document (PostOfDocument type)
        if (values.documentValue) {
            if (Platform.OS === 'web') {
                const dataToSend = {
                    content: values.message.replace(/\n+/g, '\n'),
                    companyId,
                    creatorId: me?.id,
                    document: {
                        title: encodeURIComponent(values.documentValue.name),
                        file: {
                            content: extractBase64FromDataUrl(values.documentValue.content),
                            filename: values.documentValue.name,
                            mimeType: values.documentValue.type,
                        },
                    },
                };
                handleCreatePost(dataToSend);
                return;
            }
            const document = values.documentValue;
            const path = Platform.OS === 'android'
                ? decodeURIComponent(document?.fileCopyUri)
                : decodeURIComponent(document?.uri.replace('file://', ''));
            const base64 = await RNFetchBlob.fs.readFile(path, 'base64');
            const dataToSend = {
                content: values.message.replace(/\n+/g, '\n'),
                companyId,
                creatorId: me?.id,
                document: {
                    title: encodeURIComponent(document?.name),
                    file: {
                        content: base64,
                        filename: document?.name,
                        mimeType: document?.type,
                    },
                },
            };
            handleCreatePost(dataToSend);
        }
    }
    // Functions
    function onCloseClick() {
        // Show accept leaving modal
        if (dirty) {
            setIsAcceptLeavingModalOpen(true);
            return;
        }
        onAcceptLeaving();
    }
    function onAcceptLeaving() {
        setIsAcceptLeavingModalOpen(false);
        resetForm({
            values: initialValues,
        });
        goBack();
    }
    function onCancelLeaving() {
        setIsAcceptLeavingModalOpen(false);
    }
    function onClickDelete() {
        form?.documentValue?.onChange(null);
        form?.imageValue?.onChange(null);
    }
    // - Handlers when user choose a picture from it's gallery
    // -- Ask user permission to use picture from it's gallery
    async function handleAddPictureFromGallery() {
        const isUserGavePermission = await handleFilePermission();
        if (!isUserGavePermission) {
            return;
        }
        await handleChoosePictureFromGallery();
    }
    function handleOnSelectFile(value) {
        setSelectedFile(value);
    }
    // Handle the selected picture
    async function handleChoosePictureFromGallery() {
        const choosenImage = await openPicker({
            includeBase64: true,
            mediaType: 'photo',
            enableRotationGesture: false,
        });
        if (!choosenImage) {
            return;
        }
        const choosenImageExtension = getFileName(choosenImage.mime)?.split('.')[1];
        // Check the extensions of the choosen file
        if (!imageExtensionsValid?.includes(choosenImageExtension)) {
            toast({
                status: 'error',
                title: t(tKeys.validation.create_post.errors.invalid_file_extension, {
                    extensions: imageExtensionsValid.toString(),
                }),
            });
        }
        form?.imageValue?.onChange(choosenImage);
    }
    // - Handlers when user choose a picture from it's camera
    // -- Ask user permission to use picture from it's camera
    async function handleTakePictureFromCamera() {
        const choosenImage = await openCamera(imagePickerOptions);
        if (!choosenImage) {
            return;
        }
        const choosenImageExtension = getFileName(choosenImage.mime)?.split('.')[1];
        // Check the extensions of the choosen file
        if (!imageExtensionsValid?.includes(choosenImageExtension)) {
            toast({
                status: 'error',
                title: t(tKeys.validation.create_post.errors.invalid_file_extension, {
                    extensions: imageExtensionsValid.toString(),
                }),
            });
        }
        form?.imageValue?.onChange(choosenImage);
    }
    const handleAddPictureFromCamera = async () => {
        const isUserGavePermission = await handleCameraPermission();
        if (!isUserGavePermission) {
            return;
        }
        await handleTakePictureFromCamera();
    };
    // - Handlers when user choose a picture from it's storage to pickup a document
    // -- Ask user permission to use picture from it's storage to pickup a document
    async function handleChooseFileFromDocuments() {
        const choosenDocument = await handlePickFile();
        if (!choosenDocument) {
            return;
        }
        form?.documentValue?.onChange(choosenDocument);
    }
    // Upload file
    async function handleAddFileFromDocuments() {
        const isUserGavePermission = await handleFilePermission();
        if (!isUserGavePermission) {
            return;
        }
        await handleChooseFileFromDocuments();
    }
    function handlePollVisibility() {
        const visibleState = values.isPollVisible;
        form?.isPollVisible?.onChange(!visibleState);
        // Reset poll options when hiding it
        if (visibleState) {
            form?.pollOptions?.onChange(pollOptionInitalState);
        }
    }
    function handleCreatePost(values) {
        if (!values?.content) {
            toast({
                status: 'error',
                title: t(tKeys.validation.create_post.errors.message.required),
            });
            return;
        }
        void createPost({ data: values }, {
            onSuccess: () => {
                void refetchNewsFeedData();
                resetForm();
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.validation.create_post.errors.generic),
                });
                console.error('Error with create a post : ', err);
            },
        });
    }
    // Effects
    // Show toast when form errors occured
    useEffect(() => {
        const errorValues = Object.values(errors);
        if (!errorValues?.length) {
            return;
        }
        toast({
            status: 'error',
            title: errorValues?.[0],
        });
        // Reset errors
        setErrors({});
    }, [errors]);
    useEffect(() => {
        if (!selectedFile)
            return;
        if (selectedFile?.dataType === DocumentTypes.FILE) {
            return form?.documentValue?.onChange?.(selectedFile?.documentMetaData);
        }
        form?.imageValue?.onChange?.(selectedFile?.documentMetaData);
    }, [selectedFile]);
    // Return
    return {
        me,
        isAcceptLeavingModalOpen,
        onCloseClick,
        onAcceptLeaving,
        onCancelLeaving,
        onClickDelete,
        handleAddPictureFromGallery,
        handleAddPictureFromCamera,
        handleAddFileFromDocuments,
        handleOnSubmit: submitForm,
        form,
        isCreatePostLoading,
        handlePollVisibility,
        displayPollButton,
        handleAddFile,
    };
};
export default useLogic;
