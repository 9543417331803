import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { t } from 'i18next';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../../contexts/companies';
import { getReadEventQueryKey, getReadEventsQueryKey, useCreateEvent, useDeleteEvent, useUpdateEvent, } from '../../../../services/online/calendar/calendar';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { useReadGroups } from '../../../../services/online/groups/groups';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import { validationSchema } from './validations';
const useLogic = (onClose, event, onCloseDeleteEvent, additionalFunctionOnCreate) => {
    // Attributes
    const queryClient = useQueryClient();
    const [searchInvitees, setSearchInvitees] = useState('');
    const toast = useToast();
    // Queries
    const { me } = useCompaniesContext();
    const eventDetails = event
        ? {
            endDate: new Date(event?.endDate),
            startDate: new Date(event?.startDate),
            eventType: event?.type,
            inviteesIds: event?.inviteeIds,
            timezoneId: event?.timeZoneId,
            location: event?.location,
            title: event?.name,
            url: event?.locationUrl,
            id: event?.id,
            groupsIds: event?.groupIds,
        }
        : undefined;
    // Form
    const { values, ...rest } = useFormik({
        initialValues: eventDetails ?? {
            title: '',
            startDate: null,
            endDate: null,
            eventType: null,
            inviteesIds: [],
            location: '',
            timezoneId: me?.timeZoneId,
            url: '',
            groupsIds: [],
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        validationSchema: validationSchema(t),
    });
    const { submitForm, resetForm } = rest;
    const form = createForm(values, rest);
    // Queries
    const { selectedCompanyId } = useCompaniesContext();
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': searchInvitees,
    });
    const { data: groupsInTheCompany } = useReadGroups({
        'company-id': selectedCompanyId,
        'search-term': searchInvitees,
    });
    const { mutateAsync: createEvent, isLoading: isCreateLoading } = useCreateEvent();
    const { mutateAsync: updateEvent, isLoading: isUpdateEventLoading } = useUpdateEvent();
    const { mutateAsync: deleteEvent, isLoading: isDeleteLoading } = useDeleteEvent();
    // Functions
    const peopleInTheCompany = useMemo(() => {
        return enrolment?.items?.map(enrolment => enrolment.person);
    }, [enrolment]);
    const [filterDateOpen, setFilterDateOpen] = useState(undefined);
    // Functions
    function handleClose() {
        resetForm();
        onClose();
    }
    async function handleOnSubmit(values) {
        try {
            if (event?.id) {
                // Update Event
                await updateEvent({
                    data: {
                        ...event,
                        startDate: values?.startDate?.toISOString(),
                        endDate: values?.endDate?.toISOString(),
                        location: values?.location,
                        locationUrl: values?.url,
                        timeZoneId: values?.timezoneId,
                        typeId: values?.eventType?.id,
                        inviteeIds: values?.inviteesIds,
                        name: values?.title,
                        groupIds: values?.groupsIds,
                    },
                }, {
                    onSuccess: () => {
                        handleClose();
                        void queryClient.invalidateQueries(getReadEventQueryKey(event?.id));
                        void queryClient.invalidateQueries(getReadEventsQueryKey(null));
                        toast({
                            status: 'success',
                            title: t(tKeys.event.update_event.success),
                        });
                    },
                });
                return;
            }
            await createEvent({
                data: {
                    companyId: selectedCompanyId,
                    startDate: values?.startDate?.toISOString(),
                    endDate: values?.endDate?.toISOString(),
                    location: values?.location,
                    locationUrl: values?.url,
                    timeZoneId: values?.timezoneId,
                    typeId: values?.eventType?.id,
                    inviteeIds: values?.inviteesIds,
                    name: values?.title,
                    groupIds: values?.groupsIds,
                },
            }, {
                onSuccess: () => {
                    handleClose();
                    void queryClient.invalidateQueries(['/calendar/events']);
                    toast({
                        status: 'success',
                        title: t(tKeys.event.create_event.success),
                    });
                    additionalFunctionOnCreate?.();
                },
                onError: err => {
                    toast({
                        status: 'error',
                        title: t(tKeys.profile['user-profile']['update-person'].error),
                    });
                    console.error('Error when create a new event : ', err);
                },
            });
        }
        catch (err) {
            console.error('Error when create an event : ', err);
        }
    }
    function onDeleteEventPress() {
        if (event?.id) {
            void deleteEvent({ eventId: event?.id }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries(['/calendar/events']);
                    handleClose();
                    onCloseDeleteEvent();
                },
            });
        }
    }
    function handleOnChangeSearch(value) {
        setSearchInvitees(value);
    }
    function isInviteeSelected(invitee) {
        return values?.inviteesIds?.some(id => id === invitee?.id);
    }
    function handleOnSelectInvitee(invitee) {
        const inviteesIds = _.clone(values.inviteesIds);
        if (isInviteeSelected(invitee)) {
            const inviteesSelectedFiltered = inviteesIds.filter(id => id !== invitee.id);
            form.inviteesIds.onChange(inviteesSelectedFiltered);
            return;
        }
        inviteesIds.push(invitee.id);
        form.inviteesIds.onChange?.(inviteesIds);
    }
    function isGroupSelected(group) {
        return values?.groupsIds?.some(id => id === group?.id);
    }
    function handleGroupSelect(group) {
        const groupsIds = _.clone(values.groupsIds);
        if (isGroupSelected(group)) {
            const groupsSelectedFiltered = groupsIds.filter(id => id !== group.id);
            form.groupsIds.onChange(groupsSelectedFiltered);
            return;
        }
        groupsIds.push(group.id);
        form.groupsIds.onChange?.(groupsIds);
    }
    const isCreateDisabled = !values.startDate || !values.endDate || !values.eventType || !values.title;
    // Return
    return {
        isEditMode: !!event?.id,
        filterDateOpen,
        setFilterDateOpen,
        form,
        values,
        isCreateLoading,
        isDeleteLoading,
        onDeleteEventPress,
        isUpdateEventLoading,
        handleOnSubmit: submitForm,
        handleOnChangeSearch,
        searchInvitees,
        peopleInTheCompany,
        handleOnSelectInvitee,
        isInviteeSelected,
        isCreateDisabled,
        handleClose,
        groupsInTheCompany,
        handleGroupSelect,
        isGroupSelected,
    };
};
export default useLogic;
