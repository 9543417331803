import React from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { Shape } from '../../../../constants/shape';
import useFormatDate from '../../../../hooks/format-date/useFormatDate';
import { tKeys } from '../../../../translations/keys';
import Text from '../../../content/text';
import { Kind } from '../../../inputs/button/constants/kind';
import { Size } from '../../../inputs/button/constants/size';
import Button from '../../../inputs/button/index.web';
import { Input, InputGroup } from '@chakra-ui/react';
const FilterButton = ({ isFocused, isDirty, onPress, isDisabled, date, dateFormat, showPlaceholder, timezoneId, isHourPicker, onChangeHours, }) => {
    // Attributes
    const { formatDate } = useFormatDate();
    const { t } = useTranslation();
    // Render
    return (<Button kind={isDirty ? Kind.SECONDARY : Kind.WHITE} size={Size.MINI} shape={Shape.ROUND} borderColor={COLORS.Stroke.value} borderWidth={isDirty ? 0 : 1} onPress={onPress} isDisabled={isDisabled} backgroundColorPerso={isFocused && !isDirty ? COLORS.Stroke.value : undefined} minWidth={'6.75rem'}>
      {isHourPicker ? (<InputGroup>
          <Input placeholder={t(tKeys.date.no_date.placeholder)} size="md" isDisabled={isDisabled} textColor={isDirty ? COLORS?.White.T500.value : COLORS?.Text.T400.value} justifyContent={'center'} type="time" value={showPlaceholder
                ? t(tKeys.date.no_date.placeholder)
                : formatDate({
                    date,
                    formatWithTimezone: false,
                    timezoneId,
                    format: dateFormat,
                })} fontSize={12} onChange={e => {
                onChangeHours?.(e.currentTarget.value);
            }} borderWidth={0} _focusVisible={{ borderWidth: 0 }} sx={{
                '&::-webkit-calendar-picker-indicator': {
                    filter: 'invert(100%)',
                    cursor: 'pointer',
                },
            }}/>
        </InputGroup>) : (<Text font={FONTS.T1.T12Px.Regular400.value} color={isDirty ? COLORS?.White.T500.value : COLORS?.Text.T400.value}>
          {showPlaceholder
                ? t(tKeys.date.no_date.placeholder)
                : formatDate({
                    date,
                    formatWithTimezone: false,
                    timezoneId,
                    format: dateFormat,
                })}
        </Text>)}
    </Button>);
};
export default FilterButton;
