/* eslint-disable array-callback-return */
import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../../contexts/companies';
import { useGenerateProxy, useUploadProxy, } from '../../../../services/online/calendar/calendar';
import { tKeys } from '../../../../translations/keys';
import { createForm } from '../../../../utils/formik';
import { extractBase64FromDataUrl, onSelectedFileSuccess, } from '../../../../utils/selectFile.web';
const useLogic = (onClose, proxyEntity, enrolment) => {
    // Attributes
    const queryClient = useQueryClient();
    const toast = useToast();
    const { isOpen: isOpenAssign, onToggle: onToggleAssign } = useDisclosure();
    // Queries
    const { mutateAsync: uploadProxyWithDocument, isLoading: isUploadProxyWithDocumentLoading, } = useUploadProxy();
    const { mutateAsync: uploadProxyWithoutDocument, isLoading: isUploadProxyWithoutDocumentLoading, } = useGenerateProxy();
    const { me } = useCompaniesContext();
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: [
            'image/jpeg',
            'image/png',
            'image/gif',
            '.txt',
            '.pdf',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.csv',
        ],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile),
    });
    // Get people to display
    const peopleInTheCompany = useMemo(() => {
        if (proxyEntity?.inviteePeopleIds) {
            // eslint-disable-next-line array-callback-return
            return enrolment?.items
                ?.map(enrolment => {
                if (enrolment?.person?.id !== me?.id &&
                    proxyEntity.inviteePeopleIds?.includes(enrolment.person?.id)) {
                    return enrolment?.person;
                }
            })
                ?.filter(elt => elt !== undefined);
        }
        return enrolment?.items?.map(enrolment => enrolment.person);
    }, [enrolment, proxyEntity?.inviteePeopleIds]);
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            peopleAssigned: proxyEntity?.peopleAssigned ?? undefined,
            document: proxyEntity?.document ?? undefined,
            eventId: proxyEntity?.eventId,
            inviteePeopleIds: proxyEntity?.inviteePeopleIds,
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Functions
    async function handleOnSubmit() {
        try {
            // if user didn't give a document then a document by default is created using the following call
            if (!form?.document?.value) {
                await uploadProxyWithoutDocument({
                    eventId: proxyEntity?.eventId,
                    params: {
                        'proxy-id': form?.peopleAssigned?.value?.id,
                    },
                }, {
                    onSuccess: () => {
                        void queryClient.invalidateQueries([
                            `/calendar/events/${proxyEntity?.eventId}`,
                        ]);
                        onClose();
                    },
                    onError: () => {
                        toast({
                            status: 'error',
                            title: t(tKeys.validation.common.error_occuried),
                        });
                    },
                });
                return;
            }
            const content = form?.document?.value?.documentMetaData?.mime
                ? extractBase64FromDataUrl(form?.document?.value?.data)
                : form?.document?.value?.data;
            await uploadProxyWithDocument({
                data: {
                    content,
                    filename: form?.document?.value?.documentMetaData?.name ??
                        form?.document?.value?.documentMetaData?.filename,
                    mimeType: form?.document?.value?.documentMetaData?.mime ??
                        form?.document?.value?.documentMetaData?.type,
                },
                eventId: proxyEntity?.eventId,
                params: {
                    'proxy-id': form?.peopleAssigned?.value?.id,
                },
            }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries([
                        `/calendar/events/${proxyEntity?.eventId}`,
                    ]);
                    onClose();
                },
                onError: () => {
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.common.error_occuried),
                    });
                },
            });
        }
        catch (err) {
            console.error('Error when upload a proxy : ', err);
        }
    }
    function handleOnClickNewFile() {
        openFilePicker();
    }
    function handleOnSelectFile(value) {
        form?.document?.onChange(value);
    }
    // Return
    return {
        form,
        dirty,
        handleOnSubmit,
        isUploadProxyLoading: isUploadProxyWithDocumentLoading || isUploadProxyWithoutDocumentLoading,
        peopleInTheCompany,
        handleOnClickNewFile,
        onToggleAssign,
        isOpenAssign,
    };
};
export default useLogic;
