/* eslint-disable @typescript-eslint/no-misused-promises */
import { t } from 'i18next';
import React from 'react';
import AvatarDetails from '../../../../../../components/content/avatar-details';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import Container from '../../../../../../components/surfaces/container';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import { PresenceStatus } from '../../../event-details/types';
import ButtonPresenceStatus from '../../inputs/button-present-status';
import EventDetailsUserStatus from '../event-details-user-status';
import useLogic from './logic';
import styles from './style';
const EventDetailsInviteesPresenceStatus = ({ invitee, handlePresenceStatus, event, }) => {
    // Attributes
    const { getPresenceStatusFromInvitee, isOpen, person, handleOpenState, proxy, inviteeIsCurrentUser, inviteeResponded, } = useLogic(invitee);
    const { colors, iconName, isSelected, title, status } = getPresenceStatusFromInvitee();
    // Render
    return (<Flex style={styles.mainContent} direction="column">
      <Flex justify="space-between" style={styles.avatarButtonContainer}>
        {/* Avatar with picture */}
        <AvatarDetails person={person} avatarSize={32} avatarFont={FONTS.T1.T14Px.SemiBold600.value} showBoardRole subTitleEnhancer={!!invitee?.proxyId && (<Flex spacing={4} align="center">
                <Icon name={'proxy'} width={15} height={15} color={COLORS.InputText.value}/>
                {/* Proxy name */}
                <Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>{`${proxy?.firstname ?? ''} ${proxy?.lastname ?? ''}`}</Text>
              </Flex>)}/>
        {/* Button status */}
        {inviteeIsCurrentUser && inviteeResponded && !invitee?.proxyId ? (<ButtonPresenceStatus title={title} onPress={handleOpenState} iconName={iconName} isSelected={isSelected} colors={colors} isForPresence={isOpen}/>) : (<EventDetailsUserStatus presenceStatus={status}/>)}
      </Flex>

      {/* Title */}
      {isOpen && (<Flex direction="column">
          <Container marginVertical={16} marginHorizontal={12}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.event.presences.change_your_status)}
            </Text>
          </Container>

          <Flex justify="space-between">
            {/* Present */}
            <Container marginHorizontal={4}>
              <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .present)} onPress={async () => {
                await handlePresenceStatus(PresenceStatus?.Present, invitee?.personId);
                handleOpenState();
            }} iconName={'check'} isSelected={true} colors={COLORS.Success.T500.value} buttonSize={Size.COMPACT}/>
            </Container>

            {/* Remote */}
            <Container marginHorizontal={4}>
              <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .remote)} onPress={async () => {
                await handlePresenceStatus(PresenceStatus?.Remote, invitee?.personId);
                handleOpenState();
            }} iconName={'remote'} isSelected={true} colors={COLORS.Warning.T500.value} buttonSize={Size.COMPACT}/>
            </Container>

            {/* Absent */}
            <Container marginHorizontal={4}>
              <ButtonPresenceStatus title={t(tKeys.home.calendar.event_details.information.presence.status
                .absent)} onPress={async () => {
                await handlePresenceStatus(PresenceStatus?.Absent, invitee?.personId);
                handleOpenState();
            }} iconName={'close-cross'} isSelected={true} colors={COLORS.Error.T500.value} buttonSize={Size.COMPACT}/>
            </Container>
          </Flex>
        </Flex>)}
    </Flex>);
};
export default EventDetailsInviteesPresenceStatus;
