import MobilePhone from '.';
import { stackNavigator } from '../../../../routes/navigators';
import verifyMobileCodeUserProfileRoutes from './verify-code/routes';
const profileUserMobilePhoneRoutes = [
    {
        name: '/mobile-phone',
        deepLink: '/mobile-phone',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                tabBarLabel: 'profile.user-profile.myinfo.my-information',
                component: MobilePhone,
            },
            ...verifyMobileCodeUserProfileRoutes,
        ],
    },
];
export default profileUserMobilePhoneRoutes;
