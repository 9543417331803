import { useFormik } from 'formik';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import useRouter from '../../../../../../hooks/router/useRouter';
import { useUpdateEventType } from '../../../../../../services/online/calendar/calendar';
import { createForm } from '../../../../../../utils/formik';
const useLogic = () => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { goBack, params } = useRouter();
    const eventType = params?.eventType;
    const eventTypeTyped = eventType;
    // Queries
    const { mutateAsync: updateEventType, isLoading: isUpdateEventLoading } = useUpdateEventType();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPressButton = async () => {
        if (form?.eventName?.value?.length) {
            await updateEventType({
                data: {
                    companyId: selectedCompanyId,
                    id: eventTypeTyped?.id,
                    name: form?.eventName?.value,
                },
            }, {
                onSuccess: () => {
                    goBack();
                },
                onError: err => console.error('Error when updating event type : ', err),
            });
        }
    };
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            eventName: eventTypeTyped?.name,
        },
        onSubmit: undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressBackButton,
        handleOnPressButton,
        isUpdateEventLoading,
        dirty,
        form,
    };
};
export default useLogic;
