import useRouter from '../../../hooks/router/useRouter';
import { useImpersonateUser } from '../../../services/online/people/people';
import { useEffect } from 'react';
import { saveTokens } from '../../../utils/handleTokens';
const useLogic = () => {
    const { push, params } = useRouter();
    const { mutateAsync: impersonateUserAsync } = useImpersonateUser();
    /* on loading get the userid and token from the folling url
      https://app.govrn.com/impersonate?userId={userid}&token={token}
      */
    const userId = params?.userId;
    const token = params?.token;
    // get the token from the userid using impersonateUserAsync
    // Functions
    useEffect(() => {
        if (userId && token) {
            saveTokens({
                accessToken: token,
                refreshToken: token,
            }).then(() => {
                impersonateUserAsync({
                    params: {
                        userId,
                    },
                }).then(value => {
                    saveTokens({
                        accessToken: value.accessToken,
                        refreshToken: value.refreshToken,
                    }).then(() => {
                        window.location.href = '/';
                        return {};
                    });
                });
            });
        }
        else {
            console.log('No userId or token found');
        }
    }, [userId, token]);
    return {};
};
export default useLogic;
