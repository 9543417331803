import React from 'react';
import FONTS from '../../../constants/fonts';
import Text from '../text/index.web';
import Flex from '../../surfaces/flex';
import COLORS from '../../../constants/colors';
import KINDS from './constants/kind';
import Icon from '../icon';
import { Stack } from '@chakra-ui/react';
const Tag = ({ children, color = COLORS.InputText.value, backgroundColor, height = 27, style: customStyle, font = FONTS.T1.T12Px.Medium500.value, kind = 'SQUARE', width = '100%', hasEndEnhancer, iconName, iconColor, }) => {
    // Attributes
    const style = KINDS[kind];
    // Render
    return (<Flex align="center" justify="center" direction="row" style={[
            style,
            {
                backgroundColor,
                height,
                flex: 'none',
            },
            customStyle,
        ]}>
      {iconName && (<Stack pr={'.25rem'}>
          <Icon name={iconName} color={iconColor} width={16} height={16}/>
        </Stack>)}
      <Stack width={'fit-content'}>
        <Text color={color} font={font} width={'fit-content'}>
          {children}
        </Text>
      </Stack>
      {hasEndEnhancer && (<Icon name="chevron-down" height={24} width={24} fill={'transparent'}/>)}
    </Flex>);
};
export default Tag;
