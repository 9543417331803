import { VStack, HStack, Checkbox } from '@chakra-ui/react';
import { t } from 'i18next';
import SearchInput from '../../../../../../components/inputs/search-input';
import { tKeys } from '../../../../../../translations/keys';
import TagField from '../../inputs/tag-field';
import React from 'react';
import AvatarDetails from '../../../../../../components/content/avatar-details';
import { GroupAvatars } from '../../../../../../components/content/group-avatars/index.web';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
export default function SelectPersonGroupSection({ title, onPersonSelect, peopleInCompany, groupsInCompany, fieldError, onChangeSearchValue, searchValue, isPersonSelected, isGroupSelected, onGroupSelect, isGroupAlreadyInEvent, isPersonAlreadyInEvent, }) {
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'}>
      <TagField title={title} startEnhancer={<Icon name="invitees"/>} endEnhancer={<HStack spacing={'.5rem'}>
            <SearchInput name={'search'} value={searchValue} onChange={onChangeSearchValue} placeholder={t(tKeys.search.placeholder.no_results.title)} style={{
                width: '20rem',
                height: '2.5rem',
            }}/>
          </HStack>} error={fieldError}/>

      {/* Members and groups list */}
      <VStack alignItems={'flex-start'} width={'full'} px={'1.75rem'} maxHeight={'18.75rem'} overflow={'auto'} spacing={'1.375rem'} zIndex={1}>
        {/* Groups list */}
        {!!groupsInCompany && groupsInCompany.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.375rem'}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.common.groups_number, { value: groupsInCompany.length })}
            </Text>
            <VStack alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
              {groupsInCompany.map(group => (<HStack justify="space-between" key={group.id} width={'full'} opacity={isGroupAlreadyInEvent?.(group) ? 0.5 : 1}>
                  <GroupAvatars group={group} showNumberOfMembers/>
                  <Checkbox onChange={() => {
                    onGroupSelect(group);
                }} isChecked={isGroupAlreadyInEvent?.(group) || isGroupSelected(group)} isDisabled={isGroupAlreadyInEvent?.(group)}/>
                </HStack>))}
            </VStack>
          </VStack>)}
        {/* Members list */}
        {!!peopleInCompany && peopleInCompany.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.375rem'}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.common.members_number, {
                value: peopleInCompany.length,
            })}
            </Text>
            <VStack alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
              {peopleInCompany.map(person => (<HStack justify="space-between" key={person.id} width={'full'} opacity={isPersonAlreadyInEvent?.(person) ? 0.5 : 1}>
                  <AvatarDetails person={person} showStatus avatarSize={'2.125rem'} showBoardRole/>
                  <Checkbox onChange={() => {
                    onPersonSelect(person);
                }} isChecked={isPersonAlreadyInEvent?.(person) ||
                    isPersonSelected(person)} isDisabled={isPersonAlreadyInEvent?.(person)}/>
                </HStack>))}
            </VStack>
          </VStack>)}
      </VStack>
    </VStack>);
}
