import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { TouchableOpacity } from 'react-native';
import Text from '../../../../../../components/content/text';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import styles from './style';
const ButtonProgression = ({ maxValue, onPress, value, backgroundColor = COLORS.Govrn.Blue.T100.value, progressionColor = COLORS.Govrn.Blue.T500.value, borderRadius = 8, height = 44, isDisabled, isSelected, title, }) => {
    const percentageProgress = (value / maxValue) * 100;
    const maxPercentageTreshold = 88;
    const springControls = useAnimation();
    const [width, setWidth] = useState(0);
    // Effects
    useEffect(() => {
        if (isSelected) {
            void springControls.start({
                width: percentageProgress,
            });
            setWidth(percentageProgress);
        }
        else {
            void springControls.start({
                width: 0,
            });
            setWidth(0);
        }
    }, [isSelected, percentageProgress, springControls]);
    // Render
    return (<TouchableOpacity onPress={onPress} style={{
            ...styles.mainContainer,
            backgroundColor,
            borderRadius,
            height,
            overflow: 'hidden',
        }} disabled={isDisabled} onLayout={event => {
            const { width } = event.nativeEvent.layout;
            setWidth(width);
        }}>
      {/* Background button */}
      <Flex justify="space-between" style={styles.textContainer} flex={1}>
        {/* Text */}
        <Text font={FONTS?.T1?.T14Px?.Medium500?.value} color={isSelected ? COLORS.White.T500.value : progressionColor} width={'85%'}>
          {title}
        </Text>

        {/* Percentage */}

        <Text font={FONTS?.T1?.T14Px?.Medium500?.value} color={percentageProgress < maxPercentageTreshold
            ? progressionColor
            : COLORS.White.T500.value}>
          {`${value} vote${value > 1 ? 's' : ''}`}
        </Text>
      </Flex>

      {/* Progress bar */}
      <motion.div style={{
            ...styles.progressBar,
            backgroundColor: progressionColor,
            borderRadius,
            height,
            width: `${width}%`,
        }} animate={springControls}/>
    </TouchableOpacity>);
};
export default ButtonProgression;
