import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import { Box, HStack } from '@chakra-ui/react';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../components/content/text';
import { tKeys } from '../../../../../../translations/keys';
const ButtonAddEvent = ({ onClickButtonAddEvent, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Button size={Size.SMALL} kind={Kind.SECONDARY} onPress={onClickButtonAddEvent}>
      <HStack>
        <Box>
          <Icon name="add-event" width={28} height={28} fill={COLORS.White.T500.value}/>
        </Box>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} color="white">
          {t(tKeys.calendar.createEvent.title)}
        </Text>
      </HStack>
    </Button>);
};
export default ButtonAddEvent;
