import { useFocusEffect } from '@react-navigation/native';
import React, { useState } from 'react';
import { useCompaniesContext } from '../../../../../contexts/companies';
import { useReadDocumentTypes, useDeleteDocumentType, } from '../../../../../services/online/repository/repository';
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '../../../../../components/content/use-toast/index.web';
import { tKeys } from '../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
const useLogic = () => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const [selectedFileType, setSelectedFileType] = useState();
    const { isOpen: isOpenFileType, onOpen: onOpenFileType, onClose: onCloseFileType, } = useDisclosure();
    const toast = useToast();
    const { t } = useTranslation();
    // Queries
    const { data: filesType, refetch: refetchFileTypes, isLoading: isFileTypesLoading, } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    });
    const { mutateAsync: deleteFileType } = useDeleteDocumentType();
    // Functions
    const handleOnPressModifyFileType = (fileType) => {
        setSelectedFileType(fileType);
        onOpenFileType();
    };
    const handleOnPressAddFileType = () => {
        setSelectedFileType(undefined);
        onOpenFileType();
    };
    const handleOnPressDeleteFileType = (fileTypeId) => {
        void deleteFileType({
            documentTypeId: fileTypeId,
        }, {
            onSuccess: () => {
                void refetchFileTypes();
                toast({
                    status: 'success',
                    title: t(tKeys.settings.file_item.deleted.success),
                });
            },
            onError: err => {
                toast({
                    status: 'error',
                    title: t(tKeys.validation.common.error_occuried),
                });
                console.error('Error when delete a event type : ', err);
            },
        });
    };
    const handleOnPressEditableMenu = (item) => {
        if (!item.isEditable) {
            return undefined;
        }
        return handleOnPressModifyFileType(item);
    };
    function handleRefetchFilesType() {
        void refetchFileTypes();
    }
    // Effect
    useFocusEffect(React.useCallback(() => {
        void refetchFileTypes();
    }, []));
    // Return
    return {
        handleOnPressEditableMenu,
        handleOnPressDeleteFileType,
        filesType,
        handleOnPressAddFileType,
        isFileTypesLoading,
        selectedFileType,
        isOpenFileType,
        onCloseFileType,
        handleRefetchFilesType,
    };
};
export default useLogic;
