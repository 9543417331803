// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Size = {
    DEFAULT: 'DEFAULT',
    LARGE: 'LARGE',
    EXTRA_LARGE: 'EXTRA_LARGE',
    COMPACT: 'COMPACT',
    MINI: 'MINI',
};
const SIZES = {
    [Size.DEFAULT]: {
        height: 100,
        width: 100,
    },
    [Size.EXTRA_LARGE]: {
        height: 150,
        width: 150,
    },
    [Size.LARGE]: {
        height: 80,
        width: 80,
    },
    [Size.COMPACT]: {
        height: 50,
        width: 50,
    },
    [Size.MINI]: {
        height: 30,
        width: 30,
    },
};
export default SIZES;
