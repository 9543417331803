import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    taskSubtitleContainer: {
        alignContent: 'center',
        alignItems: 'center',
        marginTop: 4,
    },
    separatorContainer: {
        width: 3,
        height: 3,
        borderRadius: 100,
        borderColor: COLORS.InputText.value,
        backgroundColor: COLORS.InputText.value,
    },
    nameContainer: {
        backgroundColor: COLORS.Tag.value,
        borderRadius: 100,
        paddingHorizontal: 8,
        paddingVertical: 4,
        marginRight: 8,
        overflowX: 'scroll',
    },
    poepleContainer: {
        marginTop: 16,
    },
});
export default styles;
