import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        marginHorizontal: 12,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconContainer: { marginRight: 6 },
});
export default styles;
