import React from 'react';
import { View } from 'react-native';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../text';
import styles from './style';
import { HStack, Stack } from '@chakra-ui/react';
const ListItemSeparator = ({ title, maxWidth, }) => {
    // Functions
    function SeparateLine() {
        return <View style={styles.separateLine}/>;
    }
    // Render
    return (<HStack justify="space-evenly" style={styles.listHeaderContainer} maxW={maxWidth}>
      <SeparateLine />

      <Stack px={'1rem'}>
        <Text color={COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value}>
          {title}
        </Text>
      </Stack>

      <SeparateLine />
    </HStack>);
};
export default ListItemSeparator;
