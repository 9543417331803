import React from 'react';
import Container from '../../surfaces/container';
const LineSeparator = ({ type, width, color, }) => {
    switch (type) {
        case 'left':
            return (<Container borderLeftWidth={width} borderLeftColor={color}>
          <></>
        </Container>);
        case 'right':
            return (<Container borderRightWidth={width} borderRightColor={color}>
          <></>
        </Container>);
        case 'top':
            return (<Container borderTopWidth={width} borderTopColor={color}>
          <></>
        </Container>);
        case 'bottom':
            return (<Container borderBottomWidth={width} borderBottomColor={color}>
          <></>
        </Container>);
        default:
            return <></>;
    }
};
export default LineSeparator;
