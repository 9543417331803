import { useDisclosure } from '@chakra-ui/react';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { useReadTasks } from '../../../../../../services/online/tasks/tasks';
import { TaskGroupBy, TaskType } from '../../../../../../services/types';
import { TaskStatus } from '../../../../tasks/types';
const useLogic = (event) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const createTaskEventDetails = { eventId: '' };
    const { isOpen: isOpenCreateTask, onOpen: onOpenCreateTask, onClose: onCloseCreateTask, } = useDisclosure();
    // Queries
    const { data: tasks, isLoading: isTasksLoading } = useReadTasks({
        'company-id': selectedCompanyId,
        status: TaskStatus.Open,
        types: [TaskType[6]],
        // 1 is for grouping by event
        'group-by': TaskGroupBy?.[1],
        'event-id': event?.id,
    }, {
        query: {
            enabled: !!event?.id,
        },
    });
    // Functions
    function handleAddNewTask() {
        // Update entity
        createTaskEventDetails.eventId = event?.id;
        onOpenCreateTask();
    }
    // Return
    return {
        tasks: tasks?.flatMap(t => t?.items)?.filter(i => i?.eventId === event?.id),
        isTasksLoading,
        handleAddNewTask,
        isOpenCreateTask,
        onCloseCreateTask,
    };
};
export default useLogic;
