import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    documentIcon: {
        height: 24,
        width: 24,
        backgroundColor: COLORS.PlaceholderBG.value,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    documentDeleteButton: {
        height: 20,
        width: 20,
        backgroundColor: COLORS.Black.T500.value,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    proxyDocumentContainer: {
        marginVertical: 24,
        marginHorizontal: 12,
    },
    endEnhancer: { marginRight: 0 },
});
export default styles;
