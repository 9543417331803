import ManageFiles from '.';
import { stackNavigator } from '../../../../../routes/navigators';
import AddFileType from './add-file-type';
import ModifyFileType from './modify-file-type';
const manageFilesRoutes = [
    {
        name: '/manage-files',
        deepLink: '/manage-files',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: ManageFiles,
            },
            {
                name: '/add-files-types',
                deepLink: '/add-files-types',
                component: AddFileType,
            },
            {
                name: '/modify-files-types',
                deepLink: '/modify-files-types',
                component: ModifyFileType,
            },
        ],
    },
];
export default manageFilesRoutes;
