import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        marginHorizontal: 12,
    },
    textContainer: {
        margin: 12,
    },
    separator: { height: 16 },
    flatList: { paddingTop: 20 },
});
export default styles;
