import Settings from '.';
import { stackNavigator } from '../../../../routes/navigators';
import manageBoardRolesRoutes from './manage-board-roles/routes';
import CompanyDetails from './manage-company-details';
import manageEventsRoutes from './manage-events/routes';
import manageFilesRoutes from './manage-files/routes';
const settingsRoutes = [
    {
        name: '/settings',
        deepLink: '/settings',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: Settings,
            },
            {
                name: '/manage-company-details',
                deepLink: '/manage-company-details',
                component: CompanyDetails,
            },
            ...manageEventsRoutes,
            ...manageFilesRoutes,
            ...manageBoardRolesRoutes,
        ],
    },
];
export default settingsRoutes;
