import React from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../constants/colors';
import { tKeys } from '../../../translations/keys';
import Flex from '../../surfaces/flex';
import styles, { borderRadiusCurrentUser, borderRadiusOtherUser, } from './style.web';
import Text from '../text';
import Icon from '../icon';
import FONTS from '../../../constants/fonts';
import { Dimensions } from 'react-native';
import { dateFormatFrench } from '../../../constants/date-format';
import useFormatDate from '../../../hooks/format-date/useFormatDate';
import { Box, HStack } from '@chakra-ui/react';
const MessageBubble = ({ content, isCurrentUserMessage, isEphemeral, isFirstMessage, isLastMessage, messageSendDate, isSameUser, ephemeralUntilDate, }) => {
    // Attributes
    const { formatDate } = useFormatDate();
    const { t } = useTranslation();
    const backgroundColor = isCurrentUserMessage
        ? COLORS.Govrn.Blue.T500.value
        : COLORS.Chat.value;
    const textColor = isCurrentUserMessage
        ? COLORS.White.T500.value
        : COLORS.Text.T400.value;
    let borderRadiusType = borderRadiusCurrentUser.single;
    if (isCurrentUserMessage) {
        if (isFirstMessage) {
            borderRadiusType = borderRadiusCurrentUser.first;
        }
        else if (isLastMessage) {
            borderRadiusType = borderRadiusCurrentUser.last;
        }
        else {
            borderRadiusType = borderRadiusCurrentUser.betweenFirstAndLast;
        }
    }
    else {
        if (isFirstMessage) {
            borderRadiusType = borderRadiusOtherUser.first;
        }
        else if (isLastMessage) {
            borderRadiusType = borderRadiusOtherUser.last;
        }
        else {
            borderRadiusType = borderRadiusOtherUser.betweenFirstAndLast;
        }
    }
    if (!content) {
        return <></>;
    }
    // Render
    return (<Box maxWidth={'60%'}>
      <Flex style={{
            ...styles.mainContainer,
            ...borderRadiusType,
            backgroundColor,
            width: '100%',
        }}>
        <Flex flex={1} direction="column" spacing={12} style={{
            width: '100%',
            maxWidth: Dimensions.get('window').width * 0.75,
        }}>
          {/* Text content */}
          <Flex style={styles.textContainer}>
            <Text color={textColor} numberOfLines={10000} font={FONTS.T1.T14Px.Regular400.value}>
              {content}
            </Text>
          </Flex>
          {/* Date send message and ephemeral mode */}
          <HStack justify={'space-between'} width={'full'} pb={'1rem'} px={'1rem'}>
            {/* Send message hour */}
            <Flex justify={isCurrentUserMessage ? 'flex-start' : 'flex-end'} align="center">
              <Text color={isCurrentUserMessage
            ? COLORS.White.T500.value
            : COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value}>
                {formatDate({
            date: messageSendDate,
            formatWithTimezone: true,
            format: 'HH:mm',
        })}
              </Text>
            </Flex>

            {/* Ephemeral icons */}
            {isEphemeral && (<HStack justify="space-between" spacing={'.5rem'}>
                <Text color={isCurrentUserMessage
                ? COLORS.White.T500.value
                : COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value}>{`${t(tKeys.common.until)} ${formatDate({
                date: ephemeralUntilDate,
                formatWithTimezone: true,
                format: dateFormatFrench,
            })}`}</Text>
                <Icon name="ephemeral" width={16} height={16} color={isCurrentUserMessage
                ? COLORS.White.T500.value
                : COLORS.InputText.value}/>
              </HStack>)}
          </HStack>
        </Flex>
      </Flex>
    </Box>);
};
export default MessageBubble;
