import React from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native-safe-area-context';
import List from '../../../../../components/content/list';
import Text from '../../../../../components/content/text';
import Button from '../../../../../components/inputs/button';
import NavigationHeader from '../../../../../components/navigation/navigation-header';
import Flex from '../../../../../components/surfaces/flex';
import Page from '../../../../../components/surfaces/page';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import useLogic from './logic';
import styles from './style';
const KnowledgeBaseFilters = () => {
    // Attributes
    const { t } = useTranslation();
    const { onGoBack, options, selectedLanguage, onSubmitFilters, onSelectLanguage, isSubmitDisabled, } = useLogic();
    return (<Page barStyle="default" backgroundColor={COLORS.White.T500.value}>
      {/* Header */}
      <NavigationHeader onGoBack={onGoBack} leftIconName="close-cross">
        {t(tKeys.common.filters)}
      </NavigationHeader>

      <Flex direction="column" flex={1} flexGrow={1} style={styles.mainContent}>
        <List name={'id'} onChange={onSelectLanguage} value={selectedLanguage} options={options} title={<Text color={COLORS.Text.T400.value} font={FONTS.T1.T14Px.SemiBold600.value}>
              Choose a language
            </Text>}/>
        {/* Apply filter button */}
        <SafeAreaView>
          <Button onPress={onSubmitFilters} isDisabled={isSubmitDisabled}>
            {t(tKeys.home.feed.filter['apply-filter'])}
          </Button>
        </SafeAreaView>
      </Flex>
    </Page>);
};
export default KnowledgeBaseFilters;
