import * as Yup from 'yup';
import { tKeys } from '../../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        addressLine1: Yup.string().required(t(tKeys.validation.company_form.street_name)),
        addressLine2: Yup.string().notRequired(),
        city: Yup.string().required(t(tKeys.validation.company_form.city_name)),
        country: Yup.string().required(t(tKeys.validation.company_form.country)),
        cultureName: Yup.string().required(t(tKeys.validation.company_form.culture_name)),
        legalName: Yup.string().required(t(tKeys.validation.company_form.legal_name)),
        name: Yup.string().required(t(tKeys.validation.common.enter_a_title)),
        postalCode: Yup.string().required(t(tKeys.validation.company_form.zip_code)),
        postMail: Yup.string().required(t(tKeys.validation.enter_valid_email)),
        vatId: Yup.string().required(t(tKeys.validation.company_form.vat_id)),
        state: Yup.string().required(t(tKeys.validation.company_form.state)),
    });
};
