import { t } from 'i18next';
import React from 'react';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import FormControl from '../../../../components/inputs/form-control';
import PhoneInput from '../../../../components/inputs/phone-input/index.web';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import useLogic from './logic.web';
import { boxSectionStyle } from '../my-informations/style.web';
import { Stack } from '@chakra-ui/react';
const MobilePhone = () => {
    // Attributes
    const { handleOnPressButton, isRequestPhoneChangeLoading, currentUserData, form, handleOnCountryChange, goBack, } = useLogic();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={goBack} endEnhancer={<Button onPress={handleOnPressButton} isLoading={isRequestPhoneChangeLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
            {t(tKeys.common.verify)}
          </Button>}>
        {`${t(tKeys.profile['user-profile']['my-information'])} / ${t(tKeys['sign-up'].page['mobile-phone'].label)}`}
      </NavigationHeader>

      <Stack width={'full'} height={'full'} bgColor={COLORS.BG.value} p={'1rem'}>
        {/* Mobile phone */}
        <Stack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile['user-profile'].mobile.form.label)} labelFont={FONTS.T1.T14Px.SemiBold600.value} spacing={4}>
            <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.InputText.value}>{`${t(tKeys.profile['user-profile'].mobile.form['current-phone'])} ${currentUserData?.person?.phone}`}</Text>
            <PhoneInput {...form?.mobilePhone} onCountryChange={handleOnCountryChange} country={form?.country.value} placeholder={t(tKeys.profile['user-profile'].mobile.form['current-phone'])}/>
          </FormControl>
        </Stack>
      </Stack>
    </Page>);
};
export default MobilePhone;
