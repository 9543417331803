import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../contexts/companies';
import { getReadInvitationsQueryKey, useCreateInvitation, useReadBoardRoles, useReadInvitations, } from '../../services/online/enrolments/enrolments';
import { Role } from '../../services/types';
import { tKeys } from '../../translations/keys';
import { createForm } from '../../utils/formik';
import { validationSchema } from './validations';
const useLogic = (onClose, refetchAfterInvite, redirectionAfterInvite) => {
    // Attributes
    const { t } = useTranslation();
    const { selectedCompanyId, me } = useCompaniesContext();
    const queryClient = useQueryClient();
    const toast = useToast();
    const { isOpen: isOpenBoardRole, onToggle: onToggleBoardRole } = useDisclosure();
    // Queries
    const { mutateAsync: createInvitation, isLoading: isAddNewMemberLoading } = useCreateInvitation();
    const { data: invitations } = useReadInvitations({
        'company-id': selectedCompanyId,
    });
    const { data: boardRoles } = useReadBoardRoles({
        'company-id': selectedCompanyId,
    });
    // Functions
    const handleOnSubmit = async () => {
        if (isButtonDisabled)
            return;
        const invitationAlreadyExists = invitations?.find(i => i?.email === form?.newMemberEmail?.value);
        if (invitationAlreadyExists) {
            toast({
                status: 'error',
                title: t(tKeys.add.member_already_exists.invitation.error),
            });
            return;
        }
        try {
            await createInvitation({
                data: {
                    inviterId: me?.id,
                    companyId: selectedCompanyId,
                    email: form?.newMemberEmail?.value,
                    role: form?.newMemberRole?.value,
                    boardRoleId: form?.newBoardRoleId?.value,
                },
            });
            // To refetch the invitations and display the new one on the list
            void queryClient.invalidateQueries(getReadInvitationsQueryKey({
                'company-id': selectedCompanyId,
                'search-term': '',
            }));
            // Refetch all invitees and members after invite someone
            refetchAfterInvite();
            // Reset the form
            resetForm();
            // Close the invite modal
            onClose();
            // Show toast success for the invitation
            toast({
                status: 'success',
                title: t(tKeys.add.member.invitation.success),
            });
            // Redirect to the invitation tab after the invitation to see it
            redirectionAfterInvite(1);
        }
        catch (err) {
            const toastErrorTitle = err?.response?.data?.message?.includes('same email')
                ? t(tKeys.invite.email_already_exist.toast.error)
                : t(tKeys.add.member.invitation.error);
            toast({
                status: 'error',
                title: toastErrorTitle,
            });
            console.log('Error when sending invitation to new member : ', err);
        }
    };
    function handleRoleChange(role) {
        form?.newMemberRole?.onChange?.(role);
    }
    function handleBoardRoleChange(boardRole) {
        form?.newBoardRoleId?.onChange?.(boardRole.id);
        form?.newBoardRoleName?.onChange?.(boardRole.name);
        onToggleBoardRole();
    }
    // Form
    const { values, dirty, setFieldError, ...rest } = useFormik({
        initialValues: {
            newMemberEmail: '',
            newMemberRole: Role?.[2],
            newBoardRoleId: boardRoles?.[0].id,
            newBoardRoleName: boardRoles?.[0].name,
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit, resetForm } = rest;
    const form = createForm(values, rest);
    const isButtonDisabled = isAddNewMemberLoading ||
        !form?.newMemberEmail?.value ||
        form?.newMemberRole?.value === undefined;
    // Return
    return {
        form,
        values,
        handleOnSubmit: handleSubmit,
        isAddNewMemberLoading,
        handleRoleChange,
        isOpenBoardRole,
        onToggleBoardRole,
        handleBoardRoleChange,
    };
};
export default useLogic;
