import { t } from 'i18next';
import React from 'react';
import AvatarMessagePreview from '../../../../../components/content/avatar-message-preview';
import Icon from '../../../../../components/content/icon';
import MenuItem from '../../../../../components/pickers/menu/menu-item';
import LoadingWrapper from '../../../../../components/utility/loading-wrapper';
import { tKeys } from '../../../../../translations/keys';
import { VStack, Text } from '@chakra-ui/react';
const ShareInMessageList = ({ channels, isChannelsLoading, handleOnPress, getParticipant, }) => {
    // Functions
    const ShareInMessageListItem = ({ item, }) => {
        // Render
        return (<MenuItem justifyContentTitle="flex-start" startEnhancer={<AvatarMessagePreview lastMessageDate={''} isLastMessagesUnread={false} isGroupChat={item?.isGroup} conversationName={item?.name} personId={getParticipant(item)} channelId={item?.id} onPress={() => handleOnPress(item)} avatarSize={32}/>} endEnhancer={<Icon name="chevron-right" width={24} height={24} fill={'transparent'}/>} onPress={() => handleOnPress(item)} title={''}/>);
    };
    // Render
    return (<LoadingWrapper isLoading={isChannelsLoading}>
      <VStack alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
        <Text fontWeight={'600'}>{t(tKeys.common.your_conversations)}</Text>
        <VStack alignItems={'flex-start'} width={'full'} spacing={'1rem'}>
          {channels?.map(channel => (<ShareInMessageListItem key={channel.id} item={channel}/>))}
        </VStack>
      </VStack>
    </LoadingWrapper>);
};
export default ShareInMessageList;
