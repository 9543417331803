import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    container: {
        paddingVertical: 24,
        paddingHorizontal: 12,
    },
    selectedTag: {
        backgroundColor: COLORS.Disabled.value,
        borderColor: COLORS.Line.value,
        borderWidth: 1,
        borderRadius: 100,
    },
    endEnhancer: { marginRight: 0 },
    startEnhancer: { marginRight: 12 },
});
export default styles;
