import * as Yup from 'yup';
import { tKeys } from '../../../../../translations/keys';
export const validationSchema = (t) => {
    return Yup.object({
        description: Yup.string().optional().nullable(),
        file: Yup.object().required(),
        fileType: Yup.object().required(t(tKeys.validation.common.select_file_type) ?? ''),
        title: Yup.string().required(t(tKeys.validation.title) ?? ''),
        issuedOn: Yup.string().optional(),
        groupIds: Yup.array().optional(),
        personIds: Yup.array().optional(),
        signedBy: Yup.array().optional(),
    });
};
