import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        color: COLORS.White.T500.value,
        textAlign: 'center',
        paddingLeft: 8,
    },
});
export default styles;
