import { t } from 'i18next';
import React from 'react';
import Card from '../../../../../../../components/surfaces/card/index.web';
import Container from '../../../../../../../components/surfaces/container';
import FONTS from '../../../../../../../constants/fonts';
import NewsFeedPostModal from '../../modal/news-feed-modal/index.web';
import CardFooter from '../card-footer';
import CardHeader from '../card-header/index.web';
import CardPoll from '../card-poll';
import Icon from '../../../../../../../components/content/icon';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
const CardPostPoll = ({ styles, itemPostOfQuestion, currentUserId, index, isNewsFeedDataLoading, handleOnPressMessage, creator, isCreatorLoading, isPostLikedByUser, handleDeletePost, handleDismissModal, handleEditPost, handleIconPress, showPostModal, isVoteLoading, handleVote, handleLike, isDeletePostLoading, onPressMemberName, footerSeparator, handleOnPostPress, isPostDetails, }) => {
    // Attributes
    if (isCreatorLoading) {
        return <></>;
    }
    // Render
    return (<Card isPostDetails={isPostDetails} onPress={() => handleOnPostPress(itemPostOfQuestion?.id)} header={<CardHeader imageUri={creator?.picture?.uri} title={`${creator?.firstname ?? ''} ${creator?.lastname ?? ''}`} titleFont={FONTS.T1.T14Px.SemiBold600.value} subTitle={t(creator?.enrolments[0]?.boardRoleName)} imageHeight={33} imageWidth={33} onPressMemberName={onPressMemberName} memberId={creator?.id} actionButton={currentUserId === creator?.id && (<PopOver from={<Icon name={'navigation-bar-more-default'} width={24} height={24}/>} isPortal={!isPostDetails}>
                <NewsFeedPostModal handleEditPost={handleEditPost} handleDeletePost={handleDeletePost} handleOnDismiss={handleDismissModal} isDeletePostLoading={isDeletePostLoading}/>
              </PopOver>)}/>} footer={<CardFooter date={new Date(itemPostOfQuestion?.createdOn)} currentUserId={currentUserId} postId={itemPostOfQuestion?.id} onPressMessage={handleOnPressMessage} isInsightfulSelected={isPostLikedByUser} handleLikePost={handleLike} footerSeparator={footerSeparator}/>}>
      <Container paddingHorizontal={12}>
        <CardPoll postData={itemPostOfQuestion} handleOnPressPollButton={handleVote} isVoteLoading={isVoteLoading} currentUserId={currentUserId}/>
      </Container>
    </Card>);
};
export default CardPostPoll;
