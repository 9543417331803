import { Container, HStack, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { useCompaniesContext } from '../../../../contexts/companies';
import { ModulesEnum } from '../../../../enum/Modules';
import useRouter from '../../../../hooks/router/useRouter';
import { tKeys } from '../../../../translations/keys';
import getUserName from '../../../../utils/getUserName';
import Icon from '../../../content/icon';
import Image from '../../../content/image';
import Text from '../../../content/text';
import PopOver from '../../../surfaces/pop-over/index.web';
import { CompaniesPopup } from '../companies-popup';
import { useSideBarRoutingLogic } from './logic';
import SideBarItem from './side-bar-Item';
export const SideBarRouting = ({ title, updateCurrentCompany, workspaces, }) => {
    // Attributes
    const { push, getCurrent } = useRouter();
    const { t } = useTranslation();
    const { me } = useCompaniesContext();
    // Logic
    const { countNews, isModuleAvailable, selectedCompanyId, currentCompany, isCompaniesPopupOpen, setIsCompaniesPopupOpen, } = useSideBarRoutingLogic();
    // Render
    return (<VStack 
    // 224 in rem? =
    width={'15rem'} backgroundColor={COLORS.Text.T500.value} alignItems={'flex-start'} justify="space-between" height={'100vh'}>
      <VStack alignItems={'flex-start'} width={'100%'} spacing={'.5rem'}>
        <Stack px={'1.5rem'} py={'.75rem'} width={'100%'} minH={'3.75rem'} justifyContent={'center'} borderBottomWidth={'0.0625rem'} borderColor={'#ffffff1a'}>
          <PopOver from={<HStack spacing={'0.875rem'} width={'100%'}>
                <Image imageURI={currentCompany?.picture?.uri} width={'1.875rem'} height={'1.875rem'} borderRadius={5} resizeMode="cover"/>
                <Text font={FONTS.T1.T14Px.Bold.value} color={COLORS.White.T500.value} numberOfLines={2} maxWidth={'10rem'}>
                  {currentCompany?.name}
                </Text>
                <Icon name="chevron-down" stroke={'#FFF'} width={'1rem'}/>
              </HStack>} placement="bottom-start" isOpen={isCompaniesPopupOpen} isPressable={true} onToggle={() => setIsCompaniesPopupOpen(!isCompaniesPopupOpen)}>
            <CompaniesPopup workspaces={workspaces} selectedCompanyId={selectedCompanyId} updateCurrentCompany={updateCurrentCompany} isPopUpOpen={isCompaniesPopupOpen}/>
          </PopOver>
        </Stack>
        <VStack paddingLeft="2rem" width={'100%'} alignItems={'flex-start'} px={'.5rem'}>
          <SideBarItem onSelect={() => push('/company/home/default', { companyId: selectedCompanyId })} icon={'navigation-bar-home-white'} active={getCurrent() === '/company/home/default'}>
            {t(tKeys.homepage.title)}
          </SideBarItem>
          <SideBarItem icon={'navigation-bar-calendar-white'} active={getCurrent() === '/company/home/calendar/default'} onSelect={() => push('/company/home/calendar', { companyId: selectedCompanyId })} notification={countNews?.eventCount}>
            {t(tKeys.home.navigation_bar.calendar)}
          </SideBarItem>
          <SideBarItem icon={'navigation-bar-documents-white'} active={getCurrent() === '/company/home/more/repository'} onSelect={() => push('/company/home/more/repository', {
            companyId: selectedCompanyId,
        })}>
            {t(tKeys.more.page.menu.repository.menu_title)}
          </SideBarItem>
          <SideBarItem onSelect={() => push('/company/home/tasks', { companyId: selectedCompanyId })} icon={'navigation-bar-tasks-white'} active={getCurrent() === '/company/home/tasks'} notification={countNews?.actionCount}>
            {t(tKeys.page.menu.actions)}
          </SideBarItem>
          <SideBarItem onSelect={() => push('/company/home/feed/default', {
            companyId: selectedCompanyId,
        })} icon={'navigation-bar-home'} active={getCurrent() === '/company/home/feed/default'} notification={countNews?.postCount}>
            {t(tKeys.page.menu['news-feed'])}
          </SideBarItem>

          {isModuleAvailable(ModulesEnum.DISCUSS) && (<SideBarItem onSelect={() => push('/company/home/messages', { companyId: selectedCompanyId })} icon={'navigation-bar-messages-white'} active={getCurrent() === '/company/home/messages/default'} notification={countNews?.messageCount}>
              {t(tKeys.home.messages.navHeader.title)}
            </SideBarItem>)}

          {/* TEMPORARY REMOVED (LUDO DECISION) */}
          {/* {isModuleAvailable(ModulesEnum.KBASE) && (
          <SideBarItem
            icon={'knowledge-base-default'}
            active={getCurrent() === '/company/home/more/knowledge-base'}
            onSelect={() =>
              push('/company/home/more/knowledge-base', {
                companyId: selectedCompanyId,
              })
            }
          >
            {t(tKeys.more.page.menu['knowledge-base'].menu_title)}
          </SideBarItem>
        )} */}

          <SideBarItem icon={'members-white'} active={getCurrent() === '/company/home/more/members'} onSelect={() => push('/company/home/more/members', {
            companyId: selectedCompanyId,
        })}>
            {t(tKeys.more.page.menu.members.menu_title)}
          </SideBarItem>

          {isModuleAvailable(ModulesEnum.AAS) && (<SideBarItem icon={'ai-assist'} active={getCurrent() === '/company/home/ai-assist'} onSelect={() => push('/company/home/ai-assist', {
                companyId: selectedCompanyId,
            })}>
              {t(tKeys.home.navigation_bar.ai_assist)}
            </SideBarItem>)}
        </VStack>
      </VStack>
      <Container padding={0}>
        <VStack w={'100%'} spacing={'.75rem'} borderTopWidth={1} paddingTop={'.75rem'} borderBottomWidth={'0.0625rem'} paddingLeft={'.5rem'} paddingRight={'.5rem'} paddingBottom={'.75rem'} borderColor={'#ffffff1a'}>
          <SideBarItem icon={'settings-white'} active={getCurrent() === '/company/home/more/settings/default'} onSelect={() => push('/company/home/more/settings', {
            companyId: selectedCompanyId,
        })}>
            {t(tKeys.common.settings)}
          </SideBarItem>
          <SideBarItem icon={'help-white'} active={false} onSelect={() => 
        // eslint-disable-next-line no-void
        void Linking.openURL('https://www.govrn.com/r/Fuz')}>
            {t(tKeys.more.page['need-help'].helpdesk)}
          </SideBarItem>
        </VStack>
        <VStack w={'100%'} spacing={'.5rem'} paddingTop={'.75rem'} borderBottomWidth={'0.0625rem'} borderColor={'#ffffff1a'}>
          <Container padding={'0 0.75rem 0 0.5rem'}>
            <SideBarItem image={me.picture?.uri} avatar={true} active={getCurrent() === '/company/profile/user-profile/default'} onSelect={() => push('/company/profile/user-profile/default', {})}>
              {getUserName(me)}
            </SideBarItem>
          </Container>

          <VStack bgColor={COLORS.Text.T900.value} width={'100%'} style={{
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
        }}>
            <SideBarItem image={require('../../../../assets/images/govrn.png')} endEnhancer={<Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.Medium500.value}>
                  11.1
                </Text>} style={{
            width: '100%',
        }}/>
          </VStack>
        </VStack>
      </Container>
    </VStack>);
};
