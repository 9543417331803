import { useReadMe } from '../../services/online/people/people';
import moment from 'moment-timezone';
import { getFullFormatDate } from '../../constants/date-format';
const useFormatDate = () => {
    // Query
    const { data: me } = useReadMe();
    // Functions
    function formatDate({ date, formatWithTimezone, timezoneId, language, format, }) {
        let dateValue = date;
        if (typeof date === 'string') {
            dateValue = new Date(date);
        }
        if (formatWithTimezone) {
            return moment(dateValue)
                .tz(timezoneId ?? me?.person?.timeZoneId)
                .format(format ?? getFullFormatDate(language));
        }
        return moment(dateValue).format(format ?? getFullFormatDate(language));
    }
    function formatDateTime({ date, formatWithTimezone, timezoneId, language, format, }) {
        let dateValue = date;
        if (typeof date === 'string') {
            dateValue = new Date(date);
        }
        if (format === undefined) {
            const dateString = formatDate({
                date: dateValue,
                formatWithTimezone,
                timezoneId,
                language,
                format,
            });
            const timeString = formatDate({
                date: dateValue,
                formatWithTimezone,
                timezoneId,
                language,
                format: 'HH:mm',
            });
            return `${dateString} - ${timeString}`;
        }
        return formatDate({
            date: dateValue,
            formatWithTimezone,
            timezoneId,
            language,
            format,
        });
    }
    return { formatDate, formatDateTime };
};
export default useFormatDate;
