import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        paddingTop: 9,
        minHeight: 320,
        flex: 1,
        flexGrow: 1,
        backgroundColor: COLORS.White.T500.value,
    },
    titleContainer: {
        paddingLeft: 12,
    },
    documentIcon: {
        height: 24,
        width: 24,
        backgroundColor: COLORS.PlaceholderBG.value,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    documentDeleteButton: {
        height: 20,
        width: 20,
        backgroundColor: COLORS.Black.T500.value,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    documentNameContainer: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    documentContainer: {
        marginVertical: 6,
        marginHorizontal: 8,
    },
});
export default styles;
