import React from 'react';
import { HStack, Switch, VStack } from '@chakra-ui/react';
import Text from '../../../../../components/content/text';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import Button from '../../../../../components/inputs/button';
import Input from '../../../../../components/inputs/input/index.web';
export const VotingCollapse = ({ isEditMode, form, isAnonymousDisabled, showTerminateBtn, isCloseQuestionLoading, isDeleteQuestionLoading, isOpenQuestionLoading, onVoteDelete, onVoteOpen, onVoteTerminate, isQuestionClosed, showDeleteVotingBtn, isAdmin, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.25rem'} py={'1.25rem'} px={'1rem'}>
      <HStack width={'100%'} justifyContent={'space-between'} spacing={'1.25rem'}>
        <VStack alignItems={'flex-start'} spacing={'.25rem'} width={'75%'}>
          <Text color={COLORS.Text.T500.value} font={FONTS.T1.T14Px.Medium500.value}>
            {t(tKeys.common.voting)}
          </Text>
          <Text color={COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value} numberOfLines={4}>
            {t(tKeys.calendar.agenda.item.question.subtitle)}
          </Text>
        </VStack>
        {isAdmin && (<>
            {isEditMode ? (<HStack width={'full'} spacing={'.75rem'} justifyContent={'flex-end'}>
                {showTerminateBtn && (<>
                    {isQuestionClosed ? (<Button onPress={onVoteOpen} isLoading={isOpenQuestionLoading} isDisabled={isOpenQuestionLoading}>
                        <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.White.T500.value}>
                          {t(tKeys.calendar.agenda.item.reopenVote)}
                        </Text>
                      </Button>) : (<Button onPress={onVoteTerminate} isLoading={isCloseQuestionLoading} isDisabled={isCloseQuestionLoading}>
                        <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.White.T500.value}>
                          {t(tKeys.calendar.agenda.item.terminateVote)}
                        </Text>
                      </Button>)}
                  </>)}
                {showDeleteVotingBtn && (<Button kind={Kind.OUTLINE} onPress={onVoteDelete} isLoading={isDeleteQuestionLoading} isDisabled={isDeleteQuestionLoading}>
                    <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                      {t(tKeys.calendar.agenda.item.deleteVoting)}
                    </Text>
                  </Button>)}
              </HStack>) : (<Button kind={Kind.OUTLINE} onPress={onVoteDelete}>
                {t(tKeys.calendar.agenda.item.deleteVoting)}
              </Button>)}
          </>)}
      </HStack>

      <Input value={form?.question?.value} onChange={e => {
            form?.question?.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.question.placeholder) ?? ''} isReadOnly={!isAdmin} autoFocus/>

      <VStack alignItems={'flex-start'} width={'full'} spacing={'0.75rem'}>
        <HStack spacing={'.75rem'} width={'full'} justifyContent={'space-between'}>
          <Text font={FONTS.T1.T14Px.SemiBold600.value}>
            {t(tKeys.calendar.agenda.item.isAnonymous.title)}
          </Text>
          <Switch disabled={isAnonymousDisabled} isReadOnly={!isAdmin} isChecked={form?.isAnonymous?.value} onChange={() => form?.isAnonymous?.onChange(!form?.isAnonymous?.value)}/>
        </HStack>
        <Text color={COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value} numberOfLines={undefined}>
          {t(tKeys.calendar.agenda.item.isAnonymous.subtitle)}
        </Text>
      </VStack>
    </VStack>);
};
