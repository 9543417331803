/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Generates the generic form with the keys of the field used
export function createForm(values, rest) {
    // All keys of values
    const keys = Object.keys(values);
    // Destruct
    const { touched, errors, setFieldValue, submitForm } = rest;
    // Generate Form
    const form = { submitForm };
    keys.forEach(key => {
        form[key] = createFormField(values, touched, errors, setFieldValue, key);
    });
    return form;
}
// Generates inputFieldForms
function createFormField(values, touched, errors, setFieldValue, name) {
    // Generate field
    const field = {
        name,
        value: values[name],
        meta: {
            touched: touched[name],
            error: errors[name],
        },
        onChange: (value) => setFieldValue(name, value),
    };
    return field;
}
