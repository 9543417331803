import * as React from 'react';
import Svg, { Path, G, Defs, Rect } from 'react-native-svg';
const ChevronLeftDefault = (props) => (<Svg width={props.width ?? 14} height={props.height ?? 14} fill={props.fill ?? 'none'} {...props}>
    <G clip-path="url(#clip0_3290_13357)">
      <Path fillRule="evenodd" clipRule="evenodd" d="M11 12.534C11.0031 12.1492 10.8602 11.7775 10.6 11.494L6.34802 7.00001L10.6 2.50601C10.8605 2.22331 11.0052 1.85295 11.0052 1.46851C11.0052 1.08408 10.8605 0.713715 10.6 0.431013C10.4754 0.295063 10.3239 0.186509 10.1551 0.112246C9.98632 0.0379831 9.80393 -0.000366211 9.61952 -0.000366211C9.43511 -0.000366211 9.25271 0.0379831 9.08392 0.112246C8.91512 0.186509 8.76361 0.295063 8.63902 0.431013L3.40702 5.96301C3.14541 6.24457 3 6.61468 3 6.99901C3 7.38335 3.14541 7.75346 3.40702 8.03501L8.63402 13.569C8.75861 13.705 8.91012 13.8135 9.07892 13.8878C9.24771 13.962 9.43011 14.0004 9.61452 14.0004C9.79893 14.0004 9.98132 13.962 10.1501 13.8878C10.3189 13.8135 10.4704 13.705 10.595 13.569C10.8549 13.2873 10.9979 12.9173 10.995 12.534" fill={props.fill ?? 'black'}/>
    </G>
    <Defs>
      <clipPath id="clip0_3290_13357">
        <Rect width={props.height ?? 14} height={props.height ?? 14} fill={props.fill ?? 'white'}/>
      </clipPath>
    </Defs>
  </Svg>);
export default ChevronLeftDefault;
