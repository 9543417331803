import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingWrapper from '../../components/utility/loading-wrapper';
import { tKeys } from '../../translations/keys';
import { getPostType } from '../../utils/getPostType';
import FeedListItem from '../home/feed/components/content/news-feed-list-item/index.web';
import useLogic from './logic.web';
import Modal from '../../components/surfaces/modal/index.web';
import ShareInMessages from '../share-in-messages/index.web';
const PostDetails = ({ isOpen, onClose, postId }) => {
    // Attributes
    const { t } = useTranslation();
    const { post, handleOnPressMessage, currentUser, companyData, selectedEnrolment, isLoading, handleOnCloseCreateMessage, isOpenShareInMessage, onCloseShareInMessages, shareInMessageEntity, } = useLogic(postId, onClose);
    // Render
    return (<Modal size={'2xl'} hasFooter={false} title={t(tKeys.common.post)} hasCloseButton isOpen={isOpen} onClose={onClose}>
      <LoadingWrapper isLoading={isLoading}>
        <FeedListItem isPostDetails item={post} index={0} getPostType={getPostType} currentUserId={currentUser?.person?.id} handleOnPressMessage={handleOnPressMessage} companyData={companyData} selectedEnrolment={selectedEnrolment} isNewsFeedDataLoading={isLoading} refetchNewsFeedData={undefined} key={0} footerSeparator/>
      </LoadingWrapper>
      {/* Modals */}
      <ShareInMessages isOpen={isOpenShareInMessage} onClose={onCloseShareInMessages} onCloseCreateMessage={handleOnCloseCreateMessage} shareInMessageEntity={shareInMessageEntity}/>
    </Modal>);
};
export default PostDetails;
