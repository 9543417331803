import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
    },
    footerVersion: {
        color: COLORS.InputText.value,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statusBar: {},
    verificationCodeContainer: {
        alignItems: 'stretch',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },
    inputContainer: {
        position: 'absolute',
        margin: 0,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    textInputStyle: {
        flex: 1,
        opacity: 0,
    },
    separatorLine: {
        flexDirection: 'row',
        marginVertical: 8,
        marginHorizontal: 6,
        height: 30,
    },
    safeAreaViewContainer: {
        flex: 1,
        flexGrow: 1,
    },
});
export default styles;
