import { useEffect, useState } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies/index.web';
import useRouter from '../../../../hooks/router/useRouter';
export const useSideBarRoutingLogic = () => {
    // Attributes
    const { selectedCompanyId, modules, companyNews, allUserCompanies } = useCompaniesContext();
    const [currentCompany, setCurrentCompany] = useState();
    const [isCompaniesPopupOpen, setIsCompaniesPopupOpen] = useState(false);
    const { replace } = useRouter();
    useEffect(() => {
        setCurrentCompany(allUserCompanies?.find(company => company.id === selectedCompanyId));
    }, [selectedCompanyId]);
    // Functions
    function isModuleAvailable(value) {
        return modules?.some(m => m.name === value);
    }
    function handleOnClickProfile() {
        replace('/company/profile', { companyId: selectedCompanyId });
    }
    // Return
    return {
        countNews: companyNews,
        isModuleAvailable,
        selectedCompanyId,
        currentCompany,
        handleOnClickProfile,
        isCompaniesPopupOpen,
        setIsCompaniesPopupOpen,
    };
};
