import React from 'react';
import useLogic from './logic';
import { Box, VStack } from '@chakra-ui/react';
import COLORS from '../../../../constants/colors';
const SelectDocumentLanguages = ({ onSelectLanguage, selectedLanguage, optionsOverride, }) => {
    // Attributes
    const { options } = useLogic();
    function isSelected(language) {
        return language === selectedLanguage;
    }
    const items = optionsOverride ?? options;
    return (<VStack width={'full'} alignItems={'flex-start'} overflow={'hidden'} p=".5rem">
      {items.map(option => (<Box cursor="pointer" key={option.id} onClick={() => onSelectLanguage(option.id)} bgColor={isSelected(option.id)
                ? COLORS.BG.value
                : COLORS.White.T500.value} width={'full'} borderRadius={'.5rem'} p={'.5rem'}>
          <>{option?.children}</>
        </Box>))}
    </VStack>);
};
export default SelectDocumentLanguages;
