import { useUpdateEvent, useUpdateOccurrenceAttendee, } from '../../../../services/online/calendar/calendar';
import { PresenceStatus } from '../event-details/types';
import { useDisclosure } from '@chakra-ui/react';
import { useReadPerson } from '../../../../services/online/people/people';
import { useCompaniesContext } from '../../../../contexts/companies/index.web';
export const useLogic = (event, refetchEvent, canModifyInviteePresence, proxyPersonId) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { isOpen: isOpenRemove, onClose: onCloseRemove, onOpen: onOpenRemove, } = useDisclosure();
    // Queries
    const { mutateAsync: updateEvent } = useUpdateEvent();
    const { mutateAsync: updatePresence } = useUpdateOccurrenceAttendee();
    const { isOpen: isOpenPopover, onToggle: onTogglePopover } = useDisclosure();
    const { data: proxyPerson } = useReadPerson(proxyPersonId, { 'company-id': selectedCompanyId }, {
        query: { enabled: !!proxyPersonId },
    });
    // Functions
    async function handleRemoveInvitee(inviteeId) {
        const newInviteeIds = event?.inviteeIds.filter(eventInviteeId => eventInviteeId !== inviteeId);
        await updateEvent({
            data: {
                ...event,
                inviteeIds: newInviteeIds,
            },
        }, {
            onSuccess: () => {
                void refetchEvent();
            },
        });
    }
    function handleOnToggle() {
        if (!canModifyInviteePresence)
            return;
        onTogglePopover();
    }
    async function handlePresenceStatus(presenceStatus, inviteeId) {
        try {
            const data = {};
            switch (presenceStatus) {
                case PresenceStatus.Present:
                    data.isPresent = true;
                    data.isInRemote = false;
                    break;
                case PresenceStatus.Absent:
                    data.isPresent = false;
                    data.isInRemote = false;
                    break;
                case PresenceStatus.Remote:
                    data.isPresent = true;
                    data.isInRemote = true;
                    break;
            }
            await updatePresence({
                eventId: event?.id,
                personId: inviteeId,
                data,
            });
        }
        finally {
            refetchEvent();
        }
    }
    // Return
    return {
        handlePresenceStatus,
        isOpenPopover,
        handleOnToggle,
        proxyPerson,
        handleRemoveInvitee,
        isOpenRemove,
        onCloseRemove,
        onOpenRemove,
    };
};
