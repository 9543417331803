import React from 'react';
import Avatar from '../../../../../components/content/avatar';
import Text from '../../../../../components/content/text';
import COLORS from '../../../../../constants/colors';
import { Stack, VStack } from '@chakra-ui/react';
import Icon from '../../../../../components/content/icon';
const ProfileHeader = ({ profileImageUri, children, profileName, isUpdatePersonLoading, isLocalImage, backgroundColor = COLORS.Line.value, onPressPicture, }) => {
    // Functions
    const renderChildren = () => {
        if (typeof children === 'string') {
            return <Text>{children}</Text>;
        }
        return children;
    };
    // Render
    return (<VStack justify="center" align="center" width={'full'} bgColor={COLORS.White.T500.value} p={'1rem'} borderRadius={'.5rem'} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'}>
      {/* Profile picture */}
      <Stack position={'relative'}>
        <Avatar imageSource={profileImageUri} name={profileName} width={95.33} height={92.88} borderRadius={100} isLoading={isUpdatePersonLoading} isOnline={undefined} isLocalImage={isLocalImage} backgroundColor={backgroundColor}/>
        {onPressPicture && (<Stack position={'absolute'} bottom={0} right={0} borderRadius={'100rem'} backgroundColor={COLORS.Text.T500.value} p={'.5rem'} cursor={'pointer'} onClick={onPressPicture}>
            <Icon name="editable-small" width={16} height={16} stroke={COLORS.White.T500.value}/>
          </Stack>)}
      </Stack>

      {/* Children */}
      <>{renderChildren()}</>
    </VStack>);
};
export default ProfileHeader;
