import moment from 'moment';
import { getApplicationLanguageFromStorage } from './handleStorageApplicationLanguage';
import 'moment/min/locales';
const handleMomentLanguage = async (language) => {
    const languageApp = await getApplicationLanguageFromStorage();
    switch (languageApp || language) {
        case 'fr': {
            moment.locale('fr');
            break;
        }
        case 'nl': {
            moment.locale('nl');
            break;
        }
        case 'es': {
            moment.locale('es');
            break;
        }
        default:
            moment.locale('en');
    }
};
export default handleMomentLanguage;
