import React from 'react';
import { View } from 'react-native';
import Avatar from '../../../../../../components/content/avatar';
import MessageBubble from '../../../../../../components/content/message-bubble';
import Checkbox from '../../../../../../components/inputs/checkbox';
import Container from '../../../../../../components/surfaces/container';
import Flex from '../../../../../../components/surfaces/flex';
import FONTS from '../../../../../../constants/fonts';
import getUserName from '../../../../../../utils/getUserName';
import SharedContentInPrivate from '../../surfaces/shared-content-in-private';
import useLogic from './logic';
import styles from './style';
import { Image } from '@chakra-ui/react';
const MessageBubbleAndAvatar = ({ findMember, index, isCurrentUserMessage, message, previousUserId, isLastMessage, form, }) => {
    // Attributes
    const { isSelected, onSelectMessage, eventData, documentRepositoryData, isImage, } = useLogic(form, message);
    const member = findMember(message?.creatorId);
    // Render
    return (<Flex justify={isCurrentUserMessage(message?.creatorId) ? 'flex-end' : 'flex-start'} direction="column" style={{ paddingHorizontal: 20 }}>
      {isImage ? (<Container marginLeft={isCurrentUserMessage(message?.creatorId) ? '40%' : '10%'} marginRight={isCurrentUserMessage(message?.creatorId) ? 0 : '30%'}>
          <Image src={message?.file?.uri} borderRadius={'1rem'} objectFit={'contain'} alignSelf={isCurrentUserMessage(message?.creatorId)
                ? 'flex-end'
                : 'flex-start'} maxHeight={'15.625rem'}/>
        </Container>) : null}

      <Flex justify={isCurrentUserMessage(message?.creatorId) ? 'flex-end' : 'flex-start'} style={styles.shareContentContainer}>
        {!isImage ? (<SharedContentInPrivate eventData={eventData} documentRepositoryData={documentRepositoryData} isCurrentUserMessage={isCurrentUserMessage} file={message?.file} postId={message?.postId}/>) : null}
      </Flex>

      {message?.content !== null || !message?.content?.length ? (<Container marginBottom={isLastMessage ? 12 : 4} key={message?.id}>
          <Flex spacing={4} justify={isCurrentUserMessage(message?.creatorId)
                ? 'flex-end'
                : 'flex-start'} style={{
                width: '100%',
            }}>
            <Flex align="flex-end" style={styles.messageBubbleContainer}>
              {!isCurrentUserMessage(message?.creatorId) &&
                isLastMessage &&
                !message.isDeleted &&
                message?.content ? (<Avatar name={getUserName(member)} width={'1.875rem'} height={'1.875rem'} imageSource={member?.picture?.uri} placeholderFont={FONTS.T1.T12Px.SemiBold600.value}/>) : (<View style={styles.hiddenAvatarContainer}/>)}
            </Flex>

            <MessageBubble content={message?.content} messageSendDate={message?.createdOn} isCurrentUserMessage={isCurrentUserMessage(message?.creatorId)} isFirstMessage={index === 0} isLastMessage={isLastMessage} isSameUser={previousUserId === message?.creatorId} isEphemeral={message?.isEphemeral} ephemeralUntilDate={message?.ephemeralDate}/>

            {form.isDeleteMode.value &&
                !message?.isDeleted &&
                isCurrentUserMessage(message?.creatorId) && (<Flex align="flex-end" style={{
                    marginLeft: 4,
                }}>
                  <Checkbox name={''} value={isSelected} onChange={onSelectMessage}/>
                </Flex>)}
          </Flex>
        </Container>) : null}
    </Flex>);
};
export default MessageBubbleAndAvatar;
