import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: { flex: 1, flexGrow: 1, paddingBottom: 40 },
    fieldContainer: {
        paddingHorizontal: 12,
        paddingVertical: 24,
    },
    contentContainer: {
        paddingBottom: 32,
    },
    filterDateContainer: { marginTop: 16 },
    transparentInput: {
        borderWidth: 0,
        padding: 0,
        marginLeft: 0,
        marginVertical: 0,
        borderRadius: 0,
        marginTop: 0,
    },
});
export default styles;
