import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../components/content/list';
import Text from '../../components/content/text';
import FONTS from '../../constants/fonts';
import { VStack } from '@chakra-ui/react';
import COLORS from '../../constants/colors';
const SelectFileType = ({ onChange, selectedDocumentTypeId, documentTypes, }) => {
    // Attributes
    const { t } = useTranslation();
    // Functions
    const onPress = (fileTypeId) => {
        const documentType = documentTypes.find(documentType => documentType?.id === fileTypeId);
        onChange(documentType);
    };
    const options = useMemo(() => {
        if (!documentTypes) {
            return undefined;
        }
        return documentTypes?.map(documentType => {
            return {
                id: documentType?.id,
                children: (<Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
            {t(documentType?.name)}
          </Text>),
            };
        });
    }, [documentTypes]);
    // Render
    return (<VStack px={'1rem'} minWidth={'15rem'} maxHeight={'20rem'} overflow={'auto'}>
      <List name={'id'} value={selectedDocumentTypeId} onChange={onPress} options={options}/>
    </VStack>);
};
export default SelectFileType;
