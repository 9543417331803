import EmailAddress from '.';
import { stackNavigator } from '../../../../routes/navigators';
import verifyCodeUserProfileRoutes from './verify-code/routes';
const profileUserEmailAddressesRoutes = [
    {
        name: '/email-address',
        deepLink: '/email-address',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                tabBarLabel: 'profile.user-profile.myinfo.my-information',
                component: EmailAddress,
            },
            ...verifyCodeUserProfileRoutes,
        ],
    },
];
export default profileUserEmailAddressesRoutes;
