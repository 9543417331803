import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
export const useGroupListLogic = () => {
    // Attributes
    const upsertGroupDisclosure = useDisclosure();
    const [selectedGroup, setSelectedGroup] = useState();
    // Functions
    function handleEditGroupClick(group) {
        setSelectedGroup(group);
        upsertGroupDisclosure.onOpen();
    }
    function handleCreateGroupClick() {
        setSelectedGroup(undefined);
        upsertGroupDisclosure.onOpen();
    }
    // Return
    return {
        upsertGroupDisclosure,
        handleEditGroupClick,
        handleCreateGroupClick,
        selectedGroup,
    };
};
