import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../components/content/icon';
import Text from '../../../../../../../components/content/text';
import Container from '../../../../../../../components/surfaces/container';
import LoadingAnimation from '../../../../../../../components/utility/loading';
import { Size } from '../../../../../../../components/utility/loading/size';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
import styles from './style';
import { Button, HStack, VStack } from '@chakra-ui/react';
const NewsFeedPostModal = ({ handleEditPost, handleDeletePost, isDeletePostLoading, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<VStack align={'baseline'} minW={'17.5rem'}>
      {/* Edit post */}
      <Button onClick={handleEditPost} variant={'ghost'} width={'full'} justifyContent={'space-between'}>
        <HStack justify={'space-between'}>
          <Icon name="edit" width={30} height={30} fill={'transparent'}/>
          <Text font={FONTS.T1.T14Px.Medium500.value}>
            {t(tKeys.news_feed.edit_post)}
          </Text>
        </HStack>
      </Button>

      <Button onClick={!isDeletePostLoading && handleDeletePost} variant={'ghost'} width={'full'} justifyContent={'space-between'}>
        <HStack justify={'space-between'}>
          {isDeletePostLoading ? (<Container style={styles.iconDeleteContainer}>
              <LoadingAnimation size={Size.MINI}/>
            </Container>) : (<Icon name="delete-bin" width={30} height={30}/>)}
          <Text font={FONTS.T1.T14Px.Medium500.value} color={isDeletePostLoading
            ? COLORS.InputText.value
            : COLORS.Text.T800.value}>
            {t(tKeys.news_feed.delete_post.title)}
          </Text>
        </HStack>
      </Button>
    </VStack>);
};
export default NewsFeedPostModal;
