import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';
const QuestionMark = (props) => (<Svg {...props}>
    <G clipPath="url(#a)" stroke="#393360" strokeWidth={1.667} strokeLinecap="round" strokeLinejoin="round">
      <Path d="M10 18.333a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667Zm0-4.167h.008"/>
      <Path d="M7.575 7.5a2.5 2.5 0 0 1 4.858.833c0 1.667-2.5 2.5-2.5 2.5"/>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h20v20H0z"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default QuestionMark;
