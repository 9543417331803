import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../components/content/text';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size';
import FormControl from '../../../../../components/inputs/form-control';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import { getLanguageLabel } from '../../../../../utils/getLanguageValue';
import useLogic from './logic.web';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import TagField from '../../../calendar/components/inputs/tag-field/index.web';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../../../calendar/components/inputs/tag-field/tag-item/index.web';
import SelectDocumentLanguages from '../../../calendar/document-language/index.web';
import Input from '../../../../../components/inputs/input/index.web';
const CompanyDetails = () => {
    // Attributes
    const { handleOnSubmit, isUpdateCompanyLoading, form, isOpenLanguage, onToggleLanguage, handleOnChangeLanguage, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<HStack width={'full'} justifyContent={'space-between'}>
      <VStack alignItems={'flex-start'} height={'full'} width={'30%'} spacing={'1.25rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.settings['manage-company-details']['navigation-header'])}
        </Text>
        <Button size={Size.SMALL} kind={Kind.SECONDARY} isLoading={isUpdateCompanyLoading} onPress={handleOnSubmit}>
          {t(tKeys.common.save)}
        </Button>
      </VStack>
      <VStack alignItems={'flex-start'} height={'full'} width={'70%'} borderRadius={'.5rem'} overflow={'auto'} bgColor={COLORS.White.T500.value} borderWidth={1} py={'1rem'} spacing={'1.25rem'}>
        <HStack width={'full'} px={'1rem'} spacing={'1.25rem'}>
          {/*  Company name */}
          <FormControl label={t(tKeys.settings['manage-company-details']['company-name'].label)} isMandatory error={form?.name?.meta?.error} spacing={2}>
            <Input value={form?.name?.value} onChange={e => form?.name?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common['insert-name'])}/>
          </FormControl>
          {/* Legal name */}
          <FormControl label={t(tKeys.settings['manage-company-details']['legal-name'].label)} isMandatory error={form?.legalName?.meta?.error} spacing={2}>
            <Input value={form?.legalName?.value} onChange={e => form?.legalName?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common['insert-name'])}/>
          </FormControl>
        </HStack>

        {/* VAT Number */}
        <Stack width={'full'} px={'1rem'}>
          <FormControl label={_.capitalize(t(tKeys.settings['manage-company-details'].vat.label))} isMandatory error={form?.vatId?.meta?.error} spacing={2}>
            <Input type="number" value={form?.vatId?.value} onChange={e => form?.vatId?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details'].vat.placeholder)}/>
          </FormControl>
        </Stack>

        <HStack width={'full'} px={'1rem'} spacing={'1.25rem'}>
          {/* Street 1 */}
          <FormControl label={`${t(tKeys.settings['manage-company-details'].street.label)} 1`} isMandatory error={form?.addressLine1?.meta?.error} spacing={2}>
            <Input value={form?.addressLine1?.value} onChange={e => form?.addressLine1?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details'].street.placeholder)}/>
          </FormControl>

          {/* Street 2 */}
          <FormControl label={`${t(tKeys.settings['manage-company-details'].street.label)} 2`} error={form?.addressLine2?.meta?.error} spacing={2}>
            <Input value={form?.addressLine2?.value} onChange={e => form?.addressLine2?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details'].street.placeholder)}/>
          </FormControl>
        </HStack>

        <HStack width={'full'} px={'1rem'} spacing={'1.25rem'}>
          {/* City */}
          <FormControl label={t(tKeys.settings['manage-company-details'].city.label)} isMandatory error={form?.city?.meta?.error} spacing={2}>
            <Input value={form?.city?.value} onChange={e => form?.city?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details'].city.placeholder)}/>
          </FormControl>
          {/* Zip code */}
          <FormControl label={t(tKeys.settings['manage-company-details']['zip-code'].label)} isMandatory error={form?.postalCode?.meta?.error} spacing={2}>
            <Input type="number" value={form?.postalCode?.value} onChange={e => form?.postalCode?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details']['zip-code'].placeholder)}/>
          </FormControl>
        </HStack>

        <HStack width={'full'} px={'1rem'} spacing={'1.25rem'}>
          {/* State */}
          <FormControl label={t(tKeys.settings['manage-company-details'].state.label)} isMandatory error={form?.state?.meta?.error} spacing={2}>
            <Input value={form?.state?.value} onChange={e => form?.state?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details'].state.placeholder)}/>
          </FormControl>

          {/* Country */}
          <FormControl label={t(tKeys.settings['manage-company-details'].country.label)} isMandatory error={form?.country?.meta?.error} spacing={2}>
            <Input value={form?.country?.value} onChange={e => form?.country?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details'].country.placeholder)}/>
          </FormControl>
        </HStack>

        {/* Post mail */}
        <Stack width={'full'} px={'1rem'}>
          <FormControl label={t(tKeys.settings['manage-company-details']['post-mail'].label)} isMandatory error={form?.postMail?.meta?.error} spacing={2}>
            <Input type="email" value={form?.postMail?.value} onChange={e => form?.postMail?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.settings['manage-company-details']['post-mail']
            .placeholder)}/>
          </FormControl>
        </Stack>

        {/* Language */}
        <TagField title={t(tKeys.profile['user-profile'].myinfo['app-lang'])} style={{ py: 0 }} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(getLanguageLabel(form?.cultureName?.value))} hasEndEnhancer/>} isOpen={isOpenLanguage} onToggle={onToggleLanguage}>
              <SelectDocumentLanguages onSelectLanguage={handleOnChangeLanguage} selectedLanguage={form?.cultureName?.value}/>
            </PopOver>}/>
      </VStack>
    </HStack>);
};
export default CompanyDetails;
