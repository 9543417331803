import React from 'react';
import { Size } from '../loading/size';
import Flex from '../../surfaces/flex';
import LoadingAnimation from '../loading';
import styles from './style';
const LoadingWrapper = ({ size = Size.DEFAULT, isLoading, children, fullScreen = true, }) => {
    // Render
    if (isLoading)
        return (<Flex justify="center" align="center" flex={1} flexGrow={1} style={fullScreen ? styles.container : undefined}>
        <LoadingAnimation size={size}/>
      </Flex>);
    // Render
    return children;
};
export default LoadingWrapper;
