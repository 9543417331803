import { StyleSheet } from 'react-native';
import COLORS from '../../constants/colors';
const styles = StyleSheet.create({
    flatlist: {
        backgroundColor: COLORS.BG.value,
        paddingVertical: 20,
    },
    contentContainer: { paddingBottom: 80, paddingHorizontal: 12 },
    separator: { height: 12 },
});
export default styles;
