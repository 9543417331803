import { openDatabase, enablePromise, deleteDatabase, } from 'react-native-sqlite-storage';
import { readDocument } from '../online/repository/repository';
import { Platform } from 'react-native';
import RNFetchBlob from 'rn-fetch-blob';
import { sanitizePath } from '../../utils/sanitizePath';
import path from 'path';
const tableDocumentName = 'Document';
const tableAnnotationName = 'Annotation';
enablePromise(true);
let db = null;
export const getDBConnection = async () => {
    if (db) {
        return db;
    }
    db = await openDatabase({ name: 'local_3.db', location: 'default' });
    return db;
};
export const createDocumentTable = async () => {
    const query = `CREATE TABLE IF NOT EXISTS ${tableDocumentName} (
    rowid TEXT PRIMARY KEY,
    title TEXT,
    initialXfdfString TEXT,
    filename TEXT,
    filePath TEXT,
    fileThumbnailPath TEXT,
    createdAt DATETIME,
    offlineUpdated INTEGER,
    eventName TEXT,
    createdOn DATETIME,
    eventStartDate DATETIME,
    description TEXT,
    fileMime TEXT,
    versionId TEXT
  );`;
    await db.executeSql(query);
};
export const createAnnotationTable = async () => {
    const query = `CREATE TABLE IF NOT EXISTS ${tableAnnotationName} (
    rowid INTEGER PRIMARY KEY AUTOINCREMENT,
    documentId TEXT,
    annotation TEXT,
    creationDate DATETIME
  );`;
    await db.executeSql(query);
};
export const getDocuments = async () => {
    try {
        const document = [];
        const results = await db.executeSql(`SELECT * FROM ${tableDocumentName}`);
        results.forEach(result => {
            for (let index = 0; index < result.rows.length; index++) {
                document.push(result.rows.item(index));
            }
        });
        return document;
    }
    catch (error) {
        console.error(error);
    }
};
const getDocumentsFromOldDB = async (db) => {
    try {
        const document = [];
        const results = await db.executeSql(`SELECT * FROM ${tableDocumentName}`);
        results.forEach(result => {
            for (let index = 0; index < result.rows.length; index++) {
                document.push(result.rows.item(index));
            }
        });
        return document;
    }
    catch (error) {
        console.error(error);
    }
};
export const getDocumentById = async (id) => {
    try {
        let document;
        const results = await db.executeSql(`SELECT * FROM ${tableDocumentName} WHERE rowid = '${id}'`);
        results.forEach(result => {
            for (let index = 0; index < result.rows.length; index++) {
                document = result.rows.item(index);
            }
        });
        return document;
    }
    catch (error) {
        console.error(error);
    }
};
export const getAllAnnotationsByDocumentId = async (id) => {
    try {
        const annotations = [];
        const results = await db.executeSql(`SELECT * FROM ${tableAnnotationName} WHERE documentId = ?`, [id]);
        results.forEach(result => {
            for (let index = 0; index < result.rows.length; index++) {
                annotations.push(result.rows.item(index));
            }
        });
        return annotations;
    }
    catch (error) {
        console.error(error);
    }
};
export const saveDocument = async (document) => {
    try {
        const query = `INSERT OR REPLACE INTO ${tableDocumentName}  (rowid, title, initialXfdfString, filename, filePath, fileThumbnailPath, createdAt, offlineUpdated, eventName, createdOn, eventStartDate, description, fileMime, versionId) values (?,?,?,?,?,?,?,?,?,?,?,?,?,?)`;
        return await db.executeSql(query, [
            document.rowid,
            document.title,
            document.initialXfdfString,
            document.filename,
            document.filePath,
            document.fileThumbnailPath,
            document.createdAt,
            document.offlineUpdated,
            document.eventName,
            document.createdOn,
            document.eventStartDate,
            document.description,
            document.fileMime,
            document.versionId,
        ]);
    }
    catch (error) {
        console.error(error);
    }
};
export const saveAnnotation = async (annotation) => {
    try {
        const query = `INSERT INTO ${tableAnnotationName}  (rowid, documentId, annotation, creationDate) values (?,?,?,?)`;
        return await db.executeSql(query, [
            annotation.rowid,
            annotation.documentId,
            annotation.annotation,
            annotation.creationDate,
        ]);
    }
    catch (error) {
        console.error(error);
    }
};
export const deleteDocument = async (id) => {
    const query = `DELETE FROM ${tableDocumentName} WHERE rowid = '${id}'`;
    await db.executeSql(query);
    await deleteAnnotationsByDocumentId(id);
};
export const deleteAnnotationsByDocumentId = async (id) => {
    const query = `DELETE FROM ${tableAnnotationName} WHERE documentId = '${id}'`;
    await db.executeSql(query);
};
export const initializeLocalDatabase = async () => {
    await getDBConnection();
    await createDocumentTable();
    await createAnnotationTable();
    await migrateDatabase();
};
const migrateDatabase = async () => {
    const oldDbName = 'local_2.db';
    const newDbName = 'local_3.db';
    const oldDb = await openDatabase({ name: oldDbName, location: 'default' });
    const newDb = await openDatabase({ name: newDbName, location: 'default' });
    label: try {
        const structureDocumentoldDb = await fetchTableStructure(oldDb, tableDocumentName);
        const structureDocumentnewDb = await fetchTableStructure(newDb, tableDocumentName);
        if (!structureDocumentoldDb.length)
            break label;
        if (JSON.stringify(structureDocumentoldDb) !==
            JSON.stringify(structureDocumentnewDb)) {
            const dataToMigrate = await getDocumentsFromOldDB(oldDb);
            for (const row of dataToMigrate) {
                const { rowid, filePath, fileThumbnailPath, createdAt, offlineUpdated, eventName, eventStartDate, xfdfString, initialXfdfString, } = row;
                const document = await readDocument(rowid);
                await saveDocument({
                    rowid,
                    title: document.title,
                    description: document.description,
                    initialXfdfString,
                    createdOn: document.createdOn,
                    versionId: document.versionId,
                    filename: document.file.filename,
                    filePath,
                    fileThumbnailPath,
                    fileMime: document.file.mimeType,
                    createdAt,
                    offlineUpdated,
                    eventName,
                    eventStartDate,
                });
                await saveAnnotation({
                    documentId: rowid,
                    annotation: xfdfString,
                    creationDate: new Date().toISOString(),
                });
            }
        }
        await deleteDatabase({ name: oldDbName, location: 'default' });
    }
    catch (error) {
        console.log(error);
    }
    if (Platform.OS === 'ios') {
        const data = await getDocuments();
        const newPathDir = RNFetchBlob.fs.dirs.DocumentDir;
        const splitedNewPathDir = newPathDir.split('/');
        for (const row of data) {
            const splitedFilePath = row.filePath.split('/');
            if (splitedNewPathDir[splitedNewPathDir.length - 2] ===
                splitedFilePath[splitedFilePath.length - 2])
                continue;
            const sanitizedPath = sanitizePath(newPathDir, `${row.rowid}-${row.filename}`);
            const sanitizedThumbnailPath = path.join(newPathDir, `thumbnail-${row.rowid}-${row.filename}`);
            await saveDocument({
                ...row,
                filePath: sanitizedPath,
                fileThumbnailPath: sanitizedThumbnailPath,
            });
        }
    }
};
const fetchTableStructure = async (db, tableName) => {
    return await new Promise((resolve, reject) => {
        db.transaction((tx) => {
            tx.executeSql(`PRAGMA table_info(${tableName});`, [], (tx, result) => {
                const structure = [];
                for (let i = 0; i < result.rows.length; i++) {
                    const column = result.rows.item(i);
                    structure.push(column);
                }
                resolve(structure);
            }, (error) => {
                reject(error);
            });
        });
    });
};
