import { StyleSheet } from 'react-native';
import CUSTOM_SHADOWS from '../../../../utils/shadows';
// const styles = StyleSheet.create({
//   toastContainer: {
//     width: '90%',
//     paddingVertical: 14,
//     paddingHorizontal: 12,
//     borderRadius: 8,
//     ...CUSTOM_SHADOWS.PopupMenu,
//   },
//   iconContainer: {
//     marginRight: 8,
//     borderRadius: 8,
//     padding: 8,
//     alignContent: 'center',
//     alignSelf: 'center',
//   },
//   crossIconContainer: {
//     alignContent: 'center',
//     justifyContent: 'center',
//   },
// });
const styles = StyleSheet.create({
    toastContainer: {
        width: '90%',
        paddingVertical: 14,
        paddingHorizontal: 12,
        borderRadius: 8,
        ...CUSTOM_SHADOWS.PopupMenu,
    },
    iconContainer: {
        marginRight: 8,
        borderRadius: 8,
        padding: 8,
        alignContent: 'center',
        alignSelf: 'center',
    },
    crossIconContainer: {
        alignContent: 'center',
        justifyContent: 'center',
    },
});
export default styles;
