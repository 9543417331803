import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Collapsible from 'react-native-collapsible';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { Shape } from '../../../constants/shape';
import { tKeys } from '../../../translations/keys';
import Text from '../../content/text';
import Button from '../../inputs/button';
import { Kind } from '../../inputs/button/constants/kind';
import { Size } from '../../inputs/button/constants/size';
import Container from '../../surfaces/container';
import CalendarPicker from '../calendar';
import styles from './style';
import Icon from '../../content/icon';
import { HStack } from '@chakra-ui/react';
function FilterCalendar({ title, dateFormat = 'DD MMM YYYY', monthFormat = 'MMMM', isValueTouched, closeCalendar, isDisabled, dateTitleFont = FONTS.T1.T14Px.Medium500.value, name, value, onChange, marginTop = 20, initialValue, minDate, }) {
    // Attributes
    const [renderChildrenCollapsed, setRenderChildrenCollapsed] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    // Effects
    useEffect(() => {
        if (closeCalendar) {
            setIsCalendarOpen(false);
        }
    }, [closeCalendar]);
    const onButtonPress = () => {
        setIsCalendarOpen(!isCalendarOpen);
        setRenderChildrenCollapsed(true);
    };
    const onDayPress = (value) => {
        onChange(value);
        setIsCalendarOpen(false);
    };
    // Render
    return (<Container style={{
            marginTop,
        }}>
      <HStack width={'100%'} justify="space-between">
        {title ? (<HStack style={styles.dateTitleContainer} spacing={'.75rem'}>
            <Icon name="calendar-blue"/>
            <Text font={dateTitleFont} color={COLORS?.Text.T400.value}>
              {title}
            </Text>
          </HStack>) : null}

        <Button kind={isCalendarOpen || isValueTouched ? Kind.SECONDARY : Kind.WHITE} size={Size.MINI} shape={Shape.ROUND} borderColor={COLORS.Stroke.value} borderWidth={isCalendarOpen || isValueTouched ? 0 : 1} onPress={onButtonPress} isDisabled={isDisabled}>
          <Text font={FONTS.T1.T12Px.Regular400.value} color={isCalendarOpen || isValueTouched
            ? COLORS?.White.T500.value
            : COLORS?.Text.T400.value}>
            {value
            ? moment(value).format(dateFormat)
            : t(tKeys.date.no_date.placeholder)}
          </Text>
        </Button>
      </HStack>

      <Collapsible collapsed={!isCalendarOpen} renderChildrenCollapsed={renderChildrenCollapsed}>
        <Container style={styles.calendarContainer}>
          <CalendarPicker name={name} value={value} onChange={(value) => onDayPress?.(value)} initialValue={initialValue} minDate={minDate}/>
        </Container>
      </Collapsible>
    </Container>);
}
export default FilterCalendar;
