import { useFormik } from 'formik';
import { countries } from '../../../../components/inputs/phone-input/assets/countries-flags-dials';
import useRouter from '../../../../hooks/router/useRouter';
import { useReadMe, useRequestPhoneChange, } from '../../../../services/online/people/people';
import { createForm } from '../../../../utils/formik';
import getStaleTime from '../../../../utils/getStaleTime';
import { useCompaniesContext } from '../../../../contexts/companies/index.web';
const useLogic = () => {
    // Attributes
    const { push, goBack } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    const { data: currentUserData } = useReadMe({
        query: {
            staleTime: getStaleTime(),
            retry: 3,
        },
    });
    // Queries
    const { mutateAsync: requestPhoneChange, isLoading: isRequestPhoneChangeLoading, } = useRequestPhoneChange();
    // Functions
    const handleOnPressButton = async () => {
        if (dirty) {
            try {
                await requestPhoneChange({
                    params: {
                        phone: `${form?.country?.value?.dialCode}${form?.mobilePhone?.value}`,
                    },
                }, {
                    onSuccess: () => {
                        // redirect to the verify code
                        push('/company/profile/user-profile/mobile-phone/verify-mobile-code-user-profile', {
                            companyId: selectedCompanyId,
                            mobilePhone: `${form?.country?.value?.dialCode}${form?.mobilePhone?.value}`,
                            currentUserData,
                        });
                    },
                });
            }
            catch (err) {
                console.error('Error when update phone number : ', err);
                setFieldError('mobilePhone', err);
            }
        }
    };
    // Functions
    function handleOnCountryChange(country) {
        form?.country?.onChange?.(country);
    }
    const getMobilePhoneWithoutDialCode = (dialCode) => {
        if (!currentUserData) {
            return '';
        }
        return currentUserData?.person?.phone?.replace(dialCode, '');
    };
    const getCurrentUserCountry = () => {
        if (currentUserData) {
            const findCountry = countries?.find(country => country?.dialCode ===
                currentUserData?.person?.phone?.substring(0, 3) ||
                country?.dialCode === currentUserData?.person?.phone?.substring(0, 4));
            return findCountry;
        }
        return undefined;
    };
    // Form
    const { values, dirty, setFieldError, ...rest } = useFormik({
        initialValues: {
            mobilePhone: getMobilePhoneWithoutDialCode(getCurrentUserCountry().dialCode),
            country: {
                ...getCurrentUserCountry(),
            },
        },
        onSubmit: handleOnPressButton,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        handleOnPressButton,
        isRequestPhoneChangeLoading,
        dirty,
        currentUserData,
        form,
        handleOnCountryChange,
        goBack,
    };
};
export default useLogic;
