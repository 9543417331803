import React from 'react';
import Container from '../container';
import styles from './style';
import { SafeAreaView, StatusBar } from 'react-native';
import COLORS from '../../../constants/colors';
import { Sidebar } from '../../navigation/sidebar';
import { HStack, Stack, VStack } from '@chakra-ui/react';
const Page = ({ children, backgroundColor = COLORS.Text.T500.value, barStyle = 'dark-content', hideStatusBar, showSideBar, }) => {
    // Render
    return (<VStack style={{
            ...styles.mainContainer,
            backgroundColor,
        }} spacing={0}>
      <Container style={styles.statusBar}>
        <SafeAreaView>
          <StatusBar barStyle={barStyle} hidden={hideStatusBar}/>
        </SafeAreaView>
      </Container>
      <HStack width={'full'} spacing={0}>
        <Stack>{showSideBar && <Sidebar />}</Stack>
        <VStack minW={'70vw'} width={'100%'} height={'100vh'} alignItems={'flex-start'} spacing={0}>
          {children}
        </VStack>
      </HStack>
    </VStack>);
};
export default Page;
