import React from 'react';
import Icon from '../../../../../components/content/icon';
import Image from '../../../../../components/content/image';
import Text from '../../../../../components/content/text';
import Menu from '../../../../../components/pickers/menu';
import MenuItem from '../../../../../components/pickers/menu/menu-item/index.web';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import CompanyNotificationModal from '../company-notification-modal/index.web';
import useLogic from './logic.web';
import { Stack } from '@chakra-ui/react';
const CompanyNotifications = ({ enrolment, }) => {
    // Attributes
    const { handleOnPress, handleSwitchOnChange, handleChangeNotificationfrequency, form, isOpenNotificationModal, onCloseNotificationModal, } = useLogic({
        enrolment,
    });
    // Render
    return (<Menu>
      {/* Information about the company notification */}
      <MenuItem title={enrolment?.company?.name} endEnhancer={<Icon name="chevron-right" width={20} height={20} fill={'transparent'}/>} style={{ padding: '1rem' }} onPress={handleOnPress} startEnhancer={<Stack borderRadius={8} borderWidth={1} borderColor={COLORS.Line.value} overflow={'hidden'}>
            <Image imageURI={enrolment?.company?.picture?.uri} width={'2.25rem'} height={'2.25rem'} isLocalImage={false}/>
          </Stack>} titleEnhancer={<Text font={FONTS.T1.T12Px.Regular400?.value} color={COLORS?.InputText?.value}>
            {enrolment?.email}
          </Text>}/>

      {/* Modal and loading */}
      <CompanyNotificationModal handleSwitchOnChange={value => {
            void handleSwitchOnChange(value);
        }} handleChangeNotificationfrequency={value => {
            void handleChangeNotificationfrequency(value);
        }} switchValue={form?.switch?.value} notificationFrequencyValue={form?.notificationFrequency?.value} isOpen={isOpenNotificationModal} onClose={onCloseNotificationModal}/>
    </Menu>);
};
export default CompanyNotifications;
