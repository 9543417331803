import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import useRouter from '../../../../hooks/router/useRouter';
import { deleteTokensFromStorage } from '../../../../utils/handleTokens';
const Disconnect = () => {
    const { reset } = useRouter();
    const queryClient = useQueryClient();
    // Effects
    useEffect(() => {
        // Deletes tokens from storage
        void deleteTokensFromStorage();
        void queryClient?.resetQueries();
        // Redirect to the auth page
        reset('/auth');
    }, []);
    return <></>;
};
export default Disconnect;
