/* eslint-disable @typescript-eslint/no-explicit-any */
import { StackActions, useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { getDefaultRoute, routes } from '../../routes/routes';
const useRouter = () => {
    // Attributes
    const navigation = useNavigation();
    const [params, setParams] = useState();
    const [currentRoute, setCurrentRoute] = useState();
    // Functions
    const getParamsFromRoute = (pages, params, parent) => {
        if (pages?.length > 0) {
            const page = parent ? parent + `/${pages.pop()}` : `/${pages.pop()}`;
            return {
                ...params,
                animation: params?.animation ?? 'default',
                screen: page,
                params: getParamsFromRoute(pages, params, page),
            };
        }
        return { ...params, animation: params?.animation ?? 'default' };
    };
    const push = (route, params) => {
        const pages = route.split('/');
        // Reverses the routes to be able to pop
        pages.reverse();
        // Removes the initial '/'
        pages.pop();
        // Create a new object for params
        const updatedParams = { ...params };
        // Adds current route to params
        updatedParams.currentRoute = route;
        // Recursively creates the route for react-navigation
        const navigationPage = getParamsFromRoute(pages, updatedParams);
        navigation?.navigate?.(navigationPage.screen, { ...navigationPage.params });
    };
    const replace = (route, params) => {
        const currentRoute = getParamsForCurrentRoute()?.currentRoute;
        if (currentRoute === route) {
            return;
        }
        const pages = route.split('/');
        // Reverses the routes to be able to pop
        pages.reverse();
        // Removes the initial '/'
        pages.pop();
        // Adds current route to params
        params = {
            ...params,
            currentRoute: route,
        };
        // Recursively creates the route for react-navigation
        const navigationPage = getParamsFromRoute(pages, params);
        navigation.replace?.(navigationPage.screen, navigationPage.params);
    };
    const reset = (route) => {
        navigation.reset({
            index: 0,
            routes: [{ name: route }],
        });
    };
    const goBack = (count) => {
        if (typeof count === 'number') {
            const popAction = StackActions.pop(count);
            navigation.dispatch(popAction);
            return;
        }
        // Check if we can go back (has always the history) on react navigation
        if (navigation.canGoBack()) {
            return navigation.goBack();
        }
        // Go back with the history of the navigator
        return window.history.back();
    };
    const getCurrent = () => {
        const route = navigation.getState();
        const current = route?.routes[route?.routes?.length - 1].name;
        return current;
    };
    const getCurrentRoute = () => {
        let route = getDefaultRoute(routes);
        const splittedRoute = getCurrent()?.split('/');
        splittedRoute?.forEach(splitted => {
            if (splitted) {
                route = route?.subRoutes?.find(r => r.name === `/${splitted}`);
            }
        });
        return route;
    };
    const getParamsForCurrentRoute = () => {
        let route = navigation.getState();
        while (route?.routes?.length) {
            if (route.routes[route.routes?.length - 1].state) {
                route = route.routes[route.routes?.length - 1].state;
            }
            else {
                break;
            }
        }
        const parameterRoutes = route?.routes?.filter((r) => r.params);
        if (parameterRoutes?.length) {
            const mergedParams = parameterRoutes.reduce((acc, currentValue) => {
                return { ...acc, ...currentValue.params };
            }, {});
            return mergedParams;
        }
        return undefined;
    };
    useEffect(() => {
        setParams(getParamsForCurrentRoute());
        navigation.addListener('focus', x => {
            setCurrentRoute(getCurrentRoute());
            setParams(getParamsForCurrentRoute());
        });
    }, []);
    return {
        push,
        goBack,
        replace,
        reset,
        getCurrent,
        currentRoute,
        params,
    };
};
export default useRouter;
