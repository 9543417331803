import React from 'react';
import { View, Platform } from 'react-native';
const Flex = ({ direction = 'row', children, align, justify, style, flex = 0, flexGrow = 0, flexWrap, testID, spacing = 0, }) => {
    // Render
    const renderChildren = () => {
        if (Array.isArray(children)) {
            return children
                ?.filter(
            // Don't render child which are null | undefined | false | ''
            child => child !== undefined &&
                child !== null &&
                child !== false &&
                child !== '')
                ?.map((child, i, children) => (<React.Fragment key={`spacing_${i}`}>
            {child}
            {/* Create spacing between each child */}
            {i < children.length - 1 && (<View style={{
                        width: direction === 'row' ? spacing : undefined,
                        height: direction === 'column' ? spacing : undefined,
                    }}/>)}
          </React.Fragment>));
        }
        return children;
    };
    // Render
    return (<View style={[
            {
                flexDirection: direction,
                alignItems: align,
                flexGrow,
                justifyContent: justify,
                flex,
                flexWrap,
                minWidth: Platform.OS === 'web' ? 'auto' : undefined,
                minHeight: Platform.OS === 'web' ? 'auto' : undefined,
            },
            style,
        ]} testID={testID}>
      {renderChildren()}
    </View>);
};
export default Flex;
