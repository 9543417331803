import { t } from 'i18next';
import React from 'react';
import { FlatList } from 'react-native';
import Text from '../../../../../components/content/text';
import FilterHeader from '../../../../../components/surfaces/filter-header';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import { tKeys } from '../../../../../translations/keys';
import useLogic from './logic.web';
import styles from './style';
import { HStack, Switch, VStack } from '@chakra-ui/react';
const RepositoryFilter = ({ repositoryFilterForm, }) => {
    // Attributes
    const { form, isAllFileTypesSelected, onPressFromDateButton, onPressPeriodTimeButton, onPressToDateButton, onSelectAllFileTypes, handleOnChangeSwitchButton, } = useLogic(repositoryFilterForm);
    // Render functions
    const RepositoryHeaderFilterItemList = ({ id, isSelected, label, }) => (<HStack justify="space-between" style={styles.buttonFilterContainer}>
      {/* File type name */}
      <Text font={FONTS.T1.T12Px.Medium500.value}>{label}</Text>

      {/* Switch button */}
      <Switch isChecked={isSelected} onChange={() => handleOnChangeSwitchButton(id)} _checked={{
            '& .chakra-switch__track': {
                backgroundColor: COLORS.Success.T500.value,
            },
        }}/>
    </HStack>);
    // Render
    return (<VStack alignItems={'flex-start'} width={'30rem'} maxH={'45rem'} pb={'1rem'}>
      <HStack width={'full'} pl={'1rem'} pt={'1rem'}>
        <Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.common.filters)}
        </Text>
      </HStack>
      {/* Main content */}
      <FlatList style={styles.mainContent} contentContainerStyle={styles.flatlistContent} showsVerticalScrollIndicator={false} data={form?.selectedFileTypes?.value} renderItem={({ item }) => (<RepositoryHeaderFilterItemList label={t(item?.label)} id={item?.id} isSelected={item?.isSelected}/>)} ListHeaderComponent={<FilterHeader title={t(tKeys.home.more.repository.filter.header.title)} 
        // From date props
        fromDateValue={form?.fromDate?.value} onPressFromDateButton={onPressFromDateButton} isFromDateDirty={form?.period?.meta?.touched} closeFromDateCalendar={!!form?.period?.value} 
        // To date props
        toDateValue={form?.toDate?.value} onPressToDateButton={onPressToDateButton} isToDateDirty={form?.period?.meta?.touched} closeToDateCalendar={!!form?.period?.value} 
        // Period group buttons props
        selectedPeriodValue={form?.period?.value} handleOnPressPeriodTimeButton={period => onPressPeriodTimeButton(period)} 
        // Options props
        handleOnSelect={onSelectAllFileTypes} isAllOptionsSelected={isAllFileTypesSelected}/>}/>
    </VStack>);
};
export default RepositoryFilter;
