/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Retrieves a list of enrolments for a specific company or person with filtering support
 */
export const readEnrolments = (params, options, signal) => {
    return customInstance({ url: `/enrolments`, method: 'get', params, signal }, options);
};
export const getReadEnrolmentsQueryKey = (params) => [
    `/enrolments`,
    ...(params ? [params] : []),
];
export const useReadEnrolments = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadEnrolmentsQueryKey(params);
    const queryFn = ({ signal, }) => readEnrolments(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates an enrolment
 */
export const updateEnrolment = (enrolment, params, options) => {
    return customInstance({
        url: `/enrolments`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: enrolment,
        params,
    }, options);
};
export const useUpdateEnrolment = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data, params } = props ?? {};
        return updateEnrolment(data, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Sets a specific enrolment as last used
 */
export const switchEnrolment = (enrolmentId, options) => {
    return customInstance({ url: `/enrolments/${enrolmentId}/switch`, method: 'post' }, options);
};
export const useSwitchEnrolment = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { enrolmentId } = props ?? {};
        return switchEnrolment(enrolmentId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Requests email update for a specific enrolment
 */
export const requestEmailChange = (enrolmentId, params, options) => {
    return customInstance({ url: `/enrolments/${enrolmentId}/email-update`, method: 'post', params }, options);
};
export const useRequestEmailChange = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { enrolmentId, params } = props ?? {};
        return requestEmailChange(enrolmentId, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Creates an invitation
 */
export const createInvitation = (invitation, options) => {
    return customInstance({
        url: `/enrolments/invitations`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: invitation,
    }, options);
};
export const useCreateInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createInvitation(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of invitations for a specific company
 */
export const readInvitations = (params, options, signal) => {
    return customInstance({ url: `/enrolments/invitations`, method: 'get', params, signal }, options);
};
export const getReadInvitationsQueryKey = (params) => [
    `/enrolments/invitations`,
    ...(params ? [params] : []),
];
export const useReadInvitations = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadInvitationsQueryKey(params);
    const queryFn = ({ signal, }) => readInvitations(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves an invitation
 */
export const readInvitation = (invitationId, options, signal) => {
    return customInstance({ url: `/enrolments/invitations/${invitationId}`, method: 'get', signal }, options);
};
export const getReadInvitationQueryKey = (invitationId) => [
    `/enrolments/invitations/${invitationId}`,
];
export const useReadInvitation = (invitationId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadInvitationQueryKey(invitationId);
    const queryFn = ({ signal, }) => readInvitation(invitationId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!invitationId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Deletes an invitation
 */
export const deleteInvitation = (invitationId, options) => {
    return customInstance({ url: `/enrolments/invitations/${invitationId}`, method: 'delete' }, options);
};
export const useDeleteInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { invitationId } = props ?? {};
        return deleteInvitation(invitationId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves at least an invitation confirmed by the email
 */
export const readInvitationByEmail = (params, options, signal) => {
    return customInstance({ url: `/enrolments/invitations/email`, method: 'get', params, signal }, options);
};
export const getReadInvitationByEmailQueryKey = (params) => [`/enrolments/invitations/email`, ...(params ? [params] : [])];
export const useReadInvitationByEmail = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadInvitationByEmailQueryKey(params);
    const queryFn = ({ signal }) => readInvitationByEmail(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Resends an invitation by email
 */
export const resendInvitation = (invitationId, options) => {
    return customInstance({ url: `/enrolments/invitations/${invitationId}/resend`, method: 'post' }, options);
};
export const useResendInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { invitationId } = props ?? {};
        return resendInvitation(invitationId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Accepts an invitation by providing a phone number as query string parameter, initiates the creation of a code
that'll be sent by SMS
 */
export const acceptInvitation = (invitationId, params, options) => {
    return customInstance({
        url: `/enrolments/invitations/${invitationId}/accept`,
        method: 'post',
        params,
    }, options);
};
export const useAcceptInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { invitationId, params } = props ?? {};
        return acceptInvitation(invitationId, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Confirms an invitation by providing a valid code received by SMS
 */
export const confirmInvitation = (invitationId, params, options) => {
    return customInstance({
        url: `/enrolments/invitations/${invitationId}/confirm`,
        method: 'post',
        params,
    }, options);
};
export const useConfirmInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { invitationId, params } = props ?? {};
        return confirmInvitation(invitationId, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Update user notification for an enrolment
 */
export const updateNotificationSetting = (updateNotificationSettingBody, options) => {
    return customInstance({
        url: `/enrolments/notifications`,
        method: 'put',
        data: updateNotificationSettingBody,
    }, options);
};
export const useUpdateNotificationSetting = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateNotificationSetting(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of notifications settings for a specific enrolment
 */
export const readNotificationsSettings = (enrolmentId, options, signal) => {
    return customInstance({ url: `/enrolments/notifications/${enrolmentId}`, method: 'get', signal }, options);
};
export const getReadNotificationsSettingsQueryKey = (enrolmentId) => [
    `/enrolments/notifications/${enrolmentId}`,
];
export const useReadNotificationsSettings = (enrolmentId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadNotificationsSettingsQueryKey(enrolmentId);
    const queryFn = ({ signal }) => readNotificationsSettings(enrolmentId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!enrolmentId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves a list of board roles
 */
export const readBoardRoles = (params, options, signal) => {
    return customInstance({ url: `/enrolments/board-roles`, method: 'get', params, signal }, options);
};
export const getReadBoardRolesQueryKey = (params) => [
    `/enrolments/board-roles`,
    ...(params ? [params] : []),
];
export const useReadBoardRoles = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadBoardRolesQueryKey(params);
    const queryFn = ({ signal, }) => readBoardRoles(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Creates a board role
 */
export const createBoardRole = (boardRole, options) => {
    return customInstance({
        url: `/enrolments/board-roles`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: boardRole,
    }, options);
};
export const useCreateBoardRole = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createBoardRole(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Updates a board role
 */
export const updateBoardRole = (boardRole, options) => {
    return customInstance({
        url: `/enrolments/board-roles`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: boardRole,
    }, options);
};
export const useUpdateBoardRole = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateBoardRole(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes a board role
 */
export const deleteBoardRole = (boardRoleId, params, options) => {
    return customInstance({ url: `/enrolments/board-roles/${boardRoleId}`, method: 'delete', params }, options);
};
export const useDeleteBoardRole = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { boardRoleId, params } = props ?? {};
        return deleteBoardRole(boardRoleId, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
