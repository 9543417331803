import React from 'react';
import COLORS from '../../../constants/colors';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import FONTS from '../../../constants/fonts';
const DocumentCard = ({ backgroundColor = COLORS?.BG.value, children, endEnhancer, startEnhancer, width, subtitle, }) => {
    // Render children
    const renderChildren = () => {
        const { fontFamily, fontSize, fontWeight } = FONTS.T1.T12Px.Medium500.value;
        if (typeof children === 'string') {
            return (<Text noOfLines={1} overflow={'hidden'} fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight}>
          {children}
        </Text>);
        }
        return <>{children}</>;
    };
    const { fontFamily, fontSize, fontWeight } = FONTS.T1.T10Px.Regular400.value;
    // Render
    return (<HStack justifyContent={'space-between'} width={width} bgColor={backgroundColor} p={'.75rem'} borderRadius={'.5rem'} borderWidth={1} borderColor={COLORS.Line.value} marginY={'.25rem'}>
      <HStack width={'full'} overflow={'hidden'}>
        {/* Start Enhancer */}
        {startEnhancer}

        {/* Children */}
        {children && (<VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
            {renderChildren()}
            {subtitle && (<Text fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} color={COLORS.InputText.value}>
                {subtitle}
              </Text>)}
          </VStack>)}
      </HStack>

      {/* Icon if the current item is selected */}
      <Box>{endEnhancer}</Box>
    </HStack>);
};
export default DocumentCard;
