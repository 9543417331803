import React from 'react';
import Feed from '.';
import Icon from '../../../components/content/icon';
import { stackNavigator } from '../../../routes/navigators';
import SharePost from './share-post';
const feedRoutes = [
    // Feed route
    {
        name: '/feed',
        deepLink: '/feed',
        defaultRoute: '/default',
        component: stackNavigator,
        activeIcon: (<Icon name="navigation-bar-home-selected" width={24} height={24}/>),
        inactiveIcon: <Icon name="navigation-bar-home" width={24} height={24}/>,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                tabBarLabel: 'page.menu.news-feed',
                component: Feed,
            },
            {
                name: '/share-post',
                deepLink: '/share-post',
                component: SharePost,
            },
        ],
    },
];
export default feedRoutes;
