import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContent: {
        width: '100%',
        paddingVertical: 10,
        padingHorizontal: 12,
        alignContent: 'center',
        alignItems: 'center',
    },
    avatarButtonContainer: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
});
export default styles;
