import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../hooks/router/useRouter';
import { useAuthenticatePerson } from '../../../services/online/people/people';
import { tKeys } from '../../../translations/keys';
import { createForm } from '../../../utils/formik';
import { saveTokens } from '../../../utils/handleTokens';
import { ToAuthenticationDTO } from './mappers';
import { validationSchema } from './validations';
const useLogic = () => {
    // Attributes
    const { push } = useRouter();
    const { t } = useTranslation();
    const continueButtonRef = useRef(null);
    // Queries
    const { mutateAsync: authenticatePerson, isLoading: isAuthenticatePersonLoading, } = useAuthenticatePerson();
    // Functions
    const handlerOnSubmit = async (values) => {
        try {
            await authenticatePerson({
                data: ToAuthenticationDTO(values),
            }, {
                onSuccess: response => {
                    const signInEntity = {
                        email: values.email,
                        password: values.password,
                        accessToken: response?.accessToken,
                        refreshToken: response?.refreshToken,
                    };
                    void saveTokens({
                        accessToken: '',
                        refreshToken: response?.refreshToken,
                    });
                    push('/auth/verification-code', { signInEntity });
                },
                onError: err => {
                    setFieldError('email', t(tKeys.validation.signIn.all.invalid));
                    setFieldError('password', t(tKeys.validation.signIn.all.invalid));
                    console.error('Error with authenticate person : ', err);
                },
            });
        }
        catch (err) {
            console.error('Error with authenticate person : ', err);
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            continueButtonRef.current.click();
        }
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: handlerOnSubmit,
        validationSchema: validationSchema(t),
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const { submitForm, setFieldError } = rest;
    const form = createForm(values, rest);
    const isSubmitButtonDisabled = (form?.email?.value?.length <= 0 && form?.password?.value?.length <= 0) ||
        isAuthenticatePersonLoading;
    // Functions
    const handleOnPressForgotPassword = () => {
        push('/auth/sign-in/forgot-password');
    };
    // Effects
    useEffect(() => {
        if (form?.email?.value) {
            form?.email?.onChange(form?.email?.value?.trim());
        }
    }, [form?.email?.value]);
    // Return
    return {
        form,
        handleOnPressForgotPassword,
        handlerOnSubmit: submitForm,
        isSubmitButtonDisabled,
        isAuthenticatePersonLoading,
        handleKeyPress,
        continueButtonRef,
    };
};
export default useLogic;
