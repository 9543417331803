import { useDisclosure } from '@chakra-ui/react';
import { useCompaniesContext } from '../../../../../../contexts/companies';
import { TaskTypeEnum } from '../../../../../../enum/TaskType';
import useRouter from '../../../../../../hooks/router/useRouter';
import { useReadPerson } from '../../../../../../services/online/people/people';
import getUserName from '../../../../../../utils/getUserName';
import { DocumentKindEnum, } from '../../../../../document-details/type';
const useLogic = (task) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const { push } = useRouter();
    const { isOpen: isOpenCustomTask, onOpen: onOpenCustomTask, onClose: onCloseCustomTask, } = useDisclosure();
    // Queries
    // creatorData, assigneeData
    const { data: creatorData } = useReadPerson(task?.creatorId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId && !!task?.creatorId,
        },
    });
    const { data: assigneeData } = useReadPerson(task?.personId, {
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId && !!task?.personId,
        },
    });
    // Functions
    const handleTaskPress = (task) => {
        if (task.type === TaskTypeEnum?.Custom) {
            onOpenCustomTask();
            return;
        }
        const params = {
            documentKind: DocumentKindEnum.DOCUMENT,
            taskType: task.type,
            documentId: task.documentId,
            eventId: task.eventId,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...params,
        });
    };
    // Return
    return {
        creatorUserName: getUserName(creatorData),
        assigneeUserName: getUserName(assigneeData),
        handleTaskPress,
        isOpenCustomTask,
        onCloseCustomTask,
    };
};
export default useLogic;
