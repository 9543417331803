import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import useRouter from '../../../hooks/router/useRouter';
import Image from '../../content/image';
import Text from '../../content/text';
import NavigationHeader from '../../navigation/navigation-header';
import Container from '../../surfaces/container';
import Flex from '../../surfaces/flex';
import Page from '../../surfaces/page';
const PageWorkInProgress = () => {
    // Attributes
    const { goBack } = useRouter();
    // Render
    return (<Page backgroundColor={COLORS.White.T500.value}>
      <NavigationHeader onGoBack={goBack}>
        {'Work in progress'}
      </NavigationHeader>
      <Container style={{
            width: '100%',
            height: '70%',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
        <Flex direction="column">
          <Image imageURI={require('../../../assets/images/work_in_progress.webp')} isLocalImage={true} width={300} height={300} borderRadius={12}/>

          <Container style={{
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 12,
        }}>
            <Text font={FONTS.T1.T24Px.Semibold.value}>
              {'Work in progress...'}
            </Text>
          </Container>
        </Flex>
      </Container>
    </Page>);
};
export default PageWorkInProgress;
