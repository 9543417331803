import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/content/text';
import Button from '../../../components/inputs/button';
import { Kind } from '../../../components/inputs/button/constants/kind';
import NavigationHeader from '../../../components/navigation/navigation-header';
import FilterDateAndHours from '../../../components/pickers/filter-hours';
import Container from '../../../components/surfaces/container';
import Flex from '../../../components/surfaces/flex';
import Page from '../../../components/surfaces/page';
import COLORS from '../../../constants/colors';
import { dateFormatFrench } from '../../../constants/date-format';
import FONTS from '../../../constants/fonts';
import { tKeys } from '../../../translations/keys';
import getUserName from '../../../utils/getUserName';
import ProfileHeader from '../components/navigation/profile-header';
import useLogic from './logic.web';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import TagFieldItem from '../../home/calendar/components/inputs/tag-field/tag-item/index.web';
import PopOver from '../../../components/surfaces/pop-over/index.web';
import SelectMemberRole from '../../select-member-role/index.web';
import { getPlatformRoleLabel } from '../../../utils/platformRole';
import SelectBoardRole from '../../select-board-role/index.web';
const OtherUserProfile = () => {
    // Attributes
    const { form, personData, personEnrolement, isAdmin, handleOnSubmit, isUpdateEnrolmentLoading, handleSuspendUser, isMemberSuspended, handleOnChooseRole, isOpenMemberRole, onToggleMemberRole, goBack, handleChooseBoardRole, isOpenBoardRole, onToggleBoardRole, } = useLogic();
    const { t } = useTranslation();
    const boxShadow = '0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)';
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={goBack} endEnhancer={<HStack spacing={'.5rem'}>
            {/* Suspend member */}
            {!isMemberSuspended && (<Button kind={Kind.TRANSPARENT} onPress={handleSuspendUser} borderColor="red" borderWidth={1}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                  {t(tKeys.other_member.suspend_user)}
                </Text>
              </Button>)}
            {isAdmin && personData && (<Button onPress={handleOnSubmit} isLoading={isUpdateEnrolmentLoading} kind={Kind.SECONDARY}>
                {t(tKeys.common.save)}
              </Button>)}
          </HStack>}>
        {getUserName(personData)}
      </NavigationHeader>

      <Stack width={'full'} bgColor={COLORS.BG.value} overflow={'auto'} height={'full'} alignItems={'center'} p={'1rem'}>
        {/* User profile image */}
        <VStack direction="column" width={'32rem'} spacing={'1rem'}>
          <ProfileHeader profileImageUri={isMemberSuspended
            ? require('../../../assets/images/member_placeholder.png')
            : personData?.picture?.uri} profileName={getUserName(personData)} isLocalImage={isMemberSuspended} backgroundColor={COLORS.Warning.T100.value}>
            <Container marginTop={14} marginBottom={8}>
              <Flex justify="center" align="center" direction="column" spacing={4}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {getUserName(personData)}
                </Text>
                <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.Text.T300.value}>
                  {personData?.email ?? ''}
                </Text>
              </Flex>
            </Container>
          </ProfileHeader>

          {/* User profile informations */}
          <VStack p={'1rem'} alignItems={'flex-start'} width={'full'} bgColor={COLORS.White.T500.value} borderRadius={'.5rem'} boxShadow={boxShadow} spacing={'1.25rem'}>
            {/* Title */}
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.other_profile.profile_informations)}
            </Text>

            <VStack width={'100%'} alignItems={'flex-start'} spacing={'1rem'}>
              {/* User mobile phone */}
              <HStack width={'100%'} justifyContent={'space-between'}>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {t(tKeys.profile['user-profile'].mobile['navigation-header'])}
                </Text>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {personData?.phone}
                </Text>
              </HStack>

              {/* User member since */}
              <HStack width={'100%'} justifyContent={'space-between'}>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {t(tKeys.profile.user_profile.member_since)}
                </Text>
                <Stack py={'.25rem'} px={'.5rem'} bgColor={COLORS.Tag.value} borderRadius={'.375rem'}>
                  <Text font={FONTS.T1.T12Px.Regular400.value}>
                    {moment(personEnrolement?.startedOn).format(dateFormatFrench)}
                  </Text>
                </Stack>
              </HStack>
            </VStack>
          </VStack>

          {/* User bio */}
          <VStack bgColor={COLORS.White.T500.value} borderRadius={'.5rem'} p={'1rem'} width={'full'} alignItems={'flex-start'} spacing={'1.25rem'} boxShadow={boxShadow}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.profile['user-profile'].myinfo.bio.label)}
            </Text>

            {personData?.biography?.length ? (<Text font={FONTS.T1.T14Px.Regular400.value} numberOfLines={10}>
                {personData?.biography}
              </Text>) : (<Text color={COLORS.InputText.value}>
                {t(tKeys.other_profile.no_bio_provided_placeholder)}
              </Text>)}
          </VStack>

          <VStack alignItems={'flex-start'} width={'100%'} p={'1rem'} bgColor={COLORS.White.T500.value} borderRadius={'.5rem'} spacing={'1.25rem'} boxShadow={boxShadow}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.common.roles)}
            </Text>

            <VStack width={'100%'} alignItems={'flex-start'} spacing={'.5rem'}>
              {/* Platform role */}
              <HStack width={'full'} justifyContent={'space-between'}>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {t(tKeys.common.platform_role)}
                </Text>

                {isAdmin ? (<PopOver placement={'bottom-end'} from={<TagFieldItem value={getPlatformRoleLabel(form?.memberRole?.value)} hasEndEnhancer/>} isOpen={isOpenMemberRole} onToggle={onToggleMemberRole}>
                    <SelectMemberRole memberRole={form?.memberRole?.value} onChoose={handleOnChooseRole}/>
                  </PopOver>) : (<TagFieldItem value={getPlatformRoleLabel(form?.memberRole?.value)}/>)}
              </HStack>

              {/* Board role */}
              <HStack width={'full'} justifyContent={'space-between'}>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {t(tKeys.common.board_role)}
                </Text>

                {isAdmin ? (<PopOver placement={'bottom-end'} from={<TagFieldItem value={form?.boardRoleName.value
                    ? t(form?.boardRoleName?.value)
                    : t(tKeys.common.choose_role)} hasEndEnhancer/>} isOpen={isOpenBoardRole} onToggle={onToggleBoardRole}>
                    <SelectBoardRole selectedBoardRoleId={form?.boardRoleId?.value} onChange={handleChooseBoardRole}/>
                  </PopOver>) : (<TagFieldItem value={form?.boardRoleName.value
                ? t(form?.boardRoleName?.value)
                : t(tKeys.common.no_role)}/>)}
              </HStack>
            </VStack>
          </VStack>

          {/* Change user member date */}
          {isAdmin && (<HStack width={'full'} justifyContent={'space-between'} bgColor={COLORS.White.T500.value} p={'1rem'} borderRadius={'.5rem'} boxShadow={boxShadow}>
              <FilterDateAndHours {...form?.govrnUserUntil} isTimeEnabled={false} title={t(tKeys.other_member.govrn_user_until)} dateTitleFont={FONTS.T1.T14Px.SemiBold600.value} placeholderEnabled/>
            </HStack>)}
        </VStack>
      </Stack>
    </Page>);
};
export default OtherUserProfile;
