import { TransitionPresets } from '@react-navigation/stack';
import SignInPage from '.';
import { stackNavigator } from '../../../routes/navigators';
import SignInForgotPasswordAddressMail from './sign-in-forgot-address-mail';
import SignInCreateNewPassword from './sign-in-forgot-create-new-password';
const signInRoutes = [
    {
        name: '/sign-in',
        deepLink: '/sign-in',
        defaultRoute: '/default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: SignInPage,
                transition: TransitionPresets.ModalTransition,
            },
            //  Forgot password flow
            {
                name: '/forgot-password',
                deepLink: '/forgot-password',
                defaultRoute: '/forgot-password-mail',
                component: stackNavigator,
                transition: TransitionPresets.ModalTransition,
                subRoutes: [
                    {
                        name: '/forgot-password-mail',
                        deepLink: '/forgot-password-mail',
                        component: SignInForgotPasswordAddressMail,
                    },
                    {
                        name: '/forgot-password-create-new-password',
                        deepLink: '/forgot-password-create-new-password',
                        component: SignInCreateNewPassword,
                    },
                ],
            },
        ],
    },
];
export default signInRoutes;
