/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { checkTokens } from '../../utils/handleTokens';
import useRouter from '../../hooks/router/useRouter';
// Export des routes d'exceptions
export const exceptionsRoutes = [
    '',
    '/',
    '/auth',
    '/auth/sign-in',
    '/auth/sign-in/forgot-password',
    '/auth/verification-code',
    '/auth/create-password',
    '/impersonate',
    '/select-application-language',
    '/select-timezone',
    '/choose-country',
    '/auth/sign-up/create-account',
    '/auth/sign-up',
    '/auth/sign-up/default',
    '/auth/sign-up/invitation-expired',
    '/auth/sign-in',
    '/auth/sign-in/default',
    '/auth/sign-in/forgot-password',
    '/auth/sign-in/forgot-password-mail',
    '/auth/sign-in/forgot-password-create-new-password',
    '/web-view',
    '/offline',
    '/offline/offline-repository',
    '/offline/offline-document-details',
];
const TokenStatus = ({ children, }) => {
    // Attributes
    const { push, getCurrent } = useRouter();
    const [isTokenChecked, setIsTokenChecked] = useState(false);
    useEffect(() => {
        const checkInitialToken = async () => {
            setIsTokenChecked(false);
            const current = getCurrent();
            const value = await checkTokens();
            setIsTokenChecked(true);
            if (!exceptionsRoutes.includes(current)) {
                if (!value) {
                    window.location.href = '/auth/sign-in';
                }
            }
        };
        if (!isTokenChecked) {
            checkInitialToken();
        }
        const intervalId = setInterval(async () => {
            const current = getCurrent();
            if (!exceptionsRoutes.includes(current)) {
                const value = await checkTokens();
                if (!value) {
                    window.location.href = '/auth/sign-in';
                }
            }
        }, 30000);
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [getCurrent, push]);
    if (!isTokenChecked) {
        return <></>;
    }
    // Render
    return <>{children}</>;
};
export default TokenStatus;
