/* eslint-disable array-callback-return */
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies';
import { getReadEventsQueryKey } from '../../../../services/online/calendar/calendar';
import { Role } from '../../../../services/types';
import { PeriodValues } from '../../feed/filter/types';
const useLogic = (calendarFiltersForm, allEventsTypes, resetForm) => {
    // Attributes
    const { selectedEnrolment } = useCompaniesContext();
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    const queryClient = useQueryClient();
    // Params entity
    const allEventTypeToCustomEventTypeDTO = useMemo(() => {
        return allEventsTypes?.map(eventType => {
            return { id: eventType?.id, label: eventType?.name, isSelected: true };
        });
    }, [allEventsTypes]);
    // States
    const [isAllEventTypesSelected, setAllEventTypesSelected] = useState(true);
    // Form Initial values
    const initialFilters = {
        fromDate: undefined,
        toDate: undefined,
        period: undefined,
        eventTypes: allEventTypeToCustomEventTypeDTO,
        isInvited: true,
    };
    // Functions
    function resetFilters() {
        void queryClient.invalidateQueries(getReadEventsQueryKey(undefined));
        resetForm();
    }
    // Handlers
    const onPressPeriodTimeButton = (period) => {
        calendarFiltersForm?.fromDate?.onChange(PeriodValues[period]?.fromDate);
        calendarFiltersForm?.toDate?.onChange(PeriodValues[period]?.toDate);
        calendarFiltersForm?.period?.onChange(period);
    };
    const onPressFromDateButton = (fromDate) => {
        // Reset the period
        calendarFiltersForm?.period.onChange(null);
        calendarFiltersForm?.fromDate.onChange(fromDate?.toISOString());
    };
    const onPressToDateButton = (toDate) => {
        // Reset the period
        calendarFiltersForm?.period?.onChange(null);
        calendarFiltersForm?.toDate?.onChange(toDate?.toISOString());
    };
    function onSelectAllEventTypes() {
        changeOnAllSelectEventTypes(!isAllEventTypesSelected);
        setAllEventTypesSelected(prev => !prev);
    }
    function changeOnAllSelectEventTypes(isAllSelected) {
        const tmpEventTypes = [
            ...calendarFiltersForm?.eventTypes.value,
        ];
        tmpEventTypes?.map(tmpEventType => {
            tmpEventType.isSelected = isAllSelected;
        });
        calendarFiltersForm?.eventTypes?.onChange(tmpEventTypes);
    }
    // To handle when a switch button is pressed.
    function handleOnChangeSwitchButton(id) {
        const tmpEventTypes = [
            ...calendarFiltersForm?.eventTypes.value,
        ];
        tmpEventTypes?.map(tmpEventType => {
            if (tmpEventType?.id === id) {
                tmpEventType.isSelected = !tmpEventType?.isSelected;
            }
        });
        calendarFiltersForm?.eventTypes?.onChange(tmpEventTypes);
    }
    // Return
    return {
        initialFilters,
        onPressPeriodTimeButton,
        onPressFromDateButton,
        onPressToDateButton,
        onSelectAllEventTypes,
        isAllEventTypesSelected,
        handleOnChangeSwitchButton,
        isAdmin,
        resetFilters,
    };
};
export default useLogic;
