import { useToast as useToastChakra } from '@chakra-ui/react';
import { Toast } from './toast/index.web';
import React from 'react';
export const useToast = () => {
    const toast = useToastChakra({
        duration: 8000,
    });
    return (defaultOptions) => {
        return toast({
            position: 'top',
            render: ({ onClose }) => (<Toast status={defaultOptions?.status} handleOnClose={onClose} title={defaultOptions?.title}/>),
        });
    };
};
