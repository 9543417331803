import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    input: {
        height: 40,
        width: 312,
    },
    container: {
        alignItems: 'center',
        flexDirection: 'row',
    },
});
export default styles;
