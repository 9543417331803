import 'setimmediate';
import { AppRegistry } from 'react-native-web';
import App from './WebApp';
import { name as appName } from './app.json';
import InterBold from './src/assets/fonts/Inter-Bold.ttf';
import InterBlack from './src/assets/fonts/Inter-Black.ttf';
import InterMedium from './src/assets/fonts/Inter-Medium.ttf';
import InterRegular from './src/assets/fonts/Inter-Regular.ttf';
import InterSemiBold from './src/assets/fonts/Inter-SemiBold.ttf';
function createStyleSheet(link, fontFamily) {
    var FontStyle = `@font-face {
    src: url(${link});
    font-family: ${fontFamily};
  }`;
    var style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = InterBoldStyle;
    }
    else {
        style.appendChild(document.createTextNode(FontStyle));
    }
    document.head.appendChild(style);
}
createStyleSheet(InterBold, 'Inter-Bold');
createStyleSheet(InterBlack, 'Inter-Black');
createStyleSheet(InterMedium, 'Inter-Medium');
createStyleSheet(InterRegular, 'Inter-Regular');
createStyleSheet(InterSemiBold, 'Inter-SemiBold');
AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
    initialProps: {},
    rootTag: document.getElementById('root'),
});
