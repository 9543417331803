import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContainer: {
        marginHorizontal: 12,
        paddingVertical: 20,
        height: '100%',
    },
    separator: { height: 20 },
});
export default styles;
