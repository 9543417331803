import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../../contexts/companies';
import { finalizeMinutes } from '../../../../services/online/calendar/calendar';
import { tKeys } from '../../../../translations/keys';
import { DocumentKindEnum, } from '../../../document-details/type';
import { downloadMinutes } from '../../../../utils/downloadFile.web';
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '../../../../components/content/use-toast/index.web';
import useRouter from '../../../../hooks/router/useRouter';
const useLogic = (eventId, culture) => {
    // Attributes
    const toast = useToast();
    const [selectedCulture, setSelectedCulture] = useState();
    const [isLoading, setLoading] = useState(false);
    const [isDownloading, setDownloading] = useState(false);
    const { selectedEnrolment, selectedCompanyId } = useCompaniesContext();
    const { t } = useTranslation();
    const { push } = useRouter();
    const { isOpen: isOpenLanguage, onToggle: onToggleLanguage } = useDisclosure();
    // Effects
    useEffect(() => {
        setSelectedCulture(culture || selectedEnrolment?.company?.cultureName);
    }, [culture]);
    // Functions
    function handleOnSelectedLanguage(value) {
        setSelectedCulture(value);
        onToggleLanguage();
    }
    async function onDownloadPress() {
        setDownloading(true);
        try {
            await downloadMinutes(eventId, selectedCulture);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setDownloading(false);
        }
    }
    async function onSignPress() {
        setLoading(true);
        try {
            const { value: documentId } = await finalizeMinutes(eventId);
            const params = {
                documentKind: DocumentKindEnum.MINUTES,
                eventId,
                documentId,
            };
            push('/company/document-details', {
                companyId: selectedCompanyId,
                ...params,
            });
        }
        catch (err) {
            console.error(err);
            toast({
                status: 'error',
                title: t(tKeys['sign-in-forgot-mail'].errors.email_not_sent),
            });
        }
        finally {
            setLoading(false);
        }
    }
    return {
        onDownloadPress,
        selectedCulture,
        onSignPress,
        isLoading,
        isDownloading,
        handleOnSelectedLanguage,
        isOpenLanguage,
        onToggleLanguage,
    };
};
export default useLogic;
