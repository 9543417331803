import Add from './add';
import ApplicationLogo from './application-logo';
import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import CloseCross from './close-cross';
import Disconnect from './disconnect';
import Editable from './editable';
import FilterGroupBy from './filter-group-by';
import FilterOrder from './filter-order';
import GeneralSearch from './general-search';
import GroupSettings from './group-settings';
import Helpdesk from './helpdesk';
import KnowledgeBase from './knowledge-base';
import Members from './members';
import NavigationBarCalendarDefault from './navigation-bar-calendar/default';
import NavigationBarCalendarSelected from './navigation-bar-calendar/selected';
import NavigationBarMessagesDefault from './navigation-bar-messages/default';
import NavigationBarMessagesSelected from './navigation-bar-messages/selected';
import NavigationBarMoreDefault from './navigation-bar-more/default';
import NavigationBarMoreSelected from './navigation-bar-more/selected';
import NavigationBarNewsDefault from './navigation-bar-news/default';
import NavigationBarNewsSelected from './navigation-bar-news/selected';
import NavigationBarTasksDefault from './navigation-bar-tasks/default';
import NavigationBarTasksSelected from './navigation-bar-tasks/selected';
import Repository from './repository';
import Search from './search';
import SearchReset from './search-reset';
import Check from './selected-list-items-check';
import SettingsManageEvent from './settings-manage-event';
import SettingsManageCompanyDetails from './settings-manage-company-details';
import SettingsManageFiles from './settings-manage-files';
import ShowPasswordDisabled from './show-password/disabled';
import ShowPasswordEnabled from './show-password/enabled';
import TaskAction from './task-action';
import TaskProxy from './task-proxy';
import TaskRead from './task-read';
import TasksRSVP from './task-rsvp';
import TaskSign from './task-sign';
import ToastError from './toast-error';
import ToastSuccess from './toast-success';
import Profile from './profile';
import Messages from './messages';
import MobilePhone from './mobile-phone';
import Password from './password';
import Notification from './notification';
import Insightful from './insightful';
import InsightfulSelected from './insightful-selected';
import Comments from './comments';
import Filter from './filter';
import Box from './box';
import BoxSelected from './box-selected';
import Edit from './edit';
import DeleteBin from './delete-bin';
import Camera from './camera';
import Files from './files';
import Poll from './poll';
import Gallery from './gallery';
import Cross from './cross';
import ApplicationLogoSplash from './application-logo-splash';
import AddTask from './add-task';
import CommentPlus from './comment-plus';
import Share from './share';
import Book from './book';
import Pen from './pen';
import ListRow from './list-row';
import ListGrid from './list-grid';
import AddDocument from './add-document';
import MapPin from './map-pin';
import Phone from './phone';
import Clock from './clock';
import Layers from './layers';
import Invitees from './invitees';
import Calendar from './calendar';
import AddEvent from './add-event';
import Remove from './remove';
import ChevronLeftWhite from './chevron-left-white';
import MessageWhite from './message-white';
import EditWhite from './edit-white';
import ClockBlack from './clock-black';
import MapPinBlack from './map-pin-black';
import CalendarBlack from './calendar-black';
import PhoneBlack from './phone-black';
import Proxy from './proxy';
import EventStatusPending from './event-status-pending';
import AddDocumentBlue from './add-document-blue';
import AddFiles from './add-files';
import AddPicture from './add-picture';
import AddPictureCamera from './add-picture-camera';
import Remote from './remote';
import OrganizationDrag from './organization-drag';
import PollSquare from './poll-square';
import PollWhite from './poll-white';
import FullScreen from './full-screen';
import Picture from './picture';
import BarChart from './bar-chart';
import Ephemeral from './ephemeral';
import RotateRight from './rotate-right';
import Users from './users';
import MembersBlack from './members-black';
import AddMember from './add-member';
import Trash from './trash';
import SendMessage from './send-message';
import CameraFilled from './camera-filled';
import CalendarBlue from './calendar-blue';
import Clip from './clip';
import ClipBlue from './clip-blue';
import Sections from './sections';
import QuestionMark from './question-mark';
import Info from './info';
import ArrowDown from './arrow-down';
import Action from './action';
import RepositoryDefault from './repository/default';
import MembersDefault from './members/default';
import Settings from './settings';
import Help from './help';
import ChevronLeftDefault from './chevron-left/default';
import ChevronRightDefault from './chevron-right/default';
import Downloaded from './downloaded';
import MoreVertical from './more-vertical';
import DownloadFile from './download-file';
import CheckCircle from './check-circle';
import CloudCheck from './cloud-check';
import CloudTimes from './cloud-times';
import EditableSmall from './editable-small';
import ChevronDown from './chevron-down';
import NavigationBarHome from './navigation-bar-home';
import Publish from './publish';
import SignIcon from './sign-icon';
import SignOut from './sign-out';
import NavigationBarHomeSelected from './navigation-bar-home-selected';
import KnowledgeBaseDefault from './knowledge-base/default';
import DraggableItem from './draggable-item';
import FilesBlue from './files-blue';
import Warning from './warning';
import PictureBlue from './picture-blue';
import AI from './AI';
import Task from './task';
import ChevronUp from './chevron-up';
import NavigationBarCalendarWhite from './navigation-bar-calendar-white';
import NavigationBarMessagesWhite from './navigation-bar-messages-white';
import NavigationBarNewsWhite from './navigation-bar-news-white';
import NavigationBarTasksWhite from './navigation-bar-tasks-white';
import NavigationBarHomeWhite from './navigation-bar-home-white';
import HelpWhite from './help-white';
import SettingsWhite from './settings-white';
import MembersWhite from './members-white';
import NavigationBarDocumentsWhite from './navigation-bar-documents-white';
const Icons = {
    check: Check,
    'navigation-bar-news-default': NavigationBarNewsDefault,
    'navigation-bar-news-selected': NavigationBarNewsSelected,
    'navigation-bar-tasks-default': NavigationBarTasksDefault,
    'navigation-bar-tasks-selected': NavigationBarTasksSelected,
    'navigation-bar-calendar-default': NavigationBarCalendarDefault,
    'navigation-bar-calendar-selected': NavigationBarCalendarSelected,
    'navigation-bar-messages-default': NavigationBarMessagesDefault,
    'navigation-bar-messages-selected': NavigationBarMessagesSelected,
    'navigation-bar-more-default': NavigationBarMoreDefault,
    'navigation-bar-more-selected': NavigationBarMoreSelected,
    search: Search,
    'search-reset': SearchReset,
    'application-logo': ApplicationLogo,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'show-password-enabled': ShowPasswordEnabled,
    'show-password-disabled': ShowPasswordDisabled,
    'filter-group-by': FilterGroupBy,
    'filter-order': FilterOrder,
    'task-rsvp': TasksRSVP,
    'task-read': TaskRead,
    'task-sign': TaskSign,
    'task-proxy': TaskProxy,
    'task-action': TaskAction,
    'close-cross': CloseCross,
    repository: Repository,
    'knowledge-base': KnowledgeBase,
    members: Members,
    'group-settings': GroupSettings,
    helpdesk: Helpdesk,
    disconnect: Disconnect,
    'toast-success': ToastSuccess,
    'toast-error': ToastError,
    'general-search': GeneralSearch,
    'settings-manage-events': SettingsManageEvent,
    'settings-manage-files': SettingsManageFiles,
    'settings-manage-company-details': SettingsManageCompanyDetails,
    add: Add,
    editable: Editable,
    profile: Profile,
    messages: Messages,
    'mobile-phone': MobilePhone,
    password: Password,
    notification: Notification,
    insightful: Insightful,
    'insightful-selected': InsightfulSelected,
    comments: Comments,
    filter: Filter,
    box: Box,
    'box-selected': BoxSelected,
    edit: Edit,
    'delete-bin': DeleteBin,
    camera: Camera,
    files: Files,
    poll: Poll,
    gallery: Gallery,
    cross: Cross,
    'application-logo-splash': ApplicationLogoSplash,
    'add-task': AddTask,
    share: Share,
    commentPlus: CommentPlus,
    book: Book,
    pen: Pen,
    'list-row': ListRow,
    'list-grid': ListGrid,
    'add-document': AddDocument,
    'map-pin': MapPin,
    phone: Phone,
    clock: Clock,
    layers: Layers,
    invitees: Invitees,
    calendar: Calendar,
    'add-event': AddEvent,
    remove: Remove,
    'chevron-left-white': ChevronLeftWhite,
    'message-white': MessageWhite,
    'edit-white': EditWhite,
    'clock-black': ClockBlack,
    'map-pin-black': MapPinBlack,
    'calendar-black': CalendarBlack,
    'phone-black': PhoneBlack,
    proxy: Proxy,
    'event-status-pending': EventStatusPending,
    'add-document-blue': AddDocumentBlue,
    'add-files': AddFiles,
    'add-picture': AddPicture,
    'add-picture-camera': AddPictureCamera,
    remote: Remote,
    'organization-drag': OrganizationDrag,
    'poll-square': PollSquare,
    'full-screen': FullScreen,
    picture: Picture,
    'bar-chart': BarChart,
    ephemeral: Ephemeral,
    'rotate-right': RotateRight,
    users: Users,
    'members-black': MembersBlack,
    'add-member': AddMember,
    trash: Trash,
    'send-message': SendMessage,
    'camera-filled': CameraFilled,
    'calendar-blue': CalendarBlue,
    clip: Clip,
    'clip-blue': ClipBlue,
    sections: Sections,
    'question-mark': QuestionMark,
    info: Info,
    'arrow-down': ArrowDown,
    action: Action,
    repoDefault: RepositoryDefault,
    membersDefault: MembersDefault,
    settings: Settings,
    help: Help,
    'chevron-left-default': ChevronLeftDefault,
    'chevron-right-default': ChevronRightDefault,
    downloaded: Downloaded,
    'more-vertical': MoreVertical,
    'download-file': DownloadFile,
    'check-circle': CheckCircle,
    'cloud-check': CloudCheck,
    'cloud-times': CloudTimes,
    'editable-small': EditableSmall,
    'chevron-down': ChevronDown,
    'navigation-bar-home': NavigationBarHome,
    publish: Publish,
    'sign-icon': SignIcon,
    'poll-white': PollWhite,
    'sign-out': SignOut,
    'navigation-bar-home-selected': NavigationBarHomeSelected,
    'knowledge-base-default': KnowledgeBaseDefault,
    'draggable-item': DraggableItem,
    'files-blue': FilesBlue,
    warning: Warning,
    'picture-blue': PictureBlue,
    'ai-assist': AI,
    task: Task,
    'chevron-up': ChevronUp,
    'navigation-bar-calendar-white': NavigationBarCalendarWhite,
    'navigation-bar-messages-white': NavigationBarMessagesWhite,
    'navigation-bar-news-white': NavigationBarNewsWhite,
    'navigation-bar-tasks-white': NavigationBarTasksWhite,
    'navigation-bar-home-white': NavigationBarHomeWhite,
    'help-white': HelpWhite,
    'settings-white': SettingsWhite,
    'members-white': MembersWhite,
    'navigation-bar-documents-white': NavigationBarDocumentsWhite,
};
export default Icons;
