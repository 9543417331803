import React from 'react';
import { Size } from '../document-card/constants/size';
import DocumentCardCompact from '../document-card/document-card-compact';
import DocumentCardDefault from '../document-card/document-card-default';
const DocumentCard = ({ size, title, imageURI, eventName, updateDate, titleNbOfLines = 1, onPress, creationDate, type, }) => {
    // Render
    if (size === Size.COMPACT) {
        // Return the Document card compact component
        return (<DocumentCardCompact title={title} eventName={eventName} updateDate={updateDate} onPress={onPress} titleNbOfLines={titleNbOfLines} isAlreadyEnableOffline={true}/>);
    }
    // Return the Document card default component
    return (<DocumentCardDefault title={title} eventName={eventName} imageURI={imageURI} titleNbOfLines={titleNbOfLines} updateDate={updateDate} onPress={onPress} creationDate={creationDate} type={type} isAlreadyEnableOffline={true} isLocalStorage={true}/>);
};
export default DocumentCard;
