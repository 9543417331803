import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    tabsContainer: {
        marginTop: -2,
        width: '100%',
    },
    mainContainer: {
        height: '100%',
        backgroundColor: COLORS.BG.value,
    },
});
export default styles;
