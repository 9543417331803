import React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { AvatarOrCircle } from './avatar-or-circle/index.web';
import Text from '../text';
import { useTranslation } from 'react-i18next';
import { useGroupAvatarsLogic } from './logic.web';
import { tKeys } from '../../../translations/keys';
export const GroupAvatars = ({ group, groupId, showNumberOfMembers, }) => {
    // Attributes
    const { t } = useTranslation();
    const { groupDetails } = useGroupAvatarsLogic(group, groupId);
    const positions = [
        {},
        { bottom: '1rem', right: 0 },
        { bottom: 0, right: '.875rem' },
    ];
    // Render
    return (<HStack spacing={'.625rem'}>
      <Box position="relative" height="2.25rem" width="2.25rem">
        {Array.from({ length: 3 }, (_, index) => {
            const person = groupDetails?.persons[index];
            return (<AvatarOrCircle key={index} person={person} positionStyle={positions[index]}/>);
        })}
      </Box>
      <VStack alignItems={'flex-start'} spacing={'.25rem'}>
        <Text font={FONTS.T1.T12Px.Regular400.value}>{groupDetails?.name}</Text>
        {showNumberOfMembers && (<Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.Text.T300.value}>
            {t(tKeys.groups.members_number, {
                value: groupDetails?.persons?.length,
            })}
          </Text>)}
      </VStack>
    </HStack>);
};
