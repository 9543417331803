import React from 'react';
import COLORS from '../../constants/colors';
import Flex from '../../components/surfaces/flex';
import Text from '../../components/content/text';
import { t } from 'i18next';
import { tKeys } from '../../translations/keys';
import FONTS from '../../constants/fonts';
import Container from '../../components/surfaces/container';
import styles from './style';
import Button from '../../components/inputs/button';
import Modal from '../../components/surfaces/modal/index.web';
import { VStack } from '@chakra-ui/react';
const SignDocument = ({ isOpen, onClose, onClickSign, }) => {
    // Attributes
    const signRules = [
        t(tKeys.sign.instructions.body.first_rule),
        t(tKeys.sign.instructions.body.second_rule),
        t(tKeys.sign.instructions.body.thirth_rule),
        t(tKeys.sign.instructions.body.fourth_rule),
        t(tKeys.sign.instructions.body.fifth_rule),
    ];
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t('title')} actionButton={<Button onPress={onClickSign}>
          <Text color={COLORS.White.T500.value} font={FONTS.T1.T14Px.Medium500.value}>
            {t(tKeys.sign.instructions.button.start_signature_process)}
          </Text>
        </Button>} hasCloseButton>
      <VStack style={styles.mainContainer} width={'full'} p={'1rem'} spacing={'1rem'}>
        <Flex direction="column">
          {/* Instructions header */}
          <Container style={styles.sectionContainer}>
            <Text font={FONTS.T1.T16Px.SemiBold600.value} numberOfLines={10}>
              {t(tKeys.sign.instructions.header)}
            </Text>
          </Container>

          {/* Instruction body header */}
          <Container style={styles.sectionContainer}>
            <Text font={FONTS.T1.T14Px.Medium500.value} numberOfLines={10}>
              {t(tKeys.sign.instructions.body.header)}
            </Text>
          </Container>
          {/* Instruction rules */}
          {signRules?.map((signRule, index) => (<Container key={index} style={styles.sectionRulesContainer}>
              <Text font={FONTS.T1.T14Px.Regular400.value} numberOfLines={10}>{`${index + 1}. ${signRule}`}</Text>
            </Container>))}
        </Flex>
      </VStack>
    </Modal>);
};
export default SignDocument;
