import React from 'react';
import { Path, Svg } from 'react-native-svg';
const Info = (props) => {
    return (<Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6025 1.66675 10.0001 1.66675C5.39771 1.66675 1.66675 5.39771 1.66675 10.0001C1.66675 14.6025 5.39771 18.3334 10.0001 18.3334Z" stroke="#8F95B2" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <Path d="M10.0002 13.3333V10" stroke="#8F95B2" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <Path d="M10.0002 6.66675H10.0086" stroke="#8F95B2" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>);
};
export default Info;
