import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: -6,
        right: -7,
        backgroundColor: COLORS.Govrn.Blue.T100.value,
        width: 19,
        height: 19,
        borderRadius: 16,
        borderWidth: 2,
        borderColor: COLORS.White.T500.value,
    },
});
export default styles;
