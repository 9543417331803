import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, SectionList, } from 'react-native';
import AvatarDetails from '../../components/content/avatar-details';
import Text from '../../components/content/text';
import Container from '../../components/surfaces/container';
import Flex from '../../components/surfaces/flex';
import LineSeparator from '../../components/utility/line-separator';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import ButtonProgression from '../home/feed/components/inputs/button-progression';
import useLogic from './logic.web';
import styles from './style';
import Modal from '../../components/surfaces/modal/index.web';
const VoteResults = ({ isOpen, onClose, voteResultParams, selectedAnswerId, isNewsFeed, }) => {
    // Attributes
    const { t } = useTranslation();
    const { question, allVotesTotal, pendingVotes, handlePress, isClosed, filteredAnswers, votes, selectedAnswer, userAlreadyVoted, canUserVote, } = useLogic(voteResultParams, selectedAnswerId);
    // Render Answer
    const renderAnswer = ({ item, }) => {
        const isSelected = isClosed ? true : selectedAnswer === item?.text;
        const maxValue = userAlreadyVoted ? allVotesTotal : allVotesTotal + 1;
        return (<Container style={styles.inputContainer}>
        <ButtonProgression value={item?.votes?.length} maxValue={maxValue} minValue={0} title={item?.text?.replace('_', '')} isSelected={isSelected} animate={true} isDisabled={isNewsFeed ? true : isClosed} onPress={() => handlePress(item)} backgroundColor={canUserVote ? COLORS.Govrn.Blue.T100.value : COLORS.Tag.value} progressionColor={canUserVote ? COLORS.Govrn.Blue.T500.value : COLORS.Text.T300.value}/>
      </Container>);
    };
    const renderItem = ({ item, section }) => {
        return (<Flex justify="space-between" align="center" style={styles.itemContainer}>
        <AvatarDetails personId={item} avatarSize={'1.75rem'}/>
        <Text font={FONTS.T1.T12Px.SemiBold600.value}>{section?.title}</Text>
      </Flex>);
    };
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.poll.voteResults) ?? ''} hasCloseButton hasFooter={false}>
      <FlatList data={undefined} renderItem={undefined} style={styles.mainContainer} contentContainerStyle={styles.contentContainer} ListFooterComponent={<>
            <Text font={FONTS.T1.T14Px.Regular400.value}>{question?.text}</Text>
            <FlatList listKey={'pollAnswers'} style={styles.flatlist} data={filteredAnswers} renderItem={renderAnswer} keyExtractor={(item, index) => `${item?.text}_${index}`} scrollEnabled={false}/>
            <Text font={FONTS.T1.T14Px.SemiBold600.value}>
              {t(tKeys.voteResults.pendingVotes, { votes: pendingVotes })}
            </Text>

            {!question?.isAnonymous && (<>
                <Container marginVertical={32}>
                  <LineSeparator color={COLORS.Line.value} type="bottom" width={1}/>
                </Container>

                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(tKeys.common.votes)}
                </Text>

                <SectionList style={styles.flatlist} showsVerticalScrollIndicator={false} stickySectionHeadersEnabled={false} sections={votes} renderItem={renderItem} renderSectionHeader={undefined} keyExtractor={(item, index) => `${item}_${index}`} scrollEnabled={false}/>
              </>)}
          </>}/>
    </Modal>);
};
export default VoteResults;
