import React from 'react';
import Button from '../../../../../../components/inputs/button';
import FormControl from '../../../../../../components/inputs/form-control';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic.web';
import Modal from '../../../../../../components/surfaces/modal/index.web';
import { Stack } from '@chakra-ui/react';
import Input from '../../../../../../components/inputs/input/index.web';
const AddEventType = ({ isOpen, onClose, eventType, refetchEventTypes, }) => {
    // Attributes
    const { handleOnSubmit, isCreateEventLoading, form, isUpdateEventLoading } = useLogic(onClose, refetchEventTypes, eventType);
    const { t } = useTranslation();
    // Render
    return (<Modal size={'2xl'} hasCloseButton isOpen={isOpen} onClose={onClose} title={eventType
            ? t(tKeys.settings['manage-events']['modify-event-type']['navigation-header'])
            : t(tKeys.settings['manage-events']['add-event-type']['navigation-header'])} actionButton={<Button onPress={handleOnSubmit} isLoading={isCreateEventLoading || isUpdateEventLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
          {eventType ? t(tKeys.common.save) : t(tKeys.common.create)}
        </Button>}>
      <Stack width={'full'} p={'1rem'}>
        <FormControl label={t(tKeys.common.name.label)} error={form?.eventName?.meta?.error} spacing={4}>
          <Input value={form?.eventName?.value} onChange={e => form?.eventName?.onChange?.(e?.currentTarget?.value)} placeholder={t(tKeys.common.name.placeholder)} autoFocus/>
        </FormControl>
      </Stack>
    </Modal>);
};
export default AddEventType;
