import { useMemo } from 'react';
import { useReadEvent } from '../../../../../../services/online/calendar/calendar';
import { useReadDocument } from '../../../../../../services/online/repository/repository';
const useLogic = (form, message) => {
    // Queries
    const { data: eventData } = useReadEvent(message?.eventId, {
        query: {
            enabled: !!message?.eventId,
        },
    });
    const { data: documentRepositoryData } = useReadDocument(message?.documentId, {}, {
        query: {
            enabled: !!message?.documentId,
        },
    });
    // Functions
    const isSelected = useMemo(() => {
        return form?.deletedMessageIds?.value?.some(id => message.id === id);
    }, [form?.deletedMessageIds]);
    const onSelectMessage = () => {
        if (isSelected) {
            const index = form?.deletedMessageIds?.value?.findIndex(id => id === message?.id);
            const arrayCopy = form?.deletedMessageIds?.value;
            arrayCopy?.splice(index, 1);
            form?.deletedMessageIds?.onChange(arrayCopy);
        }
        else {
            form?.deletedMessageIds?.onChange([
                ...form?.deletedMessageIds?.value,
                message?.id,
            ]);
        }
    };
    const isImage = message?.file?.mimeType?.startsWith('image');
    // Return
    return {
        eventData,
        documentRepositoryData,
        isSelected,
        onSelectMessage,
        isImage,
    };
};
export default useLogic;
