import { DocumentKindEnum } from './type';
import moment from 'moment';
export function mapToOfflineDocumentEntityFromDocument(document, taskType) {
    return {
        kind: DocumentKindEnum.DOCUMENT,
        title: document?.title,
        description: document?.description,
        eventTitle: getEventTitle(document?.eventName, document?.eventStartDate),
        taskType,
        filename: document?.filename,
        filePath: document?.filePath,
        fileThumbnailPath: document?.fileThumbnailPath,
    };
}
export function mapToOfflineDocumentEntityFromMinutes(document) {
    return {
        kind: DocumentKindEnum.MINUTES,
        title: document?.title,
        description: document?.description,
        eventTitle: getEventTitle(document?.eventName, document?.eventStartDate),
        filename: document?.filename,
        filePath: document?.filePath,
        fileThumbnailPath: document?.fileThumbnailPath,
    };
}
// Helper function
function getEventTitle(eventName, eventStartDate) {
    if (!eventName && !eventStartDate)
        return '';
    return `In ${eventName} ${moment(new Date(eventStartDate)).format('D/MM/yyyy')}`;
}
