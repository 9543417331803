import { StyleSheet } from 'react-native';
import COLORS from '../../../../../constants/colors';
export default StyleSheet.create({
    container: {
        padding: 8,
        backgroundColor: COLORS.Tag.value,
        borderRadius: 4,
        marginHorizontal: 8,
    },
    notificationContainer: {
        borderRadius: 100,
        backgroundColor: COLORS.Error.T500.value,
        width: 24,
        height: 20,
        justifyContent: 'center',
    },
    notificationText: {
        color: 'white',
        textAlign: 'center',
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
    },
    icon: {
        width: 20,
        height: 20,
        preseveAspectRatio: 'xMinYMin slice',
        viewBox: '0 0 100 100',
    },
});
