import React from 'react';
import Text from '../../../../../../../components/content/text';
import FONTS from '../../../../../../../constants/fonts';
import styles from './style';
import { HStack, Switch } from '@chakra-ui/react';
import COLORS from '../../../../../../../constants/colors';
const CalendarFilterEventTypes = ({ id, isSelected, label, handleOnChangeSwitchButton, }) => {
    // Render
    return (<HStack justify="space-between" style={styles.buttonFilterContainer}>
      {/* File type name */}
      <Text font={FONTS.T1.T12Px.Medium500.value}>{label}</Text>

      {/* Switch button */}
      <Switch isChecked={isSelected} onChange={() => handleOnChangeSwitchButton(id)} _checked={{
            '& .chakra-switch__track': {
                backgroundColor: COLORS.Success.T500.value,
            },
        }}/>
    </HStack>);
};
export default CalendarFilterEventTypes;
