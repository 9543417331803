/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates a template
 */
export const createTemplate = (template, options) => {
    return customInstance({
        url: `/knowledge-base`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: template,
    }, options);
};
export const useCreateTemplate = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createTemplate(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a paginated list of templates with filtering support
 */
export const readTemplates = (params, options, signal) => {
    return customInstance({ url: `/knowledge-base`, method: 'get', params, signal }, options);
};
export const getReadTemplatesQueryKey = (params) => [
    `/knowledge-base`,
    ...(params ? [params] : []),
];
export const useReadTemplates = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadTemplatesQueryKey(params);
    const queryFn = ({ signal, }) => readTemplates(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a template
 */
export const updateTemplate = (template, options) => {
    return customInstance({
        url: `/knowledge-base`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: template,
    }, options);
};
export const useUpdateTemplate = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateTemplate(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes a template
 */
export const deleteTemplate = (templateId, options) => {
    return customInstance({ url: `/knowledge-base/${templateId}`, method: 'delete' }, options);
};
export const useDeleteTemplate = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { templateId } = props ?? {};
        return deleteTemplate(templateId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Downloads a template
 */
export const downloadTemplate = (templateId, options, signal) => {
    return customInstance({
        url: `/knowledge-base/${templateId}/download`,
        method: 'get',
        responseType: 'blob',
        signal,
    }, options);
};
export const getDownloadTemplateQueryKey = (templateId) => [
    `/knowledge-base/${templateId}/download`,
];
export const useDownloadTemplate = (templateId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getDownloadTemplateQueryKey(templateId);
    const queryFn = ({ signal }) => downloadTemplate(templateId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!templateId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves a list of cultures
 */
export const readKnowledgeBaseCultures = (options, signal) => {
    return customInstance({ url: `/knowledge-base/cultures`, method: 'get', signal }, options);
};
export const getReadKnowledgeBaseCulturesQueryKey = () => [
    `/knowledge-base/cultures`,
];
export const useReadKnowledgeBaseCultures = (options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadKnowledgeBaseCulturesQueryKey();
    const queryFn = ({ signal }) => readKnowledgeBaseCultures(requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Creates a knowledge base category
 */
export const createCategory = (category, options) => {
    return customInstance({
        url: `/knowledge-base/categories`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: category,
    }, options);
};
export const useCreateCategory = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createCategory(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves the list of knowledge base categories
 */
export const readCategories = (params, options, signal) => {
    return customInstance({ url: `/knowledge-base/categories`, method: 'get', params, signal }, options);
};
export const getReadCategoriesQueryKey = (params) => [
    `/knowledge-base/categories`,
    ...(params ? [params] : []),
];
export const useReadCategories = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadCategoriesQueryKey(params);
    const queryFn = ({ signal, }) => readCategories(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates a knowledge base category
 */
export const updateCategory = (category, options) => {
    return customInstance({
        url: `/knowledge-base/categories`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: category,
    }, options);
};
export const useUpdateCategory = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateCategory(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes a knowledge base category
 */
export const deleteCategory = (categoryId, options) => {
    return customInstance({ url: `/knowledge-base/categories/${categoryId}`, method: 'delete' }, options);
};
export const useDeleteCategory = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { categoryId } = props ?? {};
        return deleteCategory(categoryId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
