import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    mainContent: {
        padding: '1rem',
        height: 96,
        borderRadius: 12,
    },
    imageBackground: {
        flex: 1,
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: 12,
    },
});
export default styles;
