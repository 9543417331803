import { useDisclosure } from '@chakra-ui/react';
import { useReadResolutions } from '../../../../../../services/online/calendar/calendar';
import { useState } from 'react';
const useLogic = (event) => {
    // Attributes
    const { isOpen: isOpenEventResolution, onOpen: onOpenEventResolution, onClose: onCloseEventResolution, } = useDisclosure();
    const [resolutionEntity, setResolutionEntity] = useState();
    // Queries
    const { data: eventResolutions, isLoading: isEventResolutionsLoading, refetch: refetchResolutions, } = useReadResolutions({
        eventId: event?.id,
    });
    // Functions
    function handleCreateResolution() {
        setResolutionEntity({ eventId: event?.id });
        onOpenEventResolution();
    }
    function onRefetchResolutions() {
        void refetchResolutions();
    }
    function handleUpdateResolution(resolutionId) {
        const findedResolution = eventResolutions?.find(r => r?.id === resolutionId);
        // Update the entity
        const params = {
            ...findedResolution,
        };
        setResolutionEntity(params);
        // Send the entity
        onOpenEventResolution();
    }
    // Return
    return {
        eventResolutions,
        isEventResolutionsLoading,
        handleCreateResolution,
        handleUpdateResolution,
        isOpenEventResolution,
        onCloseEventResolution,
        resolutionEntity,
        onRefetchResolutions,
    };
};
export default useLogic;
