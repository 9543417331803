import { useEffect, useMemo, useState } from 'react';
import useRouter from '../../../hooks/router/useRouter';
import { mapToOfflineDocumentEntityFromDocument, mapToOfflineDocumentEntityFromMinutes, } from './mappers';
import { DocumentKindEnum, } from './type';
import { deleteDocument, getDocumentById, } from '../../../services/offline/db-service';
import { deleteFile } from '../../../utils/downloadFile';
import useConnectionState from '../../../hooks/connection-state/useConnectionState';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { goBack, params, push, replace } = useRouter();
    const [showMoreModal, setShowMoreModal] = useState();
    const { connectionState } = useConnectionState();
    // Query params
    const { documentKind, documentId, taskType } = params ?? {};
    const [document, setDocument] = useState();
    const [isDocumentLoading, setIsDocumentLoading] = useState(false);
    const [isSync, setIsSync] = useState(true);
    // Entity
    const documentEntity = useMemo(() => {
        if (documentKind === DocumentKindEnum.DOCUMENT || document)
            return mapToOfflineDocumentEntityFromDocument(document, taskType);
        if (documentKind === DocumentKindEnum.MINUTES)
            return mapToOfflineDocumentEntityFromMinutes(document);
    }, [document, taskType]);
    const onFullScreenPress = () => {
        push('/offline/offline-document-viewer', {
            documentUri: document?.filePath,
            documentTitle: document?.filename,
            document,
        });
    };
    function handleOpenMoreModal() {
        setShowMoreModal(true);
    }
    function handleCloseMoreModal() {
        setShowMoreModal(false);
    }
    async function handleDisableOffline() {
        try {
            await deleteDocument(document.rowid).then(() => Toast.show({
                type: 'error',
                text1: t(tKeys.document.disable_offline),
            }));
            deleteFile(document?.filePath);
            deleteFile(document?.fileThumbnailPath);
        }
        catch (err) {
            console.log(err);
        }
        handleCloseMoreModal();
        replace('/offline');
    }
    function fetchDocument() {
        getDocumentById(documentId).then(document => {
            setDocument(document);
            setIsDocumentLoading(false);
            setIsSync(document.offlineUpdated === 0);
        });
    }
    // Effect
    useEffect(() => {
        setIsDocumentLoading(true);
        fetchDocument();
    }, [documentId]);
    useEffect(() => {
        fetchDocument();
    });
    // Return
    return {
        onGoBack: goBack,
        onFullScreenPress,
        isLoading: !!documentId && isDocumentLoading,
        documentEntity,
        showMoreModal,
        handleOpenMoreModal,
        handleCloseMoreModal,
        handleDisableOffline,
        connectionState,
        isSync,
    };
};
export default useLogic;
