import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Image from '../../../../../../components/content/image';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import { Shape } from '../../../../../../constants/shape';
import { Box, Stack } from '@chakra-ui/react';
import COLORS from '../../../../../../constants/colors';
const DocumentDetailsPreviewer = ({ url, onFullScreenPress, }) => {
    return (<Stack width={'full'} height={'20rem'} backgroundColor={COLORS.Line.value} onClick={onFullScreenPress} justifyContent={'center'} alignItems={'center'} position={'relative'}>
      <Image imageURI={url || require('../../../../../../assets/images/pdf_preview.png')} width={!url ? '5.625rem' : '20rem'} height={!url ? '7rem' : '15rem'} isLocalImage={!url} resizeMode="cover" isLocalStorage={url?.includes('/storage')}/>
      <Box position={'absolute'} bottom={'1rem'} right={'1rem'}>
        <Button shape={Shape.ROUND} size={Size.MINI} kind={Kind.SECONDARY} onPress={onFullScreenPress}>
          <Icon name="full-screen" width={24} height={24}/>
        </Button>
      </Box>
    </Stack>);
};
export default DocumentDetailsPreviewer;
