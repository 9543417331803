import { TaskType } from '../services/types';
export var TaskTypeEnum;
(function (TaskTypeEnum) {
    TaskTypeEnum[TaskTypeEnum["Read"] = TaskType?.[1]] = "Read";
    TaskTypeEnum[TaskTypeEnum["RSVP"] = TaskType?.[2]] = "RSVP";
    TaskTypeEnum[TaskTypeEnum["Proxy"] = TaskType?.[3]] = "Proxy";
    TaskTypeEnum[TaskTypeEnum["Vote"] = TaskType?.[4]] = "Vote";
    TaskTypeEnum[TaskTypeEnum["Sign"] = TaskType?.[5]] = "Sign";
    TaskTypeEnum[TaskTypeEnum["Custom"] = TaskType?.[6]] = "Custom";
})(TaskTypeEnum || (TaskTypeEnum = {}));
