import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    crossButtonLight: {
        position: 'absolute',
        right: 7,
        top: 7,
        width: 20,
        height: 20,
        borderRadius: 6,
        backgroundColor: COLORS.White.T500.value,
        zIndex: 9999,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    crossButtonDark: {
        width: 20,
        height: 20,
        borderRadius: 7,
        backgroundColor: COLORS.Black.T500.value,
        zIndex: 9999,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    fileIconContainer: {
        width: 24,
        height: 24,
        backgroundColor: COLORS.PlaceholderBG.value,
        borderRadius: 6,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileContainer: {
        minWidth: 217,
        height: 40,
        borderRadius: 8,
        borderColor: COLORS.Line.value,
        borderWidth: 1,
        backgroundColor: COLORS.Disabled.value,
        padding: 8,
    },
});
export default styles;
