// theme.js
import { extendTheme } from '@chakra-ui/react';
import COLORS from './src/constants/colors';
const theme = extendTheme({
    components: {
        Checkbox: {
            baseStyle: {
                control: {
                    _checked: {
                        background: COLORS.Success.T500.value,
                        borderColor: COLORS.Success.T500.value,
                        _hover: {
                            background: COLORS.Success.T500.value,
                            borderColor: COLORS.Success.T500.value,
                        },
                    },
                },
            },
        },
    },
});
export default theme;
