import { HStack, Stack } from '@chakra-ui/react';
import COLORS from '../../../../constants/colors';
import React, { useMemo } from 'react';
import Text from '../../text';
import FONTS from '../../../../constants/fonts';
import Icon from '../../icon';
export const Toast = ({ status, handleOnClose, title, isClosable = true, }) => {
    // Attributes
    const iconStatus = useMemo(() => {
        switch (status) {
            case 'error':
                return { color: COLORS.Error.T500.value, name: 'toast-error' };
            case 'success':
                return { color: COLORS.Success.T500.value, name: 'toast-success' };
            case 'info':
                return { color: COLORS.Govrn.Blue.T400.value, name: 'info' };
            case 'warning':
                return { color: COLORS.Warning.T500.value, name: 'warning' };
            default:
                return { color: COLORS.Success.T500.value, name: 'toast-error' };
        }
    }, [status]);
    // Render
    return (<HStack backgroundColor={COLORS.White.T500.value} width={'100%'} borderRadius={'.75rem'} justifyContent={'space-between'} p={'.875rem'} marginTop={'2rem'} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'}>
      <HStack width={'full'} spacing={'.75rem'}>
        {/* Icon */}
        <Stack bgColor={iconStatus.color} p={'.375rem'} borderRadius={'.375rem'}>
          <Icon name={iconStatus.name} height={18} width={18}/>
        </Stack>
        {/* Title */}
        <Stack>
          <Text font={FONTS.T1.T12Px.Medium500.value}>{title}</Text>
        </Stack>
      </HStack>
      {/* Close icon */}
      {isClosable && (<Stack onClick={handleOnClose}>
          <Icon name="close-cross" height={18} width={18}/>
        </Stack>)}
    </HStack>);
};
