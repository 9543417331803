import React from 'react';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Flex from '../../../../components/surfaces/flex';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import DocumentList from '../components/list/document-list/index.web';
import useLogic from './logic.web';
import ButtonAddDocument from '../components/input/button-add-document';
import CreateDocument from './create-document/index.web';
import { HStack } from '@chakra-ui/react';
import FilterSelect from './filter-select/index.web';
import Text from '../../../../components/content/text';
import Icon from '../../../../components/content/icon';
import { Pressable } from 'react-native';
import { FilterDate } from './filter-date/index.web';
const Repository = () => {
    // Attributes
    const { t, optionsGroupBy, handleOnPressTab, selectedTab, selectedRepositoryFilters, handleOnPressAddDocument, documentsData, isDocumentDataLoading, isDropdownOpen, listLayoutType, onDocumentPress, onLayoutIconPress, optionsOrder, refetchDocumentData, setDropdownOpen, isOpenCreateDocument, onCloseCreateDocument, selectedFile, refetchDocuments, canUploadDocument, setOrderBy, orderBy, translatedDocumentTypesFilter, documentSources, handleOnDocumentTypeFilterChange, handleOnDocumentRelationChange, handleOnSetFromDateChange, fromDate, onToggleFromPopOver, isFromPopoverOpen, handleOnSetToDateChange, toDate, onToggleToPopOver, isToPopoverOpen, handleOnGroupByChange, selectedOptionGroupBy, selectedDocumentRelation, selectedDocumentTypes, } = useLogic();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader style={{ zIndex: 900, minHeight: '3.75rem' }} justifyTitle={'flex-start'} endEnhancer={<Flex spacing={20} align="center">
            {canUploadDocument && (<ButtonAddDocument onClickButtonAddDocument={handleOnPressAddDocument}/>)}
          </Flex>}>
        {t(tKeys.common.repository)}
      </NavigationHeader>

      <HStack width={'100%'} wrap={'wrap'} spacing={4} alignItems="center" align="normal" p={4} backgroundColor={'#f8f9fc'} style={{ paddingLeft: '2rem' }}>
        <FilterSelect options={documentSources} placeholder={t(tKeys.common.document_sources)} onChange={handleOnDocumentRelationChange} isMulti={false} value={selectedDocumentRelation}/>
        <FilterSelect options={translatedDocumentTypesFilter} placeholder={t(tKeys.common.document_types)} onChange={handleOnDocumentTypeFilterChange} isMulti={true} value={selectedDocumentTypes}/>
        <FilterDate date={fromDate} handleOnDateChange={handleOnSetFromDateChange} togglePopover={isFromPopoverOpen} onTogglePopOver={onToggleFromPopOver} textDate={t(tKeys.common.from)}/>

        <FilterDate date={toDate} handleOnDateChange={handleOnSetToDateChange} togglePopover={isToPopoverOpen} onTogglePopOver={onToggleToPopOver} textDate={t(tKeys.common.to)}/>

        <FilterSelect options={optionsGroupBy} placeholder={t(tKeys.common['group-by'])} onChange={handleOnGroupByChange} isMulti={false} value={selectedOptionGroupBy}/>
        {/* reset filter */}
        <Pressable onPress={() => {
            handleOnSetFromDateChange(null);
            handleOnSetToDateChange(null);
            handleOnDocumentTypeFilterChange([]);
            handleOnDocumentRelationChange(null);
            handleOnGroupByChange(optionsGroupBy[0]);
        }}>
          <HStack>
            <Icon name="close-cross" width={14} height={14} fill={COLORS.Govrn.Blue.T500.value}/>
            <Text color={COLORS.Govrn.Blue.T500.value}>
              {' '}
              {t(tKeys.common['reset-filter'])}
            </Text>
          </HStack>
        </Pressable>
      </HStack>

      {/* Main content */}
      <DocumentList kind="document" documentsData={documentsData} isDocumentDataLoading={isDocumentDataLoading} listLayoutType={listLayoutType} onDocumentPress={onDocumentPress} onLayoutIconPress={onLayoutIconPress} optionsOrder={optionsOrder} refetchDocumentData={refetchDocumentData} setOrderBy={setOrderBy} orderBy={orderBy}/>
      {/* Modal */}
      <CreateDocument isOpen={isOpenCreateDocument} onClose={onCloseCreateDocument} selectedFile={selectedFile} refetchDocuments={refetchDocuments}/>
    </Page>);
};
export default Repository;
