import { useMemo, useState } from 'react';
import { useCreateGroup, useUpdateGroup, } from '../../../../../../services/online/groups/groups';
import { useReadEnrolments } from '../../../../../../services/online/enrolments/enrolments';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
import { useFormik } from 'formik';
import { validationSchema } from './validation';
import { useTranslation } from 'react-i18next';
import { createForm } from '../../../../../../utils/formik';
import _ from 'lodash';
export const useEditGroupLogic = (group, refetchGroups, onClose) => {
    // Attributes
    const { t } = useTranslation();
    const isEditMode = !!group;
    const [search, setSearch] = useState('');
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { mutateAsync: createGroup, isLoading: isCreateGroupLoading } = useCreateGroup();
    const { mutateAsync: updateGroup, isLoading: isUpdateGroupLoading } = useUpdateGroup();
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': search,
    }, { query: { enabled: !!selectedCompanyId } });
    // Functions
    async function handleOnSubmit() {
        if (isEditMode) {
            await updateGroup({
                data: {
                    companyId: group.companyId,
                    id: group.id,
                    name: form.name.value,
                    persons: form.persons.value,
                },
            });
            handleModalClose();
            refetchGroups();
            return;
        }
        await createGroup({
            data: {
                companyId: selectedCompanyId,
                name: form.name.value,
                persons: form.persons.value,
            },
        });
        handleModalClose();
        refetchGroups();
    }
    function handleModalClose() {
        onClose();
        resetForm();
    }
    function isPersonSelected(personId) {
        return !!values.persons.find(person => person.personId === personId);
    }
    function allowedHistoricalDataForPerson(personId) {
        return values.persons.find(person => person.personId === personId)
            .allowHistoricalData;
    }
    function handlePersonCheck(personId) {
        const personsSelected = _.clone(values.persons);
        if (!isPersonSelected(personId)) {
            personsSelected.push({
                allowHistoricalData: false,
                personId,
            });
            form.persons.onChange(personsSelected);
            return;
        }
        const personsSelectedFiltered = personsSelected.filter(person => person.personId !== personId);
        form.persons.onChange(personsSelectedFiltered);
    }
    function handleAllowHistoricalDataGiven(personId) {
        const personsSelected = _.clone(values.persons);
        const personFound = personsSelected.find(person => person.personId === personId);
        if (!personFound)
            return;
        personFound.allowHistoricalData = !personFound.allowHistoricalData;
        form.persons.onChange(personsSelected);
    }
    function mapGroupPersonToGroupPersonUpsert() {
        if (!group)
            return [];
        return (group.persons?.map(person => {
            return {
                allowHistoricalData: person.allowHistoricalData,
                personId: person.personId,
            };
        }) ?? []);
    }
    const peopleInTheCompany = enrolments?.items?.map(enrolment => enrolment.person) ?? [];
    const initialValues = useMemo(() => {
        if (!group)
            return { name: '', persons: [] };
        return {
            name: group.name ?? '',
            persons: mapGroupPersonToGroupPersonUpsert(),
        };
    }, [group]);
    // Form
    const { values, dirty, setFieldError, ...rest } = useFormik({
        initialValues,
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit, resetForm } = rest;
    const form = createForm(values, rest);
    const updateSearchDebounce = _.debounce((value) => {
        setSearch(value);
    }, 500);
    // Return
    return {
        isCreateGroupLoading,
        isUpdateGroupLoading,
        isEditMode,
        handleSubmit,
        search,
        setSearch: updateSearchDebounce,
        peopleInTheCompany,
        form,
        handleModalClose,
        isPersonSelected,
        allowedHistoricalDataForPerson,
        handlePersonCheck,
        handleAllowHistoricalDataGiven,
    };
};
