import moment from 'moment';
import React from 'react';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import EventCardDate from '../event-card-date/index.web';
import Text from '../../../content/text';
import Tag from '../../../content/tag/index.web';
import { tKeys } from '../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../../hooks/format-date/useFormatDate';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import { PresenceStatus } from '../../../../screens/home/calendar/event-details/types';
import Icon from '../../../content/icon';
import { BusyIndicator } from '../../../utility/busy-indicator';
const EventCardContent = ({ eventEndDate, eventStartDate, eventType, eventTitle, isEventPast, isPublished, onClick, presence, isTasksLoading, tasksToDoLength, isCurrentUserInvited, }) => {
    // Attributes
    const { t } = useTranslation();
    const { formatDateTime } = useFormatDate();
    const canRenderTasks = !isEventPast &&
        !isTasksLoading &&
        !!presence &&
        presence.status !== PresenceStatus.Absent &&
        presence.status !== PresenceStatus.Proxy;
    // Functions
    function renderTagPresence() {
        if (presence) {
            return (<Tag backgroundColor={presence?.bgColor} iconColor={presence?.colors} color={COLORS.Text.T400.value} iconName={presence?.iconName} style={{ borderRadius: 100, paddingHorizontal: 8 }} font={FONTS.T1.T14Px.Medium500.value}>
          {presence?.title}
        </Tag>);
        }
        if (isEventPast || !isCurrentUserInvited)
            return;
        return (<Text font={FONTS.T1.T16Px.Medium500.value}>
        {t(tKeys.home.calendar.event_details.information.presence.title)}
      </Text>);
    }
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} borderRadius={'.75rem'} p={'1.25rem'} backgroundColor={!isEventPast && COLORS.White.T500.value} borderWidth={1} borderColor={COLORS.Line.value} _hover={{ borderColor: COLORS.Text.T400.value }} cursor={'pointer'} spacing={'.75rem'} onClick={onClick}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <HStack spacing={'.75rem'}>
          {/*  Event Date */}
          <EventCardDate date={eventStartDate}/>

          <VStack width={'full'} alignItems={'flex-start'}>
            <HStack spacing={'.75rem'}>
              {/*  Event Name */}
              <Text font={FONTS.T1.T20Px.Bold700.value}>{eventTitle}</Text>
              {!isPublished && (<Tag font={FONTS.T1.T10Px.Medium500.value} color={COLORS.InputText.value} backgroundColor={COLORS.Stroke.value} width={'100%'}>
                  {t(tKeys.home.calendar.card_event.status.draft)}
                </Tag>)}
            </HStack>

            {/*  Event Hours */}
            <HStack align="center" spacing={'1.25rem'}>
              <Tag font={FONTS.T1.T12Px.Medium500.value} backgroundColor={COLORS.Line.value} color={COLORS.Text.T400.value}>
                {t(eventType.name)}
              </Tag>
              <HStack>
                <Icon name="clock-black" height={20} width={20}/>
                <Text font={FONTS.T1.T14Px.Regular400.value}>
                  {`${formatDateTime({
            date: moment.utc(eventStartDate).toDate(),
            formatWithTimezone: true,
            format: 'HH:mm',
        })} - ${formatDateTime({
            date: moment.utc(eventEndDate).toDate(),
            formatWithTimezone: true,
            format: 'HH:mm',
        })}`}
                </Text>
              </HStack>
              {canRenderTasks && (<BusyIndicator isEmpty={tasksToDoLength <= 0} placeholder={<Text font={FONTS.T1.T14Px.Regular400.value}>
                      {t(tKeys.event.card.tasks_completed)}
                    </Text>}>
                  <HStack>
                    <Icon name="task" height={20} width={20}/>
                    <Text font={FONTS.T1.T14Px.Regular400.value}>
                      {t(tKeys.event.details.number_of_tasks, {
                value: tasksToDoLength,
            })}
                    </Text>
                  </HStack>
                </BusyIndicator>)}
            </HStack>
          </VStack>
        </HStack>
        {/* Presence */}
        <Stack>{renderTagPresence()}</Stack>
      </HStack>
    </VStack>);
};
export default EventCardContent;
