import React from 'react';
import Icon from '../../../../../components/content/icon';
import Tag from '../../../../../components/content/tag';
import COLORS from '../../../../../constants/colors';
import FONTS from '../../../../../constants/fonts';
import useLogic from './logic';
import styles from './style';
import { HStack, Text, VStack } from '@chakra-ui/react';
const SearchResultCard = ({ searchResult, onPress, }) => {
    // Attributes
    const { title, subtitle, tagProps } = useLogic(searchResult);
    // Render
    return (<HStack width={'full'} alignItems={'center'} onClick={onPress} px={'.5rem'} py={'.75rem'}>
      <VStack width={'full'} alignItems={'flex-start'} spacing={'.75rem'}>
        <Text isTruncated fontWeight={'600'} fontFamily={'Inter-SemiBold'}>
          {title}
        </Text>
        <HStack width={'full'} spacing={'.75rem'}>
          <Tag {...tagProps} height={20} font={FONTS.T1.T10Px.Medium500.value} style={styles.tag}/>
          <Text fontWeight={'600'} fontSize={'0.625rem'} fontFamily={'Inter-Regular'} color={COLORS.InputText.value} isTruncated maxWidth={'25rem'}>
            {subtitle}
          </Text>
        </HStack>
      </VStack>
      <VStack alignItems="center">
        <Icon name="chevron-right" fill={COLORS.InputText.value} width={20} height={20}/>
      </VStack>
    </HStack>);
};
export default SearchResultCard;
