import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../../components/inputs/form-control';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import EmailAddressCompany from '../../components/inputs/email-address-company/index.web';
import { boxSectionStyle } from '../my-informations/style.web';
import useLogic from './logic.web';
const EmailAddress = () => {
    // Attributes
    const { currentUserData, handleOnRequestEmailChange, handleOnPressBackButton, } = useLogic();
    const { t } = useTranslation();
    const CompaniesAddressMails = () => {
        if (!currentUserData) {
            return <></>;
        }
        return (<>
        {currentUserData?.enrolments?.map((enrolment, index) => (<EmailAddressCompany key={index} enrolment={enrolment} index={index} handleOnEndEditing={(newEmail) => handleOnRequestEmailChange(enrolment, newEmail)}/>))}
      </>);
    };
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={handleOnPressBackButton} style={{ minHeight: '3.75rem' }}>
        {`${t(tKeys.profile['user-profile']['my-information'])} / ${t(tKeys.profile['user-profile']['email-address']['navigation-header'])}`}
      </NavigationHeader>

      <Stack width={'full'} height={'full'} bgColor={COLORS.BG.value} p={'1rem'}>
        {/* Addresses mails */}
        <Stack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile['user-profile']['email-address']['address-mails'])} spacing={4}>
            <CompaniesAddressMails />
          </FormControl>
        </Stack>
      </Stack>
    </Page>);
};
export default EmailAddress;
