import React from 'react';
import Text from '../../../../../../components/content/text';
import { HStack, Switch, VStack } from '@chakra-ui/react';
import COLORS from '../../../../../../constants/colors';
const TasksFilterContentModal = ({ form, }) => {
    function SwitchWithText({ title, onChange, value, }) {
        return (<HStack justify="space-between" align="center" width={'full'}>
        <Text>{title}</Text>
        <Switch isChecked={value} onChange={() => onChange(!value)} _checked={{
                '& .chakra-switch__track': {
                    backgroundColor: COLORS.Success.T500.value,
                },
            }}/>
      </HStack>);
    }
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'} spacing={'0.875rem'} p={'1rem'}>
      {/* Files */}
      <SwitchWithText {...form?.filesSwitch} title="Files"/>

      {/* RSVP */}
      <SwitchWithText {...form?.rsvpSwitch} title="RSVP"/>

      {/* Proxy */}
      <SwitchWithText {...form?.proxySwitch} title="Proxy"/>

      {/* Votes */}
      <SwitchWithText {...form?.votesSwitch} title="Votes"/>

      {/* Signature */}
      <SwitchWithText {...form?.signatureSwitch} title="Signature"/>

      {/* Custom */}
      <SwitchWithText {...form?.customSwitch} title="Custom"/>
    </VStack>);
};
export default TasksFilterContentModal;
