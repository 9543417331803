import { useState, useEffect } from 'react';
import useRouter from '../../hooks/router/useRouter';
import { tKeys } from '../../translations/keys';
import { useTranslation } from 'react-i18next';
const useLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const languages = [
        {
            label: t(tKeys.languages.english),
            value: 'en-GB',
        },
        { label: t(tKeys.languages.french), value: 'fr-FR' },
        { label: t(tKeys.languages.dutch), value: 'nl-NL' },
        { label: t(tKeys.languages.spanish), value: 'es-ES' },
    ];
    const { goBack, params } = useRouter();
    const formCultureName = params?.formCultureName;
    const [selectedLanguage, setSelectedLanguage] = useState();
    // Functions
    const handleOnPressBackButton = () => {
        goBack();
    };
    const handleOnPress = (language) => {
        const selectedLanguage = languages?.find(item => item?.value === language);
        setSelectedLanguage(selectedLanguage?.value);
        formCultureName?.onChange(selectedLanguage?.value);
        goBack();
    };
    // Effects
    useEffect(() => {
        if (formCultureName)
            setSelectedLanguage(languages?.find(language => language?.value === formCultureName?.value)
                ?.value);
    }, [formCultureName]);
    // Return
    return {
        languages,
        handleOnPressBackButton,
        selectedLanguage,
        handleOnPress,
    };
};
export default useLogic;
