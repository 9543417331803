/* eslint-disable react/prop-types */
import React from 'react';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Placeholder from '../../../../../../components/utility/placeholder';
import { tKeys } from '../../../../../../translations/keys';
import { BusyIndicator } from '../../../../../../components/utility/busy-indicator';
import { useEventDetailsAISummaryLogic } from './logic.web';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
const EventDetailsAISummary = ({ isAdmin, eventSummary, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isModuleAvailable, supportEmail, handleMailto } = useEventDetailsAISummaryLogic();
    const formattedSummary = eventSummary
        ? eventSummary.replace(/\\n/gi, '\n')
        : null;
    const Heading1 = ({ children }) => (<h1 style={{ fontWeight: 'bold' }}>{children}</h1>);
    const Heading2 = ({ children }) => (<h2 style={{ fontWeight: 'bold' }}>{children}</h2>);
    const List = ({ children }) => (<ul style={{ paddingLeft: '20px' }}>{children}</ul>);
    const ListItem = ({ children }) => <li>{children}</li>;
    // Render
    return (<Stack pb={'2rem'} width={'100%'} overflow={'auto'}>
      <BusyIndicator isEmpty={!isModuleAvailable} placeholder={<Placeholder title={t(tKeys.event.details.AI.module.title)} imageType={'monocle-emoji'} hasImage={false} subTitle={t(tKeys.event.details.AI.module.subtitle, {
                value: supportEmail,
            })} endEnhancer={isAdmin && (<Box pt={'1.25rem'}>
                  <Button kind={Kind.SECONDARY} onPress={handleMailto}>
                    {t(tKeys.common.send_email)}
                  </Button>
                </Box>)}/>}>
        <BusyIndicator isEmpty={!eventSummary} placeholder={<Placeholder title={t(tKeys.event.details.AI.placeholder.title)} imageType={'monocle-emoji'} subTitle={''} hasImage={false}/>}>
          <Stack p={'1.25rem'}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} components={{
            h1: Heading1,
            h2: Heading2,
            ul: List,
            li: ListItem,
        }}>
              {formattedSummary}
            </ReactMarkdown>
          </Stack>
        </BusyIndicator>
      </BusyIndicator>
    </Stack>);
};
export default EventDetailsAISummary;
