import { stackNavigator } from '../../routes/navigators';
import Repository from './offline-repository';
import DocumentDetails from './offline-document-details';
import DocumentViewer from './offline-document-viewer';
const offlineModeRoutes = [
    {
        name: '/offline',
        deepLink: '/offline',
        defaultRoute: '/offline-repository',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/offline-repository',
                deepLink: '/offline-repository',
                component: Repository,
            },
            {
                name: '/offline-document-details',
                deepLink: '/offline-document-details',
                component: DocumentDetails,
            },
            {
                name: '/offline-document-viewer',
                deepLink: '/offline-document-viewer',
                component: DocumentViewer,
            },
        ],
    },
];
export default offlineModeRoutes;
