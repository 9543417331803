import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        paddingHorizontal: 32,
        paddingTop: 8,
        paddingBottom: 10,
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        borderWidth: 1,
        borderColor: COLORS.Line.value,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.Line.value,
    },
});
export default styles;
