import { StyleSheet } from 'react-native';
import COLORS from '../../../../../constants/colors';
import CUSTOM_SHADOWS from '../../../../../utils/shadows';
const styles = StyleSheet.create({
    mainContainer: {
        height: 74,
        backgroundColor: COLORS.White.T500.value,
        padding: 16,
        borderRadius: 12,
        ...CUSTOM_SHADOWS.FormControlContainer,
    },
    tag: { paddingHorizontal: 6, borderRadius: 4 },
});
export default styles;
