import GenerateEventDocument from '.';
import { stackNavigator } from '../../../../routes/navigators';
const GenerateEventDocumentRoutes = [
    {
        name: '/generate-event-document',
        deepLink: '/generate-event-document',
        defaultRoute: 'default',
        component: stackNavigator,
        subRoutes: [
            {
                name: '/default',
                deepLink: '/default',
                component: GenerateEventDocument,
            },
        ],
    },
];
export default GenerateEventDocumentRoutes;
