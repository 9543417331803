import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../hooks/router/useRouter';
import { DocumentKindEnum } from '../../document-details/type';
import { ListLayoutEnum } from './type';
import { getDocuments } from '../../../services/offline/db-service';
import useConnectionState from '../../../hooks/connection-state/useConnectionState';
import { tKeys } from '../../../translations/keys';
const useLogic = () => {
    // Attributes
    const { push } = useRouter();
    const { t } = useTranslation();
    const [listLayoutType, setListLayoutType] = useState(ListLayoutEnum.GRID);
    const { connectionState } = useConnectionState();
    // Queries
    // - Getters
    const [data, setData] = useState();
    const [isDocumentDataLoading, setIsDocumentDataLoading] = useState(false);
    const documentsData = useMemo(() => data?.length
        ? [
            {
                title: t(tKeys.offline_repository.section.title) ?? '',
                data,
            },
        ]
        : [], [data]);
    // Functions
    function onLayoutIconPress() {
        setListLayoutType(listLayoutType === ListLayoutEnum.GRID
            ? ListLayoutEnum.ROW
            : ListLayoutEnum.GRID);
    }
    function onDocumentPress(document) {
        const params = {
            documentKind: document?.filename?.toLocaleLowerCase().includes('minutes')
                ? DocumentKindEnum.MINUTES
                : DocumentKindEnum.DOCUMENT,
            documentId: document.rowid,
        };
        push('/offline/offline-document-details', params);
    }
    // Effect
    useEffect(() => {
        setIsDocumentDataLoading(true);
        getDocuments().then(value => {
            setData(value);
            setIsDocumentDataLoading(false);
        });
    }, []);
    // Return
    return {
        t,
        documentsData,
        onLayoutIconPress,
        listLayoutType,
        onDocumentPress,
        connectionState,
        isDocumentDataLoading,
    };
};
export default useLogic;
