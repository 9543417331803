import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        borderWidth: 1,
        borderColor: COLORS.Line.value,
        borderRadius: 12,
        backgroundColor: COLORS.White.T500.value,
        paddingVertical: 12,
    },
    contentTextContainer: {
        marginBottom: 16,
    },
    titleContainer: {
        marginBottom: 20,
        margin: 12,
        maxWidth: '70%',
    },
    inputContainer: {
        paddingVertical: 6,
        paddingHorizontal: 12,
    },
    bottomContentContainer: {
        marginHorizontal: 4,
        alignContent: 'center',
        alignItems: 'center',
        marginTop: 8,
    },
});
export default styles;
