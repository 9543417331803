import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: COLORS.Tag.value,
        borderRadius: 12,
        overflow: 'hidden',
    },
});
export default styles;
