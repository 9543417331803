import React from 'react';
import Menu from '../../pickers/menu';
import MenuItem from '../../pickers/menu/menu-item/index.web';
import styles from './style';
import { VStack } from '@chakra-ui/react';
const RenderMenu = ({ menuItems }) => {
    // Render if no items in menuItems
    if (!menuItems) {
        return <></>;
    }
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'}>
      {menuItems?.map((menuItem, index) => {
            return (<Menu title={menuItem?.name} key={`${menuItem?.name}-${index}`}>
            {menuItem?.items
                    ?.filter(item => item)
                    .map((item, itemIndex) => {
                    return (<MenuItem key={`${menuItem?.name}-${item?.title}-${itemIndex}`} startEnhancer={item?.startEnhancer} title={item?.title} titleEnhancer={item?.titleEnhancer} endEnhancer={item?.endEnhancer} onPress={item?.onPress} startEnhancerStyle={styles.innerItem} style={{ padding: '1rem' }}/>);
                })}
          </Menu>);
        })}
    </VStack>);
};
export default RenderMenu;
