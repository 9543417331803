import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useRouter from '../../../../hooks/router/useRouter';
import { createForm } from '../../../../utils/formik';
const useLogic = () => {
    // Attributes
    const { goBack, push, params } = useRouter();
    const assignPeopleEntity = params?.assignPeopleEntity;
    const task = params?.task;
    // States
    const [selectedPerson, setSelectedPerson] = useState(assignPeopleEntity?.selectedPeople?.[0]);
    const [allPeopleInCompany, setAllPeopleInCompany] = useState(assignPeopleEntity?.allPeopleInTheCompany);
    // Functions
    const redirectToNewsTasksPage = () => {
        push('/task-details/action', {
            assignPeopleEntity,
            task,
        });
    };
    const selectAssignPeople = (person) => {
        assignPeopleEntity.selectedPeople = person;
        setSelectedPerson(person);
        redirectToNewsTasksPage();
    };
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            searchValue: '',
        },
        onSubmit: undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Effect
    useEffect(() => {
        if (form?.searchValue?.value?.length) {
            const searchValueFormatted = form?.searchValue?.value?.toLocaleLowerCase();
            const filteredPeopleCompany = allPeopleInCompany?.filter(person => person.firstname.toLocaleLowerCase().includes(searchValueFormatted) ||
                person.lastname.toLocaleLowerCase().includes(searchValueFormatted) ||
                person.email.toLocaleLowerCase().includes(searchValueFormatted));
            setAllPeopleInCompany(filteredPeopleCompany);
        }
        else {
            setAllPeopleInCompany(assignPeopleEntity?.allPeopleInTheCompany?.filter(people => people?.id !== assignPeopleEntity?.selectedPeople?.id));
        }
    }, [form?.searchValue?.value?.length]);
    useEffect(() => {
        if (assignPeopleEntity) {
            setSelectedPerson(assignPeopleEntity?.selectedPeople);
            setAllPeopleInCompany(assignPeopleEntity?.allPeopleInTheCompany);
        }
    }, [params]);
    useEffect(() => {
        if (selectedPerson) {
            const formattedArray = assignPeopleEntity?.allPeopleInTheCompany?.filter(person => person?.id !== selectedPerson?.id);
            setAllPeopleInCompany(formattedArray);
        }
    }, [selectedPerson]);
    return {
        selectedPerson,
        allPeopleInCompany,
        goBack,
        selectAssignPeople,
        form,
    };
};
export default useLogic;
