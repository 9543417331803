import { Fragment } from 'react';
import { stackNavigator } from '../../routes/navigators';
import CustomTaskDetail from './custom-task-detail';
const taskDetailsRoutes = [
    // Tasks route
    {
        name: '/task-details',
        deepLink: '/task-details',
        component: stackNavigator,
        defaultRoute: '/action',
        subRoutes: [
            {
                name: '/action',
                deepLink: '/action',
                component: CustomTaskDetail,
            },
            {
                name: '/rsvp',
                deepLink: '/rsvp',
                component: Fragment,
            },
        ],
    },
];
export default taskDetailsRoutes;
