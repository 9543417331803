/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Retrieves a list of time zones
 */
export const readTimeZones = (params, options, signal) => {
    return customInstance({ url: `/others/timezones`, method: 'get', params, signal }, options);
};
export const getReadTimeZonesQueryKey = (params) => [
    `/others/timezones`,
    ...(params ? [params] : []),
];
export const useReadTimeZones = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadTimeZonesQueryKey(params);
    const queryFn = ({ signal, }) => readTimeZones(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves a list of cultures
 */
export const readCultures = (options, signal) => {
    return customInstance({ url: `/others/cultures`, method: 'get', signal }, options);
};
export const getReadCulturesQueryKey = () => [`/others/cultures`];
export const useReadCultures = (options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadCulturesQueryKey();
    const queryFn = ({ signal, }) => readCultures(requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
