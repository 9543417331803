import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { useReadGroupsByPerson } from '../../../../../../../services/online/groups/groups';
import { useCompaniesContext } from '../../../../../../../contexts/companies/index.web';
export const useMemberListTableRowLogic = (enrolment, onEnrolmentUpdate) => {
    // Attributes
    const { isOpen: isBoardRoleOpen, onToggle: handleBoardRoleToggle } = useDisclosure();
    const { isOpen: isPlatformRoleOpen, onToggle: handlePlatformRoleToggle } = useDisclosure();
    const { isOpen: isMoreMenuOpen, onToggle: handleMoreMenuToggle } = useDisclosure();
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: personGroups } = useReadGroupsByPerson({
        'company-id': selectedCompanyId,
        'person-id': enrolment.personId,
    });
    // Functions
    function handleBoardRoleChange(boardRole) {
        void onEnrolmentUpdate(enrolment, enrolment.role, boardRole.id);
    }
    function handlePlatformRoleChange(platformRole) {
        void onEnrolmentUpdate(enrolment, platformRole, enrolment.boardRoleId);
    }
    function handleEnrolmentSuspend() {
        void onEnrolmentUpdate(enrolment, enrolment.role, enrolment.boardRoleId, moment().toISOString());
    }
    // Render
    return {
        isBoardRoleOpen,
        handleBoardRoleToggle,
        isPlatformRoleOpen,
        handlePlatformRoleToggle,
        handleBoardRoleChange,
        handlePlatformRoleChange,
        isMoreMenuOpen,
        handleMoreMenuToggle,
        handleEnrolmentSuspend,
        personGroups,
    };
};
