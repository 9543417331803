/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import TouchableScale from 'react-native-touchable-scale';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Container from '../../../../../../components/surfaces/container';
import DocumentCard from '../../../../../../components/surfaces/document-card-event';
import COLORS from '../../../../../../constants/colors';
import DeletableImage from '../../inputs/deletable-image';
import CardCreatePoll from '../../surfaces/card/card-create-poll';
import styles from './styles';
import FONTS from '../../../../../../constants/fonts';
import { Stack } from '@chakra-ui/react';
const CreatePostContent = ({ form, handlePollVisibility, onClickDelete, }) => {
    // Render create poll component
    if (form.isPollVisible?.value) {
        // Render
        return (<CardCreatePoll handleOnClosePoll={handlePollVisibility} onChangeOptions={form?.pollOptions?.onChange} onChangeTitleValue={form?.title?.onChange} optionsValue={form?.pollOptions?.value} questionTitleValue={form?.title?.value}/>);
    }
    // Render selected image
    if (form?.imageValue?.value) {
        // Render
        return (<DeletableImage imageURI={form?.imageValue?.value?.data ?? ''} borderRadius={12} hasDeleteButton={true} onPressDeleteButton={onClickDelete}/>);
    }
    // Render selected document
    if (form?.documentValue?.value) {
        const document = form?.documentValue?.value;
        // Render
        return (<Stack width={'fit-content'}>
        <DocumentCard startEnhancer={<Container style={{
                    backgroundColor: COLORS.PlaceholderBG.value,
                    padding: 4,
                    borderRadius: 6,
                }}>
              <Icon name="files" height={14} width={14} fill={COLORS.InputText.value}/>
            </Container>} endEnhancer={<TouchableScale onPress={onClickDelete} style={styles.documentDeleteButton} disabled={false} activeScale={0.98} hitSlop={{ bottom: 12, left: 12, right: 12, top: 12 }}>
              <Icon name="cross" height={10} width={10}/>
            </TouchableScale>}>
          <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T400.value}>
            {document?.name}
          </Text>
        </DocumentCard>
      </Stack>);
    }
    return <></>;
};
export default CreatePostContent;
