import React from 'react';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import Text from '../../../content/text';
import Container from '../../../surfaces/container';
import Flex from '../../../surfaces/flex';
import styles from './style';
import { Box, HStack } from '@chakra-ui/react';
const MenuItem = ({ endEnhancer, onPress, startEnhancer, title, titleFont = FONTS.T1.T14Px.Medium500.value, titleEnhancer, startEnhancerStyle, isMandatory, justifyContentTitle = 'center', style, }) => {
    // Render
    return (<Box cursor="pointer" onClick={onPress} style={{ width: '100%', ...style }}>
      <HStack spacing={'0.75rem'}>
        {/* Start enhancer */}
        {startEnhancer && <>{startEnhancer}</>}

        {/* Title and title enhancer */}
        <Flex direction="column" style={{
            ...styles.titleContainer,
            justifyContent: justifyContentTitle,
        }}>
          {/* Title */}
          <HStack spacing={'.25rem'}>
            <Text font={titleFont} color={COLORS.Text.T400.value}>
              {title}
            </Text>
            <Text font={titleFont} color={COLORS.Error.T500.value}>
              {`${isMandatory ? '*' : ''}`}
            </Text>
          </HStack>

          {/*  title enhancer */}
          {titleEnhancer && (<Container marginTop={4}>{titleEnhancer}</Container>)}
        </Flex>

        {/* end enhancer */}
        {endEnhancer && <Box>{endEnhancer}</Box>}
      </HStack>
    </Box>);
};
export default MenuItem;
