import { Box, Divider, HStack, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
import Container from '../container';
import Icon from '../../content/icon';
export const PageWebAuth = ({ children, onGoBack, title, }) => {
    // Render
    return (<VStack w="full" h="100vh" backgroundColor={COLORS.Text.T500.value} justifyContent={'center'} background={`linear-gradient(45deg, ${COLORS.Text.T400.value}, ${COLORS.Text.T500.value}, ${COLORS.Text.T400.value})`}>
      <VStack bgColor={COLORS.White.T500.value} w="full" maxW="35.75rem" rounded="1.25rem" overflow={'hidden'} spacing={0}>
        {onGoBack ? (<>
            <HStack backgroundColor={COLORS.White.T500.value} py={'1.5rem'} px={'2.5rem'} width={'full'} justifyContent={'space-between'}>
              <Stack onClick={onGoBack}>
                <Icon name="chevron-left-default"/>
              </Stack>
              <Text font={FONTS.T1.T14Px.Medium500.value}>{title}</Text>
              <Box height={'.875rem'} width={'.875rem'}/>
            </HStack>
            <Divider />
          </>) : (<Container style={{
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 40,
            }}>
            <Icon name="application-logo" width={110} height={36}/>
          </Container>)}
        {children}
      </VStack>
    </VStack>);
};
