import { useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useAddGroupToEvent, useRemoveGroupFromEvent, useUpdateEvent, } from '../../../../../../services/online/calendar/calendar';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
import { useReadEnrolments } from '../../../../../../services/online/enrolments/enrolments';
import COLORS from '../../../../../../constants/colors';
import { PresenceStatus } from '../../../event-details/types';
import { tKeys } from '../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { useReadGroups } from '../../../../../../services/online/groups/groups';
import _ from 'lodash';
import { useReadPeople } from '../../../../../../services/online/people/people';
export const useEventDetailsInviteesLogic = (event, refetchEvent) => {
    // Attributes
    const { selectedCompanyId } = useCompaniesContext();
    const popoverRef = useRef(null);
    const [search, setSearch] = useState('');
    const { isOpen: isPopoverOpen, onToggle: onPopoverToggle } = useDisclosure();
    const { t } = useTranslation();
    const sortedInviteesList = event?.invitees?.sort((a, b) => {
        if (a.acceptedOn && !b.acceptedOn) {
            return -1;
        }
        else if (!a.acceptedOn && b.acceptedOn) {
            return 1;
        }
        else if (a.refusedOn && !b.refusedOn) {
            return -1;
        }
        else if (!a.refusedOn && b.refusedOn) {
            return 1;
        }
        else {
            return 0;
        }
    });
    // Queries
    const { mutateAsync: updateEvent, isLoading: isLoadingUpdateEvent } = useUpdateEvent();
    const { mutateAsync: addGroupToEvent, isLoading: isLoadingAddGroupToEvent } = useAddGroupToEvent();
    const { mutateAsync: removeGroupFromEvent } = useRemoveGroupFromEvent();
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': search,
    });
    const { data: people } = useReadPeople({
        'company-id': selectedCompanyId,
        'people-ids': event?.inviteeIds,
    }, { query: { enabled: !!event } });
    const { data: groups } = useReadGroups({
        'company-id': selectedCompanyId,
        'search-term': search,
    });
    // Function
    const filterPeopleByInvitees = (people, invitees) => {
        if (!people || !invitees)
            return;
        const inviteeIds = invitees.map(invitee => invitee.personId);
        return people.filter(person => inviteeIds.includes(person.id));
    };
    const peopleInvited = filterPeopleByInvitees(people?.items, sortedInviteesList);
    function getPresenceStatusFromInvitee(invitee) {
        if (invitee?.proxyId) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.proxy),
                iconName: 'proxy',
                bgColor: COLORS.Warning.T100.value,
                colors: COLORS.Warning.T500.value,
                status: PresenceStatus.Proxy,
            };
        }
        if (invitee?.isRemote) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.remote),
                iconName: 'remote',
                bgColor: COLORS.Success.T100.value,
                colors: COLORS.Success.T500.value,
                status: PresenceStatus.Remote,
            };
        }
        if (invitee?.acceptedOn) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.present),
                iconName: 'check',
                bgColor: COLORS.Success.T100.value,
                colors: COLORS.Success.T500.value,
                status: PresenceStatus.Present,
            };
        }
        if (invitee?.refusedOn && !invitee.proxyId) {
            return {
                title: t(tKeys.home.calendar.event_details.information.presence.status.absent),
                iconName: 'close-cross',
                bgColor: COLORS.Error.T100.value,
                colors: COLORS.Error.T500.value,
                status: PresenceStatus.Absent,
            };
        }
        return {
            title: t(tKeys.event.details.presence.is_present),
            bgColor: COLORS.White.T500.value,
            colors: COLORS.Text.T400.value,
            status: PresenceStatus.Pending,
        };
    }
    const handleSetSearch = _.debounce((value) => setSearch(value), 500);
    async function handleAddInvitee(inviteeId) {
        const newInviteeIds = [...event?.inviteeIds, inviteeId];
        await updateEvent({
            data: {
                ...event,
                inviteeIds: newInviteeIds,
            },
        }, {
            onSuccess: () => {
                refetchEvent();
                onPopoverToggle();
            },
        });
    }
    async function handleGroupAddToEvent(groupId) {
        await addGroupToEvent({
            data: {
                companyId: event?.companyId,
                eventId: event?.id,
                groupId,
            },
        });
        refetchEvent();
        onPopoverToggle();
    }
    async function handleGroupRemoveFromEvent(groupId) {
        await removeGroupFromEvent({
            eventId: event?.id,
            groupId,
        });
        refetchEvent();
    }
    const peopleNotInvited = enrolment?.items?.filter(people => !event?.inviteeIds.includes(people?.person.id));
    const groupsNotInvited = groups?.filter(group => !event?.groupIds.includes(group.id));
    useOutsideClick({
        ref: popoverRef,
        handler: () => setTimeout(() => {
            isPopoverOpen && onPopoverToggle();
        }, 100),
    });
    // Return
    return {
        popoverRef,
        isPopoverOpen,
        onPopoverToggle,
        handleSetSearch,
        peopleNotInvited,
        handleAddInvitee,
        isLoadingUpdateEvent,
        getPresenceStatusFromInvitee,
        sortedInviteesList,
        groupsNotInvited,
        handleGroupAddToEvent,
        isLoadingAddGroupToEvent,
        handleGroupRemoveFromEvent,
        peopleInvited,
    };
};
