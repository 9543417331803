import { StyleSheet } from 'react-native';
import COLORS from '../../../constants/colors';
import CUSTOM_SHADOWS from '../../../utils/shadows';
const styles = StyleSheet.create({
    mainContainer: {
        height: 68,
        backgroundColor: COLORS.White.T500.value,
        paddingHorizontal: 16,
        borderRadius: 12,
        ...CUSTOM_SHADOWS.FormControlContainer,
    },
    tag: { paddingHorizontal: 8 },
    resendButton: {
        borderColor: '#E7E9F4',
        borderWidth: 1,
        width: 32,
        height: 32,
        borderRadius: 32,
    },
});
export default styles;
