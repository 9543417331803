import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-native-calendars';
import TouchableScale from 'react-native-touchable-scale';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
import styles from './style.web';
import Icon from '../../content/icon';
function CalendarPicker({ onChange, value, backgroundColor = COLORS.White.T500.value, handlePress, initialValue, minDate, }) {
    // Attributes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSelectedMonth] = useState();
    const [currentSelectedDay, setCurrentSelectedDay] = useState(new Date());
    // Effects
    useEffect(() => {
        if (!value && !initialValue)
            return;
        if (!value) {
            setCurrentSelectedDay(new Date(initialValue));
            return;
        }
        setCurrentSelectedDay(new Date(value));
    }, [value, initialValue]);
    // Functions
    const renderDay = (date, state) => {
        const selectedDate = moment(currentSelectedDay?.toISOString?.())?.format('YYYY-MM-DD');
        const isSelected = state !== 'disabled' && selectedDate === date.dateString;
        let color;
        switch (state) {
            case 'today':
                color = COLORS.Govrn.Blue.T500.value;
                break;
            case 'disabled':
                color = COLORS.InputText.value;
                break;
        }
        return (<TouchableScale onPress={state === 'disabled' ? undefined : () => onDayPress(date)} hitSlop={{
                bottom: 16,
                left: 16,
                right: 16,
                top: 16,
            }} style={{
                ...styles.dayContainer,
                backgroundColor: isSelected
                    ? COLORS.Govrn.Blue.T100.value
                    : undefined,
            }}>
        <Text style={styles.text} font={FONTS.T1.T12Px.Medium500.value} color={isSelected ? COLORS.Govrn.Blue.T500.value : color}>
          {date?.day ?? ''}
        </Text>
      </TouchableScale>);
    };
    function onDayPress(day) {
        const date = new Date(day?.dateString);
        onChange(date);
        if (handlePress)
            handlePress();
    }
    function minDateToString() {
        if (!minDate)
            return;
        if (typeof minDate === 'string')
            return minDate;
        return minDate.toISOString();
    }
    // Return
    return (<Calendar renderArrow={direction => (<Icon name={direction === 'left'
                ? 'chevron-left-default'
                : 'chevron-right-default'} width={20} height={20} fill={COLORS.Text.T400.value}/>)} onPressArrowLeft={goToPreviousMonth => {
            goToPreviousMonth();
        }} onPressArrowRight={goToNextMonth => {
            goToNextMonth();
        }} initialDate={currentSelectedDay?.toISOString()} style={styles.mainContainer} theme={{
            calendarBackground: backgroundColor,
            monthTextColor: COLORS.Text.T400.value,
            arrowColor: COLORS.Text.T400.value,
            textDayFontFamily: FONTS.T1.T10Px.Regular400.value.fontFamily,
            textMonthFontFamily: FONTS.T1.T10Px.Regular400.value.fontFamily,
            textDayHeaderFontFamily: FONTS.T1.T10Px.Regular400.value.fontFamily,
            // Header Month Font
            textMonthFontSize: 14,
            textMonthFontWeight: '600',
            // Header days font
            textDayHeaderFontSize: 12,
            textDayHeaderFontWeight: '500',
        }} dayComponent={({ date, state }) => renderDay(date, state)} onMonthChange={date => setSelectedMonth(new Date(date.dateString))} allowSelectionOutOfRange={false} minDate={minDateToString()} firstDay={1}/>);
}
export default CalendarPicker;
