import { useTranslation } from 'react-i18next';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
import { ModulesEnum } from '../../../../../../enum/Modules';
import { tKeys } from '../../../../../../translations/keys';
export const useEventDetailsAISummaryLogic = () => {
    // Attributes
    const { t } = useTranslation();
    const { modules } = useCompaniesContext();
    const supportEmail = 'help@govrn.com';
    const isModuleAvailable = modules?.some(m => m.name === ModulesEnum.AI_MINUTE_BUILDER);
    // Functions
    function handleMailto() {
        const subject = t(tKeys.event.details.AI.subject);
        window.location.href = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}`;
    }
    // Return
    return { isModuleAvailable, supportEmail, handleMailto };
};
