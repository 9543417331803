import React from 'react';
import COLORS from '../../../constants/colors';
import Text from '../text';
import { Box } from '@chakra-ui/react';
const BannerMessage = ({ backgroundColor = COLORS.Govrn.Blue.T100.value, text, textColor = COLORS.Govrn.Blue.T500.value, textFont, }) => {
    return (<Box p={'.75rem'} bgColor={backgroundColor}>
      <Text color={textColor} font={textFont} numberOfLines={2} width={'full'}>
        {text}
      </Text>
    </Box>);
};
export default BannerMessage;
