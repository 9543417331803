/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useCompaniesContext } from '../../contexts/companies';
import { getReadDocumentHistoryQueryKey, getReadDocumentQueryKey, getReadDocumentsQueryKey, useDeleteDocument, useReadDocument, useReadDocumentTypes, useUpdateDocument, } from '../../services/online/repository/repository';
import { createForm } from '../../utils/formik';
import getStaleTime from '../../utils/getStaleTime';
import { useDisclosure } from '@chakra-ui/react';
import { useReadEnrolments } from '../../services/online/enrolments/enrolments';
import { useReadGroups } from '../../services/online/groups/groups';
import { useMemo, useState } from 'react';
const useLogic = (editDocumentEntity, onClose, onCloseDetails, refetchDocument) => {
    // Attributes
    const [search, setSearch] = useState('');
    const { selectedCompanyId } = useCompaniesContext();
    const queryClient = useQueryClient();
    const { isOpen: isOpenFileType, onToggle: onToggleFileType } = useDisclosure();
    // Queries
    const { mutateAsync: updateDocument, isLoading: isUpdateDocumentLoading } = useUpdateDocument();
    const { mutateAsync: deleteDocument, isLoading: isDeleteDocumentLoading } = useDeleteDocument();
    const { data: document } = useReadDocument(editDocumentEntity?.documentId, undefined, {
        query: { enabled: !!editDocumentEntity?.documentId },
    });
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': search,
    });
    const peopleInTheCompany = useMemo(() => {
        return enrolments?.items?.map(enrolment => enrolment.person);
    }, [enrolments]);
    const { data: groupsInTheCompany } = useReadGroups({
        'company-id': selectedCompanyId,
        'search-term': search,
    });
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    const selectedFileType = documentTypes?.filter(filetype => filetype?.id === editDocumentEntity?.typeId);
    // Functions
    const handleOnSubmit = async () => {
        const documentType = documentTypes.filter(filetype => filetype?.name === form?.typeId?.value);
        await updateDocument({
            data: {
                changeLog: form?.changeLog?.value,
                companyId: document?.companyId,
                createdOn: document?.createdOn,
                creatorId: document?.creatorId,
                description: form?.description?.value,
                eventId: document?.eventId,
                file: form?.file?.value,
                id: document?.id,
                isDownloadable: document?.isDownloadable,
                messageId: document?.messageId,
                signerIds: document?.signerIds,
                signers: document?.signers,
                title: form?.title?.value,
                typeId: documentType[0]?.id,
                versionId: document?.versionId,
                xfdfString: document?.xfdfString,
                agendaItemId: document?.agendaItemId,
                issuedOn: form?.issuedOn?.value,
                groupIds: form?.groupIds?.value,
                personIds: form?.personIds?.value,
            },
        }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadDocumentQueryKey(document?.id));
                void queryClient.invalidateQueries(getReadDocumentHistoryQueryKey(document?.id));
                onClose();
                refetchDocument();
            },
        });
    };
    const handleOnPressDelete = () => {
        void deleteDocument({ documentId: editDocumentEntity?.documentId }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                    'event-id': document?.eventId,
                    'document-types': documentTypes?.map(d => d?.id),
                    'company-id': selectedCompanyId,
                }));
                onClose();
                onCloseDetails();
            },
        });
    };
    function handleSearchChange(value) {
        setSearch(value);
    }
    function isPersonSelected(person) {
        return values?.personIds?.some(id => id === person?.id);
    }
    function handlePersonSelect(person) {
        const personIds = [...values.personIds];
        if (isPersonSelected(person)) {
            const personsSelectedFiltered = personIds.filter(id => id !== person.id);
            form.personIds.onChange(personsSelectedFiltered);
            return;
        }
        personIds.push(person.id ?? '');
        form.personIds.onChange?.(personIds);
    }
    function isGroupSelected(group) {
        return values?.groupIds?.some(id => id === group?.id);
    }
    function handleGroupSelect(group) {
        const groupsIds = [...values.groupIds];
        if (isGroupSelected(group)) {
            const groupsSelectedFiltered = groupsIds.filter(id => id !== group.id);
            form.groupIds.onChange(groupsSelectedFiltered);
            return;
        }
        groupsIds.push(group.id ?? '');
        form.groupIds.onChange?.(groupsIds);
    }
    const getInitialsValues = () => {
        return {
            title: editDocumentEntity?.title ?? '',
            description: editDocumentEntity?.description ?? '',
            file: editDocumentEntity?.file ?? null,
            typeId: selectedFileType?.[0]?.name ?? '',
            changeLog: editDocumentEntity?.changeLog ?? '',
            issuedOn: editDocumentEntity?.issuedOn ?? '',
            groupIds: editDocumentEntity?.groupIds ?? [],
            personIds: editDocumentEntity?.personIds ?? [],
        };
    };
    function onChangeTypeId(value) {
        form?.typeId?.onChange(value?.name);
        onToggleFileType();
    }
    // Form
    const { values, ...rest } = useFormik({
        initialValues: getInitialsValues(),
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validationSchema: false,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    // Return
    return {
        form,
        values,
        handleOnPressDelete,
        handleOnSubmit,
        isUpdateDocumentLoading,
        isDeleteDocumentLoading,
        documentTypes,
        onChangeTypeId,
        onToggleFileType,
        isOpenFileType,
        handleSearchChange,
        search,
        isGroupSelected,
        isPersonSelected,
        handleGroupSelect,
        handlePersonSelect,
        groupsInTheCompany,
        peopleInTheCompany,
    };
};
export default useLogic;
