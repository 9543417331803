import { postTypes } from '../screens/home/feed/types';
export function getPostType(item) {
    if (item?.invitationId) {
        return postTypes.PostOfInvitation;
    }
    if (item?.document) {
        return postTypes.PostOfDocument;
    }
    if (item?.question) {
        return postTypes.PostOfQuestion;
    }
    return postTypes.Post;
}
