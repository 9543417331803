import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import FormControl from '../../../../components/inputs/form-control';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import PasswordInput from '../../../auth/components/inputs/password-input/index.web';
import useLogic from './logic';
import { Stack, VStack } from '@chakra-ui/react';
import { boxSectionStyle } from '../my-informations/style.web';
const UserPasswordChange = () => {
    // Attributes
    const { handleOnPressButton, isUpdatePersonLoading, handleOnPressBackButton, form, } = useLogic();
    const { t } = useTranslation();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader onGoBack={handleOnPressBackButton} endEnhancer={<Button onPress={handleOnPressButton} isLoading={isUpdatePersonLoading} kind={Kind.SECONDARY} size={Size.SMALL}>
            {t(tKeys.common.save)}
          </Button>}>
        {t('profile.user-profile.password.form.title')}
      </NavigationHeader>

      <VStack width={'full'} height={'full'} bgColor={COLORS.BG.value} spacing={'.5rem'} p={'1rem'}>
        {/* Password input */}
        <Stack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile?.['user-profile'].password.form.label)} spacing={4}>
            {/* Current password input */}
            <PasswordInput {...form?.password} placeholder={t('profile.user-profile.password.form.placeholder')}/>
          </FormControl>
        </Stack>

        {/* New password inputs */}
        <Stack {...boxSectionStyle}>
          <FormControl label={t(tKeys.profile['user-profile'].password.change.label)} spacing={4}>
            {/* New password input */}
            <PasswordInput {...form?.newPassword} placeholder={t('profile.user-profile.password.change.placeholder')}/>

            {/* Confirm new password input */}
            <PasswordInput {...form?.confirmNewPassword} placeholder={t(tKeys.profile['user-profile'].password.change.confirm)}/>
          </FormControl>
        </Stack>
      </VStack>
    </Page>);
};
export default UserPasswordChange;
