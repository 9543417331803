import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../../components/content/icon';
import Text from '../../../../../../../../components/content/text';
import COLORS from '../../../../../../../../constants/colors';
import FONTS from '../../../../../../../../constants/fonts';
import { TaskTypeEnum } from '../../../../../../../../enum/TaskType';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import { tKeys } from '../../../../../../../../translations/keys';
import useFormatDate from '../../../../../../../../hooks/format-date/useFormatDate';
import Tag from '../../../../../../../../components/content/tag/index.web';
import styles from './style.web';
const ActiveTaskItem = ({ task, onPress }) => {
    // Attributes
    const { t } = useTranslation();
    const { formatDate } = useFormatDate();
    const renderTag = () => {
        switch (TaskTypeEnum[task?.type]) {
            case 'Proxy':
                return (<Tag style={styles.tag} backgroundColor={COLORS.Govrn.Yellow.T100.value} color={COLORS.Govrn.Yellow.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.PROXY).toLocaleUpperCase()}
          </Tag>);
            case 'Read':
                return (<Tag style={styles.tag} backgroundColor={COLORS.Govrn.PastelGreen.T100.value} color={COLORS.Govrn.PastelGreen.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.READ).toLocaleUpperCase()}
          </Tag>);
            case 'Sign':
                return (<Tag style={styles.tag} backgroundColor={COLORS.Warning.T100.value} color={COLORS.Warning.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.SIGN).toLocaleUpperCase()}
          </Tag>);
            case 'Vote':
                return (<Tag style={styles.tag} backgroundColor={COLORS.Tags.PurpleRoseBg.value} color={COLORS.Tags.PurpleRose.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.VOTES).toLocaleUpperCase()}
          </Tag>);
            case 'RSVP':
                return (<Tag style={styles.tag} backgroundColor={COLORS.Govrn.Pink.T100.value} color={COLORS.Govrn.Pink.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.RSVP).toLocaleUpperCase()}
          </Tag>);
            case 'Custom':
                return (<Tag style={styles.tag} backgroundColor={COLORS.Govrn.DarkBlue.T200.value} color={COLORS.Govrn.DarkBlue.T500.value} font={FONTS.T1.T10Px.Medium500.value}>
            {t(tKeys.tasks.type.ACTION)}
          </Tag>);
        }
    };
    const getIconName = () => {
        switch (TaskTypeEnum[task?.type]) {
            case 'RSVP':
                return 'navigation-bar-calendar-default';
            case 'Read':
                return 'book';
            case 'Sign':
                return 'pen';
            case 'Proxy':
                return 'files';
            case 'Vote':
                return 'poll-white';
            default:
                return 'members-black';
        }
    };
    function getTitle() {
        if (!task?.title) {
            switch (TaskTypeEnum[task?.type]) {
                case 'Vote':
                    return t(tKeys.tasks.type.VOTES);
                default:
                    return '';
            }
        }
        return task?.title;
    }
    // Return
    return (<HStack width={'full'} onClick={onPress} cursor={'pointer'} justifyContent={'space-between'} spacing={'.75rem'} px={'1.25rem'} py={'.75rem'} _hover={{ bgColor: COLORS.Tag.value }}>
      <HStack width={'full'}>
        <Stack p={'.5rem'} bgColor={COLORS.DarkGrey.value} borderRadius={'0.625rem'}>
          <Icon name={getIconName()} width={20} height={20} fill={COLORS.White.T500.value}/>
        </Stack>
        <VStack width={'full'} alignItems={'flex-start'} spacing={'.25rem'}>
          <Text font={FONTS.T1.T12Px.Regular400.value}>{getTitle()}</Text>
          <HStack>
            <Stack>{renderTag()}</Stack>
            {task?.deadline && (<Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.Text.T300.value}>
                {t(tKeys.tasks.card.dueDate, {
                interpolation: { escapeValue: false },
                date: formatDate({
                    date: task?.deadline,
                    formatWithTimezone: false,
                }),
            })}
              </Text>)}
          </HStack>
        </VStack>
      </HStack>

      <Icon name="chevron-right" width={20} height={20}/>
    </HStack>);
};
export default ActiveTaskItem;
