import { VStack } from '@chakra-ui/react';
import React from 'react';
import Text from '../../../../../../components/content/text';
import FONTS from '../../../../../../constants/fonts';
import COLORS from '../../../../../../constants/colors';
export const DocumentDetailsInformationBox = ({ information, title, }) => {
    // Render
    return (<VStack borderRadius={'.5rem'} borderWidth={1} p={'0.75rem'} spacing={'.25rem'} alignItems={'flex-start'} width={'full'}>
      <Text font={FONTS.T1.T12Px.Regular400.value} color={COLORS.Text.T300.value}>
        {title}
      </Text>
      <Text font={FONTS.T1.T12Px.SemiBold600.value} numberOfLines={2}>
        {information}
      </Text>
    </VStack>);
};
