import { useDisclosure } from '@chakra-ui/react';
import { finalizeMinutes } from '../../../../../../services/online/calendar/calendar';
import { DocumentKindEnum, } from '../../../../../document-details/type';
import { tKeys } from '../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
import useRouter from '../../../../../../hooks/router/useRouter.web';
import { useToast } from '../../../../../../components/content/use-toast/index.web';
export const usePreviewMinutesLogic = (eventId) => {
    // Attributes
    const { t } = useTranslation();
    const { isOpen: isOpenPopover, onToggle: onTogglePopover } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const { selectedCompanyId } = useCompaniesContext();
    const { push } = useRouter();
    const toast = useToast();
    // Functions
    async function handleGenerateClick(culture) {
        setIsLoading(true);
        try {
            const { value: documentId } = await finalizeMinutes(eventId, { culture });
            const params = {
                documentKind: DocumentKindEnum.MINUTES,
                eventId,
                documentId,
            };
            push('/company/document-details', {
                companyId: selectedCompanyId,
                ...params,
            });
        }
        catch (err) {
            console.error(err);
            toast({
                status: 'error',
                title: t(tKeys['sign-in-forgot-mail'].errors.email_not_sent),
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    // Return
    return { isOpenPopover, onTogglePopover, isLoading, handleGenerateClick };
};
