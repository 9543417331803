import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import Text from '../../../../../../components/content/text';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import { dateFormatEnglish } from '../../../../../../constants/date-format';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic.web';
import styles from './style';
import { HStack, VStack } from '@chakra-ui/react';
import CustomTaskDetail from '../../../../../task-details/custom-task-detail/index.web';
const EventDetailsTaskCard = ({ index, task, event, onClose, }) => {
    // Attributes
    const { creatorUserName, assigneeUserName, handleTaskPress, isOpenCustomTask, onCloseCustomTask, } = useLogic(task);
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'} onClick={() => handleTaskPress(task)} cursor={'pointer'}>
      <VStack width={'full'} alignItems={'flex-start'} spacing={0}>
        {/* Title */}
        <Text font={FONTS.T1.T12Px.SemiBold600.value}>{task?.title}</Text>

        {/* Due date */}
        <Flex style={styles.taskSubtitleContainer} spacing={4}>
          <Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>{`${t(tKeys.common.due_date)} ${moment(task?.deadline).format(dateFormatEnglish)}`}</Text>
          <View style={styles.separatorContainer}/>
          <Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
            {event?.name}
          </Text>
        </Flex>
      </VStack>

      <HStack style={styles.poepleContainer} spacing={'.5rem'}>
        {/* Creator name */}
        {creatorUserName && (<HStack backgroundColor={COLORS.Tag.value} borderRadius={'1rem'} px={'.5rem'} py={'.25rem'}>
            <Text font={FONTS.T1.T10Px.Medium500.value} color={COLORS.InputText.value}>
              {t(tKeys.taskDetail.taskCard.createdBy) + ' '}
            </Text>
            <Text font={FONTS.T1.T10Px.SemiBold600.value} color={COLORS.Text.T400.value}>
              {creatorUserName}
            </Text>
          </HStack>)}

        {/* Assigne name */}
        {assigneeUserName && (<HStack backgroundColor={COLORS.Tag.value} borderRadius={'1rem'} px={'.5rem'} py={'.25rem'}>
            <Text font={FONTS.T1.T10Px.Medium500.value} color={COLORS.InputText.value}>
              {t(tKeys.taskDetail.taskCard.assignTo) + ' '}
            </Text>
            <Text font={FONTS.T1.T10Px.SemiBold600.value} color={COLORS.Text.T400.value}>
              {assigneeUserName}
            </Text>
          </HStack>)}
      </HStack>
      {/* Modals */}
      <CustomTaskDetail task={task} isOpen={isOpenCustomTask} onClose={onCloseCustomTask}/>
    </VStack>);
};
export default EventDetailsTaskCard;
