import { useEffect, useState } from 'react';
import { AnswerTypeEnum } from '../../../../../../enum/AnswerType';
import { useReadAgendaItems, useUpdateAgendaItem, } from '../../../../../../services/online/calendar/calendar';
import { useReadMe } from '../../../../../../services/online/people/people';
import getStaleTime from '../../../../../../utils/getStaleTime';
import { useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';
import useRouter from '../../../../../../hooks/router/useRouter.web';
import { useCompaniesContext } from '../../../../../../contexts/companies/index.web';
const useLogic = (event) => {
    // Attributes
    const [agendaItems, setAgendaItems] = useState([]);
    const [agendaItemEntity, setAgendaItemEntity] = useState();
    const [voteResultParams, setVoteResultParams] = useState();
    const { isOpen: isOpenEditAgendaItem, onOpen: onOpenEditAgendaItem, onClose: onCloseEditAgendaItem, } = useDisclosure();
    const { isOpen: isOpenVoteResult, onOpen: onOpenVoteResult, onClose: onCloseVoteResult, } = useDisclosure();
    const { push } = useRouter();
    const { selectedCompanyId } = useCompaniesContext();
    // Queries
    const { data: me } = useReadMe({ query: { staleTime: getStaleTime() } });
    const { data: agendaItemsList, refetch: refetchAgendaItems, isLoading: isAgendaItemsLoading, } = useReadAgendaItems({ 'event-id': event?.id ?? '' }, {
        query: {
            enabled: !!event?.id,
        },
    });
    const canUserVote = event?.invitees?.find(invitee => {
        return (invitee?.personId === me?.person?.id &&
            (invitee?.acceptedOn || invitee?.isRemote));
    });
    // Effects
    useEffect(() => {
        if (agendaItemsList)
            setAgendaItems(agendaItemsList);
    }, [agendaItemsList]);
    const { mutateAsync: updateAgendaItem } = useUpdateAgendaItem();
    // Functions
    function onEditAgendaItem(item) {
        setAgendaItemEntity({
            eventId: event?.id,
            id: item.id,
            isCompleted: item.isCompleted,
            question: item.question,
            title: item.title ?? '',
            sortOrder: item.sortOrder,
            duration: item.duration,
            type: item.type,
            agendaItemParentId: item.agendaItemParentId,
        });
        onOpenEditAgendaItem();
    }
    async function handleDropAgendaItem(agendaItemDraggedId, agendaItemParentId, agendaItemHoverIndex, isDropAfterAgendaItem) {
        const agendaItemsArray = _.cloneDeep(agendaItems);
        const elementToMove = findElementToMove(agendaItemsArray, agendaItemDraggedId);
        if (isDropAfterAgendaItem) {
            if (agendaItemParentId) {
                const destinationParent = findParent(agendaItemsArray, agendaItemParentId);
                if (!destinationParent)
                    return;
                destinationParent.subAgendaItems.splice(agendaItemHoverIndex, 0, elementToMove);
            }
            else {
                agendaItemsArray.splice(agendaItemHoverIndex, 0, elementToMove);
            }
        }
        setAgendaItems(agendaItemsArray);
        await updateAgendaItem({
            data: {
                ...elementToMove,
                sortOrder: agendaItemHoverIndex,
                agendaItemParentId,
            },
        });
        await refetchAgendaItems();
    }
    function findElementToMove(items, agendaItemId) {
        for (let i = 0; i < items.length; i++) {
            const currentItem = items[i];
            if (currentItem.id === agendaItemId) {
                items.splice(i, 1);
                return currentItem;
            }
            else {
                const foundInSubItems = findElementToMove(currentItem.subAgendaItems, agendaItemId);
                if (foundInSubItems) {
                    return foundInSubItems;
                }
            }
        }
        return undefined;
    }
    function findParent(items, itemId) {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.id === itemId) {
                return item;
            }
            else {
                const foundInDescendant = findParent(item.subAgendaItems, itemId);
                if (foundInDescendant) {
                    return foundInDescendant;
                }
            }
        }
        return null;
    }
    function findAgendaItem(items, agendaItemId, agendaItemParentId) {
        if (agendaItemParentId) {
            const findedParent = findParent(items, agendaItemParentId);
            if (!findedParent)
                return null;
            return (findedParent.subAgendaItems?.find(item => item.id === agendaItemId) ||
                null);
        }
        else {
            return items.find(item => item.id === agendaItemId) || null;
        }
    }
    function onSelectAgendaItem(agendaItemId, agendaItemParentId) {
        const agendaItemArray = _.cloneDeep(agendaItems);
        const findedAgendaItem = findAgendaItem(agendaItemArray, agendaItemId, agendaItemParentId);
        if (!findedAgendaItem)
            return;
        findedAgendaItem.isCompleted = !findedAgendaItem.isCompleted;
        void updateAgendaItem({
            data: { ...findedAgendaItem },
        });
        setAgendaItems(agendaItemArray);
    }
    function onAddPress(agendaItemParentId) {
        setAgendaItemEntity({
            id: undefined,
            isCompleted: false,
            question: undefined,
            title: undefined,
            eventId: event?.id,
            sortOrder: 0,
            duration: undefined,
            type: undefined,
            agendaItemParentId,
        });
        onOpenEditAgendaItem();
    }
    function onVotePress(question) {
        const params = {
            question,
            canUserVote: canUserVote !== undefined,
            eventId: event?.id,
            event,
        };
        setVoteResultParams(params);
        onOpenVoteResult();
    }
    function isVoteEnabled(question) {
        const hasVoted = question?.answers
            ?.flatMap(a => a.votes)
            ?.filter(v => v.answerType !== AnswerTypeEnum.None)
            ?.some(vote => vote.personId === me?.person?.id);
        const voteClosed = !!question?.closedOn;
        return !voteClosed && !hasVoted;
    }
    async function handleAgendaItemsRefetch() {
        await refetchAgendaItems();
    }
    function redirectToBoardPack() {
        push('/company/boardpack', {
            companyId: selectedCompanyId,
            eventId: event?.id,
            agendaItemId: agendaItems?.[0].id,
            agendaItemName: agendaItems?.[0].title,
        });
    }
    return {
        onEditAgendaItem,
        onSelectAgendaItem,
        agendaItems,
        onAddPress,
        refetchAgendaItems,
        isAgendaItemsLoading,
        onVotePress,
        isVoteEnabled,
        isOpenEditAgendaItem,
        onCloseEditAgendaItem,
        agendaItemEntity,
        isOpenVoteResult,
        onCloseVoteResult,
        voteResultParams,
        handleDropAgendaItem,
        handleAgendaItemsRefetch,
        redirectToBoardPack,
    };
};
export default useLogic;
