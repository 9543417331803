import { StyleSheet } from 'react-native';
import COLORS from '../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: COLORS.White.T500.value,
        paddingHorizontal: 16,
        paddingVertical: 20,
    },
    contentContainer: {
        paddingBottom: 120,
    },
    inputContainer: {
        paddingVertical: 6,
    },
    flatlist: { marginTop: 20, marginBottom: 40 },
    bottomSectionContainer: {
        marginTop: 32,
    },
    itemContainer: { paddingBottom: 20 },
});
export default styles;
