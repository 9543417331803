import React from 'react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Text from '../../content/text';
import { Stack, VStack } from '@chakra-ui/react';
const Menu = ({ children, title }) => {
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} spacing={'.75rem'}>
      {/*  Title */}
      {title && (<Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.InputText.value}>
          {title}
        </Text>)}

      {/* Container for children */}
      <Stack width={'full'} boxShadow={'0px 26px 34px 0px rgba(96, 97, 112, 0.06), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)'} borderRadius={'.5rem'} overflow={'hidden'} bgColor={COLORS.White.T500.value}>
        {children}
      </Stack>
    </VStack>);
};
export default Menu;
