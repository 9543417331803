// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Kind = {
    SQUARE: 'SQUARE',
    ROUNDED: 'ROUNDED',
};
const KINDS = {
    [Kind.SQUARE]: {
        borderRadius: 6,
        paddingHorizontal: 12,
    },
    [Kind.ROUNDED]: {
        borderRadius: 12,
        paddingHorizontal: 12,
    },
};
export default KINDS;
