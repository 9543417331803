/* eslint-disable react/prop-types */
import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import { FlatList } from 'react-native';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import Container from '../../../../../../components/surfaces/container';
import LineSeparator from '../../../../../../components/utility/line-separator';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import Placeholder from '../../../../../../components/utility/placeholder';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic.web';
import { Box, HStack, Stack, VStack } from '@chakra-ui/react';
import EventDetailsResolutionPage from '../event-details-resolution-page/index.web';
import { Size } from '../../../../../../components/inputs/button/constants/size';
const EventDetailsResolutions = ({ event, isAdmin, }) => {
    // Attributes
    const { eventResolutions, handleCreateResolution, isEventResolutionsLoading, handleUpdateResolution, isOpenEventResolution, onCloseEventResolution, resolutionEntity, onRefetchResolutions, } = useLogic(event);
    // Functions
    const ListHeaderComponent = () => {
        if (!eventResolutions?.length) {
            return <></>;
        }
        return (<HStack justify="space-between" width={'full'} px={'1rem'} py={'1.25rem'}>
        <Text font={FONTS.T1.T16Px.SemiBold600.value}>
          {t(tKeys.home.calendar.event_details.resolutions)}
        </Text>
        {isAdmin && (<Button onPress={handleCreateResolution} kind={Kind.OUTLINE} size={Size.DEFAULT}>
            {t(tKeys.common.add)}
          </Button>)}
      </HStack>);
    };
    function ListEmptyComponent() {
        if (isEventResolutionsLoading) {
            return <></>;
        }
        return (<Placeholder imageType="monocle-emoji" hasImage={false} title={t(tKeys.calendar.resolution.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.calendar.resolution.placeholder.subTitle)
                : t(tKeys.calendar.resolution.placeholder.subTitle_invitees)} endEnhancer={isAdmin && (<Box marginTop={'1.25rem'}>
              <Button kind={Kind.SECONDARY} onPress={handleCreateResolution} startEnhancer={() => (<Stack pl={'.5rem'}>
                    <Icon name="add" width={20} height={20} fill={COLORS.White.T500.value}/>
                  </Stack>)}>
                <Stack pl={'.5rem'}>
                  <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                    {t(tKeys.calendar.resolution.add_a_resolution)}
                  </Text>
                </Stack>
              </Button>
            </Box>)}/>);
    }
    function renderDocumentsItems({ item }) {
        return (<VStack alignItems={'flex-start'} width={'full'} onClick={() => handleUpdateResolution(item?.id)} spacing={'.5rem'} px={'1rem'} cursor={'pointer'}>
        {/* Title and date */}
        <HStack justify="space-between" width={'full'}>
          {/* Title */}
          <Text font={FONTS.T1.T12Px.SemiBold600.value}>{item?.title}</Text>
          {/* Date */}
          <Text font={FONTS.T1.T10Px.Regular400.value} color={COLORS.InputText.value}>
            {moment(item?.createdOn)?.format('DD MMM')}
          </Text>
        </HStack>

        {/* Resolution text */}
        <Text numberOfLines={9999} font={FONTS.T1.T12Px.Regular400.value}>
          {item?.description}
        </Text>
      </VStack>);
    }
    function ItemSeparatorComponent() {
        return (<Container marginVertical={16} marginHorizontal={8}>
        <LineSeparator width={1} color={COLORS.Line.value} type="bottom"/>
      </Container>);
    }
    // Render
    return (<Box width={'full'} pb={'2rem'}>
      <LoadingWrapper isLoading={isEventResolutionsLoading}>
        <FlatList data={eventResolutions ?? []} renderItem={renderDocumentsItems} ListHeaderComponent={ListHeaderComponent} ListEmptyComponent={() => <ListEmptyComponent />} ItemSeparatorComponent={ItemSeparatorComponent}/>
      </LoadingWrapper>
      {/* Modals */}
      <EventDetailsResolutionPage isOpen={isOpenEventResolution} onClose={onCloseEventResolution} refetchResolutions={onRefetchResolutions} resolutionEntity={resolutionEntity}/>
    </Box>);
};
export default EventDetailsResolutions;
