import React from 'react';
import Icon from '../../../../../../components/content/icon';
import Text from '../../../../../../components/content/text';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../components/inputs/button/constants/size';
import Container from '../../../../../../components/surfaces/container';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { Shape } from '../../../../../../constants/shape';
import styles from './style';
const ButtonCreatePost = ({ title, iconName, isDisabled, handleOnPress, fontTitle = FONTS.T1.T12Px.Medium500.value, iconSize = 12, }) => {
    return (<Container zIndex={50}>
      <Button shape={Shape.DEFAULT} size={Size.COMPACT} kind={Kind.GRAY} isDisabled={isDisabled} borderColor={COLORS.Line.value} backgroundColorPerso={COLORS.Disabled.value} onPress={handleOnPress} borderWidth={1} opacity={isDisabled ? 0.4 : 1}>
        <Flex direction="row" align="center" style={styles.mainContainer}>
          <Container style={styles.iconContainer}>
            <Icon name={iconName} width={iconSize} height={iconSize}/>
          </Container>

          <Text font={fontTitle} color={COLORS.Text.T400.value}>
            {title}
          </Text>
        </Flex>
      </Button>
    </Container>);
};
export default ButtonCreatePost;
