import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useCompaniesContext } from '../../../../contexts/companies';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { getReadTasksQueryKey, useCreateTask, } from '../../../../services/online/tasks/tasks';
import { createForm } from '../../../../utils/formik';
import { validationSchema } from './validations';
const useLogic = (onClose, eventId, inviteesIds) => {
    // Attributes
    const { selectedCompanyId, me } = useCompaniesContext();
    const queryClient = useQueryClient();
    const { isOpen: isOpenSelectPeople, onToggle: onToggleSelectPeople } = useDisclosure();
    // Form
    const { values, dirty, ...rest } = useFormik({
        initialValues: {
            title: '',
            description: '',
            assigneePerson: undefined,
            date: undefined,
        },
        onSubmit: handleOnSubmit,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: validationSchema(t),
    });
    const { handleSubmit } = rest;
    const form = createForm(values, rest);
    const disableTaskCreation = !values.title ||
        !values.date ||
        !values.assigneePerson ||
        !values.description;
    // Queries
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    });
    const { mutateAsync: createTask, isLoading: isCreateTaskLoading } = useCreateTask();
    const peopleInTheCompany = useMemo(() => {
        if (inviteesIds) {
            return enrolment?.items
                ?.filter(enrolment => inviteesIds?.includes(enrolment.person?.id))
                ?.map(p => p?.person);
        }
        return enrolment?.items?.map(enrolment => enrolment.person);
    }, [enrolment, inviteesIds]);
    // Functions
    function handleOnSubmit() {
        void createTask({
            data: {
                personId: values?.assigneePerson?.id,
                title: values?.title,
                description: values?.description,
                deadline: values?.date.toISOString(),
                eventId,
                creatorId: me?.id,
                companyId: selectedCompanyId,
            },
        }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadTasksQueryKey(undefined));
                onClose();
            },
        });
    }
    // Return
    return {
        // Functions
        handleOnSubmit: handleSubmit,
        isCreateTaskLoading,
        // Form
        form,
        dirty,
        disableTaskCreation,
        // Entities
        peopleInTheCompany,
        isOpenSelectPeople,
        onToggleSelectPeople,
    };
};
export default useLogic;
