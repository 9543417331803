import React from 'react';
import { Text as ChakraText } from '@chakra-ui/react';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
const Text = ({ children, font = FONTS.T1.T12Px.Regular400.value, 
// To have color on Android phone
color = COLORS.Text.T400.value, testID, numberOfLines = 1, width, maxWidth, ...rest }) => {
    return (<ChakraText {...rest} style={{
            color,
            fontFamily: font?.fontFamily,
            fontSize: font?.fontSize,
            fontWeight: font?.fontWeight,
            width,
            maxWidth,
            ...rest?.style,
        }} noOfLines={numberOfLines}>
      {children}
    </ChakraText>);
};
export default Text;
