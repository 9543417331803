import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useCompaniesContext } from '../../contexts/companies';
import { AnswerTypeEnum } from '../../enum/AnswerType';
import { getReadAgendaItemsQueryKey, getReadEventQueryKey, useAnswerAgendaItemQuestion, useReadEvent, } from '../../services/online/calendar/calendar';
import { useReadEnrolments } from '../../services/online/enrolments/enrolments';
import { useVote } from '../../services/online/news-feed/news-feed';
import { tKeys } from '../../translations/keys';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../components/content/use-toast/index.web';
const useLogic = (voteResultParams, selectedAnswerId) => {
    // Attributes
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const queryClient = useQueryClient();
    const toast = useToast();
    // Queries
    const { mutateAsync: answerQuestion } = useAnswerAgendaItemQuestion();
    const { mutateAsync: addVote } = useVote();
    const { data: eventVote } = useReadEvent(voteResultParams?.eventId ?? '', {
        query: { enabled: !!voteResultParams?.eventId && !voteResultParams?.event },
    });
    const event = eventVote || voteResultParams?.event;
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            enabled: !!selectedCompanyId && !voteResultParams?.eventId,
        },
    });
    const inviteeIds = useMemo(() => {
        if (event?.inviteeIds)
            return event?.inviteeIds;
        // Return all company members
        return enrolments?.items?.map(enrolment => enrolment.person?.id);
    }, [event, enrolments]);
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState();
    useEffect(() => {
        const previousAnswerIndex = voteResultParams?.question?.answers?.findIndex(a => a?.votes
            ?.flatMap(v => v?.personId)
            ?.some(id => id === selectedEnrolment?.person?.id));
        const hasNewAnswer = !!selectedAnswerId &&
            previousAnswerIndex !== -1 &&
            selectedAnswerId !==
                voteResultParams?.question?.answers?.[previousAnswerIndex].id;
        const isFirstAnswer = !!selectedAnswerId && previousAnswerIndex === -1;
        const answerEntities = voteResultParams?.question?.answers?.map((answer, index) => {
            let votes = answer?.votes?.map(v => v?.personId);
            // Remove previous vote
            if (hasNewAnswer && index === previousAnswerIndex) {
                votes = votes?.filter(personId => personId !== selectedEnrolment?.person?.id);
            }
            // Add newly selected vote
            if ((hasNewAnswer || isFirstAnswer) && answer.id === selectedAnswerId) {
                votes = votes?.concat(selectedEnrolment?.person?.id);
            }
            return {
                id: answer?.id,
                text: answer.text,
                type: AnswerTypeEnum[answer?.text ?? ''],
                votes,
            };
        });
        setAnswers(answerEntities);
        const selected = answerEntities?.find(a => a?.votes?.includes(selectedEnrolment?.person?.id ?? ''));
        setSelectedAnswer(selected?.text);
    }, [
        voteResultParams?.question?.answers,
        selectedAnswerId,
        selectedEnrolment?.person?.id,
    ]);
    const filteredAnswers = useMemo(() => answers?.filter(a => a.type !== AnswerTypeEnum.None), [answers]);
    const pendingVotes = useMemo(() => {
        const totalInviteesNumber = inviteeIds?.length ?? 0;
        const totalInviteesThatAlreadyVoted = answers?.reduce((previousCount, answer) => {
            return previousCount + answer?.votes?.length;
        }, 0);
        const totalVotes = totalInviteesNumber - totalInviteesThatAlreadyVoted;
        return totalVotes > 0 ? totalVotes : 0;
    }, [answers, inviteeIds]);
    const allVotesTotal = useMemo(() => {
        return filteredAnswers?.flatMap(a => a.votes)?.length;
    }, [filteredAnswers]);
    const handlePress = (answer) => {
        if (!voteResultParams?.canUserVote) {
            toast({
                status: 'error',
                title: t(tKeys.vote.need_presence.error),
            });
            return;
        }
        // If he already voted for this answer
        if (answer?.votes?.some(id => id === selectedEnrolment?.person?.id))
            return;
        let index;
        if (voteResultParams?.eventId) {
            void answerQuestion({
                questionId: voteResultParams?.question?.id ?? '',
                data: {
                    votedOn: new Date().toISOString(),
                    personId: selectedEnrolment?.person?.id,
                    answerType: answer?.type,
                },
            }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadEventQueryKey(event?.id ?? ''));
                    void queryClient.invalidateQueries(getReadAgendaItemsQueryKey({
                        'event-id': event?.id ?? '',
                    }));
                },
            });
            index = answers?.findIndex(a => a?.type === answer?.type);
        }
        else {
            void addVote({ answerId: answer?.id });
            index = answers?.findIndex(a => a?.id === answer?.id);
        }
        // Check and remove previous answer
        verifyPreviousAnswer();
        // Add new vote
        setAnswers(prev => {
            const selected = prev[index];
            selected?.votes?.push(selectedEnrolment?.person?.id ?? '');
            return prev;
        });
        setSelectedAnswer(answer?.text);
    };
    const votes = _.chain(filteredAnswers)
        .groupBy(a => a.text)
        .map((value, key) => ({ title: key, data: value?.flatMap(v => v.votes) }))
        .value();
    const verifyPreviousAnswer = () => {
        const previousAnswerIndex = filteredAnswers?.findIndex(a => a?.votes?.includes(selectedEnrolment?.person?.id ?? ''));
        if (previousAnswerIndex !== -1) {
            // remove previous vote
            setAnswers(prev => {
                const prevAnswer = prev[previousAnswerIndex];
                const prevVoteIndex = prevAnswer?.votes?.findIndex(id => id === selectedEnrolment?.person?.id);
                prevAnswer?.votes?.splice(prevVoteIndex, 1);
                return prev;
            });
        }
    };
    const userAlreadyVoted = useMemo(() => {
        const voterIds = filteredAnswers?.flatMap(answer => answer.votes);
        return voterIds?.some(id => id === selectedEnrolment?.person?.id);
    }, [filteredAnswers]);
    return {
        question: voteResultParams?.question,
        allVotesTotal,
        inviteeIds,
        pendingVotes,
        isClosed: !!voteResultParams?.question?.closedOn,
        handlePress,
        filteredAnswers,
        votes,
        selectedAnswer,
        userAlreadyVoted,
        canUserVote: voteResultParams?.canUserVote,
    };
};
export default useLogic;
