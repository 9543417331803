import { openPicker, openCamera } from 'react-native-image-crop-picker';
import Toast from 'react-native-toast-message';
import { DocumentTypes } from '../components/surfaces/document-modal/props';
import getDocumentData from './getDocumentData';
import getFileName from './getFileName';
import handleCameraPermission from './handleCameraPermission';
import handleFilePermission from './handleFilePermission';
import imageExtensionsValid from './imageExtensionsValid';
import { handlePickFile } from './uploadFile';
export default async function handleSelectDocument(documentType, redirect, handleOnCloseModal, routeName, eventId, eventStartDate, agendaItemId) {
    switch (documentType) {
        case DocumentTypes.FILE: {
            const document = await handleAddFileFromDocuments();
            handleOnCloseModal?.();
            if (!document) {
                return undefined;
            }
            const base64Data = await getDocumentData(document);
            if (!redirect) {
                return {
                    documentMetaData: document,
                    data: base64Data,
                };
            }
            redirect(routeName, {
                documentMetaData: document,
                data: base64Data,
                dataType: DocumentTypes.FILE,
                eventId,
                agendaItemId,
                issuedOn: eventStartDate,
            });
            return;
        }
        case DocumentTypes.PICTURE_GALLERY: {
            const picture = await handleAddPictureFromGallery();
            const splittedPath = picture?.path?.split('/');
            const documentName = picture?.filename ?? splittedPath[splittedPath?.length - 1];
            handleOnCloseModal?.();
            if (!picture) {
                return undefined;
            }
            if (!redirect) {
                return picture;
            }
            redirect(routeName, {
                data: picture?.data,
                dataType: DocumentTypes.PICTURE_GALLERY,
                eventId,
                agendaItemId,
                issuedOn: eventStartDate,
                documentMetaData: {
                    ...picture,
                    filename: documentName,
                },
            });
            return;
        }
        case DocumentTypes.CAMERA: {
            const picture = await handleAddPictureFromCamera();
            const splittedPath = picture?.path?.split('/');
            const documentName = picture?.filename ?? splittedPath[splittedPath?.length - 1];
            handleOnCloseModal?.();
            if (!picture) {
                return undefined;
            }
            if (!redirect) {
                return picture;
            }
            redirect(routeName, {
                data: picture?.data,
                dataType: DocumentTypes.CAMERA,
                eventId,
                agendaItemId,
                issuedOn: eventStartDate,
                documentMetaData: { ...picture, filename: documentName },
            });
            return;
        }
        default:
            return undefined;
    }
}
// - Handlers when user choose a picture from it's storage to pickup a document
// -- Ask user permission to use picture from it's storage to pickup a document
const handleChooseFileFromDocuments = async () => {
    const choosenDocument = await handlePickFile();
    if (choosenDocument) {
        return choosenDocument;
    }
};
const handleAddFileFromDocuments = async () => {
    const isUserGavePermission = await handleFilePermission();
    if (isUserGavePermission) {
        return await handleChooseFileFromDocuments();
    }
};
// - Handlers when user choose a picture from it's gallery
// -- Ask user permission to use picture from it's gallery
const handleAddPictureFromGallery = async () => {
    const isUserGavePermission = await handleFilePermission();
    if (isUserGavePermission) {
        return await handleChoosePictureFromGallery();
    }
};
// Handle the selected picture
const handleChoosePictureFromGallery = async () => {
    const choosenImage = await openPicker({
        includeBase64: true,
        mediaType: 'photo',
        enableRotationGesture: false,
    });
    if (choosenImage) {
        const choosenImageExtension = getFileName(choosenImage.mime)?.split('.')?.[1];
        // Check the extensions of the choosen file
        if (!imageExtensionsValid?.includes(choosenImageExtension)) {
            Toast.show({
                type: 'error',
                text1: `Your file extension do not match. Please use a ${imageExtensionsValid.toString()} file.`,
            });
        }
        return choosenImage;
    }
};
// - Handlers when user choose a picture from it's camera
// -- Ask user permission to use picture from it's camera
const handleTakePictureFromCamera = async () => {
    const choosenImage = await openCamera({
        includeBase64: true,
        mediaType: 'photo',
        enableRotationGesture: false,
        compressImageMaxHeight: 750,
        compressImageMaxWidth: 750,
    });
    if (choosenImage) {
        const choosenImageExtension = getFileName(choosenImage.mime)?.split('.')[1];
        // Check the extensions of the choosen file
        if (!imageExtensionsValid?.includes(choosenImageExtension)) {
            Toast.show({
                type: 'error',
                text1: `Your file extension do not match. Please use a ${imageExtensionsValid.toString()} file.`,
            });
        }
        return choosenImage;
    }
};
const handleAddPictureFromCamera = async () => {
    const isUserGavePermission = await handleCameraPermission();
    if (isUserGavePermission) {
        return await handleTakePictureFromCamera();
    }
};
