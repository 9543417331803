import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import RNCalendarEvents from 'react-native-calendar-events';
import { useToast } from '../../../../components/content/use-toast/index.web';
import { PostKind } from '../../../../components/surfaces/share-in-private-card/props';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { getReadEventQueryKey, getReadEventsQueryKey, useAcceptEventInvitation, useAcceptInRemoteEventInvitation, useDeclineEventInvitation, useDeleteEvent, useDownloadIcsFile, usePublishEvent, useReadEvent, useUpdateEvent, } from '../../../../services/online/calendar/calendar';
import { useReadEnrolments } from '../../../../services/online/enrolments/enrolments';
import { useReadTimeZones } from '../../../../services/online/others/others';
import { getReadTasksQueryKey, useReadTasks, } from '../../../../services/online/tasks/tasks';
import { Role, TaskType } from '../../../../services/types';
import { tKeys } from '../../../../translations/keys';
import { TaskStatus } from '../../tasks/types';
import { EventDetailsTabs, PresenceStatus } from './types';
import { ModulesEnum } from '../../../../enum/Modules';
const useLogic = () => {
    // Attributes
    const queryClient = useQueryClient();
    const { push, goBack, params } = useRouter();
    const toast = useToast();
    const eventId = params?.eventId;
    const { selectedCompanyId, selectedEnrolment, modules } = useCompaniesContext();
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    const [proxyEntityParams, setProxyEntityParams] = useState();
    const { isOpen: isOpenEditEvent, onOpen: onOpenEditEvent, onClose: onCloseEditEvent, } = useDisclosure();
    const { isOpen: isOpenShareEvent, onOpen: onOpenShareEvent, onClose: onCloseShareEvent, } = useDisclosure();
    const { isOpen: isOpenPreviewMinutes, onOpen: onOpenPreviewMinutes, onClose: onClosePreviewMinutes, } = useDisclosure();
    const { isOpen: isOpenProxy, onOpen: onOpenProxy, onClose: onCloseProxy, } = useDisclosure();
    // State
    const [selectedTab, setSelectedTab] = useState(0);
    // States
    const [showBigHeader, setShowBigHeader] = useState(true);
    // Queries
    const { me } = useCompaniesContext();
    const { data: allTimezones } = useReadTimeZones({
        culture: me?.cultureName ?? '',
    }, {
        query: {
            enabled: !!me,
        },
    });
    const { data: eventTasks, isLoading: isTasksLoading, refetch: refetchTasks, } = useReadTasks({
        'company-id': selectedCompanyId,
        'person-id': me?.id,
        status: TaskStatus.Open,
        types: Object.values(TaskType),
        'event-id': eventId,
    }, {
        query: {
            enabled: !!eventId,
        },
    });
    const { data: event, refetch: refetchEvent, isLoading: isEventLoading, } = useReadEvent(eventId, {
        query: {
            enabled: !!eventId,
        },
    });
    const today = new Date();
    const eventIsPast = moment(event?.endDate).isBefore(today);
    const isCurrentUserInvited = event?.invitees?.some(invitee => invitee?.personId === me?.id) ?? false;
    const isCurrentUserPresent = event?.invitees?.some(invitee => (invitee.acceptedOn || invitee.isRemote) && invitee.personId === me?.id) ?? false;
    const { mutateAsync: publishEvent, isLoading: isPublishEventLoading } = usePublishEvent();
    const { mutateAsync: acceptEventInvitation } = useAcceptEventInvitation();
    const { mutateAsync: declineEventInvitation } = useDeclineEventInvitation();
    const { mutateAsync: acceptInRemoteEventInvitation } = useAcceptInRemoteEventInvitation();
    const { data: downloadIcsFile, isLoading: isDownloadIcsFileLoading } = useDownloadIcsFile(eventId, {
        query: {
            enabled: !!eventId,
        },
    });
    const { data: enrolment } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    });
    const { mutateAsync: deleteEvent, isLoading: isDeleteLoading } = useDeleteEvent();
    const { mutateAsync: updateEvent } = useUpdateEvent();
    const peopleInTheCompany = enrolment?.items?.map(enrolment => enrolment.person);
    const [eventShouldBeRecorded, setEventShouldBeRecorded] = useState(false);
    const [eventWillBeRecorded, setEventWillBeRecorded] = useState(false);
    useEffect(() => {
        if (event && event.publishedOn) {
            const shouldBeRecorded = modules?.some(m => m.name === ModulesEnum.AI_MINUTE_BUILDER);
            setEventShouldBeRecorded(shouldBeRecorded);
            const willBeRecorded = shouldBeRecorded && !!event.leexiReference;
            setEventWillBeRecorded(willBeRecorded);
        }
    }, [event, modules]);
    // Tabs options
    const tabsOption = [
        {
            label: t(EventDetailsTabs[0]),
            value: 0,
        },
        {
            label: t(EventDetailsTabs[1]),
            value: 1,
        },
        {
            label: t(EventDetailsTabs[2]),
            value: 2,
        },
        {
            label: t(EventDetailsTabs[3]),
            value: 3,
        },
        {
            label: t(EventDetailsTabs[4]),
            value: 4,
        },
    ];
    // Functions
    function handleOnPressTab(activeKey) {
        setSelectedTab(activeKey);
    }
    function handleDeleteEventPress() {
        if (event?.id) {
            void deleteEvent({ eventId: event?.id }, {
                onSuccess: () => {
                    void queryClient.invalidateQueries(['/calendar/events']);
                    onGoBack();
                },
            });
        }
    }
    async function handlePublishEvent(eventId) {
        try {
            if (!event?.inviteeIds?.length) {
                toast({
                    status: 'error',
                    title: t(tKeys.home.calendar.event_details.information.no_invitees.body_text),
                });
                return;
            }
            await publishEvent({
                eventId,
            }, {
                onSuccess: () => {
                    void queryClient.refetchQueries(getReadEventsQueryKey(null));
                    void queryClient.refetchQueries(getReadEventQueryKey(event?.id));
                    void queryClient.refetchQueries(getReadTasksQueryKey(undefined));
                },
                onError: err => {
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.events.publish.error),
                    });
                    console.error('Error when publishing an event : ', err);
                },
            });
        }
        catch (err) {
            console.error('Error when publising an event : ', err);
        }
    }
    function findTimezoneName(timezoneId) {
        return allTimezones?.find(t => t?.id === timezoneId ||
            t?.displayName === timezoneId ||
            t?.standardName === timezoneId ||
            t?.daylightName === timezoneId)?.displayName;
    }
    function parseIcsInJson(icsEvent) {
        const parsedIcsToReturn = {
            title: '',
            startDate: '',
            endDate: '',
            location: '',
            description: '',
            url: '',
        };
        // eslint-disable-next-line array-callback-return
        icsEvent?.map(event => {
            if (event) {
                const splittedEvent = event?.split(':');
                const value = splittedEvent?.[1];
                switch (splittedEvent?.[0]) {
                    case 'DESCRIPTION': {
                        parsedIcsToReturn.description = value;
                        parsedIcsToReturn.title = value;
                        break;
                    }
                    case 'DTSTART': {
                        parsedIcsToReturn.startDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        break;
                    }
                    case 'DTEND': {
                        parsedIcsToReturn.endDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        break;
                    }
                    case 'LOCATION': {
                        const parsedValue = value
                            ?.split(',')
                            ?.map(v => v?.replace('\\', ''));
                        if (parsedValue) {
                            parsedIcsToReturn.location = parsedValue?.[0];
                            break;
                        }
                        break;
                    }
                    case 'URL': {
                        parsedIcsToReturn.url = value?.concat(':', splittedEvent[splittedEvent?.length - 1]);
                        break;
                    }
                    default:
                        break;
                }
            }
        });
        return parsedIcsToReturn;
    }
    async function handleDownloadICSFile() {
        if (event?.id && downloadIcsFile) {
            const parsedIcs = downloadIcsFile?.split('\r\n');
            const { description, endDate, location, startDate, title, url } = parseIcsInJson(parsedIcs);
            try {
                const eventStatus = await RNCalendarEvents?.saveEvent(title, {
                    startDate,
                    endDate,
                    description,
                    location,
                    url,
                });
                if (eventStatus) {
                    toast({
                        status: 'success',
                        title: t(tKeys.home.calendar.save_event_in_calendar),
                    });
                    return;
                }
                toast({
                    status: 'error',
                    title: t(tKeys.home.calendar.error_event_in_calendar),
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    }
    function handleEditEventPress() {
        onOpenEditEvent();
    }
    async function handlePresenceStatus(status, currentInviteeProxyId) {
        switch (status) {
            case PresenceStatus.Present: {
                try {
                    await acceptEventInvitation({
                        eventId: event?.id,
                    }, {
                        onSuccess: () => {
                            void refetchEvent();
                            void queryClient.invalidateQueries(getReadTasksQueryKey(undefined));
                        },
                        onError: err => {
                            toast({
                                status: 'error',
                                title: t(tKeys.validation.event_details.event_invitation),
                            });
                            console.error('Error when changing event invitation status : ', err);
                        },
                    });
                }
                catch (err) {
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.event_details.event_invitation),
                    });
                    console.error('Error when changing event invitation status : ', err);
                }
                return;
            }
            case PresenceStatus.Absent: {
                try {
                    await declineEventInvitation({
                        eventId: event?.id,
                    }, {
                        onSuccess: () => {
                            void refetchEvent();
                        },
                    });
                }
                catch (err) {
                    console.error('Error when refusing an event invitation : ', err);
                }
                break;
            }
            case PresenceStatus.Proxy: {
                // Update entity
                const proxyEntity = {
                    eventId: event?.id,
                    inviteePeopleIds: event?.inviteeIds,
                    peopleAssigned: peopleInTheCompany?.find(p => p?.id === currentInviteeProxyId),
                    invitees: event.invitees,
                };
                setProxyEntityParams(proxyEntity);
                onOpenProxy();
                break;
            }
            case PresenceStatus.Remote: {
                await acceptInRemoteEventInvitation({
                    eventId: event?.id ?? '',
                }, {
                    onSuccess: () => {
                        void refetchEvent();
                        void queryClient.invalidateQueries(getReadTasksQueryKey(undefined));
                    },
                    onError: err => {
                        toast({
                            status: 'error',
                            title: t(tKeys.validation.event_details.event_invitation),
                        });
                        console.error('Error when changing event invitation status : ', err);
                    },
                });
            }
        }
    }
    function refetchEventData() {
        void refetchEvent();
    }
    function refetchEventTasks() {
        void refetchTasks();
    }
    function handleOnPressInvitees() {
        // If user is admin and the event has begin / is past then user is redirect to the "presence" page
        const isAdmin = selectedEnrolment?.role === Role?.[0];
        if (isAdmin &&
            moment(event?.startDate)?.isBefore(new Date()) &&
            !!event?.publishedOn) {
            push('/event-detail-invitees-presence', {
                event,
            });
            return;
        }
        push('/event-detail-invitees-page', {
            event,
        });
    }
    async function handleOnPreviewMinutesPress() {
        onOpenPreviewMinutes();
    }
    function handleOnPressMessage() {
        onOpenShareEvent();
    }
    function onGoBack() {
        void queryClient.refetchQueries([getReadEventsQueryKey(null)]);
        goBack();
    }
    const shareInMessageEntity = {
        data: event,
        type: PostKind?.EVENT,
        isEvent: true,
    };
    function handleOnCloseShareInMessage() {
        goBack();
        onCloseShareEvent();
    }
    const currentUserInvitee = useMemo(() => {
        return event?.invitees?.find(invitee => invitee?.personId === me?.id);
    }, [event?.invitees]);
    const canModifyInviteesPresence = isAdmin &&
        moment(event?.startDate)?.isBefore(today) &&
        !!event?.publishedOn;
    const canRemoveInvitees = isAdmin && moment(event?.startDate)?.isAfter(moment().subtract(1, 'days'));
    // Effects
    useEffect(() => {
        if (!event)
            return;
        setTimeout(() => (document.title = `${selectedEnrolment.company?.name} | ${t(event?.name)}`), 200);
    }, [event?.name, selectedEnrolment]);
    // Return
    return {
        event,
        onGoBack,
        showBigHeader,
        setShowBigHeader,
        tabsOption,
        handleOnPressTab,
        selectedTab,
        handlePublishEvent,
        isPublishEventLoading,
        findTimezoneName,
        isEventLoading,
        handleDownloadICSFile,
        handleEditEventPress,
        handlePresenceStatus,
        me,
        handleOnPressInvitees,
        showPreviewMinutes: isAdmin && eventIsPast && !!event?.publishedOn,
        handleOnPreviewMinutesPress,
        isDownloadIcsFileLoading,
        isAdmin,
        handleOnPressMessage,
        isCurrentUserInvited,
        isCurrentUserPresent,
        isOpenEditEvent,
        onCloseEditEvent,
        shareInMessageEntity,
        isOpenShareEvent,
        isOpenPreviewMinutes,
        onClosePreviewMinutes,
        isOpenProxy,
        onCloseProxy,
        proxyEntityParams,
        handleOnCloseShareInMessage,
        onCloseShareEvent,
        refetchEventData,
        eventId,
        isDeleteLoading,
        handleDeleteEventPress,
        currentUserInvitee,
        canModifyInviteesPresence,
        canRemoveInvitees,
        eventIsPast,
        refetchEventTasks,
        eventTasks,
        isTasksLoading,
        enrolment,
        eventShouldBeRecorded,
        eventWillBeRecorded,
    };
};
export default useLogic;
