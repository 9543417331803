import React from 'react';
import { TouchableHighlight } from 'react-native';
import AvatarDetails from '../../../../../../components/content/avatar-details';
import Flex from '../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import styles from './style';
const SingleSelectItem = ({ item, onPressSelected, endEnhancer, }) => {
    if (!item || item === undefined) {
        return <></>;
    }
    // Render
    return (<TouchableHighlight onPress={onPressSelected} underlayColor={COLORS.Tag.value} style={styles.mainContainer}>
      <Flex justify="space-between">
        <AvatarDetails avatarSize={40} showBoardRole person={item} avatarFont={FONTS.T1.T14Px.SemiBold600.value}/>
        {endEnhancer || null}
      </Flex>
    </TouchableHighlight>);
};
export default SingleSelectItem;
