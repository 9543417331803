import { SearchResultType } from './components/content/search-result-card/props';
// Functions
export function getSearchResultType(result) {
    if (result?.document)
        return SearchResultType.FILE;
    if (result?.event)
        return SearchResultType.EVENT;
    if (result?.message)
        return SearchResultType.MESSAGE;
    if (result?.post)
        return SearchResultType.POST;
}
