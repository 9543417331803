import React from 'react';
import TouchableScale from 'react-native-touchable-scale';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Icon from '../../content/icon';
import Text from '../../content/text';
import Flex from '../../surfaces/flex';
export function Checkbox({ onChange, value, children, isDisabled, font = FONTS.T1.T12Px.Medium500.value, spacing = 6, color, }) {
    // Render
    return (<TouchableScale onPress={() => onChange(!value)} disabled={isDisabled} activeScale={1} hitSlop={{ bottom: 12, left: 12, right: 12, top: 12 }}>
      <Flex spacing={spacing} align="center">
        {value ? (<Icon name={'box-selected'} width={24} height={24} fill={color}/>) : (<Icon name={'box'} width={24} height={24}/>)}
        <Text font={font} color={COLORS.Text.T400.value}>
          {children}
        </Text>
      </Flex>
    </TouchableScale>);
}
export default Checkbox;
