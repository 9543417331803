import React from 'react';
import Shapes, { Shape } from '../../../constants/shape';
import KINDS, { Kind } from './constants/kind';
import { Size } from './constants/size';
import styles from './style';
import { Button as ChakraButton, Text } from '@chakra-ui/react';
import { WEBSIZES } from './constants/size/index.web';
import COLORS from '../../../constants/colors';
const Button = ({ id, children, endEnhancer, isDisabled, isLoading, kind = Kind.PRIMARY, onPress, shape = Shape.DEFAULT, size = Size.DEFAULT, startEnhancer, width, borderWidth = 0, borderColor, backgroundColorPerso, textColor, opacity, right = 0, textWidth = '100%', minWidth, height, }) => {
    // Attributes
    const { backgroundColor, disabledBackgroundColor, disabledTextColor, textColor: textColorFromKind, } = KINDS[kind];
    const { borderRadius } = Shapes[shape];
    const { paddingVertical, font, paddingHorizontal, iconSize } = WEBSIZES[size];
    textColor = textColor ?? textColorFromKind;
    const { fontFamily, fontSize, fontWeight } = font;
    const isOutline = kind === Kind.OUTLINE;
    // Functions
    const renderChildren = () => {
        switch (typeof children) {
            // Text
            case 'string': {
                return (<Text fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} color={isDisabled ? disabledTextColor : textColor} width={textWidth}>
            {children}
          </Text>);
            }
            // ReactElement
            default:
                return <>{children}</>;
        }
    };
    // Render
    return (<ChakraButton id={id} overflow={'hidden'} onClick={() => onPress?.()} {...styles.mainContainer} backgroundColor={backgroundColorPerso ??
            (isDisabled ? disabledBackgroundColor : backgroundColor)} py={paddingVertical} borderRadius={borderRadius} borderColor={isOutline ? borderColor ?? COLORS.BGFullscreen.value : borderColor} borderWidth={isOutline ? 1 : borderWidth} width={width ?? 'fit-content'} opacity={opacity} right={right} isDisabled={isDisabled} isLoading={isLoading} px={paddingHorizontal} minW={minWidth} height={height} _focus={{ boxShadow: 'none', backgroundColor }} _hover={{ boxShadow: 'none', backgroundColor }} cursor={'pointer'} textAlign={'center'}>
      {startEnhancer?.({
            width: iconSize,
            height: iconSize,
            color: textColor,
        })}

      <>{renderChildren()}</>
      {endEnhancer?.({
            width: iconSize,
            height: iconSize,
            color: textColor,
        })}
    </ChakraButton>);
};
export default Button;
