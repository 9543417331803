import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/content/icon';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import { getLanguageLabel } from '../../../../utils/getLanguageValue';
import TagField from '../components/inputs/tag-field';
import useLogic from './logic.web';
import Modal from '../../../../components/surfaces/modal/index.web';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import PopOver from '../../../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../components/inputs/tag-field/tag-item/index.web';
import SelectDocumentLanguages from '../document-language/index.web';
const GenerateEventDocument = ({ isOpen, onClose, eventId, culture, }) => {
    // Attributes
    const { t } = useTranslation();
    const { onDownloadPress, selectedCulture, onSignPress, isLoading, isDownloading, handleOnSelectedLanguage, isOpenLanguage, onToggleLanguage, } = useLogic(eventId, culture);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} title={t(tKeys.calendar.generateDocument.header)} onClose={onClose} hasFooter={false} hasCloseButton>
      <VStack py={'1rem'} alignItems={'flex-start'} width={'full'} minHeight={'20rem'}>
        <TagField title={t(tKeys.calendar.generateDocument.selectLanguage)} startEnhancer={<Icon name="add-files" width={32} height={32}/>} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(getLanguageLabel(selectedCulture))}/>} isPortal={false} isOpen={isOpenLanguage} onToggle={onToggleLanguage}>
              <SelectDocumentLanguages onSelectLanguage={handleOnSelectedLanguage} selectedLanguage={selectedCulture}/>
            </PopOver>}/>

        <HStack spacing={'.75rem'} width={'full'} px={'1rem'}>
          <Button width={'100%'} onPress={onSignPress} isLoading={isLoading} isDisabled={isLoading}>
            <Text color={COLORS.White.T500.value}>
              {t(tKeys.calendar.generateDocument.signButton)}
            </Text>
          </Button>
          <Button width={'100%'} onPress={onDownloadPress} isLoading={isDownloading} isDisabled={isDownloading}>
            <Text color={COLORS.White.T500.value}>
              {t(tKeys.common.download)}
            </Text>
          </Button>
        </HStack>
        <Stack width={'full'} px={'1rem'}>
          <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.InputText.value} numberOfLines={2}>
            {t(tKeys.calendar.eventDetail.previewMinutes.helper)}
          </Text>
        </Stack>
      </VStack>
    </Modal>);
};
export default GenerateEventDocument;
