import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import Text from '../../../../../../../components/content/text';
import Button from '../../../../../../../components/inputs/button';
import { Kind } from '../../../../../../../components/inputs/button/constants/kind';
import Container from '../../../../../../../components/surfaces/container';
import Flex from '../../../../../../../components/surfaces/flex';
import COLORS from '../../../../../../../constants/colors';
import FONTS from '../../../../../../../constants/fonts';
import { tKeys } from '../../../../../../../translations/keys';
import ButtonProgression from '../../../inputs/button-progression';
import useLogic from './logic.web';
import styles from './style';
import VoteResults from '../../../../../../vote-results/index.web';
const CardPoll = ({ postData, handleOnPressPollButton, isVoteLoading, currentUserId, }) => {
    // Attributes
    const { t } = useTranslation();
    const { answers, text } = postData?.question;
    // Logic
    const { animateVote, selectedAnswerId, maxValue, handlePress, allVotesTotal, isOpen, onClose, handleSeePollResults, voteResultParams, } = useLogic({
        currentUserId,
        handleOnPressPollButton,
        isVoteLoading,
        post: postData,
    });
    // Render Answer
    const renderAnswer = ({ item }) => {
        const isSelected = selectedAnswerId === item.id;
        const userVotedForAnswer = item?.votes?.find(v => v.personId === currentUserId);
        const value = item?.votes?.length + (!userVotedForAnswer && isSelected && 1);
        return (<Container style={{
                ...styles.inputContainer,
            }}>
        <ButtonProgression value={value} maxValue={maxValue} minValue={0} onPress={!isSelected ? () => handlePress(item.id) : undefined} title={item?.text?.replace('_', '')} isSelected={isSelected} showNumberOfVotes={item?.votes?.find(vote => vote?.personId === currentUserId) !==
                undefined} animate={animateVote}/>
      </Container>);
    };
    // Render
    return (<Container>
      <Container style={styles.contentTextContainer}>
        <Text numberOfLines={100}>{postData?.content}</Text>
      </Container>
      <Flex direction="column" style={styles.mainContainer}>
        {/* Poll title */}
        {!!text && (<Container style={styles.titleContainer}>
            <Text color={COLORS.Text.T400.value} font={FONTS.T1.T14Px.Regular400.value} numberOfLines={100}>
              {text}
            </Text>
          </Container>)}

        {/* Inputs */}
        <FlatList data={answers} renderItem={renderAnswer} keyExtractor={item => item.id}/>
      </Flex>

      {/* Number total of vote and see poll results button */}
      <Flex justify="space-between" style={styles.bottomContentContainer}>
        {/* Number total of votes */}
        <Text font={FONTS.T1.T14Px.Regular400.value} color={COLORS.InputText.value}>
          {t(tKeys.calendar.agenda.item.totalVotes, {
            total: allVotesTotal,
        })}
        </Text>

        {/* Button see poll results */}
        <Container>
          <Button kind={Kind.TRANSPARENT} onPress={handleSeePollResults}>
            <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Govrn.Blue.T500.value}>
              {t(tKeys.newsFeed.cardPoll.seePollResults)}
            </Text>
          </Button>
        </Container>
      </Flex>
      <VoteResults onClose={onClose} isOpen={isOpen} voteResultParams={voteResultParams} selectedAnswerId={selectedAnswerId} isNewsFeed/>
    </Container>);
};
export default CardPoll;
