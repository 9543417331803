import { StyleSheet } from 'react-native';
import COLORS from '../../../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        borderWidth: 1,
        borderColor: COLORS.Line.value,
        borderRadius: 12,
        padding: 12,
        backgroundColor: 'white',
    },
    pollContainer: { marginBottom: 20 },
    closeButtonContainer: {
        alignSelf: 'flex-start',
    },
    closeButton: {
        borderRadius: 100,
        backgroundColor: COLORS.Text.T500.value,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    addOptionContainer: {
        alignContent: 'center',
        alignItems: 'center',
    },
    addOptionContainerButton: {
        marginTop: 24,
        marginBottom: 12,
    },
});
export default styles;
