import { getReadDocumentsQueryKey, useCreateDocument, useReadDocumentTypes, } from '../../../services/online/repository/repository';
import { useToast } from '../../../components/content/use-toast/index.web';
import { useCompaniesContext } from '../../../contexts/companies';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translations/keys';
import { useQueryClient } from '@tanstack/react-query';
export const useBoardPackDocumentPreviewPlaceholderLogic = (event, agendaItemId) => {
    // Attributes
    const toast = useToast();
    const { t } = useTranslation();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const queryClient = useQueryClient();
    // Queries
    const { mutateAsync: createRepositoryDocument, isLoading: isCreateDocumentLoading, } = useCreateDocument();
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId,
            keepPreviousData: true,
        },
    });
    const defaultDocumentType = documentTypes?.find(documentType => documentType.name === '_FT_BOARDAPPE')?.id;
    // Functions
    async function handleCreateNewFile(file) {
        if (!file)
            return;
        try {
            await createRepositoryDocument({
                data: {
                    typeId: defaultDocumentType,
                    companyId: selectedCompanyId,
                    creatorId: selectedEnrolment?.person?.id,
                    description: '',
                    file: {
                        content: file?.base64,
                        mimeType: file?.type,
                        filename: file?.name,
                    },
                    title: file?.name,
                    eventId: event.id,
                    agendaItemId,
                    issuedOn: event?.startDate,
                },
            }, {
                onError: () => {
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.document.upload.error),
                    });
                },
                onSuccess: () => {
                    void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                        'event-id': event?.id,
                        'document-types': documentTypes?.map(d => d?.id),
                        'company-id': selectedCompanyId,
                    }));
                    toast({
                        status: 'success',
                        title: t(tKeys.validation.document.upload.success),
                    });
                },
            });
        }
        catch (err) {
            console.error('Error', err);
        }
    }
    const handleDropDocument = async (acceptedFiles) => {
        const readFileAsBase64 = async (file) => {
            return await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result?.split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        };
        const filesWithBase64 = await Promise.all(acceptedFiles.map(async (file, index) => {
            const base64 = await readFileAsBase64(file);
            return {
                path: acceptedFiles[index].path,
                lastModified: acceptedFiles[index].lastModified,
                lastModifiedDate: acceptedFiles[index].lastModifiedDate,
                name: acceptedFiles[index].name,
                size: acceptedFiles[index].size,
                type: acceptedFiles[index].type,
                webkitRelativePath: acceptedFiles[index].webkitRelativePath,
                base64,
            };
        }));
        await Promise.all(filesWithBase64.map(async (file) => await handleCreateNewFile(file)));
    };
    // Return
    return { isCreateDocumentLoading, handleDropDocument };
};
