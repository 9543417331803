import AsyncStorage from '@react-native-async-storage/async-storage';
import Axios from 'axios';
import Config from 'react-native-config';
import { EnumTokenService } from '../../screens/auth/type';
export const AXIOS_INSTANCE = Axios.create({
    baseURL: getUrlFromEnv(),
});
function getUrlFromEnv() {
    if (!Config.API_URL && !process.env.API_URL) {
        return 'https://app-govrn-sagas-dev-fr-001.azurewebsites.net/';
    }
    return Config.API_URL || process.env.API_URL;
}
const getImprovedConfig = async (options) => {
    let tokenToUse = await AsyncStorage.getItem(EnumTokenService.ACCESS_TOKEN);
    if (options?.useRefreshToken) {
        tokenToUse = await AsyncStorage.getItem(EnumTokenService.REFRESH_TOKEN);
    }
    return {
        headers: {
            Authorization: `bearer ${tokenToUse}`,
        },
    };
};
export const customInstance = async (config, options) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
        ...config,
        ...(await getImprovedConfig(options)),
        cancelToken: source.token,
    }).then(({ data }) => data);
    // eslint-disable-next-line
    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
    };
    return await promise;
};
