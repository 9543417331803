import AsyncStorage from '@react-native-async-storage/async-storage';
var EnumApplicationLanguage;
(function (EnumApplicationLanguage) {
    EnumApplicationLanguage["APPLICATION_LANGUAGE"] = "application_language";
})(EnumApplicationLanguage || (EnumApplicationLanguage = {}));
export const saveApplicationLanguageInStorage = async ({ language, }) => {
    try {
        await AsyncStorage.setItem(EnumApplicationLanguage.APPLICATION_LANGUAGE, language);
    }
    catch (err) {
        console.error('Error when store application langauge in store : ', err);
    }
};
export const getApplicationLanguageFromStorage = async () => {
    try {
        const applicationLanguage = await AsyncStorage.getItem(EnumApplicationLanguage.APPLICATION_LANGUAGE);
        return applicationLanguage;
    }
    catch (err) {
        console.error('Error when retrieve application language from store  : ', err);
    }
    return 'en';
};
