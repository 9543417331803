import React from 'react';
import { TouchableHighlight } from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import COLORS from '../../../../constants/colors';
import { PressableType } from '../type';
const PressableWrapper = ({ pressableType, children, underlayColor = COLORS.Tag.value, activeScale, ...rest }) => {
    switch (pressableType) {
        case PressableType.HIGHLIGHT:
            return (<TouchableHighlight underlayColor={underlayColor} {...rest}>
          {children}
        </TouchableHighlight>);
        case PressableType.SCALE:
            return (<TouchableScale {...rest} activeScale={activeScale ?? 0.98} useNativeDriver>
          {children}
        </TouchableScale>);
        case PressableType.NONE:
            return (<TouchableScale {...rest} activeScale={1} useNativeDriver>
          {children}
        </TouchableScale>);
    }
};
export default PressableWrapper;
